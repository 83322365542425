// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Portal from "../Portal.mjs";
import * as Styles from "../styles.mjs";
import * as $$Window from "../externals/window.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@mantine/core";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as ProductTour__ClickSimulator from "./ProductTour__ClickSimulator.mjs";

function getPulseKeyframeStyle(step) {
  var color = Styles.Color.avoPinkShade2;
  var alpha0 = Styles.Color.setAlpha(color, 0.7);
  if (step === 1) {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(24), undefined, undefined, alpha0)),
            tl: /* [] */0
          };
  } else if (step === 2) {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(5), undefined, undefined, alpha0)),
            tl: /* [] */0
          };
  } else {
    return {
            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(5), undefined, undefined, color)),
            tl: /* [] */0
          };
  }
}

function buttonWrapper(opened, br) {
  return Curry._1(Css.style, {
              hd: opened ? Css.outline(Css.px(1), "solid", Styles.Color.avoPink) : Css.outline(Css.px(1), "solid", "transparent"),
              tl: {
                hd: Css.borderRadius(Css.px(br)),
                tl: {
                  hd: opened ? Css.animation({
                          NAME: "ms",
                          VAL: 3000.0
                        }, undefined, undefined, "easeInOut", "forwards", undefined, "infinite", Curry._1(Css.keyframes, {
                              hd: [
                                0,
                                getPulseKeyframeStyle(0)
                              ],
                              tl: {
                                hd: [
                                  70,
                                  getPulseKeyframeStyle(1)
                                ],
                                tl: {
                                  hd: [
                                    100,
                                    getPulseKeyframeStyle(2)
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            })) : Styles.emptyStyle,
                  tl: /* [] */0
                }
              }
            });
}

function targetWrapperStyles(elementClickable, leftFloat, topFloat, widthFloat, heightFloat, elementPaddingFloat, withOutline, opened, br) {
  return Curry._1(Css.merge, {
              hd: withOutline ? buttonWrapper(opened, br) : Curry._1(Css.style, /* [] */0),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position("fixed"),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: 150.0
                            }, undefined, undefined, "all"),
                        tl: {
                          hd: Css.borderRadius(Css.px(br)),
                          tl: {
                            hd: elementClickable ? Css.pointerEvents("none") : Styles.emptyStyle,
                            tl: {
                              hd: Css.left(Css.pxFloat(leftFloat - elementPaddingFloat)),
                              tl: {
                                hd: Css.top(Css.pxFloat(topFloat - elementPaddingFloat)),
                                tl: {
                                  hd: Css.width(Css.pxFloat(widthFloat + elementPaddingFloat * 2.0)),
                                  tl: {
                                    hd: Css.height(Css.pxFloat(heightFloat + elementPaddingFloat * 2.0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var dropdownAdditionalStyles_0 = Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(8), Css.px(32), undefined, undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.2)));

var dropdownAdditionalStyles = {
  hd: dropdownAdditionalStyles_0,
  tl: /* [] */0
};

var stepWrapperStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(-16)),
        tl: {
          hd: Css.top(Css.px(-22)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.gap(Css.px(4)),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var durationWrapperStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(-12)),
        tl: {
          hd: Css.top(Css.px(-20)),
          tl: /* [] */0
        }
      }
    });

var itemStyles = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.4)),
        tl: /* [] */0
      }
    });

function aboveStyles(topFloat, elementPaddingFloat) {
  return Curry._1(Css.merge, {
              hd: itemStyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.top(Css.px(0)),
                        tl: {
                          hd: Css.width(Css.vw(100.0)),
                          tl: {
                            hd: Css.height(Css.pxFloat(topFloat - elementPaddingFloat)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function leftStyles(topFloat, leftFloat, heightFloat, elementPaddingFloat) {
  return Curry._1(Css.merge, {
              hd: itemStyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.top(Css.pxFloat(topFloat - elementPaddingFloat)),
                        tl: {
                          hd: Css.width(Css.pxFloat(leftFloat - elementPaddingFloat)),
                          tl: {
                            hd: Css.height(Css.pxFloat(heightFloat + elementPaddingFloat * 2.0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function rightStyles(rightFloat, topFloat, heightFloat, elementPaddingFloat) {
  return Curry._1(Css.merge, {
              hd: itemStyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.left(Css.pxFloat(rightFloat + elementPaddingFloat)),
                      tl: {
                        hd: Css.top(Css.pxFloat(topFloat - elementPaddingFloat)),
                        tl: {
                          hd: Css.width({
                                NAME: "subtract",
                                VAL: [
                                  Css.vw(100.0),
                                  Css.pxFloat(rightFloat + elementPaddingFloat)
                                ]
                              }),
                          tl: {
                            hd: Css.height(Css.pxFloat(heightFloat + elementPaddingFloat * 2.0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function bottomStyles(bottomFloat, elementPaddingFloat) {
  return Curry._1(Css.merge, {
              hd: itemStyles,
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.top(Css.pxFloat(bottomFloat + elementPaddingFloat)),
                        tl: {
                          hd: Css.width(Css.vw(100.0)),
                          tl: {
                            hd: Css.height({
                                  NAME: "subtract",
                                  VAL: [
                                    Css.vh(100.0),
                                    Css.pxFloat(bottomFloat - elementPaddingFloat)
                                  ]
                                }),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function cornerBeforeStyles(br, pos) {
  return {
          hd: Css.unsafe("content", "''"),
          tl: {
            hd: Css.display("block"),
            tl: {
              hd: Css.width(Css.pct(50.0)),
              tl: {
                hd: Css.height(Css.pct(50.0)),
                tl: {
                  hd: Css.position("absolute"),
                  tl: {
                    hd: Css.borderRadius(Css.px(br)),
                    tl: pos === "upperLeft" ? ({
                          hd: Css.left(Css.px(0)),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(-50), Css.px(-50), undefined, undefined, undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.4))),
                              tl: /* [] */0
                            }
                          }
                        }) : (
                        pos === "upperRight" ? ({
                              hd: Css.top(Css.px(0)),
                              tl: {
                                hd: Css.right(Css.px(0)),
                                tl: {
                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(50), Css.px(-50), undefined, undefined, undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.4))),
                                  tl: /* [] */0
                                }
                              }
                            }) : (
                            pos === "bottomLeft" ? ({
                                  hd: Css.bottom(Css.px(0)),
                                  tl: {
                                    hd: Css.left(Css.px(0)),
                                    tl: {
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(-50), Css.px(50), undefined, undefined, undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.4))),
                                      tl: /* [] */0
                                    }
                                  }
                                }) : ({
                                  hd: Css.bottom(Css.px(0)),
                                  tl: {
                                    hd: Css.right(Css.px(0)),
                                    tl: {
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(50), Css.px(50), undefined, undefined, undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.4))),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          )
                      )
                  }
                }
              }
            }
          }
        };
}

function cornerStyles(br, leftFloat, rightFloat, topFloat, bottomFloat, elementPaddingFloat, pos) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.pointerEvents("none"),
                tl: {
                  hd: Css.width(Css.px(200)),
                  tl: {
                    hd: Css.height(Css.px(200)),
                    tl: {
                      hd: Css.overflow("hidden"),
                      tl: {
                        hd: Css.before(cornerBeforeStyles(br, pos)),
                        tl: pos === "upperLeft" ? ({
                              hd: Css.left(Css.pxFloat(leftFloat - elementPaddingFloat)),
                              tl: {
                                hd: Css.top(Css.pxFloat(topFloat - elementPaddingFloat)),
                                tl: /* [] */0
                              }
                            }) : (
                            pos === "upperRight" ? ({
                                  hd: Css.left(Css.pxFloat(rightFloat + elementPaddingFloat - 200.0)),
                                  tl: {
                                    hd: Css.top(Css.pxFloat(topFloat - elementPaddingFloat)),
                                    tl: /* [] */0
                                  }
                                }) : (
                                pos === "bottomLeft" ? ({
                                      hd: Css.left(Css.pxFloat(leftFloat - elementPaddingFloat)),
                                      tl: {
                                        hd: Css.top(Css.pxFloat(bottomFloat + elementPaddingFloat - 200.0)),
                                        tl: /* [] */0
                                      }
                                    }) : ({
                                      hd: Css.left(Css.pxFloat(rightFloat + elementPaddingFloat - 200.0)),
                                      tl: {
                                        hd: Css.top(Css.pxFloat(bottomFloat + elementPaddingFloat - 200.0)),
                                        tl: /* [] */0
                                      }
                                    })
                              )
                          )
                      }
                    }
                  }
                }
              }
            });
}

function ProductTour__OnElement$Surrounder(Props) {
  var bottomFloat = Props.bottomFloat;
  var borderRadius = Props.borderRadius;
  var delayedOpened = Props.delayedOpened;
  var elementPaddingFloat = Props.elementPaddingFloat;
  var heightFloat = Props.heightFloat;
  var leftFloat = Props.leftFloat;
  var lockBackground = Props.lockBackground;
  var rightFloat = Props.rightFloat;
  var topFloat = Props.topFloat;
  var getCornerStyles = function (pos) {
    return cornerStyles(borderRadius, leftFloat, rightFloat, topFloat, bottomFloat, elementPaddingFloat, pos);
  };
  return React.createElement(Mantine.Transition.make, {
              transition: "fade",
              mounted: lockBackground && delayedOpened,
              duration: 350,
              children: (function (style) {
                  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                  className: aboveStyles(topFloat, elementPaddingFloat),
                                  style: style
                                }), React.createElement("div", {
                                  className: leftStyles(topFloat, leftFloat, heightFloat, elementPaddingFloat),
                                  style: style
                                }), React.createElement("div", {
                                  className: getCornerStyles("upperLeft"),
                                  style: style
                                }), React.createElement("div", {
                                  className: getCornerStyles("upperRight"),
                                  style: style
                                }), React.createElement("div", {
                                  className: getCornerStyles("bottomLeft"),
                                  style: style
                                }), React.createElement("div", {
                                  className: getCornerStyles("bottomRight"),
                                  style: style
                                }), React.createElement("div", {
                                  className: rightStyles(rightFloat, topFloat, heightFloat, elementPaddingFloat),
                                  style: style
                                }), React.createElement("div", {
                                  className: bottomStyles(bottomFloat, elementPaddingFloat),
                                  style: style
                                }));
                })
            });
}

var Surrounder = {
  itemStyles: itemStyles,
  aboveStyles: aboveStyles,
  leftStyles: leftStyles,
  rightStyles: rightStyles,
  bottomStyles: bottomStyles,
  cornerBeforeStyles: cornerBeforeStyles,
  cornerStyles: cornerStyles,
  make: ProductTour__OnElement$Surrounder
};

function useElementFound(forId, waitForId, opened) {
  var match = React.useState(function (param) {
        return $$Window.$$Document.getElementById(forId);
      });
  var setElement = match[1];
  var element = match[0];
  var match$1 = React.useState(function (param) {
        return Belt_Option.mapWithDefault(waitForId, true, (function (id) {
                      return Belt_Option.isSome($$Window.$$Document.getElementById(id));
                    }));
      });
  var setWaitForElementFound = match$1[1];
  var waitForElementFound = match$1[0];
  var match$2 = React.useState(function (param) {
        return 0;
      });
  var setWaitForElementFinderCounter = match$2[1];
  var waitForElementFinderCounter = match$2[0];
  var match$3 = React.useState(function (param) {
        return 0;
      });
  var setElementFinderCounter = match$3[1];
  var elementFinderCounter = match$3[0];
  React.useEffect((function (param) {
          var timeout = {
            contents: undefined
          };
          var handleFindElement = function (param) {
            Curry._1(setWaitForElementFinderCounter, (function (param) {
                    return waitForElementFinderCounter + 1 | 0;
                  }));
            Curry._1(setWaitForElementFound, (function (param) {
                    return Belt_Option.mapWithDefault(waitForId, true, (function (id) {
                                  return Belt_Option.isSome($$Window.$$Document.getElementById(id));
                                }));
                  }));
          };
          if (!waitForElementFound && opened) {
            timeout.contents = Caml_option.some(setTimeout(handleFindElement, 200));
          }
          return (function (param) {
                    Belt_Option.forEach(timeout.contents, (function (prim) {
                            clearTimeout(prim);
                          }));
                  });
        }), [
        opened,
        waitForElementFound,
        waitForElementFinderCounter
      ]);
  React.useEffect((function (param) {
          var timeout = {
            contents: undefined
          };
          var handleFindElement = function (param) {
            Curry._1(setElementFinderCounter, (function (param) {
                    return elementFinderCounter + 1 | 0;
                  }));
            Curry._1(setElement, (function (param) {
                    return $$Window.$$Document.getElementById(forId);
                  }));
          };
          if (Belt_Option.isNone(element) && opened) {
            timeout.contents = Caml_option.some(setTimeout(handleFindElement, 200));
          }
          return (function (param) {
                    Belt_Option.forEach(timeout.contents, (function (prim) {
                            clearTimeout(prim);
                          }));
                  });
        }), [
        element,
        opened,
        elementFinderCounter
      ]);
  return {
          element: element,
          elementFound: waitForElementFound
        };
}

function useHandleClickOnElement(delayedOpened, opened, forIds, onClick, onOutsideClick) {
  React.useEffect((function (param) {
          var sentOutsideClick = {
            contents: false
          };
          var handleClick = function (e) {
            Belt_Option.forEach(onClick, (function (fn) {
                    Curry._1(fn, e);
                  }));
          };
          var handleOutsideClick = function (e) {
            var clientX = e.clientX;
            var clientY = e.clientY;
            Belt_Array.forEach(forIds, (function (forId) {
                    var el = $$Window.$$Document.getElementById(forId);
                    if (el === undefined) {
                      return ;
                    }
                    var rect = Caml_option.valFromOption(el).getBoundingClientRect();
                    if ((clientX < rect.left || clientX > rect.right || clientY < rect.top || clientY > rect.bottom) && !sentOutsideClick.contents) {
                      Belt_Option.forEach(onOutsideClick, (function (fn) {
                              Curry._1(fn, e);
                            }));
                      sentOutsideClick.contents = true;
                      return ;
                    }
                    
                  }));
            sentOutsideClick.contents = false;
          };
          if (opened) {
            window.addEventListener("click", handleOutsideClick);
          }
          Belt_Array.forEach(forIds, (function (forId) {
                  var el = $$Window.$$Document.getElementById(forId);
                  if (el !== undefined && opened && Belt_Option.isSome(onClick)) {
                    Caml_option.valFromOption(el).addEventListener("click", handleClick);
                    return ;
                  }
                  
                }));
          return (function (param) {
                    window.removeEventListener("click", handleOutsideClick);
                    Belt_Array.forEach(forIds, (function (forId) {
                            var el = $$Window.$$Document.getElementById(forId);
                            if (el !== undefined) {
                              Caml_option.valFromOption(el).removeEventListener("click", handleClick);
                              return ;
                            }
                            
                          }));
                  });
        }), [
        forIds,
        delayedOpened
      ]);
}

function findScrollingParents(element) {
  var _found = [];
  var _element = element;
  while(true) {
    var element$1 = _element;
    var found = _found;
    if (element$1 === undefined) {
      return found;
    }
    var el = Caml_option.valFromOption(element$1);
    if (el.scrollHeight > el.clientHeight) {
      _element = Caml_option.nullable_to_opt(el.parentNode);
      _found = Belt_Array.concat(found, [el]);
      continue ;
    }
    _element = Caml_option.nullable_to_opt(el.parentNode);
    continue ;
  };
}

function useDelayedOpen(forId, waitForId, positionDependencies, opened, delay) {
  var match = React.useState(function (param) {
        return false;
      });
  var setDelayedOpened = match[1];
  var match$1 = useElementFound(forId, waitForId, opened);
  var elementFound = match$1.elementFound;
  var element = match$1.element;
  var match$2 = React.useState(function (param) {
        var el = $$Window.$$Document.getElementById(forId);
        if (el !== undefined) {
          return Caml_option.some(Caml_option.valFromOption(el).getBoundingClientRect());
        }
        
      });
  var setDomRect = match$2[1];
  var domRect = match$2[0];
  var match$3 = React.useState(function (param) {
        return [
                window.innerWidth,
                window.innerHeight
              ];
      });
  var setDimensions = match$3[1];
  var match$4 = match$3[0];
  var windowHeight = match$4[1];
  var windowWidth = match$4[0];
  React.useEffect((function (param) {
          var resizeHandler = function (param) {
            Curry._1(setDimensions, (function (param) {
                    return [
                            window.innerWidth,
                            window.innerHeight
                          ];
                  }));
          };
          if (opened) {
            window.addEventListener("resize", resizeHandler);
          }
          return (function (param) {
                    window.removeEventListener("resize", resizeHandler);
                  });
        }), [opened]);
  React.useEffect((function (param) {
          var scrollingParents = findScrollingParents(element);
          var scrollHandler = function (param) {
            Curry._1(setDomRect, (function (param) {
                    var el = $$Window.$$Document.getElementById(forId);
                    if (el !== undefined) {
                      return Caml_option.some(Caml_option.valFromOption(el).getBoundingClientRect());
                    }
                    
                  }));
          };
          if (opened) {
            Belt_Array.forEach(scrollingParents, (function (scrollingParent) {
                    scrollingParent.addEventListener("scroll", scrollHandler);
                  }));
          }
          return (function (param) {
                    Belt_Array.forEach(scrollingParents, (function (scrollingParent) {
                            scrollingParent.removeEventListener("scroll", scrollHandler);
                          }));
                  });
        }), [
        opened,
        elementFound
      ]);
  React.useEffect((function (param) {
          var timeout = setTimeout((function (param) {
                  Curry._1(setDomRect, (function (param) {
                          var el = $$Window.$$Document.getElementById(forId);
                          if (el !== undefined) {
                            return Caml_option.some(Caml_option.valFromOption(el).getBoundingClientRect());
                          }
                          
                        }));
                  Curry._1(setDelayedOpened, (function (param) {
                          if (elementFound) {
                            return opened;
                          } else {
                            return false;
                          }
                        }));
                }), delay);
          return (function (param) {
                    clearTimeout(timeout);
                  });
        }), [
        opened,
        delay,
        element,
        elementFound,
        windowWidth,
        windowHeight,
        positionDependencies
      ]);
  var positionDependencies$1 = React.useMemo((function (param) {
          return Belt_Array.concat([
                      String(Belt_Option.mapWithDefault(domRect, 0.0, (function (prim) {
                                  return prim.left;
                                }))),
                      String(Belt_Option.mapWithDefault(domRect, 0.0, (function (prim) {
                                  return prim.top;
                                }))),
                      String(Belt_Option.mapWithDefault(domRect, 0.0, (function (prim) {
                                  return prim.width;
                                }))),
                      String(Belt_Option.mapWithDefault(domRect, 0.0, (function (prim) {
                                  return prim.height;
                                })))
                    ], Belt_Option.getWithDefault(positionDependencies, []));
        }), [
        domRect,
        windowWidth,
        windowHeight
      ]);
  return {
          delayedOpened: match[0],
          positionDependencies: positionDependencies$1,
          domRect: domRect
        };
}

function ProductTour__OnElement(Props) {
  var arrowOffset = Props.arrowOffset;
  var borderRadiusOpt = Props.borderRadius;
  var delayOpt = Props.delay;
  var elementClickableOpt = Props.elementClickable;
  var elementPadding = Props.elementPadding;
  var forId = Props.forId;
  var forIds = Props.forIds;
  var lockBackgroundOpt = Props.lockBackground;
  var offset = Props.offset;
  var onClick = Props.onClick;
  var onOutsideClick = Props.onOutsideClick;
  var opened = Props.opened;
  var position = Props.position;
  var positionDependencies = Props.positionDependencies;
  var onStepActive = Props.onStepActive;
  var simulateClick = Props.simulateClick;
  var transition = Props.transition;
  var transitionDuration = Props.transitionDuration;
  var transitionTimingFunction = Props.transitionTimingFunction;
  var waitForId = Props.waitForId;
  var withOutlineOpt = Props.withOutline;
  var children = Props.children;
  var borderRadius = borderRadiusOpt !== undefined ? borderRadiusOpt : 0;
  var delay = delayOpt !== undefined ? delayOpt : 300;
  var elementClickable = elementClickableOpt !== undefined ? elementClickableOpt : true;
  var lockBackground = lockBackgroundOpt !== undefined ? lockBackgroundOpt : true;
  var withOutline = withOutlineOpt !== undefined ? withOutlineOpt : true;
  React.useEffect((function (param) {
          if (opened) {
            Belt_Option.forEach(onStepActive, (function (fn) {
                    Curry._1(fn, undefined);
                  }));
          }
          
        }), []);
  var match = useDelayedOpen(forId, waitForId, positionDependencies, opened, delay);
  var domRect = match.domRect;
  var delayedOpened = match.delayedOpened;
  useHandleClickOnElement(delayedOpened, opened, Belt_Option.getWithDefault(forIds, [forId]), onClick, onOutsideClick);
  var elementPaddingFloat = Belt_Option.mapWithDefault(elementPadding, 0.0, (function (prim) {
          return prim;
        }));
  if (domRect === undefined) {
    return null;
  }
  var domRect$1 = Caml_option.valFromOption(domRect);
  var topFloat = domRect$1.top;
  var bottomFloat = domRect$1.bottom;
  var leftFloat = domRect$1.left;
  var rightFloat = domRect$1.right;
  var heightFloat = domRect$1.height;
  var widthFloat = domRect$1.width;
  if (!opened) {
    return null;
  }
  var tmp = {
    arrowSize: 10,
    opened: delayedOpened,
    positionDependencies: match.positionDependencies,
    withinPortal: true,
    withArrow: true,
    children: null
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (transition !== undefined) {
    tmp.transition = Caml_option.valFromOption(transition);
  }
  if (transitionDuration !== undefined) {
    tmp.transitionDuration = Caml_option.valFromOption(transitionDuration);
  }
  if (transitionTimingFunction !== undefined) {
    tmp.transitionTimingFunction = Caml_option.valFromOption(transitionTimingFunction);
  }
  return React.createElement(Portal.make, {
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.children({
                                hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        })
                  }, React.createElement(ProductTour__OnElement$Surrounder, {
                        bottomFloat: bottomFloat,
                        borderRadius: borderRadius,
                        delayedOpened: delayedOpened,
                        elementPaddingFloat: elementPaddingFloat,
                        heightFloat: heightFloat,
                        leftFloat: leftFloat,
                        lockBackground: lockBackground,
                        rightFloat: rightFloat,
                        topFloat: topFloat
                      }), React.createElement(Mantine.Popover.make, tmp, React.createElement(Core.Popover.Target, {
                            children: React.createElement("div", {
                                  className: targetWrapperStyles(elementClickable, leftFloat, topFloat, widthFloat, heightFloat, elementPaddingFloat, withOutline, opened, borderRadius)
                                }, simulateClick !== undefined ? React.createElement(ProductTour__ClickSimulator.make, {
                                        pos: simulateClick
                                      }) : null)
                          }), React.createElement(Mantine.Popover.Dropdown.make, {
                            additionalStyles: dropdownAdditionalStyles,
                            children: children
                          })))
            });
}

var ClickSimulator;

var make = ProductTour__OnElement;

export {
  ClickSimulator ,
  getPulseKeyframeStyle ,
  buttonWrapper ,
  targetWrapperStyles ,
  dropdownAdditionalStyles ,
  stepWrapperStyles ,
  durationWrapperStyles ,
  Surrounder ,
  useElementFound ,
  useHandleClickOnElement ,
  findScrollingParents ,
  useDelayedOpen ,
  make ,
}
/* dropdownAdditionalStyles Not a pure module */
