// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InspectorIssueStatus from "./InspectorIssueStatus.mjs";
import * as InspectorIssuesFilter from "./InspectorIssuesFilter.mjs";

var SavedView = {};

function encode(t) {
  return {
          id: t.id,
          name: t.name,
          createdBy: t.createdBy,
          createdAt: t.createdAt,
          lastModifiedBy: Js_null.fromOption(t.lastModifiedBy),
          lastModifiedAt: Js_null.fromOption(t.lastModifiedAt),
          filters: Belt_Array.map(t.filters, InspectorIssuesFilter.Filter.toKeyValue),
          order: Js_null.fromOption(t.order),
          isArchived: t.isArchived
        };
}

function decode(data) {
  return {
          id: data.id,
          name: data.name,
          createdBy: data.createdBy,
          createdAt: data.createdAt.toDate(),
          lastModifiedBy: Caml_option.null_to_opt(data.lastModifiedBy),
          lastModifiedAt: Belt_Option.map(Caml_option.null_to_opt(data.lastModifiedAt), (function (prim) {
                  return prim.toDate();
                })),
          filters: Belt_Array.keepMap(data.filters, InspectorIssuesFilter.Filter.fromString),
          order: Caml_option.null_to_opt(data.order),
          isArchived: data.isArchived
        };
}

var allIssuesViewId = "all-issues";

var allIssuesView_createdAt = new Date("2024-01-03 16:00:00 GMT");

var allIssuesView_filters = [];

var allIssuesView = {
  id: allIssuesViewId,
  name: "All Issues",
  createdBy: InspectorIssueStatus.updatedBySystem,
  createdAt: allIssuesView_createdAt,
  lastModifiedBy: undefined,
  lastModifiedAt: undefined,
  filters: allIssuesView_filters,
  order: undefined,
  isArchived: false
};

function isAllIssuesView(savedView) {
  return savedView.id === allIssuesViewId;
}

function getViewUrl(schemaId, savedView) {
  if (savedView.id === allIssuesViewId) {
    return "https://www.avo.app/schemas/" + schemaId + "/inspector/issues";
  } else {
    return "https://www.avo.app/schemas/" + schemaId + "/inspector/issues/view/" + savedView.id;
  }
}

export {
  SavedView ,
  encode ,
  decode ,
  allIssuesViewId ,
  allIssuesView ,
  isAllIssuesView ,
  getViewUrl ,
}
/* allIssuesView Not a pure module */
