// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Nanoid from "nanoid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../../shared/utils/NameUtils.mjs";
import * as StringExt from "../StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ImportFormats from "./ImportFormats.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ImportParserTypes from "./ImportParserTypes.mjs";
import * as BeltListExtensions from "../BeltListExtensions.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as ImportParserFiltering from "./ImportParserFiltering.mjs";
import * as ImportParserUniqueIndexes from "./ImportParserUniqueIndexes.mjs";
import * as Sync from "csv-parse/browser/esm/sync";
import * as ImportParserProcessRawOutputUtils from "./ImportParserProcessRawOutputUtils.mjs";

function getColumnConfig(param) {
  return Belt_Array.reduce([
              ImportParserTypes.StringOp.getColumnPresence(param.eventNameParser),
              ImportParserTypes.StringOp.getColumnPresence(param.eventDescriptionParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.eventCategoriesParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.eventTagsParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.eventSourcesParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.eventStakeholderDomainsParser),
              ImportParserTypes.StringTupleOp.getColumnPresence(param.eventNameMappingParser),
              ImportParserTypes.StringOp.getColumnPresence(param.eventPropertyBundleNameParser),
              ImportParserTypes.IntOp.getColumnPresence(param.eventVersionParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyNameParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyDescriptionParser),
              ImportParserTypes.StringTupleOp.getColumnPresence(param.propertyTypeParser),
              ImportParserTypes.BoolOp.getColumnPresence(param.propertyRequiredParser),
              ImportParserTypes.BoolOp.getColumnPresence(param.propertyListParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.propertyMatchesParser),
              ImportParserTypes.SendAsOp.getColumnPresence(param.propertySendAsParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyPropertyBundleNameParser),
              ImportParserTypes.StringArrayOp.getColumnPresence(param.propertyEventsParser),
              ImportParserTypes.StringTupleOp.getColumnPresence(param.propertyNameMappingParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyPinnedValueParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyBundleRowNameParser),
              ImportParserTypes.StringOp.getColumnPresence(param.propertyBundleRowDescriptionParser)
            ], {
              requiredColumnNames: [],
              optionalColumnNames: [],
              optionalColumnNameRegex: [],
              allColumnNames: []
            }, (function (columnConfig, columnPresence) {
                if (typeof columnPresence !== "object") {
                  return columnConfig;
                }
                var variant = columnPresence.NAME;
                if (variant === "requiredColumnNames") {
                  var columnNames = columnPresence.VAL;
                  return {
                          requiredColumnNames: Belt_Array.concat(columnConfig.requiredColumnNames, columnNames),
                          optionalColumnNames: columnConfig.optionalColumnNames,
                          optionalColumnNameRegex: columnConfig.optionalColumnNameRegex,
                          allColumnNames: Belt_Array.concat(columnConfig.allColumnNames, columnNames)
                        };
                }
                if (variant === "optionalColumnNameRegex") {
                  var columnNameRegex = columnPresence.VAL;
                  return {
                          requiredColumnNames: columnConfig.requiredColumnNames,
                          optionalColumnNames: columnConfig.optionalColumnNames,
                          optionalColumnNameRegex: Belt_Array.concat(columnConfig.optionalColumnNameRegex, columnNameRegex),
                          allColumnNames: Belt_Array.concat(columnConfig.allColumnNames, columnNameRegex)
                        };
                }
                var columnNames$1 = columnPresence.VAL;
                return {
                        requiredColumnNames: columnConfig.requiredColumnNames,
                        optionalColumnNames: Belt_Array.concat(columnConfig.optionalColumnNames, columnNames$1),
                        optionalColumnNameRegex: columnConfig.optionalColumnNameRegex,
                        allColumnNames: Belt_Array.concat(columnConfig.allColumnNames, columnNames$1)
                      };
              }));
}

function parseFormat($staropt$star, format, csv) {
  var customParser = format.customParser;
  var propertyBundleRowDescriptionParser = format.propertyBundleRowDescriptionParser;
  var propertyBundleRowNameParser = format.propertyBundleRowNameParser;
  var propertyPinnedValueParser = format.propertyPinnedValueParser;
  var propertyNameMappingParser = format.propertyNameMappingParser;
  var propertyEventsParser = format.propertyEventsParser;
  var propertyPropertyBundleNameParser = format.propertyPropertyBundleNameParser;
  var propertySendAsParser = format.propertySendAsParser;
  var propertyMatchesParser = format.propertyMatchesParser;
  var propertyListParser = format.propertyListParser;
  var propertyRequiredParser = format.propertyRequiredParser;
  var propertyTypeParser = format.propertyTypeParser;
  var propertyDescriptionParser = format.propertyDescriptionParser;
  var propertyNameParser = format.propertyNameParser;
  var eventVersionParser = format.eventVersionParser;
  var eventPropertyBundleNameParser = format.eventPropertyBundleNameParser;
  var eventNameMappingParser = format.eventNameMappingParser;
  var eventStakeholderDomainsParser = format.eventStakeholderDomainsParser;
  var eventSourcesParser = format.eventSourcesParser;
  var eventTagsParser = format.eventTagsParser;
  var eventCategoriesParser = format.eventCategoriesParser;
  var eventDescriptionParser = format.eventDescriptionParser;
  var eventNameParser = format.eventNameParser;
  var formatName = format.formatName;
  var generateId = $staropt$star !== undefined ? $staropt$star : (function (prim) {
        return Nanoid.nanoid();
      });
  var match = getColumnConfig(format);
  var allColumnNames = match.allColumnNames;
  var requiredColumnNames = match.requiredColumnNames;
  var headerRowIndex = Belt_Array.getIndexByU(csv, (function (row) {
          return Belt_Array.everyU(requiredColumnNames, (function (columnName) {
                        return Belt_Array.someU(row, (function (cell) {
                                      return cell.toLowerCase() === columnName.toLowerCase();
                                    }));
                      }));
        }));
  if (headerRowIndex !== undefined) {
    var headerRow = Belt_Array.getExn(csv, headerRowIndex);
    var columnIndexesFromNames = Belt_Array.keepMapU(allColumnNames, (function (columnName) {
            var maybeColumnIndex = Belt_Array.getIndexByU(headerRow, (function (cell) {
                    return cell.toLowerCase() === columnName.toLowerCase();
                  }));
            if (maybeColumnIndex !== undefined) {
              return [
                      columnName,
                      maybeColumnIndex
                    ];
            }
            
          }));
    var columnIndexesFromRegex = Belt_Array.keepMapU(match.optionalColumnNameRegex, (function (pattern) {
            var maybeColumnIndex = Belt_Array.getIndexByU(headerRow, (function (cell) {
                    var regex = new RegExp(pattern);
                    return regex.test(cell);
                  }));
            if (maybeColumnIndex !== undefined) {
              return [
                      Belt_Option.getExn(Belt_Array.get(headerRow, maybeColumnIndex)),
                      maybeColumnIndex
                    ];
            }
            
          }));
    var columnIndexes = Belt_MapString.fromArray(Belt_Array.concat(columnIndexesFromNames, columnIndexesFromRegex));
    var parseCategory = function (row, rowIndex) {
      return ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventCategoriesParser);
    };
    var parsePropertyBundle = function (row, rowIndex) {
      return {
              name: NameUtils.replaceNewlinesWithWhitespace(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyBundleRowNameParser)),
              description: StringExt.normalizeNewlines(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyBundleRowDescriptionParser))
            };
    };
    var parseEventPropertyBundleName = function (row, rowIndex) {
      return NameUtils.replaceNewlinesWithWhitespace(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, eventPropertyBundleNameParser));
    };
    var parseEvent = function (row, rowIndex) {
      var match = ImportParserTypes.StringTupleOp.parse(row, rowIndex, columnIndexes, eventNameMappingParser);
      var sendAsName = match[0];
      return {
              id: Curry._1(generateId, undefined),
              name: NameUtils.replaceNewlinesWithWhitespace(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, eventNameParser)),
              description: StringExt.normalizeNewlines(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, eventDescriptionParser)),
              categories: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventCategoriesParser),
              tags: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventTagsParser),
              properties: [],
              propertyBundleNames: [],
              sources: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventSourcesParser),
              stakeholderDomains: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, eventStakeholderDomainsParser),
              nameMapping: sendAsName !== "" ? [[
                    NameUtils.replaceNewlinesWithWhitespace(sendAsName),
                    match[1]
                  ]] : [],
              version: ImportParserTypes.IntOp.parse(row, rowIndex, columnIndexes, eventVersionParser)
            };
    };
    var parseProperty = function (row, rowIndex) {
      var match = ImportParserTypes.StringTupleOp.parse(row, rowIndex, columnIndexes, propertyTypeParser);
      var type_ = match[0];
      var match$1 = ImportParserTypes.StringTupleOp.parse(row, rowIndex, columnIndexes, propertyNameMappingParser);
      var sendAsName = match$1[0];
      var rawPinnedValue = ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyPinnedValueParser);
      var pinnedValue;
      if (rawPinnedValue === "") {
        pinnedValue = undefined;
      } else {
        switch (type_) {
          case "bool" :
              pinnedValue = Belt_Option.map(Pervasives.bool_of_string_opt(rawPinnedValue), (function (bool) {
                      return {
                              NAME: "BooleanLit",
                              VAL: bool
                            };
                    }));
              break;
          case "float" :
              pinnedValue = Belt_Option.map(Pervasives.float_of_string_opt(rawPinnedValue), (function ($$float) {
                      return {
                              NAME: "FloatLit",
                              VAL: $$float
                            };
                    }));
              break;
          case "int" :
              pinnedValue = Belt_Option.map(Pervasives.int_of_string_opt(rawPinnedValue), (function ($$int) {
                      return {
                              NAME: "IntLit",
                              VAL: $$int
                            };
                    }));
              break;
          case "any" :
          case "string" :
              pinnedValue = {
                NAME: "StringLit",
                VAL: rawPinnedValue
              };
              break;
          default:
            pinnedValue = undefined;
        }
      }
      return {
              name: NameUtils.replaceNewlinesWithWhitespace(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyNameParser)),
              description: StringExt.normalizeNewlines(ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyDescriptionParser)),
              type_: type_,
              typeWarning: match[1],
              required: ImportParserTypes.BoolOp.parse(row, rowIndex, columnIndexes, propertyRequiredParser),
              list: ImportParserTypes.BoolOp.parse(row, rowIndex, columnIndexes, propertyListParser),
              matches: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, propertyMatchesParser),
              sendAs: ImportParserTypes.SendAsOp.parse(row, rowIndex, columnIndexes, propertySendAsParser),
              propertyBundle: {
                name: ImportParserTypes.StringOp.parse(row, rowIndex, columnIndexes, propertyPropertyBundleNameParser),
                description: ""
              },
              propertyEvents: ImportParserTypes.StringArrayOp.parse(row, rowIndex, columnIndexes, propertyEventsParser),
              nameMapping: sendAsName !== "" ? [
                  NameUtils.replaceNewlinesWithWhitespace(sendAsName),
                  match$1[1]
                ] : undefined,
              pinnedValue: pinnedValue
            };
    };
    var match$1 = Belt_Array.reduceWithIndex(Belt_Array.sliceToEnd(csv, headerRowIndex + 1 | 0), [
          [],
          [],
          [],
          [],
          undefined,
          undefined,
          undefined,
          undefined
        ], (function (param, row, rowIndex) {
            var currentVersion = param[6];
            var currentCategories = param[4];
            var warnings = param[3];
            var propertyBundles = param[2];
            var propertiesWithoutEvent = param[1];
            var events = param[0];
            var rowIndex$1 = (headerRowIndex + rowIndex | 0) + 2 | 0;
            var rowString = row.join(", ");
            var maybeParsedCategory;
            try {
              var parsedCategory = parseCategory(row, rowIndex$1);
              maybeParsedCategory = Caml_obj.equal(parsedCategory, []) ? ({
                    TAG: "Error",
                    _0: "Categories not found in row " + String(rowIndex$1)
                  }) : ({
                    TAG: "Ok",
                    _0: parsedCategory
                  });
            }
            catch (raw_error){
              var error = Caml_js_exceptions.internalToOCamlException(raw_error);
              maybeParsedCategory = error.RE_EXN_ID === ImportParserTypes.ParseWarning ? ({
                    TAG: "Error",
                    _0: error._1
                  }) : ({
                    TAG: "Error",
                    _0: error
                  });
            }
            var maybeParsedEventPropertyBundleName;
            try {
              var parsedEventPropertyBundleName = parseEventPropertyBundleName(row, rowIndex$1);
              maybeParsedEventPropertyBundleName = parsedEventPropertyBundleName === "" ? ({
                    TAG: "Error",
                    _0: "Event property bundle name not found in row " + String(rowIndex$1)
                  }) : ({
                    TAG: "Ok",
                    _0: parsedEventPropertyBundleName
                  });
            }
            catch (raw_error$1){
              var error$1 = Caml_js_exceptions.internalToOCamlException(raw_error$1);
              maybeParsedEventPropertyBundleName = error$1.RE_EXN_ID === ImportParserTypes.ParseWarning ? ({
                    TAG: "Error",
                    _0: error$1._1
                  }) : ({
                    TAG: "Error",
                    _0: error$1
                  });
            }
            var maybeParsedPropertyBundle;
            try {
              var parsedPropertyBundle = parsePropertyBundle(row, rowIndex$1);
              maybeParsedPropertyBundle = parsedPropertyBundle.name === "" ? ({
                    TAG: "Error",
                    _0: "Property bundle not found in row " + String(rowIndex$1)
                  }) : ({
                    TAG: "Ok",
                    _0: parsedPropertyBundle
                  });
            }
            catch (raw_error$2){
              var error$2 = Caml_js_exceptions.internalToOCamlException(raw_error$2);
              maybeParsedPropertyBundle = error$2.RE_EXN_ID === ImportParserTypes.ParseWarning ? ({
                    TAG: "Error",
                    _0: error$2._1
                  }) : ({
                    TAG: "Error",
                    _0: error$2
                  });
            }
            var propertyBundles$1;
            propertyBundles$1 = maybeParsedPropertyBundle.TAG === "Ok" ? Belt_Array.concat(propertyBundles, [maybeParsedPropertyBundle._0]) : propertyBundles;
            var maybeParsedEvent;
            try {
              var parsedEvent = parseEvent(row, rowIndex$1);
              maybeParsedEvent = {
                TAG: "Ok",
                _0: parsedEvent
              };
            }
            catch (raw_error$3){
              var error$3 = Caml_js_exceptions.internalToOCamlException(raw_error$3);
              maybeParsedEvent = error$3.RE_EXN_ID === ImportParserTypes.ParseWarning ? ({
                    TAG: "Error",
                    _0: error$3._1
                  }) : ({
                    TAG: "Error",
                    _0: error$3
                  });
            }
            var currentPropertyBundle;
            currentPropertyBundle = maybeParsedPropertyBundle.TAG === "Ok" ? (
                maybeParsedEvent.TAG === "Ok" ? undefined : maybeParsedPropertyBundle._0
              ) : (
                maybeParsedEvent.TAG === "Ok" ? undefined : param[7]
              );
            var currentEventName = currentPropertyBundle !== undefined ? undefined : param[5];
            var maybeParsedProperty;
            try {
              var parserProperty = parseProperty(row, rowIndex$1);
              var parsedProperty_name = parserProperty.name;
              var parsedProperty_description = parserProperty.description;
              var parsedProperty_type_ = parserProperty.type_;
              var parsedProperty_typeWarning = parserProperty.typeWarning;
              var parsedProperty_required = parserProperty.required;
              var parsedProperty_list = parserProperty.list;
              var parsedProperty_matches = parserProperty.matches;
              var parsedProperty_sendAs = parserProperty.sendAs;
              var parsedProperty_propertyBundle = Belt_Option.getWithDefault(currentPropertyBundle, parserProperty.propertyBundle);
              var parsedProperty_propertyEvents = parserProperty.propertyEvents;
              var parsedProperty_nameMapping = parserProperty.nameMapping;
              var parsedProperty_pinnedValue = parserProperty.pinnedValue;
              var parsedProperty = {
                name: parsedProperty_name,
                description: parsedProperty_description,
                type_: parsedProperty_type_,
                typeWarning: parsedProperty_typeWarning,
                required: parsedProperty_required,
                list: parsedProperty_list,
                matches: parsedProperty_matches,
                sendAs: parsedProperty_sendAs,
                propertyBundle: parsedProperty_propertyBundle,
                propertyEvents: parsedProperty_propertyEvents,
                nameMapping: parsedProperty_nameMapping,
                pinnedValue: parsedProperty_pinnedValue
              };
              maybeParsedProperty = {
                TAG: "Ok",
                _0: parsedProperty
              };
            }
            catch (raw_error$4){
              var error$4 = Caml_js_exceptions.internalToOCamlException(raw_error$4);
              maybeParsedProperty = error$4.RE_EXN_ID === ImportParserTypes.ParseWarning ? ({
                    TAG: "Error",
                    _0: error$4._1
                  }) : ({
                    TAG: "Error",
                    _0: error$4
                  });
            }
            var match;
            var exit = 0;
            if (maybeParsedCategory.TAG === "Ok" || maybeParsedEvent.TAG === "Ok" || maybeParsedProperty.TAG === "Ok" || maybeParsedPropertyBundle.TAG === "Ok" || maybeParsedEventPropertyBundleName.TAG === "Ok") {
              exit = 1;
            } else {
              var nextWarnings = Belt_Array.concat(warnings, [String(rowIndex$1) + ": Could not parse row: " + rowString]);
              match = [
                events,
                propertiesWithoutEvent,
                propertyBundles$1,
                nextWarnings,
                currentCategories,
                currentEventName,
                currentVersion,
                currentPropertyBundle
              ];
            }
            if (exit === 1) {
              var currentCategories$1;
              currentCategories$1 = maybeParsedCategory.TAG === "Ok" ? maybeParsedCategory._0 : currentCategories;
              var match$1;
              if (maybeParsedEvent.TAG === "Ok") {
                var parsedEvent$1 = maybeParsedEvent._0;
                var existingOutputEvent = Belt_Array.getByU(events, (function (existingOutputEvent) {
                        if (existingOutputEvent.name === parsedEvent$1.name) {
                          return existingOutputEvent.version === parsedEvent$1.version;
                        } else {
                          return false;
                        }
                      }));
                match$1 = existingOutputEvent !== undefined ? [
                    events,
                    existingOutputEvent.name,
                    existingOutputEvent.version
                  ] : [
                    Belt_Array.concat(events, [{
                            id: parsedEvent$1.id,
                            name: parsedEvent$1.name,
                            description: parsedEvent$1.description,
                            categories: Belt_Option.getWithDefault(currentCategories$1, []),
                            tags: parsedEvent$1.tags,
                            properties: parsedEvent$1.properties,
                            propertyBundleNames: parsedEvent$1.propertyBundleNames,
                            sources: parsedEvent$1.sources,
                            stakeholderDomains: parsedEvent$1.stakeholderDomains,
                            nameMapping: parsedEvent$1.nameMapping,
                            version: parsedEvent$1.version
                          }]),
                    parsedEvent$1.name,
                    parsedEvent$1.version
                  ];
              } else {
                match$1 = [
                  events,
                  currentEventName,
                  currentVersion
                ];
              }
              var currentVersion$1 = match$1[2];
              var currentEventName$1 = match$1[1];
              var events$1 = match$1[0];
              var match$2;
              var exit$1 = 0;
              if (currentEventName$1 !== undefined) {
                if (maybeParsedProperty.TAG === "Ok") {
                  exit$1 = 2;
                } else {
                  match$2 = maybeParsedCategory.TAG === "Ok" || maybeParsedEvent.TAG === "Ok" || maybeParsedEventPropertyBundleName.TAG === "Ok" ? [
                      events$1,
                      propertiesWithoutEvent,
                      warnings
                    ] : Pervasives.failwith("we should never end up in this property case?");
                }
              } else if (maybeParsedProperty.TAG === "Ok") {
                exit$1 = 2;
              } else {
                match$2 = [
                  events$1,
                  propertiesWithoutEvent,
                  warnings
                ];
              }
              if (exit$1 === 2) {
                if (maybeParsedProperty._0.name === "") {
                  match$2 = [
                    events$1,
                    propertiesWithoutEvent,
                    warnings
                  ];
                } else if (currentEventName$1 !== undefined) {
                  var parsedProperty$1 = maybeParsedProperty._0;
                  match$2 = [
                    Belt_Array.mapU(events$1, (function ($$event) {
                            if ($$event.name === currentEventName$1 && $$event.version === currentVersion$1) {
                              return {
                                      id: $$event.id,
                                      name: $$event.name,
                                      description: $$event.description,
                                      categories: $$event.categories,
                                      tags: $$event.tags,
                                      properties: Belt_Array.concat($$event.properties, [parsedProperty$1]),
                                      propertyBundleNames: $$event.propertyBundleNames,
                                      sources: $$event.sources,
                                      stakeholderDomains: $$event.stakeholderDomains,
                                      nameMapping: $$event.nameMapping,
                                      version: $$event.version
                                    };
                            } else {
                              return $$event;
                            }
                          })),
                    propertiesWithoutEvent,
                    warnings
                  ];
                } else {
                  var propertiesWithoutEvent$1 = Belt_Array.concat(propertiesWithoutEvent, [maybeParsedProperty._0]);
                  match$2 = [
                    events$1,
                    propertiesWithoutEvent$1,
                    warnings
                  ];
                }
              }
              var events$2 = match$2[0];
              var events$3;
              if (currentEventName$1 !== undefined && maybeParsedEventPropertyBundleName.TAG === "Ok") {
                var propertyBundleName = maybeParsedEventPropertyBundleName._0;
                events$3 = Belt_Array.mapU(events$2, (function ($$event) {
                        if ($$event.name === currentEventName$1 && $$event.version === currentVersion$1) {
                          return {
                                  id: $$event.id,
                                  name: $$event.name,
                                  description: $$event.description,
                                  categories: $$event.categories,
                                  tags: $$event.tags,
                                  properties: $$event.properties,
                                  propertyBundleNames: Belt_Array.concat($$event.propertyBundleNames, [propertyBundleName]),
                                  sources: $$event.sources,
                                  stakeholderDomains: $$event.stakeholderDomains,
                                  nameMapping: $$event.nameMapping,
                                  version: $$event.version
                                };
                        } else {
                          return $$event;
                        }
                      }));
              } else {
                events$3 = events$2;
              }
              match = [
                events$3,
                match$2[1],
                propertyBundles$1,
                match$2[2],
                currentCategories$1,
                currentEventName$1,
                currentVersion$1,
                currentPropertyBundle
              ];
            }
            return [
                    match[0],
                    match[1],
                    match[2],
                    match[3],
                    match[4],
                    match[5],
                    match[6],
                    match[7]
                  ];
          }));
    var events = match$1[0];
    var eventNamesOrder = Belt_Array.map(events, (function ($$event) {
            return $$event.name;
          }));
    var events$1 = Belt_Array.keepMapU(Belt_MapString.toArray(Belt_Array.reduce(events, undefined, (function (eventMap, $$event) {
                      return Belt_MapString.update(eventMap, $$event.name, (function (maybeExistingEvents) {
                                    return Belt_Array.concat(Belt_Option.getWithDefault(maybeExistingEvents, []), [$$event]);
                                  }));
                    }))), (function (param) {
              return Belt_Array.get(param[1].sort(function (a, b) {
                              return Caml.int_compare(b.version, a.version);
                            }), 0);
            })).sort(function (a, b) {
          return Caml.int_compare(eventNamesOrder.indexOf(a.name), eventNamesOrder.indexOf(b.name));
        });
    var output_propertiesWithoutEvent = match$1[1];
    var output_warnings = match$1[3];
    var output_propertyBundles = match$1[2];
    var output = {
      formatName: formatName,
      events: events$1,
      propertiesWithoutEvent: output_propertiesWithoutEvent,
      warnings: output_warnings,
      propertyBundles: output_propertyBundles
    };
    if (customParser !== undefined) {
      return Curry._2(customParser, output, csv);
    } else {
      return output;
    }
  }
  var maybeFoundColumns = Belt_Option.flatMap(Belt_Array.get(Belt_Array.mapU(csv, (function (row) {
                    return Belt_Array.keepU(row, (function (cell) {
                                  return Belt_Array.someU(requiredColumnNames, (function (columnName) {
                                                return cell.toLowerCase() === columnName.toLowerCase();
                                              }));
                                }));
                  })).sort(function (a, b) {
                return Caml.int_compare(b.length, a.length);
              }), 0), (function (foundColumns) {
          if (Caml_obj.equal(foundColumns, [])) {
            return ;
          } else {
            return foundColumns;
          }
        }));
  var missingColumns = Belt_Option.mapWithDefault(maybeFoundColumns, requiredColumnNames, (function (foundColumns) {
          return Belt_Array.keepU(allColumnNames, (function (columnName) {
                        return !Belt_Array.someU(foundColumns, (function (foundColumn) {
                                      return foundColumn.toLowerCase() === columnName.toLowerCase();
                                    }));
                      }));
        }));
  throw {
        RE_EXN_ID: ImportParserTypes.ParseFormatError,
        _1: {
          foundColumns: Belt_Option.mapWithDefault(maybeFoundColumns, 0, (function (prim) {
                  return prim.length;
                })),
          missingColumns: missingColumns.length,
          message: "Missing expected columns: " + missingColumns.join(", ") + Belt_Option.mapWithDefault(maybeFoundColumns, "", (function (foundColumns) {
                  return ".\nExpected columns found in file: " + foundColumns.join(", ") + ".";
                })),
          name: formatName
        },
        Error: new Error()
      };
}

function oneOfFormats(formats, csv) {
  var _formats = formats;
  var _errors = /* [] */0;
  while(true) {
    var errors = _errors;
    var formats$1 = _formats;
    if (formats$1) {
      try {
        return parseFormat(undefined, formats$1.hd, csv);
      }
      catch (raw_e){
        var e = Caml_js_exceptions.internalToOCamlException(raw_e);
        if (e.RE_EXN_ID === ImportParserTypes.ParseFormatError) {
          _errors = {
            hd: e._1,
            tl: errors
          };
          _formats = formats$1.tl;
          continue ;
        }
        throw e;
      }
    } else {
      var errors$1 = Belt_List.sortU(errors, (function (a, b) {
              return Caml.int_compare(b.foundColumns, a.foundColumns);
            }));
      throw {
            RE_EXN_ID: ImportParserTypes.ParseError,
            _1: Belt_Array.map(Belt_List.toArray(errors$1), (function (error) {
                    return [
                            error.name,
                            error.message
                          ];
                  })),
            Error: new Error()
          };
    }
  };
}

function processRawOutput(generateIdOpt, model, rawParserOutput, importMethod) {
  var generateId = generateIdOpt !== undefined ? generateIdOpt : (function (prim) {
        return Nanoid.nanoid();
      });
  var rawEvents = rawParserOutput.events;
  var dedupedPropertiesWithoutEvents = ImportParserProcessRawOutputUtils.appendRawPropertiesWithDedupe([], generateId, rawParserOutput.formatName, importMethod, Belt_Array.map(rawParserOutput.propertiesWithoutEvent, (function (property) {
              return [
                      undefined,
                      property
                    ];
            })));
  var globalProperties = ImportParserProcessRawOutputUtils.appendRawPropertiesWithDedupe(dedupedPropertiesWithoutEvents, generateId, rawParserOutput.formatName, importMethod, Belt_Array.concatMany(Belt_Array.mapU(rawEvents, (function ($$event) {
                  return Belt_Array.mapU($$event.properties, (function (property) {
                                return [
                                        $$event,
                                        property
                                      ];
                              }));
                }))));
  var globalPropertyBundles = Belt_Array.mapU(Belt_MapString.toArray(Belt_Array.reduce(Belt_Array.concat(Belt_Array.keepMap(rawParserOutput.propertiesWithoutEvent, (function (property) {
                          return ImportParserProcessRawOutputUtils.findPropertyBundleAndGlobalPropertyForRawProperty(globalProperties, property);
                        })), Belt_Array.concatMany(Belt_Array.map(rawEvents, (function ($$event) {
                              return Belt_Array.keepMap($$event.properties, (function (property) {
                                            return ImportParserProcessRawOutputUtils.findPropertyBundleAndGlobalPropertyForRawProperty(globalProperties, property);
                                          }));
                            })))), undefined, (function (map, param) {
                  var propertyBundle = param[0];
                  var match = Belt_MapString.getWithDefault(map, propertyBundle.name, [
                        undefined,
                        []
                      ]);
                  var nextProperties = match[1];
                  nextProperties.push(param[1]);
                  return Belt_MapString.set(map, propertyBundle.name, [
                              propertyBundle,
                              nextProperties
                            ]);
                }))), (function (param) {
          var match = param[1];
          var properties = match[1];
          var propertyBundleName = param[0];
          var parsedPropertyBundleDescription = Belt_Option.mapWithDefault(match[0], "", (function (parsedPropertyBundle) {
                  return parsedPropertyBundle.description;
                }));
          var existingPropertyBundle = Belt_List.getBy(model.propertyBundles, (function (propertyBundle) {
                  return propertyBundle.name === propertyBundleName;
                }));
          if (existingPropertyBundle === undefined) {
            return {
                    id: Curry._1(generateId, undefined),
                    name: propertyBundleName,
                    description: StringExt.normalizeNewlines(parsedPropertyBundleDescription),
                    properties: Belt_Array.map(properties, (function (property) {
                            return {
                                    id: property.id,
                                    name: property.name
                                  };
                          }))
                  };
          }
          var tmp = parsedPropertyBundleDescription === "" ? existingPropertyBundle.description : parsedPropertyBundleDescription;
          return {
                  id: existingPropertyBundle.id,
                  name: existingPropertyBundle.name,
                  description: StringExt.normalizeNewlines(tmp),
                  properties: Belt_Array.map(properties, (function (property) {
                          return {
                                  id: property.id,
                                  name: property.name
                                };
                        }))
                };
        }));
  var globalPropertyBundles$1 = Belt_Array.concat(globalPropertyBundles, Belt_Array.keepMapU(rawParserOutput.propertyBundles, (function (rawPropertyBundle) {
              var globalPropertyBundlesContainsRawPropertyBundle = Belt_Array.some(globalPropertyBundles, (function (globalPropertyBundle) {
                      return globalPropertyBundle.name === rawPropertyBundle.name;
                    }));
              if (globalPropertyBundlesContainsRawPropertyBundle) {
                return ;
              }
              var existingPropertyBundle = Belt_List.getBy(model.propertyBundles, (function (propertyBundle) {
                      return propertyBundle.name === propertyBundle.name;
                    }));
              if (existingPropertyBundle !== undefined) {
                return {
                        id: existingPropertyBundle.id,
                        name: existingPropertyBundle.name,
                        description: existingPropertyBundle.description,
                        properties: []
                      };
              } else {
                return {
                        id: Nanoid.nanoid(),
                        name: rawPropertyBundle.name,
                        description: StringExt.normalizeNewlines(rawPropertyBundle.description),
                        properties: []
                      };
              }
            })));
  var propertyIdsToNumberOfOccurancesInBundles = Belt_Array.reduce(Belt_Array.concatMany(Belt_Array.map(globalPropertyBundles$1, (function (bundle) {
                  return bundle.properties;
                }))), undefined, (function (map, property) {
          var nextCount = Belt_MapString.getWithDefault(map, property.id, 0) + 1 | 0;
          return Belt_MapString.set(map, property.id, nextCount);
        }));
  var propertyIdsUsedInSingleBundle = Belt_MapString.keysToArray(Belt_MapString.keep(propertyIdsToNumberOfOccurancesInBundles, (function (param, occurancesInBundles) {
              return occurancesInBundles === 1;
            })));
  var globalPropertyBundlesWithPartitionedProperties = Belt_Array.map(globalPropertyBundles$1, (function (group) {
          var match = Belt_Array.partition(group.properties, (function (property) {
                  return Belt_Array.some(propertyIdsUsedInSingleBundle, (function (id) {
                                return id === property.id;
                              }));
                }));
          return {
                  id: group.id,
                  name: group.name,
                  description: StringExt.normalizeNewlines(group.description),
                  propertiesUsedOnlyInThisPropertyBundle: match[0],
                  propertiesUsedInMultiplePropertyBundles: match[1]
                };
        }));
  var globalProperties$1 = Belt_Array.map(globalProperties, (function (property) {
          var groupName = property.sendAs;
          if (typeof groupName !== "object") {
            return property;
          }
          var groupName$1 = groupName._0.trim();
          var maybeGroupId = Belt_Array.get(Belt_Array.keepMap(globalPropertyBundles$1, (function (group) {
                      if (group.name === groupName$1) {
                        return group.id;
                      }
                      
                    })), 0);
          return Belt_Option.mapWithDefault(maybeGroupId, property, (function (groupId) {
                        return {
                                id: property.id,
                                name: property.name,
                                uniqueNameIndex: property.uniqueNameIndex,
                                description: property.description,
                                type_: property.type_,
                                list: property.list,
                                matches: property.matches,
                                warnings: property.warnings,
                                presence: property.presence,
                                sendAs: {
                                  TAG: "GroupProperty",
                                  _0: groupId
                                },
                                nameMapping: property.nameMapping
                              };
                      }));
        }));
  var globalSources = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(rawEvents, (function ($$event) {
                      return $$event.sources;
                    })))));
  var globalStakeholderDomains = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(rawEvents, (function ($$event) {
                      return $$event.stakeholderDomains;
                    })))));
  var events = Belt_Array.keepMapU(rawEvents, (function (rawEvent) {
          var amplitudeUserPropertiesEvent = rawParserOutput.formatName === "Amplitude User Properties CSV Export" && rawEvent.name === "" && Belt_Array.everyU(rawEvent.properties, (function (rawProperty) {
                  return rawProperty.sendAs === "UserProperty";
                }));
          var mixpanelUserPropertiesEvent = rawParserOutput.formatName === "Mixpanel CSV Export" && rawEvent.name === "$user";
          var mixpanelCustomEvent = rawParserOutput.formatName === "Mixpanel CSV Export" && rawEvent.name.startsWith("$custom_event:");
          var skipEvent = rawEvent.name === "skip-event";
          if (amplitudeUserPropertiesEvent || mixpanelUserPropertiesEvent || mixpanelCustomEvent || skipEvent) {
            return ;
          } else {
            return {
                    id: rawEvent.id,
                    name: rawEvent.name,
                    uniqueNameIndex: undefined,
                    description: StringExt.normalizeNewlines(rawEvent.description),
                    categories: Belt_Array.keepU(rawEvent.categories, (function (category) {
                            return category !== "Uncategorized";
                          })),
                    tags: rawEvent.tags,
                    propertyIds: Belt_Array.keepMapU(rawEvent.properties, (function (rawProperty) {
                            return Belt_Option.map(Belt_Array.getByU(globalProperties$1, (function (globalProperty) {
                                              return ImportParserProcessRawOutputUtils.isPropertyEqual(globalProperty, rawProperty, importMethod);
                                            })), (function (globalProperty) {
                                          return [
                                                  globalProperty.id,
                                                  rawProperty.pinnedValue
                                                ];
                                        }));
                          })),
                    updatedPropertyIds: Belt_Array.keepMapU(rawEvent.properties, (function (rawProperty) {
                            return Belt_Option.map(Belt_Array.getByU(globalProperties$1, (function (globalProperty) {
                                              if (ImportParserProcessRawOutputUtils.isPropertyEqual(globalProperty, rawProperty, importMethod)) {
                                                return ImportParserProcessRawOutputUtils.hasNewMatches(globalProperty, rawProperty);
                                              } else {
                                                return false;
                                              }
                                            })), (function (globalProperty) {
                                          return [
                                                  globalProperty.id,
                                                  rawProperty.pinnedValue
                                                ];
                                        }));
                          })),
                    propertyBundleIds: ImportParserProcessRawOutputUtils.rawEventBundleIds(rawEvent, globalPropertyBundles$1),
                    sources: rawEvent.sources,
                    stakeholderDomains: rawEvent.stakeholderDomains,
                    warnings: [],
                    nameMapping: rawEvent.nameMapping
                  };
          }
        }));
  var eventNamesToPropertyIdsFromPropertyEvents = Belt_Array.concatMany(Belt_Array.keepMap(Belt_Array.concatMany(Belt_Array.concat(Belt_Array.map(rawEvents, (function ($$event) {
                          return Belt_Array.map($$event.properties, (function (property) {
                                        return property;
                                      }));
                        })), [rawParserOutput.propertiesWithoutEvent])), (function (property) {
              if (property.propertyEvents.length === 0) {
                return ;
              }
              var maybePropertyId = Belt_Array.get(Belt_Array.keepMap(globalProperties$1, (function (globalProperty) {
                          if (globalProperty.name === property.name) {
                            return globalProperty.id;
                          }
                          
                        })), 0);
              return Belt_Option.flatMap(maybePropertyId, (function (propertyId) {
                            return Belt_Array.map(property.propertyEvents, (function (eventName) {
                                          return [
                                                  eventName,
                                                  propertyId
                                                ];
                                        }));
                          }));
            })));
  var eventNamesToPropertyIdsFromNonUniquePropertiesInBundles = Belt_Array.concatMany(Belt_Array.map(rawEvents, (function ($$event) {
              var propertyBundleIds = ImportParserProcessRawOutputUtils.rawEventBundleIds($$event, globalPropertyBundles$1);
              var associatedPropertyIdRefs = Belt_Array.concatMany(Belt_Array.map(propertyBundleIds, (function (propertyBundleId) {
                          return Belt_Array.concatMany(Belt_Array.keepMap(globalPropertyBundlesWithPartitionedProperties, (function (bundle) {
                                            if (bundle.id === propertyBundleId) {
                                              return bundle.propertiesUsedInMultiplePropertyBundles;
                                            }
                                            
                                          })));
                        })));
              return Belt_Array.map(associatedPropertyIdRefs, (function (propertyIdRef) {
                            var propertyId = propertyIdRef.id;
                            return [
                                    $$event.name,
                                    propertyId
                                  ];
                          }));
            })));
  var eventNameToPropertyIdsMap = Belt_Array.reduce(Belt_Array.concat(eventNamesToPropertyIdsFromPropertyEvents, eventNamesToPropertyIdsFromNonUniquePropertiesInBundles), undefined, (function (eventMap, param) {
          var eventName = param[0];
          var nextProperties = Belt_MapString.getWithDefault(eventMap, eventName, []);
          nextProperties.push(param[1]);
          return Belt_MapString.set(eventMap, eventName, nextProperties);
        }));
  var events$1 = Belt_Array.map(events, (function ($$event) {
          var maybePropertyIdsToAdd = Belt_MapString.get(eventNameToPropertyIdsMap, $$event.name);
          if (maybePropertyIdsToAdd !== undefined) {
            return {
                    id: $$event.id,
                    name: $$event.name,
                    uniqueNameIndex: $$event.uniqueNameIndex,
                    description: $$event.description,
                    categories: $$event.categories,
                    tags: $$event.tags,
                    propertyIds: Belt_List.toArray(BeltListExtensions.dedupeOrdered(Belt_List.fromArray(Belt_Array.concat($$event.propertyIds, Belt_Array.map(maybePropertyIdsToAdd, (function (propertyId) {
                                            return [
                                                    propertyId,
                                                    undefined
                                                  ];
                                          })))), (function (param) {
                                return param[0];
                              }))),
                    updatedPropertyIds: $$event.updatedPropertyIds,
                    propertyBundleIds: $$event.propertyBundleIds,
                    sources: $$event.sources,
                    stakeholderDomains: $$event.stakeholderDomains,
                    warnings: $$event.warnings,
                    nameMapping: $$event.nameMapping
                  };
          } else {
            return $$event;
          }
        }));
  var events$2 = Belt_Array.concat(events$1, Belt_Array.keepMap(Belt_MapString.toArray(eventNameToPropertyIdsMap), (function (param) {
              var eventName = param[0];
              var maybeEvent = Belt_Array.getBy(events$1, (function ($$event) {
                      return $$event.name === eventName;
                    }));
              if (maybeEvent !== undefined) {
                return ;
              } else {
                return {
                        id: Curry._1(generateId, undefined),
                        name: eventName,
                        uniqueNameIndex: undefined,
                        description: "",
                        categories: [],
                        tags: [],
                        propertyIds: Belt_Array.map(param[1], (function (propertyId) {
                                return [
                                        propertyId,
                                        undefined
                                      ];
                              })),
                        updatedPropertyIds: [],
                        propertyBundleIds: [],
                        sources: [],
                        stakeholderDomains: [],
                        warnings: [],
                        nameMapping: []
                      };
              }
            })));
  return {
          formatName: rawParserOutput.formatName,
          events: events$2,
          warnings: {
            parseWarnings: rawParserOutput.warnings,
            filteredOutEventInfo: [],
            filteredOutPropertyInfo: []
          },
          properties: globalProperties$1,
          sources: globalSources,
          allStakeholderDomains: globalStakeholderDomains,
          propertyBundles: globalPropertyBundlesWithPartitionedProperties
        };
}

function filterParsedModelBasedOnAvoModel(model, parseOutputWithFlattenedProperties, importMethod, domainEvents) {
  var match = ImportParserFiltering.Filtering.filterOutEventsThatAreAlreadyInTheModelAndDontContainAnythingNew(model, parseOutputWithFlattenedProperties, importMethod, domainEvents);
  var filteredOutEventsInfo = match[2];
  var updatedEvents = match[1];
  var newEvents = match[0];
  var parsedEvents = Belt_Array.concat(newEvents, updatedEvents);
  var match$1 = ImportParserFiltering.Filtering.filterOutPropertiesThatAreOnlyOnFilteredOutEvents(parseOutputWithFlattenedProperties.properties, parsedEvents, filteredOutEventsInfo);
  var parsedProperties = match$1[0];
  var match$2 = ImportParserFiltering.Filtering.filterOutPropertiesThatAreAlreadyInTheModel(model, parsedProperties, parseOutputWithFlattenedProperties.properties, importMethod);
  var updatedProperties = match$2[1];
  var filteredOutPropertiesInfo = Belt_Array.concat(match$1[1], match$2[2]);
  var newEvents$1 = ImportParserFiltering.Filtering.updatePropertyIdsOnEventsAfterFiltering(model, filteredOutPropertiesInfo, newEvents);
  var updatedEvents$1 = ImportParserFiltering.Filtering.updatePropertyIdsOnEventsAfterFiltering(model, filteredOutPropertiesInfo, updatedEvents);
  var newProperties = ImportParserUniqueIndexes.Properties.assignUniqueIndexesToPropertiesWithSameName(match$2[0], model);
  var newEvents$2 = ImportParserUniqueIndexes.Events.assignUniqueIndexesToEventsWithSameName(newEvents$1, model);
  var match$3 = ImportParserFiltering.Filtering.replacePropertyRefsWithPropertyBundleRefs(model, newEvents$2, parseOutputWithFlattenedProperties.propertyBundles);
  var match$4 = ImportParserFiltering.Filtering.replacePropertyRefsWithPropertyBundleRefs(model, updatedEvents$1, match$3[1]);
  return {
          formatName: parseOutputWithFlattenedProperties.formatName,
          newEvents: match$3[0],
          updatedEvents: match$4[0],
          warnings: {
            parseWarnings: parseOutputWithFlattenedProperties.warnings.parseWarnings,
            filteredOutEventInfo: filteredOutEventsInfo,
            filteredOutPropertyInfo: filteredOutPropertiesInfo
          },
          newProperties: newProperties,
          updatedProperties: updatedProperties,
          allParsedProperties: Belt_Array.concat(parsedProperties, updatedProperties),
          sources: parseOutputWithFlattenedProperties.sources,
          allStakeholderDomains: parseOutputWithFlattenedProperties.allStakeholderDomains,
          propertyBundles: match$4[1]
        };
}

function parseRaw(csvFile) {
  var csv = Sync.parse(csvFile, {
        relax_column_count: true,
        relax_quotes: true
      });
  var trimmedCsv = Belt_Array.mapU(csv, (function (row) {
          return Belt_Array.mapU(row, (function (value) {
                        return value.trim();
                      }));
        }));
  return oneOfFormats(ImportFormats.formats, trimmedCsv);
}

function parse(model, csvFile, importMethod, domainEvents) {
  var rawOutput = parseRaw(csvFile);
  var parseOutputWithFlattenedProperties = processRawOutput(undefined, model, rawOutput, importMethod);
  return filterParsedModelBasedOnAvoModel(model, parseOutputWithFlattenedProperties, importMethod, domainEvents);
}

var ParseError = ImportParserTypes.ParseError;

var ParseFormatError = ImportParserTypes.ParseFormatError;

var ParseWarning = ImportParserTypes.ParseWarning;

var getColumnValue = ImportParserTypes.getColumnValue;

var getOptionalColumnValue = ImportParserTypes.getOptionalColumnValue;

var getColumnIndex = ImportParserTypes.getColumnIndex;

var getOptionalColumnIndex = ImportParserTypes.getOptionalColumnIndex;

var getOptionalRegexColumnNameAndIndex = ImportParserTypes.getOptionalRegexColumnNameAndIndex;

var StringTupleOp = ImportParserTypes.StringTupleOp;

var StringOp = ImportParserTypes.StringOp;

var BoolOp = ImportParserTypes.BoolOp;

var IntOp = ImportParserTypes.IntOp;

var StringArrayOp = ImportParserTypes.StringArrayOp;

var SendAsOp = ImportParserTypes.SendAsOp;

var Utils = ImportParserFiltering.Utils;

var Filtering = ImportParserFiltering.Filtering;

export {
  ParseError ,
  ParseFormatError ,
  ParseWarning ,
  getColumnValue ,
  getOptionalColumnValue ,
  getColumnIndex ,
  getOptionalColumnIndex ,
  getOptionalRegexColumnNameAndIndex ,
  StringTupleOp ,
  StringOp ,
  BoolOp ,
  IntOp ,
  StringArrayOp ,
  SendAsOp ,
  Utils ,
  Filtering ,
  getColumnConfig ,
  parseFormat ,
  oneOfFormats ,
  processRawOutput ,
  filterParsedModelBasedOnAvoModel ,
  parseRaw ,
  parse ,
}
/* nanoid Not a pure module */
