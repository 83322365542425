// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as App from "firebase/app";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function addCollaborator(schemaBundle, userId, currentBranch, collaborationType, collaborationId, collaboratorEmail, adderAction, sendActions, isReviewerOpt, onReviewerAdded, collaboratorId) {
  var isReviewer = isReviewerOpt !== undefined ? isReviewerOpt : false;
  var batch = Firebase.app().firestore().batch();
  var collaborationRef = Firebase.app().firestore().collection("schemas").doc(schemaBundle.schemaId).collection("collaboration").doc(collaborationId);
  batch.set(collaborationRef, {
        itemType: "branch",
        id: collaborationId
      });
  var collaboratorRef = Firebase.app().firestore().collection("schemas").doc(schemaBundle.schemaId).collection("collaboration").doc(collaborationId).collection("collaborators").doc(collaboratorId);
  batch.set(collaboratorRef, {
        itemType: "branch",
        itemId: collaborationId,
        id: collaboratorId,
        createdAt: App.default.firestore.FieldValue.serverTimestamp(),
        createdBy: userId,
        removedAt: null,
        collaborationTrigger: Models.CollaborationTrigger.tToJs("AddCollaborator"),
        isReviewer: isReviewer
      });
  var action_1 = [
    {
      NAME: "CollaborationItemBranch",
      VAL: collaborationId
    },
    userId,
    collaboratorId
  ];
  var action = {
    NAME: "CollaboratorAdded",
    VAL: action_1
  };
  var context = {
    branchId: collaborationId,
    branchQuery: collaborationId
  };
  Curry.app(sendActions, [
        currentBranch,
        undefined,
        Caml_option.some(batch),
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            AnalyticsRe.collaboratorAdded(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, collaborationId, NamedBranch.getId(branch), "Branch", collaborationType, collaboratorEmail, adderAction, userId === collaboratorId ? "Self" : "OtherMember", NamedBranch.getId(branch), schemaBundle$1.schemaId);
            if (isReviewer) {
              return Belt_Option.forEach(onReviewerAdded, (function (f) {
                            Curry._1(f, undefined);
                          }));
            }
            
          }),
        undefined,
        Belt_Array.concat([[
                action,
                context
              ]], isReviewer ? [[
                  {
                    NAME: "ReviewerAdded",
                    VAL: [
                      collaborationId,
                      userId,
                      collaboratorId
                    ]
                  },
                  context
                ]] : [])
      ]);
}

function updateCollaboratorIsReviewer(schemaId, branchId, collaboratorId, viewerId, sendActions, isReviewer, onReviewerAdded, onReviewerRemoved) {
  var batch = Firebase.app().firestore().batch();
  var collaboratorRef = Firebase.app().firestore().collection("schemas").doc(schemaId).collection("collaboration").doc(branchId).collection("collaborators").doc(collaboratorId);
  batch.set(collaboratorRef, {
        isReviewer: isReviewer
      }, {"merge": true});
  var context = {
    branchId: branchId,
    branchQuery: branchId
  };
  var action = isReviewer ? [[
        {
          NAME: "ReviewerAdded",
          VAL: [
            branchId,
            viewerId,
            collaboratorId
          ]
        },
        context
      ]] : [[
        {
          NAME: "ReviewerRemoved",
          VAL: [
            branchId,
            viewerId,
            collaboratorId
          ]
        },
        context
      ]];
  Curry.app(sendActions, [
        {
          NAME: "Branch",
          VAL: branchId
        },
        undefined,
        Caml_option.some(batch),
        undefined,
        undefined,
        undefined,
        undefined,
        (function (_branch) {
            if (isReviewer) {
              return Curry._1(onReviewerAdded, undefined);
            } else {
              return Curry._1(onReviewerRemoved, undefined);
            }
          }),
        undefined,
        action
      ]);
}

function removeCollaborator(schemaBundle, userId, currentBranch, collaborationType, collaborationId, collaborator, sendActions, onRemoveReviewer) {
  var batch = Firebase.app().firestore().batch();
  var collaboratorId = collaborator.id;
  var collaboratorRef = Firebase.app().firestore().collection("schemas").doc(schemaBundle.schemaId).collection("collaboration").doc(collaborationId).collection("collaborators").doc(collaboratorId);
  batch.update(collaboratorRef, {
        removedAt: App.default.firestore.FieldValue.serverTimestamp(),
        removedBy: userId
      });
  var action_1 = [
    {
      NAME: "CollaborationItemBranch",
      VAL: collaborationId
    },
    userId,
    collaboratorId
  ];
  var action = {
    NAME: "CollaboratorRemoved",
    VAL: action_1
  };
  var context = {
    branchId: collaborationId,
    branchQuery: collaborationId
  };
  Curry.app(sendActions, [
        currentBranch,
        undefined,
        Caml_option.some(batch),
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            AnalyticsRe.collaboratorRemoved(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, collaborationId, NamedBranch.getId(branch), "Branch", collaborationType, "@", NamedBranch.getId(branch), schemaBundle$1.schemaId);
            if (Caml_obj.equal(Caml_option.undefined_to_opt(collaborator.isReviewer), true)) {
              return Curry._1(onRemoveReviewer, undefined);
            }
            
          }),
        undefined,
        [[
            action,
            context
          ]]
      ]);
}

function getCollaborators(workspaceId, collaborationId) {
  return new Promise((function (resolve, reject) {
                Firebase.app().firestore().collection("schemas").doc(workspaceId).collection("collaboration").doc(collaborationId).collection("collaborators").where("removedAt", "==", null).orderBy("createdAt", "asc").onSnapshot((function (snapshot) {
                        resolve(Belt_Array.map(snapshot.docs, (function (prim) {
                                    return prim.data();
                                  })));
                      }), (function (error) {
                        reject(error);
                      }));
              }));
}

function getCollaborator(workspaceId, collaborationId, collaboratorId) {
  return Firebase.app().firestore().collection("schemas").doc(workspaceId).collection("collaboration").doc(collaborationId).collection("collaborators").doc(collaboratorId).get().then(function (snapshot) {
              if (snapshot.exists) {
                return Caml_option.some(snapshot.data());
              }
              
            });
}

function useCollaborators(collaborationId) {
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var collaborators = FirebaseFetcherHooks.useStreamCollection(Firebase.app().firestore().collection("schemas").doc(workspace.id).collection("collaboration").doc(collaborationId).collection("collaborators"), (function (query) {
          return query.where("removedAt", "==", null).orderBy("createdAt", "asc");
        }), undefined, undefined);
  var workspaceUserIds = Belt_List.map(workspace.members, (function (member) {
          return member.id;
        }));
  return Belt_List.keep(collaborators, (function (collaborator) {
                return Belt_List.has(workspaceUserIds, collaborator.id, (function (prim0, prim1) {
                              return prim0 === prim1;
                            }));
              }));
}

function useCollaboratorIds(collaborationId) {
  return Belt_List.map(useCollaborators(collaborationId), (function (prim) {
                return prim.id;
              }));
}

export {
  addCollaborator ,
  updateCollaboratorIsReviewer ,
  removeCollaborator ,
  getCollaborators ,
  getCollaborator ,
  useCollaborators ,
  useCollaboratorIds ,
}
/* react Not a pure module */
