// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Styles from "./styles.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterTypes from "./RouterTypes.mjs";
import * as QueryString from "query-string";

var _map = {"Web":"Web","iOS":"iOS","Android":"Android","Desktop":"Desktop","Game Engine":"Game Engine","Cross-platform":"Cross-platform","Backend":"Backend","Other":"Other"};

function sourceGroupToJs(param) {
  return param;
}

function sourceGroupFromJs(param) {
  return _map[param];
}

var companySizes = [
  "UpTo20People",
  "_21Minus50People",
  "_51Minus100People",
  "_101Minus500People",
  "_501Minus1000People",
  "_1001Minus5000People",
  "MoreThan5000People"
];

var jobFunctions = [
  "ProductAnalytics",
  "DataScience",
  "DataAnalyticsEngineering",
  "SoftwareEngineering",
  "ProductManagement",
  "Marketing",
  "Other"
];

var cmp = Caml_obj.compare;

var SourceGroupCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

var cmp$1 = Caml_obj.compare;

var JobFunctionCmp = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

var cmp$2 = Caml_obj.compare;

var ContributionCmp = Belt_Id.MakeComparable({
      cmp: cmp$2
    });

function getInitialState(onboardingRoute) {
  var query = window.location.search.replace("?", "");
  var email = QueryString.parse(query).email;
  return {
          email: Belt_Option.mapWithDefault((email == null) ? undefined : Caml_option.some(email), "", (function (prim) {
                  return decodeURIComponent(prim);
                })),
          password: "",
          signUpStatus: "Initial",
          showNewOrJoinModal: false,
          loadingJoinInstructions: false,
          hasSeenJoinInstructions: false,
          jobFunction: Belt_Set.make(JobFunctionCmp),
          companyRole: undefined,
          companySize: undefined,
          contribution: Belt_Set.make(ContributionCmp),
          position: {
            NAME: "Step",
            VAL: onboardingRoute
          },
          emailSso: "Unknown",
          sourceGroups: Belt_Set.make(SourceGroupCmp),
          destinations: /* [] */0
        };
}

function stepToJs(step) {
  if (step === "contribution") {
    return "Contribution";
  } else if (step === "job") {
    return "JobFunction";
  } else if (step === "loading") {
    return "CreatingSchema";
  } else if (step === "destinations") {
    return "Destinations";
  } else if (step === "sources") {
    return "Sources";
  } else {
    return "Password";
  }
}

var funnel = [
  "job",
  "contribution",
  "sources",
  "destinations",
  "password",
  "loading"
];

function getColor(x) {
  if (x === "contribution") {
    return Styles.Color.darkGreen;
  } else if (x === "destinations") {
    return Styles.Color.darkPurple;
  } else if (x === "sources") {
    return Styles.Color.deepBlue;
  } else {
    return Styles.Color.darkPink;
  }
}

function getUrlFragmentFromStep(step) {
  return "/" + RouterTypes.BaseRoute.toUrlFragment({
              NAME: "onboarding",
              VAL: step
            });
}

function getBack(step) {
  var stepIndex = Js_array.indexOf(step, funnel);
  return Belt_Array.get(funnel, stepIndex - 1 | 0);
}

function getNext(step) {
  var stepIndex = Js_array.indexOf(step, funnel);
  if (stepIndex < funnel.length) {
    return Belt_Array.get(funnel, stepIndex + 1 | 0);
  }
  
}

function validateStep(state, step) {
  if (step === "contribution") {
    if (Belt_Set.isEmpty(state.contribution)) {
      return "ValidEmptyState";
    } else {
      return "Valid";
    }
  } else if (step === "job") {
    if (!Belt_Set.isEmpty(state.jobFunction) || Belt_Option.isSome(state.companyRole) || Belt_Option.isSome(state.companySize)) {
      return "Valid";
    } else {
      return "ValidEmptyState";
    }
  } else if (step === "loading") {
    return "Valid";
  } else if (step === "destinations") {
    if (state.destinations === /* [] */0) {
      return "ValidEmptyState";
    } else {
      return "Valid";
    }
  } else if (step === "sources") {
    if (Belt_Set.isEmpty(state.sourceGroups)) {
      return "ValidEmptyState";
    } else {
      return "Valid";
    }
  } else if ($$String.contains(state.email, /* '@' */64) && $$String.contains(state.email, /* '.' */46) && state.email.length > 5) {
    if (state.password.length > 5) {
      return "Valid";
    } else {
      return {
              NAME: "Invalid",
              VAL: "Invalid password"
            };
    }
  } else {
    return {
            NAME: "Invalid",
            VAL: "Invalid email address"
          };
  }
}

function getDirection(fromStep, toStep) {
  var fromStepIndex = Js_array.indexOf(fromStep, funnel);
  var toStepIndex = Js_array.indexOf(toStep, funnel);
  if (fromStepIndex === toStepIndex) {
    return "NA";
  } else if (fromStepIndex < toStepIndex) {
    return "Forward";
  } else {
    return "Back";
  }
}

var sourceGroups = [
  "Web",
  "Desktop",
  "Backend",
  "iOS",
  "Android",
  "Cross-platform",
  "Game Engine",
  "Other"
];

export {
  sourceGroupToJs ,
  sourceGroupFromJs ,
  companySizes ,
  jobFunctions ,
  SourceGroupCmp ,
  JobFunctionCmp ,
  ContributionCmp ,
  getInitialState ,
  stepToJs ,
  funnel ,
  getColor ,
  getUrlFragmentFromStep ,
  getBack ,
  getNext ,
  validateStep ,
  getDirection ,
  sourceGroups ,
}
/* SourceGroupCmp Not a pure module */
