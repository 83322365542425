// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CmdK from "./CmdK.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function CmdKProvider$ShortcutOpeningAndPathHelper(Props) {
  var setPath = Props.setPath;
  var children = Props.children;
  var schemaBundle = SchemaBundleContext.use();
  var match = Curry._1(CmdK.Matches.useMatches, undefined);
  var rootActionId = match.rootActionId;
  var kBarState = Kbar.useKBar(function (state) {
        return {
                searchTerm: state.searchQuery,
                visualState: state.visualState
              };
      });
  var opening = React.useRef(false);
  var wantsToGoUp = React.useRef(false);
  React.useEffect((function (param) {
          var match = kBarState.visualState;
          if (match !== undefined) {
            if (match === "showing") {
              if (opening.current) {
                var tmp;
                if (rootActionId !== undefined) {
                  switch (rootActionId) {
                    case "branch" :
                        tmp = "Branches";
                        break;
                    case "create" :
                        tmp = "Create";
                        break;
                    case "search" :
                        tmp = "Search";
                        break;
                    case "unarchive" :
                        tmp = "Unarchive";
                        break;
                    default:
                      tmp = Pervasives.failwith("Fix new paths in Command Palette: " + rootActionId);
                  }
                } else {
                  tmp = "CmdKRoot";
                }
                AnalyticsRe.cmdPaletteOpened(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, tmp, schemaBundle.branchId, schemaBundle.schemaId);
              }
              opening.current = false;
            } else if (match === "animating-in") {
              opening.current = true;
            }
            
          }
          
        }), [kBarState.visualState]);
  React.useEffect((function (param) {
          var onKeyStroke = function ($$event) {
            if ($$event.key === "Backspace" && kBarState.searchTerm === "") {
              wantsToGoUp.current = true;
            } else {
              wantsToGoUp.current = false;
            }
          };
          window.addEventListener("keydown", onKeyStroke);
          return (function (param) {
                    window.removeEventListener("keydown", onKeyStroke);
                  });
        }), []);
  Hooks.useDidUpdate1((function (param) {
          Curry._1(setPath, (function (path) {
                  var l = Belt_List.fromArray(Belt_Array.reverse(path));
                  var tmp;
                  if (rootActionId === undefined) {
                    tmp = /* [] */0;
                  } else if (l) {
                    var l$1 = l.tl;
                    var exit = 0;
                    if (l$1 || !wantsToGoUp.current) {
                      exit = 1;
                    } else {
                      tmp = /* [] */0;
                    }
                    if (exit === 1) {
                      tmp = wantsToGoUp.current ? l$1 : ({
                            hd: Belt_Option.getExn(rootActionId),
                            tl: l
                          });
                    }
                    
                  } else {
                    tmp = wantsToGoUp.current ? /* [] */0 : ({
                          hd: Belt_Option.getExn(rootActionId),
                          tl: /* [] */0
                        });
                  }
                  return Belt_Array.reverse(Belt_List.toArray(tmp));
                }));
        }), [rootActionId]);
  return children;
}

var ShortcutOpeningAndPathHelper = {
  make: CmdKProvider$ShortcutOpeningAndPathHelper
};

var context = React.createContext([
      false,
      (function (param) {
          
        })
    ]);

var provider = context.Provider;

function useDisabled(param) {
  return React.useContext(context);
}

function CmdKProvider(Props) {
  var children = Props.children;
  var schemaBundle = SchemaBundleContext.use();
  var match = React.useState(function (param) {
        return [];
      });
  var path = match[0];
  var searchTerm = React.useRef("");
  var match$1 = React.useState(function (param) {
        return false;
      });
  var disabled = match$1[0];
  return React.createElement(Kbar.KBarProvider, {
              actions: [],
              options: {
                callbacks: {
                  onClose: (function (param) {
                      if (disabled) {
                        return ;
                      } else {
                        return AnalyticsRe.cmdPaletteExited(path.join("/"), path.length, searchTerm.current, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.branchId, schemaBundle.schemaId);
                      }
                    }),
                  onQueryChange: (function (query) {
                      if (disabled) {
                        return ;
                      } else {
                        searchTerm.current = query;
                        return ;
                      }
                    }),
                  onSelectAction: (function (action) {
                      if (disabled) {
                        return ;
                      }
                      var action$1 = Curry._1(CmdK.Result.convert, action);
                      if (action$1.TAG === "Section") {
                        return ;
                      }
                      var action$2 = action$1._0;
                      var match = action$2.perform;
                      AnalyticsRe.cmdPaletteOptionSelected(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, path.join("/"), path.length, searchTerm.current, match !== undefined ? "ActionPerformed" : "PathDeeper", action$2.name, schemaBundle.branchId, schemaBundle.schemaId);
                    })
                }
              },
              children: React.createElement(provider, {
                    value: [
                      disabled,
                      match$1[1]
                    ],
                    children: React.createElement(CmdKProvider$ShortcutOpeningAndPathHelper, {
                          setPath: match[1],
                          children: children
                        })
                  })
            });
}

var make = CmdKProvider;

export {
  ShortcutOpeningAndPathHelper ,
  context ,
  provider ,
  useDisabled ,
  make ,
}
/* context Not a pure module */
