// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Nanoid from "nanoid";
import * as Zustand from "./Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";

function Make(Config) {
  var StoreConfig = {};
  var ZustandStore = Zustand.MakeStore(StoreConfig);
  var store = Zustand$1.create(function (set, _get) {
        return {
                initialized: false,
                subscribers: [],
                subscribe: (function (subscribeId) {
                    set(function (state) {
                          return {
                                  initialized: state.initialized,
                                  subscribers: Belt_SetString.toArray(Belt_SetString.add(Belt_SetString.fromArray(state.subscribers), subscribeId)),
                                  subscribe: state.subscribe,
                                  unsubscribe: state.unsubscribe,
                                  items: state.items,
                                  setItems: state.setItems
                                };
                        });
                  }),
                unsubscribe: (function (subscribeId) {
                    set(function (state) {
                          return {
                                  initialized: state.initialized,
                                  subscribers: Belt_SetString.toArray(Belt_SetString.remove(Belt_SetString.fromArray(state.subscribers), subscribeId)),
                                  subscribe: state.subscribe,
                                  unsubscribe: state.unsubscribe,
                                  items: state.items,
                                  setItems: state.setItems
                                };
                        });
                  }),
                items: Config.empty,
                setItems: (function (items) {
                    set(function (state) {
                          return {
                                  initialized: true,
                                  subscribers: state.subscribers,
                                  subscribe: state.subscribe,
                                  unsubscribe: state.unsubscribe,
                                  items: items,
                                  setItems: state.setItems
                                };
                        });
                  })
              };
      });
  var _use = function (param) {
    var schemaId = WorkspaceContext.use().id;
    var subscribeId = React.useRef(Nanoid.nanoid());
    var subscribe = Curry._2(ZustandStore.use, store, (function (state) {
            return state.subscribe;
          }));
    var unsubscribe = Curry._2(ZustandStore.use, store, (function (state) {
            return state.unsubscribe;
          }));
    var subscribers = Curry._2(ZustandStore.use, store, (function (state) {
            return state.subscribers;
          }));
    var setItems = Curry._2(ZustandStore.use, store, (function (state) {
            return state.setItems;
          }));
    React.useEffect((function (param) {
            Curry._1(subscribe, subscribeId.current);
            return (function (param) {
                      Curry._1(unsubscribe, subscribeId.current);
                    });
          }), []);
    var id = Belt_Array.get(subscribers, 0);
    var isMainSubscriber = id !== undefined ? id === subscribeId.current : false;
    var mode = React.useMemo((function (param) {
            if (isMainSubscriber) {
              return "Fetch";
            } else {
              return "Wait";
            }
          }), [isMainSubscriber]);
    var itemsQuery = Curry._2(Config.use, mode, schemaId);
    React.useEffect((function (param) {
            var items = Curry._1(Config.resolve, itemsQuery);
            if (items !== undefined) {
              Curry._1(setItems, Caml_option.valFromOption(items));
            }
            
          }), [
          itemsQuery,
          mode
        ]);
  };
  var use = function (param) {
    _use();
    return Curry._2(ZustandStore.useShallow, store, (function (state) {
                  return {
                          items: state.items,
                          initialized: state.initialized
                        };
                }));
  };
  return {
          StoreConfig: StoreConfig,
          ZustandStore: ZustandStore,
          store: store,
          use: use
        };
}

export {
  Make ,
}
/* react Not a pure module */
