// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Link(Props) {
  var path = Props.path;
  var className = Props.className;
  var onClick = Props.onClick;
  var disabledOpt = Props.disabled;
  var id = Props.id;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var newPath = Router.reParsePath(path);
  var tmp = {
    className: Belt_Option.mapWithDefault(className, Curry._1(Css.style, {
              hd: Css.textDecoration("none"),
              tl: /* [] */0
            }), (function (styles) {
            return styles + " " + Curry._1(Css.style, {
                        hd: Css.textDecoration("none"),
                        tl: /* [] */0
                      });
          })),
    href: newPath,
    onClick: (function ($$event) {
        var match = $$event.metaKey || $$event.shiftKey;
        if (disabled || match) {
          
        } else {
          $$event.preventDefault();
          Router.push(undefined, newPath);
        }
        Belt_Option.mapWithDefault(onClick, undefined, (function (onClick) {
                Curry._1(onClick, $$event);
              }));
      })
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement("a", tmp, children);
}

var make = Link;

export {
  make ,
}
/* Css Not a pure module */
