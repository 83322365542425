// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CreateMore from "./CreateMore.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as NameInputUtils from "./NameInputUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as NameInput__Validations from "./NameInput__Validations.mjs";
import * as AdvancedNameInput__SimilarNames from "./AdvancedNameInput__SimilarNames.mjs";

function NameInput(Props) {
  var name = Props.name;
  var existingNamesOpt = Props.existingNames;
  var expectedCase = Props.expectedCase;
  var forceCaseOpt = Props.forceCase;
  var autoFocusOpt = Props.autoFocus;
  var onChange = Props.onChange;
  var submitLabelOpt = Props.submitLabel;
  var placeholderOpt = Props.placeholder;
  var onCloseOpt = Props.onClose;
  var fullscreenOpt = Props.fullscreen;
  var dirtyOpt = Props.dirty;
  var disabledOpt = Props.disabled;
  var inputStylesOpt = Props.inputStyles;
  var itemType = Props.itemType;
  var itemSubType = Props.itemSubType;
  var actionType = Props.actionType;
  var getPossibleItemLink = Props.getPossibleItemLink;
  var createMore = Props.createMore;
  var existingNames = existingNamesOpt !== undefined ? existingNamesOpt : [];
  var forceCase = forceCaseOpt !== undefined ? forceCaseOpt : false;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var submitLabel = submitLabelOpt !== undefined ? submitLabelOpt : "Update Name";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Event Name";
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  var fullscreen = fullscreenOpt !== undefined ? fullscreenOpt : false;
  var dirty = dirtyOpt !== undefined ? dirtyOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var inputStyles = inputStylesOpt !== undefined ? inputStylesOpt : /* [] */0;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var memberRole = WorkspaceContext.useMemberRole();
  var memberIsAdmin = memberRole === "Admin";
  var globalSend = GlobalSendContext.use();
  var showAdvancedNamingTip = memberIsAdmin && itemType === "Event";
  var forceCasingAvailability = AvoLimits.ForcePreferredCasing.computeAvailability(workspace);
  var forceCase$1 = forceCase && forceCasingAvailability !== "Unavailable";
  var name$1 = NameUtils.replaceNewlinesWithWhitespace(name);
  var match = React.useReducer((function (state, action) {
          switch (action.TAG) {
            case "ToggleFocus" :
                if (disabled) {
                  return state;
                }
                var hasFocus = action._0;
                return {
                        hasFocus: fullscreen || hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: fullscreen || hasFocus ? state.interactedWith : false,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: state.validation,
                        intelligenceFeedback: state.intelligenceFeedback
                      };
            case "UpdateName" :
                return {
                        hasFocus: state.hasFocus,
                        name: action._0,
                        inputRef: state.inputRef,
                        interactedWith: true,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: "Loading",
                        intelligenceFeedback: "Init"
                      };
            case "UpdateValidation" :
                return {
                        hasFocus: state.hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: state.interactedWith,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: action._0,
                        intelligenceFeedback: state.intelligenceFeedback
                      };
            case "UpdateIntelligenceFeedback" :
                return {
                        hasFocus: state.hasFocus,
                        name: state.name,
                        inputRef: state.inputRef,
                        interactedWith: state.interactedWith,
                        numberOfSuggestions: state.numberOfSuggestions,
                        validation: state.validation,
                        intelligenceFeedback: action._0
                      };
            
          }
        }), {
        hasFocus: autoFocus,
        name: name$1,
        inputRef: {
          contents: undefined
        },
        interactedWith: false,
        numberOfSuggestions: {
          contents: 0
        },
        validation: "Init",
        intelligenceFeedback: "Init"
      });
  var send = match[1];
  var state = match[0];
  var existingNames$1 = React.useMemo((function (param) {
          return Belt_Array.keepMapU(existingNames, (function (existingName) {
                        if (existingName !== state.name && existingName !== name$1) {
                          return existingName;
                        }
                        
                      }));
        }), [
        existingNames,
        state.name
      ]);
  var expectedCase$1 = React.useMemo((function (param) {
          return Belt_Option.getWithDefault(expectedCase, NameUtils.getCommonCase__array(existingNames$1));
        }), [expectedCase]);
  var match$1 = state.validation;
  var similarNames = NameInputUtils.useDebouncedSimilarNamesWithNamespaceIndex(existingNames$1, typeof match$1 === "object" && match$1.NAME === "Result" ? match$1.VAL.conflictingName : undefined, itemType, itemSubType, state.name);
  var schemaBundle = SchemaBundleContext.use();
  React.useEffect((function (param) {
          if (fullscreen) {
            NameInputUtils.validateInputWithAction(send, existingNames$1, name$1, state.name, expectedCase$1, false);
          }
          
        }), []);
  React.useEffect((function (param) {
          if (state.interactedWith && state.validation === "Loading") {
            NameInputUtils.validateInputWithAction(send, existingNames$1, name$1, state.name, expectedCase$1, false);
          }
          
        }), [state.name]);
  var isDirty = dirty || state.name !== name$1;
  var focusInput = function (param) {
    var inputRef = state.inputRef.contents;
    if (inputRef !== undefined) {
      Caml_option.valFromOption(inputRef).focus();
      return ;
    }
    
  };
  var handleBlur = function (param) {
    var inputRef = state.inputRef.contents;
    if (inputRef !== undefined) {
      Caml_option.valFromOption(inputRef).blur();
    }
    Curry._1(send, {
          TAG: "ToggleFocus",
          _0: false
        });
  };
  var reset = function (param) {
    Curry._1(send, {
          TAG: "UpdateName",
          _0: name$1
        });
    setTimeout((function (param) {
            focusInput();
          }), 0);
  };
  var handleSubmit = function (param) {
    var match = state.validation;
    if (typeof match !== "object") {
      return ;
    }
    if (match.NAME !== "Result") {
      return ;
    }
    var match$1 = match.VAL;
    if (!match$1.isIllegal) {
      Curry._4(onChange, state.name.trim(), Case.getCasingWithWorkspaceCase(state.name, expectedCase$1), !match$1.inconsistentCase, reset);
      return handleBlur();
    }
    
  };
  var handleEscape = function (explicit) {
    if (explicit || state.name === "") {
      Curry._1(send, {
            TAG: "UpdateName",
            _0: name$1
          });
    }
    handleBlur();
    Curry._1(onClose, undefined);
  };
  var logClose = function (closingMethod, changeMade) {
    AnalyticsRe.nameEditorClosed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, closingMethod, itemType, actionType, Case.toAnalytics(Case.getCasingWithWorkspaceCase(state.name, expectedCase$1)), state.numberOfSuggestions.contents, state.interactedWith ? "Keystroke" : "None", changeMade, schemaBundle.branchId, schemaBundle.schemaId);
  };
  var showSimilarNames = state.hasFocus && similarNames.length !== 0;
  var tmp;
  if (state.hasFocus) {
    var tmp$1;
    if (isDirty) {
      var tmp$2 = {
        validation: state.validation,
        onNamingFeedbackReceived: (function (feedbackType, feedbackDetails) {
            state.numberOfSuggestions = {
              contents: state.numberOfSuggestions.contents + 1 | 0
            };
            var arg = schemaBundle.branchId;
            var arg$1 = schemaBundle.schemaId;
            AnalyticsUtils.withSchemaBundle((function (param) {
                    var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17) {
                      return AnalyticsRe.namingFeedbackReceived(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17);
                    };
                    return function (param) {
                      var func$1 = Curry._1(func, param);
                      return function (param) {
                        var func$2 = Curry._1(func$1, param);
                        return function (param) {
                          var func$3 = Curry._1(func$2, param);
                          return function (param) {
                            var func$4 = Curry._1(func$3, param);
                            return function (param) {
                              var func$5 = Curry._1(func$4, param);
                              return function (param) {
                                var func$6 = Curry._1(func$5, param);
                                return function (param) {
                                  var func$7 = Curry._1(func$6, param);
                                  return function (param) {
                                    var func$8 = Curry._1(func$7, param);
                                    return function (param) {
                                      var func$9 = Curry._1(func$8, param);
                                      return function (param) {
                                        return Curry._8(func$9, param, feedbackType, itemType, actionType, undefined, feedbackDetails, arg, arg$1);
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          };
                        };
                      };
                    };
                  }), schemaBundle);
          }),
        onFixCasing: (function (correctlyCasedName) {
            Curry._1(send, {
                  TAG: "UpdateName",
                  _0: correctlyCasedName
                });
            focusInput();
            var arg = state.name;
            var arg$1 = schemaBundle.branchId;
            var arg$2 = schemaBundle.schemaId;
            AnalyticsUtils.withSchemaBundle((function (param) {
                    var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18) {
                      return AnalyticsRe.namingFeedbackApplied(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18);
                    };
                    return function (param) {
                      var func$1 = Curry._1(func, param);
                      return function (param) {
                        var func$2 = Curry._1(func$1, param);
                        return function (param) {
                          var func$3 = Curry._1(func$2, param);
                          return function (param) {
                            var func$4 = Curry._1(func$3, param);
                            return function (param) {
                              var func$5 = Curry._1(func$4, param);
                              return function (param) {
                                var func$6 = Curry._1(func$5, param);
                                return function (param) {
                                  var func$7 = Curry._1(func$6, param);
                                  return function (param) {
                                    var func$8 = Curry._1(func$7, param);
                                    return function (param) {
                                      var func$9 = Curry._1(func$8, param);
                                      return function (param) {
                                        return Curry.app(func$9, [
                                                    param,
                                                    "Suggestion",
                                                    itemType,
                                                    actionType,
                                                    "ConsistentCasing",
                                                    correctlyCasedName,
                                                    arg,
                                                    arg$1,
                                                    arg$2
                                                  ]);
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          };
                        };
                      };
                    };
                  }), schemaBundle);
          }),
        expectedCase: expectedCase$1,
        forceCase: forceCase$1,
        onLinkOpened: (function (param) {
            handleEscape(true);
          }),
        name: state.name
      };
      if (getPossibleItemLink !== undefined) {
        tmp$2.maybeGetItemLink = Caml_option.valFromOption(getPossibleItemLink);
      }
      tmp$1 = React.createElement(NameInput__Validations.make, tmp$2);
    } else {
      tmp$1 = null;
    }
    var match$2 = state.validation;
    var tmp$3;
    if (typeof match$2 === "object") {
      var match$3 = match$2.VAL;
      tmp$3 = match$3.isIllegal || forceCase$1 && match$3.inconsistentCase ? true : false;
    } else {
      tmp$3 = true;
    }
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(showAdvancedNamingTip ? -100 : -50)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.height(Css.px(50)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(9)),
                          tl: {
                            hd: Css.paddingRight(Css.px(9)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.light02),
                              tl: {
                                hd: Css.borderBottomRightRadius(showSimilarNames ? Css.px(0) : Css.px(10)),
                                tl: {
                                  hd: Css.borderBottomLeftRadius(showSimilarNames ? Css.px(0) : Css.px(10)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.gap(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, React.createElement(Button.make, {
              label: "Cancel",
              onClick: (function (param) {
                  handleEscape(true);
                  logClose("PressCancelButton", false);
                }),
              style: "ghost"
            }), tmp$1, React.createElement(Spacer.make, {
              grow: 1.0
            }), createMore !== undefined ? React.createElement(CreateMore.make, {
                toggled: createMore[0],
                onToggle: createMore[1]
              }) : null, React.createElement(Button.make, {
              disabled: tmp$3,
              label: submitLabel,
              onClick: (function (param) {
                  handleSubmit();
                  logClose("PressConfirmButton", name$1 !== state.name);
                })
            }));
  } else {
    tmp = null;
  }
  return React.createElement("div", undefined, state.hasFocus ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("fixed"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: {
                                    hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.1)),
                                      tl: {
                                        hd: Css.cursor("pointer"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    onClick: (function (param) {
                        handleEscape(false);
                        logClose("ClickOutside", false);
                      })
                  }) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.zIndex(state.hasFocus ? Styles.ZIndex.aboveAll : 0),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  onClick: (function ($$event) {
                      $$event.preventDefault();
                    })
                }, React.createElement("input", {
                      ref: (function (inputRef) {
                          state.inputRef.contents = (inputRef == null) ? undefined : Caml_option.some(inputRef);
                        }),
                      className: Curry._1(Css.style, Belt_List.concatMany([
                                Styles.input,
                                {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.top(Css.px(0)),
                                    tl: {
                                      hd: Css.right(Css.px(0)),
                                      tl: {
                                        hd: Css.bottom(Css.px(0)),
                                        tl: {
                                          hd: Css.left(Css.px(0)),
                                          tl: {
                                            hd: Css.width(Css.pct(100)),
                                            tl: {
                                              hd: Css.padding(Css.px(20)),
                                              tl: {
                                                hd: Css.paddingLeft(Css.px(24)),
                                                tl: {
                                                  hd: Css.outlineWidth(Css.px(0)),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: {
                                                      hd: Css.fontSize(Styles.FontSize.medium),
                                                      tl: {
                                                        hd: Css.backgroundColor(state.hasFocus ? Styles.Color.white : "transparent"),
                                                        tl: {
                                                          hd: Css.borderTopLeftRadius(Styles.Border.radius),
                                                          tl: {
                                                            hd: Css.borderTopRightRadius(Styles.Border.radius),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                inputStyles
                              ])),
                      autoFocus: autoFocus,
                      disabled: disabled,
                      placeholder: name$1 === "" ? Case.to_(expectedCase$1, placeholder) : name$1,
                      value: state.hasFocus ? state.name : name$1,
                      onKeyDown: (function ($$event) {
                          var match = $$event.key;
                          switch (match) {
                            case "Enter" :
                                handleSubmit();
                                return logClose("PressEnterKey", name$1 !== state.name);
                            case "Escape" :
                                $$event.stopPropagation();
                                handleEscape(true);
                                return logClose("PressEscapeKey", false);
                            default:
                              return ;
                          }
                        }),
                      onFocus: (function (param) {
                          Curry._1(send, {
                                TAG: "ToggleFocus",
                                _0: true
                              });
                        }),
                      onChange: (function ($$event) {
                          Curry._1(send, {
                                TAG: "UpdateName",
                                _0: $$event.target.value
                              });
                        })
                    })), tmp, state.hasFocus && showAdvancedNamingTip ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.left(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(-50)),
                                tl: {
                                  hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.light02),
                                    tl: {
                                      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                                      tl: {
                                        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                        tl: {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.justifyContent("spaceBetween"),
                                              tl: {
                                                hd: Css.height(Css.px(50)),
                                                tl: {
                                                  hd: Css.paddingLeft(Css.px(24)),
                                                  tl: {
                                                    hd: Css.paddingRight(Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.gap(Css.px(4)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light11,
                            children: "Set advanced rules for your event naming framework"
                          }), React.createElement(SimpleTooltip.make, {
                            tooltip: "Using the naming framework will enable you to define a name structure, predefined allowed values, blocked words and custom casing rules",
                            children: React.createElement(Icon.make, {
                                  type_: "information",
                                  size: "small",
                                  color: Styles.Color.light10
                                })
                          })), React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: "OpenSlideOver",
                                  _0: {
                                    NAME: "DiscrepanciesConfig",
                                    VAL: "eventNamingConventions"
                                  }
                                });
                            Curry._1(onClose, undefined);
                          }),
                        style: "Blue",
                        size: "Small",
                        children: "Advanced rules ->"
                      })) : null, showSimilarNames ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(showAdvancedNamingTip ? 175 : 125)),
                            tl: {
                              hd: Css.left(Css.px(0)),
                              tl: {
                                hd: Css.right(Css.px(0)),
                                tl: {
                                  hd: Css.zIndex(Styles.ZIndex.aboveAll),
                                  tl: {
                                    hd: Css.borderBottomRightRadius(showSimilarNames ? Css.px(0) : Css.px(10)),
                                    tl: {
                                      hd: Css.borderBottomLeftRadius(showSimilarNames ? Css.px(0) : Css.px(10)),
                                      tl: {
                                        hd: Css.overflow("hidden"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(AdvancedNameInput__SimilarNames.make, {
                        similarNames: similarNames,
                        getPossibleItemLink: getPossibleItemLink,
                        onClose: onClose,
                        itemType: itemType
                      })) : null);
}

var make = NameInput;

export {
  make ,
}
/* Css Not a pure module */
