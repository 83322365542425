// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as TextButton from "./TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as NameInputUtils from "./NameInputUtils.mjs";

function AdvancedNameInput__SimilarNames(Props) {
  var similarNames = Props.similarNames;
  var getPossibleItemLink = Props.getPossibleItemLink;
  var onClose = Props.onClose;
  var itemType = Props.itemType;
  var mainModel = ModelStore.useMainModel();
  var itemName = itemType === "Property" ? "property" : (
      itemType === "Event" ? "event" : "item"
    );
  var itemNamePlural = itemType === "Property" ? "properties" : (
      itemType === "Event" ? "events" : "items"
    );
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.light03),
                              tl: {
                                hd: Css.overflow("hidden"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  children: "Similar to " + AvoUtils.plural(itemNamePlural, true, similarNames.length, itemName) + ":"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(8)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, Belt_Array.map(similarNames, (function (param) {
                        var maybeNamespaceItems = param[1];
                        var similarName = param[0];
                        var path = NameInputUtils.getItemLink(similarName, maybeNamespaceItems, getPossibleItemLink, itemType);
                        var tmp;
                        if (maybeNamespaceItems !== undefined && maybeNamespaceItems.length !== 0) {
                          var namespaceIndexItem = Belt_Array.getExn(maybeNamespaceItems, 0);
                          tmp = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 4
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Regular",
                                    color: Styles.Color.light11,
                                    children: "on"
                                  }), React.createElement(Spacer.make, {
                                    width: 4
                                  }), React.createElement(Icon.make, {
                                    type_: "branch",
                                    size: {
                                      NAME: "int",
                                      VAL: 12
                                    },
                                    color: Styles.Color.light11
                                  }), React.createElement(Spacer.make, {
                                    width: 4
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Regular",
                                    color: Styles.Color.light11,
                                    children: NamedBranch.getName(NamedBranch.fromUnnamed(mainModel, {
                                              NAME: "Branch",
                                              VAL: namespaceIndexItem.branchId
                                            }))
                                  }), maybeNamespaceItems.length > 1 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                          width: 4
                                        }), React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Regular",
                                          color: Styles.Color.light11,
                                          children: "and " + AvoUtils.plural("other branches", undefined, maybeNamespaceItems.length - 1 | 0, "other branch")
                                        })) : null);
                        } else {
                          tmp = null;
                        }
                        return React.createElement("div", {
                                    key: similarName + "-" + Belt_Option.mapWithDefault(maybeNamespaceItems, "this-branch", (function (param) {
                                            return "other-branches";
                                          })),
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.flexDirection("row"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, path !== undefined ? React.createElement(TextButton.make, {
                                          onClick: (function ($$event) {
                                              if ($$event.metaKey) {
                                                window.open(path, "_blank");
                                                return ;
                                              } else {
                                                Router.push(undefined, path);
                                                return Curry._1(onClose, undefined);
                                              }
                                            }),
                                          children: React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Semi",
                                                color: Styles.Color.darkBlue,
                                                children: similarName
                                              })
                                        }) : React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          color: Styles.Color.light12,
                                          children: similarName
                                        }), tmp);
                      }))));
}

var make = AdvancedNameInput__SimilarNames;

export {
  make ,
}
/* Css Not a pure module */
