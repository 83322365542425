// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

var TaskAborted = /* @__PURE__ */Caml_exceptions.create("LongTask.TaskAborted");

function shouldYield(batchDurationOpt, timeOfLastYield) {
  var batchDuration = batchDurationOpt !== undefined ? batchDurationOpt : 66;
  var now = performance.now();
  if (now - timeOfLastYield.contents > batchDuration) {
    timeOfLastYield.contents = now;
    return true;
  } else {
    return false;
  }
}

function shouldAbort(report, signal) {
  if (signal === undefined) {
    return ;
  }
  if (!Caml_option.valFromOption(signal).aborted) {
    return ;
  }
  if (report) {
    console.info("Task aborted");
  }
  throw {
        RE_EXN_ID: TaskAborted,
        Error: new Error()
      };
}

function onReport(iterationsInBatch, iterationsInBatchCount, currentCycleCount) {
  iterationsInBatch.contents = Belt_Array.concatMany([
        iterationsInBatch.contents,
        [[
            performance.now(),
            currentCycleCount - iterationsInBatchCount.contents | 0
          ]]
      ]);
  iterationsInBatchCount.contents = currentCycleCount;
}

function shouldReport(batchDurationOpt, iterationsInBatch, totalLength) {
  var batchDuration = batchDurationOpt !== undefined ? batchDurationOpt : 66;
  var iterationsInBatch$1 = Belt_Array.reduceWithIndex(Belt_Array.concatMany([
              iterationsInBatch.contents,
              [[
                  performance.now(),
                  totalLength - Belt_Array.reduce(iterationsInBatch.contents, 0, (function (acc, param) {
                          return acc + param[1] | 0;
                        })) | 0
                ]]
            ]), [
          [],
          0.0
        ], (function (param, param$1, i) {
            var ts = param$1[0];
            var acc = param[0];
            if (i === 0) {
              return [
                      acc,
                      ts
                    ];
            } else {
              return [
                      Belt_Array.concatMany([
                            acc,
                            [[
                                ts - param[1],
                                param$1[1]
                              ]]
                          ]),
                      ts
                    ];
            }
          }))[0];
  var totalTime = Belt_Array.reduce(iterationsInBatch$1, 0.0, (function (acc, param) {
          return acc + param[0];
        }));
  var averageTime = totalTime / iterationsInBatch$1.length;
  var averageCount = Caml_int32.div(totalLength, iterationsInBatch$1.length);
  var maxCount = Caml_splice_call.spliceApply(Math.max, [Belt_Array.map(iterationsInBatch$1, (function (prim) {
                return prim[1];
              }))]);
  var minCount = Caml_splice_call.spliceApply(Math.min, [Belt_Array.map(iterationsInBatch$1, (function (prim) {
                return prim[1];
              }))]);
  console.info({
        "Batch duration": batchDuration,
        "Total time": totalTime.toFixed(2) + "ms",
        "Total batches": iterationsInBatch$1.length,
        "Average time per batch": averageTime.toFixed(2) + "ms",
        "Average time per iteration": (totalTime / totalLength).toFixed(5),
        "Total iterations": totalLength,
        "Average iterations per batch": averageCount,
        "Max iterations in batch": maxCount,
        "Min iterations in batch": minCount,
        Batches: Belt_Array.mapWithIndex(iterationsInBatch$1, (function (i, param) {
                var count = param[1];
                var time = param[0];
                return {
                        Batch: i + 1 | 0,
                        Time: time.toFixed(2) + "ms",
                        Iterations: count,
                        "Average time per iteration": (time / count).toFixed(5)
                      };
              }))
      });
}

async function filter(batchDuration, signal, reportOpt, m, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var keys = Belt_MapString.keysToArray(m);
    var result = {};
    var dict = Js_dict.fromArray(Belt_MapString.toArray(m));
    for(var i = 0 ,i_finish = keys.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      var key = Belt_Array.getExn(keys, i);
      var value = dict[key];
      var keep = Curry._2(fn, key, value);
      if (keep) {
        result[key] = value;
      }
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, Belt_MapString.size(m));
    }
    return {
            NAME: "success",
            VAL: Belt_MapString.fromArray(Js_dict.entries(result))
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

async function map(batchDuration, signal, reportOpt, m, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var keys = Belt_MapString.keysToArray(m);
    var result = {};
    var dict = Js_dict.fromArray(Belt_MapString.toArray(m));
    for(var i = 0 ,i_finish = keys.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      var key = Belt_Array.getExn(keys, i);
      var value = dict[key];
      var newValue = Curry._1(fn, value);
      result[key] = newValue;
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, Belt_MapString.size(m));
    }
    return {
            NAME: "success",
            VAL: Belt_MapString.fromArray(Js_dict.entries(result))
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

async function mapWithKey(batchDuration, signal, reportOpt, m, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var keys = Belt_MapString.keysToArray(m);
    var result = {};
    var dict = Js_dict.fromArray(Belt_MapString.toArray(m));
    for(var i = 0 ,i_finish = keys.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      var key = Belt_Array.getExn(keys, i);
      var value = dict[key];
      var newValue = Curry._2(fn, key, value);
      result[key] = newValue;
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, Belt_MapString.size(m));
    }
    return {
            NAME: "success",
            VAL: Belt_MapString.fromArray(Js_dict.entries(result))
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

async function filterMap(batchDuration, signal, reportOpt, m, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var keys = Belt_MapString.keysToArray(m);
    var result = {};
    var dict = Js_dict.fromArray(Belt_MapString.toArray(m));
    for(var i = 0 ,i_finish = keys.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      var key = Belt_Array.getExn(keys, i);
      var value = dict[key];
      var keep = Curry._2(fn, key, value);
      if (Belt_Option.isSome(keep)) {
        result[key] = Belt_Option.getExn(keep);
      }
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, Belt_MapString.size(m));
    }
    return {
            NAME: "success",
            VAL: Belt_MapString.fromArray(Js_dict.entries(result))
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

var StringMap = {
  filter: filter,
  map: map,
  mapWithKey: mapWithKey,
  filterMap: filterMap
};

async function reduce(batchDuration, signal, reportOpt, arr, initial, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var result = initial;
    for(var i = 0 ,i_finish = arr.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      result = Curry._2(fn, result, Belt_Array.getExn(arr, i));
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, arr.length);
    }
    return {
            NAME: "success",
            VAL: result
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

async function forEach(batchDuration, signal, reportOpt, arr, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    for(var i = 0 ,i_finish = arr.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      Curry._1(fn, Belt_Array.getExn(arr, i));
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, arr.length);
    }
    return {
            NAME: "success",
            VAL: undefined
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

async function map$1(batchDuration, signal, reportOpt, arr, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var result = [];
    for(var i = 0 ,i_finish = arr.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      var newValue = Curry._1(fn, Belt_Array.getExn(arr, i));
      result.push(newValue);
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, arr.length);
    }
    return {
            NAME: "success",
            VAL: result
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

async function mapWithIndex(batchDuration, signal, reportOpt, arr, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var result = [];
    for(var i = 0 ,i_finish = arr.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      var newValue = Curry._2(fn, i, Belt_Array.getExn(arr, i));
      result.push(newValue);
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, arr.length);
    }
    return {
            NAME: "success",
            VAL: result
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

async function filterMap$1(batchDuration, signal, reportOpt, arr, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var result = [];
    for(var i = 0 ,i_finish = arr.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      var value = Belt_Array.getExn(arr, i);
      if (Curry._1(fn, value) !== undefined) {
        result.push(Belt_Option.getExn(Curry._1(fn, value)));
      }
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, arr.length);
    }
    return {
            NAME: "success",
            VAL: result
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

async function filter$1(batchDuration, signal, reportOpt, arr, fn) {
  var report = reportOpt !== undefined ? reportOpt : false;
  var timeOfLastYield = {
    contents: performance.now()
  };
  var iterationsInBatch = {
    contents: [[
        timeOfLastYield.contents,
        0
      ]]
  };
  var iterationsInBatchCount = {
    contents: 0
  };
  try {
    var result = [];
    for(var i = 0 ,i_finish = arr.length; i < i_finish; ++i){
      shouldAbort(report, signal);
      var value = Belt_Array.getExn(arr, i);
      if (Curry._1(fn, value)) {
        result.push(value);
      }
      if (shouldYield(batchDuration, timeOfLastYield)) {
        if (report) {
          onReport(iterationsInBatch, iterationsInBatchCount, i + 1 | 0);
        }
        await scheduler.yield();
      }
      
    }
    if (report) {
      shouldReport(batchDuration, iterationsInBatch, arr.length);
    }
    return {
            NAME: "success",
            VAL: result
          };
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn.RE_EXN_ID === TaskAborted) {
      return "aborted";
    }
    throw exn;
  }
}

var $$Array = {
  reduce: reduce,
  forEach: forEach,
  map: map$1,
  mapWithIndex: mapWithIndex,
  filterMap: filterMap$1,
  filter: filter$1
};

async function reduce$1(batchDuration, signal, report, l, initial, fn) {
  return await reduce(batchDuration, signal, report, Belt_List.toArray(l), initial, fn);
}

async function forEach$1(batchDuration, signal, report, l, fn) {
  return await forEach(batchDuration, signal, report, Belt_List.toArray(l), fn);
}

async function map$2(batchDuration, signal, report, l, fn) {
  var match = await map$1(batchDuration, signal, report, Belt_List.toArray(l), fn);
  if (typeof match === "object") {
    return {
            NAME: "success",
            VAL: Belt_List.fromArray(match.VAL)
          };
  } else {
    return "aborted";
  }
}

async function mapWithIndex$1(batchDuration, signal, report, l, fn) {
  var match = await mapWithIndex(batchDuration, signal, report, Belt_List.toArray(l), fn);
  if (typeof match === "object") {
    return {
            NAME: "success",
            VAL: Belt_List.fromArray(match.VAL)
          };
  } else {
    return "aborted";
  }
}

async function filterMap$2(batchDuration, signal, report, l, fn) {
  var match = await filterMap$1(batchDuration, signal, report, Belt_List.toArray(l), fn);
  if (typeof match === "object") {
    return {
            NAME: "success",
            VAL: Belt_List.fromArray(match.VAL)
          };
  } else {
    return "aborted";
  }
}

async function filter$2(batchDuration, signal, report, l, fn) {
  var match = await filter$1(batchDuration, signal, report, Belt_List.toArray(l), fn);
  if (typeof match === "object") {
    return {
            NAME: "success",
            VAL: Belt_List.fromArray(match.VAL)
          };
  } else {
    return "aborted";
  }
}

var List = {
  reduce: reduce$1,
  forEach: forEach$1,
  map: map$2,
  mapWithIndex: mapWithIndex$1,
  filterMap: filterMap$2,
  filter: filter$2
};

export {
  TaskAborted ,
  StringMap ,
  $$Array ,
  List ,
}
/* No side effect */
