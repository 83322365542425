// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FloatingUI from "./externals/FloatingUI.mjs";
import Tinycolor2 from "tinycolor2";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as React$1 from "@floating-ui/react";

var itemWrapper = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.minHeight(Css.px(40)),
          tl: {
            hd: Css.paddingLeft(Css.px(10)),
            tl: {
              hd: Css.paddingRight(Css.px(44)),
              tl: {
                hd: Css.paddingTop(Css.px(4)),
                tl: {
                  hd: Css.paddingBottom(Css.px(4)),
                  tl: {
                    hd: Css.margin("zero"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function input(style, color) {
  return Belt_List.concatMany([
              {
                hd: Css.borderColor(Styles.Color.light04),
                tl: {
                  hd: Css.textOverflow("ellipsis"),
                  tl: {
                    hd: Css.whiteSpace("nowrap"),
                    tl: {
                      hd: Css.overflow("hidden"),
                      tl: {
                        hd: Css.paddingRight(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              },
              style === "default" ? /* [] */0 : ({
                    hd: Css.backgroundColor(color),
                    tl: {
                      hd: Css.borderColor(color),
                      tl: {
                        hd: Css.focusWithin({
                              hd: Css.borderColor(Styles.Color.darken(color, 40)),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.borderRadius(Css.px(16)),
                          tl: {
                            hd: Css.paddingTop(Css.px(4)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(4)),
                              tl: {
                                hd: Css.minHeight(Css.px(24)),
                                tl: {
                                  hd: Css.height(Css.px(24)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            ]);
}

var dropdown_0 = Css.borderWidth(Css.px(1));

var dropdown_1 = {
  hd: Css.borderColor(Styles.Color.light04),
  tl: {
    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                  NAME: "num",
                  VAL: 0.10
                }))),
    tl: {
      hd: Css.minWidth("maxContent"),
      tl: {
        hd: Css.marginTop(Css.px(0)),
        tl: /* [] */0
      }
    }
  }
};

var dropdown = {
  hd: dropdown_0,
  tl: dropdown_1
};

var itemsWrapper_0 = Css.padding("zero");

var itemsWrapper_1 = {
  hd: Css.paddingTop(Css.px(6)),
  tl: {
    hd: Css.paddingBottom(Css.px(8)),
    tl: /* [] */0
  }
};

var itemsWrapper = {
  hd: itemsWrapper_0,
  tl: itemsWrapper_1
};

var item_0 = Css.display("flex");

var item_1 = {
  hd: Css.alignItems("center"),
  tl: {
    hd: Css.margin("zero"),
    tl: {
      hd: Css.marginBottom(Css.px(1)),
      tl: {
        hd: Css.padding("zero"),
        tl: {
          hd: Css.paddingRight(Css.px(32)),
          tl: {
            hd: Css.borderRadius("zero"),
            tl: {
              hd: Css.transitions({
                    hd: Css_Legacy_Core.Transition.shorthand({
                          NAME: "ms",
                          VAL: Styles.Duration.$$short
                        }, undefined, undefined, "background-color"),
                    tl: {
                      hd: Css_Legacy_Core.Transition.shorthand({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "color"),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.hover({
                      hd: Css.backgroundColor(Styles.Color.light02),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.fontFamily(Styles.FontFamily.inter),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.selector(" input", {
                            hd: Css.important(Css.fontFamily(Styles.FontFamily.inter)),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.selector("&[data-disabled]", {
                              hd: Css.color(Styles.Color.light08),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.backgroundColor("transparent"),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var item = {
  hd: item_0,
  tl: item_1
};

var itemSelected_0 = Css.color(Styles.Color.light12);

var itemSelected_1 = {
  hd: Css.backgroundColor(Styles.Color.white),
  tl: {
    hd: Css.position("relative"),
    tl: {
      hd: Css.hover({
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.after({
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.pct(50.0)),
                tl: {
                  hd: Css.right(Css.px(12)),
                  tl: {
                    hd: Css.marginTop(Css.px(-8)),
                    tl: {
                      hd: Css.width(Css.px(16)),
                      tl: {
                        hd: Css.height(Css.px(16)),
                        tl: {
                          hd: Css.unsafe("content", "url(\"data:image/svg+xml,%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%3E%3Cpath%20fill%3D%22%23FF0EB4%22%20d%3D%22m14.53%205.03-8%208a.75.75%200%200%201-1.06%200l-3.5-3.5a.75.75%200%201%201%201.06-1.06L6%2011.44l7.47-7.47a.75.75%200%200%201%201.06%201.06Z%22%2F%3E%3C%2Fsvg%3E\")"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    }
  }
};

var itemSelected = {
  hd: itemSelected_0,
  tl: itemSelected_1
};

var separatorLabel_0 = Css.color(Styles.Color.light11);

var separatorLabel_1 = {
  hd: Css.fontWeight(Styles.FontWeight.bold),
  tl: {
    hd: Css.fontSize(Styles.FontSize.small),
    tl: {
      hd: Css.letterSpacing(Css.em(-0.04)),
      tl: {
        hd: Css.textTransform("uppercase"),
        tl: {
          hd: Css.height(Css.px(30)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.margin("zero"),
              tl: {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.after({
                          hd: Css.display("none"),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.paddingLeft(Css.px(10)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var separatorLabel = {
  hd: separatorLabel_0,
  tl: separatorLabel_1
};

var separator_0 = Css.margin("zero");

var separator_1 = {
  hd: Css.marginBottom(Css.px(1)),
  tl: {
    hd: Css.padding("zero"),
    tl: {
      hd: Css.selector(".mantine-Divider-horizontal", {
            hd: Css.margin("zero"),
            tl: {
              hd: Css.marginLeft(Css.px(-10)),
              tl: {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.paddingLeft(Css.px(10)),
                  tl: {
                    hd: Css.borderColor(Styles.Color.light04),
                    tl: {
                      hd: Css.borderTopWidth(Css.pxFloat(0.5)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }),
      tl: {
        hd: Css.selector(":not(:has(.mantine-Select-separatorLabel))", {
              hd: Css.marginTop(Css.pxFloat(7.5)),
              tl: {
                hd: Css.marginBottom(Css.pxFloat(7.5)),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    }
  }
};

var separator = {
  hd: separator_0,
  tl: separator_1
};

function wrapper(customMinWidth, customMaxWidth) {
  return {
          hd: Belt_Option.mapWithDefault(customMinWidth, Styles.emptyStyle, (function (w) {
                  return Css.minWidth(Css.px(w));
                })),
          tl: {
            hd: Belt_Option.mapWithDefault(customMaxWidth, Styles.emptyStyle, (function (w) {
                    return Css.maxWidth(Css.px(w));
                  })),
            tl: /* [] */0
          }
        };
}

var root_0 = Css.fontFamily(Styles.FontFamily.inter);

var root = {
  hd: root_0,
  tl: /* [] */0
};

var Style = {
  itemWrapper: itemWrapper,
  input: input,
  dropdown: dropdown,
  itemsWrapper: itemsWrapper,
  item: item,
  itemSelected: itemSelected,
  separatorLabel: separatorLabel,
  separator: separator,
  wrapper: wrapper,
  root: root
};

function AvoSelect$ItemComponent(Props) {
  var item = Props.item;
  var description = item.description;
  return React.createElement("div", {
              className: itemWrapper
            }, React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(item.disabled), Styles.Color.light10, (function (disabled) {
                              if (disabled) {
                                return Styles.Color.light09;
                              } else {
                                return Styles.Color.light10;
                              }
                            })),
                      maxWidth: Css.pct(100.0),
                      children: item.label
                    }), description !== undefined ? React.createElement("div", {
                        className: "avo-select-item-description"
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light08,
                            withWordBreak: true,
                            children: description
                          })) : null));
}

var ItemComponent = Mantine.Select.Item({
      make: AvoSelect$ItemComponent
    });

function toMantineItem(item) {
  var tmp = {
    label: item.label,
    value: item.value
  };
  var tmp$1 = Caml_option.undefined_to_opt(item.description);
  if (tmp$1 !== undefined) {
    tmp.description = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Caml_option.undefined_to_opt(item.icon);
  if (tmp$2 !== undefined) {
    tmp.icon = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = Caml_option.undefined_to_opt(item.group);
  if (tmp$3 !== undefined) {
    tmp.group = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = Caml_option.undefined_to_opt(item.disabled);
  if (tmp$4 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(tmp$4);
  }
  return tmp;
}

var toMantineItemU = toMantineItem;

var Item = {
  toMantineItem: toMantineItem,
  toMantineItemU: toMantineItemU
};

function AvoSelect(Props) {
  var data = Props.data;
  var defaultValue = Props.defaultValue;
  var disabledOpt = Props.disabled;
  var maxHeightOpt = Props.maxHeight;
  var minWidth = Props.minWidth;
  var maxWidth = Props.maxWidth;
  var onChange = Props.onChange;
  var value = Props.value;
  var styleOpt = Props.style;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var maxHeight = maxHeightOpt !== undefined ? maxHeightOpt : 500;
  var style = styleOpt !== undefined ? styleOpt : "default";
  var color = React.useMemo((function (param) {
          if (value === undefined) {
            return "transparent";
          }
          var value$1 = Caml_option.valFromOption(value);
          return Belt_Option.getWithDefault(Belt_Option.flatMapU(Belt_Array.getByU(data, (function (item) {
                                return Caml_obj.equal(item.value, value$1);
                              })), (function (item) {
                            return Caml_option.undefined_to_opt(item.color);
                          })), "transparent");
        }), [value]);
  var data$1 = Belt_Array.mapU(data, toMantineItemU);
  var tmp = {
    data: data$1,
    disabled: disabled,
    dropdownStyles: dropdown,
    inputStyles: input(style, color),
    itemComponent: ItemComponent.make,
    itemStyles: item,
    itemSelectedStyles: itemSelected,
    itemsWrapperStyles: itemsWrapper,
    maxDropdownHeight: maxHeight,
    onChange: onChange,
    rightSection: React.createElement(Icon.make, {
          type_: "chevronDown",
          size: "small",
          color: Styles.Color.light10
        }),
    rightSectionWidth: 32,
    separatorStyles: separator,
    separatorLabelStyles: separatorLabel,
    size: "xs",
    transition: "fade",
    transitionDuration: 130,
    wrapperStyles: wrapper(minWidth, maxWidth),
    rootStyles: root
  };
  if (defaultValue !== undefined) {
    tmp.defaultValue = Caml_option.valFromOption(defaultValue);
  }
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  return React.createElement(Mantine.Select.make, tmp);
}

function MakeFloatingSelect(C) {
  var getOpposingLuminance = function (c, luminanceEdge) {
    var hslColor = Tinycolor2(Styles.Color.toString(c)).toHsl();
    if (hslColor.l <= 0.5) {
      return Tinycolor2("hsla " + String(hslColor.h) + " " + String(hslColor.s) + " " + String(Math.max(0.0, 1.0 - luminanceEdge / 100.0)) + " " + String(hslColor.a));
    } else {
      return Tinycolor2("hsla " + String(hslColor.h) + " " + String(hslColor.s) + " " + String(Math.min(1.0, 0.0 + luminanceEdge / 100.0)) + " " + String(hslColor.a));
    }
  };
  var enhance = function (c, by_) {
    var match = Tinycolor2(Styles.Color.toString(c)).toHsl();
    var a = match.a;
    var luminance = match.l;
    var s = match.s;
    var h = match.h;
    if (luminance <= 0.9) {
      return Tinycolor2("hsla " + String(h) + " " + String(s) + " " + String(Math.min(1.0, luminance + by_ / 100.0)) + " " + String(a));
    } else {
      return Tinycolor2("hsla " + String(h) + " " + String(s) + " " + String(Math.max(0.0, luminance - by_ / 100.0)) + " " + String(a));
    }
  };
  var toCss = function (c) {
    return Css.hex(c.toHex8String().replace("#", ""));
  };
  var rootMenu = function (c) {
    var textColor = toCss(getOpposingLuminance(c, 15));
    var disabledColor = toCss(getOpposingLuminance(c, 30).desaturate(100));
    var chevronColor = toCss(getOpposingLuminance(c, 20));
    return Curry._1(Css.merge, {
                hd: Curry._1(Css.style, Styles.buttonReset),
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(8)),
                        tl: {
                          hd: Css.borderWidth(Css.px(1)),
                          tl: {
                            hd: Css.background("none"),
                            tl: {
                              hd: Css.backgroundColor(c),
                              tl: {
                                hd: Css.borderColor(c),
                                tl: {
                                  hd: Css.borderRadius(Css.px(16)),
                                  tl: {
                                    hd: Css.paddingTop(Css.px(4)),
                                    tl: {
                                      hd: Css.paddingBottom(Css.px(4)),
                                      tl: {
                                        hd: Css.minHeight(Css.px(24)),
                                        tl: {
                                          hd: Css.height(Css.px(24)),
                                          tl: {
                                            hd: Css.textOverflow("ellipsis"),
                                            tl: {
                                              hd: Css.whiteSpace("nowrap"),
                                              tl: {
                                                hd: Css.overflow("hidden"),
                                                tl: {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: {
                                                      hd: Css.selector("&[data-open],&[aria-expanded=true]", {
                                                            hd: Css.borderColor(toCss(enhance(c, 60))),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: {
                                                        hd: Css.color(Styles.Color.light10),
                                                        tl: {
                                                          hd: Css.transition({
                                                                NAME: "ms",
                                                                VAL: Styles.Duration.$$short
                                                              }, undefined, undefined, "all"),
                                                          tl: {
                                                            hd: Css.selector("&.root", {
                                                                  hd: Css.color(textColor),
                                                                  tl: {
                                                                    hd: Css.disabled({
                                                                          hd: Css.color(disabledColor),
                                                                          tl: {
                                                                            hd: Icon.color(disabledColor),
                                                                            tl: /* [] */0
                                                                          }
                                                                        }),
                                                                    tl: {
                                                                      hd: Css.selector(":hover[aria-expanded=false]", {
                                                                            hd: Css.borderColor(toCss(enhance(c, 40))),
                                                                            tl: /* [] */0
                                                                          }),
                                                                      tl: {
                                                                        hd: Css.focusWithin({
                                                                              hd: Css.outlineWidth(Css.px(0)),
                                                                              tl: {
                                                                                hd: Css.borderColor(toCss(enhance(c, 60))),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }),
                                                            tl: {
                                                              hd: Css.selector(" .root-menu-chevron-down", {
                                                                    hd: Css.height(Css.px(16)),
                                                                    tl: {
                                                                      hd: Icon.color(chevronColor),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              });
  };
  var menuItem = Curry._1(Css.merge, {
        hd: Curry._1(Css.style, Styles.buttonReset),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.gap(Css.px(2)),
                      tl: {
                        hd: Css.background("none"),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: {
                            hd: Css.borderWidth("zero"),
                            tl: {
                              hd: Css.outlineWidth("zero"),
                              tl: {
                                hd: Css.textAlign("left"),
                                tl: {
                                  hd: Css.minWidth(Css.px(110)),
                                  tl: {
                                    hd: Css.margin("zero"),
                                    tl: {
                                      hd: Css.padding(Css.px(10)),
                                      tl: {
                                        hd: Css.paddingLeft(Css.px(20)),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(40)),
                                          tl: {
                                            hd: Css.color(Styles.Color.light10),
                                            tl: {
                                              hd: Icon.color(Styles.Color.light09),
                                              tl: {
                                                hd: Css.disabled({
                                                      hd: Css.color(Styles.Color.light08),
                                                      tl: /* [] */0
                                                    }),
                                                tl: {
                                                  hd: Css.active({
                                                        hd: Css.outlineWidth(Css.px(0)),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: {
                                                    hd: Css.selector(":hover:not(:disabled)", {
                                                          hd: Css.background(Styles.Color.light02),
                                                          tl: {
                                                            hd: Css.color(Styles.Color.light12),
                                                            tl: {
                                                              hd: Icon.color(Styles.Color.light11),
                                                              tl: {
                                                                hd: Css.selector(" > .description", {
                                                                      hd: Css.color(Styles.Color.light09),
                                                                      tl: /* [] */0
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: Css.focus({
                                                            hd: Css.outlineWidth(Css.px(0)),
                                                            tl: {
                                                              hd: Css.background(Styles.Color.light02),
                                                              tl: {
                                                                hd: Css.color(Styles.Color.light12),
                                                                tl: {
                                                                  hd: Icon.color(Styles.Color.light11),
                                                                  tl: {
                                                                    hd: Css.selector(" > .description", {
                                                                          hd: Css.color(Styles.Color.light09),
                                                                          tl: /* [] */0
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }),
                                                      tl: {
                                                        hd: Css.selector(" > .description", {
                                                              hd: Css.color(Styles.Color.light08),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: {
                                                          hd: Css.selector(":has(.description)", {
                                                                hd: Css.paddingTop(Css.px(2)),
                                                                tl: {
                                                                  hd: Css.paddingBottom(Css.px(2)),
                                                                  tl: /* [] */0
                                                                }
                                                              }),
                                                          tl: {
                                                            hd: Css.focusWithin({
                                                                  hd: Css.unsafe("outline", "0"),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      });
  var menu = Curry._1(Css.merge, {
        hd: Curry._1(Css.style, Styles.buttonReset),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.background(Styles.Color.white),
                tl: {
                  hd: Css.padding(Css.px(12)),
                  tl: {
                    hd: Css.selector("&[data-nested]", {
                          hd: Css.padding(Css.px(10)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(20)),
                            tl: /* [] */0
                          }
                        }),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.outlineWidth("zero"),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: {
                                hd: Css.minWidth(Css.px(140)),
                                tl: {
                                  hd: Css.color(Styles.Color.light10),
                                  tl: {
                                    hd: Icon.color(Styles.Color.light09),
                                    tl: {
                                      hd: Css.selector("&[data-nested]:focus-within", {
                                            hd: Css.background(Styles.Color.light02),
                                            tl: {
                                              hd: Css.outlineWidth(Css.px(0)),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: {
                                                  hd: Icon.color(Styles.Color.light11),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }),
                                      tl: {
                                        hd: Css.selector("&[data-focus-inside][data-open]", {
                                              hd: Css.background(Styles.Color.light02),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.focusWithin({
                                                hd: Css.unsafe("outline", "0"),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      });
  var dropdown = Curry._1(Css.merge, {
        hd: menu,
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.minWidth(Css.px(240)),
                tl: {
                  hd: Css.padding2(Css.px(6), Css.px(0)),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.borderRadius(Css.px(6)),
                      tl: {
                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                                      NAME: "num",
                                      VAL: 0.10
                                    }))),
                        tl: {
                          hd: Css.focusWithin({
                                hd: Css.unsafe("outline", "0"),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      });
  var Style = {
    getOpposingLuminance: getOpposingLuminance,
    enhance: enhance,
    toCss: toCss,
    rootMenu: rootMenu,
    menuItem: menuItem,
    menu: menu,
    dropdown: dropdown
  };
  var removeProp = ((p, key) => {
  if (p.hasOwnProperty(key)) {
    const { ...newProps } = p
    delete newProps[key]
    return newProps;
  } else {
    return p;
  }
});
  var removeProps = function (props, propsKeys) {
    return Belt_Array.reduceU(propsKeys, props, removeProp);
  };
  var AvoSelect$MakeFloatingSelect$Spread = function (Props) {
    var beforeProps = Props.beforeProps;
    var afterProps = Props.afterProps;
    var children = Props.children;
    var props = Object.assign(Object.assign(Object.assign({}, Belt_Option.getWithDefault(beforeProps, {})), Belt_Option.mapWithDefault(Belt_Array.get(React.Children.toArray(children), 0), {}, (function (c) {
                    return c.props;
                  }))), Belt_Option.getWithDefault(afterProps, {}));
    return React.cloneElement(children, props);
  };
  var Spread = {
    removeProp: removeProp,
    removeProps: removeProps,
    make: AvoSelect$MakeFloatingSelect$Spread
  };
  var buildProps = function (a, b) {
    return Object.assign(a, b);
  };
  var context = React.createContext({
        getItemProps: (function (param) {
            return {};
          }),
        activeIndex: undefined,
        setActiveIndex: (function (param) {
            
          }),
        setHasFocusInside: (function (param) {
            
          }),
        isOpen: false,
        keepOpen: false
      });
  var provider = context.Provider;
  var use = function (param) {
    return React.useContext(context);
  };
  var AvoSelect$MakeFloatingSelect$MenuContext = function (Props) {
    var value = Props.value;
    var children = Props.children;
    return React.createElement(provider, {
                value: value,
                children: children
              });
  };
  var MenuContext = {
    buildProps: buildProps,
    context: context,
    provider: provider,
    use: use,
    make: AvoSelect$MakeFloatingSelect$MenuContext
  };
  var AvoSelect$MakeFloatingSelect$LabelComponent = function (Props) {
    var label = Props.label;
    var icon = Props.icon;
    var isNested = Props.isNested;
    var isRoot = Props.isRoot;
    if (isNested) {
      return React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, Belt_Option.mapWithDefault(icon, null, (function (icon) {
                        return React.createElement(React.Fragment, undefined, icon, React.createElement(Spacer.make, {
                                        width: 4
                                      }));
                      })), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      children: label
                    }), React.createElement(Spacer.make, {
                      width: 4,
                      grow: 1.0
                    }), React.createElement(Icon.make, {
                      type_: "chevronRight",
                      size: "small"
                    }));
    } else if (isRoot) {
      return React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, Belt_Option.mapWithDefault(icon, null, (function (icon) {
                        return React.createElement(React.Fragment, undefined, icon, React.createElement(Spacer.make, {
                                        width: 4
                                      }));
                      })), React.createElement($$Text.make, {
                      size: "Small",
                      children: label
                    }), React.createElement(Spacer.make, {
                      width: 4,
                      grow: 1.0
                    }), React.createElement("span", {
                      className: "root-menu-chevron-down"
                    }, React.createElement(Icon.make, {
                          type_: "chevronDown",
                          size: "small"
                        })));
    } else {
      return React.createElement(React.Fragment, undefined, Belt_Option.mapWithDefault(icon, null, (function (icon) {
                        return React.createElement(React.Fragment, undefined, icon, React.createElement(Spacer.make, {
                                        width: 4
                                      }));
                      })), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      children: label
                    }));
    }
  };
  var LabelComponent = {
    make: AvoSelect$MakeFloatingSelect$LabelComponent
  };
  var AvoSelect$MakeFloatingSelect$DescriptionComponent = function (Props) {
    var description = Props.description;
    return React.createElement("span", {
                className: "description"
              }, React.createElement($$Text.make, {
                    size: "Tiny",
                    children: description
                  }));
  };
  var DescriptionComponent = {
    make: AvoSelect$MakeFloatingSelect$DescriptionComponent
  };
  var AvoSelect$MakeFloatingSelect$WithPortal = function (Props) {
    var withPortal = Props.withPortal;
    var children = Props.children;
    if (withPortal) {
      return React.createElement(FloatingUI.FloatingPortal.make, {
                  children: children
                });
    } else {
      return children;
    }
  };
  var WithPortal = {
    make: AvoSelect$MakeFloatingSelect$WithPortal
  };
  var propsList = [
    "label",
    "labelComponent",
    "value",
    "description",
    "descriptionComponent",
    "icon",
    "hasInput",
    "keepOpen",
    "isRoot",
    "onFocusChange",
    "onOpenChange",
    "menuStyles",
    "dropdownStyles",
    "rootMenuStyles",
    "color",
    "children"
  ];
  var make = React.forwardRef(function (Props, ref_) {
        var label = Props.label;
        var labelComponentOpt = Props.labelComponent;
        var value = Props.value;
        var description = Props.description;
        var descriptionComponentOpt = Props.descriptionComponent;
        var icon = Props.icon;
        var keepOpenOpt = Props.keepOpen;
        var isRootOpt = Props.isRoot;
        var onFocusChange = Props.onFocusChange;
        var onOpenChange = Props.onOpenChange;
        var onUpdate = Props.onUpdate;
        var menuStylesOpt = Props.menuStyles;
        var dropdownStylesOpt = Props.dropdownStyles;
        var rootMenuStylesOpt = Props.rootMenuStyles;
        var colorOpt = Props.color;
        var disabled = Props.disabled;
        var onClick = Props.onClick;
        var onFocus = Props.onFocus;
        var withPortalOpt = Props.withPortal;
        var placementOpt = Props.placement;
        var children = Props.children;
        var labelComponent = labelComponentOpt !== undefined ? labelComponentOpt : AvoSelect$MakeFloatingSelect$LabelComponent;
        var descriptionComponent = descriptionComponentOpt !== undefined ? descriptionComponentOpt : AvoSelect$MakeFloatingSelect$DescriptionComponent;
        var keepOpen = keepOpenOpt !== undefined ? keepOpenOpt : false;
        var isRoot = isRootOpt !== undefined ? isRootOpt : false;
        var menuStyles = menuStylesOpt !== undefined ? menuStylesOpt : menu;
        var dropdownStyles = dropdownStylesOpt !== undefined ? dropdownStylesOpt : dropdown;
        var rootMenuStyles = rootMenuStylesOpt !== undefined ? rootMenuStylesOpt : rootMenu;
        var color = colorOpt !== undefined ? colorOpt : "transparent";
        var withPortal = withPortalOpt !== undefined ? withPortalOpt : false;
        var placement = placementOpt !== undefined ? placementOpt : "bottom-start";
        var match = React.useState(function (param) {
              return false;
            });
        var setIsOpen = match[1];
        var isOpen = match[0];
        var match$1 = React.useState(function (param) {
              return false;
            });
        var setHasFocusInside = match$1[1];
        var hasFocusInside = match$1[0];
        var match$2 = React.useState(function (param) {
              
            });
        var setActiveIndex = match$2[1];
        var activeIndex = match$2[0];
        var elementsRef = React.useRef([]);
        var labelsRef = React.useRef([]);
        var parent = React.useContext(context);
        var tree = FloatingUI.useFloatingTree();
        var nodeId = React$1.useFloatingNodeId();
        var parentId = FloatingUI.useFloatingParentNodeId();
        var item = React$1.useListItem();
        var isNested = Belt_Option.isSome(parentId);
        Hooks.useDidUpdate1((function (param) {
                Belt_Option.forEach(onFocusChange, (function (f) {
                        Curry._1(f, hasFocusInside);
                      }));
              }), [hasFocusInside]);
        var match$3 = React$1.useFloating({
              placement: isNested ? "right-start" : placement,
              middleware: [
                React$1.offset(function (param) {
                      return {
                              mainAxis: isNested ? -8 : 4,
                              alignmentAxis: isNested ? -7 : 0
                            };
                    }),
                React$1.flip(),
                React$1.shift()
              ],
              open: isOpen,
              onOpenChange: (function (open_) {
                  Curry._1(setIsOpen, (function (param) {
                          if (open_) {
                            return true;
                          } else {
                            return keepOpen;
                          }
                        }));
                  Belt_Option.forEach(onOpenChange, (function (f) {
                          Curry._1(f, open_ || keepOpen);
                        }));
                }),
              whileElementsMounted: (function (prim0, prim1, prim2, prim3) {
                  return React$1.autoUpdate(prim0, prim1, prim2, prim3);
                }),
              nodeId: nodeId
            });
        var refs = match$3.refs;
        var context$1 = match$3.context;
        var hover = React$1.useHover(context$1, {
              enabled: isNested && !keepOpen,
              delay: {
                open: 75,
                close: 75
              },
              handleClose: React$1.safePolygon({
                    requireIntent: false,
                    blockPointerEvents: true
                  })
            });
        var click = React$1.useClick(context$1, {
              event: "mousedown",
              toggle: !isNested,
              ignoreMouse: isNested
            });
        var role = React$1.useRole(context$1, {
              role: "menu"
            });
        var dismiss = React$1.useDismiss(context$1, {
              escapeKey: !keepOpen,
              bubbles: true
            });
        var listNavigation = React$1.useListNavigation(context$1, {
              listRef: elementsRef,
              activeIndex: activeIndex,
              onNavigate: (function (index) {
                  Curry._1(setActiveIndex, (function (param) {
                          return index;
                        }));
                }),
              enabled: !keepOpen,
              loop: true,
              nested: isNested
            });
        var match$4 = React$1.useInteractions([
              hover,
              click,
              role,
              dismiss,
              listNavigation
            ]);
        React.useEffect((function (param) {
                if (tree === undefined) {
                  return ;
                }
                var handleTreeClick = function (e) {
                  var match = e.value;
                  if (parentId === undefined && match !== undefined) {
                    var value = Caml_option.valFromOption(match);
                    Belt_Option.forEachU(onUpdate, (function (f) {
                            Curry._1(f, value);
                          }));
                  }
                  Curry._1(setIsOpen, (function (param) {
                          return keepOpen;
                        }));
                };
                var onSubMenuOpen = function ($$event) {
                  if ($$event.nodeId !== nodeId && Caml_obj.equal($$event.parentId, parentId)) {
                    return Curry._1(setIsOpen, (function (param) {
                                  return keepOpen;
                                }));
                  }
                  
                };
                tree.events.on("click", handleTreeClick);
                tree.events.on("menuopen", onSubMenuOpen);
                return (function (param) {
                          tree.events.off("click", handleTreeClick);
                          tree.events.off("menuopen", onSubMenuOpen);
                        });
              }), [
              tree,
              nodeId,
              parentId,
              keepOpen,
              children,
              value
            ]);
        React.useEffect((function (param) {
                if (tree !== undefined && isOpen) {
                  tree.events.emit("menuopen", {
                        nodeId: nodeId,
                        parentId: parentId
                      });
                }
                
              }), [
              tree,
              isOpen,
              nodeId,
              parentId
            ]);
        var tmp = {
          ref: React$1.useMergeRefs([
                refs.setReference,
                item.ref,
                ref_
              ]),
          className: isNested ? menuStyles : Curry._1(rootMenuStyles, color) + " root"
        };
        var tmp$1 = isNested ? "menuitem" : undefined;
        if (tmp$1 !== undefined) {
          tmp.role = Caml_option.valFromOption(tmp$1);
        }
        var tmp$2 = isNested ? (
            Caml_obj.equal(parent.activeIndex, item.index) ? 0 : -1
          ) : undefined;
        if (tmp$2 !== undefined) {
          tmp.tabIndex = Caml_option.valFromOption(tmp$2);
        }
        return React.createElement(React$1.FloatingNode, {
                    id: nodeId,
                    children: null
                  }, React.createElement(AvoSelect$MakeFloatingSelect$Spread, {
                        afterProps: Curry._1(match$4.getReferenceProps, Curry._1(parent.getItemProps, Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, removeProps(Props, propsList)), isOpen ? ({
                                                      "data-open": ""
                                                    }) : ({})), isNested ? ({
                                                  "data-nested": ""
                                                }) : ({})), hasFocusInside ? ({
                                              "data-focus-inside": ""
                                            }) : ({})), {
                                      onClick: (function (e) {
                                          Belt_Option.forEach(onClick, (function (f) {
                                                  Curry._1(f, e);
                                                }));
                                        }),
                                      onFocus: (function (e) {
                                          Belt_Option.forEach(onFocus, (function (f) {
                                                  Curry._1(f, e);
                                                }));
                                          Curry._1(setHasFocusInside, (function (param) {
                                                  return false;
                                                }));
                                          Curry._1(parent.setHasFocusInside, (function (param) {
                                                  return true;
                                                }));
                                        })
                                    }))),
                        children: React.createElement("button", tmp, Curry._1(labelComponent, {
                                  nodeId: nodeId,
                                  label: label,
                                  value: value,
                                  icon: icon,
                                  disabled: Belt_Option.getWithDefault(disabled, false),
                                  isNested: isNested,
                                  isRoot: isRoot,
                                  isActive: Belt_Option.mapWithDefault(activeIndex, false, (function (activeIndex) {
                                          return activeIndex === item.index;
                                        })),
                                  onClick: (function (e) {
                                      Belt_Option.forEach(onClick, (function (f) {
                                              Curry._1(f, e);
                                            }));
                                    }),
                                  update: (function (maybeValue) {
                                      Belt_Option.forEach(onUpdate, (function (f) {
                                              Curry._1(f, maybeValue);
                                            }));
                                    })
                                }), Belt_Option.mapWithDefault(description, null, (function (description) {
                                    return Curry._1(descriptionComponent, {
                                                description: description
                                              });
                                  })))
                      }), React.createElement(AvoSelect$MakeFloatingSelect$MenuContext, {
                        value: {
                          getItemProps: match$4.getItemProps,
                          activeIndex: activeIndex,
                          setActiveIndex: setActiveIndex,
                          setHasFocusInside: setHasFocusInside,
                          isOpen: isOpen,
                          keepOpen: keepOpen
                        },
                        children: React.createElement(React$1.FloatingList, {
                              elementsRef: elementsRef,
                              labelsRef: labelsRef,
                              children: isOpen ? React.createElement(AvoSelect$MakeFloatingSelect$WithPortal, {
                                      withPortal: withPortal,
                                      children: React.createElement(FloatingUI.FloatingFocusManager.make, {
                                            context: context$1,
                                            initialFocus: isNested ? ({
                                                  NAME: "number",
                                                  VAL: -1
                                                }) : ({
                                                  NAME: "number",
                                                  VAL: 0
                                                }),
                                            returnFocus: !isNested,
                                            modal: false,
                                            children: React.createElement(AvoSelect$MakeFloatingSelect$Spread, {
                                                  afterProps: Curry._1(match$4.getFloatingProps, undefined),
                                                  children: React.createElement("div", {
                                                        ref: refs.setFloating,
                                                        className: dropdownStyles + " dropdown",
                                                        style: match$3.floatingStyles
                                                      }, children)
                                                })
                                          })
                                    }) : null
                            })
                      }));
      });
  var MenuComponent = {
    propsList: propsList,
    make: make
  };
  var propsList$1 = [
    "hasInput",
    "keepOpen",
    "label",
    "labelComponent",
    "color",
    "icon",
    "onOpenChange",
    "onUpdate",
    "withPortal",
    "children"
  ];
  var make$1 = React.forwardRef(function (Props, ref_) {
        var keepOpen = Props.keepOpen;
        var label = Props.label;
        var labelComponentOpt = Props.labelComponent;
        var color = Props.color;
        var icon = Props.icon;
        var onOpenChange = Props.onOpenChange;
        var onUpdate = Props.onUpdate;
        var dropdownStylesOpt = Props.dropdownStyles;
        var menuStylesOpt = Props.menuStyles;
        var rootMenuStylesOpt = Props.rootMenuStyles;
        var placementOpt = Props.placement;
        var withPortal = Props.withPortal;
        var value = Props.value;
        var children = Props.children;
        var labelComponent = labelComponentOpt !== undefined ? labelComponentOpt : AvoSelect$MakeFloatingSelect$LabelComponent;
        var dropdownStyles = dropdownStylesOpt !== undefined ? dropdownStylesOpt : dropdown;
        var menuStyles = menuStylesOpt !== undefined ? menuStylesOpt : menu;
        var rootMenuStyles = rootMenuStylesOpt !== undefined ? rootMenuStylesOpt : rootMenu;
        var placement = placementOpt !== undefined ? placementOpt : "bottom-start";
        var props = removeProps(Props, propsList$1);
        var parentId = FloatingUI.useFloatingParentNodeId();
        if (Belt_Option.isSome(parentId)) {
          var tmp = {
            label: label,
            labelComponent: labelComponent,
            menuStyles: menuStyles,
            dropdownStyles: dropdownStyles,
            rootMenuStyles: rootMenuStyles,
            placement: placement,
            children: children,
            ref: ref_
          };
          if (value !== undefined) {
            tmp.value = Caml_option.valFromOption(value);
          }
          if (icon !== undefined) {
            tmp.icon = Caml_option.valFromOption(icon);
          }
          if (keepOpen !== undefined) {
            tmp.keepOpen = Caml_option.valFromOption(keepOpen);
          }
          if (onOpenChange !== undefined) {
            tmp.onOpenChange = Caml_option.valFromOption(onOpenChange);
          }
          if (onUpdate !== undefined) {
            tmp.onUpdate = Caml_option.valFromOption(onUpdate);
          }
          if (color !== undefined) {
            tmp.color = Caml_option.valFromOption(color);
          }
          if (withPortal !== undefined) {
            tmp.withPortal = Caml_option.valFromOption(withPortal);
          }
          return React.createElement(AvoSelect$MakeFloatingSelect$Spread, {
                      beforeProps: props,
                      children: React.createElement(make, tmp)
                    });
        }
        var tmp$1 = {
          label: label,
          labelComponent: labelComponent,
          isRoot: true,
          menuStyles: menuStyles,
          dropdownStyles: dropdownStyles,
          rootMenuStyles: rootMenuStyles,
          placement: placement,
          children: children,
          ref: ref_
        };
        if (icon !== undefined) {
          tmp$1.icon = Caml_option.valFromOption(icon);
        }
        if (keepOpen !== undefined) {
          tmp$1.keepOpen = Caml_option.valFromOption(keepOpen);
        }
        if (onOpenChange !== undefined) {
          tmp$1.onOpenChange = Caml_option.valFromOption(onOpenChange);
        }
        if (onUpdate !== undefined) {
          tmp$1.onUpdate = Caml_option.valFromOption(onUpdate);
        }
        if (color !== undefined) {
          tmp$1.color = Caml_option.valFromOption(color);
        }
        if (withPortal !== undefined) {
          tmp$1.withPortal = Caml_option.valFromOption(withPortal);
        }
        return React.createElement(React$1.FloatingTree, {
                    children: React.createElement(AvoSelect$MakeFloatingSelect$Spread, {
                          beforeProps: props,
                          children: React.createElement(make, tmp$1)
                        })
                  });
      });
  var Menu = {
    propsList: propsList$1,
    make: make$1
  };
  var propsList$2 = [
    "label",
    "value",
    "icon",
    "description",
    "descriptionComponent",
    "labelComponent",
    "disabled",
    "className",
    "onFocusChange",
    "onFocus",
    "onClick"
  ];
  var make$2 = React.forwardRef(function (Props, ref_) {
        var label = Props.label;
        var value = Props.value;
        var icon = Props.icon;
        var description = Props.description;
        var descriptionComponentOpt = Props.descriptionComponent;
        var labelComponentOpt = Props.labelComponent;
        var disabled = Props.disabled;
        var classNameOpt = Props.className;
        var onFocusChange = Props.onFocusChange;
        var onFocus = Props.onFocus;
        var onClick = Props.onClick;
        var descriptionComponent = descriptionComponentOpt !== undefined ? descriptionComponentOpt : AvoSelect$MakeFloatingSelect$DescriptionComponent;
        var labelComponent = labelComponentOpt !== undefined ? labelComponentOpt : AvoSelect$MakeFloatingSelect$LabelComponent;
        var className = classNameOpt !== undefined ? classNameOpt : menuItem;
        var menu = React.useContext(context);
        var item = React$1.useListItem({
              label: Belt_Option.getWithDefault(disabled, false) ? undefined : label
            });
        var tree = FloatingUI.useFloatingTree();
        var isActive = Caml_obj.equal(item.index, menu.activeIndex);
        var nodeId = React$1.useFloatingNodeId();
        Hooks.useDidUpdate1((function (param) {
                Belt_Option.forEach(onFocusChange, (function (f) {
                        Curry._1(f, isActive);
                      }));
              }), [isActive]);
        var onUpdate = function (maybeValue) {
          Belt_Option.forEach(tree, (function (param) {
                  param.events.emit("click", {
                        value: maybeValue
                      });
                }));
        };
        var tmp = {
          ref: React$1.useMergeRefs([
                item.ref,
                ref_
              ]),
          className: className,
          role: "menuitem",
          tabIndex: isActive ? 0 : -1,
          type: "button"
        };
        if (disabled !== undefined) {
          tmp.disabled = Caml_option.valFromOption(disabled);
        }
        return React.createElement(AvoSelect$MakeFloatingSelect$Spread, {
                    beforeProps: removeProps(Props, propsList$2),
                    afterProps: Curry._1(menu.getItemProps, {
                          onClick: (function (e) {
                              Belt_Option.forEach(onClick, (function (f) {
                                      Curry._1(f, e);
                                    }));
                              onUpdate(value);
                            }),
                          onFocus: (function (e) {
                              Belt_Option.forEach(onFocus, (function (f) {
                                      Curry._1(f, e);
                                    }));
                              Curry._1(menu.setHasFocusInside, (function (param) {
                                      return true;
                                    }));
                            })
                        }),
                    children: React.createElement("button", tmp, Curry._1(labelComponent, {
                              nodeId: nodeId,
                              label: label,
                              disabled: Belt_Option.getWithDefault(disabled, false),
                              icon: icon,
                              value: value,
                              isNested: false,
                              isRoot: false,
                              isActive: isActive,
                              onClick: (function (e) {
                                  Belt_Option.forEach(onClick, (function (f) {
                                          Curry._1(f, e);
                                        }));
                                }),
                              update: onUpdate
                            }), description !== undefined ? Curry._1(descriptionComponent, {
                                description: description
                              }) : null)
                  });
      });
  var MenuItem = {
    propsList: propsList$2,
    make: make$2
  };
  return {
          Style: Style,
          Spread: Spread,
          MenuContext: MenuContext,
          LabelComponent: LabelComponent,
          DescriptionComponent: DescriptionComponent,
          WithPortal: WithPortal,
          MenuComponent: MenuComponent,
          Menu: Menu,
          MenuItem: MenuItem
        };
}

var make = AvoSelect;

export {
  Style ,
  ItemComponent ,
  Item ,
  make ,
  MakeFloatingSelect ,
}
/* itemWrapper Not a pure module */
