// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as CreateEventForVariantUseCase from "../../model/src/variants/createEventForVariantUseCase.mjs";

function getEventNamespaceWithIdAndIsVariant(withVariantNamesOpt, events) {
  var withVariantNames = withVariantNamesOpt !== undefined ? withVariantNamesOpt : true;
  var uniqueNames = Belt_List.keepMapU(events, (function ($$event) {
          return Belt_Option.map($$event.uniqueName, (function (uniqueName) {
                        return [
                                uniqueName,
                                $$event.id,
                                false
                              ];
                      }));
        }));
  var eventNames = Belt_List.mapU(events, (function ($$event) {
          return [
                  $$event.name,
                  $$event.id,
                  false
                ];
        }));
  var eventVariantNames = withVariantNames ? BeltListExtensions.flatMapU(events, (function ($$event) {
            var variants = $$event.variants;
            if (variants.length !== 0) {
              return Belt_List.fromArray(Belt_Array.mapU(variants, (function (variant) {
                                return [
                                        CreateEventForVariantUseCase.variantEventUniqueName($$event, variant.nameSuffix),
                                        VariantEventIdUseCase.variantEventId({
                                              baseEventId: $$event.id,
                                              variantId: variant.id
                                            }),
                                        true
                                      ];
                              })));
            } else {
              return /* [] */0;
            }
          })) : /* [] */0;
  return Belt_List.concatMany([
              uniqueNames,
              eventNames,
              eventVariantNames
            ]);
}

function getEventNamespaceWithIds(withVariantNamesOpt, events) {
  var withVariantNames = withVariantNamesOpt !== undefined ? withVariantNamesOpt : true;
  return Belt_List.map(getEventNamespaceWithIdAndIsVariant(withVariantNames, events), (function (param) {
                return [
                        param[0],
                        param[1]
                      ];
              }));
}

function getEventNamespace(events) {
  return Belt_List.map(getEventNamespaceWithIdAndIsVariant(undefined, events), (function (param) {
                return param[0];
              }));
}

function getEventNamespaceWithIsVariant(events) {
  return Belt_List.map(getEventNamespaceWithIdAndIsVariant(undefined, events), (function (param) {
                return [
                        param[0],
                        param[2]
                      ];
              }));
}

function getGetNameMappingNamespace(model) {
  return Belt_Array.keepMapU(model.rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (match.NAME !== "Event") {
                  return ;
                }
                var eventId = match.VAL;
                if (typeof match$1 !== "object") {
                  return ;
                }
                if (match$1.NAME !== "NameMapping") {
                  return ;
                }
                var nameMapping = match$1.VAL.name;
                if (nameMapping !== undefined) {
                  return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId), (function ($$event) {
                                return {
                                        eventId: eventId,
                                        name: nameMapping,
                                        isVariant: false,
                                        originalName: $$event.name
                                      };
                              }));
                }
                
              }));
}

function getEventNamespaceWithNameMappingAndVariants(withNameMappingsOpt, withVariantNamesOpt, model) {
  var withNameMappings = withNameMappingsOpt !== undefined ? withNameMappingsOpt : true;
  var withVariantNames = withVariantNamesOpt !== undefined ? withVariantNamesOpt : true;
  var events = Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Events.toArray, model.events));
  var eventNamespaceWithIds = Belt_Array.map(Belt_List.toArray(getEventNamespaceWithIdAndIsVariant(withVariantNames, events)), (function (param) {
          return {
                  eventId: param[1],
                  name: param[0],
                  isVariant: param[2],
                  originalName: undefined
                };
        }));
  var mappingNames = withNameMappings ? getGetNameMappingNamespace(model) : [];
  return Belt_Array.concatMany([
              eventNamespaceWithIds,
              mappingNames
            ]);
}

export {
  getEventNamespaceWithIdAndIsVariant ,
  getEventNamespaceWithIds ,
  getEventNamespace ,
  getEventNamespaceWithIsVariant ,
  getGetNameMappingNamespace ,
  getEventNamespaceWithNameMappingAndVariants ,
}
/* TrackingPlanMappedModel Not a pure module */
