// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function AdvancedNameInput__Validations(Props) {
  var allFieldsFilled = Props.allFieldsFilled;
  var blockedValuesUsed = Props.blockedValuesUsed;
  var conflictingName = Props.conflictingName;
  var enforcesUniqueNames = Props.enforcesUniqueNames;
  var eventEdit = Props.eventEdit;
  var getItemLink = Props.getItemLink;
  var guardrailsEnabled = Props.guardrailsEnabled;
  var highlightErrors = Props.highlightErrors;
  var inlineEditingOpt = Props.inlineEditing;
  var invalidAllowedValues = Props.invalidAllowedValues;
  var invalidAllowedValuesConfirmed = Props.invalidAllowedValuesConfirmed;
  var onClose = Props.onClose;
  var role = Props.role;
  var setInvalidAllowedValuesConfirmed = Props.setInvalidAllowedValuesConfirmed;
  var similarNames = Props.similarNames;
  var inlineEditing = inlineEditingOpt !== undefined ? inlineEditingOpt : false;
  var tmp;
  if (conflictingName !== undefined) {
    var path = Curry._1(getItemLink, conflictingName);
    tmp = React.createElement("span", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.gap(Css.px(8)),
                      tl: {
                        hd: Css.height(Css.px(17)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        }, React.createElement(Icon.make, {
              type_: enforcesUniqueNames ? "close" : "warning",
              size: "small",
              color: highlightErrors ? (
                  enforcesUniqueNames ? Styles.Color.red : Styles.Color.orange
                ) : (
                  enforcesUniqueNames ? Styles.Color.light07 : Styles.Color.orange
                )
            }), React.createElement($$Text.make, {
              element: "Span",
              size: "Small",
              color: Styles.Color.light10,
              children: "\"" + conflictingName + "\" already exists"
            }), path !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(TextButton.make, {
                    onClick: (function (param) {
                        Router.push(undefined, path);
                        Curry._1(onClose, undefined);
                      }),
                    children: React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(2)),
                                    tl: {
                                      hd: Css.color(Styles.Color.darkBlue),
                                      tl: {
                                        hd: Icon.color(Styles.Color.darkBlue),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.color(Styles.Color.deepBlue),
                                                tl: {
                                                  hd: Icon.color(Styles.Color.deepBlue),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              children: "Go to"
                            }), React.createElement(Icon.make, {
                              type_: "arrowRight",
                              size: {
                                NAME: "int",
                                VAL: 12
                              }
                            }))
                  })) : null);
  } else {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.gap(Css.px(8)),
                    tl: /* [] */0
                  }
                }
              })
        }, React.createElement(Icon.make, {
              type_: conflictingName === undefined ? "checkmark" : "close",
              size: "small",
              color: conflictingName !== undefined ? (
                  highlightErrors ? Styles.Color.red : Styles.Color.light07
                ) : Styles.Color.green
            }), React.createElement($$Text.make, {
              size: "Small",
              weight: allFieldsFilled ? "Semi" : "Regular",
              color: Styles.Color.light10,
              children: "Event has a unique name"
            }));
  }
  var tmp$1;
  var exit = 0;
  var similarName;
  if (similarNames) {
    var similarName$1 = similarNames.hd;
    similarName = similarName$1;
    exit = 1;
  } else {
    tmp$1 = null;
  }
  if (exit === 1) {
    if (conflictingName === undefined) {
      var path$1 = Curry._1(getItemLink, similarName);
      tmp$1 = React.createElement("span", {
            className: Curry._1(Css.style, {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.marginTop(Css.px(16)),
                    tl: {
                      hd: Css.alignItems("baseline"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, React.createElement($$Text.make, {
                element: "Span",
                size: "Small",
                color: Styles.Color.light11,
                children: null
              }, "Similar to ", React.createElement($$Text.make, {
                    element: "Span",
                    weight: "Semi",
                    children: "\"" + similarName + "\""
                  })), path$1 !== undefined ? React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(2)),
                        tl: /* [] */0
                      })
                }, React.createElement(TextButton.make, {
                      onClick: (function (param) {
                          Router.push(undefined, path$1);
                          Curry._1(onClose, undefined);
                        }),
                      children: React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.gap(Css.px(2)),
                                      tl: {
                                        hd: Css.color(Styles.Color.darkBlue),
                                        tl: {
                                          hd: Icon.color(Styles.Color.darkBlue),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.color(Styles.Color.deepBlue),
                                                  tl: {
                                                    hd: Icon.color(Styles.Color.deepBlue),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                element: "Span",
                                size: "Small",
                                children: "Go to"
                              }), React.createElement(Icon.make, {
                                type_: "arrowRight",
                                size: {
                                  NAME: "int",
                                  VAL: 12
                                }
                              }))
                    })) : null);
    } else {
      tmp$1 = null;
    }
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(8)),
                            tl: {
                              hd: guardrailsEnabled ? Styles.emptyStyle : Css.textDecoration("lineThrough"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(Icon.make, {
                      type_: allFieldsFilled && guardrailsEnabled ? "checkmark" : "close",
                      size: "small",
                      color: allFieldsFilled ? (
                          guardrailsEnabled ? Styles.Color.green : (
                              guardrailsEnabled ? Styles.Color.red : Styles.Color.light07
                            )
                        ) : (
                          highlightErrors && guardrailsEnabled ? Styles.Color.red : Styles.Color.light07
                        )
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: allFieldsFilled && guardrailsEnabled ? "Semi" : "Regular",
                      color: Styles.Color.light10,
                      children: "All fields are filled"
                    })), tmp, blockedValuesUsed.length !== 0 ? Belt_Array.map(blockedValuesUsed, (function (param) {
                      var words = param[1];
                      var name = param[0];
                      return React.createElement("div", {
                                  key: name,
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.gap(Css.px(8)),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement(Icon.make, {
                                      type_: "warning",
                                      size: "small",
                                      color: Styles.Color.orange
                                    }), React.createElement($$Text.make, {
                                      size: "Small",
                                      color: Styles.Color.light10,
                                      children: "The " + AvoUtils.plural(undefined, false, words.length, "word") + " " + StringExt.joinToSentence(Belt_Array.map(words, (function (w) {
                                                  return "\"" + w + "\"";
                                                }))) + " may not be used for the \"" + name + "\" component"
                                    }));
                    })) : null, invalidAllowedValues !== undefined && Caml_obj.notequal(invalidAllowedValues, []) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(16)),
                          tl: /* [] */0
                        })
                  }, Belt_Array.map(invalidAllowedValues, (function (param) {
                          var value = param.value;
                          var name = param.name;
                          return React.createElement($$Text.make, {
                                      size: "Small",
                                      color: Styles.Color.light11,
                                      children: "\"" + Belt_Option.getWithDefault(value, "") + "\" will be added as an allowed " + name + " value",
                                      key: name + Belt_Option.getWithDefault(value, "")
                                    });
                        })), React.createElement(Spacer.make, {
                        height: 8
                      }), inlineEditing ? null : (
                      role === "Admin" ? React.createElement(Mantine.Checkbox.make, {
                              checked: invalidAllowedValuesConfirmed,
                              color: "magenta100",
                              label: "Confirm adding values when event is " + (
                                eventEdit === "Update" ? "updated" : "created"
                              ),
                              onChange: (function (param) {
                                  Curry._1(setInvalidAllowedValuesConfirmed, (function (currentValue) {
                                          return !currentValue;
                                        }));
                                }),
                              size: "xs"
                            }) : React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: "Contact an admin to add allowed values"
                            })
                    )) : null, tmp$1);
}

var make = AdvancedNameInput__Validations;

export {
  make ,
}
/* Css Not a pure module */
