// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as DateFns from "date-fns";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

function flip(f, a, b) {
  return Curry._2(f, b, a);
}

var handleOptBool = Js_null_undefined.fromOption;

function timeUnitToString(x) {
  switch (x) {
    case "Second" :
        return "s";
    case "Minute" :
        return "m";
    case "Hour" :
        return "h";
    case "Day" :
        return "d";
    case "Month" :
        return "M";
    case "Year" :
        return "Y";
    
  }
}

function partialMethodToString(x) {
  switch (x) {
    case "Floor" :
        return "floor";
    case "Ceil" :
        return "ceil";
    case "Round" :
        return "round";
    
  }
}

function formatDistance(includeSeconds, addSuffix, dateToCompare, date) {
  return DateFns.formatDistance(date, dateToCompare, {
              includeSeconds: Js_null_undefined.fromOption(includeSeconds),
              addSuffix: Js_null_undefined.fromOption(addSuffix)
            });
}

function formatDistanceToNow(includeSeconds, addSuffix, date) {
  return DateFns.formatDistanceToNow(date, {
              includeSeconds: Js_null_undefined.fromOption(includeSeconds),
              addSuffix: Js_null_undefined.fromOption(addSuffix)
            });
}

function format(param, param$1) {
  return DateFns.format(param$1, param);
}

function isAfter(base, comparator) {
  return DateFns.isAfter(comparator, base);
}

function subMinutes(param, param$1) {
  return DateFns.subMinutes(param$1, param);
}

function subHours(param, param$1) {
  return DateFns.subHours(param$1, param);
}

function subDays(param, param$1) {
  return DateFns.subDays(param$1, param);
}

function addMilliseconds(param, param$1) {
  return DateFns.addMilliseconds(param$1, param);
}

function addHours(param, param$1) {
  return DateFns.addHours(param$1, param);
}

function addDays(param, param$1) {
  return DateFns.addDays(param$1, param);
}

function addMinutes(param, param$1) {
  return DateFns.addMinutes(param$1, param);
}

export {
  flip ,
  handleOptBool ,
  timeUnitToString ,
  partialMethodToString ,
  formatDistance ,
  formatDistanceToNow ,
  format ,
  isAfter ,
  subMinutes ,
  subHours ,
  subDays ,
  addMilliseconds ,
  addHours ,
  addDays ,
  addMinutes ,
}
/* date-fns Not a pure module */
