// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as IconCheckmark from "../IconCheckmark.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SelectBranchPopup from "../SelectBranchPopup.mjs";
import * as PortalMenu_deprecated from "../PortalMenu_deprecated.mjs";

function rootStyles(hasSelected) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("row"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.border(Css.px(1), "dashed", Styles.Color.light04),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.paddingTop(Css.px(4)),
                                tl: {
                                  hd: Css.paddingRight(Css.px(8)),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(4)),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(8)),
                                      tl: {
                                        hd: Css.color(hasSelected ? Styles.Color.green : Styles.Color.darkBlue),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.borderColor(Styles.Color.light07),
                                                tl: {
                                                  hd: Css.color(hasSelected ? Styles.Color.green : Styles.Color.deepBlue),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.light07)),
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.outlineStyle("none"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function ImportReview__BranchPicker(Props) {
  var canSelectMain = Props.canSelectMain;
  var handleFinishImport = Props.handleFinishImport;
  var importDisabled = Props.importDisabled;
  var openBranches = Props.openBranches;
  var selectedBranch = Props.selectedBranch;
  var selectedEventCount = Props.selectedEventCount;
  var selectedPropertiesCount = Props.selectedPropertiesCount;
  var setSelectedBranch = Props.setSelectedBranch;
  var match = React.useState(function (param) {
        return false;
      });
  var setLoading = match[1];
  var isLoading = match[0];
  var selectBranchButtonRef = React.useRef(null);
  var match$1 = Hooks.useDisclosure(false);
  var branchPopupActions = match$1[1];
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  ref: selectBranchButtonRef,
                  className: rootStyles(Belt_Option.isSome(selectedBranch)),
                  onClick: (function (param) {
                      Curry._1(branchPopupActions.toggle, undefined);
                    })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Import " + selectedEventCount.toString() + " Events & " + selectedPropertiesCount.toString() + " Properties to…"
                    }), Belt_Option.isSome(selectedBranch) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            width: 2
                          }), React.createElement(IconCheckmark.make, {
                            size: 12,
                            color: Styles.Color.green
                          })) : null, React.createElement(Spacer.make, {
                      width: 2
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      children: selectedBranch !== undefined ? (
                          typeof selectedBranch === "object" ? (
                              selectedBranch.NAME === "Create" ? selectedBranch.VAL : selectedBranch.VAL.name
                            ) : "main"
                        ) : "Choose branch"
                    })), React.createElement(PortalMenu_deprecated.make, {
                  visible: match$1[0],
                  onClose: (function (param) {
                      Curry._1(branchPopupActions.close, undefined);
                    }),
                  referenceItemRef: selectBranchButtonRef,
                  children: React.createElement(SelectBranchPopup.make, {
                        canSelectExistingBranches: false,
                        canSelectMain: canSelectMain,
                        onClose: (function (param) {
                            Curry._1(branchPopupActions.close, undefined);
                          }),
                        onSelect: (function (selectedBranch) {
                            Curry._1(setSelectedBranch, (function (param) {
                                    return selectedBranch;
                                  }));
                          }),
                        openBranches: openBranches
                      })
                }), React.createElement(Spacer.make, {
                  width: 8
                }), selectedBranch !== undefined ? React.createElement(Button.make, {
                    disabled: isLoading || importDisabled,
                    label: isLoading ? "Importing…" : "Import to Tracking Plan",
                    loading: isLoading,
                    onClick: (function (param) {
                        Curry._1(setLoading, (function (param) {
                                return true;
                              }));
                        Curry._1(handleFinishImport, selectedBranch);
                      })
                  }) : React.createElement(Button.make, {
                    disabled: true,
                    label: isLoading ? "Importing…" : "Import to Tracking Plan",
                    loading: isLoading
                  }));
}

var make = ImportReview__BranchPicker;

export {
  make ,
}
/* Css Not a pure module */
