// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_shared from "./ModelUtils_shared.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function destinationIdsWithoutArchived(destinationIds, destinations) {
  var idSet = Belt_SetString.fromArray(destinationIds);
  return Curry._2(TrackingPlanMappedModel.Destinations.keep, destinations, (function (param) {
                return Belt_SetString.has(idSet, param.id);
              }));
}

function metricIdsWithoutArchived(metricIds, metrics) {
  var idSet = Belt_SetString.fromArray(metricIds);
  return Curry._2(TrackingPlanMappedModel.Metrics.keep, metrics, (function (param) {
                return Belt_SetString.has(idSet, param.id);
              }));
}

function eventIdsWithoutArchived(eventIds, events) {
  var idSet = Belt_SetString.fromArray(eventIds);
  return Curry._2(TrackingPlanMappedModel.Events.keep, events, (function (param) {
                return Belt_SetString.has(idSet, param.id);
              }));
}

function getGoalsWithEvent(eventId, categories) {
  return Curry._1(TrackingPlanMappedModel.Categories.toArray, Curry._2(TrackingPlanMappedModel.Categories.keep, categories, (function (category) {
                    return Belt_List.some(category.events, (function (id) {
                                  return id === eventId;
                                }));
                  })));
}

function getPropertyRefById(eventId, propertyId, events) {
  return Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Events.get, events, eventId), (function ($$event) {
                return Belt_List.head(Belt_List.keepMapU($$event.directPropertyRefs, (function (property) {
                                  if (property.TAG !== "PropertyRef") {
                                    return ;
                                  }
                                  var propRef = property._0;
                                  if (propRef.id === propertyId) {
                                    return propRef;
                                  }
                                  
                                })));
              }));
}

function resolveProperty(property, properties) {
  if (property.TAG === "PropertyRef") {
    return Curry._2(TrackingPlanMappedModel.Properties.get, properties, property._0.id);
  } else {
    return property._0;
  }
}

function resolveProperties(properties, modelProperties) {
  return Belt_Array.keepMap(properties, (function (property) {
                return resolveProperty(property, modelProperties);
              }));
}

function getAllPropertyIdsFromNameMappingName(eventIdOpt, rules, destinations, propertyName) {
  var eventId = eventIdOpt !== undefined ? Caml_option.valFromOption(eventIdOpt) : undefined;
  return Belt_Array.keepMapU(rules, (function (rule) {
                var match = rule.item;
                var match$1 = rule.definition;
                if (typeof match !== "object") {
                  return ;
                }
                var variant = match.NAME;
                if (variant === "PropertyRef") {
                  if (typeof match$1 !== "object") {
                    return ;
                  }
                  if (match$1.NAME !== "NameMapping") {
                    return ;
                  }
                  var match$2 = match$1.VAL;
                  var destination = match$2.destination;
                  var match$3 = match.VAL;
                  var destinationMissing = destination !== undefined ? (
                      typeof destination === "object" ? !Curry._2(TrackingPlanMappedModel.Destinations.has, destinations, destination.VAL) : false
                    ) : true;
                  if (Caml_obj.equal(match$2.name, propertyName) && !destinationMissing && Caml_obj.equal(match$3[0], eventId)) {
                    return match$3[1];
                  } else {
                    return ;
                  }
                }
                if (variant !== "Property") {
                  return ;
                }
                if (typeof match$1 !== "object") {
                  return ;
                }
                if (match$1.NAME !== "NameMapping") {
                  return ;
                }
                var match$4 = match$1.VAL;
                var destination$1 = match$4.destination;
                var destinationMissing$1 = destination$1 !== undefined ? (
                    typeof destination$1 === "object" ? !Curry._2(TrackingPlanMappedModel.Destinations.has, destinations, destination$1.VAL) : false
                  ) : true;
                if (Caml_obj.equal(match$4.name, propertyName) && !destinationMissing$1) {
                  return match.VAL;
                }
                
              }));
}

function getPropertyIdFromNameMappingName(eventIdOpt, rules, destinations, propertyName) {
  var eventId = eventIdOpt !== undefined ? Caml_option.valFromOption(eventIdOpt) : undefined;
  return Belt_Array.get(getAllPropertyIdsFromNameMappingName(Caml_option.some(eventId), rules, destinations, propertyName), 0);
}

function getPropertyByNameOrNameMapping(rules, destinations, properties, propertyName, eventId) {
  var property = Curry._2(TrackingPlanMappedModel.Properties.getBy, properties, (function (param) {
          return propertyName === param.name;
        }));
  if (property !== undefined) {
    return property;
  } else {
    return Belt_Option.flatMap(getPropertyIdFromNameMappingName(Caml_option.some(eventId), rules, destinations, propertyName), (function (id) {
                  return Curry._2(TrackingPlanMappedModel.Properties.get, properties, id);
                }));
  }
}

function mapRulesByPropertyId(rules) {
  var map = {
    contents: undefined
  };
  var update = function (propertyId, rule) {
    map.contents = Belt_MapString.update(map.contents, propertyId, (function (maybeRules) {
            return Belt_Option.mapWithDefault(maybeRules, {
                        hd: rule,
                        tl: /* [] */0
                      }, (function (existingRules) {
                          return {
                                  hd: rule,
                                  tl: existingRules
                                };
                        }));
          }));
  };
  Belt_List.forEach(rules, (function (rule) {
          var match = rule.item;
          if (typeof match !== "object") {
            return ;
          }
          var variant = match.NAME;
          if (variant === "PropertyRef") {
            var match$1 = rule.definition;
            if (typeof match$1 !== "object") {
              return ;
            }
            if (match$1.NAME !== "NameMapping") {
              return ;
            }
            var match$2 = match$1.VAL;
            if (match$2.destination !== undefined && match$2.name !== undefined) {
              return update(match.VAL[1], rule);
            } else {
              return ;
            }
          }
          if (variant !== "Property") {
            return ;
          }
          var match$3 = rule.definition;
          if (typeof match$3 !== "object") {
            return ;
          }
          if (match$3.NAME !== "NameMapping") {
            return ;
          }
          var match$4 = match$3.VAL;
          if (match$4.destination !== undefined && match$4.name !== undefined) {
            return update(match.VAL, rule);
          }
          
        }));
  return map.contents;
}

function getAllPropertiesByNameOrNameMapping(rules, destinations, properties, propertyName, eventId) {
  var nameMappings = Curry._1(TrackingPlanMappedModel.Properties.fromArray, Belt_Array.keepMap(getAllPropertyIdsFromNameMappingName(Caml_option.some(eventId), rules, destinations, propertyName), Curry._1(TrackingPlanMappedModel.Properties.get, properties)));
  var namedProperties = Curry._2(TrackingPlanMappedModel.Properties.keep, properties, (function (param) {
          return param.name === propertyName;
        }));
  return Curry._2(TrackingPlanMappedModel.Properties.mergeMany, nameMappings, namedProperties);
}

function resolvePropertyBundleRefs(propertyBundles, groupRefs) {
  return Belt_List.keepMapU(groupRefs, (function (groupRef) {
                return Curry._2(TrackingPlanMappedModel.PropertyBundles.get, propertyBundles, groupRef.id);
              }));
}

function getSourcesEvents(codegenEventsOnlyOpt, events, sourceIds) {
  var codegenEventsOnly = codegenEventsOnlyOpt !== undefined ? codegenEventsOnlyOpt : false;
  var sourceIds$1 = Belt_SetString.fromArray(Belt_List.toArray(sourceIds));
  return Curry._2(TrackingPlanMappedModel.Events.keep, events, (function ($$event) {
                return Belt_List.someU($$event.includeSources, (function (includedSource) {
                              if (Belt_SetString.has(sourceIds$1, includedSource.id)) {
                                if (codegenEventsOnly) {
                                  return includedSource.includeInCodegen;
                                } else {
                                  return true;
                                }
                              } else {
                                return false;
                              }
                            }));
              }));
}

function getSourceEvents(codegenEventsOnlyOpt, events, sourceId) {
  var codegenEventsOnly = codegenEventsOnlyOpt !== undefined ? codegenEventsOnlyOpt : false;
  return getSourcesEvents(codegenEventsOnly, events, {
              hd: sourceId,
              tl: /* [] */0
            });
}

function doesEventSendPropertyFromSource($$event, propertyBundles, sourceSet, propertyId) {
  var sentFromSources = Belt_Option.mapWithDefault(sourceSet, true, (function (sourcesSet) {
          return Belt_List.someU($$event.includeSources, (function (includedSource) {
                        return Belt_SetString.has(sourcesSet, includedSource.id);
                      }));
        }));
  if (sentFromSources) {
    if (ModelUtils_shared.eventsSendingPropertyFromSources__eventUsingProperty($$event, propertyId) || ModelUtils_shared.eventsSendingPropertyFromSources__eventVariantUsingProperty($$event, propertyId)) {
      return true;
    } else {
      return Belt_List.someU(resolvePropertyBundleRefs(propertyBundles, $$event.propertyBundles), (function (bundle) {
                    return Belt_List.someU(bundle.properties, (function (bundlePropertyId) {
                                  return bundlePropertyId === propertyId;
                                }));
                  }));
    }
  } else {
    return false;
  }
}

function eventsSendingPropertyFromSources(events, propertyBundles, sources, propertyId) {
  var maybeSourcesSet = Belt_Option.map(sources, Belt_SetString.fromArray);
  return Curry._2(TrackingPlanMappedModel.Events.keep, events, (function ($$event) {
                return doesEventSendPropertyFromSource($$event, propertyBundles, maybeSourcesSet, propertyId);
              }));
}

function eventsSendingProperty(events, propertyBundles, sourceId, propertyId) {
  return eventsSendingPropertyFromSources(events, propertyBundles, Belt_Option.map(sourceId, (function (sourceId) {
                    return [sourceId];
                  })), propertyId);
}

function getIncludedSourceIds(includeSources) {
  return Belt_Array.mapU(includeSources, (function (includedSource) {
                return includedSource.id;
              }));
}

function isPropertySentFromSource(events, propertyBundles, propertyId, sourceId) {
  var includeSources = Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.Events.mapToArray, eventsSendingProperty(events, propertyBundles, sourceId, propertyId), (function (param) {
              return Belt_List.toArray(param.includeSources);
            })));
  return Belt_Array.someU(Belt_Array.mapU(includeSources, (function (includedSource) {
                    return includedSource.id;
                  })), (function (includedSourceId) {
                return includedSourceId === sourceId;
              }));
}

function getEventSourceIdsByEventId(model, eventId) {
  var $$event = Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId);
  if ($$event === undefined) {
    return [];
  }
  var includeSources = Belt_List.toArray($$event.includeSources);
  return Belt_Array.mapU(includeSources, (function (includedSource) {
                return includedSource.id;
              }));
}

function makePropertyAbsenceFromOptionalAndExcludedSources(property, model) {
  var match = property.optionalDeprecated;
  var match$1 = property.excludedSourcesDeprecated;
  var match$2 = property.sendAs;
  if (match) {
    if (!match$1) {
      return {
              TAG: "SometimesSent",
              _0: ""
            };
    }
    if (typeof match$2 !== "object" && match$2 === "SystemProperty") {
      return {
              TAG: "Mixed",
              _0: Belt_MapString.fromArray([[
                      TrackingPlanModel.propertyAbsenceAllEventsKey,
                      {
                        TAG: "MixedSources",
                        _0: Curry._2(TrackingPlanMappedModel.Sources.mapToMap, model.sources, (function (source) {
                                if (Belt_List.has(match$1, source.id, (function (prim0, prim1) {
                                          return prim0 === prim1;
                                        }))) {
                                  return "NeverSent";
                                } else {
                                  return {
                                          TAG: "SometimesSent",
                                          _0: ""
                                        };
                                }
                              }))
                      }
                    ]])
            };
    }
    var eventsSendingProperty$1 = eventsSendingProperty(model.events, model.propertyBundles, undefined, property.id);
    return {
            TAG: "Mixed",
            _0: Curry._2(TrackingPlanMappedModel.Events.mapToMap, eventsSendingProperty$1, (function ($$event) {
                    return {
                            TAG: "MixedSources",
                            _0: Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                            return [
                                                    includedSource.id,
                                                    Belt_List.has(match$1, includedSource.id, (function (prim0, prim1) {
                                                            return prim0 === prim1;
                                                          })) ? "NeverSent" : ({
                                                          TAG: "SometimesSent",
                                                          _0: ""
                                                        })
                                                  ];
                                          }))))
                          };
                  }))
          };
  }
  if (!match$1) {
    return "AlwaysSent";
  }
  if (typeof match$2 !== "object" && match$2 === "SystemProperty") {
    return {
            TAG: "Mixed",
            _0: Belt_MapString.mapU(Belt_MapString.keepU(Belt_MapString.fromArray([[
                            TrackingPlanModel.propertyAbsenceAllEventsKey,
                            Belt_MapString.fromArray(Curry._2(TrackingPlanMappedModel.Sources.keepMap, model.sources, (function (source) {
                                        if (Belt_List.has(match$1, source.id, (function (prim0, prim1) {
                                                  return prim0 === prim1;
                                                }))) {
                                          return [
                                                  source.id,
                                                  "NeverSent"
                                                ];
                                        }
                                        
                                      })))
                          ]]), (function (_eventId, sourceMap) {
                        return !Belt_MapString.isEmpty(sourceMap);
                      })), (function (sources) {
                    return {
                            TAG: "MixedSources",
                            _0: sources
                          };
                  }))
          };
  }
  var eventsSendingProperty$2 = eventsSendingProperty(model.events, model.propertyBundles, undefined, property.id);
  return {
          TAG: "Mixed",
          _0: Belt_MapString.mapU(Belt_MapString.keepU(Curry._2(TrackingPlanMappedModel.Events.mapToMap, eventsSendingProperty$2, (function ($$event) {
                          return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMapU($$event.includeSources, (function (includedSource) {
                                                if (Belt_List.has(match$1, includedSource.id, (function (prim0, prim1) {
                                                          return prim0 === prim1;
                                                        }))) {
                                                  return [
                                                          includedSource.id,
                                                          "NeverSent"
                                                        ];
                                                }
                                                
                                              }))));
                        })), (function (_eventId, sourceMap) {
                      return !Belt_MapString.isEmpty(sourceMap);
                    })), (function (sources) {
                  return {
                          TAG: "MixedSources",
                          _0: sources
                        };
                }))
        };
}

function isPropertyOnEvent_byEvent($$event, property, propertyBundles) {
  var isSystemProperty = property.sendAs === "SystemProperty";
  var properties = Belt_List.keepMapU($$event.directPropertyRefs, (function (property) {
          return property._0.id;
        }));
  var propertyBundles$1 = Belt_List.flatten(Belt_List.map($$event.propertyBundles, (function (bundleRef) {
              return Belt_Option.getWithDefault(Belt_Option.map(Curry._2(TrackingPlanMappedModel.PropertyBundles.get, propertyBundles, bundleRef.id), (function (bundle) {
                                return bundle.properties;
                              })), /* [] */0);
            })));
  var eventPropertyIds = Belt_List.concat(properties, propertyBundles$1);
  if (Belt_List.has(eventPropertyIds, property.id, (function (prim0, prim1) {
            return prim0 === prim1;
          }))) {
    return true;
  } else {
    return isSystemProperty;
  }
}

function isPropertyOnEvent(eventId, events, property, propertyBundles) {
  var isSystemProperty = property.sendAs === "SystemProperty";
  if (isSystemProperty) {
    return true;
  } else {
    return Belt_Option.getWithDefault(Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.get, events, eventId), (function ($$event) {
                      return isPropertyOnEvent_byEvent($$event, property, propertyBundles);
                    })), false);
  }
}

function getEventPropertyIds(events, propertyBundles, eventId) {
  var $$event = Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
  if ($$event === undefined) {
    return [];
  }
  var propertyIds = Belt_List.toArray(Belt_List.keepMapU($$event.directPropertyRefs, (function (property) {
              if (property.TAG === "PropertyRef") {
                return property._0.id;
              }
              
            })));
  var propertyBundlePropertyIds = Belt_List.toArray(Belt_List.flatten(Belt_List.mapU($$event.propertyBundles, (function (bundleRef) {
                  var match = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, propertyBundles, bundleRef.id);
                  if (match !== undefined) {
                    return match.properties;
                  } else {
                    return /* [] */0;
                  }
                }))));
  return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(propertyIds, propertyBundlePropertyIds)));
}

function getEventProperties(events, properties, propertyBundles, eventId) {
  return Curry._1(TrackingPlanMappedModel.Properties.fromArray, Belt_Array.keepMapU(getEventPropertyIds(events, propertyBundles, eventId), (function (propertyId) {
                    return Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId);
                  })));
}

function isPropertyIncludedOnEventAndSources(includeSystemPropertiesOpt, sourceIds, events, propertyBundles, eventId, property) {
  var includeSystemProperties = includeSystemPropertiesOpt !== undefined ? includeSystemPropertiesOpt : true;
  var isSystemProperty = property.sendAs === "SystemProperty";
  var eventId$1 = isSystemProperty ? TrackingPlanModel.propertyAbsenceAllEventsKey : eventId;
  var propertyIsOnEvent = isPropertyOnEvent(eventId$1, events, property, propertyBundles);
  return Belt_List.someU(sourceIds, (function (sourceId) {
                var sourceSendsEvent = Curry._2(TrackingPlanMappedModel.Events.has, getSourceEvents(undefined, events, sourceId), eventId$1) || isSystemProperty && includeSystemProperties;
                var match = property.absence;
                var propertyAtLeastSometimeSent;
                if (match !== undefined) {
                  if (typeof match !== "object" || match.TAG === "SometimesSent") {
                    propertyAtLeastSometimeSent = true;
                  } else {
                    var match$1 = Belt_MapString.get(match._0, eventId$1);
                    if (match$1 !== undefined) {
                      if (match$1.TAG === "AllSources") {
                        var tmp = match$1._0;
                        propertyAtLeastSometimeSent = typeof tmp !== "object" ? false : true;
                      } else {
                        var match$2 = Belt_MapString.get(match$1._0, sourceId);
                        var tmp$1;
                        tmp$1 = match$2 !== undefined ? (
                            typeof match$2 !== "object" ? false : true
                          ) : (
                            isSystemProperty ? true : Curry._2(TrackingPlanMappedModel.Events.has, getSourceEvents(undefined, events, sourceId), eventId$1)
                          );
                        propertyAtLeastSometimeSent = tmp$1 === true;
                      }
                    } else {
                      propertyAtLeastSometimeSent = true;
                    }
                  }
                } else {
                  propertyAtLeastSometimeSent = !Belt_List.someU(property.excludedSourcesDeprecated, (function (excludedSourceId) {
                          return excludedSourceId === sourceId;
                        }));
                }
                if (sourceSendsEvent && propertyAtLeastSometimeSent) {
                  return propertyIsOnEvent;
                } else {
                  return false;
                }
              }));
}

function isPropertyIncludedOnEventAndSource(includeSystemProperties, events, sourceId, eventId, property) {
  var partial_arg = {
    hd: sourceId,
    tl: /* [] */0
  };
  return function (param) {
    return isPropertyIncludedOnEventAndSources(includeSystemProperties, partial_arg, events, param, eventId, property);
  };
}

function isPropertyOptionalOnEventsAndSources(eventIds, sourceIds, events, property) {
  var eventIds$1 = Belt_Option.map(eventIds, (function (eventIds) {
          return Belt_List.map(eventIds, (function (eventId) {
                        if (property.sendAs === "SystemProperty") {
                          return TrackingPlanModel.propertyAbsenceAllEventsKey;
                        } else {
                          return eventId;
                        }
                      }));
        }));
  var isEventIdSentFromSourceIds = function (eventId, sourceIds) {
    var maybeEvent = Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
    if (eventId === TrackingPlanModel.propertyAbsenceAllEventsKey) {
      return true;
    } else if (maybeEvent !== undefined) {
      if (sourceIds !== undefined) {
        return Belt_List.someU(maybeEvent.includeSources, (function (includedSource) {
                      return Belt_List.someU(sourceIds, (function (sourceId) {
                                    return sourceId === includedSource.id;
                                  }));
                    }));
      } else {
        return Belt_List.length(maybeEvent.includeSources) > 0;
      }
    } else {
      return false;
    }
  };
  var match = property.absence;
  if (match === undefined) {
    return property.optionalDeprecated;
  }
  if (typeof match !== "object") {
    return false;
  }
  if (match.TAG === "SometimesSent") {
    return true;
  }
  var eventMap = match._0;
  return Belt_MapString.some(Belt_Option.mapWithDefault(eventIds$1, eventMap, (function (eventIds) {
                    return Belt_MapString.keep(eventMap, (function (eventId, _absence) {
                                  return Belt_List.has(eventIds, eventId, (function (prim0, prim1) {
                                                return prim0 === prim1;
                                              }));
                                }));
                  })), (function (eventId, eventIdsAbsences) {
                var match = isEventIdSentFromSourceIds(eventId, sourceIds);
                if (!match) {
                  return false;
                }
                if (eventIdsAbsences.TAG === "AllSources") {
                  return true;
                }
                var sourceMap = eventIdsAbsences._0;
                return Belt_MapString.someU(Belt_Option.mapWithDefault(sourceIds, sourceMap, (function (sourceIds) {
                                  return Belt_MapString.keep(sourceMap, (function (sourceId, _absence) {
                                                return Belt_List.has(sourceIds, sourceId, (function (prim0, prim1) {
                                                              return prim0 === prim1;
                                                            }));
                                              }));
                                })), (function (_sourceId, eventSourceAbsence) {
                              return true;
                            }));
              }));
}

function isPropertyOptionalOnEventAndSource(eventId, sourceId, events, property) {
  return isPropertyOptionalOnEventsAndSources({
              hd: eventId,
              tl: /* [] */0
            }, {
              hd: sourceId,
              tl: /* [] */0
            }, events, property);
}

function isPropertyOptionalOnEvents(eventIds, events, property) {
  return isPropertyOptionalOnEventsAndSources(eventIds, undefined, events, property);
}

function isPropertyOptionalOnEvent(eventId, events, property) {
  return isPropertyOptionalOnEventsAndSources({
              hd: eventId,
              tl: /* [] */0
            }, undefined, events, property);
}

function isPropertyOptionalOnSource(sourceId, events, property) {
  return isPropertyOptionalOnEventsAndSources(undefined, {
              hd: sourceId,
              tl: /* [] */0
            }, events, property);
}

function isPropertyOptionalSomewhere(events, property) {
  return isPropertyOptionalOnEventsAndSources(undefined, undefined, events, property);
}

function systemPropertyExcludedSources(property, model) {
  var match = property.absence;
  if (match === undefined) {
    return Belt_List.toArray(property.excludedSourcesDeprecated);
  }
  if (typeof match !== "object") {
    return [];
  }
  if (match.TAG === "SometimesSent") {
    return [];
  }
  if (property.sendAs !== "SystemProperty") {
    return Pervasives.failwith("Not implemented yet");
  }
  var sourceAbsence = Belt_MapString.get(match._0, TrackingPlanModel.propertyAbsenceAllEventsKey);
  if (sourceAbsence === undefined) {
    return [];
  }
  if (sourceAbsence.TAG !== "AllSources") {
    return Belt_Array.mapU(Belt_MapString.toArray(Belt_MapString.keepU(sourceAbsence._0, (function (sourceId, absence) {
                          if (typeof absence !== "object" && Curry._2(TrackingPlanMappedModel.Sources.has, model.sources, sourceId)) {
                            return true;
                          } else {
                            return false;
                          }
                        }))), (function (param) {
                  return param[0];
                }));
  }
  var tmp = sourceAbsence._0;
  if (typeof tmp !== "object") {
    return Curry._1(TrackingPlanMappedModel.Sources.keys, model.sources);
  } else {
    return [];
  }
}

function isEventIncludedInSource(codegenEventsOnlyOpt, events, sourceId, eventId) {
  var codegenEventsOnly = codegenEventsOnlyOpt !== undefined ? codegenEventsOnlyOpt : false;
  return Curry._2(TrackingPlanMappedModel.Events.has, getSourceEvents(codegenEventsOnly, events, sourceId), eventId);
}

function getAllTags(events) {
  return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.Events.mapToArray, events, (function (param) {
                            return Belt_List.toArray(param.tags);
                          })))));
}

function getGroupTypeNames(groupTypes) {
  return Curry._2(TrackingPlanMappedModel.GroupTypes.mapToArray, groupTypes, (function (param) {
                return param.name;
              }));
}

function getEventNames(events) {
  return Curry._2(TrackingPlanMappedModel.Events.mapToArray, events, (function (param) {
                return param.name;
              }));
}

function getPropertyNames(properties) {
  return Curry._2(TrackingPlanMappedModel.Properties.mapToArray, properties, (function (param) {
                return param.name;
              }));
}

function updatePropertyValidations(properties, propertyId, modifier) {
  return Curry._3(TrackingPlanMappedModel.Properties.updateExisting, properties, propertyId, (function (property) {
                var newrecord = Caml_obj.obj_dup(property);
                newrecord.validations = Curry._2(modifier, property.validations, property);
                return newrecord;
              }));
}

function updateExistingVariant(events, variantIdentifier, modifier) {
  return Curry._3(TrackingPlanMappedModel.Events.updateExisting, events, variantIdentifier.baseEventId, (function ($$event) {
                return {
                        id: $$event.id,
                        name: $$event.name,
                        uniqueName: $$event.uniqueName,
                        description: $$event.description,
                        directPropertyRefs: $$event.directPropertyRefs,
                        propertyBundles: $$event.propertyBundles,
                        variants: Belt_Array.mapU($$event.variants, (function (variant) {
                                if (variant.id === variantIdentifier.variantId) {
                                  return Curry._1(modifier, variant);
                                } else {
                                  return variant;
                                }
                              })),
                        types: $$event.types,
                        tags: $$event.tags,
                        excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                        includeSources: $$event.includeSources,
                        includeDestinations: $$event.includeDestinations,
                        hashes: $$event.hashes,
                        propertyWhitelist: $$event.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                        triggers: $$event.triggers,
                        globalRequirementsMetadata: $$event.globalRequirementsMetadata
                      };
              }));
}

function updateTrigger(events, eventId, triggerId, modifier) {
  return Curry._3(TrackingPlanMappedModel.Events.updateExisting, events, eventId, (function ($$event) {
                return {
                        id: $$event.id,
                        name: $$event.name,
                        uniqueName: $$event.uniqueName,
                        description: $$event.description,
                        directPropertyRefs: $$event.directPropertyRefs,
                        propertyBundles: $$event.propertyBundles,
                        variants: $$event.variants,
                        types: $$event.types,
                        tags: $$event.tags,
                        excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                        includeSources: $$event.includeSources,
                        includeDestinations: $$event.includeDestinations,
                        hashes: $$event.hashes,
                        propertyWhitelist: $$event.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                        triggers: Belt_Array.mapU($$event.triggers, (function (trigger) {
                                if (trigger.id === triggerId) {
                                  return Curry._1(modifier, trigger);
                                } else {
                                  return trigger;
                                }
                              })),
                        globalRequirementsMetadata: $$event.globalRequirementsMetadata
                      };
              }));
}

function updateEventVariantTrigger_mappedModel(model, variantIdentifier, triggerId, modifier) {
  return {
          archive: model.archive,
          branchPointer: model.branchPointer,
          migrations: model.migrations,
          rules: model.rules,
          types: model.types,
          categories: model.categories,
          destinations: model.destinations,
          events: updateExistingVariant(model.events, variantIdentifier, (function (eventVariant) {
                  return {
                          id: eventVariant.id,
                          baseEventId: eventVariant.baseEventId,
                          nameSuffix: eventVariant.nameSuffix,
                          description: eventVariant.description,
                          propertyOverrides: eventVariant.propertyOverrides,
                          sourceOverrides: eventVariant.sourceOverrides,
                          triggers: Belt_Array.map(eventVariant.triggers, (function (trigger) {
                                  if (trigger.id === triggerId) {
                                    return Curry._1(modifier, trigger);
                                  } else {
                                    return trigger;
                                  }
                                }))
                        };
                })),
          groupTypes: model.groupTypes,
          integrations: model.integrations,
          metrics: model.metrics,
          openBranches: model.openBranches,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          sources: model.sources,
          globalRequirements: model.globalRequirements
        };
}

function hasMigrated(model, maybeMigration) {
  return Belt_Array.some(model.migrations, (function (migration) {
                return migration === maybeMigration;
              }));
}

function isPropertyIdNested(model, nestedPropertyId) {
  return Curry._2(TrackingPlanMappedModel.Properties.some, model.properties, (function (modelProperty) {
                return ModelUtils_shared.doesPropertyContainPropertyIdAsFirstLevelNested(modelProperty, nestedPropertyId);
              }));
}

function getEventPossibleNames(modelEvent, rules) {
  return Belt_Array.concat(Belt_Array.keepMapU(rules, (function (rule) {
                    var match = rule.item;
                    if (match.NAME !== "Event") {
                      return ;
                    }
                    var match$1 = rule.definition;
                    var variant = match$1.NAME;
                    if (!(variant === "NameInLocalWorkspace" || variant === "NameMapping")) {
                      return ;
                    }
                    var name = match$1.VAL.name;
                    if (name !== undefined && match.VAL === modelEvent.id) {
                      return name;
                    }
                    
                  })), [modelEvent.name]);
}

function getPropertyPossibleNames(modelProperty, maybeModelEvent, mappedRulesByPropertyId) {
  var rules = Belt_MapString.get(mappedRulesByPropertyId, modelProperty.id);
  if (rules !== undefined) {
    return Belt_List.add(Belt_List.keepMapU(rules, (function (rule) {
                      var match = rule.item;
                      if (typeof match !== "object") {
                        return ;
                      }
                      var variant = match.NAME;
                      if (variant === "PropertyRef") {
                        var match$1 = rule.definition;
                        if (typeof match$1 !== "object") {
                          return ;
                        }
                        if (match$1.NAME !== "NameMapping") {
                          return ;
                        }
                        var match$2 = match$1.VAL;
                        if (match$2.destination === undefined) {
                          return ;
                        }
                        var name = match$2.name;
                        if (name === undefined) {
                          return ;
                        }
                        var match$3 = match.VAL;
                        if (match$3[1] !== modelProperty.id) {
                          return ;
                        }
                        var eventId = match$3[0];
                        var isNameMappedOnEvent = Belt_Option.getWithDefault(Belt_Option.mapU(maybeModelEvent, (function ($$event) {
                                    return $$event.id === eventId;
                                  })), false);
                        if (isNameMappedOnEvent) {
                          return name;
                        } else {
                          return ;
                        }
                      }
                      if (variant !== "Property") {
                        return ;
                      }
                      var match$4 = rule.definition;
                      if (typeof match$4 !== "object") {
                        return ;
                      }
                      if (match$4.NAME !== "NameMapping") {
                        return ;
                      }
                      var match$5 = match$4.VAL;
                      if (match$5.destination === undefined) {
                        return ;
                      }
                      var name$1 = match$5.name;
                      if (name$1 !== undefined && match.VAL === modelProperty.id) {
                        return name$1;
                      }
                      
                    })), modelProperty.name);
  } else {
    return {
            hd: modelProperty.name,
            tl: /* [] */0
          };
  }
}

function getEventVariantByIdentifier(events, variantIdentifier) {
  return Belt_Option.flatMap(Curry._2(TrackingPlanMappedModel.Events.get, events, variantIdentifier.baseEventId), (function ($$event) {
                return Belt_Array.getBy($$event.variants, (function (variant) {
                              return variant.id === variantIdentifier.variantId;
                            }));
              }));
}

function getActionsToReplacePropertiesInTrackingPlan(modelEvents, modelProperties, propertyIdsToReplace, replacementProperty) {
  var propertiesToReplace = Curry._1(TrackingPlanMappedModel.Properties.fromArray, Belt_Array.keepMapU(propertyIdsToReplace, (function (propertyIdToReplace) {
              return Curry._2(TrackingPlanMappedModel.Properties.get, modelProperties, propertyIdToReplace);
            })));
  var replaceActions = Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.Events.mapToArray, modelEvents, (function ($$event) {
              var removeActions = Belt_List.toArray(Belt_List.keepMapU($$event.directPropertyRefs, (function (modelProperty) {
                          if (modelProperty.TAG !== "PropertyRef") {
                            return ;
                          }
                          var id = modelProperty._0.id;
                          if (Curry._2(TrackingPlanMappedModel.Properties.has, propertiesToReplace, id)) {
                            return [
                                    {
                                      NAME: "RemovePropertyRefV3",
                                      VAL: [
                                        $$event.id,
                                        id
                                      ]
                                    },
                                    {
                                      eventId: $$event.id,
                                      eventQuery: $$event.id,
                                      propertyId: id,
                                      propertyQuery: id
                                    }
                                  ];
                          }
                          
                        })));
              if (removeActions.length !== 0) {
                return Belt_Array.concat(removeActions, Belt_List.everyU($$event.directPropertyRefs, (function (modelProperty) {
                                  return ModelUtils_shared.getPropertyId(modelProperty) !== replacementProperty.id;
                                })) ? [[
                                {
                                  NAME: "AddPropertyRef",
                                  VAL: [
                                    $$event.id,
                                    replacementProperty.id
                                  ]
                                },
                                {
                                  eventId: $$event.id,
                                  eventQuery: $$event.id,
                                  propertyId: replacementProperty.id,
                                  propertyQuery: replacementProperty.id
                                }
                              ]] : []);
              } else {
                return [];
              }
            })));
  var nestedObjectPropertyActions = Curry._2(TrackingPlanMappedModel.Properties.keepMap, modelProperties, (function (parentProperty) {
          var objectChildren = Curry._2(TrackingPlanMappedModel.Properties.Utils.getObjectChildren, modelProperties, parentProperty.id);
          var propertiesToReplace = Curry._2(TrackingPlanMappedModel.Properties.keep, objectChildren, (function (param) {
                  var id = param.id;
                  return Belt_Array.someU(propertyIdsToReplace, (function (propertyIdToReplace) {
                                return id === propertyIdToReplace;
                              }));
                }));
          if (Curry._1(TrackingPlanMappedModel.Properties.size, propertiesToReplace) > 0 && !Curry._2(TrackingPlanMappedModel.Properties.has, objectChildren, replacementProperty.id)) {
            return [
                    {
                      NAME: "AddPropertyValidation",
                      VAL: [
                        parentProperty.id,
                        {
                          NAME: "NestedProperty",
                          VAL: {
                            hd: {
                              id: replacementProperty.id,
                              description: "",
                              pinnedValue: undefined
                            },
                            tl: /* [] */0
                          }
                        }
                      ]
                    },
                    {
                      propertyIds: Belt_Array.concat([
                            parentProperty.id,
                            replacementProperty.id
                          ], Curry._1(TrackingPlanMappedModel.Properties.keys, propertiesToReplace)),
                      propertiesQuery: Belt_Array.concat([
                            parentProperty.id,
                            replacementProperty.id
                          ], Curry._1(TrackingPlanMappedModel.Properties.keys, propertiesToReplace))
                    }
                  ];
          }
          
        }));
  var archiveActions = Belt_Array.mapU(propertyIdsToReplace, (function (propertyId) {
          return [
                  {
                    NAME: "Archive",
                    VAL: {
                      NAME: "Property",
                      VAL: propertyId
                    }
                  },
                  {
                    propertyId: propertyId,
                    propertyQuery: propertyId
                  }
                ];
        }));
  return Belt_Array.concatMany([
              replaceActions,
              nestedObjectPropertyActions,
              archiveActions
            ]);
}

function getDefaultDestinationsForSource(destinations, source) {
  return Belt_List.keepMapU(Belt_List.keepMapU(source.destinations, (function (param) {
                    return Curry._2(TrackingPlanMappedModel.Destinations.get, destinations, param.destinationId);
                  })), (function (destination) {
                if (destination.disabledByDefault) {
                  return ;
                } else {
                  return destination.id;
                }
              }));
}

function getPropertyNamespaceWithIds(withPropertyBundleNamesOpt, model) {
  var withPropertyBundleNames = withPropertyBundleNamesOpt !== undefined ? withPropertyBundleNamesOpt : true;
  var mappingNames = Belt_Array.keepMapU(model.rules, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          var propertyId;
          var name;
          var variant = match.NAME;
          if (variant === "PropertyRef") {
            if (typeof match$1 !== "object") {
              return ;
            }
            if (match$1.NAME !== "NameMapping") {
              return ;
            }
            var name$1 = match$1.VAL.name;
            if (name$1 === undefined) {
              return ;
            }
            propertyId = match.VAL[1];
            name = name$1;
          } else {
            if (variant !== "Property") {
              return ;
            }
            if (typeof match$1 !== "object") {
              return ;
            }
            if (match$1.NAME !== "NameMapping") {
              return ;
            }
            var name$2 = match$1.VAL.name;
            if (name$2 === undefined) {
              return ;
            }
            propertyId = match.VAL;
            name = name$2;
          }
          return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Properties.get, model.properties, propertyId), (function (property) {
                        return {
                                propertyId: propertyId,
                                name: name,
                                originalName: property.name,
                                sendAs: property.sendAs
                              };
                      }));
        }));
  var propertyNames = Curry._2(TrackingPlanMappedModel.Properties.mapToArray, model.properties, (function (property) {
          return {
                  propertyId: property.id,
                  name: property.name,
                  originalName: undefined,
                  sendAs: property.sendAs
                };
        }));
  var propertyBundleNames = withPropertyBundleNames ? Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToArray, model.propertyBundles, (function (bundle) {
            return {
                    propertyId: bundle.id,
                    name: bundle.name,
                    originalName: undefined,
                    sendAs: undefined
                  };
          })) : [];
  var propertyUniqueNames = Curry._2(TrackingPlanMappedModel.Properties.keepMap, model.properties, (function (property) {
          return Belt_Option.map(property.uniqueName, (function (uniqueName) {
                        return {
                                propertyId: property.id,
                                name: uniqueName,
                                originalName: undefined,
                                sendAs: property.sendAs
                              };
                      }));
        }));
  return Belt_Array.concatMany([
              mappingNames,
              propertyNames,
              propertyBundleNames,
              propertyUniqueNames
            ]);
}

function getPropertyNamespace(model) {
  return Belt_Array.map(getPropertyNamespaceWithIds(undefined, model), (function (param) {
                return param.name;
              }));
}

function getBranchName(openBranches, branchId) {
  if (branchId === "master") {
    return "main";
  } else {
    return Belt_Option.map(Curry._2(TrackingPlanMappedModel.OpenBranches.get, openBranches, branchId), (function (param) {
                  return param[1];
                }));
  }
}

var getMatchesFromPropertyLegacy = ModelUtils_shared.getMatchesFromPropertyLegacy;

var getTriggerFromEvent = ModelUtils_shared.getTriggerFromEvent;

var defaultDestinationName = ModelUtils_shared.defaultDestinationName;

var getDestinationName = ModelUtils_shared.getDestinationName;

var getPropertyId = ModelUtils_shared.getPropertyId;

var getPropertyNameMappingsOnEvent = ModelUtils_shared.getPropertyNameMappingsOnEvent;

var isDestinationInterfaceEnabledForSourceDestination = ModelUtils_shared.isDestinationInterfaceEnabledForSourceDestination;

var getPropertyPinnedValueFromEvent = ModelUtils_shared.getPropertyPinnedValueFromEvent;

var getEventActions = ModelUtils_shared.getEventActions;

var eventsSendingPropertyFromSources__eventUsingProperty = ModelUtils_shared.eventsSendingPropertyFromSources__eventUsingProperty;

var eventsSendingPropertyFromSources__eventVariantUsingProperty = ModelUtils_shared.eventsSendingPropertyFromSources__eventVariantUsingProperty;

var doesPropertyContainPropertyIdAsFirstLevelNested = ModelUtils_shared.doesPropertyContainPropertyIdAsFirstLevelNested;

var doesPropertyContainPropertyIdAsAnyLevelNested = ModelUtils_shared.doesPropertyContainPropertyIdAsAnyLevelNested;

export {
  getMatchesFromPropertyLegacy ,
  getTriggerFromEvent ,
  defaultDestinationName ,
  getDestinationName ,
  getPropertyId ,
  getPropertyNameMappingsOnEvent ,
  isDestinationInterfaceEnabledForSourceDestination ,
  getPropertyPinnedValueFromEvent ,
  getEventActions ,
  eventsSendingPropertyFromSources__eventUsingProperty ,
  eventsSendingPropertyFromSources__eventVariantUsingProperty ,
  doesPropertyContainPropertyIdAsFirstLevelNested ,
  doesPropertyContainPropertyIdAsAnyLevelNested ,
  destinationIdsWithoutArchived ,
  metricIdsWithoutArchived ,
  eventIdsWithoutArchived ,
  getGoalsWithEvent ,
  getPropertyRefById ,
  resolveProperty ,
  resolveProperties ,
  getAllPropertyIdsFromNameMappingName ,
  getPropertyIdFromNameMappingName ,
  getPropertyByNameOrNameMapping ,
  mapRulesByPropertyId ,
  getAllPropertiesByNameOrNameMapping ,
  resolvePropertyBundleRefs ,
  getSourcesEvents ,
  getSourceEvents ,
  doesEventSendPropertyFromSource ,
  eventsSendingPropertyFromSources ,
  eventsSendingProperty ,
  getIncludedSourceIds ,
  isPropertySentFromSource ,
  getEventSourceIdsByEventId ,
  makePropertyAbsenceFromOptionalAndExcludedSources ,
  isPropertyOnEvent_byEvent ,
  isPropertyOnEvent ,
  getEventPropertyIds ,
  getEventProperties ,
  isPropertyIncludedOnEventAndSources ,
  isPropertyIncludedOnEventAndSource ,
  isPropertyOptionalOnEventsAndSources ,
  isPropertyOptionalOnEventAndSource ,
  isPropertyOptionalOnEvents ,
  isPropertyOptionalOnEvent ,
  isPropertyOptionalOnSource ,
  isPropertyOptionalSomewhere ,
  systemPropertyExcludedSources ,
  isEventIncludedInSource ,
  getAllTags ,
  getGroupTypeNames ,
  getEventNames ,
  getPropertyNames ,
  updatePropertyValidations ,
  updateExistingVariant ,
  updateTrigger ,
  updateEventVariantTrigger_mappedModel ,
  hasMigrated ,
  isPropertyIdNested ,
  getEventPossibleNames ,
  getPropertyPossibleNames ,
  getEventVariantByIdentifier ,
  getActionsToReplacePropertiesInTrackingPlan ,
  getDefaultDestinationsForSource ,
  getPropertyNamespaceWithIds ,
  getPropertyNamespace ,
  getBranchName ,
}
/* ModelUtils_shared Not a pure module */
