// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconWarning from "../IconWarning.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as IconCheckmark from "../IconCheckmark.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ImportReviewRow from "./ImportReviewRow.mjs";
import * as CollapsedContent from "../CollapsedContent.mjs";
import * as IconErrorHexagon from "../IconErrorHexagon.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as IconCheckmarkCircle from "../IconCheckmarkCircle.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as ImportReview__V2__Utils from "./ImportReview__V2__Utils.mjs";
import * as ImportReview__BranchPicker from "./ImportReview__BranchPicker.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(40)),
      tl: {
        hd: Css.maxWidth(Css.pct(100.0)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.zIndex(1),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.right(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.vw(100.0)),
                      tl: {
                        hd: Css.paddingTop(Css.px(48)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.light02),
                          tl: {
                            hd: Css.paddingBottom(Css.px(12)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var topStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(580)),
      tl: {
        hd: Css.position("relative"),
        tl: /* [] */0
      }
    });

var backlinkStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.pct(100.0)),
        tl: {
          hd: Css.top(Css.px(10)),
          tl: {
            hd: Css.marginRight(Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var countsStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(12)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.boxShadow(Styles.Shadow.subtle),
          tl: {
            hd: Css.padding(Css.px(12)),
            tl: {
              hd: Css.marginBottom(Css.px(32)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var countStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var tableIntroStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(99)),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.paddingTop(Css.px(8)),
                    tl: {
                      hd: Css.paddingRight(Css.px(8)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(8)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(80)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var tableIntroLeftStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.marginRight(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function cmp(param, param$1) {
  var c = Caml_obj.compare(param[0], param$1[0]);
  if (c !== 0) {
    return c;
  } else {
    return Caml_obj.compare(param[1], param$1[1]);
  }
}

var StringTupleComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function ImportReview(Props) {
  var parsedTrackingPlan = Props.parsedTrackingPlan;
  var onContinue = Props.onContinue;
  var hasEventsOnModel = Props.hasEventsOnModel;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var importMethod = Props.importMethod;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use();
  var globalSend = GlobalSendContext.use();
  var match = React.useState(function (param) {
        return false;
      });
  var setLoading = match[1];
  var isLoading = match[0];
  var match$1 = React.useState(function (param) {
        
      });
  var setExcludedEventIds = match$1[1];
  var excludedEventIds = match$1[0];
  var importStartedLocationQueryParam = ImportReview__V2__Utils.useImportStartedLocationQueryParam();
  var trackImportChanged = function (eventNameOpt, propertyNameOpt, importChange) {
    var eventName = eventNameOpt !== undefined ? Caml_option.valFromOption(eventNameOpt) : undefined;
    var propertyName = propertyNameOpt !== undefined ? Caml_option.valFromOption(propertyNameOpt) : undefined;
    AnalyticsRe.importChanged(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, importChange, eventName, propertyName, importStartedLocationQueryParam.value, workspace.id);
  };
  var match$2 = React.useState(function (param) {
        
      });
  var match$3 = React.useState(function (param) {
        return Belt_Set.make(StringTupleComparator);
      });
  var setExcludedEventPropertyIds = match$3[1];
  var excludedEventPropertyIds = match$3[0];
  var match$4 = React.useState(function (param) {
        
      });
  var setExcludedUserPropertyIds = match$4[1];
  var excludedUserPropertyIds = match$4[0];
  var match$5 = React.useState(function (param) {
        return false;
      });
  var setExcludeAllUserProperties = match$5[1];
  var excludeAllUserProperties = match$5[0];
  var match$6 = React.useState(function (param) {
        
      });
  var setExcludedSystemPropertyIds = match$6[1];
  var excludedSystemPropertyIds = match$6[0];
  var match$7 = React.useState(function (param) {
        return false;
      });
  var setExcludeAllSystemProperties = match$7[1];
  var excludeAllSystemProperties = match$7[0];
  React.useEffect((function (param) {
          if (isLoading || Belt_SetString.isEmpty(excludedEventIds) && Belt_Set.isEmpty(excludedEventPropertyIds) && Belt_SetString.isEmpty(excludedUserPropertyIds) && excludeAllUserProperties && Belt_SetString.isEmpty(excludedSystemPropertyIds) && excludeAllSystemProperties) {
            return ;
          }
          var warnBeforeLosingChanges = function ($$event) {
            $$event.preventDefault();
            $$event.returnValue = "";
          };
          window.addEventListener("beforeunload", warnBeforeLosingChanges);
          return (function (param) {
                    window.removeEventListener("beforeunload", warnBeforeLosingChanges);
                  });
        }), [
        isLoading,
        excludedEventIds,
        excludedEventPropertyIds,
        excludedUserPropertyIds,
        excludeAllUserProperties,
        excludedSystemPropertyIds,
        excludeAllSystemProperties
      ]);
  var handleIncludeToggle = function (eventId, eventName, reactEvent) {
    var checked = reactEvent.target.checked;
    Curry._1(setExcludedEventIds, (function (excluded) {
            if (checked) {
              return Belt_SetString.remove(excluded, eventId);
            } else {
              return Belt_SetString.add(excluded, eventId);
            }
          }));
    trackImportChanged(Caml_option.some(eventName), undefined, checked ? "EventSelected" : "EventDeselected");
  };
  var handleIncludePropertyToggle = function (eventId, eventName, propertyId, propertyName, reactEvent) {
    var checked = reactEvent.target.checked;
    Curry._1(setExcludedEventPropertyIds, (function (excluded) {
            if (checked) {
              return Belt_Set.remove(excluded, [
                          eventId,
                          propertyId
                        ]);
            } else {
              return Belt_Set.add(excluded, [
                          eventId,
                          propertyId
                        ]);
            }
          }));
    trackImportChanged(Caml_option.some(eventName), Caml_option.some(propertyName), checked ? "PropertySelected" : "PropertyDeselected");
  };
  var handleIncludeUserPropertyToggle = function (propertyId, propertyName, reactEvent) {
    var checked = reactEvent.target.checked;
    Curry._1(setExcludedUserPropertyIds, (function (excluded) {
            if (checked) {
              return Belt_SetString.remove(excluded, propertyId);
            } else {
              return Belt_SetString.add(excluded, propertyId);
            }
          }));
    trackImportChanged(undefined, Caml_option.some(propertyName), checked ? "PropertySelected" : "PropertyDeselected");
  };
  var handleExcludeAllUserPropertiesToggle = function (reactEvent) {
    var checked = reactEvent.target.checked;
    Curry._1(setExcludeAllUserProperties, (function (param) {
            return !checked;
          }));
    trackImportChanged(undefined, undefined, checked ? "UserPropertiesIncluded" : "UserPropertiesExcluded");
  };
  var handleIncludeSystemPropertyToggle = function (propertyId, propertyName, reactEvent) {
    var checked = reactEvent.target.checked;
    Curry._1(setExcludedSystemPropertyIds, (function (excluded) {
            if (checked) {
              return Belt_SetString.remove(excluded, propertyId);
            } else {
              return Belt_SetString.add(excluded, propertyId);
            }
          }));
    trackImportChanged(undefined, Caml_option.some(propertyName), checked ? "PropertySelected" : "PropertyDeselected");
  };
  var handleExcludeAllSystemPropertiesToggle = function (reactEvent) {
    var checked = reactEvent.target.checked;
    Curry._1(setExcludeAllSystemProperties, (function (param) {
            return !checked;
          }));
    trackImportChanged(undefined, undefined, checked ? "SystemPropertiesIncluded" : "SystemPropertiesExcluded");
  };
  var filterPropertiesBeforeImport = function (properties) {
    var includedEvents = Belt_Array.keepU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
            return !Belt_SetString.has(excludedEventIds, $$event.id);
          }));
    return Belt_Array.keepU(properties, (function (property) {
                  var match = property.sendAs;
                  if (typeof match !== "object") {
                    switch (match) {
                      case "SystemProperty" :
                          if (excludeAllSystemProperties) {
                            return false;
                          } else {
                            return !Belt_SetString.has(excludedSystemPropertyIds, property.id);
                          }
                      case "EventProperty" :
                          break;
                      case "UserProperty" :
                          if (excludeAllUserProperties) {
                            return false;
                          } else {
                            return !Belt_SetString.has(excludedUserPropertyIds, property.id);
                          }
                      
                    }
                  }
                  return !Belt_MapString.some(property.presence, (function (eventId, param) {
                                if (Belt_SetString.has(excludedEventIds, eventId) && Belt_Array.every(includedEvents, (function ($$event) {
                                          return $$event.id !== eventId;
                                        }))) {
                                  return true;
                                } else {
                                  return Belt_Set.has(excludedEventPropertyIds, [
                                              eventId,
                                              property.id
                                            ]);
                                }
                              }));
                }));
  };
  var newAndUpdatedProperties = Belt_Array.concat(parsedTrackingPlan.newProperties, parsedTrackingPlan.updatedProperties);
  var handleFinishImport = function (selectedBranch) {
    var branchName = typeof selectedBranch === "object" ? (
        selectedBranch.NAME === "Create" ? selectedBranch.VAL : selectedBranch.VAL.name
      ) : "main";
    var includedNewEvents = Belt_Array.keepU(parsedTrackingPlan.newEvents, (function ($$event) {
            return !Belt_SetString.has(excludedEventIds, $$event.id);
          }));
    var includedUpdatedEvents = Belt_Array.keepU(parsedTrackingPlan.updatedEvents, (function ($$event) {
            return !Belt_SetString.has(excludedEventIds, $$event.id);
          }));
    var numEvents = includedNewEvents.length + includedUpdatedEvents.length | 0;
    var numProperties = filterPropertiesBeforeImport(newAndUpdatedProperties).length;
    var importedStakeholderDomains = parsedTrackingPlan.allStakeholderDomains;
    var confirmationText = "You are about to import " + String(numEvents) + " event" + (
      numEvents === 1 ? "" : "s"
    ) + " and " + String(numProperties) + " propert" + (
      numProperties === 1 ? "y" : "ies"
    ) + " to your workspace" + (
      selectedBranch === "Master" ? "" : " on the \"" + branchName + "\" branch"
    ) + "." + (
      importedStakeholderDomains.length !== 0 ? " The stakeholder domains changes will be applied to the tracking plan globally, on all branches." : ""
    ) + " Do you want to run the import?";
    Curry._1(globalSend, {
          TAG: "OpenModal",
          _0: {
            NAME: "ConfirmModal",
            VAL: [
              "Finish import",
              confirmationText,
              "Import",
              (function (param) {
                  Curry._1(setLoading, (function (param) {
                          return true;
                        }));
                  Curry._2(onContinue, selectedBranch, {
                        formatName: parsedTrackingPlan.formatName,
                        newEvents: Belt_Array.mapU(includedNewEvents, (function ($$event) {
                                return {
                                        id: $$event.id,
                                        name: $$event.name,
                                        uniqueNameIndex: $$event.uniqueNameIndex,
                                        description: $$event.description,
                                        categories: $$event.categories,
                                        tags: $$event.tags,
                                        propertyIds: Belt_Array.keepU($$event.propertyIds, (function (param) {
                                                return !Belt_Set.has(excludedEventPropertyIds, [
                                                            $$event.id,
                                                            param[0]
                                                          ]);
                                              })),
                                        updatedPropertyIds: $$event.updatedPropertyIds,
                                        propertyBundleIds: $$event.propertyBundleIds,
                                        sources: $$event.sources,
                                        stakeholderDomains: $$event.stakeholderDomains,
                                        warnings: $$event.warnings,
                                        nameMapping: $$event.nameMapping
                                      };
                              })),
                        updatedEvents: Belt_Array.mapU(includedUpdatedEvents, (function ($$event) {
                                return {
                                        id: $$event.id,
                                        name: $$event.name,
                                        uniqueNameIndex: $$event.uniqueNameIndex,
                                        description: $$event.description,
                                        categories: $$event.categories,
                                        tags: $$event.tags,
                                        propertyIds: Belt_Array.keepU($$event.propertyIds, (function (param) {
                                                return !Belt_Set.has(excludedEventPropertyIds, [
                                                            $$event.id,
                                                            param[0]
                                                          ]);
                                              })),
                                        updatedPropertyIds: $$event.updatedPropertyIds,
                                        propertyBundleIds: $$event.propertyBundleIds,
                                        sources: $$event.sources,
                                        stakeholderDomains: $$event.stakeholderDomains,
                                        warnings: $$event.warnings,
                                        nameMapping: $$event.nameMapping
                                      };
                              })),
                        warnings: parsedTrackingPlan.warnings,
                        newProperties: filterPropertiesBeforeImport(parsedTrackingPlan.newProperties),
                        updatedProperties: filterPropertiesBeforeImport(parsedTrackingPlan.updatedProperties),
                        allParsedProperties: parsedTrackingPlan.allParsedProperties,
                        sources: parsedTrackingPlan.sources,
                        allStakeholderDomains: parsedTrackingPlan.allStakeholderDomains,
                        propertyBundles: parsedTrackingPlan.propertyBundles
                      });
                }),
              (function (param) {
                  Curry._1(setLoading, (function (param) {
                          return false;
                        }));
                })
            ]
          }
        });
  };
  var hasCategories = Belt_Array.someU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return $$event.categories.length !== 0;
        }));
  var hasTags = Belt_Array.someU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return $$event.tags.length !== 0;
        }));
  var hasStakeholderDomains = Belt_Array.someU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return $$event.stakeholderDomains.length !== 0;
        }));
  var hasEventNameMapping = Belt_Array.someU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return $$event.nameMapping.length !== 0;
        }));
  var hasPropertyNameMapping = Belt_Array.some(parsedTrackingPlan.allParsedProperties, (function (property) {
          return Belt_Option.isSome(property.nameMapping);
        }));
  var hasNameMapping = hasEventNameMapping || hasPropertyNameMapping;
  var selectedEvents = Belt_Array.keepU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
          return !Belt_SetString.has(excludedEventIds, $$event.id);
        }));
  var selectedEventCount = selectedEvents.length;
  var selectedPropertiesCount = filterPropertiesBeforeImport(newAndUpdatedProperties).length;
  var currentBranchName;
  if (typeof currentBranch === "object") {
    var branchId = currentBranch.VAL;
    currentBranchName = Belt_Option.mapWithDefault(Belt_List.getByU(openBranches, (function (param) {
                return param[0] === branchId;
              })), "main", (function (param) {
            return param[1];
          }));
  } else {
    currentBranchName = "main";
  }
  var canSelectMain = !workspace.settings.protectedMainBranch || viewerRole === "Admin";
  var match$8 = Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length;
  var match$9 = newAndUpdatedProperties.length;
  var match$10 = parsedTrackingPlan.sources.length;
  var match$11 = parsedTrackingPlan.allStakeholderDomains.length;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  if (match$8 !== 0) {
    exit$4 = 5;
  } else {
    var exit$5 = 0;
    if (match$9 !== 0) {
      exit$5 = 6;
    } else {
      var exit$6 = 0;
      if (match$10 !== 0 || match$11 !== 0) {
        exit$6 = 7;
      } else {
        tmp = "Found no data";
      }
      if (exit$6 === 7) {
        if (match$11 !== 0) {
          if (match$10 !== 0) {
            exit$5 = 6;
          } else {
            tmp = match$11.toString() + " stakeholder domains found";
          }
        } else {
          tmp = match$10.toString() + " sources found";
        }
      }
      
    }
    if (exit$5 === 6) {
      if (match$10 !== 0 || match$11 !== 0) {
        exit$4 = 5;
      } else {
        tmp = match$9.toString() + " properties found";
      }
    }
    
  }
  if (exit$4 === 5) {
    if (match$9 !== 0 || match$10 !== 0 || match$11 !== 0) {
      exit$3 = 4;
    } else {
      tmp = match$8.toString() + " events found";
    }
  }
  if (exit$3 === 4) {
    if (match$8 !== 0) {
      exit$2 = 3;
    } else if (match$11 !== 0) {
      if (match$10 !== 0) {
        exit$2 = 3;
      } else {
        tmp = match$9.toString() + " properties and " + match$11.toString() + " stakeholder domains found";
      }
    } else {
      tmp = match$9.toString() + " properties and " + match$10.toString() + " sources found";
    }
  }
  if (exit$2 === 3) {
    if (match$9 !== 0 || match$11 !== 0) {
      exit$1 = 2;
    } else {
      tmp = match$8.toString() + " events and " + match$10.toString() + " sources found";
    }
  }
  if (exit$1 === 2) {
    if (match$10 !== 0 || match$11 !== 0) {
      exit = 1;
    } else {
      tmp = match$8.toString() + " events and " + match$9.toString() + " properties found";
    }
  }
  if (exit === 1) {
    tmp = match$11 !== 0 ? (
        match$10 !== 0 ? match$8.toString() + " events, " + match$9.toString() + " properties, " + match$10.toString() + " sources and " + match$11.toString() + " stakeholder domains found" : match$8.toString() + " events, " + match$9.toString() + " properties and " + match$11.toString() + " stakeholder domains found"
      ) : match$8.toString() + " events, " + match$9.toString() + " properties and " + match$10.toString() + " sources found";
  }
  var userProperties = Belt_Array.keepU(newAndUpdatedProperties, (function (property) {
          return property.sendAs === "UserProperty";
        }));
  var systemProperties = Belt_Array.keepU(newAndUpdatedProperties, (function (property) {
          return property.sendAs === "SystemProperty";
        }));
  var propertyIdsOnEvents = Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.map(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
                  return Belt_Array.concat($$event.propertyIds, $$event.updatedPropertyIds);
                }))), (function (param) {
          return param[0];
        }));
  var findPropertyIdsUsedInBundleById = function (propertyBundleId) {
    return Belt_Option.getWithDefault(Belt_Option.mapU(Belt_Array.getByU(parsedTrackingPlan.propertyBundles, (function (bundle) {
                          return bundle.id === propertyBundleId;
                        })), (function (bundle) {
                      return Belt_Array.mapU(bundle.propertiesUsedOnlyInThisPropertyBundle, (function (param) {
                                    return param.id;
                                  }));
                    })), []);
  };
  var propertyIdsOnEventBundles = Belt_Array.concatMany(Belt_Array.concat(Belt_Array.concatMany(Belt_Array.map(parsedTrackingPlan.newEvents, (function ($$event) {
                      return Belt_Array.map($$event.propertyBundleIds, findPropertyIdsUsedInBundleById);
                    }))), Belt_Array.concatMany(Belt_Array.map(parsedTrackingPlan.updatedEvents, (function ($$event) {
                      return Belt_Array.map($$event.propertyBundleIds, findPropertyIdsUsedInBundleById);
                    })))));
  var allPropertyIdsOnEvents = Belt_Array.concat(propertyIdsOnEvents, propertyIdsOnEventBundles);
  var eventPropertiesWithoutEvents = Belt_Array.keepU(newAndUpdatedProperties, (function (property) {
          if (property.sendAs === "EventProperty") {
            return Belt_Array.everyU(allPropertyIdsOnEvents, (function (propertyId) {
                          return propertyId !== property.id;
                        }));
          } else {
            return false;
          }
        }));
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("header", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.px(580)),
                            tl: {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: backlinkStyles
                        }, React.createElement(TextButton.make, {
                              onClick: (function (param) {
                                  if (Belt_SetString.isEmpty(excludedEventIds) && Belt_Set.isEmpty(excludedEventPropertyIds) || window.confirm("You have an unfinished import draft. Are you sure you want to discard it?")) {
                                    window.history.back();
                                    return ;
                                  }
                                  
                                }),
                              style: "Blue",
                              size: "Medium",
                              children: null
                            }, React.createElement(Icons.ArrowLeft.make, {
                                  size: 11,
                                  color: Styles.Color.darkBlue
                                }), React.createElement(Spacer.make, {
                                  display: "inlineBlock",
                                  width: 4
                                }), " Back")), React.createElement(Title.make, {
                          children: "Review Uploaded Data",
                          size: "Medium"
                        }))), React.createElement("div", {
                  className: topStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: "Please review the information below before completing the import process."
                    }), React.createElement("div", {
                      className: countsStyles
                    }, React.createElement("div", {
                          className: countStyles
                        }, React.createElement(IconCheckmarkCircle.make, {
                              size: 16,
                              color: Styles.Color.green
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: tmp
                            })), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement(CollapsedContent.make, {
                          button: (function (onClick, isExpanded) {
                              return React.createElement("div", {
                                          className: countStyles
                                        }, React.createElement(IconErrorHexagon.make, {
                                              size: 16
                                            }), React.createElement(Spacer.make, {
                                              width: 8
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              children: null
                                            }, String(parsedTrackingPlan.warnings.parseWarnings.length), parsedTrackingPlan.warnings.parseWarnings.length === 1 ? " error" : " errors"), React.createElement(Spacer.make, {
                                              width: 8
                                            }), Caml_obj.equal(parsedTrackingPlan.warnings.parseWarnings, []) ? null : React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.flexGrow(1.0),
                                                        tl: {
                                                          hd: Css.justifyContent("flexEnd"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })
                                              }, React.createElement(Button.make, {
                                                    label: isExpanded ? "Show Errors" : "Hide Errors",
                                                    onClick: (function (param) {
                                                        Curry._1(onClick, undefined);
                                                      }),
                                                    style: "outline"
                                                  })));
                            }),
                          children: (function (param) {
                              return React.createElement("p", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.fontSize(Css.px(11)),
                                                tl: {
                                                  hd: Css.margin2(Css.px(4), Css.px(0)),
                                                  tl: {
                                                    hd: Css.fontFamily(Styles.FontFamily.polar),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.red),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              })
                                        }, Belt_Array.mapWithIndexU(parsedTrackingPlan.warnings.parseWarnings, (function (index, errorLine) {
                                                return React.createElement(React.Fragment, {
                                                            children: null,
                                                            key: errorLine
                                                          }, index === 0 ? null : React.createElement(Spacer.make, {
                                                                  height: 4
                                                                }), errorLine);
                                              })));
                            })
                        }), React.createElement(Spacer.make, {
                          height: 12
                        }), React.createElement("div", {
                          className: countStyles
                        }, React.createElement(IconWarning.make, {
                              color: Styles.Color.orange,
                              size: 16
                            }), React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: null
                            }, String(Belt_Array.reduceU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), 0, (function (sum, $$event) {
                                        return sum + $$event.warnings.length | 0;
                                      })) + Belt_Array.reduceU(newAndUpdatedProperties, 0, (function (sum, property) {
                                        return sum + property.warnings.length | 0;
                                      })) | 0), " warnings", React.createElement($$Text.make, {
                                  element: "Span",
                                  weight: "Regular",
                                  children: " (see details in the table below)"
                                }))))), React.createElement("div", {
                  className: tableIntroStyles
                }, React.createElement("div", {
                      className: tableIntroLeftStyles
                    }, React.createElement($$Text.make, {
                          size: "Large",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: null
                        }, "Import ", React.createElement($$Text.make, {
                              element: "Span",
                              size: "Medium",
                              weight: "Medium",
                              color: Styles.Color.light10,
                              children: "(" + selectedEventCount.toString() + " events)"
                            }))), typeof currentBranch === "object" ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Medium",
                            color: Styles.Color.light08,
                            children: "Import " + selectedEventCount.toString() + " Events & " + selectedPropertiesCount.toString() + " Properties to…"
                          }), React.createElement(Spacer.make, {
                            width: 2
                          }), React.createElement(IconCheckmark.make, {
                            size: 12,
                            color: Styles.Color.green
                          }), React.createElement(Spacer.make, {
                            width: 2
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.green,
                            children: currentBranchName
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Button.make, {
                            disabled: isLoading || selectedEventCount === 0,
                            label: isLoading ? "Importing…" : "Import",
                            onClick: (function (param) {
                                Curry._1(setLoading, (function (param) {
                                        return true;
                                      }));
                                handleFinishImport({
                                      NAME: "Branch",
                                      VAL: {
                                        id: NamedBranch.unnamedBranchToId(currentBranch),
                                        name: currentBranchName
                                      }
                                    });
                              })
                          })) : (
                    hasEventsOnModel ? React.createElement(ImportReview__BranchPicker.make, {
                            canSelectMain: canSelectMain,
                            handleFinishImport: handleFinishImport,
                            importDisabled: selectedEventCount === 0 && selectedPropertiesCount === 0,
                            openBranches: openBranches,
                            selectedBranch: match$2[0],
                            selectedEventCount: selectedEventCount,
                            selectedPropertiesCount: selectedPropertiesCount,
                            setSelectedBranch: match$2[1]
                          }) : React.createElement(Button.make, {
                            disabled: isLoading,
                            label: isLoading ? "Importing..." : "Import " + String(selectedEventCount) + " Events & " + String(selectedPropertiesCount) + " Properties to main",
                            onClick: (function (param) {
                                handleFinishImport(currentBranch);
                              })
                          })
                  )), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.overflowX("auto"),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("table", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderCollapse("separate"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.minWidth(Css.vw(100.0)),
                                tl: {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.borderSpacing(Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length === 0 ? null : React.createElement("thead", {
                            className: Curry._1(Css.style, {
                                  hd: Css.position("sticky"),
                                  tl: {
                                    hd: Css.top(Css.px(0)),
                                    tl: {
                                      hd: Css.selector(" th", {
                                            hd: Css.textAlign("left"),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.white),
                                              tl: {
                                                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding(Css.px(12)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement("input", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.margin(Css.px(0)),
                                              tl: {
                                                hd: Css.cursor("pointer"),
                                                tl: {
                                                  hd: Css.display("block"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }),
                                        checked: Belt_SetString.isEmpty(excludedEventIds),
                                        type: "checkbox",
                                        onChange: (function (param) {
                                            Curry._1(setExcludedEventIds, (function (excluded) {
                                                    if (Belt_SetString.isEmpty(excluded)) {
                                                      return Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents), (function ($$event) {
                                                                        return $$event.id;
                                                                      })));
                                                    }
                                                    
                                                  }));
                                          })
                                      })), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                                          tl: /* [] */0
                                        })
                                  }), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.light10,
                                        children: "EVENT NAME"
                                      })), hasNameMapping ? React.createElement("th", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingRight(Css.px(12)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(12)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.light10,
                                          children: "NAME MAPPING"
                                        })) : null, React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(12)),
                                            tl: {
                                              hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.light10,
                                        children: "DESCRIPTION"
                                      })), hasCategories ? React.createElement("th", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingRight(Css.px(12)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(12)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.light10,
                                          children: "CATEGORIES"
                                        })) : null, hasTags ? React.createElement("th", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingRight(Css.px(48)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(12)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.light10,
                                          children: "TAGS"
                                        })) : null, hasStakeholderDomains ? React.createElement("th", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.paddingRight(Css.px(12)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(12)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          weight: "Semi",
                                          color: Styles.Color.light10,
                                          children: "STAKEHOLDER DOMAINS"
                                        })) : null, Belt_Array.map(parsedTrackingPlan.sources, (function (source) {
                                      return React.createElement("th", {
                                                  key: source,
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                                                        tl: {
                                                          hd: Css.paddingLeft(Css.px(12)),
                                                          tl: {
                                                            hd: Css.paddingRight(Css.px(12)),
                                                            tl: {
                                                              hd: Css.maxWidth(Css.px(80)),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, React.createElement($$Text.make, {
                                                      size: "Small",
                                                      weight: "Semi",
                                                      singleLine: true,
                                                      color: Styles.Color.light10,
                                                      children: source
                                                    }), React.createElement($$Text.make, {
                                                      size: "Tiny",
                                                      weight: "Semi",
                                                      color: Styles.Color.light08,
                                                      children: "SOURCE"
                                                    }));
                                    })), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(12)),
                                            tl: {
                                              hd: Css.width(Css.pct(100.0)),
                                              tl: {
                                                hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.light10,
                                        children: "WARNINGS"
                                      })))), React.createElement("tbody", undefined, Belt_Array.concatMany([
                              userProperties.length !== 0 ? [React.createElement(ImportReviewRow.UserOrSystemProperties.make, {
                                        properties: userProperties,
                                        excludedProperties: excludedUserPropertyIds,
                                        onPropertyToggle: handleIncludeUserPropertyToggle,
                                        onPropertiesIncludedToggle: handleExcludeAllUserPropertiesToggle,
                                        propertiesIncluded: !excludeAllUserProperties,
                                        importMethod: importMethod,
                                        propertySendAs: "UserProperty",
                                        key: "user-properties"
                                      })] : [],
                              systemProperties.length !== 0 ? [React.createElement(ImportReviewRow.UserOrSystemProperties.make, {
                                        properties: systemProperties,
                                        excludedProperties: excludedSystemPropertyIds,
                                        onPropertyToggle: handleIncludeSystemPropertyToggle,
                                        onPropertiesIncludedToggle: handleExcludeAllSystemPropertiesToggle,
                                        propertiesIncluded: !excludeAllSystemProperties,
                                        importMethod: importMethod,
                                        propertySendAs: "SystemProperty",
                                        key: "system-properties"
                                      })] : [],
                              eventPropertiesWithoutEvents.length !== 0 ? [React.createElement(ImportReviewRow.EventPropertiesWithoutEvents.make, {
                                        properties: eventPropertiesWithoutEvents,
                                        importMethod: importMethod,
                                        key: "event-properties"
                                      })] : [],
                              Belt_Array.map(parsedTrackingPlan.newEvents, (function ($$event) {
                                      return React.createElement(ImportReviewRow.$$Event.make, {
                                                  event: $$event,
                                                  included: !Belt_SetString.has(excludedEventIds, $$event.id),
                                                  properties: Belt_Array.keepMapU($$event.propertyIds, (function (param) {
                                                          var id = param[0];
                                                          return Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                        return property.id === id;
                                                                      }));
                                                        })),
                                                  propertyGroups: Belt_Array.keepMapU($$event.propertyBundleIds, (function (id) {
                                                          return Belt_Array.getByU(parsedTrackingPlan.propertyBundles, (function (group) {
                                                                        return group.id === id;
                                                                      }));
                                                        })),
                                                  propertyGroupProperties: Belt_Array.concatMany(Belt_Array.map(Belt_Array.keepMapU($$event.propertyBundleIds, (function (id) {
                                                                  return Belt_Array.getByU(parsedTrackingPlan.propertyBundles, (function (group) {
                                                                                return group.id === id;
                                                                              }));
                                                                })), (function (group) {
                                                              return Belt_Array.keepMapU(group.propertiesUsedOnlyInThisPropertyBundle, (function (param) {
                                                                            var id = param.id;
                                                                            return Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                                          return property.id === id;
                                                                                        }));
                                                                          }));
                                                            }))),
                                                  onToggle: handleIncludeToggle,
                                                  excludedProperties: excludedEventPropertyIds,
                                                  sources: parsedTrackingPlan.sources,
                                                  hasStakeholderDomains: hasStakeholderDomains,
                                                  hasCategories: hasCategories,
                                                  hasTags: hasTags,
                                                  hasNameMapping: hasNameMapping,
                                                  onPropertyToggle: handleIncludePropertyToggle,
                                                  model: model,
                                                  importMethod: importMethod,
                                                  key: $$event.id
                                                });
                                    })),
                              Belt_Array.map(parsedTrackingPlan.updatedEvents, (function ($$event) {
                                      return React.createElement(ImportReviewRow.$$Event.make, {
                                                  event: $$event,
                                                  included: !Belt_SetString.has(excludedEventIds, $$event.id),
                                                  properties: Belt_Array.keepMapU(Belt_Array.concat($$event.propertyIds, $$event.updatedPropertyIds), (function (param) {
                                                          var id = param[0];
                                                          return Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                        return property.id === id;
                                                                      }));
                                                        })),
                                                  propertyGroups: Belt_Array.keepMapU($$event.propertyBundleIds, (function (id) {
                                                          return Belt_Array.getByU(parsedTrackingPlan.propertyBundles, (function (group) {
                                                                        return group.id === id;
                                                                      }));
                                                        })),
                                                  propertyGroupProperties: Belt_Array.concatMany(Belt_Array.map(Belt_Array.keepMapU($$event.propertyBundleIds, (function (id) {
                                                                  return Belt_Array.getByU(parsedTrackingPlan.propertyBundles, (function (group) {
                                                                                return group.id === id;
                                                                              }));
                                                                })), (function (group) {
                                                              return Belt_Array.keepMapU(group.propertiesUsedOnlyInThisPropertyBundle, (function (param) {
                                                                            var id = param.id;
                                                                            return Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                                          return property.id === id;
                                                                                        }));
                                                                          }));
                                                            }))),
                                                  onToggle: handleIncludeToggle,
                                                  excludedProperties: excludedEventPropertyIds,
                                                  sources: parsedTrackingPlan.sources,
                                                  hasStakeholderDomains: hasStakeholderDomains,
                                                  hasCategories: hasCategories,
                                                  hasTags: hasTags,
                                                  hasNameMapping: hasNameMapping,
                                                  updatedEvent: true,
                                                  onPropertyToggle: handleIncludePropertyToggle,
                                                  model: model,
                                                  importMethod: importMethod,
                                                  key: $$event.id
                                                });
                                    }))
                            ])))));
}

var ImportBranchPicker;

var make = ImportReview;

export {
  rootStyles ,
  headerStyles ,
  topStyles ,
  backlinkStyles ,
  countsStyles ,
  countStyles ,
  tableIntroStyles ,
  tableIntroLeftStyles ,
  StringTupleComparator ,
  ImportBranchPicker ,
  make ,
}
/* rootStyles Not a pure module */
