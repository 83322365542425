// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as CmdK from "./CmdK.mjs";
import * as Icon from "./Icon.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSendAs from "./IconSendAs.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ActivityItem from "./ActivityItem.mjs";
import * as NewEventModal from "./NewEventModal.mjs";

var wrapper = Curry._1(Css.style, {
      hd: Css.width(Css.px(18)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

function CmdKActions$CmdKIcon(Props) {
  var type_ = Props.type_;
  var size = Props.size;
  return React.createElement("span", {
              className: wrapper
            }, React.createElement(Icon.make, {
                  type_: type_,
                  size: size,
                  color: Styles.Color.light07
                }));
}

var CmdKIcon = {
  wrapper: wrapper,
  make: CmdKActions$CmdKIcon
};

function getActions(globalSend, schemaBundle, groupTypes) {
  return Belt_Array.concatMany([
              [
                Curry.app(CmdK.Action.t, [
                      "add_event",
                      {
                        TAG: "Create",
                        _0: "Event..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  NewEventModal.openNewEventModal("CommandPalette", undefined, globalSend);
                                }), 0);
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "create_branch",
                      {
                        TAG: "Create",
                        _0: "Branch..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "NewBranch",
                                          VAL: [
                                            undefined,
                                            ""
                                          ]
                                        }
                                      });
                                }), 0);
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_event_property",
                      {
                        TAG: "Create",
                        _0: "Event Property..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "NewProperty",
                                          VAL: [
                                            "",
                                            "EventProperty",
                                            undefined,
                                            (function (propertyId, _propertyName) {
                                                Router.Schema.pushDrawerItem(undefined, undefined, {
                                                      NAME: "property",
                                                      VAL: [
                                                        propertyId,
                                                        undefined
                                                      ]
                                                    });
                                              }),
                                            "CommandPalette"
                                          ]
                                        }
                                      });
                                }), 0);
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_user_property",
                      {
                        TAG: "Create",
                        _0: "User Property..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "NewProperty",
                                          VAL: [
                                            "",
                                            "UserProperty",
                                            undefined,
                                            (function (propertyId, _propertyName) {
                                                Router.Schema.pushDrawerItem(undefined, undefined, {
                                                      NAME: "property",
                                                      VAL: [
                                                        propertyId,
                                                        undefined
                                                      ]
                                                    });
                                              }),
                                            "CommandPalette"
                                          ]
                                        }
                                      });
                                }), 0);
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_system_property",
                      {
                        TAG: "Create",
                        _0: "System Property..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "NewProperty",
                                          VAL: [
                                            "",
                                            "SystemProperty",
                                            undefined,
                                            (function (propertyId, _propertyName) {
                                                Router.Schema.pushDrawerItem(undefined, undefined, {
                                                      NAME: "property",
                                                      VAL: [
                                                        propertyId,
                                                        undefined
                                                      ]
                                                    });
                                              }),
                                            "CommandPalette"
                                          ]
                                        }
                                      });
                                }), 0);
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_event_property_group",
                      {
                        TAG: "Create",
                        _0: "Event Property Bundle..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "NewPropertyGroup",
                                          VAL: [
                                            undefined,
                                            undefined
                                          ]
                                        }
                                      });
                                }), 0);
                        }),
                      "create",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "add_category",
                      {
                        TAG: "Create",
                        _0: "Category..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "CreateCategory",
                                          VAL: [
                                            undefined,
                                            undefined
                                          ]
                                        }
                                      });
                                  AnalyticsRe.categoryInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.branchId, schemaBundle.schemaId);
                                }), 0);
                        }),
                      "create",
                      undefined
                    ])
              ],
              Belt_Array.map(groupTypes, (function (param) {
                      var id = param.id;
                      return Curry.app(CmdK.Action.t, [
                                  "add_group_property_" + id,
                                  {
                                    TAG: "Create",
                                    _0: param.name + " Property..."
                                  },
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (param) {
                                      return React.createElement(CmdKActions$CmdKIcon, {
                                                  type_: "plus",
                                                  size: "medium"
                                                });
                                    }),
                                  (function (param) {
                                      setTimeout((function (param) {
                                              Curry._1(globalSend, {
                                                    TAG: "OpenModal",
                                                    _0: {
                                                      NAME: "NewProperty",
                                                      VAL: [
                                                        "",
                                                        {
                                                          TAG: "GroupProperty",
                                                          _0: id
                                                        },
                                                        undefined,
                                                        (function (propertyId, _propertyName) {
                                                            Router.Schema.pushDrawerItem(undefined, undefined, {
                                                                  NAME: "property",
                                                                  VAL: [
                                                                    propertyId,
                                                                    undefined
                                                                  ]
                                                                });
                                                          }),
                                                        "CommandPalette"
                                                      ]
                                                    }
                                                  });
                                            }), 0);
                                    }),
                                  "create",
                                  undefined
                                ]);
                    })),
              [Curry.app(CmdK.Action.t, [
                      "add_group_type",
                      {
                        TAG: "Create",
                        _0: "Group Type..."
                      },
                      undefined,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      (function (param) {
                          setTimeout((function (param) {
                                  Curry._1(globalSend, {
                                        TAG: "OpenModal",
                                        _0: {
                                          NAME: "NewGroupType",
                                          VAL: [
                                            undefined,
                                            undefined
                                          ]
                                        }
                                      });
                                }), 0);
                        }),
                      "create",
                      undefined
                    ])]
            ]);
}

var Create = {
  getActions: getActions
};

function getSections(isGlobalWorkspace) {
  return Belt_Array.concatMany([
              [
                Curry.app(CmdK.Action.t, [
                      "events",
                      {
                        TAG: "Command",
                        _0: "Events..."
                      },
                      1.2,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "search",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      "search",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "properties",
                      {
                        TAG: "Command",
                        _0: "Properties..."
                      },
                      1.2,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "search",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      "search",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "propertyGroups",
                      {
                        TAG: "Command",
                        _0: "Property Bundles..."
                      },
                      1.2,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "search",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      "search",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "categories",
                      {
                        TAG: "Command",
                        _0: "Categories..."
                      },
                      1.2,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "search",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      "search",
                      undefined
                    ])
              ],
              isGlobalWorkspace ? [] : [
                  Curry.app(CmdK.Action.t, [
                        "sources",
                        {
                          TAG: "Command",
                          _0: "Sources..."
                        },
                        1.2,
                        undefined,
                        undefined,
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "search",
                                        size: "small"
                                      });
                          }),
                        undefined,
                        "search",
                        undefined
                      ]),
                  Curry.app(CmdK.Action.t, [
                        "destinations",
                        {
                          TAG: "Command",
                          _0: "Destinations..."
                        },
                        1.2,
                        undefined,
                        undefined,
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "search",
                                        size: "small"
                                      });
                          }),
                        undefined,
                        "search",
                        undefined
                      ]),
                  Curry.app(CmdK.Action.t, [
                        "metrics",
                        {
                          TAG: "Command",
                          _0: "Metrics..."
                        },
                        1.2,
                        undefined,
                        undefined,
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "search",
                                        size: "small"
                                      });
                          }),
                        undefined,
                        "search",
                        undefined
                      ]),
                  Curry.app(CmdK.Action.t, [
                        "integrations",
                        {
                          TAG: "Command",
                          _0: "Integrations..."
                        },
                        1.2,
                        undefined,
                        undefined,
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "search",
                                        size: "small"
                                      });
                          }),
                        undefined,
                        "search",
                        undefined
                      ])
                ]
            ]);
}

function getEventActions(model) {
  return Belt_List.toArray(Belt_List.map(model.events, (function ($$event) {
                    return Curry.app(CmdK.Action.t, [
                                $$event.id,
                                {
                                  TAG: "Event",
                                  _0: $$event.name,
                                  _1: $$event.description
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "event",
                                          VAL: [
                                            $$event.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function nameMappedActions(model) {
  return Belt_List.toArray(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant = match.NAME;
                    if (variant === "Property") {
                      var match$1 = rule.definition;
                      if (typeof match$1 !== "object") {
                        return ;
                      }
                      if (match$1.NAME !== "NameMapping") {
                        return ;
                      }
                      var name = match$1.VAL.name;
                      var propertyId = match.VAL;
                      return Belt_Option.map(ModelUtils.resolvePropertyById(propertyId, model), (function (resolvedProperty) {
                                    return Curry.app(CmdK.Action.t, [
                                                propertyId + "-namemapped",
                                                {
                                                  TAG: "NameMappedProperty",
                                                  _0: name,
                                                  _1: resolvedProperty.name,
                                                  _2: resolvedProperty.description
                                                },
                                                undefined,
                                                undefined,
                                                "mapping " + Belt_Option.mapWithDefault(name, "", (function (name) {
                                                        return name + " ";
                                                      })) + resolvedProperty.name,
                                                (function (param) {
                                                    return React.createElement(IconSendAs.make, {
                                                                size: 12,
                                                                color: Styles.Color.light08
                                                              });
                                                  }),
                                                (function (param) {
                                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                                          NAME: "property",
                                                          VAL: [
                                                            propertyId,
                                                            undefined
                                                          ]
                                                        });
                                                  }),
                                                "search",
                                                undefined
                                              ]);
                                  }));
                    }
                    if (variant !== "Event") {
                      return ;
                    }
                    var match$2 = rule.definition;
                    if (typeof match$2 !== "object") {
                      return ;
                    }
                    if (match$2.NAME !== "NameMapping") {
                      return ;
                    }
                    var name$1 = match$2.VAL.name;
                    var eventId = match.VAL;
                    return Belt_Option.map(ModelUtils.getEventById(eventId, model), (function (resolvedEvent) {
                                  return Curry.app(CmdK.Action.t, [
                                              eventId + "-namemapped",
                                              {
                                                TAG: "NameMappedEvent",
                                                _0: name$1,
                                                _1: resolvedEvent.name,
                                                _2: resolvedEvent.description
                                              },
                                              undefined,
                                              undefined,
                                              "mapping " + Belt_Option.mapWithDefault(name$1, "", (function (name) {
                                                      return name + " ";
                                                    })) + resolvedEvent.name,
                                              (function (param) {
                                                  return React.createElement(IconSendAs.make, {
                                                              size: 12,
                                                              color: Styles.Color.light08
                                                            });
                                                }),
                                              (function (param) {
                                                  Router.Schema.pushDrawerItem(undefined, undefined, {
                                                        NAME: "event",
                                                        VAL: [
                                                          eventId,
                                                          undefined,
                                                          undefined,
                                                          false
                                                        ]
                                                      });
                                                }),
                                              "search",
                                              undefined
                                            ]);
                                }));
                  })));
}

function getPropertyActions(model) {
  return Belt_List.toArray(Belt_List.keepMap(model.properties, (function (property) {
                    if (property.TAG === "PropertyRef") {
                      return ;
                    }
                    var property$1 = property._0;
                    var groupTypeId = property$1.sendAs;
                    if (typeof groupTypeId !== "object") {
                      return Caml_option.some(Curry.app(CmdK.Action.t, [
                                      property$1.id,
                                      {
                                        TAG: "Property",
                                        _0: property$1.name,
                                        _1: property$1.sendAs,
                                        _2: undefined,
                                        _3: property$1.description
                                      },
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (param) {
                                          Router.Schema.pushDrawerItem(undefined, undefined, {
                                                NAME: "property",
                                                VAL: [
                                                  property$1.id,
                                                  undefined
                                                ]
                                              });
                                        }),
                                      "search",
                                      undefined
                                    ]));
                    } else {
                      return Belt_Option.map(ModelUtils.getGroupType(groupTypeId._0, model), (function (param) {
                                    return Curry.app(CmdK.Action.t, [
                                                property$1.id,
                                                {
                                                  TAG: "Property",
                                                  _0: property$1.name,
                                                  _1: property$1.sendAs,
                                                  _2: param.name,
                                                  _3: property$1.description
                                                },
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (param) {
                                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                                          NAME: "property",
                                                          VAL: [
                                                            property$1.id,
                                                            undefined
                                                          ]
                                                        });
                                                  }),
                                                "search",
                                                undefined
                                              ]);
                                  }));
                    }
                  })));
}

function getPropertyGroupActions(model) {
  return Belt_List.toArray(Belt_List.map(model.propertyBundles, (function (propertyGroup) {
                    return Curry.app(CmdK.Action.t, [
                                propertyGroup.id,
                                {
                                  TAG: "PropertyGroup",
                                  _0: propertyGroup.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "propertyGroup",
                                          VAL: [
                                            propertyGroup.id,
                                            undefined
                                          ]
                                        });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getSourceActions(model) {
  return Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                    return Curry.app(CmdK.Action.t, [
                                source.id,
                                {
                                  TAG: "Source",
                                  _0: source.name
                                },
                                undefined,
                                undefined,
                                "source",
                                undefined,
                                (function (param) {
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "source",
                                          VAL: [
                                            source.id,
                                            "overview"
                                          ]
                                        });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getDestinationActions(model) {
  return Belt_List.toArray(Belt_List.map(model.destinations, (function (destination) {
                    return Curry.app(CmdK.Action.t, [
                                destination.id,
                                {
                                  TAG: "Destination",
                                  _0: destination.name
                                },
                                undefined,
                                undefined,
                                "destination",
                                undefined,
                                (function (param) {
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "destination",
                                          VAL: destination.id
                                        });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getGoalActions(model) {
  return Belt_List.toArray(Belt_List.map(model.goals, (function (goal) {
                    return Curry.app(CmdK.Action.t, [
                                goal.id,
                                {
                                  TAG: "Category",
                                  _0: goal.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "category",
                                          VAL: [
                                            goal.id,
                                            undefined
                                          ]
                                        });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getMetricActions(model) {
  return Belt_List.toArray(Belt_List.map(model.metrics, (function (metric) {
                    return Curry.app(CmdK.Action.t, [
                                metric.id,
                                {
                                  TAG: "Metric",
                                  _0: metric.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "metric",
                                          VAL: [
                                            metric.id,
                                            undefined,
                                            false
                                          ]
                                        });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getIntegrationActions(model) {
  return Belt_List.toArray(Belt_List.map(model.integrations, (function (integration) {
                    return Curry.app(CmdK.Action.t, [
                                integration.id,
                                {
                                  TAG: "Integration",
                                  _0: integration.name
                                },
                                undefined,
                                undefined,
                                "integration",
                                undefined,
                                (function (param) {
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "integration",
                                          VAL: [
                                            integration.id,
                                            undefined
                                          ]
                                        });
                                  }),
                                "search",
                                undefined
                              ]);
                  })));
}

function getActions$1(model, isGlobalWorkspace) {
  return Belt_Array.concatMany([
              getSections(isGlobalWorkspace),
              getEventActions(model),
              getPropertyActions(model),
              nameMappedActions(model),
              getPropertyGroupActions(model),
              isGlobalWorkspace ? [] : getSourceActions(model),
              isGlobalWorkspace ? [] : getDestinationActions(model),
              getGoalActions(model),
              isGlobalWorkspace ? [] : getMetricActions(model),
              isGlobalWorkspace ? [] : getIntegrationActions(model)
            ]);
}

var TrackingPlan = {
  getSections: getSections,
  getEventActions: getEventActions,
  nameMappedActions: nameMappedActions,
  getPropertyActions: getPropertyActions,
  getPropertyGroupActions: getPropertyGroupActions,
  getSourceActions: getSourceActions,
  getDestinationActions: getDestinationActions,
  getGoalActions: getGoalActions,
  getMetricActions: getMetricActions,
  getIntegrationActions: getIntegrationActions,
  getActions: getActions$1
};

function getSections$1(isGlobalWorkspace) {
  return Belt_Array.concatMany([
              [
                Curry.app(CmdK.Action.t, [
                      "unarchiveEvents",
                      {
                        TAG: "Command",
                        _0: "Events..."
                      },
                      1.2,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "folder",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      "unarchive",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "unarchiveProperties",
                      {
                        TAG: "Command",
                        _0: "Properties..."
                      },
                      1.2,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "folder",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      "unarchive",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "unarchivePropertyGroups",
                      {
                        TAG: "Command",
                        _0: "Property Bundles..."
                      },
                      1.2,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "folder",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      "unarchive",
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "unarchiveCategories",
                      {
                        TAG: "Command",
                        _0: "Categories..."
                      },
                      1.2,
                      undefined,
                      undefined,
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "folder",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      "unarchive",
                      undefined
                    ])
              ],
              isGlobalWorkspace ? [] : [
                  Curry.app(CmdK.Action.t, [
                        "unarchiveSources",
                        {
                          TAG: "Command",
                          _0: "Sources..."
                        },
                        1.2,
                        undefined,
                        undefined,
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "folder",
                                        size: "small"
                                      });
                          }),
                        undefined,
                        "unarchive",
                        undefined
                      ]),
                  Curry.app(CmdK.Action.t, [
                        "unarchiveDestinations",
                        {
                          TAG: "Command",
                          _0: "Destinations..."
                        },
                        1.2,
                        undefined,
                        undefined,
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "folder",
                                        size: "small"
                                      });
                          }),
                        undefined,
                        "unarchive",
                        undefined
                      ]),
                  Curry.app(CmdK.Action.t, [
                        "unarchiveMetrics",
                        {
                          TAG: "Command",
                          _0: "Metrics..."
                        },
                        1.2,
                        undefined,
                        undefined,
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "folder",
                                        size: "small"
                                      });
                          }),
                        undefined,
                        "unarchive",
                        undefined
                      ]),
                  Curry.app(CmdK.Action.t, [
                        "unarchiveGroupTypes",
                        {
                          TAG: "Command",
                          _0: "Group Types..."
                        },
                        1.2,
                        undefined,
                        undefined,
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "folder",
                                        size: "small"
                                      });
                          }),
                        undefined,
                        "unarchive",
                        undefined
                      ])
                ]
            ]);
}

function handleUnarchive(item, itemId, itemName, itemType, schemaBundle, sendActions, param) {
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (param) {
            AnalyticsRe.itemUnarchived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, itemId, itemName, itemType, "CommandPalette", schemaBundle.schemaId, schemaBundle.branchId);
            var variant = item.NAME;
            if (variant === "Destination") {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "destination",
                          VAL: item.VAL
                        });
            } else if (variant === "Integration") {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "integration",
                          VAL: [
                            item.VAL,
                            undefined
                          ]
                        });
            } else if (variant === "GroupType") {
              return Router.Schema.pushSchemaRoute(undefined, undefined, "properties");
            } else if (variant === "Property") {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "property",
                          VAL: [
                            item.VAL,
                            undefined
                          ]
                        });
            } else if (variant === "Event") {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "event",
                          VAL: [
                            item.VAL,
                            undefined,
                            undefined,
                            false
                          ]
                        });
            } else if (variant === "PropertyGroup") {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "propertyGroup",
                          VAL: [
                            item.VAL,
                            undefined
                          ]
                        });
            } else if (variant === "Goal") {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "category",
                          VAL: [
                            item.VAL,
                            undefined
                          ]
                        });
            } else if (variant === "EventVariant") {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "eventVariant",
                          VAL: [
                            item.VAL,
                            undefined
                          ]
                        });
            } else if (variant === "Metric") {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "metric",
                          VAL: [
                            item.VAL,
                            undefined,
                            false
                          ]
                        });
            } else {
              return Router.Schema.pushDrawerItem(undefined, undefined, {
                          NAME: "source",
                          VAL: [
                            item.VAL,
                            "overview"
                          ]
                        });
            }
          }),
        undefined,
        [[
            {
              NAME: "Unarchive",
              VAL: item
            },
            ActivityItem.getActionContext(item)
          ]]
      ]);
}

function getEventActions$1(model, sendActions, schemaBundle) {
  return Belt_List.toArray(Belt_List.map(model.archive.events, (function ($$event) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + $$event.id,
                                {
                                  TAG: "Event",
                                  _0: $$event.name,
                                  _1: $$event.description
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    handleUnarchive({
                                          NAME: "Event",
                                          VAL: $$event.id
                                        }, $$event.id, $$event.name, "Event", schemaBundle, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function nameMappedActions$1(model, sendActions, schemaBundle) {
  return Belt_List.toArray(Belt_List.keepMap(model.rules, (function (rule) {
                    var match = rule.item;
                    if (typeof match !== "object") {
                      return ;
                    }
                    var variant = match.NAME;
                    if (variant === "Property") {
                      var match$1 = rule.definition;
                      if (typeof match$1 !== "object") {
                        return ;
                      }
                      if (match$1.NAME !== "NameMapping") {
                        return ;
                      }
                      var name = match$1.VAL.name;
                      var propertyId = match.VAL;
                      return Belt_Option.map(ModelUtils.resolvePropertyByIdFromArchive(propertyId, model), (function (resolvedProperty) {
                                    return Curry.app(CmdK.Action.t, [
                                                "unarchive-" + propertyId + "-namemapped",
                                                {
                                                  TAG: "NameMappedProperty",
                                                  _0: name,
                                                  _1: resolvedProperty.name,
                                                  _2: resolvedProperty.description
                                                },
                                                undefined,
                                                undefined,
                                                "mapping " + Belt_Option.mapWithDefault(name, "", (function (name) {
                                                        return name + " ";
                                                      })) + resolvedProperty.name,
                                                (function (param) {
                                                    return React.createElement(IconSendAs.make, {
                                                                size: 12,
                                                                color: Styles.Color.light08
                                                              });
                                                  }),
                                                (function (param) {
                                                    handleUnarchive({
                                                          NAME: "Property",
                                                          VAL: propertyId
                                                        }, propertyId, resolvedProperty.name, "Property", schemaBundle, sendActions, undefined);
                                                  }),
                                                "unarchive",
                                                undefined
                                              ]);
                                  }));
                    }
                    if (variant !== "Event") {
                      return ;
                    }
                    var match$2 = rule.definition;
                    if (typeof match$2 !== "object") {
                      return ;
                    }
                    if (match$2.NAME !== "NameMapping") {
                      return ;
                    }
                    var name$1 = match$2.VAL.name;
                    var eventId = match.VAL;
                    return Belt_Option.map(ModelUtils.getEventByIdFromArchive(eventId, model), (function (resolvedEvent) {
                                  return Curry.app(CmdK.Action.t, [
                                              "unarchive-" + eventId + "-namemapped",
                                              {
                                                TAG: "NameMappedEvent",
                                                _0: name$1,
                                                _1: resolvedEvent.name,
                                                _2: resolvedEvent.description
                                              },
                                              undefined,
                                              undefined,
                                              "mapping " + Belt_Option.mapWithDefault(name$1, "", (function (name) {
                                                      return name + " ";
                                                    })) + resolvedEvent.name,
                                              (function (param) {
                                                  return React.createElement(IconSendAs.make, {
                                                              size: 12,
                                                              color: Styles.Color.light08
                                                            });
                                                }),
                                              (function (param) {
                                                  handleUnarchive({
                                                        NAME: "Event",
                                                        VAL: eventId
                                                      }, eventId, resolvedEvent.name, "Event", schemaBundle, sendActions, undefined);
                                                }),
                                              "unarchive",
                                              undefined
                                            ]);
                                }));
                  })));
}

function getPropertyActions$1(model, sendActions, schemaBundle) {
  return Belt_List.toArray(Belt_List.keepMap(model.archive.properties, (function (property) {
                    if (property.TAG === "PropertyRef") {
                      return ;
                    }
                    var property$1 = property._0;
                    var groupTypeId = property$1.sendAs;
                    if (typeof groupTypeId !== "object") {
                      return Caml_option.some(Curry.app(CmdK.Action.t, [
                                      "unarchive-" + property$1.id,
                                      {
                                        TAG: "Property",
                                        _0: property$1.name,
                                        _1: property$1.sendAs,
                                        _2: undefined,
                                        _3: property$1.description
                                      },
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (param) {
                                          handleUnarchive({
                                                NAME: "Property",
                                                VAL: property$1.id
                                              }, property$1.id, property$1.name, "Property", schemaBundle, sendActions, undefined);
                                        }),
                                      "unarchive",
                                      undefined
                                    ]));
                    } else {
                      return Belt_Option.map(ModelUtils.getGroupTypeWithArchive(groupTypeId._0, model), (function (param) {
                                    return Curry.app(CmdK.Action.t, [
                                                "unarchive-" + property$1.id,
                                                {
                                                  TAG: "Property",
                                                  _0: property$1.name,
                                                  _1: property$1.sendAs,
                                                  _2: param.name,
                                                  _3: property$1.description
                                                },
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (param) {
                                                    handleUnarchive({
                                                          NAME: "GroupType",
                                                          VAL: property$1.id
                                                        }, property$1.id, property$1.name, "GroupType", schemaBundle, sendActions, undefined);
                                                  }),
                                                "unarchive",
                                                undefined
                                              ]);
                                  }));
                    }
                  })));
}

function getPropertyGroupActions$1(model, sendActions, schemaBundle) {
  return Belt_List.toArray(Belt_List.map(model.archive.propertyBundles, (function (propertyGroup) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + propertyGroup.id,
                                {
                                  TAG: "PropertyGroup",
                                  _0: propertyGroup.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    handleUnarchive({
                                          NAME: "PropertyGroup",
                                          VAL: propertyGroup.id
                                        }, propertyGroup.id, propertyGroup.name, "PropertyGroup", schemaBundle, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getSourceActions$1(model, sendActions, schemaBundle) {
  return Belt_List.toArray(Belt_List.map(model.archive.sources, (function (source) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + source.id,
                                {
                                  TAG: "Source",
                                  _0: source.name
                                },
                                undefined,
                                undefined,
                                "source",
                                undefined,
                                (function (param) {
                                    handleUnarchive({
                                          NAME: "Source",
                                          VAL: source.id
                                        }, source.id, AvoConfig.getSourceName(source), "Source", schemaBundle, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getDestinationActions$1(model, sendActions, schemaBundle) {
  return Belt_List.toArray(Belt_List.map(model.archive.destinations, (function (destination) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + destination.id,
                                {
                                  TAG: "Destination",
                                  _0: destination.name
                                },
                                undefined,
                                undefined,
                                "destination",
                                undefined,
                                (function (param) {
                                    handleUnarchive({
                                          NAME: "Destination",
                                          VAL: destination.id
                                        }, destination.id, Belt_Option.getWithDefault(destination.name, "Untitled Destination"), "Destination", schemaBundle, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getGoalActions$1(model, sendActions, schemaBundle) {
  return Belt_List.toArray(Belt_List.map(model.archive.goals, (function (goal) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + goal.id,
                                {
                                  TAG: "Category",
                                  _0: goal.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    handleUnarchive({
                                          NAME: "Goal",
                                          VAL: goal.id
                                        }, goal.id, goal.name, "Goal", schemaBundle, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getMetricActions$1(model, sendActions, schemaBundle) {
  return Belt_List.toArray(Belt_List.map(model.archive.metrics, (function (metric) {
                    return Curry.app(CmdK.Action.t, [
                                "unarchive-" + metric.id,
                                {
                                  TAG: "Metric",
                                  _0: metric.name === "" ? "Untitled Metric" : metric.name
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (param) {
                                    handleUnarchive({
                                          NAME: "Metric",
                                          VAL: metric.id
                                        }, metric.id, metric.name, "Metric", schemaBundle, sendActions, undefined);
                                  }),
                                "unarchive",
                                undefined
                              ]);
                  })));
}

function getGroupTypeActions(model, sendActions, schemaBundle) {
  return Belt_Array.map(model.archive.groupTypes, (function (groupType) {
                return Curry.app(CmdK.Action.t, [
                            "unarchive-" + groupType.id,
                            {
                              TAG: "GroupType",
                              _0: groupType.name === "" ? "Untitled Group Type" : groupType.name
                            },
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (param) {
                                handleUnarchive({
                                      NAME: "GroupType",
                                      VAL: groupType.id
                                    }, groupType.id, groupType.name, "GroupType", schemaBundle, sendActions, undefined);
                              }),
                            "unarchive",
                            undefined
                          ]);
              }));
}

function getActions$2(model, sendActions, schemaBundle, isGlobalWorkspace) {
  return Belt_Array.concatMany([
              getSections$1(isGlobalWorkspace),
              getEventActions$1(model, sendActions, schemaBundle),
              getPropertyActions$1(model, sendActions, schemaBundle),
              nameMappedActions$1(model, sendActions, schemaBundle),
              getPropertyGroupActions$1(model, sendActions, schemaBundle),
              isGlobalWorkspace ? [] : getSourceActions$1(model, sendActions, schemaBundle),
              isGlobalWorkspace ? [] : getDestinationActions$1(model, sendActions, schemaBundle),
              getGoalActions$1(model, sendActions, schemaBundle),
              isGlobalWorkspace ? [] : getMetricActions$1(model, sendActions, schemaBundle),
              getGroupTypeActions(model, sendActions, schemaBundle)
            ]);
}

var TrackingPlanArchive = {
  getSections: getSections$1,
  handleUnarchive: handleUnarchive,
  getEventActions: getEventActions$1,
  nameMappedActions: nameMappedActions$1,
  getPropertyActions: getPropertyActions$1,
  getPropertyGroupActions: getPropertyGroupActions$1,
  getSourceActions: getSourceActions$1,
  getDestinationActions: getDestinationActions$1,
  getGoalActions: getGoalActions$1,
  getMetricActions: getMetricActions$1,
  getGroupTypeActions: getGroupTypeActions,
  getActions: getActions$2
};

function getOpenBranches(openBranches) {
  return Belt_List.toArray({
              hd: [
                "master",
                "main"
              ],
              tl: Belt_List.reverse(openBranches)
            });
}

function getActions$3(openBranches) {
  return Belt_Array.map(getOpenBranches(openBranches), (function (param) {
                var id = param[0];
                return Curry.app(CmdK.Action.t, [
                            id,
                            {
                              TAG: "Branch",
                              _0: param[1]
                            },
                            undefined,
                            undefined,
                            undefined,
                            (function (param) {
                                return React.createElement(Icon.make, {
                                            type_: "branch",
                                            size: "small",
                                            color: Styles.Color.light08
                                          });
                              }),
                            (function (param) {
                                var match = Router.getStaticRoute();
                                Router.push(undefined, Curry._4(Router.Link.get, match.baseRoute, id === "master" ? "master" : ({
                                              NAME: "branch",
                                              VAL: id
                                            }), match.schemaRoute, /* [] */0));
                              }),
                            "branch",
                            undefined
                          ]);
              }));
}

var Branch = {
  getOpenBranches: getOpenBranches,
  getActions: getActions$3
};

function getActions$4(globalSend, schemaBundle, role) {
  return Belt_Array.concatMany([
              [
                Curry.app(CmdK.Action.t, [
                      "search",
                      {
                        TAG: "Command",
                        _0: "Search..."
                      },
                      2.0,
                      ["/"],
                      "s",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "search",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      undefined,
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "create",
                      {
                        TAG: "Command",
                        _0: "Create..."
                      },
                      2.0,
                      ["c"],
                      "c",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "plus",
                                      size: "medium"
                                    });
                        }),
                      undefined,
                      undefined,
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "branch",
                      {
                        TAG: "Command",
                        _0: "Switch Branch..."
                      },
                      2.0,
                      ["b"],
                      "b",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "branch",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      undefined,
                      undefined
                    ]),
                Curry.app(CmdK.Action.t, [
                      "unarchive",
                      {
                        TAG: "Command",
                        _0: "Unarchive..."
                      },
                      2.0,
                      ["u"],
                      "u",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "folder",
                                      size: "small"
                                    });
                        }),
                      undefined,
                      undefined,
                      undefined
                    ])
              ],
              RoleUtils.canEdit(role) ? [Curry.app(CmdK.Action.t, [
                        "import",
                        {
                          TAG: "Command",
                          _0: "Import..."
                        },
                        2.0,
                        undefined,
                        "i",
                        (function (param) {
                            return React.createElement(CmdKActions$CmdKIcon, {
                                        type_: "import",
                                        size: "small"
                                      });
                          }),
                        (function (param) {
                            Router.push(undefined, Router.Schema.getImportRoute(undefined, "CmdPalette", {
                                      NAME: "import",
                                      VAL: "index"
                                    }));
                          }),
                        undefined,
                        undefined
                      ])] : [],
              [Curry.app(CmdK.Action.t, [
                      "settings",
                      {
                        TAG: "Command",
                        _0: "Workspace Settings..."
                      },
                      2.0,
                      undefined,
                      "w",
                      (function (param) {
                          return React.createElement(CmdKActions$CmdKIcon, {
                                      type_: "settings",
                                      size: "small"
                                    });
                        }),
                      (function (param) {
                          AnalyticsRe.membersClicked(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.branchId, schemaBundle.schemaId);
                          Curry._1(globalSend, {
                                TAG: "OpenModal",
                                _0: {
                                  NAME: "Settings",
                                  VAL: undefined
                                }
                              });
                        }),
                      undefined,
                      undefined
                    ])]
            ]);
}

var Root = {
  getActions: getActions$4
};

function getActions$5(isGlobalWorkspace, globalSend, model, openBranches, schemaBundle, sendActions, role) {
  return Belt_Array.concatMany([
              getActions$4(globalSend, schemaBundle, role),
              getActions(globalSend, schemaBundle, model.groupTypes),
              getActions$3(openBranches),
              getActions$1(model, isGlobalWorkspace),
              getActions$2(model, sendActions, schemaBundle, isGlobalWorkspace)
            ]);
}

export {
  CmdKIcon ,
  Create ,
  TrackingPlan ,
  TrackingPlanArchive ,
  Branch ,
  Root ,
  getActions$5 as getActions,
}
/* wrapper Not a pure module */
