// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import Prismjs from "prismjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var _map = {"javascript":"javascript","typescript":"typescript","rescript":"rescript","java":"java","swift":"swift","json":"json","python":"python","php":"php","kotlin":"kotlin","csharp":"csharp","objectivec":"objectivec","ruby":"ruby","dart":"dart","go":"go","html":"html","bash":"bash","diff":"diff","diff-javascript":"diff-javascript","diff-typescript":"diff-typescript","diff-rescript":"diff-rescript","diff-java":"diff-java","diff-swift":"diff-swift","diff-json":"diff-json","diff-python":"diff-python","diff-php":"diff-php","diff-kotlin":"diff-kotlin","diff-csharp":"diff-csharp","diff-objectivec":"diff-objectivec","diff-ruby":"diff-ruby","diff-dart":"diff-dart","diff-go":"diff-go","diff-html":"diff-html"};

function languageToJs(param) {
  return param;
}

function languageFromJs(param) {
  return _map[param];
}

import '../assets/prism.css'
;

import 'prismjs/plugins/diff-highlight/prism-diff-highlight.min.css'
;

import 'prismjs/components/prism-rescript.min'
;

import 'prismjs/components/prism-typescript.min'
;

import 'prismjs/components/prism-dart.min'
;

import 'prismjs/components/prism-csharp.min'
;

import 'prismjs/components/prism-java.min'
;

import 'prismjs/components/prism-json.min'
;

import 'prismjs/components/prism-kotlin.min'
;

import 'prismjs/components/prism-python.min'
;

import 'prismjs/components/prism-go.min'
;

import 'prismjs/components/prism-ruby.min'
;

import 'prismjs/components/prism-swift.min'
;

import 'prismjs/components/prism-markup.min'
;

import 'prismjs/components/prism-diff.min'
;

import 'prismjs/components/prism-bash.min'
;

import 'prismjs/plugins/diff-highlight/prism-diff-highlight'
;

var prism = Prismjs;

import 'prismjs/plugins/autoloader/prism-autoloader.min'
;

function getGrammar(prism, language) {
  if (language === "bash") {
    return prism.languages.bash;
  } else if (language === "dart") {
    return prism.languages.dart;
  } else if (language === "diff-typescript" || language === "diff-go" || language === "diff-javascript" || language === "diff-python" || language === "diff-objectivec" || language === "diff-php" || language === "diff-swift" || language === "diff-csharp" || language === "diff-ruby" || language === "diff-rescript" || language === "diff-json" || language === "diff-java" || language === "diff-html" || language === "diff-dart" || language === "diff-kotlin" || language === "diff") {
    return prism.languages.diff;
  } else if (language === "html") {
    return prism.languages.markup;
  } else if (language === "java") {
    return prism.languages.java;
  } else if (language === "json") {
    return prism.languages.json;
  } else if (language === "ruby") {
    return prism.languages.ruby;
  } else if (language === "typescript" || language === "php" || language === "javascript" || language === "objectivec") {
    return prism.languages.javascript;
  } else if (language === "kotlin") {
    return prism.languages.kotlin;
  } else if (language === "go") {
    return prism.languages.go;
  } else if (language === "swift") {
    return prism.languages.swift;
  } else if (language === "csharp") {
    return prism.languages.csharp;
  } else if (language === "rescript") {
    return prism.languages.rescript;
  } else {
    return prism.languages.python;
  }
}

function getDiffLanguage(language) {
  if (language === "rescript" || language === "php" || language === "kotlin" || language === "python") {
    return "diff-rescript";
  } else if (language === "dart") {
    return "diff-dart";
  } else if (language === "html") {
    return "diff-html";
  } else if (language === "java") {
    return "diff-java";
  } else if (language === "json") {
    return "diff-json";
  } else if (language === "csharp" || language === "swift" || language === "javascript" || language === "ruby") {
    return "diff-csharp";
  } else if (language === "typescript" || language === "objectivec") {
    return "diff-javascript";
  } else if (language === "go") {
    return "diff-go";
  } else {
    return "diff";
  }
}

function getLanguageString(language) {
  return "language-" + language;
}

var partial_arg = /(\r?\n)?/;

function trimFirstNewLine(param) {
  return Js_string.replaceByRe(partial_arg, "", param);
}

function Prism(Props) {
  var snippet = Props.snippet;
  var withDiffOpt = Props.withDiff;
  var language = Props.language;
  var className = Props.className;
  var withDiff = withDiffOpt !== undefined ? withDiffOpt : false;
  var code = trimFirstNewLine(prism.highlight(snippet, getGrammar(prism, language), language));
  return React.createElement("pre", {
              className: getLanguageString(language) + Belt_Option.mapWithDefault(className, "", (function (cn) {
                      return " " + cn;
                    }))
            }, React.createElement("code", {
                  className: getLanguageString(language) + (
                    withDiff ? " diff-highlight" : ""
                  ),
                  dangerouslySetInnerHTML: {
                    __html: code
                  }
                }));
}

var make = Prism;

export {
  languageToJs ,
  languageFromJs ,
  prism ,
  getGrammar ,
  getDiffLanguage ,
  getLanguageString ,
  trimFirstNewLine ,
  make ,
}
/*  Not a pure module */
