// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as CreateSystemEventUseCase from "../base/CreateSystemEventUseCase.mjs";

function get(propertyValues, $$event) {
  var eventSourceIdsSet = Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray($$event.includeSources), (function (param) {
              return param.id;
            })));
  return Belt_Array.keepMap(Belt_Array.keepMap(propertyValues, (function (param) {
                    var literal = param[0];
                    if (typeof literal === "object" && literal.NAME === "StringLit") {
                      return [
                              literal.VAL,
                              param[1]
                            ];
                    }
                    
                  })), (function (param) {
                var literal = param[0];
                var match = Belt_MapString.get(param[1], $$event.id);
                if (match === undefined) {
                  return [
                          literal,
                          undefined
                        ];
                }
                if (typeof match !== "object") {
                  return ;
                }
                var disallowedSourceSet = match._0;
                if (Belt_SetString.isEmpty(disallowedSourceSet) || !Belt_SetString.eq(Belt_SetString.intersect(disallowedSourceSet, eventSourceIdsSet), eventSourceIdsSet)) {
                  return [
                          literal,
                          disallowedSourceSet
                        ];
                }
                
              }));
}

function getAllowedValuesOnSource(propertyValues, $$event, sourceId) {
  return Belt_Array.keepMap(get(propertyValues, $$event), (function (param) {
                if (Belt_SetString.has(param[1], sourceId)) {
                  return ;
                } else {
                  return param[0];
                }
              }));
}

function getWithLegacyFallback(hasEventSpecificAllowedPropertyValuesMigration, property, $$event) {
  if (hasEventSpecificAllowedPropertyValuesMigration) {
    return get(property.eventSpecificAllowedPropertyValues, $$event);
  } else {
    return Belt_List.toArray(Belt_List.keepMap(ModelUtils.getMatchesFromPropertyLegacy(property), (function (param) {
                      var match = param[0].VAL;
                      if (typeof match === "object" && match.NAME === "StringLit") {
                        return [
                                match.VAL,
                                Belt_SetString.fromArray(Belt_List.toArray(param[1]))
                              ];
                      }
                      
                    })));
  }
}

function getAllowedValuesOnSourceForSystemProperty(propertyValues, sourceId, modelSources) {
  return getAllowedValuesOnSource(propertyValues, CreateSystemEventUseCase.create(modelSources), sourceId);
}

export {
  get ,
  getAllowedValuesOnSource ,
  getWithLegacyFallback ,
  getAllowedValuesOnSourceForSystemProperty ,
}
/* ModelUtils Not a pure module */
