// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as NamespaceIndex from "../../shared/models/NamespaceIndex.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";

function getEventNamespace(model) {
  return Belt_Array.map(GetEventNamespaceUseCase.getEventNamespaceWithNameMappingAndVariants(true, false, model), (function (param) {
                var eventId = param.eventId;
                return [
                        eventId,
                        {
                          name: param.name,
                          itemId: eventId,
                          originalName: param.originalName
                        }
                      ];
              }));
}

function getPropertyNamespace(model) {
  return Belt_Array.map(ModelUtils_mapped.getPropertyNamespaceWithIds(false, model), (function (param) {
                var propertyId = param.propertyId;
                return [
                        propertyId,
                        {
                          name: param.name,
                          itemId: propertyId,
                          originalName: param.originalName,
                          itemSubType: Belt_Option.map(param.sendAs, NamespaceIndex.ItemSubType.propertySendAsToItemSubType)
                        }
                      ];
              }));
}

function prepareNamespaceIndexForBranch(mainModel, mainNamespace, branchModel, itemType) {
  var match;
  match = itemType === "Events" ? [
      Belt_Option.getWithDefault(mainNamespace, getEventNamespace(mainModel)),
      getEventNamespace(branchModel)
    ] : [
      Belt_Option.getWithDefault(mainNamespace, getPropertyNamespace(mainModel)),
      getPropertyNamespace(branchModel)
    ];
  var branchNamespace = match[1];
  var tmp;
  tmp = itemType === "Events" ? Curry._1(TrackingPlanMappedModel.Events.keys, mainModel.archive.events) : Curry._1(TrackingPlanMappedModel.Properties.keys, mainModel.archive.properties);
  var mainArchivedIds = Belt_SetString.fromArray(tmp);
  var mainNameSpaceWithArchive = Belt_SetString.union(Belt_SetString.fromArray(Belt_MapString.keysToArray(Belt_MapString.fromArray(match[0]))), mainArchivedIds);
  var nextNamespaceItemIds = Belt_SetString.diff(Belt_SetString.fromArray(Belt_MapString.keysToArray(Belt_MapString.fromArray(branchNamespace))), mainNameSpaceWithArchive);
  return Belt_Array.keepMap(branchNamespace, (function (param) {
                if (Belt_SetString.has(nextNamespaceItemIds, param[0])) {
                  return param[1];
                }
                
              }));
}

function updateNamespaceIndex(branchId, itemType, maybeNameSpaceIndexDoc, mainModel, branchModel, removeBranchFromIndex) {
  if (removeBranchFromIndex) {
    return NamespaceIndex.encode(Belt_MapString.remove(Belt_Option.mapWithDefault(maybeNameSpaceIndexDoc, NamespaceIndex.empty, NamespaceIndex.decode), branchId));
  }
  var nextNamespaceIndex = prepareNamespaceIndexForBranch(mainModel, undefined, branchModel, itemType);
  return NamespaceIndex.encode(Belt_MapString.set(Belt_Option.mapWithDefault(maybeNameSpaceIndexDoc, NamespaceIndex.empty, NamespaceIndex.decode), branchId, nextNamespaceIndex));
}

function getNameSpaceIndexToUpdate(action) {
  if (typeof action !== "object") {
    if (action === "ResetTrackingPlan") {
      return NamespaceIndex.ItemType.all;
    } else {
      return [];
    }
  }
  var variant = action.NAME;
  if (variant === "CreateProperty" || variant === "AddSystemProperty" || variant === "UpdatePropertyName" || variant === "RemovePropertyPermanently" || variant === "UpdatePropertyUniqueName" || variant === "AddProperty") {
    return ["Properties"];
  }
  if (variant === "CompletedImport" || variant === "CloseBranch" || variant === "MergeBranch") {
    return NamespaceIndex.ItemType.all;
  }
  if (variant === "UpdateEventName" || variant === "UpdateEventUniqueName" || variant === "DuplicateEvent" || variant === "UpdateEventUniqueNameV2" || variant === "RemoveEvent" || variant === "AddEvent") {
    return ["Events"];
  }
  if (variant === "AddRule") {
    var match = action.VAL;
    var match$1 = match[1];
    if (typeof match$1 !== "object") {
      return [];
    }
    var variant$1 = match$1.NAME;
    if (variant$1 === "Property" || variant$1 === "PropertyRef") {
      var match$2 = match[2];
      if (typeof match$2 === "object") {
        if (match$2.NAME === "NameMapping") {
          return ["Properties"];
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
    if (variant$1 !== "Event") {
      return [];
    }
    var match$3 = match[2];
    if (typeof match$3 === "object") {
      if (match$3.NAME === "NameMapping") {
        return ["Events"];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  if (variant === "UpdateRule") {
    var match$4 = action.VAL;
    var match$5 = match$4[1];
    if (typeof match$5 !== "object") {
      return [];
    }
    var variant$2 = match$5.NAME;
    if (variant$2 === "Property" || variant$2 === "PropertyRef") {
      var match$6 = match$4[2];
      if (typeof match$6 === "object") {
        if (match$6.NAME === "NameMapping") {
          return ["Properties"];
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
    if (variant$2 !== "Event") {
      return [];
    }
    var match$7 = match$4[2];
    if (typeof match$7 === "object") {
      if (match$7.NAME === "NameMapping") {
        return ["Events"];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  if (variant === "CopyChangesToBranch") {
    return Belt_Array.concatMany(Belt_Array.map(Belt_List.toArray(action.VAL[4]), getNameSpaceIndexToUpdate));
  }
  if (variant === "PullMaster") {
    return Belt_Array.concatMany(Belt_Array.map(Belt_List.toArray(action.VAL[3]), getNameSpaceIndexToUpdate));
  }
  if (variant !== "RemoveRule") {
    return [];
  }
  var match$8 = action.VAL[1];
  if (typeof match$8 !== "object") {
    return [];
  }
  var variant$3 = match$8.NAME;
  if (variant$3 === "Property" || variant$3 === "PropertyRef") {
    return ["Properties"];
  } else if (variant$3 === "Event") {
    return ["Events"];
  } else {
    return [];
  }
}

function getNamespaceIndexesToUpdate(action) {
  return Belt_Set.toArray(Belt_Set.fromArray(Belt_Array.concatMany(Belt_Array.map(action, (function (param) {
                            return getNameSpaceIndexToUpdate(param[0]);
                          }))), NamespaceIndex.ItemType.Comparator));
}

function getBranchMergeOrCloseActions(actions) {
  return Belt_Array.keep(actions, (function (param) {
                var action = param[0];
                if (typeof action !== "object") {
                  return false;
                }
                var variant = action.NAME;
                if (variant === "CloseBranch") {
                  return true;
                } else {
                  return variant === "MergeBranch";
                }
              }));
}

function wantsToUpdateNamespaceIndex(branchId, currentBranchId, actions) {
  var hasBranchMergeOrCloseActions = getBranchMergeOrCloseActions(actions).length !== 0;
  var isMainBranch = branchId === "master";
  var isWritingToCurrentBranch = isMainBranch || Belt_Option.mapWithDefault(currentBranchId, true, (function (currentBranchId) {
          return currentBranchId === branchId;
        }));
  if ((hasBranchMergeOrCloseActions || !isMainBranch) && isWritingToCurrentBranch) {
    return Belt_Array.some(actions, (function (param) {
                  return getNameSpaceIndexToUpdate(param[0]).length !== 0;
                }));
  } else {
    return false;
  }
}

function wantsToUpdateNamespaceIndex$1(branchId, currentBranchId, actions) {
  return wantsToUpdateNamespaceIndex(branchId, currentBranchId, actions);
}

var Web = {
  wantsToUpdateNamespaceIndex: wantsToUpdateNamespaceIndex$1
};

function wantsToUpdateNamespaceIndex$2(branchId, actions) {
  return wantsToUpdateNamespaceIndex(branchId, undefined, actions);
}

var CF = {
  wantsToUpdateNamespaceIndex: wantsToUpdateNamespaceIndex$2
};

export {
  getEventNamespace ,
  getPropertyNamespace ,
  prepareNamespaceIndexForBranch ,
  updateNamespaceIndex ,
  getNameSpaceIndexToUpdate ,
  getNamespaceIndexesToUpdate ,
  getBranchMergeOrCloseActions ,
  Web ,
  CF ,
}
/* NamespaceIndex Not a pure module */
