// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FeatureFlag from "../../shared/FeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as CliFeatureFlag from "../../shared/CliFeatureFlag.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";

function getSourceFilters(filters) {
  return Belt_List.keepMapU(filters, (function (item) {
                var variant = item.NAME;
                if (variant === "Tag" || variant === "Destination") {
                  return ;
                } else {
                  return item.VAL;
                }
              }));
}

function getTagFilters(filters) {
  return Belt_List.keepMapU(filters, (function (item) {
                if (item.NAME === "Tag") {
                  return item.VAL;
                }
                
              }));
}

function getDestinationFilters(filters) {
  return Belt_List.keepMapU(filters, (function (item) {
                if (item.NAME === "Destination") {
                  return item.VAL;
                }
                
              }));
}

function filterExcludedEvents(events, filters) {
  if (Belt_List.length(filters) <= 0) {
    return events;
  }
  var sourceFilters = getSourceFilters(filters);
  var tagFilters = getTagFilters(filters);
  var destinationFilters = getDestinationFilters(filters);
  var hasSourceFilters = Belt_List.length(sourceFilters) > 0;
  var hasTagFilters = Belt_List.length(tagFilters) > 0;
  var hasDestinationFilters = Belt_List.length(destinationFilters) > 0;
  var filterBySources = function ($$event) {
    if (hasSourceFilters) {
      return Belt_List.hasU(sourceFilters, $$event, AvoConfig.isEventIncludedInSource);
    } else {
      return true;
    }
  };
  var filterByTags = function ($$event) {
    if (hasTagFilters) {
      return Belt_List.hasU(tagFilters, $$event, (function (tag, $$event) {
                    return Belt_List.hasU($$event.tags, tag, (function (tag, eventTag) {
                                  return tag === eventTag;
                                }));
                  }));
    } else {
      return true;
    }
  };
  return Belt_List.keepU(events, (function ($$event) {
                if (filterBySources($$event) && filterByTags($$event)) {
                  if (hasDestinationFilters) {
                    return Belt_List.someU(destinationFilters, (function (destinationId) {
                                  return Belt_List.some($$event.includeDestinations, (function (x) {
                                                var match = x.VAL;
                                                var sourceId = match[0];
                                                if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                                                          return includedSource.id === sourceId;
                                                        }))) {
                                                  return Belt_List.someU(match[1], (function (id) {
                                                                return id === destinationId;
                                                              }));
                                                } else {
                                                  return false;
                                                }
                                              }));
                                }));
                  } else {
                    return true;
                  }
                } else {
                  return false;
                }
              }));
}

function filterUnusedPropertyBundles(model) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: Belt_List.keepU(model.propertyBundles, (function (bundle) {
                  if (Belt_List.length(bundle.properties) > 0) {
                    return Belt_List.someU(model.events, (function ($$event) {
                                  return Belt_List.someU($$event.propertyBundles, (function (propertyBundleRef) {
                                                return propertyBundleRef.id === bundle.id;
                                              }));
                                }));
                  } else {
                    return false;
                  }
                })),
          events: Belt_List.mapU(model.events, (function ($$event) {
                  return {
                          id: $$event.id,
                          name: $$event.name,
                          uniqueName: $$event.uniqueName,
                          description: $$event.description,
                          directPropertyRefs: $$event.directPropertyRefs,
                          propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (bundleRef) {
                                  var propertyGroup = ModelUtils.getPropertyBundleById(bundleRef.id, model);
                                  if (propertyGroup !== undefined) {
                                    return Belt_List.length(propertyGroup.properties) > 0;
                                  } else {
                                    return false;
                                  }
                                })),
                          variants: $$event.variants,
                          types: $$event.types,
                          tags: $$event.tags,
                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                          includeSources: $$event.includeSources,
                          includeDestinations: $$event.includeDestinations,
                          hashes: $$event.hashes,
                          propertyWhitelist: $$event.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                          triggers: $$event.triggers,
                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                        };
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function filterUnusedPropertyMatches(model) {
  return {
          types: model.types,
          properties: Belt_List.mapU(model.properties, (function (property) {
                  if (property.TAG === "PropertyRef") {
                    return property;
                  }
                  var property$1 = property._0;
                  var newrecord = Caml_obj.obj_dup(property$1);
                  return {
                          TAG: "Property",
                          _0: (newrecord.validations = Belt_List.keepU(property$1.validations, (function (validation) {
                                    if (typeof validation !== "object") {
                                      return true;
                                    }
                                    if (validation.NAME !== "Matches") {
                                      return true;
                                    }
                                    var isPropertyNested = Belt_List.length(ModelUtils.getPropertyAllLevelParents(property$1.id, model)) > 0;
                                    if (isPropertyNested) {
                                      return true;
                                    } else {
                                      return !Belt_List.everyU(ModelUtils.eventsSendingProperty(model, undefined, property$1.id), (function ($$event) {
                                                    return Belt_List.someU($$event.directPropertyRefs, (function (eventProperty) {
                                                                  if (eventProperty.TAG !== "PropertyRef") {
                                                                    return false;
                                                                  }
                                                                  var match = eventProperty._0;
                                                                  if (match.id === property$1.id) {
                                                                    return Belt_Option.mapWithDefault(match.pinnedValue, false, (function (pinnedValue) {
                                                                                  return ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, property$1, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"));
                                                                                }));
                                                                  } else {
                                                                    return false;
                                                                  }
                                                                }));
                                                  }));
                                    }
                                  })), newrecord)
                        };
                })),
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function splitProperties(propertyList) {
  return {
          props: Belt_List.keepMapU(propertyList, (function (prop) {
                  if (prop.TAG === "PropertyRef") {
                    return ;
                  } else {
                    return prop._0;
                  }
                })),
          propRefs: Belt_List.keepMapU(propertyList, (function (prop) {
                  if (prop.TAG === "PropertyRef") {
                    return prop._0;
                  }
                  
                }))
        };
}

function resolveGroupType(groupTypeId, globalGroupTypes) {
  return Belt_Array.getBy(globalGroupTypes, (function (param) {
                return param.id === groupTypeId;
              }));
}

function resolveGroupTypes(groupTypeIds, globalGroupTypes) {
  return Belt_Array.keepMap(groupTypeIds, (function (groupTypeId) {
                return Belt_Array.getBy(globalGroupTypes, (function (param) {
                              return groupTypeId === param.id;
                            }));
              }));
}

function resolveProperty(model, property) {
  var globalProperties = model.properties;
  var resolvedProperty;
  if (property.TAG === "PropertyRef") {
    var propRef = property._0;
    resolvedProperty = Belt_Option.mapU(Belt_List.getByU(globalProperties, (function (prop) {
                if (prop.TAG === "PropertyRef") {
                  return false;
                } else {
                  return prop._0.id === propRef.id;
                }
              })), (function (prop) {
            if (prop.TAG === "PropertyRef") {
              return Pervasives.failwith("unable to find ref in global properties");
            } else {
              return prop._0;
            }
          }));
  } else {
    resolvedProperty = property._0;
  }
  return Belt_Option.flatMap(resolvedProperty, (function (property) {
                var groupTypeId = property.sendAs;
                if (typeof groupTypeId !== "object") {
                  return property;
                } else {
                  return Belt_Option.map(resolveGroupType(groupTypeId._0, model.groupTypes), (function (param) {
                                return property;
                              }));
                }
              }));
}

function resolveProperties(model, propertyList) {
  return Belt_List.keepMap(propertyList, (function (param) {
                return resolveProperty(model, param);
              }));
}

function getOptionalAndRequiredEventPropertiesFromEvent(model, $$event, sourceId) {
  var match = Belt_Array.partitionU(Belt_Array.keepU(Belt_Array.keepU(Belt_List.toArray(Belt_List.keepMap($$event.directPropertyRefs, (function (param) {
                          return resolveProperty(model, param);
                        }))), (function (property) {
                  var match = property.sendAs;
                  if (typeof match === "object") {
                    return false;
                  }
                  switch (match) {
                    case "SystemProperty" :
                    case "EventProperty" :
                        return true;
                    case "UserProperty" :
                        return false;
                    
                  }
                })), (function (property) {
              return ModelUtils.isPropertyIncludedOnEventAndSource(undefined, property, $$event.id, sourceId, model)();
            })), (function (property) {
          return ModelUtils.isPropertyOptionalOnEventAndSource(property, $$event.id, sourceId, model);
        }));
  return [
          match[0],
          match[1]
        ];
}

function getPropertyById(model, propertyId) {
  return Belt_List.head(Belt_List.keepMapU(model.properties, (function (property) {
                    if (property.TAG === "PropertyRef") {
                      return ;
                    }
                    var property$1 = property._0;
                    if (property$1.id === propertyId) {
                      return property$1;
                    }
                    
                  })));
}

function resolvePropertyIds(globalProperties, propertyIds) {
  return Belt_List.keepMap(propertyIds, (function (propertyId) {
                return Belt_Option.mapU(Belt_List.getByU(globalProperties, (function (prop) {
                                  if (prop.TAG === "PropertyRef") {
                                    return false;
                                  } else {
                                    return prop._0.id === propertyId;
                                  }
                                })), (function (prop) {
                              if (prop.TAG === "PropertyRef") {
                                return Pervasives.failwith("unable to find ref in global properties");
                              } else {
                                return prop._0;
                              }
                            }));
              }));
}

function resolvePropertyBundleRef(globalPropertyBundles, propertyBundleRef) {
  return Belt_List.getByU(globalPropertyBundles, (function (group) {
                return group.id === propertyBundleRef.id;
              }));
}

function resolvePropertyBundleRef_mappedModel(globalPropertyBundles, propertyBundleRefId) {
  return Curry._2(TrackingPlanMappedModel.PropertyBundles.get, globalPropertyBundles, propertyBundleRefId);
}

function resolvePropertyBundleRefs(globalPropertyBundles, propertyBundleIds) {
  return Belt_List.keepMapU(propertyBundleIds, (function (groupRef) {
                return resolvePropertyBundleRef(globalPropertyBundles, groupRef);
              }));
}

function resolvePropertyBundleRefs_mappedModel(globalPropertyBundles, propertyBundleIds) {
  return Curry._1(TrackingPlanMappedModel.PropertyBundles.fromArray, Belt_Array.keepMapU(propertyBundleIds, (function (propertyBundleId) {
                    return Curry._2(TrackingPlanMappedModel.PropertyBundles.get, globalPropertyBundles, propertyBundleId);
                  })));
}

function resolvePropertyBundlesToProperties($$event, model) {
  return BeltListExtensions.dedupeOrdered(BeltListExtensions.flatMap(resolvePropertyBundleRefs(model.propertyBundles, $$event.propertyBundles), (function (group) {
                    return resolvePropertyIds(model.properties, group.properties);
                  })), (function (property) {
                return property.id;
              }));
}

function classifyProperties(properties) {
  var eventProperties = Belt_List.keepU(properties, (function (prop) {
          return prop.sendAs === "EventProperty";
        }));
  var userProperties = Belt_List.keepU(properties, (function (prop) {
          return prop.sendAs === "UserProperty";
        }));
  var groupProperties = Belt_List.keepU(properties, (function (prop) {
          var match = prop.sendAs;
          if (typeof match !== "object") {
            return false;
          } else {
            return true;
          }
        }));
  return [
          eventProperties,
          userProperties,
          groupProperties
        ];
}

function getSystemProperties(globalProperties) {
  return Belt_List.keepMapU(globalProperties, (function (property) {
                if (property.TAG === "PropertyRef") {
                  return ;
                }
                var prop = property._0;
                if (prop.sendAs === "SystemProperty") {
                  return prop;
                }
                
              }));
}

function getSystemProperties__mappedModel(properties) {
  return Belt_Array.keepMap(Curry._1(TrackingPlanMappedModel.Properties.toArray, properties), (function (property) {
                var match = property.sendAs;
                if (typeof match !== "object" && match === "SystemProperty") {
                  return property;
                }
                
              }));
}

function getOptionalAndRequiredSystemPropertiesOnSource(globalProperties, sourceId, model) {
  return Belt_Array.partitionU(Belt_List.toArray(getSystemProperties(globalProperties)), (function (property) {
                return ModelUtils.isPropertyOptionalOnSource(property, sourceId, model);
              }));
}

function withSystemProperties(param, globalProperties) {
  return {
          eventProperties: param[0],
          userProperties: param[1],
          groupProperties: param[2],
          systemProperties: getSystemProperties(globalProperties)
        };
}

function getProperties(model, eventProps) {
  return withSystemProperties(classifyProperties(Belt_List.keepMap(eventProps, (function (param) {
                        return resolveProperty(model, param);
                      }))), model.properties);
}

function resolveSources(model, sourceIds) {
  return Belt_List.keepMapU(sourceIds, (function (sourceId) {
                return Belt_List.getByU(model.sources, (function (source) {
                              return source.id === sourceId;
                            }));
              }));
}

function resolveDestinations(model, destinationIds) {
  return Belt_List.keepMap(destinationIds, (function (destinationId) {
                return Belt_List.getBy(model.destinations, (function (destination) {
                              return destination.id === destinationId;
                            }));
              }));
}

function destinationsReceivingEvent(model, $$event) {
  return Belt_List.keepMapU(BeltListExtensions.dedupeString(BeltListExtensions.flatMapU($$event.includeDestinations, (function (includeDestination) {
                        return includeDestination.VAL[1];
                      }))), (function (destinationId) {
                return ModelUtils.getDestinationById(destinationId, model);
              }));
}

function destinationsReceivingEvent_mapped(destinations, $$event) {
  return Belt_List.keepMapU(BeltListExtensions.dedupeString(BeltListExtensions.flatMapU($$event.includeDestinations, (function (includeDestination) {
                        return includeDestination.VAL[1];
                      }))), (function (destinationId) {
                return Curry._2(TrackingPlanMappedModel.Destinations.get, destinations, destinationId);
              }));
}

function sourcesSendingToDestinationsForEvent(model, $$event) {
  return Belt_Array.keepMapU(Belt_List.toArray($$event.includeDestinations), (function (includeDestination) {
                var match = includeDestination.VAL;
                var source = ModelUtils.getSourceById(match[0], model);
                if (source !== undefined) {
                  return [
                          source,
                          Belt_List.keepMapU(match[1], (function (destinationId) {
                                  return ModelUtils.getDestinationById(destinationId, model);
                                }))
                        ];
                }
                
              }));
}

function filterExcludedEventsFromModel(model, source) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: filterExcludedEvents(model.events, {
                hd: {
                  NAME: "Source",
                  VAL: source.id
                },
                tl: /* [] */0
              }),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function filterExcludedEventsBySourcesFromModel(model, sources) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: filterExcludedEvents(model.events, Belt_List.map(sources, (function (source) {
                      return {
                              NAME: "Source",
                              VAL: source.id
                            };
                    }))),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function removeExcludedPropertiesOnSource(model, source) {
  return {
          types: model.types,
          properties: Belt_List.keepU(model.properties, (function (property) {
                  if (property.TAG === "PropertyRef") {
                    return false;
                  } else {
                    return !Belt_List.has(ModelUtils.propertyExcludedSources(property._0, model), source.id, (function (prim0, prim1) {
                                  return prim0 === prim1;
                                }));
                  }
                })),
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function isOnlyUpdateGroupEvent($$event) {
  if (Belt_List.size($$event.types) === 2 && Belt_List.hasU($$event.types, "UpdateGroups", (function (a, b) {
            return a === b;
          }))) {
    return Belt_List.hasU($$event.types, "SkipLogEvent", (function (a, b) {
                  return a === b;
                }));
  } else {
    return false;
  }
}

function keepIncludedSystemPropsOnSource(property, sourceId, model) {
  if (!ModelUtils.isPropertyIncludedOnEventAndSource(undefined, property, TrackingPlanModel.propertyAbsenceAllEventsKey, sourceId, model)()) {
    return ;
  }
  var newrecord = Caml_obj.obj_dup(property);
  return newrecord.absence = ModelUtils.isPropertyOptionalOnEventAndSource(property, TrackingPlanModel.propertyAbsenceAllEventsKey, sourceId, model) ? ({
              TAG: "SometimesSent",
              _0: ""
            }) : "AlwaysSent", newrecord;
}

function enrichModelForCodegen(serverTrackingWithDeviceIdOpt, omitUserIdInSetUserPropertiesOnCustomDestinationOpt, skipImplementWithAvoFilteringOpt, model, source) {
  var serverTrackingWithDeviceId = serverTrackingWithDeviceIdOpt !== undefined ? serverTrackingWithDeviceIdOpt : false;
  var omitUserIdInSetUserPropertiesOnCustomDestination = omitUserIdInSetUserPropertiesOnCustomDestinationOpt !== undefined ? omitUserIdInSetUserPropertiesOnCustomDestinationOpt : false;
  var skipImplementWithAvoFiltering = skipImplementWithAvoFilteringOpt !== undefined ? skipImplementWithAvoFilteringOpt : false;
  var propertyBundles = Belt_List.keepMapU(model.propertyBundles, (function (group) {
          var filteredProperties = Belt_List.keepU(group.properties, (function (propertyId) {
                  var maybeProperty = getPropertyById(model, propertyId);
                  return Belt_Option.mapWithDefault(maybeProperty, true, (function (property) {
                                return !Belt_List.someU(ModelUtils.propertyExcludedSources(property, model), (function (sourceId) {
                                              return sourceId === source.id;
                                            }));
                              }));
                }));
          if (filteredProperties) {
            return {
                    id: group.id,
                    name: group.name,
                    description: group.description,
                    properties: filteredProperties,
                    globalRequirementsMetadata: group.globalRequirementsMetadata
                  };
          }
          
        }));
  var model$1 = removeExcludedPropertiesOnSource(model, source);
  return {
          types: model$1.types,
          properties: Belt_List.keepMap(Belt_List.mapU(model$1.properties, (function (property) {
                      if (property.TAG === "PropertyRef") {
                        return property;
                      }
                      var property$1 = property._0;
                      var newrecord = Caml_obj.obj_dup(property$1);
                      return {
                              TAG: "Property",
                              _0: (newrecord.validations = Belt_List.keepMapU(property$1.validations, (function (validation) {
                                        if (typeof validation !== "object") {
                                          return validation;
                                        }
                                        if (validation.NAME !== "Matches") {
                                          return validation;
                                        }
                                        var nextMatches = Belt_List.keepU(validation.VAL, (function (param) {
                                                if (Belt_List.someU(param[1], (function (sourceId) {
                                                          return sourceId === source.id;
                                                        }))) {
                                                  return false;
                                                }
                                                var match = param[0].VAL;
                                                if (typeof match === "object" && match.NAME === "StringLit") {
                                                  return match.VAL.trim() !== "";
                                                } else {
                                                  return true;
                                                }
                                              }));
                                        if (nextMatches === /* [] */0 || property$1.type_ !== "string") {
                                          return ;
                                        } else {
                                          return {
                                                  NAME: "Matches",
                                                  VAL: nextMatches
                                                };
                                        }
                                      })), newrecord)
                            };
                    })), (function (property) {
                  if (property.TAG === "PropertyRef") {
                    return property;
                  }
                  var property$1 = property._0;
                  var tmp = property$1.sendAs;
                  if (typeof tmp !== "object" && tmp === "SystemProperty") {
                    return Belt_Option.map(keepIncludedSystemPropsOnSource(property$1, source.id, model$1), (function (prop) {
                                  return {
                                          TAG: "Property",
                                          _0: prop
                                        };
                                }));
                  } else {
                    return property;
                  }
                })),
          propertyBundles: propertyBundles,
          events: Belt_List.keepMapU(model$1.events, (function ($$event) {
                  var isIncludedInCodegen = skipImplementWithAvoFiltering || Belt_List.someU($$event.includeSources, (function (includedSource) {
                          if (includedSource.id === source.id) {
                            return includedSource.includeInCodegen;
                          } else {
                            return false;
                          }
                        }));
                  if (!isIncludedInCodegen) {
                    return ;
                  }
                  var destinations = resolveDestinations(model$1, BeltListExtensions.dedupeString(BeltListExtensions.flatMap($$event.includeDestinations, (function (x) {
                                  var match = x.VAL;
                                  var sourceId = match[0];
                                  if (Belt_List.some($$event.includeSources, (function (includedSource) {
                                            return includedSource.id === sourceId;
                                          })) && source.id === sourceId) {
                                    return match[1];
                                  } else {
                                    return /* [] */0;
                                  }
                                }))));
                  var destinations$1 = AvoConfig.getValidDestinations(model$1.sources, destinations);
                  var event_id = $$event.id;
                  var event_name = $$event.name;
                  var event_uniqueName = $$event.uniqueName;
                  var event_description = $$event.description;
                  var event_directPropertyRefs = Belt_List.keepU($$event.directPropertyRefs, (function (property) {
                          if (property.TAG !== "PropertyRef") {
                            return true;
                          }
                          var maybeProperty = getPropertyById(model$1, property._0.id);
                          return Belt_Option.mapWithDefault(maybeProperty, true, (function (property) {
                                        return ModelUtils.isPropertyIncludedOnEventAndSource(undefined, property, $$event.id, source.id, model$1)();
                                      }));
                        }));
                  var event_propertyBundles = Belt_List.keepU($$event.propertyBundles, (function (bundleRef) {
                          return Belt_Option.isSome(Belt_List.getByU(propertyBundles, (function (bundle) {
                                            return bundle.id === bundleRef.id;
                                          })));
                        }));
                  var event_variants = $$event.variants;
                  var event_types = $$event.types;
                  var event_tags = $$event.tags;
                  var event_excludeSourcesDeprecated = $$event.excludeSourcesDeprecated;
                  var event_includeSources = $$event.includeSources;
                  var event_includeDestinations = $$event.includeDestinations;
                  var event_hashes = $$event.hashes;
                  var event_propertyWhitelist = $$event.propertyWhitelist;
                  var event_eventGroupTypeIdsWithArchive = $$event.eventGroupTypeIdsWithArchive;
                  var event_userGroupTypeIdsWithArchive = $$event.userGroupTypeIdsWithArchive;
                  var event_triggers = $$event.triggers;
                  var event_globalRequirementsMetadata = $$event.globalRequirementsMetadata;
                  var $$event$1 = {
                    id: event_id,
                    name: event_name,
                    uniqueName: event_uniqueName,
                    description: event_description,
                    directPropertyRefs: event_directPropertyRefs,
                    propertyBundles: event_propertyBundles,
                    variants: event_variants,
                    types: event_types,
                    tags: event_tags,
                    excludeSourcesDeprecated: event_excludeSourcesDeprecated,
                    includeSources: event_includeSources,
                    includeDestinations: event_includeDestinations,
                    hashes: event_hashes,
                    propertyWhitelist: event_propertyWhitelist,
                    eventGroupTypeIdsWithArchive: event_eventGroupTypeIdsWithArchive,
                    userGroupTypeIdsWithArchive: event_userGroupTypeIdsWithArchive,
                    triggers: event_triggers,
                    globalRequirementsMetadata: event_globalRequirementsMetadata
                  };
                  var isIdentify = Belt_List.some(event_types, (function (type_) {
                          return type_ === "Identify";
                        }));
                  var isServer = source.platform === "Node" || source.platform === "Python" || source.platform === "Php" || source.platform === "Ruby" || source.platform === "DotNet" || source.platform === "Java";
                  var isWeb = source.platform === "Web";
                  var isIosOrReactNativeOrExpo = source.platform === "IOS" || source.platform === "ReactNative" || source.platform === "Expo";
                  var isSegmentDestination = Belt_List.some(destinations$1, (function (destination) {
                          return destination.type_ === "Segment";
                        }));
                  var isZendeskConnectDestination = Belt_List.some(destinations$1, (function (destination) {
                          return destination.type_ === "ZendeskConnect";
                        }));
                  var isCustomDestination = Belt_List.some(destinations$1, (function (destination) {
                          return destination.type_ === "Custom";
                        }));
                  var isIosOrReactNativeOrExpoSegment = isIosOrReactNativeOrExpo && isSegmentDestination;
                  var hasUserProperties = Belt_List.some(Belt_List.keepMap(event_directPropertyRefs, (function (param) {
                              return resolveProperty(model$1, param);
                            })), (function (property) {
                          return property.sendAs === "UserProperty";
                        }));
                  var needsFixSegmentUserProperties = !isIdentify && isIosOrReactNativeOrExpoSegment && hasUserProperties;
                  var needsFixZendeskConnectUserProperties = !isIdentify && isWeb && isZendeskConnectDestination && hasUserProperties;
                  var needsFixCustomDestinationUserProperties = !isIdentify && isCustomDestination && hasUserProperties && !omitUserIdInSetUserPropertiesOnCustomDestination;
                  var onlyUpdateGroupsEvent = isOnlyUpdateGroupEvent($$event$1);
                  var serverCallNeedsUserIdEnrichment = AvoConfig.hasAtLeastOneDestinationThatDoesNotSupportGroupProperties(source, destinations$1) ? isServer : isServer && (!onlyUpdateGroupsEvent || source.platform === "DotNet");
                  var serverTrackingWithDeviceId$1 = source.platform === "Ruby" || source.platform === "Java" || source.platform === "DotNet" || source.language === "re_v2" ? true : serverTrackingWithDeviceId;
                  var $$event$2 = source.platform === "Node" && source.language === "js_v2" && isSegmentDestination ? ({
                        id: event_id,
                        name: event_name,
                        uniqueName: event_uniqueName,
                        description: event_description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-segment-context",
                              name: "Segment: Context",
                              uniqueName: undefined,
                              description: "Segment Context Spec Field",
                              type_: "object",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Server",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "Segment",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: true,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: {
                                TAG: "SometimesSent",
                                _0: ""
                              },
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: event_directPropertyRefs
                        },
                        propertyBundles: event_propertyBundles,
                        variants: event_variants,
                        types: event_types,
                        tags: event_tags,
                        excludeSourcesDeprecated: event_excludeSourcesDeprecated,
                        includeSources: event_includeSources,
                        includeDestinations: event_includeDestinations,
                        hashes: event_hashes,
                        propertyWhitelist: event_propertyWhitelist,
                        eventGroupTypeIdsWithArchive: event_eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: event_userGroupTypeIdsWithArchive,
                        triggers: event_triggers,
                        globalRequirementsMetadata: event_globalRequirementsMetadata
                      }) : $$event$1;
                  var $$event$3 = Belt_List.someU($$event$2.types, (function (t) {
                          return t === "Page";
                        })) && AvoConfig.hasAnalyticsTool(destinations$1, "Custom") ? ({
                        id: $$event$2.id,
                        name: $$event$2.name,
                        uniqueName: $$event$2.uniqueName,
                        description: $$event$2.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-type-page-custom-name",
                              name: "Custom Destination Page Name",
                              uniqueName: undefined,
                              description: "Name of the page or screen",
                              type_: "string",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Page",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "Custom",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: false,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: "AlwaysSent",
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: $$event$2.directPropertyRefs
                        },
                        propertyBundles: $$event$2.propertyBundles,
                        variants: $$event$2.variants,
                        types: $$event$2.types,
                        tags: $$event$2.tags,
                        excludeSourcesDeprecated: $$event$2.excludeSourcesDeprecated,
                        includeSources: $$event$2.includeSources,
                        includeDestinations: $$event$2.includeDestinations,
                        hashes: $$event$2.hashes,
                        propertyWhitelist: $$event$2.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event$2.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event$2.userGroupTypeIdsWithArchive,
                        triggers: $$event$2.triggers,
                        globalRequirementsMetadata: $$event$2.globalRequirementsMetadata
                      }) : $$event$2;
                  var $$event$4 = serverCallNeedsUserIdEnrichment && (source.platform === "Ruby" || serverTrackingWithDeviceId$1 && !isIdentify && isServer) ? ({
                        id: $$event$3.id,
                        name: $$event$3.name,
                        uniqueName: $$event$3.uniqueName,
                        description: $$event$3.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-server-anonymous-id-optional",
                              name: "Anonymous Id",
                              uniqueName: undefined,
                              description: "Anonymous Id is used to identify your users before they get a User Id.",
                              type_: "string",
                              sendAs: "UserProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Server",
                              virtualForAllowedValues: false,
                              analyticsTools: /* [] */0,
                              devPlatforms: {
                                hd: "Node",
                                tl: {
                                  hd: "Python",
                                  tl: {
                                    hd: "Php",
                                    tl: {
                                      hd: "Ruby",
                                      tl: {
                                        hd: "Java",
                                        tl: {
                                          hd: "DotNet",
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              },
                              list: false,
                              operation: "Set",
                              optionalDeprecated: true,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: {
                                TAG: "SometimesSent",
                                _0: ""
                              },
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: {
                            hd: {
                              TAG: "Property",
                              _0: {
                                id: "avo-enriched-server-user-id-optional",
                                name: "User Id",
                                uniqueName: undefined,
                                description: "User Id is for identifying your users.",
                                type_: "string",
                                sendAs: "UserProperty",
                                validations: /* [] */0,
                                eventSpecificAllowedPropertyValues: [],
                                auto: false,
                                builtIn: "Server",
                                virtualForAllowedValues: false,
                                analyticsTools: /* [] */0,
                                devPlatforms: {
                                  hd: "Node",
                                  tl: {
                                    hd: "Python",
                                    tl: {
                                      hd: "Php",
                                      tl: {
                                        hd: "Ruby",
                                        tl: {
                                          hd: "Java",
                                          tl: {
                                            hd: "DotNet",
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                list: false,
                                operation: "Set",
                                optionalDeprecated: true,
                                excludedSourcesDeprecated: /* [] */0,
                                optionalWhenInObject: false,
                                absence: {
                                  TAG: "SometimesSent",
                                  _0: ""
                                },
                                globalRequirementsMetadata: undefined
                              }
                            },
                            tl: $$event$3.directPropertyRefs
                          }
                        },
                        propertyBundles: $$event$3.propertyBundles,
                        variants: $$event$3.variants,
                        types: $$event$3.types,
                        tags: $$event$3.tags,
                        excludeSourcesDeprecated: $$event$3.excludeSourcesDeprecated,
                        includeSources: $$event$3.includeSources,
                        includeDestinations: $$event$3.includeDestinations,
                        hashes: $$event$3.hashes,
                        propertyWhitelist: $$event$3.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event$3.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event$3.userGroupTypeIdsWithArchive,
                        triggers: $$event$3.triggers,
                        globalRequirementsMetadata: $$event$3.globalRequirementsMetadata
                      }) : (
                      serverCallNeedsUserIdEnrichment && serverTrackingWithDeviceId$1 && isIdentify ? ({
                            id: $$event$3.id,
                            name: $$event$3.name,
                            uniqueName: $$event$3.uniqueName,
                            description: $$event$3.description,
                            directPropertyRefs: {
                              hd: {
                                TAG: "Property",
                                _0: {
                                  id: "avo-enriched-server-anonymous-id",
                                  name: "Anonymous Id",
                                  uniqueName: undefined,
                                  description: "Anonymous Id is used to identify your users before they get a User Id.",
                                  type_: "string",
                                  sendAs: "UserProperty",
                                  validations: /* [] */0,
                                  eventSpecificAllowedPropertyValues: [],
                                  auto: false,
                                  builtIn: "Server",
                                  virtualForAllowedValues: false,
                                  analyticsTools: /* [] */0,
                                  devPlatforms: {
                                    hd: "Node",
                                    tl: {
                                      hd: "Python",
                                      tl: {
                                        hd: "Php",
                                        tl: {
                                          hd: "Ruby",
                                          tl: {
                                            hd: "Java",
                                            tl: {
                                              hd: "DotNet",
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  list: false,
                                  operation: "Set",
                                  optionalDeprecated: false,
                                  excludedSourcesDeprecated: /* [] */0,
                                  optionalWhenInObject: false,
                                  absence: "AlwaysSent",
                                  globalRequirementsMetadata: undefined
                                }
                              },
                              tl: {
                                hd: {
                                  TAG: "Property",
                                  _0: {
                                    id: "avo-enriched-server-user-id",
                                    name: "User Id",
                                    uniqueName: undefined,
                                    description: "User Id is for identifying your users.",
                                    type_: "string",
                                    sendAs: "UserProperty",
                                    validations: /* [] */0,
                                    eventSpecificAllowedPropertyValues: [],
                                    auto: false,
                                    builtIn: "Server",
                                    virtualForAllowedValues: false,
                                    analyticsTools: /* [] */0,
                                    devPlatforms: {
                                      hd: "Node",
                                      tl: {
                                        hd: "Python",
                                        tl: {
                                          hd: "Php",
                                          tl: {
                                            hd: "Ruby",
                                            tl: {
                                              hd: "Java",
                                              tl: {
                                                hd: "DotNet",
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    },
                                    list: false,
                                    operation: "Set",
                                    optionalDeprecated: false,
                                    excludedSourcesDeprecated: /* [] */0,
                                    optionalWhenInObject: false,
                                    absence: "AlwaysSent",
                                    globalRequirementsMetadata: undefined
                                  }
                                },
                                tl: $$event$3.directPropertyRefs
                              }
                            },
                            propertyBundles: $$event$3.propertyBundles,
                            variants: $$event$3.variants,
                            types: $$event$3.types,
                            tags: $$event$3.tags,
                            excludeSourcesDeprecated: $$event$3.excludeSourcesDeprecated,
                            includeSources: $$event$3.includeSources,
                            includeDestinations: $$event$3.includeDestinations,
                            hashes: $$event$3.hashes,
                            propertyWhitelist: $$event$3.propertyWhitelist,
                            eventGroupTypeIdsWithArchive: $$event$3.eventGroupTypeIdsWithArchive,
                            userGroupTypeIdsWithArchive: $$event$3.userGroupTypeIdsWithArchive,
                            triggers: $$event$3.triggers,
                            globalRequirementsMetadata: $$event$3.globalRequirementsMetadata
                          }) : (
                          serverCallNeedsUserIdEnrichment && !isIdentify ? ({
                                id: $$event$3.id,
                                name: $$event$3.name,
                                uniqueName: $$event$3.uniqueName,
                                description: $$event$3.description,
                                directPropertyRefs: {
                                  hd: {
                                    TAG: "Property",
                                    _0: {
                                      id: "avo-enriched-server-user-id",
                                      name: "User Id",
                                      uniqueName: undefined,
                                      description: "User Id is required for server sources.",
                                      type_: "string",
                                      sendAs: "UserProperty",
                                      validations: /* [] */0,
                                      eventSpecificAllowedPropertyValues: [],
                                      auto: false,
                                      builtIn: "Server",
                                      virtualForAllowedValues: false,
                                      analyticsTools: /* [] */0,
                                      devPlatforms: {
                                        hd: "Node",
                                        tl: {
                                          hd: "Python",
                                          tl: {
                                            hd: "Php",
                                            tl: {
                                              hd: "Ruby",
                                              tl: {
                                                hd: "Java",
                                                tl: {
                                                  hd: "DotNet",
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      },
                                      list: false,
                                      operation: "Set",
                                      optionalDeprecated: false,
                                      excludedSourcesDeprecated: /* [] */0,
                                      optionalWhenInObject: false,
                                      absence: "AlwaysSent",
                                      globalRequirementsMetadata: undefined
                                    }
                                  },
                                  tl: $$event$3.directPropertyRefs
                                },
                                propertyBundles: $$event$3.propertyBundles,
                                variants: $$event$3.variants,
                                types: $$event$3.types,
                                tags: $$event$3.tags,
                                excludeSourcesDeprecated: $$event$3.excludeSourcesDeprecated,
                                includeSources: $$event$3.includeSources,
                                includeDestinations: $$event$3.includeDestinations,
                                hashes: $$event$3.hashes,
                                propertyWhitelist: $$event$3.propertyWhitelist,
                                eventGroupTypeIdsWithArchive: $$event$3.eventGroupTypeIdsWithArchive,
                                userGroupTypeIdsWithArchive: $$event$3.userGroupTypeIdsWithArchive,
                                triggers: $$event$3.triggers,
                                globalRequirementsMetadata: $$event$3.globalRequirementsMetadata
                              }) : (
                              needsFixCustomDestinationUserProperties || needsFixSegmentUserProperties || needsFixZendeskConnectUserProperties ? ({
                                    id: $$event$3.id,
                                    name: $$event$3.name,
                                    uniqueName: $$event$3.uniqueName,
                                    description: $$event$3.description,
                                    directPropertyRefs: {
                                      hd: {
                                        TAG: "Property",
                                        _0: {
                                          id: "avo-enriched-type-user-id",
                                          name: "User Id",
                                          uniqueName: undefined,
                                          description: "The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.",
                                          type_: "string",
                                          sendAs: "UserProperty",
                                          validations: /* [] */0,
                                          eventSpecificAllowedPropertyValues: [],
                                          auto: false,
                                          builtIn: "Identify",
                                          virtualForAllowedValues: false,
                                          analyticsTools: {
                                            hd: "Custom",
                                            tl: {
                                              hd: "Segment",
                                              tl: /* [] */0
                                            }
                                          },
                                          devPlatforms: {
                                            hd: "ReactNative",
                                            tl: {
                                              hd: "Expo",
                                              tl: {
                                                hd: "IOS",
                                                tl: /* [] */0
                                              }
                                            }
                                          },
                                          list: false,
                                          operation: "Set",
                                          optionalDeprecated: false,
                                          excludedSourcesDeprecated: /* [] */0,
                                          optionalWhenInObject: false,
                                          absence: "AlwaysSent",
                                          globalRequirementsMetadata: undefined
                                        }
                                      },
                                      tl: $$event$3.directPropertyRefs
                                    },
                                    propertyBundles: $$event$3.propertyBundles,
                                    variants: $$event$3.variants,
                                    types: $$event$3.types,
                                    tags: $$event$3.tags,
                                    excludeSourcesDeprecated: $$event$3.excludeSourcesDeprecated,
                                    includeSources: $$event$3.includeSources,
                                    includeDestinations: $$event$3.includeDestinations,
                                    hashes: $$event$3.hashes,
                                    propertyWhitelist: $$event$3.propertyWhitelist,
                                    eventGroupTypeIdsWithArchive: $$event$3.eventGroupTypeIdsWithArchive,
                                    userGroupTypeIdsWithArchive: $$event$3.userGroupTypeIdsWithArchive,
                                    triggers: $$event$3.triggers,
                                    globalRequirementsMetadata: $$event$3.globalRequirementsMetadata
                                  }) : (
                                  isIdentify ? (
                                      isServer ? ({
                                            id: $$event$3.id,
                                            name: $$event$3.name,
                                            uniqueName: $$event$3.uniqueName,
                                            description: $$event$3.description,
                                            directPropertyRefs: {
                                              hd: {
                                                TAG: "Property",
                                                _0: {
                                                  id: "avo-enriched-server-user-id",
                                                  name: "User Id",
                                                  uniqueName: undefined,
                                                  description: "User Id is required for server sources.",
                                                  type_: "string",
                                                  sendAs: "UserProperty",
                                                  validations: /* [] */0,
                                                  eventSpecificAllowedPropertyValues: [],
                                                  auto: false,
                                                  builtIn: "Server",
                                                  virtualForAllowedValues: false,
                                                  analyticsTools: /* [] */0,
                                                  devPlatforms: {
                                                    hd: "Node",
                                                    tl: {
                                                      hd: "Python",
                                                      tl: {
                                                        hd: "Php",
                                                        tl: {
                                                          hd: "Ruby",
                                                          tl: {
                                                            hd: "Java",
                                                            tl: {
                                                              hd: "DotNet",
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  },
                                                  list: false,
                                                  operation: "Set",
                                                  optionalDeprecated: false,
                                                  excludedSourcesDeprecated: /* [] */0,
                                                  optionalWhenInObject: false,
                                                  absence: "AlwaysSent",
                                                  globalRequirementsMetadata: undefined
                                                }
                                              },
                                              tl: $$event$3.directPropertyRefs
                                            },
                                            propertyBundles: $$event$3.propertyBundles,
                                            variants: $$event$3.variants,
                                            types: $$event$3.types,
                                            tags: $$event$3.tags,
                                            excludeSourcesDeprecated: $$event$3.excludeSourcesDeprecated,
                                            includeSources: $$event$3.includeSources,
                                            includeDestinations: $$event$3.includeDestinations,
                                            hashes: $$event$3.hashes,
                                            propertyWhitelist: $$event$3.propertyWhitelist,
                                            eventGroupTypeIdsWithArchive: $$event$3.eventGroupTypeIdsWithArchive,
                                            userGroupTypeIdsWithArchive: $$event$3.userGroupTypeIdsWithArchive,
                                            triggers: $$event$3.triggers,
                                            globalRequirementsMetadata: $$event$3.globalRequirementsMetadata
                                          }) : ({
                                            id: $$event$3.id,
                                            name: $$event$3.name,
                                            uniqueName: $$event$3.uniqueName,
                                            description: $$event$3.description,
                                            directPropertyRefs: {
                                              hd: {
                                                TAG: "Property",
                                                _0: {
                                                  id: "avo-enriched-type-user-id",
                                                  name: "User Id",
                                                  uniqueName: undefined,
                                                  description: "The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.",
                                                  type_: "string",
                                                  sendAs: "UserProperty",
                                                  validations: /* [] */0,
                                                  eventSpecificAllowedPropertyValues: [],
                                                  auto: false,
                                                  builtIn: "Identify",
                                                  virtualForAllowedValues: false,
                                                  analyticsTools: /* [] */0,
                                                  devPlatforms: /* [] */0,
                                                  list: false,
                                                  operation: "Set",
                                                  optionalDeprecated: false,
                                                  excludedSourcesDeprecated: /* [] */0,
                                                  optionalWhenInObject: false,
                                                  absence: "AlwaysSent",
                                                  globalRequirementsMetadata: undefined
                                                }
                                              },
                                              tl: $$event$3.directPropertyRefs
                                            },
                                            propertyBundles: $$event$3.propertyBundles,
                                            variants: $$event$3.variants,
                                            types: $$event$3.types,
                                            tags: $$event$3.tags,
                                            excludeSourcesDeprecated: $$event$3.excludeSourcesDeprecated,
                                            includeSources: $$event$3.includeSources,
                                            includeDestinations: $$event$3.includeDestinations,
                                            hashes: $$event$3.hashes,
                                            propertyWhitelist: $$event$3.propertyWhitelist,
                                            eventGroupTypeIdsWithArchive: $$event$3.eventGroupTypeIdsWithArchive,
                                            userGroupTypeIdsWithArchive: $$event$3.userGroupTypeIdsWithArchive,
                                            triggers: $$event$3.triggers,
                                            globalRequirementsMetadata: $$event$3.globalRequirementsMetadata
                                          })
                                    ) : $$event$3
                                )
                            )
                        )
                    );
                  return Belt_List.someU($$event$4.types, (function (t) {
                                return t === "Revenue";
                              })) && AvoConfig.hasAnalyticsTool(destinations$1, "Custom") ? ({
                              id: $$event$4.id,
                              name: $$event$4.name,
                              uniqueName: $$event$4.uniqueName,
                              description: $$event$4.description,
                              directPropertyRefs: {
                                hd: {
                                  TAG: "Property",
                                  _0: {
                                    id: "avo-enriched-type-revenue-custom-amount",
                                    name: "Custom Destination Amount",
                                    uniqueName: undefined,
                                    description: "The amount of money charged to the current user.",
                                    type_: "float",
                                    sendAs: "EventProperty",
                                    validations: /* [] */0,
                                    eventSpecificAllowedPropertyValues: [],
                                    auto: false,
                                    builtIn: "Revenue",
                                    virtualForAllowedValues: false,
                                    analyticsTools: {
                                      hd: "Custom",
                                      tl: /* [] */0
                                    },
                                    devPlatforms: /* [] */0,
                                    list: false,
                                    operation: "Set",
                                    optionalDeprecated: false,
                                    excludedSourcesDeprecated: /* [] */0,
                                    optionalWhenInObject: false,
                                    absence: "AlwaysSent",
                                    globalRequirementsMetadata: undefined
                                  }
                                },
                                tl: $$event$4.directPropertyRefs
                              },
                              propertyBundles: $$event$4.propertyBundles,
                              variants: $$event$4.variants,
                              types: $$event$4.types,
                              tags: $$event$4.tags,
                              excludeSourcesDeprecated: $$event$4.excludeSourcesDeprecated,
                              includeSources: $$event$4.includeSources,
                              includeDestinations: $$event$4.includeDestinations,
                              hashes: $$event$4.hashes,
                              propertyWhitelist: $$event$4.propertyWhitelist,
                              eventGroupTypeIdsWithArchive: $$event$4.eventGroupTypeIdsWithArchive,
                              userGroupTypeIdsWithArchive: $$event$4.userGroupTypeIdsWithArchive,
                              triggers: $$event$4.triggers,
                              globalRequirementsMetadata: $$event$4.globalRequirementsMetadata
                            }) : $$event$4;
                })),
          migrations: model$1.migrations,
          sources: model$1.sources,
          destinations: model$1.destinations,
          groupTypes: model$1.groupTypes,
          goals: model$1.goals,
          metrics: model$1.metrics,
          archive: model$1.archive,
          openBranches: model$1.openBranches,
          branchPointer: model$1.branchPointer,
          rules: model$1.rules,
          integrations: model$1.integrations,
          globalRequirements: model$1.globalRequirements
        };
}

function resolveEventPropertyBundlesIntoEventProperties(model, $$event, forceIncludeOpt, sourceIds) {
  var forceInclude = forceIncludeOpt !== undefined ? forceIncludeOpt : false;
  var bundledPropertyRefs = Belt_List.flatten(Belt_List.map(ModelUtils.resolvePropertyBundleRefs(model, $$event.propertyBundles), (function (bundle) {
              return Belt_List.keepMap(bundle.properties, (function (propertyId) {
                            return Belt_Option.flatMap(ModelUtils.resolvePropertyById(propertyId, model), (function (property) {
                                          var isIncluded = sourceIds === /* [] */0 || resolveSources(model, Belt_List.mapU($$event.includeSources, (function (includedSource) {
                                                      return includedSource.id;
                                                    }))) === /* [] */0 || forceInclude || ModelUtils.isPropertyIncludedOnEventAndSources(undefined, property, $$event.id, sourceIds, model, undefined);
                                          var maybeDirectPropertyRef = Belt_List.getBy($$event.directPropertyRefs, (function (propertyRef) {
                                                  if (propertyRef.TAG === "PropertyRef") {
                                                    return propertyRef._0.id === propertyId;
                                                  } else {
                                                    return false;
                                                  }
                                                }));
                                          if (isIncluded) {
                                            return Belt_Option.getWithDefault(maybeDirectPropertyRef, {
                                                        TAG: "PropertyRef",
                                                        _0: {
                                                          id: propertyId,
                                                          description: "",
                                                          pinnedValue: undefined
                                                        }
                                                      });
                                          }
                                          
                                        }));
                          }));
            })));
  var currentDirectPropertyRefs = $$event.directPropertyRefs;
  var nextDirectPropertyRefs = BeltListExtensions.dedupeOrdered(Belt_List.concat(bundledPropertyRefs, currentDirectPropertyRefs), ModelUtils.getPropertyId);
  return {
          id: $$event.id,
          name: $$event.name,
          uniqueName: $$event.uniqueName,
          description: $$event.description,
          directPropertyRefs: nextDirectPropertyRefs,
          propertyBundles: $$event.propertyBundles,
          variants: $$event.variants,
          types: $$event.types,
          tags: $$event.tags,
          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
          includeSources: $$event.includeSources,
          includeDestinations: $$event.includeDestinations,
          hashes: $$event.hashes,
          propertyWhitelist: $$event.propertyWhitelist,
          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
          triggers: $$event.triggers,
          globalRequirementsMetadata: $$event.globalRequirementsMetadata
        };
}

function resolvePropertyBundlesPropertiesToEventProperties(model, forceIncludeAllPropsOpt, sourceIds) {
  var forceIncludeAllProps = forceIncludeAllPropsOpt !== undefined ? forceIncludeAllPropsOpt : false;
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: Belt_List.mapU(model.events, (function ($$event) {
                  return resolveEventPropertyBundlesIntoEventProperties(model, $$event, forceIncludeAllProps, sourceIds);
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function enrichPropertyBundlesForCodegen(model, forceIncludeAllPropertyBundlesOpt, sourceIds) {
  var forceIncludeAllPropertyBundles = forceIncludeAllPropertyBundlesOpt !== undefined ? forceIncludeAllPropertyBundlesOpt : false;
  var improvedPropertyBundlesUnfolding = Belt_Option.mapWithDefault(CliFeatureFlag.featureFlagValues.contents, false, (function (flags) {
          return Belt_Option.getWithDefault(Belt_MapString.get(flags, FeatureFlag.featureFlagToString("ImprovedPropertyGroupsUnfolding")), false);
        }));
  var isPropertyGroupConsistentAcrossAllEventsCache = {
    contents: undefined
  };
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: Belt_List.keepU(model.propertyBundles, (function (group) {
                  return ModelUtils.isPropertyBundleConsistentAcrossAllEventsWithCache(sourceIds, improvedPropertyBundlesUnfolding, group.id, isPropertyGroupConsistentAcrossAllEventsCache, model);
                })),
          events: Belt_List.mapU(model.events, (function ($$event) {
                  var eventWithResolvedPropertiesFromPropertyBundles = resolveEventPropertyBundlesIntoEventProperties(model, $$event, forceIncludeAllPropertyBundles, sourceIds);
                  return {
                          id: eventWithResolvedPropertiesFromPropertyBundles.id,
                          name: eventWithResolvedPropertiesFromPropertyBundles.name,
                          uniqueName: eventWithResolvedPropertiesFromPropertyBundles.uniqueName,
                          description: eventWithResolvedPropertiesFromPropertyBundles.description,
                          directPropertyRefs: eventWithResolvedPropertiesFromPropertyBundles.directPropertyRefs,
                          propertyBundles: Belt_List.keepU($$event.propertyBundles, (function (propertyBundleRef) {
                                  return ModelUtils.isPropertyBundleConsistentAcrossAllEventsWithCache(sourceIds, improvedPropertyBundlesUnfolding, propertyBundleRef.id, isPropertyGroupConsistentAcrossAllEventsCache, model);
                                })),
                          variants: eventWithResolvedPropertiesFromPropertyBundles.variants,
                          types: eventWithResolvedPropertiesFromPropertyBundles.types,
                          tags: eventWithResolvedPropertiesFromPropertyBundles.tags,
                          excludeSourcesDeprecated: eventWithResolvedPropertiesFromPropertyBundles.excludeSourcesDeprecated,
                          includeSources: eventWithResolvedPropertiesFromPropertyBundles.includeSources,
                          includeDestinations: eventWithResolvedPropertiesFromPropertyBundles.includeDestinations,
                          hashes: eventWithResolvedPropertiesFromPropertyBundles.hashes,
                          propertyWhitelist: eventWithResolvedPropertiesFromPropertyBundles.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: eventWithResolvedPropertiesFromPropertyBundles.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: eventWithResolvedPropertiesFromPropertyBundles.userGroupTypeIdsWithArchive,
                          triggers: eventWithResolvedPropertiesFromPropertyBundles.triggers,
                          globalRequirementsMetadata: eventWithResolvedPropertiesFromPropertyBundles.globalRequirementsMetadata
                        };
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

var currencies = [
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BOV",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHE",
  "CHF",
  "CHW",
  "CLF",
  "CLP",
  "CNY",
  "COP",
  "COU",
  "CRC",
  "CUC",
  "CUP",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ERN",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "GBP",
  "GEL",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KMF",
  "KPW",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRU",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MXV",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SDG",
  "SEK",
  "SGD",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "SSP",
  "STN",
  "SVC",
  "SYP",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USD",
  "USN",
  "UYI",
  "UYU",
  "UZS",
  "VEF",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XAG",
  "XAU",
  "XBA",
  "XBB",
  "XBC",
  "XBD",
  "XCD",
  "XDR",
  "XOF",
  "XPD",
  "XPF",
  "XPT",
  "XSU",
  "XTS",
  "XUA",
  "XXX",
  "YER",
  "ZAR",
  "ZMW",
  "ZWL"
];

function enrichModel(model, sources) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: Belt_List.mapU(model.events, (function ($$event) {
                  var __x = resolveDestinations(model, BeltListExtensions.dedupeString(BeltListExtensions.flatMap($$event.includeDestinations, (function (x) {
                                  var match = x.VAL;
                                  var sourceId = match[0];
                                  if (Belt_List.some($$event.includeSources, (function (includedSource) {
                                            return includedSource.id === sourceId;
                                          })) && Belt_List.some(sources, (function (source) {
                                            return source.id === sourceId;
                                          }))) {
                                    return match[1];
                                  } else {
                                    return /* [] */0;
                                  }
                                }))));
                  var destinations = AvoConfig.getValidDestinations(model.sources, __x);
                  var $$event$1 = Belt_List.someU($$event.types, (function (t) {
                          return t === "Revenue";
                        })) && AvoConfig.hasAnalyticsTool(destinations, "Segment") ? ({
                        id: $$event.id,
                        name: $$event.name,
                        uniqueName: $$event.uniqueName,
                        description: $$event.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-type-revenue-segment-revenue",
                              name: "Segment: Revenue",
                              uniqueName: undefined,
                              description: "Amount of revenue an event resulted in",
                              type_: "float",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Revenue",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "Segment",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: false,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: "AlwaysSent",
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: {
                            hd: {
                              TAG: "Property",
                              _0: {
                                id: "avo-enriched-type-revenue-segment-currency",
                                name: "Segment: Currency",
                                uniqueName: undefined,
                                description: "The currency the user was charged in",
                                type_: "string",
                                sendAs: "EventProperty",
                                validations: {
                                  hd: {
                                    NAME: "Matches",
                                    VAL: Belt_List.fromArray(Belt_Array.map(currencies, (function (currency) {
                                                return [
                                                        {
                                                          NAME: "Literal",
                                                          VAL: {
                                                            NAME: "StringLit",
                                                            VAL: currency
                                                          }
                                                        },
                                                        /* [] */0
                                                      ];
                                              })))
                                  },
                                  tl: /* [] */0
                                },
                                eventSpecificAllowedPropertyValues: Belt_Array.map(currencies, (function (currency) {
                                        return [
                                                {
                                                  NAME: "StringLit",
                                                  VAL: currency
                                                },
                                                undefined
                                              ];
                                      })),
                                auto: false,
                                builtIn: "Revenue",
                                virtualForAllowedValues: false,
                                analyticsTools: {
                                  hd: "Segment",
                                  tl: /* [] */0
                                },
                                devPlatforms: /* [] */0,
                                list: false,
                                operation: "Set",
                                optionalDeprecated: true,
                                excludedSourcesDeprecated: /* [] */0,
                                optionalWhenInObject: false,
                                absence: {
                                  TAG: "SometimesSent",
                                  _0: ""
                                },
                                globalRequirementsMetadata: undefined
                              }
                            },
                            tl: $$event.directPropertyRefs
                          }
                        },
                        propertyBundles: $$event.propertyBundles,
                        variants: $$event.variants,
                        types: $$event.types,
                        tags: $$event.tags,
                        excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                        includeSources: $$event.includeSources,
                        includeDestinations: $$event.includeDestinations,
                        hashes: $$event.hashes,
                        propertyWhitelist: $$event.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                        triggers: $$event.triggers,
                        globalRequirementsMetadata: $$event.globalRequirementsMetadata
                      }) : $$event;
                  var $$event$2 = Belt_List.someU($$event$1.types, (function (t) {
                          return t === "Revenue";
                        })) && AvoConfig.hasAnalyticsTool(destinations, "FacebookAnalytics") ? ({
                        id: $$event$1.id,
                        name: $$event$1.name,
                        uniqueName: $$event$1.uniqueName,
                        description: $$event$1.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-type-revenue-facebook-analytics-price",
                              name: "Facebook Analytics: Purchace Amount",
                              uniqueName: undefined,
                              description: "The amount of money charged to the current user.",
                              type_: "float",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Revenue",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "FacebookAnalytics",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: false,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: "AlwaysSent",
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: {
                            hd: {
                              TAG: "Property",
                              _0: {
                                id: "avo-enriched-type-revenue-facebook-analytics-currency",
                                name: "Facebook Analytics: Currency",
                                uniqueName: undefined,
                                description: "The currency the user was charged in",
                                type_: "string",
                                sendAs: "EventProperty",
                                validations: {
                                  hd: {
                                    NAME: "Matches",
                                    VAL: Belt_List.fromArray(Belt_Array.map(currencies, (function (currency) {
                                                return [
                                                        {
                                                          NAME: "Literal",
                                                          VAL: {
                                                            NAME: "StringLit",
                                                            VAL: currency
                                                          }
                                                        },
                                                        /* [] */0
                                                      ];
                                              })))
                                  },
                                  tl: /* [] */0
                                },
                                eventSpecificAllowedPropertyValues: Belt_Array.map(currencies, (function (currency) {
                                        return [
                                                {
                                                  NAME: "StringLit",
                                                  VAL: currency
                                                },
                                                undefined
                                              ];
                                      })),
                                auto: false,
                                builtIn: "Revenue",
                                virtualForAllowedValues: false,
                                analyticsTools: {
                                  hd: "FacebookAnalytics",
                                  tl: /* [] */0
                                },
                                devPlatforms: /* [] */0,
                                list: false,
                                operation: "Set",
                                optionalDeprecated: false,
                                excludedSourcesDeprecated: /* [] */0,
                                optionalWhenInObject: false,
                                absence: "AlwaysSent",
                                globalRequirementsMetadata: undefined
                              }
                            },
                            tl: $$event$1.directPropertyRefs
                          }
                        },
                        propertyBundles: $$event$1.propertyBundles,
                        variants: $$event$1.variants,
                        types: $$event$1.types,
                        tags: $$event$1.tags,
                        excludeSourcesDeprecated: $$event$1.excludeSourcesDeprecated,
                        includeSources: $$event$1.includeSources,
                        includeDestinations: $$event$1.includeDestinations,
                        hashes: $$event$1.hashes,
                        propertyWhitelist: $$event$1.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event$1.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event$1.userGroupTypeIdsWithArchive,
                        triggers: $$event$1.triggers,
                        globalRequirementsMetadata: $$event$1.globalRequirementsMetadata
                      }) : $$event$1;
                  var $$event$3 = Belt_List.someU($$event$2.types, (function (t) {
                          return t === "Page";
                        })) && AvoConfig.hasAnalyticsTool(destinations, "Segment") ? ({
                        id: $$event$2.id,
                        name: $$event$2.name,
                        uniqueName: $$event$2.uniqueName,
                        description: $$event$2.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-type-page-segment-name",
                              name: "Segment: Page Name",
                              uniqueName: undefined,
                              description: "Name of the page.",
                              type_: "string",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: true,
                              builtIn: "Page",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "Segment",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: false,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: "AlwaysSent",
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: $$event$2.directPropertyRefs
                        },
                        propertyBundles: $$event$2.propertyBundles,
                        variants: $$event$2.variants,
                        types: $$event$2.types,
                        tags: $$event$2.tags,
                        excludeSourcesDeprecated: $$event$2.excludeSourcesDeprecated,
                        includeSources: $$event$2.includeSources,
                        includeDestinations: $$event$2.includeDestinations,
                        hashes: $$event$2.hashes,
                        propertyWhitelist: $$event$2.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event$2.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event$2.userGroupTypeIdsWithArchive,
                        triggers: $$event$2.triggers,
                        globalRequirementsMetadata: $$event$2.globalRequirementsMetadata
                      }) : $$event$2;
                  var $$event$4 = Belt_List.someU($$event$3.types, (function (t) {
                          return t === "Page";
                        })) && AvoConfig.hasAnalyticsTool(destinations, "MParticle") ? ({
                        id: $$event$3.id,
                        name: $$event$3.name,
                        uniqueName: $$event$3.uniqueName,
                        description: $$event$3.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-type-page-mparticle-name",
                              name: "mParticle: Page Name",
                              uniqueName: undefined,
                              description: "Name of the page.",
                              type_: "string",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Page",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "MParticle",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: false,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: "AlwaysSent",
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: $$event$3.directPropertyRefs
                        },
                        propertyBundles: $$event$3.propertyBundles,
                        variants: $$event$3.variants,
                        types: $$event$3.types,
                        tags: $$event$3.tags,
                        excludeSourcesDeprecated: $$event$3.excludeSourcesDeprecated,
                        includeSources: $$event$3.includeSources,
                        includeDestinations: $$event$3.includeDestinations,
                        hashes: $$event$3.hashes,
                        propertyWhitelist: $$event$3.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event$3.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event$3.userGroupTypeIdsWithArchive,
                        triggers: $$event$3.triggers,
                        globalRequirementsMetadata: $$event$3.globalRequirementsMetadata
                      }) : $$event$3;
                  var $$event$5 = Belt_List.someU($$event$4.types, (function (t) {
                          return t === "Page";
                        })) && AvoConfig.hasAnalyticsTool(destinations, "Snowplow") ? ({
                        id: $$event$4.id,
                        name: $$event$4.name,
                        uniqueName: $$event$4.uniqueName,
                        description: $$event$4.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-type-page-snowplow-name",
                              name: "Snowplow: Page Name",
                              uniqueName: undefined,
                              description: "Name of the page.",
                              type_: "string",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Page",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "Snowplow",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: false,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: "AlwaysSent",
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: $$event$4.directPropertyRefs
                        },
                        propertyBundles: $$event$4.propertyBundles,
                        variants: $$event$4.variants,
                        types: $$event$4.types,
                        tags: $$event$4.tags,
                        excludeSourcesDeprecated: $$event$4.excludeSourcesDeprecated,
                        includeSources: $$event$4.includeSources,
                        includeDestinations: $$event$4.includeDestinations,
                        hashes: $$event$4.hashes,
                        propertyWhitelist: $$event$4.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event$4.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event$4.userGroupTypeIdsWithArchive,
                        triggers: $$event$4.triggers,
                        globalRequirementsMetadata: $$event$4.globalRequirementsMetadata
                      }) : $$event$4;
                  var $$event$6 = Belt_List.someU($$event$5.types, (function (t) {
                          return t === "Revenue";
                        })) && AvoConfig.hasAnalyticsTool(destinations, "MParticle") ? ({
                        id: $$event$5.id,
                        name: $$event$5.name,
                        uniqueName: $$event$5.uniqueName,
                        description: $$event$5.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-type-revenue-mparticle-productName",
                              name: "mParticle: Product Name",
                              uniqueName: undefined,
                              description: "A human readable name of the product.",
                              type_: "string",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Revenue",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "MParticle",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: true,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: {
                                TAG: "SometimesSent",
                                _0: ""
                              },
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: {
                            hd: {
                              TAG: "Property",
                              _0: {
                                id: "avo-enriched-type-revenue-mparticle-productId",
                                name: "mParticle: Product Id",
                                uniqueName: undefined,
                                description: "An identifier for the product (we recommend something like the Google Play Store product ID).",
                                type_: "string",
                                sendAs: "EventProperty",
                                validations: /* [] */0,
                                eventSpecificAllowedPropertyValues: [],
                                auto: false,
                                builtIn: "Revenue",
                                virtualForAllowedValues: false,
                                analyticsTools: {
                                  hd: "MParticle",
                                  tl: /* [] */0
                                },
                                devPlatforms: /* [] */0,
                                list: false,
                                operation: "Set",
                                optionalDeprecated: true,
                                excludedSourcesDeprecated: /* [] */0,
                                optionalWhenInObject: false,
                                absence: {
                                  TAG: "SometimesSent",
                                  _0: ""
                                },
                                globalRequirementsMetadata: undefined
                              }
                            },
                            tl: {
                              hd: {
                                TAG: "Property",
                                _0: {
                                  id: "avo-enriched-type-revenue-mparticle-quantity",
                                  name: "mParticle: Quantity",
                                  uniqueName: undefined,
                                  description: "The quantity of products purchased. Defaults to one if not specified. Note: revenue = quantity * price.",
                                  type_: "int",
                                  sendAs: "EventProperty",
                                  validations: /* [] */0,
                                  eventSpecificAllowedPropertyValues: [],
                                  auto: false,
                                  builtIn: "Revenue",
                                  virtualForAllowedValues: false,
                                  analyticsTools: {
                                    hd: "MParticle",
                                    tl: /* [] */0
                                  },
                                  devPlatforms: /* [] */0,
                                  list: false,
                                  operation: "Set",
                                  optionalDeprecated: false,
                                  excludedSourcesDeprecated: /* [] */0,
                                  optionalWhenInObject: false,
                                  absence: "AlwaysSent",
                                  globalRequirementsMetadata: undefined
                                }
                              },
                              tl: {
                                hd: {
                                  TAG: "Property",
                                  _0: {
                                    id: "avo-enriched-type-revenue-mparticle-price",
                                    name: "mParticle: Price",
                                    uniqueName: undefined,
                                    description: "The price of the products purchased (can be negative). Note: revenue = quantity * price.",
                                    type_: "float",
                                    sendAs: "EventProperty",
                                    validations: /* [] */0,
                                    eventSpecificAllowedPropertyValues: [],
                                    auto: false,
                                    builtIn: "Revenue",
                                    virtualForAllowedValues: false,
                                    analyticsTools: {
                                      hd: "MParticle",
                                      tl: /* [] */0
                                    },
                                    devPlatforms: /* [] */0,
                                    list: false,
                                    operation: "Set",
                                    optionalDeprecated: false,
                                    excludedSourcesDeprecated: /* [] */0,
                                    optionalWhenInObject: false,
                                    absence: "AlwaysSent",
                                    globalRequirementsMetadata: undefined
                                  }
                                },
                                tl: $$event$5.directPropertyRefs
                              }
                            }
                          }
                        },
                        propertyBundles: $$event$5.propertyBundles,
                        variants: $$event$5.variants,
                        types: $$event$5.types,
                        tags: $$event$5.tags,
                        excludeSourcesDeprecated: $$event$5.excludeSourcesDeprecated,
                        includeSources: $$event$5.includeSources,
                        includeDestinations: $$event$5.includeDestinations,
                        hashes: $$event$5.hashes,
                        propertyWhitelist: $$event$5.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event$5.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event$5.userGroupTypeIdsWithArchive,
                        triggers: $$event$5.triggers,
                        globalRequirementsMetadata: $$event$5.globalRequirementsMetadata
                      }) : $$event$5;
                  var $$event$7 = Belt_List.someU($$event$6.types, (function (t) {
                          return t === "Revenue";
                        })) && AvoConfig.hasAnalyticsTool(destinations, "Mixpanel") ? ({
                        id: $$event$6.id,
                        name: $$event$6.name,
                        uniqueName: $$event$6.uniqueName,
                        description: $$event$6.description,
                        directPropertyRefs: {
                          hd: {
                            TAG: "Property",
                            _0: {
                              id: "avo-enriched-type-revenue-mixpanel-amount",
                              name: "Mixpanel: Amount",
                              uniqueName: undefined,
                              description: "The amount of money charged to the current user.",
                              type_: "float",
                              sendAs: "EventProperty",
                              validations: /* [] */0,
                              eventSpecificAllowedPropertyValues: [],
                              auto: false,
                              builtIn: "Revenue",
                              virtualForAllowedValues: false,
                              analyticsTools: {
                                hd: "Mixpanel",
                                tl: /* [] */0
                              },
                              devPlatforms: /* [] */0,
                              list: false,
                              operation: "Set",
                              optionalDeprecated: false,
                              excludedSourcesDeprecated: /* [] */0,
                              optionalWhenInObject: false,
                              absence: "AlwaysSent",
                              globalRequirementsMetadata: undefined
                            }
                          },
                          tl: $$event$6.directPropertyRefs
                        },
                        propertyBundles: $$event$6.propertyBundles,
                        variants: $$event$6.variants,
                        types: $$event$6.types,
                        tags: $$event$6.tags,
                        excludeSourcesDeprecated: $$event$6.excludeSourcesDeprecated,
                        includeSources: $$event$6.includeSources,
                        includeDestinations: $$event$6.includeDestinations,
                        hashes: $$event$6.hashes,
                        propertyWhitelist: $$event$6.propertyWhitelist,
                        eventGroupTypeIdsWithArchive: $$event$6.eventGroupTypeIdsWithArchive,
                        userGroupTypeIdsWithArchive: $$event$6.userGroupTypeIdsWithArchive,
                        triggers: $$event$6.triggers,
                        globalRequirementsMetadata: $$event$6.globalRequirementsMetadata
                      }) : $$event$6;
                  if (Belt_List.someU($$event$7.types, (function (t) {
                            return t === "Revenue";
                          })) && AvoConfig.hasAnalyticsTool(destinations, "Amplitude")) {
                    return {
                            id: $$event$7.id,
                            name: $$event$7.name,
                            uniqueName: $$event$7.uniqueName,
                            description: $$event$7.description,
                            directPropertyRefs: {
                              hd: {
                                TAG: "Property",
                                _0: {
                                  id: "avo-enriched-type-revenue-amplitude-productId",
                                  name: "Amplitude: Product Id",
                                  uniqueName: undefined,
                                  description: "An identifier for the product (we recommend something like the Google Play Store product ID).",
                                  type_: "string",
                                  sendAs: "EventProperty",
                                  validations: /* [] */0,
                                  eventSpecificAllowedPropertyValues: [],
                                  auto: false,
                                  builtIn: "Revenue",
                                  virtualForAllowedValues: false,
                                  analyticsTools: {
                                    hd: "Amplitude",
                                    tl: /* [] */0
                                  },
                                  devPlatforms: /* [] */0,
                                  list: false,
                                  operation: "Set",
                                  optionalDeprecated: true,
                                  excludedSourcesDeprecated: /* [] */0,
                                  optionalWhenInObject: false,
                                  absence: {
                                    TAG: "SometimesSent",
                                    _0: ""
                                  },
                                  globalRequirementsMetadata: undefined
                                }
                              },
                              tl: {
                                hd: {
                                  TAG: "Property",
                                  _0: {
                                    id: "avo-enriched-type-revenue-amplitude-quantity",
                                    name: "Amplitude: Quantity",
                                    uniqueName: undefined,
                                    description: "The quantity of products purchased. Defaults to one if not specified. Note: revenue = quantity * price.",
                                    type_: "int",
                                    sendAs: "EventProperty",
                                    validations: /* [] */0,
                                    eventSpecificAllowedPropertyValues: [],
                                    auto: false,
                                    builtIn: "Revenue",
                                    virtualForAllowedValues: false,
                                    analyticsTools: {
                                      hd: "Amplitude",
                                      tl: /* [] */0
                                    },
                                    devPlatforms: /* [] */0,
                                    list: false,
                                    operation: "Set",
                                    optionalDeprecated: false,
                                    excludedSourcesDeprecated: /* [] */0,
                                    optionalWhenInObject: false,
                                    absence: "AlwaysSent",
                                    globalRequirementsMetadata: undefined
                                  }
                                },
                                tl: {
                                  hd: {
                                    TAG: "Property",
                                    _0: {
                                      id: "avo-enriched-type-revenue-amplitude-price",
                                      name: "Amplitude: Price",
                                      uniqueName: undefined,
                                      description: "The price of the products purchased (can be negative). Note: revenue = quantity * price.",
                                      type_: "float",
                                      sendAs: "EventProperty",
                                      validations: /* [] */0,
                                      eventSpecificAllowedPropertyValues: [],
                                      auto: false,
                                      builtIn: "Revenue",
                                      virtualForAllowedValues: false,
                                      analyticsTools: {
                                        hd: "Amplitude",
                                        tl: /* [] */0
                                      },
                                      devPlatforms: /* [] */0,
                                      list: false,
                                      operation: "Set",
                                      optionalDeprecated: false,
                                      excludedSourcesDeprecated: /* [] */0,
                                      optionalWhenInObject: false,
                                      absence: "AlwaysSent",
                                      globalRequirementsMetadata: undefined
                                    }
                                  },
                                  tl: {
                                    hd: {
                                      TAG: "Property",
                                      _0: {
                                        id: "avo-enriched-type-revenue-amplitude-revenueType",
                                        name: "Amplitude: Revenue Type",
                                        uniqueName: undefined,
                                        description: "The type of revenue (e.g. tax, refund, income).",
                                        type_: "string",
                                        sendAs: "EventProperty",
                                        validations: /* [] */0,
                                        eventSpecificAllowedPropertyValues: [],
                                        auto: false,
                                        builtIn: "Revenue",
                                        virtualForAllowedValues: false,
                                        analyticsTools: {
                                          hd: "Amplitude",
                                          tl: /* [] */0
                                        },
                                        devPlatforms: /* [] */0,
                                        list: false,
                                        operation: "Set",
                                        optionalDeprecated: true,
                                        excludedSourcesDeprecated: /* [] */0,
                                        optionalWhenInObject: false,
                                        absence: {
                                          TAG: "SometimesSent",
                                          _0: ""
                                        },
                                        globalRequirementsMetadata: undefined
                                      }
                                    },
                                    tl: $$event$7.directPropertyRefs
                                  }
                                }
                              }
                            },
                            propertyBundles: $$event$7.propertyBundles,
                            variants: $$event$7.variants,
                            types: $$event$7.types,
                            tags: $$event$7.tags,
                            excludeSourcesDeprecated: $$event$7.excludeSourcesDeprecated,
                            includeSources: $$event$7.includeSources,
                            includeDestinations: $$event$7.includeDestinations,
                            hashes: $$event$7.hashes,
                            propertyWhitelist: $$event$7.propertyWhitelist,
                            eventGroupTypeIdsWithArchive: $$event$7.eventGroupTypeIdsWithArchive,
                            userGroupTypeIdsWithArchive: $$event$7.userGroupTypeIdsWithArchive,
                            triggers: $$event$7.triggers,
                            globalRequirementsMetadata: $$event$7.globalRequirementsMetadata
                          };
                  } else {
                    return $$event$7;
                  }
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function softEnrichModel(model, sources) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: Belt_List.mapU(model.events, (function ($$event) {
                  var __x = resolveDestinations(model, BeltListExtensions.dedupeString(BeltListExtensions.flatMap($$event.includeDestinations, (function (x) {
                                  var match = x.VAL;
                                  var sourceId = match[0];
                                  if (Belt_List.some($$event.includeSources, (function (includeSource) {
                                            return includeSource.id === sourceId;
                                          })) && Belt_List.some(sources, (function (source) {
                                            return source.id === sourceId;
                                          }))) {
                                    return match[1];
                                  } else {
                                    return /* [] */0;
                                  }
                                }))));
                  var destinations = AvoConfig.getValidDestinations(model.sources, __x);
                  if (Belt_List.someU($$event.types, (function (t) {
                            return t === "Page";
                          })) && AvoConfig.hasAnalyticsTool(destinations, "Segment") && AvoConfig.hasDevPlatform(sources, "Web")) {
                    return {
                            id: $$event.id,
                            name: $$event.name,
                            uniqueName: $$event.uniqueName,
                            description: $$event.description,
                            directPropertyRefs: {
                              hd: {
                                TAG: "Property",
                                _0: {
                                  id: "avo-enriched-type-page-segment-title",
                                  name: "title",
                                  uniqueName: undefined,
                                  description: "Automatically sent from all web sources to Segment. Title of the page. Equivalent to document.title from the DOM API.",
                                  type_: "string",
                                  sendAs: "EventProperty",
                                  validations: /* [] */0,
                                  eventSpecificAllowedPropertyValues: [],
                                  auto: true,
                                  builtIn: "Page",
                                  virtualForAllowedValues: false,
                                  analyticsTools: {
                                    hd: "Segment",
                                    tl: /* [] */0
                                  },
                                  devPlatforms: {
                                    hd: "Web",
                                    tl: /* [] */0
                                  },
                                  list: false,
                                  operation: "Set",
                                  optionalDeprecated: false,
                                  excludedSourcesDeprecated: /* [] */0,
                                  optionalWhenInObject: false,
                                  absence: "AlwaysSent",
                                  globalRequirementsMetadata: undefined
                                }
                              },
                              tl: {
                                hd: {
                                  TAG: "Property",
                                  _0: {
                                    id: "avo-enriched-type-page-segment-path",
                                    name: "path",
                                    uniqueName: undefined,
                                    description: "Automatically sent from all web sources to Segment. Path portion of the URL of the page. Equivalent to canonical path which defaults to location.pathname from the DOM API.",
                                    type_: "string",
                                    sendAs: "EventProperty",
                                    validations: /* [] */0,
                                    eventSpecificAllowedPropertyValues: [],
                                    auto: true,
                                    builtIn: "Page",
                                    virtualForAllowedValues: false,
                                    analyticsTools: {
                                      hd: "Segment",
                                      tl: /* [] */0
                                    },
                                    devPlatforms: {
                                      hd: "Web",
                                      tl: /* [] */0
                                    },
                                    list: false,
                                    operation: "Set",
                                    optionalDeprecated: false,
                                    excludedSourcesDeprecated: /* [] */0,
                                    optionalWhenInObject: false,
                                    absence: "AlwaysSent",
                                    globalRequirementsMetadata: undefined
                                  }
                                },
                                tl: {
                                  hd: {
                                    TAG: "Property",
                                    _0: {
                                      id: "avo-enriched-type-page-segment-url",
                                      name: "url",
                                      uniqueName: undefined,
                                      description: "Automatically sent from all web sources to Segment. Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href from the DOM API.",
                                      type_: "string",
                                      sendAs: "EventProperty",
                                      validations: /* [] */0,
                                      eventSpecificAllowedPropertyValues: [],
                                      auto: true,
                                      builtIn: "Page",
                                      virtualForAllowedValues: false,
                                      analyticsTools: {
                                        hd: "Segment",
                                        tl: /* [] */0
                                      },
                                      devPlatforms: {
                                        hd: "Web",
                                        tl: /* [] */0
                                      },
                                      list: false,
                                      operation: "Set",
                                      optionalDeprecated: false,
                                      excludedSourcesDeprecated: /* [] */0,
                                      optionalWhenInObject: false,
                                      absence: "AlwaysSent",
                                      globalRequirementsMetadata: undefined
                                    }
                                  },
                                  tl: {
                                    hd: {
                                      TAG: "Property",
                                      _0: {
                                        id: "avo-enriched-type-page-segment-referrer",
                                        name: "referrer",
                                        uniqueName: undefined,
                                        description: "Automatically sent from all web sources to Segment. Full URL of the previous page. Equivalent to document.referrer from the DOM API.",
                                        type_: "string",
                                        sendAs: "EventProperty",
                                        validations: /* [] */0,
                                        eventSpecificAllowedPropertyValues: [],
                                        auto: true,
                                        builtIn: "Page",
                                        virtualForAllowedValues: false,
                                        analyticsTools: {
                                          hd: "Segment",
                                          tl: /* [] */0
                                        },
                                        devPlatforms: {
                                          hd: "Web",
                                          tl: /* [] */0
                                        },
                                        list: false,
                                        operation: "Set",
                                        optionalDeprecated: false,
                                        excludedSourcesDeprecated: /* [] */0,
                                        optionalWhenInObject: false,
                                        absence: "AlwaysSent",
                                        globalRequirementsMetadata: undefined
                                      }
                                    },
                                    tl: {
                                      hd: {
                                        TAG: "Property",
                                        _0: {
                                          id: "avo-enriched-type-page-segment-search",
                                          name: "search",
                                          uniqueName: undefined,
                                          description: "Automatically sent from all web sources to Segment. Query string portion of the URL of the page. Equivalent to location.search from the DOM API.",
                                          type_: "string",
                                          sendAs: "EventProperty",
                                          validations: /* [] */0,
                                          eventSpecificAllowedPropertyValues: [],
                                          auto: true,
                                          builtIn: "Page",
                                          virtualForAllowedValues: false,
                                          analyticsTools: {
                                            hd: "Segment",
                                            tl: /* [] */0
                                          },
                                          devPlatforms: {
                                            hd: "Web",
                                            tl: /* [] */0
                                          },
                                          list: false,
                                          operation: "Set",
                                          optionalDeprecated: false,
                                          excludedSourcesDeprecated: /* [] */0,
                                          optionalWhenInObject: false,
                                          absence: "AlwaysSent",
                                          globalRequirementsMetadata: undefined
                                        }
                                      },
                                      tl: $$event.directPropertyRefs
                                    }
                                  }
                                }
                              }
                            },
                            propertyBundles: $$event.propertyBundles,
                            variants: $$event.variants,
                            types: $$event.types,
                            tags: $$event.tags,
                            excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                            includeSources: $$event.includeSources,
                            includeDestinations: $$event.includeDestinations,
                            hashes: $$event.hashes,
                            propertyWhitelist: $$event.propertyWhitelist,
                            eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                            userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                            triggers: $$event.triggers,
                            globalRequirementsMetadata: $$event.globalRequirementsMetadata
                          };
                  } else {
                    return $$event;
                  }
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function getAllPropertiesAvailableOnEventForMetricView(model, $$event) {
  var eventProps = Belt_List.keep(Belt_List.keepMap($$event.directPropertyRefs, (function (param) {
              return resolveProperty(model, param);
            })), (function (prop) {
          return prop.sendAs === "EventProperty";
        }));
  var propGroupProps = BeltListExtensions.dedupeOrdered(Belt_List.keepMap(BeltListExtensions.flatMap(ModelUtils.resolvePropertyBundleRefs(model, $$event.propertyBundles), (function (group) {
                  return group.properties;
                })), (function (propertyId) {
              return Belt_List.head(Belt_List.keepMap(model.properties, (function (x) {
                                if (x.TAG === "PropertyRef") {
                                  return ;
                                }
                                var property = x._0;
                                if (propertyId === property.id) {
                                  return property;
                                }
                                
                              })));
            })), (function (property) {
          return property.id;
        }));
  var userProps = Belt_List.keepMap(model.properties, (function (x) {
          if (x.TAG === "PropertyRef") {
            return ;
          }
          var property = x._0;
          if (property.sendAs === "UserProperty") {
            return property;
          }
          
        }));
  var systemProps = Belt_List.keepMap(model.properties, (function (x) {
          if (x.TAG === "PropertyRef") {
            return ;
          }
          var property = x._0;
          if (property.sendAs === "SystemProperty") {
            return property;
          }
          
        }));
  return Belt_List.concatMany([
              eventProps,
              propGroupProps,
              userProps,
              systemProps
            ]);
}

function getResolvedPropertiesForEvent(model, $$event) {
  var eventPropertyBundles = Belt_List.keepMapU(Belt_List.keepMapU(BeltListExtensions.flatMapU(resolvePropertyBundleRefs(model.propertyBundles, $$event.propertyBundles), (function (group) {
                  return group.properties;
                })), (function (propertyId) {
              return ModelUtils.getPropertyById(propertyId, model);
            })), (function (property) {
          if (property.TAG === "PropertyRef") {
            return ;
          } else {
            return property._0;
          }
        }));
  var systemProps = Belt_List.keepMap(model.properties, (function (property) {
          if (property.TAG === "PropertyRef") {
            return ;
          }
          var property$1 = property._0;
          if (property$1.sendAs === "SystemProperty") {
            return property$1;
          }
          
        }));
  var eventProperties = Belt_List.keep(Belt_List.keepMap($$event.directPropertyRefs, (function (param) {
              return resolveProperty(model, param);
            })), (function (property) {
          if (property.sendAs === "EventProperty") {
            return !Belt_List.some(eventPropertyBundles, (function (bundleProperty) {
                          return bundleProperty.id === property.id;
                        }));
          } else {
            return false;
          }
        }));
  return Belt_List.concatMany([
              eventPropertyBundles,
              eventProperties,
              systemProps
            ]);
}

function getResolvedPropertiesForEventWithPropertyMap(modelPropertyBundles, propertyMap, $$event) {
  var eventPropertyBundles = Belt_List.toArray(Belt_List.keepMapU(BeltListExtensions.flatMapU(resolvePropertyBundleRefs(modelPropertyBundles, $$event.propertyBundles), (function (group) {
                  return group.properties;
                })), (function (propertyId) {
              return Belt_MapString.get(propertyMap, propertyId);
            })));
  var systemProps = Belt_Array.keepU(Belt_MapString.valuesToArray(propertyMap), (function (property) {
          return property.sendAs === "SystemProperty";
        }));
  var eventProperties = Belt_List.toArray(Belt_List.keepMapU(Belt_List.mapU($$event.directPropertyRefs, (function (property) {
                  return property._0.id;
                })), (function (propertyId) {
              var property = Belt_MapString.get(propertyMap, propertyId);
              if (property === undefined) {
                return ;
              }
              var id = property.id;
              var tmp = property.sendAs;
              if (typeof tmp === "object") {
                return property;
              }
              switch (tmp) {
                case "SystemProperty" :
                    return ;
                case "EventProperty" :
                    if (!Belt_Array.some(eventPropertyBundles, (function (bundleProperty) {
                              return bundleProperty.id === id;
                            }))) {
                      return property;
                    } else {
                      return ;
                    }
                case "UserProperty" :
                    return property;
                
              }
            })));
  return Belt_Array.concatMany([
              eventPropertyBundles,
              eventProperties,
              systemProps
            ]);
}

function getResolvedPropertiesForEvent_mappedModel(propertyBundles, properties, $$event) {
  var eventPropertyBundleProperties = Belt_Array.keepMapU(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToArray, resolvePropertyBundleRefs_mappedModel(propertyBundles, Belt_Array.mapU(Belt_List.toArray($$event.propertyBundles), (function (param) {
                                  return param.id;
                                }))), (function (param) {
                          return Belt_List.toArray(param.properties);
                        }))))), (function (propertyId) {
          return Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId);
        }));
  var systemProps = Curry._1(TrackingPlanMappedModel.Properties.toArray, Curry._2(TrackingPlanMappedModel.Properties.keep, properties, (function (param) {
              return param.sendAs === "SystemProperty";
            })));
  var eventProperties = Belt_List.toArray(Belt_List.keepMapU(Belt_List.mapU($$event.directPropertyRefs, (function (property) {
                  return property._0.id;
                })), (function (propertyId) {
              var property = Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId);
              if (property === undefined) {
                return ;
              }
              var tmp = property.sendAs;
              if (typeof tmp === "object") {
                return ;
              }
              if (tmp !== "EventProperty") {
                return ;
              }
              var id = property.id;
              if (!Belt_Array.some(eventPropertyBundleProperties, (function (bundleProperty) {
                        return bundleProperty.id === id;
                      }))) {
                return property;
              }
              
            })));
  return Belt_Array.concatMany([
              eventPropertyBundleProperties,
              eventProperties,
              systemProps
            ]);
}

var binOpToJs = TrackingPlanModel.binOpToJs;

var binOpFromJs = TrackingPlanModel.binOpFromJs;

var encodeBinOp = TrackingPlanModel.encodeBinOp;

var Type = TrackingPlanModel.Type;

var PropertyRef = TrackingPlanModel.PropertyRef;

var EventSpecificPropertyValueActions = TrackingPlanModel.EventSpecificPropertyValueActions;

var decodeType = TrackingPlanModel.decodeType;

var DataType = TrackingPlanModel.DataType;

var builtInTypesToJs = TrackingPlanModel.builtInTypesToJs;

var builtInTypesFromJs = TrackingPlanModel.builtInTypesFromJs;

var operationToJs = TrackingPlanModel.operationToJs;

var operationFromJs = TrackingPlanModel.operationFromJs;

var propertyAbsenceAllEventsKey = TrackingPlanModel.propertyAbsenceAllEventsKey;

var GlobalRequirementsMetadata = TrackingPlanModel.GlobalRequirementsMetadata;

var Property = TrackingPlanModel.Property;

var PropertyBundle = TrackingPlanModel.PropertyBundle;

var PropertyBundleRef = TrackingPlanModel.PropertyBundleRef;

var eventTypeToJs = TrackingPlanModel.eventTypeToJs;

var eventTypeFromJs = TrackingPlanModel.eventTypeFromJs;

var eventTypeToCamelCase = TrackingPlanModel.eventTypeToCamelCase;

var eventTypes = TrackingPlanModel.eventTypes;

var Trigger = TrackingPlanModel.Trigger;

var EventVariant = TrackingPlanModel.EventVariant;

var migrationToJs = TrackingPlanModel.migrationToJs;

var migrationFromJs = TrackingPlanModel.migrationFromJs;

var constraint_ToJs = TrackingPlanModel.constraint_ToJs;

var constraint_FromJs = TrackingPlanModel.constraint_FromJs;

var Source = TrackingPlanModel.Source;

var Destination = TrackingPlanModel.Destination;

var integrationTypeToJs = TrackingPlanModel.integrationTypeToJs;

var integrationTypeFromJs = TrackingPlanModel.integrationTypeFromJs;

var integrationTypeHumanReadableName = TrackingPlanModel.integrationTypeHumanReadableName;

var integrationTypes = TrackingPlanModel.integrationTypes;

var ProtocolsIntegration = TrackingPlanModel.ProtocolsIntegration;

var RudderstackTrackingPlansIntegration = TrackingPlanModel.RudderstackTrackingPlansIntegration;

var RudderstackDataCatalogIntegration = TrackingPlanModel.RudderstackDataCatalogIntegration;

var MParticleDataMasterIntegration = TrackingPlanModel.MParticleDataMasterIntegration;

var TaxonomyIntegration = TrackingPlanModel.TaxonomyIntegration;

var LexiconIntegration = TrackingPlanModel.LexiconIntegration;

var WebhookIntegration = TrackingPlanModel.WebhookIntegration;

var SnowplowDataStructuresIntegration = TrackingPlanModel.SnowplowDataStructuresIntegration;

var AdobeExperiencePlatformIntegration = TrackingPlanModel.AdobeExperiencePlatformIntegration;

var Integration = TrackingPlanModel.Integration;

var metricTypeToJs = TrackingPlanModel.metricTypeToJs;

var metricTypeFromJs = TrackingPlanModel.metricTypeFromJs;

var branchStatusToJs = TrackingPlanModel.branchStatusToJs;

var branchStatusFromJs = TrackingPlanModel.branchStatusFromJs;

var issueTriggerToJs = TrackingPlanModel.issueTriggerToJs;

var issueTriggerFromJs = TrackingPlanModel.issueTriggerFromJs;

var importLocationToJs = TrackingPlanModel.importLocationToJs;

var importLocationFromJs = TrackingPlanModel.importLocationFromJs;

var collaborationItemId = TrackingPlanModel.collaborationItemId;

var Group = TrackingPlanModel.Group;

var GlobalRequirements = TrackingPlanModel.GlobalRequirements;

var empty = TrackingPlanModel.empty;

var emptyEvent = TrackingPlanModel.emptyEvent;

var emptyEventVariant = TrackingPlanModel.emptyEventVariant;

var EventNameDuplicator = TrackingPlanModel.EventNameDuplicator;

var duplicateEvent = TrackingPlanModel.duplicateEvent;

var MetricNameDuplicator = TrackingPlanModel.MetricNameDuplicator;

var duplicateMetric = TrackingPlanModel.duplicateMetric;

var emptyPropertyRef = TrackingPlanModel.emptyPropertyRef;

var emptyProperty = TrackingPlanModel.emptyProperty;

var emptySource = TrackingPlanModel.emptySource;

var emptyValidSource = TrackingPlanModel.emptyValidSource;

var emptyDestination = TrackingPlanModel.emptyDestination;

var emptyValidDestination = TrackingPlanModel.emptyValidDestination;

var emptyMetric = TrackingPlanModel.emptyMetric;

var emptyGoal = TrackingPlanModel.emptyGoal;

var emptyPropertyBundle = TrackingPlanModel.emptyPropertyBundle;

var emptyIntegration = TrackingPlanModel.emptyIntegration;

var emptyGroupType = TrackingPlanModel.emptyGroupType;

var emptyTrigger = TrackingPlanModel.emptyTrigger;

var DedupeProperties = TrackingPlanModel.DedupeProperties;

export {
  binOpToJs ,
  binOpFromJs ,
  encodeBinOp ,
  Type ,
  PropertyRef ,
  EventSpecificPropertyValueActions ,
  decodeType ,
  DataType ,
  builtInTypesToJs ,
  builtInTypesFromJs ,
  operationToJs ,
  operationFromJs ,
  propertyAbsenceAllEventsKey ,
  GlobalRequirementsMetadata ,
  Property ,
  PropertyBundle ,
  PropertyBundleRef ,
  eventTypeToJs ,
  eventTypeFromJs ,
  eventTypeToCamelCase ,
  eventTypes ,
  Trigger ,
  EventVariant ,
  migrationToJs ,
  migrationFromJs ,
  constraint_ToJs ,
  constraint_FromJs ,
  Source ,
  Destination ,
  integrationTypeToJs ,
  integrationTypeFromJs ,
  integrationTypeHumanReadableName ,
  integrationTypes ,
  ProtocolsIntegration ,
  RudderstackTrackingPlansIntegration ,
  RudderstackDataCatalogIntegration ,
  MParticleDataMasterIntegration ,
  TaxonomyIntegration ,
  LexiconIntegration ,
  WebhookIntegration ,
  SnowplowDataStructuresIntegration ,
  AdobeExperiencePlatformIntegration ,
  Integration ,
  metricTypeToJs ,
  metricTypeFromJs ,
  branchStatusToJs ,
  branchStatusFromJs ,
  issueTriggerToJs ,
  issueTriggerFromJs ,
  importLocationToJs ,
  importLocationFromJs ,
  collaborationItemId ,
  Group ,
  GlobalRequirements ,
  empty ,
  emptyEvent ,
  emptyEventVariant ,
  EventNameDuplicator ,
  duplicateEvent ,
  MetricNameDuplicator ,
  duplicateMetric ,
  emptyPropertyRef ,
  emptyProperty ,
  emptySource ,
  emptyValidSource ,
  emptyDestination ,
  emptyValidDestination ,
  emptyMetric ,
  emptyGoal ,
  emptyPropertyBundle ,
  emptyIntegration ,
  emptyGroupType ,
  emptyTrigger ,
  DedupeProperties ,
  getSourceFilters ,
  getTagFilters ,
  getDestinationFilters ,
  filterExcludedEvents ,
  filterUnusedPropertyBundles ,
  filterUnusedPropertyMatches ,
  splitProperties ,
  resolveGroupType ,
  resolveGroupTypes ,
  resolveProperty ,
  resolveProperties ,
  getOptionalAndRequiredEventPropertiesFromEvent ,
  getPropertyById ,
  resolvePropertyIds ,
  resolvePropertyBundleRef ,
  resolvePropertyBundleRef_mappedModel ,
  resolvePropertyBundleRefs ,
  resolvePropertyBundleRefs_mappedModel ,
  resolvePropertyBundlesToProperties ,
  classifyProperties ,
  getSystemProperties ,
  getSystemProperties__mappedModel ,
  getOptionalAndRequiredSystemPropertiesOnSource ,
  withSystemProperties ,
  getProperties ,
  resolveSources ,
  resolveDestinations ,
  destinationsReceivingEvent ,
  destinationsReceivingEvent_mapped ,
  sourcesSendingToDestinationsForEvent ,
  filterExcludedEventsFromModel ,
  filterExcludedEventsBySourcesFromModel ,
  removeExcludedPropertiesOnSource ,
  isOnlyUpdateGroupEvent ,
  keepIncludedSystemPropsOnSource ,
  enrichModelForCodegen ,
  resolveEventPropertyBundlesIntoEventProperties ,
  resolvePropertyBundlesPropertiesToEventProperties ,
  enrichPropertyBundlesForCodegen ,
  currencies ,
  enrichModel ,
  softEnrichModel ,
  getAllPropertiesAvailableOnEventForMetricView ,
  getResolvedPropertiesForEvent ,
  getResolvedPropertiesForEventWithPropertyMap ,
  getResolvedPropertiesForEvent_mappedModel ,
}
/* AvoConfig Not a pure module */
