// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Accordion from "../Accordion.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Collapsible from "../Collapsible.mjs";
import * as ItemTooltip from "../ItemTooltip.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as ImportReview__Context from "./ImportReview__Context.mjs";
import * as ImportReviewRow__Styles from "./ImportReviewRow__Styles.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as ImportReviewRow__Property from "./ImportReviewRow__Property.mjs";

function ImportReviewRow__Event$ConflictItem(Props) {
  var label = Props.label;
  var description = Props.description;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.minWidth(Css.px(256)),
                    tl: {
                      hd: Css.maxWidth(Css.px(256)),
                      tl: {
                        hd: Css.padding2(Css.px(8), Css.px(12)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("flexStart"),
                            tl: {
                              hd: Css.gap(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(Icon.make, {
                  type_: "warning",
                  size: "small",
                  color: Styles.Color.orange
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(2)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: label
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light11,
                      children: React.createElement("i", undefined, description)
                    })));
}

var ConflictItem = {
  make: ImportReviewRow__Event$ConflictItem
};

function ImportReviewRow__Event$Conflicts(Props) {
  var included = Props.included;
  var potentialConflictCount = Props.potentialConflictCount;
  var similarEventsInTrackingPlan = Props.similarEventsInTrackingPlan;
  var similarEventsInImport = Props.similarEventsInImport;
  var similarEventNamesInOtherIssues = Props.similarEventNamesInOtherIssues;
  return React.createElement(ItemTooltip.make, {
              arrowColor: Styles.Color.white,
              arrowStrokeColor: Styles.Color.light04,
              arrowStrokeWidth: 1,
              delayIn: 250,
              placement: "bottom",
              withArrow: true,
              withPortal: false,
              children: null
            }, React.createElement(ItemTooltip.Trigger.make, {
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.cursor("default"),
                              tl: {
                                hd: Css.position("relative"),
                                tl: {
                                  hd: Css.zIndex(0),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.gap(Css.px(6)),
                                        tl: {
                                          hd: Css.backgroundColor(included ? Styles.Color.paleOrange : "transparent"),
                                          tl: {
                                            hd: Css.padding2(Css.px(6), Css.px(8)),
                                            tl: {
                                              hd: Css.borderRadius(Css.px(16)),
                                              tl: {
                                                hd: Css.width("fitContent"),
                                                tl: {
                                                  hd: Css.transition({
                                                        NAME: "ms",
                                                        VAL: Styles.Duration.$$short
                                                      }, undefined, undefined, "all"),
                                                  tl: {
                                                    hd: Css.color(included ? Styles.Color.light11 : Styles.Color.light09),
                                                    tl: {
                                                      hd: Icon.styles({
                                                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light11),
                                                            tl: {
                                                              hd: Css.transition({
                                                                    NAME: "ms",
                                                                    VAL: Styles.Duration.$$short
                                                                  }, undefined, undefined, "all"),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      tl: {
                                                        hd: included ? Css.hover({
                                                                hd: Css.backgroundColor(Styles.Color.orange),
                                                                tl: {
                                                                  hd: Css.color(Styles.Color.light12),
                                                                  tl: {
                                                                    hd: Icon.color(Styles.Color.light12),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }) : Styles.emptyStyle,
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        onClick: (function (prim) {
                            prim.stopPropagation();
                          })
                      }, React.createElement(Icon.make, {
                            type_: "warning",
                            size: "small"
                          }), React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            singleLine: true,
                            children: AvoUtils.plural(undefined, undefined, potentialConflictCount, "potential conflict")
                          }))
                }), React.createElement(ItemTooltip.Content.make, {
                  className: Curry._1(Css.style, {
                        hd: Css.zIndex(Styles.ZIndex.aboveAll + 1 | 0),
                        tl: {
                          hd: Css.filter({
                                hd: {
                                  NAME: "dropShadow",
                                  VAL: [
                                    Css.px(0),
                                    Css.px(4),
                                    Css.px(4),
                                    Styles.Color.setAlpha(Styles.Color.light12, 0.125)
                                  ]
                                },
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.padding2(Css.px(4), "zero"),
                                tl: {
                                  hd: Css.cursor("auto"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }),
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }),
                        onClick: (function (prim) {
                            prim.stopPropagation();
                          })
                      }, Curry._1(TrackingPlanMappedModel.Events.size, similarEventsInTrackingPlan) > 0 ? React.createElement(ImportReviewRow__Event$ConflictItem, {
                              label: "Similar event in Tracking plan",
                              description: Belt_Option.getExn(Belt_Option.map(Curry._2(TrackingPlanMappedModel.Events.getAtIndex, similarEventsInTrackingPlan, 0), (function (param) {
                                          return param.name;
                                        })))
                            }) : null, similarEventsInImport.length !== 0 ? React.createElement(ImportReviewRow__Event$ConflictItem, {
                              label: "Similar event in import",
                              description: Belt_Option.getExn(Belt_Option.map(Belt_Array.get(similarEventsInImport, 0), (function (param) {
                                          return param.name;
                                        })))
                            }) : null, similarEventNamesInOtherIssues.length !== 0 ? React.createElement(ImportReviewRow__Event$ConflictItem, {
                              label: "Similar event seen by Inspector",
                              description: Belt_Option.getExn(Belt_Array.get(similarEventNamesInOtherIssues, 0))
                            }) : null)
                }));
}

var Conflicts = {
  make: ImportReviewRow__Event$Conflicts
};

function ImportReviewRow__Event(Props) {
  var $$event = Props.event;
  var included = Props.included;
  var propertyGroups = Props.propertyGroups;
  var propertyGroupProperties = Props.propertyGroupProperties;
  var onToggle = Props.onToggle;
  var excludedProperties = Props.excludedProperties;
  var excludedSources = Props.excludedSources;
  var sources = Props.sources;
  var updatedEventOpt = Props.updatedEvent;
  var onPropertyToggle = Props.onPropertyToggle;
  var onPropertyTypeChange = Props.onPropertyTypeChange;
  var onPropertyPresenceChange = Props.onPropertyPresenceChange;
  var onSourceToggle = Props.onSourceToggle;
  var updatedEvent = updatedEventOpt !== undefined ? updatedEventOpt : false;
  var match = React.useState(function (param) {
        return false;
      });
  var setExpanded = match[1];
  var expanded = match[0];
  var match$1 = ImportReview__Context.use();
  var match$2 = Belt_Option.getWithDefault(Belt_MapString.get(match$1.eventConflictInfo, $$event.id), {
        similarEventsInTrackingPlan: TrackingPlanMappedModel.Events.empty,
        similarEventsInImport: [],
        similarEventNamesInOtherIssues: []
      });
  var similarEventNamesInOtherIssues = match$2.similarEventNamesInOtherIssues;
  var similarEventsInImport = match$2.similarEventsInImport;
  var similarEventsInTrackingPlan = match$2.similarEventsInTrackingPlan;
  var resolvedProperties = Belt_Option.getWithDefault(Belt_MapString.get(match$1.resolvedProperties, $$event.id), undefined);
  var resolvedPropertiesArray = React.useMemo((function (param) {
          return Belt_SortArray.stableSortByU(Belt_MapString.valuesToArray(resolvedProperties), (function (a, b) {
                        var match = a.trackingPlanProperty;
                        if (match !== undefined) {
                          var sendAsA = match.sendAs;
                          var a$1 = match.name;
                          var exit = 0;
                          if (typeof sendAsA !== "object" && sendAsA === "SystemProperty") {
                            var match$1 = b.trackingPlanProperty;
                            if (match$1 !== undefined) {
                              if (Caml_obj.notequal(sendAsA, match$1.sendAs)) {
                                return 1;
                              }
                              exit = 2;
                            }
                            
                          } else {
                            exit = 2;
                          }
                          if (exit === 2) {
                            var match$2 = b.trackingPlanProperty;
                            if (match$2 !== undefined) {
                              var sendAsB = match$2.sendAs;
                              if (typeof sendAsB !== "object" && sendAsB === "SystemProperty") {
                                if (Caml_obj.notequal(sendAsA, sendAsB)) {
                                  return -1;
                                }
                                if (typeof sendAsA !== "object" && sendAsA === "SystemProperty") {
                                  return Caml.string_compare(a$1, b.trackingPlanProperty.name);
                                }
                                
                              }
                              
                            }
                            
                          }
                          var match$3 = b.trackingPlanProperty;
                          if (match$3 !== undefined) {
                            return Caml.string_compare(a$1, match$3.name);
                          } else {
                            return Caml.string_compare(a$1, b.importProperty.name);
                          }
                        }
                        var match$4 = b.trackingPlanProperty;
                        var a$2 = a.importProperty.name;
                        if (match$4 !== undefined) {
                          return Caml.string_compare(a$2, match$4.name);
                        } else {
                          return Caml.string_compare(a$2, b.importProperty.name);
                        }
                      }));
        }), [resolvedProperties]);
  var autoResolvedProperties = Belt_MapString.keepU(resolvedProperties, (function (_key, param) {
          return Belt_Array.keepU(param.importProperty.warnings, (function (warning) {
                        if (typeof warning === "object" && warning.NAME === "typeConflictAutoResolved") {
                          return warning.VAL[0] === $$event.id;
                        } else {
                          return false;
                        }
                      })).length !== 0;
        }));
  var n = Belt_Array.concatMany([
        $$event.propertyIds,
        $$event.updatedPropertyIds,
        Belt_Array.mapU(propertyGroupProperties, (function (param) {
                return [
                        param[0].id,
                        undefined
                      ];
              }))
      ]).length;
  var potentialConflictCount = (($$event.warnings.length + Curry._1(TrackingPlanMappedModel.Events.size, similarEventsInTrackingPlan) | 0) + similarEventsInImport.length | 0) + similarEventNamesInOtherIssues.length | 0;
  return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                  className: ImportReviewRow__Styles.rootStyles,
                  onClick: (function (param) {
                      Curry._1(setExpanded, (function (expanded) {
                              return !expanded;
                            }));
                    })
                }, React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, $$event.propertyIds.length !== 0 || $$event.propertyBundleIds.length !== 0 || $$event.updatedPropertyIds.length !== 0 ? React.createElement(Accordion.OpeningIcon.make, {
                            size: "large",
                            isOpen: expanded
                          }) : null), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(12)),
                            tl: /* [] */0
                          }),
                      onClick: (function (prim) {
                          prim.stopPropagation();
                        })
                    }, React.createElement(Mantine.Checkbox.make, {
                          checked: included,
                          color: "blue100",
                          onChange: onToggle,
                          size: {
                            NAME: "num",
                            VAL: 16
                          }
                        })), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.maxWidth(Css.px(300)),
                            tl: {
                              hd: Css.paddingRight(Css.px(12)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", undefined, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  singleLine: true,
                                  color: included ? Styles.Color.light11 : Styles.Color.light08,
                                  title: $$event.name,
                                  children: $$event.name
                                }), updatedEvent ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                        width: 8
                                      }), React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        singleLine: true,
                                        color: included ? Styles.Color.orange : Styles.Color.light08,
                                        children: "UPDATED"
                                      })) : null), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.gap(Css.px(6)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  singleLine: true,
                                  color: Styles.Color.light11,
                                  children: n !== 0 ? (
                                      n !== 1 ? n.toString() + " properties" : "1 property"
                                    ) : "No properties"
                                }), Belt_MapString.size(autoResolvedProperties) > 0 ? React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingLeft(Css.px(6)),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.gap(Css.px(6)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement(Icon.make, {
                                        type_: "lilRobot",
                                        size: "small",
                                        color: Styles.Color.light10
                                      }), React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        singleLine: true,
                                        color: Styles.Color.light10,
                                        children: AvoUtils.plural(undefined, undefined, Belt_MapString.size(autoResolvedProperties), "conflict") + " auto resolved"
                                      })) : null))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.padding(Css.px(12)),
                            tl: /* [] */0
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, Belt_Array.map(sources, (function (source) {
                                if (!Belt_Array.some($$event.sources, (function (eventSource) {
                                          return eventSource === source;
                                        }))) {
                                  return null;
                                }
                                var id = $$event.id + "-" + source + "}";
                                return React.createElement("div", {
                                            key: id,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: {
                                                      hd: Css.gap(Css.px(4)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }),
                                            onClick: (function (prim) {
                                                prim.stopPropagation();
                                              })
                                          }, React.createElement(Mantine.Checkbox.make, {
                                                checked: !Belt_Set.has(excludedSources, [
                                                      $$event.id,
                                                      source
                                                    ]),
                                                color: "blue100",
                                                disabled: !included,
                                                label: React.createElement($$Text.make, {
                                                      size: "Small",
                                                      singleLine: true,
                                                      color: Styles.Color.light11,
                                                      children: source
                                                    }),
                                                onChange: Curry._1(onSourceToggle, source),
                                                size: {
                                                  NAME: "num",
                                                  VAL: 16
                                                }
                                              }));
                              })))), React.createElement("td", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(12)),
                            tl: {
                              hd: Css.paddingRight(Css.px(12)),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.minWidth(Css.px(180)),
                                  tl: {
                                    hd: Css.zIndex(1),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, potentialConflictCount > 0 ? React.createElement(ImportReviewRow__Event$Conflicts, {
                            included: included,
                            potentialConflictCount: potentialConflictCount,
                            similarEventsInTrackingPlan: similarEventsInTrackingPlan,
                            similarEventsInImport: similarEventsInImport,
                            similarEventNamesInOtherIssues: similarEventNamesInOtherIssues
                          }) : null, Belt_Array.mapU($$event.warnings, (function (warning) {
                            return React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.light11,
                                        children: warning,
                                        key: warning
                                      });
                          })))), React.createElement("tr", undefined, React.createElement("td", {
                      colSpan: "100%"
                    }, React.createElement(Collapsible.make, {
                          collapsed: !expanded,
                          children: $$event.propertyIds.length !== 0 || $$event.propertyBundleIds.length !== 0 || $$event.updatedPropertyIds.length !== 0 ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.maxWidth(Css.pct(100.0)),
                                        tl: {
                                          hd: Css.padding(Css.px(8)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(66)),
                                            tl: {
                                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, Belt_Array.concatMany(Belt_Array.mapU(propertyGroups, (function (group) {
                                            return Belt_Array.mapU(Belt_Array.keepMapU(group.propertiesUsedOnlyInThisPropertyBundle, (function (property) {
                                                              return Belt_Array.getByU(propertyGroupProperties, (function (param) {
                                                                            return param[0].id === property.id;
                                                                          }));
                                                            })), (function (param) {
                                                          var property = param[0];
                                                          return React.createElement(ImportReviewRow__Property.make, {
                                                                      eventId: $$event.id,
                                                                      property: property,
                                                                      propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                                            $$event.id,
                                                                            property.id
                                                                          ]),
                                                                      disabled: !included,
                                                                      onToggle: (function (inputEvent) {
                                                                          Curry._3(onPropertyToggle, property.id, property.name, inputEvent);
                                                                        }),
                                                                      onPropertyTypeChange: Curry._2(onPropertyTypeChange, property.id, property.name),
                                                                      existingProperty: true,
                                                                      propertyGroup: group,
                                                                      onPropertyPresenceChange: Curry._2(onPropertyPresenceChange, property.id, property.name),
                                                                      key: property.id
                                                                    });
                                                        }));
                                          }))), Belt_Array.mapU(resolvedPropertiesArray, (function (param) {
                                        var property = param.importProperty;
                                        var trackingPlanProperty = param.trackingPlanProperty;
                                        var excludeId = trackingPlanProperty !== undefined ? trackingPlanProperty.id : property.id;
                                        return React.createElement(ImportReviewRow__Property.make, {
                                                    eventId: $$event.id,
                                                    property: property,
                                                    propertyIncluded: !Belt_Set.has(excludedProperties, [
                                                          $$event.id,
                                                          excludeId
                                                        ]),
                                                    disabled: !included,
                                                    onToggle: (function (inputEvent) {
                                                        Curry._3(onPropertyToggle, excludeId, property.name, inputEvent);
                                                      }),
                                                    onPropertyTypeChange: Curry._2(onPropertyTypeChange, property.id, property.name),
                                                    existingProperty: param.existingProperty,
                                                    pinnedValue: param.pinnedValue,
                                                    onPropertyPresenceChange: Curry._2(onPropertyPresenceChange, property.id, property.name),
                                                    key: property.id
                                                  });
                                      }))) : null
                        }))));
}

var WorkingModelContext;

var Property;

var make = ImportReviewRow__Event;

export {
  WorkingModelContext ,
  Property ,
  ConflictItem ,
  Conflicts ,
  make ,
}
/* Css Not a pure module */
