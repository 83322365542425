// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Number from "../Number.mjs";
import * as Styles from "../styles.mjs";
import * as Mantine from "../Mantine.mjs";
import * as AvoSelect from "../AvoSelect.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyUtils from "../PropertyUtils.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as InspectorPropertyType from "../../../shared/models/InspectorPropertyType.mjs";
import * as ImportReviewRow__Styles from "./ImportReviewRow__Styles.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";

var FloatingSelect = AvoSelect.MakeFloatingSelect({});

var availableTypes = [
  "String",
  "Int",
  "Float",
  "Bool",
  "Object"
];

function ImportReviewRow__Property$TypeSelect(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  return React.createElement(FloatingSelect.Menu.make, {
              label: Belt_Option.mapWithDefault(value, "", InspectorPropertyType.toString),
              color: Styles.Color.light02,
              onUpdate: onChange,
              dropdownStyles: Curry._1(Css.merge, {
                    hd: FloatingSelect.Style.dropdown,
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.zIndex(Styles.ZIndex.aboveAll + 1 | 0),
                            tl: {
                              hd: Css.minWidth("unset"),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }),
              rootMenuStyles: (function (c) {
                  return Curry._1(Css.merge, {
                              hd: Curry._1(FloatingSelect.Style.rootMenu, c),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.selector("&.root", {
                                            hd: Css.padding2(Css.px(1), Css.px(6)),
                                            tl: {
                                              hd: Css.borderRadius(Css.px(4)),
                                              tl: {
                                                hd: Css.height("unset"),
                                                tl: {
                                                  hd: Css.minHeight("unset"),
                                                  tl: {
                                                    hd: Css.selector(" p", {
                                                          hd: Css.fontFamily(Styles.FontFamily.polar),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            });
                }),
              withPortal: true,
              children: Belt_Array.mapU(availableTypes, (function (value) {
                      return React.createElement(FloatingSelect.MenuItem.make, {
                                  label: InspectorPropertyType.toString(value),
                                  value: value,
                                  key: InspectorPropertyType.toString(value)
                                });
                    }))
            });
}

var TypeSelect = {
  FloatingSelect: FloatingSelect,
  availableTypes: availableTypes,
  make: ImportReviewRow__Property$TypeSelect
};

var FloatingSelect$1 = AvoSelect.MakeFloatingSelect({});

var availableValues = [
  "AlwaysSent",
  "SometimesSent"
];

function getLabel(value) {
  if (value === "AlwaysSent") {
    return "Always sent";
  } else {
    return "Sometimes sent";
  }
}

function ImportReviewRow__Property$PresenceSelect(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  return React.createElement(FloatingSelect$1.Menu.make, {
              label: getLabel(value),
              color: Styles.Color.light02,
              onUpdate: onChange,
              dropdownStyles: Curry._1(Css.merge, {
                    hd: FloatingSelect$1.Style.dropdown,
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.zIndex(Styles.ZIndex.aboveAll + 1 | 0),
                            tl: {
                              hd: Css.minWidth("unset"),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }),
              rootMenuStyles: (function (c) {
                  return Curry._1(Css.merge, {
                              hd: Curry._1(FloatingSelect$1.Style.rootMenu, c),
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.selector("&.root", {
                                            hd: Css.padding2(Css.px(1), Css.px(6)),
                                            tl: {
                                              hd: Css.borderRadius(Css.px(4)),
                                              tl: {
                                                hd: Css.height("unset"),
                                                tl: {
                                                  hd: Css.minHeight("unset"),
                                                  tl: {
                                                    hd: Css.selector(" p", {
                                                          hd: Css.fontFamily(Styles.FontFamily.polar),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            });
                }),
              withPortal: true,
              children: Belt_Array.mapU(availableValues, (function (value) {
                      return React.createElement(FloatingSelect$1.MenuItem.make, {
                                  label: getLabel(value),
                                  value: value,
                                  key: getLabel(value)
                                });
                    }))
            });
}

var PresenceSelect = {
  FloatingSelect: FloatingSelect$1,
  availableValues: availableValues,
  getLabel: getLabel,
  make: ImportReviewRow__Property$PresenceSelect
};

function ImportReviewRow__Property$Change(Props) {
  var hasChange = Props.hasChange;
  var ogValue = Props.ogValue;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 8;
  if (hasChange) {
    return React.createElement(SimpleTooltip.make, {
                tooltip: "Changed from " + ogValue,
                children: React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.px(size)),
                            tl: {
                              hd: Css.height(Css.px(size)),
                              tl: {
                                hd: Css.borderRadius(Css.pxFloat(size / 2.0)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.palePurple),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    })
              });
  } else {
    return null;
  }
}

var Change = {
  make: ImportReviewRow__Property$Change
};

function ImportReviewRow__Property(Props) {
  var eventId = Props.eventId;
  var property = Props.property;
  var propertyIncluded = Props.propertyIncluded;
  var disabled = Props.disabled;
  var onToggle = Props.onToggle;
  var onPropertyTypeChange = Props.onPropertyTypeChange;
  var existingPropertyOpt = Props.existingProperty;
  var propertyGroup = Props.propertyGroup;
  var pinnedValueOpt = Props.pinnedValue;
  var onPropertyPresenceChange = Props.onPropertyPresenceChange;
  var existingProperty = existingPropertyOpt !== undefined ? existingPropertyOpt : false;
  var pinnedValue = pinnedValueOpt !== undefined ? Caml_option.valFromOption(pinnedValueOpt) : undefined;
  var mappedSources = ModelStore.Mapped.useSources();
  var ogType = React.useMemo((function (param) {
          return property.type_;
        }), []);
  var ogPresence = React.useMemo((function (param) {
          return Belt_Option.getWithDefault(Belt_MapString.get(property.presence, eventId), "AlwaysSent");
        }), []);
  var preventChanges = existingProperty || disabled || Belt_Option.isSome(propertyGroup) || property.sendAs === "SystemProperty";
  var tmp;
  if (preventChanges) {
    var match = Belt_MapString.get(property.presence, eventId);
    var tmp$1;
    tmp$1 = match !== undefined && match !== "AlwaysSent" ? "Sometimes sent" : "Always sent";
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding2(Css.px(2), Css.px(6)),
                tl: {
                  hd: Css.borderRadius(Css.px(4)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        }, React.createElement($$Text.make, {
              size: "Small",
              color: Styles.Color.light11,
              children: tmp$1
            }));
  } else {
    var match$1 = Belt_MapString.get(property.presence, eventId);
    var presence = match$1 !== undefined && match$1 !== "AlwaysSent" ? "SometimesSent" : "AlwaysSent";
    var tmp$2;
    tmp$2 = ogPresence === "AlwaysSent" ? "Always Sent" : "Sometimes Sent";
    tmp = React.createElement(React.Fragment, undefined, React.createElement(ImportReviewRow__Property$PresenceSelect, {
              value: presence,
              onChange: onPropertyPresenceChange
            }), React.createElement(ImportReviewRow__Property$Change, {
              hasChange: ogPresence !== presence,
              ogValue: tmp$2
            }));
  }
  var match$2 = property.sendAs;
  var tmp$3;
  var exit = 0;
  if (typeof match$2 !== "object" && match$2 === "SystemProperty") {
    tmp$3 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding2(Css.px(2), Css.px(6)),
                tl: {
                  hd: Css.borderRadius(Css.px(12)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.lightBlue),
                    tl: /* [] */0
                  }
                }
              })
        }, React.createElement($$Text.make, {
              size: "Tiny",
              weight: "Medium",
              singleLine: true,
              color: Styles.Color.deepBlue,
              children: "Reused System Property"
            }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$3 = existingProperty ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.padding2(Css.px(2), Css.px(6)),
                  tl: {
                    hd: Css.borderRadius(Css.px(12)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.lightBlue),
                      tl: /* [] */0
                    }
                  }
                })
          }, React.createElement($$Text.make, {
                size: "Tiny",
                weight: "Medium",
                singleLine: true,
                color: Styles.Color.deepBlue,
                children: "Reused"
              })) : null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.minWidth(Css.pct(100.0)),
                          tl: {
                            hd: Css.maxWidth(Css.px(400)),
                            tl: {
                              hd: Css.padding(Css.px(8)),
                              tl: {
                                hd: Css.borderRadius(Css.px(5)),
                                tl: {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: Styles.Duration.$$short
                                      }, undefined, undefined, "background-color"),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.backgroundColor(Styles.Color.light01),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(12)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Mantine.Checkbox.make, {
                          checked: propertyIncluded,
                          color: "blue100",
                          disabled: disabled || Belt_Option.isSome(propertyGroup) || property.sendAs === "SystemProperty",
                          onChange: onToggle,
                          size: {
                            NAME: "num",
                            VAL: 16
                          }
                        }), propertyGroup !== undefined ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                singleLine: true,
                                color: Styles.Color.light10,
                                children: "[" + propertyGroup.name + "]"
                              })) : null, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          singleLine: true,
                          color: property.sendAs === "SystemProperty" ? Styles.Color.light10 : Styles.Color.light11,
                          children: property.name
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(6)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, preventChanges ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(2), Css.px(6)),
                                  tl: {
                                    hd: Css.borderRadius(Css.px(4)),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.light02),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.gap(Css.px(4)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                element: "Span",
                                size: "Small",
                                children: React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.important(Css.fontFamily(Styles.FontFamily.polar)),
                                            tl: /* [] */0
                                          })
                                    }, property.list ? "list of " : null, property.type_)
                              })) : React.createElement(React.Fragment, undefined, React.createElement(ImportReviewRow__Property$TypeSelect, {
                                value: InspectorPropertyType.toPropertyType(property.type_),
                                onChange: onPropertyTypeChange
                              }), React.createElement(ImportReviewRow__Property$Change, {
                                hasChange: ogType !== property.type_,
                                ogValue: ogType
                              })), tmp, tmp$3)), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.selector(":not(:empty)", {
                              hd: Css.paddingTop(Css.px(4)),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.paddingLeft(Css.px(22)),
                          tl: /* [] */0
                        }
                      })
                }, pinnedValue !== undefined ? React.createElement("div", {
                        className: ImportReviewRow__Styles.propertyMatchesStyles
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            singleLine: true,
                            color: Styles.Color.light10,
                            children: null
                          }, "pinned to ", React.createElement("span", {
                                className: ImportReviewRow__Styles.propertyMatchStyles
                              }, PropertyUtils.printPinnedValue(pinnedValue)))) : null), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.gap(Css.px(2)),
                            tl: {
                              hd: Css.selector(":not(:empty)", {
                                    hd: Css.paddingTop(Css.px(8)),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(20)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, Belt_Array.mapU(property.warnings, (function (warning) {
                        var variant = warning.NAME;
                        if (variant === "typeConflictAutoResolved") {
                          var match = warning.VAL;
                          var match$1 = match[1];
                          var resolvedType = match$1.type_;
                          var eId = match[0];
                          if (eId === eventId && existingProperty) {
                            var seenTypes = match[2];
                            return React.createElement("div", {
                                        key: "reusedFromTrackingPlan",
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.gap(Css.px(6)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement(Icon.make, {
                                            type_: "lilRobot",
                                            size: "small",
                                            color: Styles.Color.light10
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            color: Styles.Color.light10,
                                            children: null
                                          }, React.createElement($$Text.make, {
                                                element: "Span",
                                                weight: "Semi",
                                                children: "Type conflict with tracking plan auto resolved"
                                              }), seenTypes.length !== 0 ? " (Property types seen: " + Belt_Array.mapU(seenTypes, (function (seenType) {
                                                      return seenType.type_ + " " + $$Number.getRoughPercentageString(seenType.propertyPercentage * 100.0) + "%";
                                                    })).join(", ") + ")" : " (No valid property types seen)"));
                          }
                          if (match$1.propertyPercentage === 0.0 && match[2].length === 0 && eId === eventId) {
                            return React.createElement("div", {
                                        key: "typeConflictAutoResolved",
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.gap(Css.px(6)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement(Icon.make, {
                                            type_: "lilRobot",
                                            size: "small",
                                            color: Styles.Color.light10
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.light10,
                                            children: "Property type automatically resolved to " + resolvedType + " because type information was missing"
                                          }));
                          }
                          if (eId !== eventId) {
                            return null;
                          }
                          var seenTypes$1 = match[2];
                          return React.createElement("div", {
                                      key: "typeConflictAutoResolved",
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.gap(Css.px(6)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement(Icon.make, {
                                          type_: "lilRobot",
                                          size: "small",
                                          color: Styles.Color.light10
                                        }), React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light10,
                                          children: null
                                        }, React.createElement($$Text.make, {
                                              element: "Span",
                                              weight: "Semi",
                                              children: "Property type automatically resolved to " + resolvedType
                                            }), seenTypes$1.length !== 0 ? " (Property types seen: " + Belt_Array.mapU(seenTypes$1, (function (seenType) {
                                                    return seenType.type_ + " " + $$Number.getRoughPercentageString(seenType.propertyPercentage * 100.0) + "%";
                                                  })).join(", ") + ")" : " (No valid property types seen)"));
                        }
                        if (variant === "message") {
                          var warning$1 = warning.VAL;
                          return React.createElement("div", {
                                      key: warning$1,
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.gap(Css.px(6)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement(Icon.make, {
                                          type_: "lilRobot",
                                          size: "small",
                                          color: Styles.Color.light10
                                        }), React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          color: Styles.Color.light10,
                                          children: warning$1
                                        }));
                        }
                        if (variant === "presenceInconsistent") {
                          var match$2 = warning.VAL;
                          if (match$2[0] !== eventId) {
                            return null;
                          }
                          var sourcePresence = match$2[1];
                          return React.createElement("div", {
                                      key: "presenceInconsistent",
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.gap(Css.px(6)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement(Icon.make, {
                                          type_: "lilRobot",
                                          size: "small",
                                          color: Styles.Color.light10
                                        }), React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light10,
                                          children: null
                                        }, React.createElement($$Text.make, {
                                              element: "Span",
                                              weight: "Semi",
                                              children: "Property presence set as \"Always sent\""
                                            }), " (Property missing in: ", Belt_Array.concatMany(Belt_Array.mapWithIndexU(sourcePresence, (function (index, sp) {
                                                    return Belt_Array.concat([
                                                                $$Number.getRoughPercentageString(1.0 - sp.percentagePresent) + "% of events on ",
                                                                React.createElement("i", undefined, Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Sources.getBy, mappedSources, (function (param) {
                                                                                return sp.sourceId === param.id;
                                                                              })), "Unknown Source", (function (param) {
                                                                            return Belt_Option.getWithDefault(param.name, "Untitled Source");
                                                                          })))
                                                              ], (index + 1 | 0) < sourcePresence.length ? [", "] : []);
                                                  }))), ")"));
                        }
                        var match$3 = warning.VAL;
                        if (match$3[0] === eventId) {
                          return React.createElement("div", {
                                      key: "mostCommonTypeUsed",
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.gap(Css.px(6)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement(Icon.make, {
                                          type_: "lilRobot",
                                          size: "small",
                                          color: Styles.Color.light10
                                        }), React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light10,
                                          children: null
                                        }, React.createElement($$Text.make, {
                                              element: "Span",
                                              weight: "Semi",
                                              children: "Property set to most common type"
                                            }), " (Inconsistent property types seen: " + Belt_Array.mapU(match$3[1], (function (seenType) {
                                                  return seenType.type_ + " " + $$Number.getRoughPercentageString(seenType.propertyPercentage * 100.0) + "%";
                                                })).join(", ") + ")"));
                        } else {
                          return null;
                        }
                      }))));
}

var make = ImportReviewRow__Property;

export {
  TypeSelect ,
  PresenceSelect ,
  Change ,
  make ,
}
/* FloatingSelect Not a pure module */
