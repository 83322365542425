// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_MutableMapString from "rescript/lib/es6/belt_MutableMapString.js";

function propertySendAsToItemSubType(sendAs) {
  if (typeof sendAs === "object") {
    return "GroupProperty";
  }
  switch (sendAs) {
    case "SystemProperty" :
        return "SystemProperty";
    case "EventProperty" :
        return "EventProperty";
    case "UserProperty" :
        return "UserProperty";
    
  }
}

function itemSubTypeToJs(itemSubType) {
  switch (itemSubType) {
    case "EventProperty" :
        return "EP";
    case "UserProperty" :
        return "UP";
    case "SystemProperty" :
        return "SP";
    case "GroupProperty" :
        return "GP";
    
  }
}

function itemSubTypeFromJs(js) {
  switch (js) {
    case "EP" :
        return "EventProperty";
    case "GP" :
        return "GroupProperty";
    case "SP" :
        return "SystemProperty";
    case "UP" :
        return "UserProperty";
    default:
      return Pervasives.failwith("Invalid item sub type");
  }
}

var ItemSubType = {
  propertySendAsToItemSubType: propertySendAsToItemSubType,
  itemSubTypeToJs: itemSubTypeToJs,
  itemSubTypeFromJs: itemSubTypeFromJs
};

function encodeItem(item) {
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "n",
                      item.name
                    ],
                    tl: {
                      hd: [
                        "iId",
                        item.itemId
                      ],
                      tl: /* [] */0
                    }
                  },
                  Belt_Option.mapWithDefault(item.originalName, /* [] */0, (function (originalName) {
                          return {
                                  hd: [
                                    "oN",
                                    originalName
                                  ],
                                  tl: /* [] */0
                                };
                        })),
                  Belt_Option.mapWithDefault(item.itemSubType, /* [] */0, (function (itemSubType) {
                          return {
                                  hd: [
                                    "iST",
                                    itemSubTypeToJs(itemSubType)
                                  ],
                                  tl: /* [] */0
                                };
                        }))
                ]));
}

function encode(t) {
  var __x = Js_dict.fromArray(Belt_MapString.toArray(t));
  return Json_encode.dict((function (items) {
                return Json_encode.array(encodeItem, items);
              }), __x);
}

function decodeItem(json) {
  return {
          name: Json_decode.field("n", Json_decode.string, json),
          itemId: Json_decode.field("iId", Json_decode.string, json),
          originalName: Json_decode.optional((function (param) {
                  return Json_decode.field("oN", Json_decode.string, param);
                }), json),
          itemSubType: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("iST", Json_decode.string, param);
                    }), json), itemSubTypeFromJs)
        };
}

function decode(json) {
  return Belt_MapString.fromArray(Js_dict.entries(Json_decode.dict((function (items) {
                        return Json_decode.array(decodeItem, items);
                      }), json)));
}

function toString(t) {
  if (t === "Events") {
    return "events";
  } else {
    return "properties";
  }
}

var all = [
  "Events",
  "Properties"
];

function cmp(a, b) {
  return $$String.compare(toString(a), toString(b));
}

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

var ItemType = {
  toString: toString,
  all: all,
  Comparator: Comparator
};

function getNamespaceId(name, items) {
  return name + "+" + Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(items, (function (param) {
                          return param.itemId;
                        })))).join(",");
}

function fromServer(itemType, keep, dataFromServer) {
  var clientData = Belt_MutableMapString.make();
  Belt_MapString.forEachU(decode(dataFromServer), (function (branchId, items) {
          Belt_Array.forEachU(items, (function (item) {
                  if (!Curry._2(keep, branchId, item)) {
                    return ;
                  }
                  var item_name = item.name;
                  var item_itemId = item.itemId;
                  var item_originalName = item.originalName;
                  var item_itemSubType = item.itemSubType;
                  var item$1 = {
                    branchId: branchId,
                    name: item_name,
                    itemId: item_itemId,
                    originalName: item_originalName,
                    itemType: itemType,
                    itemSubType: item_itemSubType
                  };
                  Belt_MutableMapString.updateU(clientData, item_name, (function (items) {
                          if (items !== undefined) {
                            return Belt_Array.concat(items, [item$1]);
                          } else {
                            return [item$1];
                          }
                        }));
                }));
        }));
  return Belt_MapString.fromArray(Belt_MutableMapString.toArray(clientData));
}

var Client = {
  getNamespaceId: getNamespaceId,
  empty: undefined,
  fromServer: fromServer
};

var empty;

export {
  ItemSubType ,
  empty ,
  encodeItem ,
  encode ,
  decodeItem ,
  decode ,
  ItemType ,
  Client ,
}
/* Comparator Not a pure module */
