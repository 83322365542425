// Generated by ReScript, PLEASE EDIT WITH CARE

import * as KBar from "./KBar.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function itemToString(_item) {
  while(true) {
    var item = _item;
    switch (item.TAG) {
      case "NameMappedEvent" :
          var mappedItemName = item._0;
          if (mappedItemName !== undefined) {
            return mappedItemName;
          } else {
            return "Untitled Event";
          }
      case "NameMappedProperty" :
          var mappedItemName$1 = item._0;
          if (mappedItemName$1 !== undefined) {
            return mappedItemName$1;
          } else {
            return "Untitled Property";
          }
      case "Source" :
          return Belt_Option.getWithDefault(item._0, "Untitled Source");
      case "Destination" :
          return Belt_Option.getWithDefault(item._0, "Untitled Destination");
      case "Integration" :
          return Belt_Option.getWithDefault(item._0, "Untitled Integration");
      case "BranchPreview" :
          _item = item._0;
          continue ;
      default:
        return item._0;
    }
  };
}

function itemToStringWithDescription(item) {
  var exit = 0;
  var name;
  var description;
  switch (item.TAG) {
    case "Event" :
        name = item._0;
        description = item._1;
        exit = 2;
        break;
    case "Property" :
        name = item._0;
        description = item._3;
        exit = 2;
        break;
    case "NameMappedEvent" :
    case "NameMappedProperty" :
        exit = 1;
        break;
    default:
      return itemToString(item);
  }
  switch (exit) {
    case 1 :
        return Belt_Option.getWithDefault(item._0, item._1) + " " + item._2;
    case 2 :
        return name + " " + description;
    
  }
}

function itemToSectionString(_item) {
  while(true) {
    var item = _item;
    switch (item.TAG) {
      case "RootItem" :
      case "Create" :
          return ;
      case "Command" :
          return "Command";
      case "Branch" :
          return "Branches";
      case "Event" :
      case "NameMappedEvent" :
          return "Events";
      case "Property" :
      case "NameMappedProperty" :
          return "Properties";
      case "Source" :
          return "Sources";
      case "Destination" :
          return "Destinations";
      case "Integration" :
          return "Integrations";
      case "Metric" :
          return "Metrics";
      case "Category" :
          return "Categories";
      case "PropertyGroup" :
          return "Property Bundles";
      case "GroupType" :
          return "Group Types";
      case "SavedView" :
          return "Saved Views";
      case "BranchPreview" :
          _item = item._0;
          continue ;
      
    }
  };
}

function itemDescription(_item) {
  while(true) {
    var item = _item;
    switch (item.TAG) {
      case "Event" :
          return item._1;
      case "Property" :
          return item._3;
      case "NameMappedEvent" :
      case "NameMappedProperty" :
          return item._2;
      case "BranchPreview" :
          _item = item._0;
          continue ;
      default:
        return ;
    }
  };
}

var include = KBar.Make({
      itemToSectionString: itemToSectionString,
      itemToString: itemToString
    });

var ActionImpl = include.ActionImpl;

var Action = include.Action;

var Result = include.Result;

var Provider = include.Provider;

var Portal = include.Portal;

var Positioner = include.Positioner;

var Animator = include.Animator;

var Search = include.Search;

var Matches = include.Matches;

var Results = include.Results;

var State = include.State;

var Query = include.Query;

var HookState = include.HookState;

export {
  itemToString ,
  itemToStringWithDescription ,
  itemToSectionString ,
  itemDescription ,
  ActionImpl ,
  Action ,
  Result ,
  Provider ,
  Portal ,
  Positioner ,
  Animator ,
  Search ,
  Matches ,
  Results ,
  State ,
  Query ,
  HookState ,
}
/* include Not a pure module */
