// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as ModelStore from "../ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamespaceIndex from "../../../shared/models/NamespaceIndex.mjs";
import * as SubscriptionStore from "../SubscriptionStore.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";

function useNamespaceIndex(schemaId, modeOpt, itemSubType, itemType) {
  var mode = modeOpt !== undefined ? modeOpt : "Wait";
  var model = ModelStore.Mapped.useMainModel();
  var branchModel = ModelStore.Mapped.use();
  var doc = FirebaseFetcherHooks.useDocSubscription(mode, Firebase.app().firestore().collection("schemas").doc(schemaId).collection("namespaceIndex"), NamespaceIndex.ItemType.toString(itemType));
  return React.useMemo((function (param) {
                if (typeof doc === "object") {
                  return {
                          NAME: "Success",
                          VAL: NamespaceIndex.Client.fromServer(itemType, (function (branchId, item) {
                                  var isOfSubType = Belt_Option.mapWithDefault(itemSubType, true, (function (itemSubType) {
                                          return Caml_obj.equal(itemSubType, item.itemSubType);
                                        }));
                                  var existsOnMainOrCurrentBranch = false;
                                  if (Curry._2(TrackingPlanMappedModel.OpenBranches.has, model.openBranches, branchId)) {
                                    var tmp;
                                    tmp = itemType === "Events" ? Curry._2(TrackingPlanMappedModel.Events.has, model.events, item.itemId) || Curry._2(TrackingPlanMappedModel.Events.has, model.archive.events, item.itemId) || Curry._2(TrackingPlanMappedModel.Events.has, branchModel.events, item.itemId) : Curry._2(TrackingPlanMappedModel.Properties.has, model.properties, item.itemId) || Curry._2(TrackingPlanMappedModel.Properties.has, model.archive.properties, item.itemId) || Curry._2(TrackingPlanMappedModel.Properties.has, branchModel.properties, item.itemId);
                                    existsOnMainOrCurrentBranch = tmp;
                                  }
                                  if (existsOnMainOrCurrentBranch) {
                                    return false;
                                  } else {
                                    return isOfSubType;
                                  }
                                }), doc.VAL)
                        };
                } else {
                  return doc;
                }
              }), [
              doc,
              model,
              branchModel,
              itemType,
              itemSubType
            ]);
}

var empty = NamespaceIndex.Client.empty;

function use(mode, schemaId) {
  return useNamespaceIndex(schemaId, mode, undefined, "Events");
}

function resolve(query) {
  if (typeof query === "object") {
    return Caml_option.some(query.VAL);
  }
  
}

var EventsConfig = {
  empty: empty,
  use: use,
  resolve: resolve
};

var empty$1 = NamespaceIndex.Client.empty;

function use$1(mode, schemaId) {
  return useNamespaceIndex(schemaId, mode, "EventProperty", "Properties");
}

function resolve$1(query) {
  if (typeof query === "object") {
    return Caml_option.some(query.VAL);
  }
  
}

var EventPropertiesConfig = {
  empty: empty$1,
  use: use$1,
  resolve: resolve$1
};

var empty$2 = NamespaceIndex.Client.empty;

function use$2(mode, schemaId) {
  return useNamespaceIndex(schemaId, mode, "UserProperty", "Properties");
}

function resolve$2(query) {
  if (typeof query === "object") {
    return Caml_option.some(query.VAL);
  }
  
}

var UserPropertiesConfig = {
  empty: empty$2,
  use: use$2,
  resolve: resolve$2
};

var empty$3 = NamespaceIndex.Client.empty;

function use$3(mode, schemaId) {
  return useNamespaceIndex(schemaId, mode, "SystemProperty", "Properties");
}

function resolve$3(query) {
  if (typeof query === "object") {
    return Caml_option.some(query.VAL);
  }
  
}

var SystemPropertiesConfig = {
  empty: empty$3,
  use: use$3,
  resolve: resolve$3
};

var empty$4 = NamespaceIndex.Client.empty;

function use$4(mode, schemaId) {
  return useNamespaceIndex(schemaId, mode, "GroupProperty", "Properties");
}

function resolve$4(query) {
  if (typeof query === "object") {
    return Caml_option.some(query.VAL);
  }
  
}

var GroupPropertiesConfig = {
  empty: empty$4,
  use: use$4,
  resolve: resolve$4
};

var Events = SubscriptionStore.Make(EventsConfig);

var EventProperties = SubscriptionStore.Make(EventPropertiesConfig);

var UserProperties = SubscriptionStore.Make(UserPropertiesConfig);

var SystemProperties = SubscriptionStore.Make(SystemPropertiesConfig);

var GroupProperties = SubscriptionStore.Make(GroupPropertiesConfig);

export {
  EventsConfig ,
  EventPropertiesConfig ,
  UserPropertiesConfig ,
  SystemPropertiesConfig ,
  GroupPropertiesConfig ,
  Events ,
  EventProperties ,
  UserProperties ,
  SystemProperties ,
  GroupProperties ,
}
/* Events Not a pure module */
