// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import LodashDebounce from "lodash.debounce";
import * as StringSimilarity from "string-similarity";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as NamespaceIndexStore from "./namespaceIndex/NamespaceIndexStore.mjs";
import * as IntelligenceNameFeedbackEndpoint from "../../shared/endpoints/IntelligenceNameFeedbackEndpoint.mjs";

function isValid(validationResult) {
  if (validationResult.isIllegal === false && validationResult.inconsistentCase === false && validationResult.isEmpty === false) {
    return validationResult.conflictingName === undefined;
  } else {
    return false;
  }
}

function validateInput(existingNames, forceAllowName, stateName, commonCase, withSimilarNames) {
  var existingNames$1 = Belt_Array.keepU(existingNames, (function (item) {
          return Case.to_("Camel", item) !== Case.to_("Camel", forceAllowName);
        }));
  var conflictingName = NameUtils.getConflictingNames__array(existingNames$1, stateName);
  var isEmpty = NameUtils.isEmpty(stateName);
  var isIllegal = Belt_Option.isSome(conflictingName) || isEmpty;
  var similarNames = withSimilarNames ? Belt_Array.mapU(Belt_SortArray.stableSortByU(Belt_Array.keepU(Belt_Array.mapU(existingNames$1, (function (name) {
                        return [
                                name,
                                StringSimilarity.compareTwoStrings(Case.camel(name).toLowerCase(), Case.camel(stateName).toLowerCase())
                              ];
                      })), (function (param) {
                    return param[1] >= 0.7;
                  })), (function (param, param$1) {
                var b = param$1[1];
                var a = param[1];
                if (a > b) {
                  return 1;
                } else if (a < b) {
                  return -1;
                } else {
                  return 0;
                }
              })), (function (param) {
            return param[0];
          })) : [];
  var inconsistentCase = !NameUtils.isCorrectCase(commonCase, stateName);
  return {
          isIllegal: isIllegal,
          inconsistentCase: inconsistentCase,
          isEmpty: isEmpty,
          conflictingName: conflictingName,
          similarNames: similarNames
        };
}

function validateInputWithAction(send, existingNames, name, stateName, commonCase, withSimilarNames) {
  Curry._1(send, {
        TAG: "UpdateValidation",
        _0: {
          NAME: "Result",
          VAL: validateInput(existingNames, name, stateName, commonCase, withSimilarNames)
        }
      });
}

var debouncedValidation = LodashDebounce((function (send, existingNames, name, stateName, commonCase) {
        return function (param) {
          return validateInputWithAction(send, existingNames, name, stateName, commonCase, param);
        };
      }), 400, {
      leading: false,
      trailing: true
    });

var debouncedIntelligenceFeedback = LodashDebounce((function (send, param, param$1, existingNames, stateName, commonCase) {
        var namingActionType = param$1[1];
        var itemType = param$1[0];
        var schemaId = param[1];
        var schemaBundle = param[0];
        if (stateName === "") {
          return Curry._1(send, {
                      TAG: "UpdateIntelligenceFeedback",
                      _0: "Init"
                    });
        } else {
          if (param[2]) {
            Curry._1(send, {
                  TAG: "UpdateIntelligenceFeedback",
                  _0: "Loading"
                });
            AnalyticsRe.intelligenceNamingFeedbackInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, itemType, namingActionType, Js_array.joinWith("\n", existingNames).length / 4 | 0, "UnlimitedAccess", schemaBundle.branchId, schemaId);
            $$Promise.$$catch(Firebase.Auth.unsafeGetCurrentUser(Firebase.app().auth()).getIdToken().then(function (userToken) {
                          return fetch(Firebase.apiUrl + "/intelligence/name", {
                                      method: "POST",
                                      body: Caml_option.some(JSON.stringify(IntelligenceNameFeedbackEndpoint.$$Request.encode({
                                                    schemaId: schemaId,
                                                    nameSpace: existingNames,
                                                    itemType: itemType === "Property" ? "Property" : "Event",
                                                    case: commonCase,
                                                    inputName: stateName
                                                  }))),
                                      headers: Caml_option.some(new Headers([
                                                [
                                                  "Accept",
                                                  "application/json"
                                                ],
                                                [
                                                  "Content-Type",
                                                  "application/json"
                                                ],
                                                [
                                                  "Authorization",
                                                  "Bearer " + userToken
                                                ]
                                              ]))
                                    });
                        }).then(function (prim) {
                        return prim.json();
                      }).then(function (responseJson) {
                      try {
                        var result = IntelligenceNameFeedbackEndpoint.$$Response.decode(responseJson);
                        return Curry._1(send, {
                                    TAG: "UpdateIntelligenceFeedback",
                                    _0: {
                                      NAME: "Result",
                                      VAL: result.feedback
                                    }
                                  });
                      }
                      catch (raw_error){
                        var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                        console.error("Intelligence feedback error", error);
                        return Curry._1(send, {
                                    TAG: "UpdateIntelligenceFeedback",
                                    _0: "Init"
                                  });
                      }
                    }), (function (error) {
                    console.error("Intelligence feedback error", error);
                    return Promise.resolve(Curry._1(send, {
                                    TAG: "UpdateIntelligenceFeedback",
                                    _0: "Init"
                                  }));
                  }));
          } else {
            AnalyticsRe.intelligenceNamingFeedbackInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, itemType, namingActionType, Js_array.joinWith("\n", existingNames).length / 4 | 0, "NoAccess", schemaBundle.branchId, schemaId);
          }
          return ;
        }
      }), 1000, {
      leading: false,
      trailing: true
    });

function useDebouncedSimilarNamesWithNamespaceIndex(existingNames, conflictingName, itemType, itemSubType, nameInputValue) {
  var debouncedNameInputValue = Hooks.useDebounced((function (param) {
          return nameInputValue;
        }), 500);
  var namespaceIndex;
  if (itemType === "Property") {
    if (itemSubType !== undefined) {
      switch (itemSubType) {
        case "EventProperty" :
            namespaceIndex = Curry._1(NamespaceIndexStore.EventProperties.use, undefined).items;
            break;
        case "UserProperty" :
            namespaceIndex = Curry._1(NamespaceIndexStore.UserProperties.use, undefined).items;
            break;
        case "SystemProperty" :
            namespaceIndex = Curry._1(NamespaceIndexStore.SystemProperties.use, undefined).items;
            break;
        case "GroupProperty" :
            namespaceIndex = undefined;
            break;
        
      }
    } else {
      namespaceIndex = undefined;
    }
  } else {
    namespaceIndex = itemType === "Event" ? Curry._1(NamespaceIndexStore.Events.use, undefined).items : undefined;
  }
  var existingNamesOnOtherBranches = Belt_Array.mapU(Belt_MapString.toArray(namespaceIndex), (function (param) {
          return [
                  param[0],
                  param[1]
                ];
        }));
  var existingNamesOnBranchWithNamespaceItems = Belt_Array.map(existingNames, (function (name) {
          return [
                  name,
                  undefined
                ];
        }));
  var existingNamesWithNamespaceItems = Belt_Array.concat(existingNamesOnBranchWithNamespaceItems, existingNamesOnOtherBranches);
  return React.useMemo((function (param) {
                if (Belt_Option.isSome(conflictingName)) {
                  return [];
                }
                var allSimilarNames = Belt_Array.mapU(Belt_Array.keepU(Belt_Array.mapU(existingNamesWithNamespaceItems, (function (param) {
                                  var name = param[0];
                                  return [
                                          [
                                            name,
                                            param[1]
                                          ],
                                          StringSimilarity.compareTwoStrings(Case.camel(name).toLowerCase(), Case.camel(debouncedNameInputValue).toLowerCase())
                                        ];
                                })), (function (param) {
                              return param[1] >= 0.7;
                            })).sort(function (param, param$1) {
                          return Caml.float_compare(param$1[1], param[1]);
                        }), (function (param) {
                        return param[0];
                      }));
                return Belt_Array.slice(allSimilarNames, 0, 5);
              }), [
              existingNamesWithNamespaceItems,
              debouncedNameInputValue,
              conflictingName
            ]);
}

function getItemLink(itemName, maybeNamespaceItems, getPossibleItemLink, itemType) {
  if (maybeNamespaceItems !== undefined && maybeNamespaceItems.length !== 0) {
    if (itemType === "Property") {
      var indexItem = Belt_Array.getExn(maybeNamespaceItems, 0);
      var propertyId = indexItem.itemId;
      var branchId = indexItem.branchId;
      return Router.Link.addDrawerItem(undefined, {
                  NAME: "branchPreview",
                  VAL: [
                    branchId,
                    {
                      NAME: "property",
                      VAL: [
                        propertyId,
                        undefined
                      ]
                    }
                  ]
                });
    }
    if (itemType !== "Event") {
      return ;
    }
    var indexItem$1 = Belt_Array.getExn(maybeNamespaceItems, 0);
    var eventId = indexItem$1.itemId;
    var branchId$1 = indexItem$1.branchId;
    return Router.Link.addDrawerItem(undefined, {
                NAME: "branchPreview",
                VAL: [
                  branchId$1,
                  {
                    NAME: "event",
                    VAL: [
                      eventId,
                      undefined,
                      undefined,
                      false
                    ]
                  }
                ]
              });
  }
  return Belt_Option.map(Belt_Option.flatMap(getPossibleItemLink, (function (getPossibleItemLink) {
                    return Curry._1(getPossibleItemLink, itemName);
                  })), (function (param) {
                return param[0];
              }));
}

export {
  isValid ,
  validateInput ,
  validateInputWithAction ,
  debouncedValidation ,
  debouncedIntelligenceFeedback ,
  useDebouncedSimilarNamesWithNamespaceIndex ,
  getItemLink ,
}
/* debouncedValidation Not a pure module */
