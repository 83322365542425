// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as InspectorEventShape from "../models/InspectorEventShape.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "eventNames",
                Json_encode.array((function (prim) {
                        return prim;
                      }), t.eventNames)
              ],
              tl: /* [] */0
            });
}

function decode(json) {
  try {
    return {
            TAG: "Ok",
            _0: {
              eventNames: Json_decode.field("eventNames", (function (param) {
                      return Json_decode.array(Json_decode.string, param);
                    }), json)
            }
          };
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    return {
            TAG: "Error",
            _0: error
          };
  }
}

var RequestBody = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.array(InspectorEventShape.encode, t);
}

function decode$1(json) {
  return Json_decode.array(InspectorEventShape.decode, json);
}

var ResponseBody = {
  encode: encode$1,
  decode: decode$1
};

export {
  RequestBody ,
  ResponseBody ,
}
/* No side effect */
