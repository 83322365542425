// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CmdK from "../CmdK.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Zustand from "../Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CmdKActions from "../CmdKActions.mjs";
import * as FilterOrder from "../../../shared/models/FilterOrder.mjs";
import * as RouterStore from "../RouterStore.mjs";
import * as QueryString from "query-string";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as InspectorIssueStatus from "../../../shared/models/InspectorIssueStatus.mjs";
import * as InspectorIssuesTypes from "../InspectorIssuesTypes.mjs";
import * as InspectorIssuesFilter from "../../../shared/models/InspectorIssuesFilter.mjs";
import * as ImportReview__V2__Utils from "../import/ImportReview__V2__Utils.mjs";
import * as InspectorIssuesFilterUtils from "../../../shared/utils/InspectorIssuesFilterUtils.mjs";

var allowedQueryParams = Belt_SetString.fromArray([
      "status",
      ImportReview__V2__Utils.QueryParams.importStartedLocation,
      ImportReview__V2__Utils.QueryParams.importSuccess,
      ImportReview__V2__Utils.QueryParams.resolvedIssuesCount,
      ImportReview__V2__Utils.QueryParams.eventsImportedCount,
      ImportReview__V2__Utils.QueryParams.propertiesImportedCount,
      ImportReview__V2__Utils.QueryParams.sourcesImportedCount
    ]);

function removeSavedViewFromPathRec(_path, _newPath) {
  while(true) {
    var newPath = _newPath;
    var path = _path;
    if (!path) {
      return Belt_List.reverse(newPath);
    }
    var a = path.hd;
    if (a === "view") {
      var match = path.tl;
      if (match) {
        _path = match.tl;
        continue ;
      }
      _newPath = {
        hd: a,
        tl: newPath
      };
      _path = path.tl;
      continue ;
    }
    _newPath = {
      hd: a,
      tl: newPath
    };
    _path = path.tl;
    continue ;
  };
}

function removeSavedViewFromPath(path) {
  return removeSavedViewFromPathRec(path, /* [] */0);
}

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      var match = RouterStore.Schema.getState().schemaRoute;
      var tmp;
      if (typeof match === "object" && match.NAME === "inspector") {
        var match$1 = match.VAL;
        tmp = typeof match$1 === "object" && match$1.NAME === "savedView" ? match$1.VAL : undefined;
      } else {
        tmp = undefined;
      }
      return {
              savedViewId: tmp,
              savedViews: "Initial",
              setSavedViews: (function (savedViews) {
                  set(function (state) {
                        return {
                                savedViewId: state.savedViewId,
                                savedViews: savedViews,
                                setSavedViews: state.setSavedViews,
                                setSavedViewId: state.setSavedViewId
                              };
                      });
                }),
              setSavedViewId: (function (savedViewId) {
                  set(function (state) {
                        return {
                                savedViewId: savedViewId,
                                savedViews: state.savedViews,
                                setSavedViews: state.setSavedViews,
                                setSavedViewId: state.setSavedViewId
                              };
                      });
                })
            };
    });

function getState(param) {
  return Curry._1(ZustandStore.getState, store);
}

function useSetSavedViewId(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.setSavedViewId;
              }));
}

function useSetSavedViews(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.setSavedViews;
              }));
}

function useSavedViewState(param) {
  var savedViews = Curry._2(ZustandStore.use, store, (function (state) {
          return state.savedViews;
        }));
  var savedViewId = Curry._2(ZustandStore.use, store, (function (state) {
          return state.savedViewId;
        }));
  return React.useMemo((function (param) {
                if (typeof savedViews !== "object") {
                  if (savedViews === "Initial") {
                    if (savedViewId !== undefined) {
                      return {
                              savedViewStatus: "loading",
                              savedView: undefined
                            };
                    } else {
                      return {
                              savedViewStatus: "noSelection",
                              savedView: undefined
                            };
                    }
                  } else if (savedViewId !== undefined) {
                    return {
                            savedViewStatus: "notFound",
                            savedView: undefined
                          };
                  } else {
                    return {
                            savedViewStatus: "noSelection",
                            savedView: undefined
                          };
                  }
                }
                if (savedViewId === undefined) {
                  return {
                          savedViewStatus: "noSelection",
                          savedView: undefined
                        };
                }
                var savedView = Belt_List.getBy(savedViews.VAL, (function (param) {
                        return param.id === savedViewId;
                      }));
                if (savedView !== undefined) {
                  return {
                          savedViewStatus: "success",
                          savedView: savedView
                        };
                } else {
                  return {
                          savedViewStatus: "notFound",
                          savedView: undefined
                        };
                }
              }), [
              savedViews,
              savedViewId
            ]);
}

function useSavedViews(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.savedViews;
              }));
}

function useSavedViewManager(param) {
  var match = WorkspaceContext.use();
  var maybeId = RouterStore.Schema.useSavedViewId();
  var savedViews = FirebaseFetcherHooks.useSavedViews(match.id);
  var setSavedViewId = Curry._2(ZustandStore.use, store, (function (state) {
          return state.setSavedViewId;
        }));
  var setSavedViews = Curry._2(ZustandStore.use, store, (function (state) {
          return state.setSavedViews;
        }));
  var tmp;
  if (typeof savedViews === "object") {
    if (savedViews.NAME === "Loaded") {
      var savedViews$1 = savedViews.VAL;
      tmp = Belt_List.length(savedViews$1) > 0 ? Belt_Array.concat([Curry.app(CmdK.Action.t, [
                    "saved-views",
                    {
                      TAG: "Command",
                      _0: "Saved Views..."
                    },
                    1.2,
                    undefined,
                    undefined,
                    (function (param) {
                        return React.createElement(CmdKActions.CmdKIcon.make, {
                                    type_: "search",
                                    size: "small"
                                  });
                      }),
                    undefined,
                    "search",
                    undefined
                  ])], Belt_List.toArray(Belt_List.map(savedViews$1, (function (savedView) {
                        return Curry.app(CmdK.Action.t, [
                                    savedView.id,
                                    {
                                      TAG: "SavedView",
                                      _0: savedView.name
                                    },
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (param) {
                                        return React.createElement(CmdKActions.CmdKIcon.make, {
                                                    type_: "filter",
                                                    size: "small"
                                                  });
                                      }),
                                    (function (param) {
                                        Router.Schema.pushSchemaRoute(undefined, undefined, {
                                              NAME: "inspector",
                                              VAL: {
                                                NAME: "savedView",
                                                VAL: savedView.id
                                              }
                                            });
                                      }),
                                    "search",
                                    undefined
                                  ]);
                      })))) : [];
    } else {
      tmp = [];
    }
  } else {
    tmp = [];
  }
  Kbar.useRegisterActions(tmp, [savedViews]);
  React.useEffect((function (param) {
          Curry._1(setSavedViews, savedViews);
        }), [savedViews]);
  React.useEffect((function (param) {
          Curry._1(setSavedViewId, maybeId);
        }), [maybeId]);
}

var SavedViewStore = {
  StoreConfig: StoreConfig,
  ZustandStore: ZustandStore,
  store: store,
  getState: getState,
  useSetSavedViewId: useSetSavedViewId,
  useSetSavedViews: useSetSavedViews,
  useSavedViewState: useSavedViewState,
  useSavedViews: useSavedViews,
  useSavedViewManager: useSavedViewManager
};

function getStringValues(values) {
  return Belt_Array.keepMap(decodeURIComponent(values).split(","), (function (item) {
                var item$1 = item.trim();
                if (item$1 === "") {
                  return ;
                } else {
                  return item$1;
                }
              }));
}

var getValueStringForKey = Js_dict.get;

function getValuesForKey(d, key) {
  return Belt_Option.map(Js_dict.get(d, key), getStringValues);
}

function getOrderedByFromSearchDict(d) {
  var order = getValuesForKey(d, "orderedBy");
  if (order === undefined) {
    return ;
  }
  if (order.length !== 2) {
    return ;
  }
  var columnId = order[0];
  var match = order[1];
  switch (match) {
    case "asc" :
        return [
                columnId,
                "asc"
              ];
    case "desc" :
        return [
                columnId,
                "desc"
              ];
    default:
      return ;
  }
}

function orderToQueryParam(order) {
  return Belt_Option.map(order, (function (order) {
                return order[0] + "," + encodeURIComponent(FilterOrder.sortDirectionToJs(order[1]));
              }));
}

function stringifySavedView(savedView) {
  if (savedView !== undefined) {
    return savedView.id + ":" + Belt_Array.map(savedView.filters, InspectorIssuesFilter.Filter.toKeyValue).join(",") + ":" + Belt_Option.getWithDefault(orderToQueryParam(savedView.order), "");
  } else {
    return "";
  }
}

var _map = {"sourceIds":"sourceIds","issueTypes":"issueTypes","categoryIds":"categoryIds","tags":"tags","eventName":"eventName","propertyName":"propertyName","releases":"releases"};

function queryFilterKeyToJs(param) {
  return param;
}

function queryFilterKeyFromJs(param) {
  return _map[param];
}

var queryFilterKeys = [
  "sourceIds",
  "issueTypes",
  "categoryIds",
  "tags",
  "eventName",
  "propertyName",
  "releases"
];

var queryFilterKeyStrings = Belt_Array.concat(Belt_Array.map(queryFilterKeys, queryFilterKeyToJs), ["noFilters"]);

function getFilterQueryString(search) {
  return QueryString.stringify(Js_dict.fromArray(Belt_Array.keep(Js_dict.entries(QueryString.parse(search)), (function (param) {
                        var key = param[0];
                        return Belt_Array.some(queryFilterKeyStrings, (function (queryParam) {
                                      return queryParam === key;
                                    }));
                      }))));
}

function filterTypeToQueryFilterKey(filterType) {
  if (filterType === "eventName") {
    return "eventName";
  } else if (filterType === "tag") {
    return "tags";
  } else if (filterType === "issueType") {
    return "issueTypes";
  } else if (filterType === "propertyName") {
    return "propertyName";
  } else if (filterType === "category") {
    return "categoryIds";
  } else if (filterType === "source") {
    return "sourceIds";
  } else {
    return "releases";
  }
}

function useFilters(param) {
  var match = RescriptReactRouter.useUrl(undefined, undefined);
  var search = match.search;
  var searchDict = React.useMemo((function (param) {
          return QueryString.parse(search);
        }), [search]);
  var match$1 = useSavedViewState();
  var savedView = match$1.savedView;
  var savedViewStatus = match$1.savedViewStatus;
  var orderedBy = React.useMemo((function (param) {
          if (savedView === undefined) {
            return getOrderedByFromSearchDict(searchDict);
          }
          var order = savedView.order;
          if (getOrderedByFromSearchDict(searchDict) === undefined && Caml_obj.equal(order, InspectorIssuesTypes.defaultOrder)) {
            return ;
          } else if (getOrderedByFromSearchDict(searchDict) === undefined) {
            return order;
          } else {
            return getOrderedByFromSearchDict(searchDict);
          }
        }), [
        Js_dict.get(searchDict, "orderedBy"),
        stringifySavedView(savedView)
      ]);
  var noFiltersInQuery = Belt_Array.every(Object.keys(searchDict), (function (key) {
          return !Belt_Array.some(queryFilterKeyStrings, (function (queryParam) {
                        return queryParam === key;
                      }));
        }));
  var groupedFilters = React.useMemo((function (param) {
          if (noFiltersInQuery && savedViewStatus === "success") {
            if (savedView !== undefined) {
              return InspectorIssuesFilterUtils.groupFilters(savedView.filters);
            } else {
              return [];
            }
          } else {
            return Belt_Array.keepMap(Belt_Array.keep((
                              search.startsWith("?") ? search.slice(1) : search
                            ).split("&"), (function (keyVal) {
                              return keyVal !== "";
                            })), (function (keyVal) {
                          var match = keyVal.split("=");
                          if (match.length !== 2) {
                            return ;
                          }
                          var key = match[0];
                          var value = match[1];
                          if (value.trim() === "") {
                            return ;
                          }
                          var match$1 = queryFilterKeyFromJs(key);
                          if (match$1 !== undefined) {
                            if (match$1 === "issueTypes") {
                              return {
                                      NAME: "issueTypes",
                                      VAL: Belt_Array.keepMap(Belt_Option.getWithDefault(getValuesForKey(searchDict, key), []), InspectorIssuesFilter.FilterIssueType.fromString)
                                    };
                            } else if (match$1 === "sourceIds") {
                              return {
                                      NAME: "sources",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            } else if (match$1 === "categoryIds") {
                              return {
                                      NAME: "categories",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            } else if (match$1 === "releases") {
                              return {
                                      NAME: "releases",
                                      VAL: Belt_Array.slice(Belt_Array.keepMap(Belt_Option.getWithDefault(getValuesForKey(searchDict, key), []), InspectorIssuesFilter.Filter.Release.fromString), 0, 1)
                                    };
                            } else if (match$1 === "eventName") {
                              return {
                                      NAME: "eventNames",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            } else if (match$1 === "propertyName") {
                              return {
                                      NAME: "propertyNames",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            } else {
                              return {
                                      NAME: "tags",
                                      VAL: Belt_Option.getWithDefault(getValuesForKey(searchDict, key), [])
                                    };
                            }
                          }
                          
                        }));
          }
        }), [
        search,
        stringifySavedView(savedView)
      ]);
  return React.useMemo((function (param) {
                return {
                        filters: Belt_Array.concatMany(Belt_Array.map(groupedFilters, InspectorIssuesFilter.GroupedFilter.toFilters)),
                        orderedBy: orderedBy,
                        groupedFilters: groupedFilters
                      };
              }), [
              groupedFilters,
              orderedBy,
              stringifySavedView(savedView)
            ]);
}

function useDraftState(param) {
  var match = RescriptReactRouter.useUrl(undefined, undefined);
  var search = match.search;
  var searchDict = React.useMemo((function (param) {
          return QueryString.parse(search);
        }), [search]);
  var isEmpty = React.useMemo((function (param) {
          if (Belt_Array.some(InspectorIssuesFilter.FilterType.availableFilterTypes, (function (filterType) {
                    return Js_dict.get(searchDict, filterTypeToQueryFilterKey(filterType)) !== undefined;
                  })) || Js_dict.get(searchDict, "orderedBy") !== undefined) {
            return true;
          } else {
            return Js_dict.get(searchDict, "noFilters") !== undefined;
          }
        }), [searchDict]);
  var isDraft = React.useMemo((function (param) {
          if (isEmpty) {
            return true;
          } else {
            return Js_dict.get(searchDict, "draft") !== undefined;
          }
        }), [searchDict]);
  return {
          isDraft: isDraft,
          isEmpty: isEmpty
        };
}

function useSourceIds(param) {
  var match = useFilters();
  var groupedFilters = match.groupedFilters;
  var getSourceIds = function (groupedFilters) {
    return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.keepMap(groupedFilters, (function (groupedFilter) {
                              if (typeof groupedFilter === "object" && groupedFilter.NAME === "sources") {
                                return groupedFilter.VAL;
                              }
                              
                            })))));
  };
  var match$1 = React.useState(function (param) {
        return getSourceIds(groupedFilters);
      });
  var setFilteredSourceIds = match$1[1];
  Hooks.useDidUpdate1((function (param) {
          Curry._1(setFilteredSourceIds, (function (param) {
                  return getSourceIds(groupedFilters);
                }));
        }), [getSourceIds(groupedFilters).join(",")]);
  return match$1[0];
}

function getQueryString(groupedFilters, orderedBy) {
  var filtersQueryParams = Belt_Array.keepMap(groupedFilters, (function (groupedFilter) {
          var variant = groupedFilter.NAME;
          if (variant === "propertyNames") {
            return [
                    "propertyName",
                    encodeURIComponent(groupedFilter.VAL.join(","))
                  ];
          } else if (variant === "issueTypes") {
            return [
                    "issueTypes",
                    encodeURIComponent(Belt_Array.map(groupedFilter.VAL, (function (issueType) {
                                  return InspectorIssuesFilter.FilterIssueType.toString(issueType);
                                })).join(","))
                  ];
          } else if (variant === "releases") {
            return [
                    "releases",
                    encodeURIComponent(groupedFilter.VAL.join(","))
                  ];
          } else if (variant === "categories") {
            return [
                    "categoryIds",
                    encodeURIComponent(groupedFilter.VAL.join(","))
                  ];
          } else if (variant === "eventNames") {
            return [
                    "eventName",
                    encodeURIComponent(groupedFilter.VAL.join(","))
                  ];
          } else if (variant === "sources") {
            return [
                    "sourceIds",
                    encodeURIComponent(groupedFilter.VAL.join(","))
                  ];
          } else {
            return [
                    "tags",
                    encodeURIComponent(groupedFilter.VAL.join(","))
                  ];
          }
        }));
  var orderedByQueryParams = Belt_Option.mapWithDefault(orderToQueryParam(orderedBy), [], (function (orderedByQueryValue) {
          return [[
                    "orderedBy",
                    encodeURIComponent(orderedByQueryValue)
                  ]];
        }));
  var existingAllowedQueryParams = Belt_Array.keepU(Js_dict.entries(QueryString.parse(Router.getSearch())), (function (param) {
          return Belt_SetString.has(allowedQueryParams, param[0]);
        }));
  var queryStringArray = Belt_Array.concatMany([
        existingAllowedQueryParams,
        filtersQueryParams,
        orderedByQueryParams
      ]);
  if (queryStringArray.length !== 0) {
    return "?" + QueryString.stringify(Js_dict.fromArray(queryStringArray));
  } else {
    return "";
  }
}

function getUpdatedGroupFilters(oldGroupedFilters, newGroupedFilters) {
  var introducedGroups = Belt_Array.keepMap(newGroupedFilters, (function (newGroupedFilter) {
          var oldGroup = Belt_Array.getBy(oldGroupedFilters, (function (groupedFilter) {
                  return InspectorIssuesFilter.GroupedFilter.toFilterType(newGroupedFilter) === InspectorIssuesFilter.GroupedFilter.toFilterType(groupedFilter);
                }));
          if (Belt_Option.isSome(oldGroup)) {
            return ;
          } else {
            return newGroupedFilter;
          }
        }));
  return Belt_Array.concat(Belt_Array.keepMap(oldGroupedFilters, (function (groupedFilter) {
                    return Belt_Array.getBy(newGroupedFilters, (function (newGroupedFilter) {
                                  return InspectorIssuesFilter.GroupedFilter.toFilterType(newGroupedFilter) === InspectorIssuesFilter.GroupedFilter.toFilterType(groupedFilter);
                                }));
                  })), introducedGroups);
}

function resetFilters(param) {
  var search = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search;
  var searchWithoutFilters = QueryString.stringify(Js_dict.fromArray(Belt_Array.keep(Js_dict.entries(QueryString.parse(search)), (function (param) {
                  var key = param[0];
                  return !Belt_Array.some(queryFilterKeyStrings, (function (queryParam) {
                                return queryParam === key;
                              }));
                }))));
  RescriptReactRouter.push("/" + Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/") + (
        searchWithoutFilters === "" ? "" : "?" + searchWithoutFilters
      ));
}

function useSetFilters(param) {
  var filterState = useFilters();
  var match = useSavedViewState();
  var savedView = match.savedView;
  var savedViewStatus = match.savedViewStatus;
  var setNewQueryString = function (oldGroupedFilters, newGroupedFilters, orderedBy) {
    var consolidatedGroupedFilters = getUpdatedGroupFilters(oldGroupedFilters, newGroupedFilters);
    var newPathString = Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/");
    var newQueryString = getQueryString(consolidatedGroupedFilters, orderedBy);
    RescriptReactRouter.push("/" + newPathString + newQueryString + (
          getFilterQueryString(newQueryString) === "" && savedView !== undefined ? (
              newQueryString === "" ? "?" : "&"
            ) + "noFilters=true" : ""
        ));
  };
  return function (setCb) {
    var searchDict = QueryString.parse(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search);
    var newGroupedFilters = InspectorIssuesFilterUtils.groupFilters(Curry._1(setCb, filterState.filters));
    if (savedView === undefined) {
      if (savedViewStatus === "loading") {
        return ;
      } else {
        return setNewQueryString(filterState.groupedFilters, newGroupedFilters, filterState.orderedBy);
      }
    }
    var filters = savedView.filters;
    if (!(InspectorIssuesFilter.GroupedFilter.cmpMany(InspectorIssuesFilterUtils.groupFilters(filters), newGroupedFilters) === 0 && getFilterQueryString(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search) !== "")) {
      if (InspectorIssuesFilter.GroupedFilter.cmpMany(InspectorIssuesFilterUtils.groupFilters(filters), newGroupedFilters) === 0) {
        return ;
      } else {
        return setNewQueryString(filterState.groupedFilters, newGroupedFilters, filterState.orderedBy);
      }
    }
    var orderedBy = getOrderedByFromSearchDict(searchDict);
    if (orderedBy !== undefined && !Caml_obj.equal(orderedBy, savedView.order)) {
      return setNewQueryString(filterState.groupedFilters, newGroupedFilters, orderedBy);
    } else {
      return resetFilters();
    }
  };
}

function useOrderedBy(param) {
  return useFilters().orderedBy;
}

function getOrderedBy(param) {
  return getOrderedByFromSearchDict(QueryString.parse(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).search));
}

function useSetOrderedBy(param) {
  var match = useFilters();
  var groupedFilters = match.groupedFilters;
  var match$1 = useSavedViewState();
  var savedView = match$1.savedView;
  var savedViewStatus = match$1.savedViewStatus;
  return function (newOrderedBy) {
    var useFilterQueryString = savedView !== undefined ? InspectorIssuesFilter.GroupedFilter.cmpMany(InspectorIssuesFilterUtils.groupFilters(savedView.filters), groupedFilters) !== 0 : savedViewStatus !== "loading";
    if (savedView !== undefined) {
      if (Caml_obj.equal(savedView.order, newOrderedBy)) {
        return RescriptReactRouter.push("/" + Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/") + getQueryString(useFilterQueryString ? groupedFilters : [], undefined));
      }
      
    } else if (savedViewStatus === "loading") {
      return ;
    }
    if (Caml_obj.equal(newOrderedBy, InspectorIssuesTypes.defaultOrder)) {
      return RescriptReactRouter.push("/" + Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/") + getQueryString(useFilterQueryString ? groupedFilters : [], undefined));
    } else {
      return RescriptReactRouter.push("/" + Belt_List.toArray(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path).join("/") + getQueryString(groupedFilters, newOrderedBy));
    }
  };
}

var toString = InspectorIssueStatus.StatusQueryParam.toString;

function fromString(value) {
  try {
    return Belt_Option.getWithDefault(Belt_Option.mapU(value, (function (value) {
                      return InspectorIssueStatus.StatusQueryParam.fromString(value);
                    })), "Unresolved");
  }
  catch (exn){
    return "Unresolved";
  }
}

var IssueStatusQueryParam = Router.QueryParams.MakeFixedKey({
      toString: toString,
      fromString: fromString,
      key: "status"
    });

function useIssueStatusQuery(param) {
  var match = Curry._1(IssueStatusQueryParam.use, undefined);
  var set = match.set;
  var value = match.value;
  return React.useMemo((function (param) {
                return [
                        value,
                        set
                      ];
              }), [
              value,
              set
            ]);
}

export {
  allowedQueryParams ,
  removeSavedViewFromPathRec ,
  removeSavedViewFromPath ,
  SavedViewStore ,
  getStringValues ,
  getValueStringForKey ,
  getValuesForKey ,
  getOrderedByFromSearchDict ,
  orderToQueryParam ,
  stringifySavedView ,
  queryFilterKeyToJs ,
  queryFilterKeyFromJs ,
  queryFilterKeys ,
  queryFilterKeyStrings ,
  getFilterQueryString ,
  filterTypeToQueryFilterKey ,
  useFilters ,
  useDraftState ,
  useSourceIds ,
  getQueryString ,
  getUpdatedGroupFilters ,
  resetFilters ,
  useSetFilters ,
  useOrderedBy ,
  getOrderedBy ,
  useSetOrderedBy ,
  IssueStatusQueryParam ,
  useIssueStatusQuery ,
}
/* allowedQueryParams Not a pure module */
