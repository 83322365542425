// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Int32 from "rescript/lib/es6/int32.js";
import * as Models from "../../app/src/Models.mjs";
import * as DateFns from "../../app/src/DateFns.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateFns$1 from "date-fns";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "../../app/src/analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as InspectorViewModel from "../../app/src/inspector/InspectorViewModel.mjs";

function isEnterprise(planName) {
  switch (planName) {
    case "Enterprise" :
    case "EnterpriseV2" :
        return true;
    default:
      return false;
  }
}

function printPlanName(planName) {
  switch (planName) {
    case "Scholarship" :
        return "Scholarship";
    case "Growth" :
    case "GrowthV2" :
        return "Growth";
    case "Starter" :
        return "Starter";
    case "TeamV2" :
    case "TeamV2Annual" :
    case "TeamV3" :
    case "TeamV3Annual" :
        return "Team";
    case "Enterprise" :
    case "EnterpriseV2" :
        return "Enterprise";
    default:
      return "Free";
  }
}

function printExplicitPlanName(planName) {
  switch (planName) {
    case "Empty" :
        return "Legacy";
    case "Free" :
        return "Free";
    case "FreeV2" :
        return "FreeV2";
    case "Scholarship" :
        return "Scholarship";
    case "Growth" :
        return "Growth";
    case "GrowthV2" :
        return "GrowthV2";
    case "Enterprise" :
        return "Enterprise";
    case "Starter" :
        return "Starter";
    case "TeamV2" :
        return "TeamV2";
    case "TeamV2Annual" :
        return "TeamV2Annual";
    case "FreeV3" :
        return "FreeV3";
    case "TeamV3" :
        return "TeamV3";
    case "TeamV3Annual" :
        return "TeamV3Annual";
    case "EnterpriseV2" :
        return "EnterpriseV2";
    
  }
}

function printMaxThreshold(maxSources) {
  if (typeof maxSources === "object") {
    return maxSources.VAL.toString();
  } else {
    return "Unlimited";
  }
}

function printSettingName(setting) {
  switch (setting) {
    case "ProtectedMainBranch" :
        return "ProtectedMainBranch";
    case "RequireAdminApproval" :
        return "RequireAdminApproval";
    case "NumRequiredApprovals" :
        return "NumRequiredApprovals";
    case "ForceBranchAudit" :
        return "ForceBranchAudit";
    case "SmartSearchEnabled" :
        return "SmartSearchEnabled";
    
  }
}

function parseSettings(schema) {
  var maybeSettingsJson = schema.settings;
  var decodeSetting = function (json, setting, decoder) {
    var partial_arg = printSettingName(setting);
    return Json_decode.optional((function (param) {
                  return Json_decode.field(partial_arg, decoder, param);
                }), json);
  };
  return Belt_Option.getWithDefault(Belt_Option.map((maybeSettingsJson == null) ? undefined : Caml_option.some(maybeSettingsJson), (function (json) {
                    var protectedMainBranch = Belt_Option.getWithDefault(decodeSetting(json, "ProtectedMainBranch", Json_decode.bool), false);
                    var forceBranchAudit = Belt_Option.getWithDefault(decodeSetting(json, "ForceBranchAudit", Json_decode.bool), false);
                    return {
                            protectedMainBranch: protectedMainBranch,
                            requireAdminApproval: protectedMainBranch && Belt_Option.getWithDefault(decodeSetting(json, "RequireAdminApproval", Json_decode.bool), false),
                            numRequiredApprovals: protectedMainBranch ? Belt_Option.getWithDefault(decodeSetting(json, "NumRequiredApprovals", Json_decode.$$int), 1) : 1,
                            forceBranchAudit: forceBranchAudit,
                            smartSearchEnabled: decodeSetting(json, "SmartSearchEnabled", Json_decode.bool)
                          };
                  })), {
              protectedMainBranch: false,
              requireAdminApproval: false,
              numRequiredApprovals: 1,
              forceBranchAudit: false,
              smartSearchEnabled: undefined
            });
}

function featureSettingToAnalyticsSetting(setting) {
  switch (setting) {
    case "ProtectedMainBranch" :
        return "ProtectedMainBranch";
    case "RequireAdminApproval" :
        return "RequireAdminApproval";
    case "NumRequiredApprovals" :
        return "NumberOfRequiredApprovals";
    case "ForceBranchAudit" :
        return "ForceBranchAudit";
    case "SmartSearchEnabled" :
        return "SmartSearch";
    
  }
}

function toggleSetting(workspace, firebase, setting, schemaBundle) {
  var settingEnabled;
  switch (setting) {
    case "ProtectedMainBranch" :
        settingEnabled = workspace.settings.protectedMainBranch;
        break;
    case "RequireAdminApproval" :
        settingEnabled = workspace.settings.requireAdminApproval;
        break;
    case "NumRequiredApprovals" :
        settingEnabled = Pervasives.failwith("NumRequiredApprovals has integer value and can't be toggled.");
        break;
    case "ForceBranchAudit" :
        settingEnabled = workspace.settings.forceBranchAudit;
        break;
    case "SmartSearchEnabled" :
        settingEnabled = Belt_Option.getWithDefault(workspace.settings.smartSearchEnabled, false);
        break;
    
  }
  var toggledSetting = !settingEnabled;
  var toggledSettingJson = Json_encode.object_({
        hd: [
          printSettingName(setting),
          toggledSetting
        ],
        tl: /* [] */0
      });
  firebase.firestore().collection("schemas").doc(workspace.id).set({
        settings: toggledSettingJson
      }, {"merge": true});
  var match = workspace.settings.smartSearchEnabled;
  AnalyticsRe.workspaceSettingUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, toggledSetting ? "On" : "Off", featureSettingToAnalyticsSetting(setting), undefined, schemaBundle.branchId, schemaBundle.schemaId, workspace.id, workspace.settings.protectedMainBranch ? "ProtectedMainBranch" : "None", undefined, workspace.settings.protectedMainBranch ? (
          workspace.settings.requireAdminApproval ? "Admin" : "Editor"
        ) : "None", workspace.settings.numRequiredApprovals, match !== undefined ? (
          match ? "Enabled" : "Disabled"
        ) : undefined);
}

function initializeSmartSearchAsFalse(workspace, firebase, schemaBundle) {
  var toggledSettingJson = Json_encode.object_({
        hd: [
          "SmartSearchEnabled",
          false
        ],
        tl: /* [] */0
      });
  firebase.firestore().collection("schemas").doc(workspace.id).set({
        settings: toggledSettingJson
      }, {"merge": true});
  AnalyticsRe.workspaceSettingUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Off", "SmartSearch", undefined, schemaBundle.branchId, schemaBundle.schemaId, workspace.id, workspace.settings.protectedMainBranch ? "ProtectedMainBranch" : "None", undefined, workspace.settings.protectedMainBranch ? (
          workspace.settings.requireAdminApproval ? "Admin" : "Editor"
        ) : "None", workspace.settings.numRequiredApprovals, "Disabled");
}

function privateUpdateIntSetting(workspace, firebase, setting, value, schemaBundle) {
  var updatedSettingJson = Json_encode.object_({
        hd: [
          printSettingName(setting),
          value
        ],
        tl: /* [] */0
      });
  firebase.firestore().collection("schemas").doc(workspace.id).set({
        settings: updatedSettingJson
      }, {"merge": true});
  var match = workspace.settings.smartSearchEnabled;
  AnalyticsRe.workspaceSettingUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, undefined, featureSettingToAnalyticsSetting(setting), value, schemaBundle.branchId, schemaBundle.schemaId, workspace.id, workspace.settings.protectedMainBranch ? "ProtectedMainBranch" : "None", undefined, workspace.settings.protectedMainBranch ? (
          workspace.settings.requireAdminApproval ? "Admin" : "Editor"
        ) : "None", workspace.settings.numRequiredApprovals, match !== undefined ? (
          match ? "Enabled" : "Disabled"
        ) : undefined);
}

function updateNumRequiredApprovals(workspace, firebase, value, schemaBundle) {
  privateUpdateIntSetting(workspace, firebase, "NumRequiredApprovals", value, schemaBundle);
}

var emptyPlan = {
  name: "Empty",
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: "Unlimited",
  maxUsers: "Unlimited",
  usersIncluded: "Unlimited",
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: "CountAsEditors",
  editors: true,
  auditLog: true,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: true,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: true,
  approvalWorkflow: true,
  pinnedProperties: true,
  nameMapping: true,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: false,
  activityLog: true,
  inspectorLookback: "Last30Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  eventVariants: true,
  discrepancyConfig: true,
  forcePreferredCasing: true,
  canDuplicateItems: true,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: false,
  hasAdvancedNameConventionsCustomStructs: false,
  domainsIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  hasImpactedStakeholders: false,
  pushPullBetweenBranches: false
};

var empty_planUsageStatus = {
  inspectorEvents: "BelowThreshold",
  implementers: "BelowThreshold"
};

var empty_settings = {
  protectedMainBranch: false,
  requireAdminApproval: false,
  numRequiredApprovals: 1,
  forceBranchAudit: false,
  smartSearchEnabled: undefined
};

var empty_serviceAccounts = [];

var empty = {
  id: "",
  name: "",
  createdAt: undefined,
  plan: emptyPlan,
  trial: undefined,
  planUsageStatus: empty_planUsageStatus,
  avoSchemaVersion: 2,
  members: /* [] */0,
  authMethod: "Default",
  settings: empty_settings,
  serviceAccounts: empty_serviceAccounts,
  lastSeenCodegen: "unset",
  featureFlags: undefined,
  isSandbox: false
};

var secretMenu = {
  hd: "Free",
  tl: {
    hd: "FreeV2",
    tl: {
      hd: "Starter",
      tl: {
        hd: "TeamV2",
        tl: {
          hd: "Scholarship",
          tl: {
            hd: "Growth",
            tl: {
              hd: "GrowthV2",
              tl: {
                hd: "Enterprise",
                tl: /* [] */0
              }
            }
          }
        }
      }
    }
  }
};

function getPlanIndex(plan) {
  return Belt_Option.getWithDefault(Belt_List.head(Belt_List.flatten(Belt_List.mapWithIndexU(secretMenu, (function (index, item) {
                            if (plan.name === item) {
                              return {
                                      hd: index,
                                      tl: /* [] */0
                                    };
                            } else {
                              return /* [] */0;
                            }
                          })))), -1);
}

var free = {
  name: "Free",
  maxSources: {
    NAME: "Limited",
    VAL: 3
  },
  maxDestinations: {
    NAME: "Limited",
    VAL: 1
  },
  maxIntegrations: {
    NAME: "Limited",
    VAL: 1
  },
  maxUsers: {
    NAME: "Limited",
    VAL: 5
  },
  usersIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: true,
  commentOnlyMembers: "NotAvailable",
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: false,
  configEventSourcesAndDestinations: false,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: false,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: false,
  propertyMatchesBySource: false,
  activityLog: true,
  inspectorLookback: "Last1Hour",
  maxCodegenEvents: "Unlimited",
  triggers: false,
  eventVariants: false,
  discrepancyConfig: false,
  forcePreferredCasing: false,
  canDuplicateItems: false,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: {
      NAME: "Monthly",
      VAL: 0
    },
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: false,
  hasAdvancedNameConventionsCustomStructs: false,
  domainsIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  hasImpactedStakeholders: false,
  pushPullBetweenBranches: false
};

var freeV2 = {
  name: "FreeV2",
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: {
    NAME: "Limited",
    VAL: 1
  },
  maxUsers: {
    NAME: "Limited",
    VAL: 2
  },
  usersIncluded: {
    NAME: "Limited",
    VAL: 2
  },
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: "NotAvailable",
  editors: false,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: false,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: false,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: false,
  activityLog: false,
  inspectorLookback: "Last1Hour",
  maxCodegenEvents: "Unlimited",
  triggers: false,
  eventVariants: false,
  discrepancyConfig: false,
  forcePreferredCasing: false,
  canDuplicateItems: false,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: {
      NAME: "Monthly",
      VAL: 0
    },
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: false,
  hasAdvancedNameConventionsCustomStructs: false,
  domainsIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  hasImpactedStakeholders: false,
  pushPullBetweenBranches: false
};

var newrecord = Caml_obj.obj_dup(freeV2);

newrecord.hasAdvancedNameConventionsCustomStructs = false;

newrecord.hasAdvancedNameConventionsPredefinedValues = false;

newrecord.viewersCanComment = true;

newrecord.implementers = {
  usageLimit: {
    NAME: "Monthly",
    VAL: 0
  },
  usageIncluded: {
    NAME: "Monthly",
    VAL: 0
  },
  usageCommitment: undefined
};

newrecord.inspectorEvents = {
  usageLimit: {
    NAME: "Monthly",
    VAL: 100000
  },
  usageIncluded: {
    NAME: "Monthly",
    VAL: 100000
  },
  usageCommitment: {
    NAME: "Monthly",
    VAL: 100000
  }
};

newrecord.collaboration = true;

newrecord.commentOnlyMembers = "NotAvailable";

newrecord.name = "FreeV3";

var starter = {
  name: "Starter",
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: {
    NAME: "Limited",
    VAL: 2
  },
  maxUsers: {
    NAME: "Limited",
    VAL: 30
  },
  usersIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  basePricePerMonth: 300.0,
  costPerExtraSeat: 40.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: {
    TAG: "AdditionalCost",
    _0: 20
  },
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: false,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: false,
  inspectorLookback: "Last24Hours",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  eventVariants: true,
  discrepancyConfig: false,
  forcePreferredCasing: false,
  canDuplicateItems: false,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: {
      NAME: "Monthly",
      VAL: 0
    },
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: false,
  hasAdvancedNameConventionsCustomStructs: false,
  domainsIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  hasImpactedStakeholders: false,
  pushPullBetweenBranches: false
};

var scholarship = {
  name: "Scholarship",
  maxSources: {
    NAME: "Limited",
    VAL: 4
  },
  maxDestinations: {
    NAME: "Limited",
    VAL: 2
  },
  maxIntegrations: {
    NAME: "Limited",
    VAL: 2
  },
  maxUsers: "Unlimited",
  usersIncluded: "Unlimited",
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: true,
  commentOnlyMembers: "NotAvailable",
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 1
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: false,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: false,
  propertyMatchesBySource: false,
  activityLog: true,
  inspectorLookback: "Last24Hours",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  eventVariants: true,
  discrepancyConfig: false,
  forcePreferredCasing: false,
  canDuplicateItems: false,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: {
      NAME: "Monthly",
      VAL: 0
    },
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: false,
  hasAdvancedNameConventionsCustomStructs: false,
  domainsIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  hasImpactedStakeholders: false,
  pushPullBetweenBranches: false
};

var teamV2 = {
  name: "TeamV2",
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: {
    NAME: "Limited",
    VAL: 5
  },
  maxUsers: {
    NAME: "Limited",
    VAL: 100
  },
  usersIncluded: {
    NAME: "Limited",
    VAL: 5
  },
  basePricePerMonth: 300.0,
  costPerExtraSeat: 60,
  countsViewersAsUsers: false,
  commentOnlyMembers: {
    TAG: "Limited",
    _0: 20
  },
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 3
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: false,
  approvalWorkflow: true,
  pinnedProperties: true,
  nameMapping: true,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last7Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  eventVariants: true,
  discrepancyConfig: true,
  forcePreferredCasing: false,
  canDuplicateItems: true,
  forceBranchAudit: false,
  expandedBranchAudit: true,
  closedBranches: true,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: {
      NAME: "Monthly",
      VAL: 0
    },
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: true,
  hasAdvancedNameConventionsCustomStructs: false,
  domainsIncluded: "Unlimited",
  hasImpactedStakeholders: false,
  pushPullBetweenBranches: true
};

var newrecord$1 = Caml_obj.obj_dup(teamV2);

newrecord$1.editors = true;

newrecord$1.costPerExtraSeat = 50;

newrecord$1.basePricePerMonth = 250;

newrecord$1.name = "TeamV2Annual";

var newrecord$2 = Caml_obj.obj_dup(teamV2);

newrecord$2.hasAdvancedNameConventionsCustomStructs = false;

newrecord$2.hasAdvancedNameConventionsPredefinedValues = true;

newrecord$2.viewersCanComment = true;

newrecord$2.implementers = {
  usageLimit: "Unlimited",
  usageIncluded: {
    NAME: "Monthly",
    VAL: 0
  },
  usageCommitment: undefined
};

newrecord$2.inspectorEvents = {
  usageLimit: {
    NAME: "Monthly",
    VAL: 100000
  },
  usageIncluded: {
    NAME: "Monthly",
    VAL: 100000
  },
  usageCommitment: {
    NAME: "Monthly",
    VAL: 100000
  }
};

newrecord$2.commentOnlyMembers = "NotAvailable";

newrecord$2.name = "TeamV3";

var newrecord$3 = Caml_obj.obj_dup(newrecord$2);

newrecord$3.implementers = {
  usageLimit: "Unlimited",
  usageIncluded: {
    NAME: "Monthly",
    VAL: 0
  },
  usageCommitment: undefined
};

newrecord$3.inspectorEvents = {
  usageLimit: {
    NAME: "Annual",
    VAL: 1200000
  },
  usageIncluded: {
    NAME: "Annual",
    VAL: 1200000
  },
  usageCommitment: {
    NAME: "Annual",
    VAL: 1200000
  }
};

newrecord$3.name = "TeamV3Annual";

var growth = {
  name: "Growth",
  maxSources: {
    NAME: "Limited",
    VAL: 5
  },
  maxDestinations: {
    NAME: "Limited",
    VAL: 5
  },
  maxIntegrations: {
    NAME: "Limited",
    VAL: 5
  },
  maxUsers: "Unlimited",
  usersIncluded: "Unlimited",
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: true,
  commentOnlyMembers: "Unlimited",
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 3
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: false,
  advancedProtectedMainBranch: false,
  approvalWorkflow: true,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: false,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last7Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  eventVariants: true,
  discrepancyConfig: true,
  forcePreferredCasing: false,
  canDuplicateItems: true,
  forceBranchAudit: false,
  expandedBranchAudit: false,
  closedBranches: false,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: {
      NAME: "Monthly",
      VAL: 0
    },
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: true,
  hasAdvancedNameConventionsCustomStructs: false,
  domainsIncluded: "Unlimited",
  hasImpactedStakeholders: false,
  pushPullBetweenBranches: false
};

var growthV2 = {
  name: "GrowthV2",
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: {
    NAME: "Limited",
    VAL: 5
  },
  maxUsers: "Unlimited",
  usersIncluded: {
    NAME: "Limited",
    VAL: 30
  },
  basePricePerMonth: 2000.0,
  costPerExtraSeat: 60.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: "Unlimited",
  editors: true,
  auditLog: false,
  maxServiceAccounts: {
    NAME: "Limited",
    VAL: 3
  },
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: false,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: true,
  approvalWorkflow: true,
  pinnedProperties: false,
  nameMapping: false,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last7Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  eventVariants: true,
  discrepancyConfig: true,
  forcePreferredCasing: false,
  canDuplicateItems: true,
  forceBranchAudit: false,
  expandedBranchAudit: true,
  closedBranches: true,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: {
      NAME: "Monthly",
      VAL: 0
    },
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: false,
  hasAdvancedNameConventionsCustomStructs: false,
  domainsIncluded: "Unlimited",
  hasImpactedStakeholders: false,
  pushPullBetweenBranches: true
};

var enterprise = {
  name: "Enterprise",
  maxSources: "Unlimited",
  maxDestinations: "Unlimited",
  maxIntegrations: "Unlimited",
  maxUsers: "Unlimited",
  usersIncluded: "Unlimited",
  basePricePerMonth: 0.0,
  costPerExtraSeat: 0.0,
  countsViewersAsUsers: false,
  commentOnlyMembers: "Unlimited",
  editors: true,
  auditLog: true,
  maxServiceAccounts: "Unlimited",
  collaboration: true,
  configEventSourcesAndDestinations: true,
  sso: true,
  canProtectMainBranch: true,
  advancedProtectedMainBranch: true,
  approvalWorkflow: true,
  pinnedProperties: true,
  nameMapping: true,
  propertyAbsenceByEventAndSource: true,
  propertyMatchesBySource: true,
  activityLog: true,
  inspectorLookback: "Last30Days",
  maxCodegenEvents: "Unlimited",
  triggers: true,
  eventVariants: true,
  discrepancyConfig: true,
  forcePreferredCasing: true,
  canDuplicateItems: true,
  forceBranchAudit: true,
  expandedBranchAudit: true,
  closedBranches: true,
  inspectorEvents: {
    usageLimit: "Unlimited",
    usageIncluded: "Unlimited",
    usageCommitment: undefined
  },
  implementers: {
    usageLimit: "Unlimited",
    usageIncluded: {
      NAME: "Monthly",
      VAL: 0
    },
    usageCommitment: undefined
  },
  viewersCanComment: false,
  hasAdvancedNameConventionsPredefinedValues: true,
  hasAdvancedNameConventionsCustomStructs: true,
  domainsIncluded: "Unlimited",
  hasImpactedStakeholders: true,
  pushPullBetweenBranches: true
};

var newrecord$4 = Caml_obj.obj_dup(enterprise);

newrecord$4.viewersCanComment = true;

newrecord$4.implementers = {
  usageLimit: "Unlimited",
  usageIncluded: {
    NAME: "Monthly",
    VAL: 0
  },
  usageCommitment: undefined
};

newrecord$4.inspectorEvents = {
  usageLimit: "Unlimited",
  usageIncluded: {
    NAME: "Annual",
    VAL: 12000000
  },
  usageCommitment: {
    NAME: "Annual",
    VAL: 12000000
  }
};

newrecord$4.commentOnlyMembers = "NotAvailable";

newrecord$4.name = "EnterpriseV2";

function toJson(plan) {
  var costPerExtraSeat = plan.commentOnlyMembers;
  var tmp;
  if (typeof costPerExtraSeat !== "object") {
    switch (costPerExtraSeat) {
      case "NotAvailable" :
          tmp = [[
              "availability",
              "NotAvailable"
            ]];
          break;
      case "CountAsEditors" :
          tmp = [[
              "availability",
              "CountAsEditors"
            ]];
          break;
      case "Unlimited" :
          tmp = [[
              "availability",
              "Unlimited"
            ]];
          break;
      
    }
  } else {
    tmp = costPerExtraSeat.TAG === "AdditionalCost" ? [
        [
          "availability",
          "AdditionalCost"
        ],
        [
          "costPerExtraSeat",
          costPerExtraSeat._0.toString()
        ]
      ] : [
        [
          "availability",
          "Limited"
        ],
        [
          "limit",
          String(costPerExtraSeat._0)
        ]
      ];
  }
  return Js_dict.fromArray([
              [
                "name",
                printExplicitPlanName(plan.name)
              ],
              [
                "maxSources",
                printMaxThreshold(plan.maxSources)
              ],
              [
                "maxDestinations",
                printMaxThreshold(plan.maxDestinations)
              ],
              [
                "maxIntegrations",
                printMaxThreshold(plan.maxIntegrations)
              ],
              [
                "maxUsers",
                printMaxThreshold(plan.maxUsers)
              ],
              [
                "usersIncluded",
                printMaxThreshold(plan.usersIncluded)
              ],
              [
                "basePricePerMonth",
                plan.basePricePerMonth.toString()
              ],
              [
                "costPerExtraSeat",
                plan.costPerExtraSeat.toString()
              ],
              [
                "collaboration",
                plan.collaboration
              ],
              [
                "activityLog",
                plan.activityLog
              ],
              [
                "auditLog",
                plan.auditLog
              ],
              [
                "maxServiceAccounts",
                printMaxThreshold(plan.maxServiceAccounts)
              ],
              [
                "configEventSourcesAndDestinations",
                plan.configEventSourcesAndDestinations
              ],
              [
                "sso",
                plan.sso
              ],
              [
                "canProtectMainBranch",
                plan.canProtectMainBranch
              ],
              [
                "advancedProtectedMainBranch",
                plan.advancedProtectedMainBranch
              ],
              [
                "approvalWorkflow",
                plan.approvalWorkflow
              ],
              [
                "propertyAbsenceByEventAndSource",
                plan.propertyAbsenceByEventAndSource
              ],
              [
                "propertyMatchesBySource",
                plan.propertyMatchesBySource
              ],
              [
                "inspectorLookback",
                InspectorViewModel.timeWindowToJs(plan.inspectorLookback)
              ],
              [
                "maxCodegenEvents",
                printMaxThreshold(plan.maxCodegenEvents)
              ],
              [
                "commentOnlyMembers",
                Js_dict.fromArray(tmp)
              ],
              [
                "triggers",
                plan.triggers
              ],
              [
                "eventVariants",
                plan.eventVariants
              ],
              [
                "discrepancyConfig",
                plan.discrepancyConfig
              ],
              [
                "forcePreferredCasing",
                plan.forcePreferredCasing
              ],
              [
                "pinnedProperties",
                plan.pinnedProperties
              ],
              [
                "nameMapping",
                plan.nameMapping
              ],
              [
                "canDuplicateItems",
                plan.canDuplicateItems
              ],
              [
                "forceBranchAudit",
                plan.forceBranchAudit
              ],
              [
                "expandedBranchAudit",
                plan.expandedBranchAudit
              ],
              [
                "closedBranches",
                plan.closedBranches
              ],
              [
                "inspectorEvents",
                Models.UsageLimit.encode(plan.inspectorEvents)
              ],
              [
                "implementers",
                Models.UsageLimit.encode(plan.implementers)
              ],
              [
                "viewersCanComment",
                plan.viewersCanComment
              ],
              [
                "hasAdvancedNameConventionsCustomStructs",
                plan.hasAdvancedNameConventionsCustomStructs
              ],
              [
                "hasAdvancedNameConventionsPredefinedValues",
                plan.hasAdvancedNameConventionsPredefinedValues
              ],
              [
                "domainsIncluded",
                printMaxThreshold(plan.domainsIncluded)
              ],
              [
                "hasImpactedStakeholders",
                plan.hasImpactedStakeholders
              ],
              [
                "pushPullBetweenBranches",
                plan.pushPullBetweenBranches
              ]
            ]);
}

function allPlansJson(param) {
  return [
          toJson(free),
          toJson(freeV2),
          toJson(newrecord),
          toJson(starter),
          toJson(scholarship),
          toJson(growth),
          toJson(growthV2),
          toJson(teamV2),
          toJson(newrecord$2),
          toJson(newrecord$1),
          toJson(newrecord$3),
          toJson(enterprise),
          toJson(newrecord$4)
        ];
}

function nextHint(plan) {
  var match = plan.name;
  switch (match) {
    case "Free" :
    case "FreeV2" :
    case "Scholarship" :
    case "Starter" :
    case "FreeV3" :
        return newrecord$2;
    default:
      return enterprise;
  }
}

function parseOverrideMaxThresholdField(fieldName, json) {
  var partial_arg_0 = function (json) {
    return {
            NAME: "Limited",
            VAL: Json_decode.$$int(json)
          };
  };
  var partial_arg_1 = {
    hd: (function (json) {
        var limit = Json_decode.string(json).toLowerCase();
        if (limit === "unlimited") {
          return "Unlimited";
        } else {
          return {
                  NAME: "Limited",
                  VAL: Caml_format.int_of_string(limit)
                };
        }
      }),
    tl: /* [] */0
  };
  var partial_arg = {
    hd: partial_arg_0,
    tl: partial_arg_1
  };
  var partial_arg$1 = function (param) {
    return Json_decode.oneOf(partial_arg, param);
  };
  var partial_arg$2 = function (param) {
    return Json_decode.field(fieldName, partial_arg$1, param);
  };
  return Json_decode.optional(partial_arg$2, json);
}

function parseOverrideIsEnabledField(fieldName, json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field(fieldName, Json_decode.bool, param);
              }), json);
}

function parseOverrideFloatField(fieldName, json) {
  return Belt_Option.flatMap(Json_decode.optional((function (param) {
                    return Json_decode.field(fieldName, (function (json) {
                                  return Number(Json_decode.string(json));
                                }), param);
                  }), json), (function (f) {
                if (Number.isNaN(f)) {
                  return ;
                } else {
                  return f;
                }
              }));
}

function parseOverrideLadderField(fieldName, stepParser, json) {
  return Belt_Option.flatMap(Json_decode.optional((function (param) {
                    return Json_decode.field(fieldName, Json_decode.string, param);
                  }), json), stepParser);
}

function parseOverrideUsageBasedField(fieldName, json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field(fieldName, Models.UsageLimit.decode, param);
              }), json);
}

function parseOverrides(plan, json) {
  var maxSources = parseOverrideMaxThresholdField("maxSources", json);
  var plan$1;
  if (maxSources !== undefined) {
    var newrecord = Caml_obj.obj_dup(plan);
    newrecord.maxSources = maxSources;
    plan$1 = newrecord;
  } else {
    plan$1 = plan;
  }
  var maxDestinations = parseOverrideMaxThresholdField("maxDestinations", json);
  var plan$2;
  if (maxDestinations !== undefined) {
    var newrecord$1 = Caml_obj.obj_dup(plan$1);
    newrecord$1.maxDestinations = maxDestinations;
    plan$2 = newrecord$1;
  } else {
    plan$2 = plan$1;
  }
  var maxIntegrations = parseOverrideMaxThresholdField("maxIntegrations", json);
  var plan$3;
  if (maxIntegrations !== undefined) {
    var newrecord$2 = Caml_obj.obj_dup(plan$2);
    newrecord$2.maxIntegrations = maxIntegrations;
    plan$3 = newrecord$2;
  } else {
    plan$3 = plan$2;
  }
  var maxUsers = parseOverrideMaxThresholdField("maxUsers", json);
  var plan$4;
  if (maxUsers !== undefined) {
    var newrecord$3 = Caml_obj.obj_dup(plan$3);
    newrecord$3.maxUsers = maxUsers;
    plan$4 = newrecord$3;
  } else {
    plan$4 = plan$3;
  }
  var usersIncluded = parseOverrideMaxThresholdField("usersIncluded", json);
  var plan$5;
  if (usersIncluded !== undefined) {
    var newrecord$4 = Caml_obj.obj_dup(plan$4);
    newrecord$4.usersIncluded = usersIncluded;
    plan$5 = newrecord$4;
  } else {
    plan$5 = plan$4;
  }
  var basePricePerMonth = parseOverrideFloatField("basePricePerMonth", json);
  var plan$6;
  if (basePricePerMonth !== undefined) {
    var newrecord$5 = Caml_obj.obj_dup(plan$5);
    newrecord$5.basePricePerMonth = basePricePerMonth;
    plan$6 = newrecord$5;
  } else {
    plan$6 = plan$5;
  }
  var costPerExtraSeat = parseOverrideFloatField("costPerExtraSeat", json);
  var plan$7;
  if (costPerExtraSeat !== undefined) {
    var newrecord$6 = Caml_obj.obj_dup(plan$6);
    newrecord$6.costPerExtraSeat = costPerExtraSeat;
    plan$7 = newrecord$6;
  } else {
    plan$7 = plan$6;
  }
  var collaboration = parseOverrideIsEnabledField("collaboration", json);
  var plan$8;
  if (collaboration !== undefined) {
    var newrecord$7 = Caml_obj.obj_dup(plan$7);
    newrecord$7.collaboration = collaboration;
    plan$8 = newrecord$7;
  } else {
    plan$8 = plan$7;
  }
  var configEventSourcesAndDestinations = parseOverrideIsEnabledField("configEventSourcesAndDestinations", json);
  var plan$9;
  if (configEventSourcesAndDestinations !== undefined) {
    var newrecord$8 = Caml_obj.obj_dup(plan$8);
    newrecord$8.configEventSourcesAndDestinations = configEventSourcesAndDestinations;
    plan$9 = newrecord$8;
  } else {
    plan$9 = plan$8;
  }
  var sso = parseOverrideIsEnabledField("sso", json);
  var plan$10;
  if (sso !== undefined) {
    var newrecord$9 = Caml_obj.obj_dup(plan$9);
    newrecord$9.sso = sso;
    plan$10 = newrecord$9;
  } else {
    plan$10 = plan$9;
  }
  var canProtectMainBranch = parseOverrideIsEnabledField("canProtectMainBranch", json);
  var plan$11;
  if (canProtectMainBranch !== undefined) {
    var newrecord$10 = Caml_obj.obj_dup(plan$10);
    newrecord$10.canProtectMainBranch = canProtectMainBranch;
    plan$11 = newrecord$10;
  } else {
    plan$11 = plan$10;
  }
  var advancedProtectedMainBranch = parseOverrideIsEnabledField("advancedProtectedMainBranch", json);
  var plan$12;
  if (advancedProtectedMainBranch !== undefined) {
    var newrecord$11 = Caml_obj.obj_dup(plan$11);
    newrecord$11.advancedProtectedMainBranch = advancedProtectedMainBranch;
    plan$12 = newrecord$11;
  } else {
    plan$12 = plan$11;
  }
  var approvalWorkflow = parseOverrideIsEnabledField("approvalWorkflow", json);
  var plan$13;
  if (approvalWorkflow !== undefined) {
    var newrecord$12 = Caml_obj.obj_dup(plan$12);
    newrecord$12.approvalWorkflow = approvalWorkflow;
    plan$13 = newrecord$12;
  } else {
    plan$13 = plan$12;
  }
  var activityLog = parseOverrideIsEnabledField("activityLog", json);
  var plan$14;
  if (activityLog !== undefined) {
    var newrecord$13 = Caml_obj.obj_dup(plan$13);
    newrecord$13.activityLog = activityLog;
    plan$14 = newrecord$13;
  } else {
    plan$14 = plan$13;
  }
  var auditLog = parseOverrideIsEnabledField("auditLog", json);
  var plan$15;
  if (auditLog !== undefined) {
    var newrecord$14 = Caml_obj.obj_dup(plan$14);
    newrecord$14.auditLog = auditLog;
    plan$15 = newrecord$14;
  } else {
    plan$15 = plan$14;
  }
  var maxServiceAccounts = parseOverrideMaxThresholdField("maxServiceAccounts", json);
  var plan$16;
  if (maxServiceAccounts !== undefined) {
    var newrecord$15 = Caml_obj.obj_dup(plan$15);
    newrecord$15.maxServiceAccounts = maxServiceAccounts;
    plan$16 = newrecord$15;
  } else {
    plan$16 = plan$15;
  }
  var inspectorLookback = parseOverrideLadderField("inspectorLookback", InspectorViewModel.timeWindowFromJs, json);
  var plan$17;
  if (inspectorLookback !== undefined) {
    var newrecord$16 = Caml_obj.obj_dup(plan$16);
    newrecord$16.inspectorLookback = inspectorLookback;
    plan$17 = newrecord$16;
  } else {
    plan$17 = plan$16;
  }
  var propertyAbsenceByEventAndSource = parseOverrideIsEnabledField("propertyAbsenceByEventAndSource", json);
  var plan$18;
  if (propertyAbsenceByEventAndSource !== undefined) {
    var newrecord$17 = Caml_obj.obj_dup(plan$17);
    newrecord$17.propertyAbsenceByEventAndSource = propertyAbsenceByEventAndSource;
    plan$18 = newrecord$17;
  } else {
    plan$18 = plan$17;
  }
  var propertyMatchesBySource = parseOverrideIsEnabledField("propertyMatchesBySource", json);
  var plan$19;
  if (propertyMatchesBySource !== undefined) {
    var newrecord$18 = Caml_obj.obj_dup(plan$18);
    newrecord$18.propertyMatchesBySource = propertyMatchesBySource;
    plan$19 = newrecord$18;
  } else {
    plan$19 = plan$18;
  }
  var maxCodegenEvents = parseOverrideMaxThresholdField("maxCodegenEvents", json);
  var plan$20;
  if (maxCodegenEvents !== undefined) {
    var newrecord$19 = Caml_obj.obj_dup(plan$19);
    newrecord$19.maxCodegenEvents = maxCodegenEvents;
    plan$20 = newrecord$19;
  } else {
    plan$20 = plan$19;
  }
  var maxCodegenEvents$1 = parseOverrideMaxThresholdField("maxCodegenEvents", json);
  var plan$21;
  if (maxCodegenEvents$1 !== undefined) {
    var newrecord$20 = Caml_obj.obj_dup(plan$20);
    newrecord$20.maxCodegenEvents = maxCodegenEvents$1;
    plan$21 = newrecord$20;
  } else {
    plan$21 = plan$20;
  }
  var triggers = parseOverrideIsEnabledField("triggers", json);
  var plan$22;
  if (triggers !== undefined) {
    var newrecord$21 = Caml_obj.obj_dup(plan$21);
    newrecord$21.triggers = triggers;
    plan$22 = newrecord$21;
  } else {
    plan$22 = plan$21;
  }
  var eventVariants = parseOverrideIsEnabledField("eventVariants", json);
  var plan$23;
  if (eventVariants !== undefined) {
    var newrecord$22 = Caml_obj.obj_dup(plan$22);
    newrecord$22.eventVariants = eventVariants;
    plan$23 = newrecord$22;
  } else {
    plan$23 = plan$22;
  }
  var discrepancyConfig = parseOverrideIsEnabledField("discrepancyConfig", json);
  var plan$24;
  if (discrepancyConfig !== undefined) {
    var newrecord$23 = Caml_obj.obj_dup(plan$23);
    newrecord$23.discrepancyConfig = discrepancyConfig;
    plan$24 = newrecord$23;
  } else {
    plan$24 = plan$23;
  }
  var forcePreferredCasing = parseOverrideIsEnabledField("forcePreferredCasing", json);
  var plan$25;
  if (forcePreferredCasing !== undefined) {
    var newrecord$24 = Caml_obj.obj_dup(plan$24);
    newrecord$24.forcePreferredCasing = forcePreferredCasing;
    plan$25 = newrecord$24;
  } else {
    plan$25 = plan$24;
  }
  var pinnedProperties = parseOverrideIsEnabledField("pinnedProperties", json);
  var plan$26;
  if (pinnedProperties !== undefined) {
    var newrecord$25 = Caml_obj.obj_dup(plan$25);
    newrecord$25.pinnedProperties = pinnedProperties;
    plan$26 = newrecord$25;
  } else {
    plan$26 = plan$25;
  }
  var nameMapping = parseOverrideIsEnabledField("nameMapping", json);
  var plan$27;
  if (nameMapping !== undefined) {
    var newrecord$26 = Caml_obj.obj_dup(plan$26);
    newrecord$26.nameMapping = nameMapping;
    plan$27 = newrecord$26;
  } else {
    plan$27 = plan$26;
  }
  var canDuplicateEvents = parseOverrideIsEnabledField("canDuplicateEvents", json);
  var plan$28;
  if (canDuplicateEvents !== undefined) {
    var newrecord$27 = Caml_obj.obj_dup(plan$27);
    newrecord$27.canDuplicateItems = canDuplicateEvents;
    plan$28 = newrecord$27;
  } else {
    plan$28 = plan$27;
  }
  var canDuplicateItems = parseOverrideIsEnabledField("canDuplicateItems", json);
  var plan$29;
  if (canDuplicateItems !== undefined) {
    var newrecord$28 = Caml_obj.obj_dup(plan$28);
    newrecord$28.canDuplicateItems = canDuplicateItems;
    plan$29 = newrecord$28;
  } else {
    plan$29 = plan$28;
  }
  var forceBranchAudit = parseOverrideIsEnabledField("forceBranchAudit", json);
  var plan$30;
  if (forceBranchAudit !== undefined) {
    var newrecord$29 = Caml_obj.obj_dup(plan$29);
    newrecord$29.forceBranchAudit = forceBranchAudit;
    plan$30 = newrecord$29;
  } else {
    plan$30 = plan$29;
  }
  var expandedBranchAudit = parseOverrideIsEnabledField("expandedBranchAudit", json);
  var plan$31;
  if (expandedBranchAudit !== undefined) {
    var newrecord$30 = Caml_obj.obj_dup(plan$30);
    newrecord$30.expandedBranchAudit = expandedBranchAudit;
    plan$31 = newrecord$30;
  } else {
    plan$31 = plan$30;
  }
  var closedBranches = parseOverrideIsEnabledField("closedBranches", json);
  var plan$32;
  if (closedBranches !== undefined) {
    var newrecord$31 = Caml_obj.obj_dup(plan$31);
    newrecord$31.closedBranches = closedBranches;
    plan$32 = newrecord$31;
  } else {
    plan$32 = plan$31;
  }
  var inspectorEvents = parseOverrideUsageBasedField("inspectorEvents", json);
  var plan$33;
  if (inspectorEvents !== undefined) {
    var newrecord$32 = Caml_obj.obj_dup(plan$32);
    newrecord$32.inspectorEvents = inspectorEvents;
    plan$33 = newrecord$32;
  } else {
    plan$33 = plan$32;
  }
  var implementers = parseOverrideUsageBasedField("implementers", json);
  var plan$34;
  if (implementers !== undefined) {
    var newrecord$33 = Caml_obj.obj_dup(plan$33);
    newrecord$33.implementers = implementers;
    plan$34 = newrecord$33;
  } else {
    plan$34 = plan$33;
  }
  var viewersCanComment = parseOverrideIsEnabledField("viewersCanComment", json);
  var plan$35;
  if (viewersCanComment !== undefined) {
    var newrecord$34 = Caml_obj.obj_dup(plan$34);
    newrecord$34.viewersCanComment = viewersCanComment;
    plan$35 = newrecord$34;
  } else {
    plan$35 = plan$34;
  }
  var hasAdvancedNameConventionsCustomStructs = parseOverrideIsEnabledField("hasAdvancedNameConventionsCustomStructs", json);
  var plan$36;
  if (hasAdvancedNameConventionsCustomStructs !== undefined) {
    var newrecord$35 = Caml_obj.obj_dup(plan$35);
    newrecord$35.hasAdvancedNameConventionsCustomStructs = hasAdvancedNameConventionsCustomStructs;
    plan$36 = newrecord$35;
  } else {
    plan$36 = plan$35;
  }
  var hasAdvancedNameConventionsPredefinedValues = parseOverrideIsEnabledField("hasAdvancedNameConventionsPredefinedValues", json);
  var plan$37;
  if (hasAdvancedNameConventionsPredefinedValues !== undefined) {
    var newrecord$36 = Caml_obj.obj_dup(plan$36);
    newrecord$36.hasAdvancedNameConventionsPredefinedValues = hasAdvancedNameConventionsPredefinedValues;
    plan$37 = newrecord$36;
  } else {
    plan$37 = plan$36;
  }
  var domainsIncluded = parseOverrideMaxThresholdField("domainsIncluded", json);
  var plan$38;
  if (domainsIncluded !== undefined) {
    var newrecord$37 = Caml_obj.obj_dup(plan$37);
    newrecord$37.domainsIncluded = domainsIncluded;
    plan$38 = newrecord$37;
  } else {
    plan$38 = plan$37;
  }
  var hasImpactedStakeholders = parseOverrideIsEnabledField("hasImpactedStakeholders", json);
  var plan$39;
  if (hasImpactedStakeholders !== undefined) {
    var newrecord$38 = Caml_obj.obj_dup(plan$38);
    newrecord$38.hasImpactedStakeholders = hasImpactedStakeholders;
    plan$39 = newrecord$38;
  } else {
    plan$39 = plan$38;
  }
  var pushPullBetweenBranches = parseOverrideIsEnabledField("pushPullBetweenBranches", json);
  if (pushPullBetweenBranches === undefined) {
    return plan$39;
  }
  var newrecord$39 = Caml_obj.obj_dup(plan$39);
  newrecord$39.pushPullBetweenBranches = pushPullBetweenBranches;
  return newrecord$39;
}

function planFromPlanName(planName) {
  switch (planName) {
    case "Empty" :
        return emptyPlan;
    case "Free" :
        return free;
    case "FreeV2" :
        return freeV2;
    case "Scholarship" :
        return scholarship;
    case "Growth" :
        return growth;
    case "GrowthV2" :
        return growthV2;
    case "Enterprise" :
        return enterprise;
    case "Starter" :
        return starter;
    case "TeamV2" :
        return teamV2;
    case "TeamV2Annual" :
        return newrecord$1;
    case "FreeV3" :
        return newrecord;
    case "TeamV3" :
        return newrecord$2;
    case "TeamV3Annual" :
        return newrecord$3;
    case "EnterpriseV2" :
        return newrecord$4;
    
  }
}

function planNameFromString(planName) {
  var match = planName.toLowerCase();
  switch (match) {
    case "empty" :
        return "Empty";
    case "enterprise" :
        return "Enterprise";
    case "enterprisev2" :
        return "EnterpriseV2";
    case "free" :
        return "Free";
    case "freev2" :
        return "FreeV2";
    case "freev3" :
        return "FreeV3";
    case "growth" :
        return "Growth";
    case "growthv2" :
        return "GrowthV2";
    case "scholarship" :
        return "Scholarship";
    case "starter" :
        return "Starter";
    case "teamv2" :
        return "TeamV2";
    case "teamv2annual" :
        return "TeamV2Annual";
    case "teamv3" :
        return "TeamV3";
    case "teamv3annual" :
        return "TeamV3Annual";
    default:
      return ;
  }
}

function planNameFromStringExn(planName) {
  var plan = planNameFromString(planName);
  if (plan !== undefined) {
    return plan;
  } else {
    return Pervasives.failwith("unknown plan name: " + planName);
  }
}

function planNameToString(planName) {
  switch (planName) {
    case "Empty" :
        return "empty";
    case "Free" :
        return "free";
    case "FreeV2" :
        return "freev2";
    case "Scholarship" :
        return "scholarship";
    case "Growth" :
        return "growth";
    case "GrowthV2" :
        return "growthv2";
    case "Enterprise" :
        return "enterprise";
    case "Starter" :
        return "starter";
    case "TeamV2" :
        return "teamv2";
    case "TeamV2Annual" :
        return "teamv2annual";
    case "FreeV3" :
        return "freev3";
    case "TeamV3" :
        return "teamv3";
    case "TeamV3Annual" :
        return "teamv3annual";
    case "EnterpriseV2" :
        return "enterprisev2";
    
  }
}

function planWithOverridesFromString(planName, json) {
  if (json !== undefined) {
    return parseOverrides(planFromPlanName(planNameFromStringExn(planName)), json);
  } else {
    return planFromPlanName(planNameFromStringExn(planName));
  }
}

function planWithOverrides(planName, json) {
  if (json !== undefined) {
    return parseOverrides(planFromPlanName(planName), json);
  } else {
    return planFromPlanName(planName);
  }
}

function planNameFromSchemaPlan(maybePlanName) {
  return Belt_Option.map(maybePlanName, planNameFromStringExn);
}

var Instances_publicPlans = {
  hd: "FreeV2",
  tl: {
    hd: "TeamV2",
    tl: {
      hd: "Enterprise",
      tl: /* [] */0
    }
  }
};

var Instances = {
  publicPlans: Instances_publicPlans,
  secretMenu: secretMenu,
  getPlanIndex: getPlanIndex,
  free: free,
  freeV2: freeV2,
  freeV3: newrecord,
  starter: starter,
  scholarship: scholarship,
  teamV2: teamV2,
  teamV2Annual: newrecord$1,
  teamV3: newrecord$2,
  teamV3Annual: newrecord$3,
  growth: growth,
  growthV2: growthV2,
  enterprise: enterprise,
  enterpriseV2: newrecord$4,
  toJson: toJson,
  allPlansJson: allPlansJson,
  nextHint: nextHint,
  parseOverrideMaxThresholdField: parseOverrideMaxThresholdField,
  parseOverrideIsEnabledField: parseOverrideIsEnabledField,
  parseOverrideFloatField: parseOverrideFloatField,
  parseOverrideLadderField: parseOverrideLadderField,
  parseOverrideUsageBasedField: parseOverrideUsageBasedField,
  parseOverrides: parseOverrides,
  planFromPlanName: planFromPlanName,
  planNameFromString: planNameFromString,
  planNameFromStringExn: planNameFromStringExn,
  planNameToString: planNameToString,
  planWithOverridesFromString: planWithOverridesFromString,
  planWithOverrides: planWithOverrides,
  planNameFromSchemaPlan: planNameFromSchemaPlan
};

function getUsedSlots(workspace, model) {
  return [
          Belt_List.length(model.sources),
          workspace.plan.maxSources
        ];
}

var Source = {
  getUsedSlots: getUsedSlots
};

function getUsedSlots$1(workspace, model) {
  return [
          Belt_List.length(model.destinations),
          workspace.plan.maxDestinations
        ];
}

var Destination = {
  getUsedSlots: getUsedSlots$1
};

function getUsedSlots$2(workspace, model) {
  return [
          Belt_List.length(model.integrations),
          workspace.plan.maxIntegrations
        ];
}

var Integration = {
  getUsedSlots: getUsedSlots$2
};

function membersExcludingAvo(workspace) {
  return Belt_List.keep(workspace.members, (function (member) {
                return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(member.email), true, (function (email) {
                              if (email.endsWith("@avo.sh")) {
                                return false;
                              } else {
                                return !email.endsWith("@avo.app");
                              }
                            }));
              }));
}

function commentOnlyPaidSlots(workspace) {
  var freeLimit = workspace.plan.commentOnlyMembers;
  if (typeof freeLimit !== "object") {
    switch (freeLimit) {
      case "CountAsEditors" :
          break;
      case "NotAvailable" :
      case "Unlimited" :
          return 0;
      
    }
  } else if (freeLimit.TAG !== "AdditionalCost") {
    return Math.max(Belt_List.length(Belt_List.keep(membersExcludingAvo(workspace), (function (member) {
                          return Caml_obj.equal(AnalyticsRe.roleFromJs(member.role), "CommentOnly");
                        }))) - freeLimit._0 | 0, 0);
  }
  return Belt_List.length(Belt_List.keep(membersExcludingAvo(workspace), (function (member) {
                    return Caml_obj.equal(AnalyticsRe.roleFromJs(member.role), "CommentOnly");
                  })));
}

function commentOnlyFreeSlots(workspace) {
  var freeLimit = workspace.plan.commentOnlyMembers;
  if (typeof freeLimit !== "object") {
    if (freeLimit === "Unlimited") {
      return Int32.max_int;
    } else {
      return 0;
    }
  } else if (freeLimit.TAG === "Limited") {
    return Math.max(freeLimit._0 - Belt_List.length(Belt_List.keep(membersExcludingAvo(workspace), (function (member) {
                          return Caml_obj.equal(AnalyticsRe.roleFromJs(member.role), "CommentOnly");
                        }))) | 0, 0);
  } else {
    return 0;
  }
}

function getUsedSlots$3(workspace) {
  var membersExcludingAvo$1 = membersExcludingAvo(workspace);
  Belt_List.forEach(membersExcludingAvo$1, (function (member) {
          var match = member.email;
          if (match !== undefined) {
            return ;
          } else {
            console.error("Cannot calculate limits without emails");
            return ;
          }
        }));
  var tmp;
  if (workspace.plan.countsViewersAsUsers) {
    tmp = Belt_List.length(membersExcludingAvo$1);
  } else {
    var commentOnlyExceeded = commentOnlyPaidSlots(workspace);
    tmp = Belt_List.length(Belt_List.keep(membersExcludingAvo$1, (function (member) {
                var match = AnalyticsRe.roleFromJs(member.role);
                if (match !== undefined) {
                  return !(match === "CommentOnly" || match === "Viewer" || match === "BillingOnly");
                } else {
                  return true;
                }
              }))) + commentOnlyExceeded | 0;
  }
  return [
          tmp,
          workspace.plan.maxUsers
        ];
}

var User = {
  membersExcludingAvo: membersExcludingAvo,
  commentOnlyPaidSlots: commentOnlyPaidSlots,
  commentOnlyFreeSlots: commentOnlyFreeSlots,
  getUsedSlots: getUsedSlots$3
};

function getUsedSlots$4(workspace, model, entityType) {
  if (entityType === "Destination") {
    return getUsedSlots$1(workspace, model);
  } else if (entityType === "Integration") {
    return getUsedSlots$2(workspace, model);
  } else if (entityType === "User") {
    return getUsedSlots$3(workspace);
  } else {
    return getUsedSlots(workspace, model);
  }
}

function makeTrialFromSchema(schema, maybeTrial) {
  var avoSchemaVersion = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.avoSchemaVersion), 1);
  if (avoSchemaVersion !== 1) {
    return Belt_Option.map(maybeTrial, (function (trial) {
                  var planName = Belt_Option.getWithDefault(Belt_Option.map(trial.plan, planNameFromStringExn), "Enterprise");
                  var plan = planWithOverrides(planName, Caml_option.nullable_to_opt(trial.planOverrides));
                  return {
                          id: trial.id,
                          createdAt: Belt_Option.getExn(Caml_option.nullable_to_opt(Js_null_undefined.bind(trial.createdAt, (function (ts) {
                                          return ts.toDate();
                                        })))),
                          endAt: Belt_Option.getExn(Caml_option.nullable_to_opt(Js_null_undefined.bind(trial.endAt, (function (ts) {
                                          return ts.toDate();
                                        })))),
                          requesterId: undefined,
                          plan: plan
                        };
                }));
  }
  var isTrialing = schema.billingStatus === "trialing";
  var createdAt = Models.toDate(schema.trialStarted);
  var endAt = Belt_Option.map((createdAt == null) ? undefined : Caml_option.some(createdAt), (function (param) {
          return DateFns.addDays(14, param);
        }));
  if (!isTrialing) {
    return ;
  }
  if (createdAt == null) {
    return ;
  }
  if (endAt === undefined) {
    return ;
  }
  var endAt$1 = Caml_option.valFromOption(endAt);
  if (DateFns$1.isFuture(endAt$1)) {
    return {
            id: "legacy-trial",
            createdAt: createdAt,
            endAt: endAt$1,
            requesterId: undefined,
            plan: planWithOverrides("Enterprise", Json_encode.object_(/* [] */0))
          };
  }
  
}

function getPlanNameFromSchema(schema) {
  var avoSchemaVersion = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.avoSchemaVersion), 1);
  var match = schema.billingStatus;
  if (avoSchemaVersion !== 1 || match !== "subscribed") {
    return Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(schema.plan), planNameFromStringExn), "Free");
  } else {
    return Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(schema.plan), planNameFromStringExn), "Enterprise");
  }
}

function fromSchema(schema, maybeTrial, members, authMethod, serviceAccounts) {
  var members$1 = Belt_List.sortU(members, (function (a, b) {
          return Caml.float_compare(Belt_Option.mapWithDefault(Models.toDateOption(a.createdAt), 0, (function (prim) {
                            return prim.getTime();
                          })), Belt_Option.mapWithDefault(Models.toDateOption(b.createdAt), 0, (function (prim) {
                            return prim.getTime();
                          })));
        }));
  var avoSchemaVersion = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.avoSchemaVersion), 1);
  var settings = parseSettings(schema);
  var planName = getPlanNameFromSchema(schema);
  var trial = makeTrialFromSchema(schema, maybeTrial);
  var plan = planWithOverrides(planName, Caml_option.nullable_to_opt(schema.planOverrides));
  var lastSeenCodegen = schema.lastSeenCodegen;
  var lastSeenCodegen$1;
  if (lastSeenCodegen == null) {
    lastSeenCodegen$1 = "unset";
  } else {
    var sourceIdDates = Belt_Array.keepMap(Object.keys(lastSeenCodegen), (function (sourceId) {
            return Belt_Option.flatMap(Js_dict.get(lastSeenCodegen, sourceId), (function (fbTimestamp) {
                          return Belt_Option.map(Caml_option.nullable_to_opt(Models.toDate(fbTimestamp)), (function (date) {
                                        return [
                                                sourceId,
                                                date
                                              ];
                                      }));
                        }));
          }));
    lastSeenCodegen$1 = sourceIdDates.length !== 0 ? ({
          NAME: "lastSeen",
          VAL: sourceIdDates
        }) : "neverSeen";
  }
  var getPlanUsageStatus = function (usageStatusItem) {
    var planUsageStatus = schema.planUsageStatus;
    if (planUsageStatus == null) {
      return "BelowThreshold";
    } else {
      return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(usageStatusItem === "implementers" ? planUsageStatus.implementers : planUsageStatus.inspectorEvents), "BelowThreshold", (function (usage) {
                    var match = usage.status;
                    var match$1 = usage.usagePercentage;
                    if (match === "ExceededThreshold") {
                      if (!(match$1 == null)) {
                        return {
                                NAME: "ExceededThreshold",
                                VAL: match$1
                              };
                      }
                      
                    } else {
                      if (match !== "ReachingThreshold") {
                        return "BelowThreshold";
                      }
                      if (!(match$1 == null)) {
                        return {
                                NAME: "ReachingThreshold",
                                VAL: match$1
                              };
                      }
                      
                    }
                    return Pervasives.failwith("Expected usagePercentage for Inspector usage status in schema " + schema.id);
                  }));
    }
  };
  return {
          id: schema.id,
          name: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.name), ""),
          createdAt: Caml_option.nullable_to_opt(Js_null_undefined.bind(schema.createdAt, (function (ts) {
                      return ts.toDate();
                    }))),
          plan: plan,
          trial: trial,
          planUsageStatus: {
            inspectorEvents: getPlanUsageStatus("inspectorEvents"),
            implementers: getPlanUsageStatus("implementers")
          },
          avoSchemaVersion: avoSchemaVersion,
          members: members$1,
          authMethod: authMethod,
          settings: settings,
          serviceAccounts: serviceAccounts,
          lastSeenCodegen: lastSeenCodegen$1,
          featureFlags: Caml_option.nullable_to_opt(schema.featureFlags),
          isSandbox: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.isSandbox), false)
        };
}

var maxRequiredApprovals = 3;

export {
  isEnterprise ,
  printPlanName ,
  printExplicitPlanName ,
  printMaxThreshold ,
  printSettingName ,
  maxRequiredApprovals ,
  parseSettings ,
  featureSettingToAnalyticsSetting ,
  toggleSetting ,
  initializeSmartSearchAsFalse ,
  privateUpdateIntSetting ,
  updateNumRequiredApprovals ,
  emptyPlan ,
  empty ,
  Instances ,
  Source ,
  Destination ,
  Integration ,
  User ,
  getUsedSlots$4 as getUsedSlots,
  makeTrialFromSchema ,
  getPlanNameFromSchema ,
  fromSchema ,
}
/*  Not a pure module */
