// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

var modes = [
  "Always",
  "SometimesAll",
  "SometimesByEvent",
  "BySource"
];

function getModeString(mode) {
  switch (mode) {
    case "Always" :
        return "Always sent";
    case "SometimesAll" :
        return "Sometimes sent on all events";
    case "SometimesByEvent" :
        return "Sometimes sent by event";
    case "BySource" :
        return "Depending on source";
    
  }
}

var ParseMode = /* @__PURE__ */Caml_exceptions.create("PropertyAbsenceViewHelpers.ParseMode");

function parseModeString(mode) {
  switch (mode) {
    case "Always sent" :
        return "Always";
    case "Depending on source" :
        return "BySource";
    case "Sometimes sent by event" :
        return "SometimesByEvent";
    case "Sometimes sent on all events" :
        return "SometimesAll";
    default:
      throw {
            RE_EXN_ID: ParseMode,
            Error: new Error()
          };
  }
}

function getMoreComplex(a, b) {
  if (Belt_Option.mapWithDefault(Belt_Array.getBy(modes, (function (mode) {
                return mode === a ? true : mode === b;
              })), true, (function (mode) {
            return mode === a;
          }))) {
    return b;
  } else {
    return a;
  }
}

function getEventAndSourceStateString(state) {
  switch (state) {
    case "Unavailable" :
        return "Unavailable";
    case "Never" :
        return "Never";
    case "Sometimes" :
        return "Sometimes";
    case "Always" :
        return "Always";
    
  }
}

function getEventAndSourceStateStringForBranchDiff(state) {
  switch (state) {
    case "Unavailable" :
        return "";
    case "Never" :
        return "Never";
    case "Sometimes" :
        return "Sometimes";
    case "Always" :
        return "Always";
    
  }
}

function parseEventAndSourceStateString(state) {
  switch (state) {
    case "Always" :
        return "Always";
    case "Never" :
        return "Never";
    case "Sometimes" :
        return "Sometimes";
    default:
      return "Unavailable";
  }
}

function getAbsenceByEventAndSource(eventAbsences, eventId, sourceId) {
  return Belt_Option.flatMap(Belt_MapString.get(eventAbsences, eventId), (function (sourceAbsences) {
                if (sourceAbsences.TAG !== "AllSources") {
                  return Belt_Option.map(Belt_MapString.get(sourceAbsences._0, sourceId), (function (sourceAbsence) {
                                if (typeof sourceAbsence !== "object") {
                                  return "Never";
                                } else {
                                  return "Sometimes";
                                }
                              }));
                }
                var tmp = sourceAbsences._0;
                if (typeof tmp !== "object") {
                  return "Never";
                } else {
                  return "Sometimes";
                }
              }));
}

function getAllEventSources(modelSources, propertyEvents) {
  var sources = Belt_List.flatten(Belt_List.map(propertyEvents, (function ($$event) {
              var match = AvoConfig.determineSendingToSources(modelSources, $$event);
              if (typeof match === "object") {
                return match.VAL;
              } else if (match === "None") {
                return /* [] */0;
              } else {
                return modelSources;
              }
            })));
  return Belt_List.keepMap(BeltListExtensions.dedupeString(Belt_List.map(sources, (function (source) {
                        return source.id;
                      }))), (function (sourceId) {
                return Belt_List.getBy(sources, (function (source) {
                              return source.id === sourceId;
                            }));
              }));
}

function getFullStateMatrix(propertyEvents, modelSources, absence) {
  if (absence !== undefined && typeof absence === "object") {
    if (absence.TAG === "SometimesSent") {
      var allEventSources = getAllEventSources(modelSources, propertyEvents);
      return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(propertyEvents, (function ($$event) {
                            var eventSources = AvoConfig.determineSendingToSources(modelSources, $$event);
                            return [
                                    $$event.id,
                                    Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(allEventSources, (function (source) {
                                                    var eventSendsSource = typeof eventSources === "object" ? Belt_Option.isSome(Belt_List.getBy(eventSources.VAL, (function (eventSource) {
                                                                  return eventSource.id === source.id;
                                                                }))) : eventSources !== "None";
                                                    return [
                                                            source.id,
                                                            eventSendsSource ? "Sometimes" : "Unavailable"
                                                          ];
                                                  }))))
                                  ];
                          }))));
    }
    var eventAbsences = absence._0;
    var allEventSources$1 = getAllEventSources(modelSources, propertyEvents);
    return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(propertyEvents, (function ($$event) {
                          var eventSources = AvoConfig.determineSendingToSources(modelSources, $$event);
                          return [
                                  $$event.id,
                                  Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(allEventSources$1, (function (source) {
                                                  var eventSendsSource = typeof eventSources === "object" ? Belt_Option.isSome(Belt_List.getBy(eventSources.VAL, (function (eventSource) {
                                                                return eventSource.id === source.id;
                                                              }))) : eventSources !== "None";
                                                  return [
                                                          source.id,
                                                          eventSendsSource ? Belt_Option.getWithDefault(getAbsenceByEventAndSource(eventAbsences, $$event.id, source.id), "Always") : "Unavailable"
                                                        ];
                                                }))))
                                ];
                        }))));
  }
  var allEventSources$2 = getAllEventSources(modelSources, propertyEvents);
  return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(propertyEvents, (function ($$event) {
                        var eventSources = AvoConfig.determineSendingToSources(modelSources, $$event);
                        return [
                                $$event.id,
                                Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(allEventSources$2, (function (source) {
                                                var eventSendsSource = typeof eventSources === "object" ? Belt_Option.isSome(Belt_List.getBy(eventSources.VAL, (function (eventSource) {
                                                              return eventSource.id === source.id;
                                                            }))) : eventSources !== "None";
                                                return [
                                                        source.id,
                                                        eventSendsSource ? "Always" : "Unavailable"
                                                      ];
                                              }))))
                              ];
                      }))));
}

function emptyOrEverythingSometimes(stateMatrix) {
  return Belt_Array.every(Belt_MapString.valuesToArray(stateMatrix), (function (sources) {
                return Belt_Array.every(Belt_MapString.valuesToArray(sources), (function (state) {
                              if (state === "Sometimes") {
                                return true;
                              } else {
                                return state === "Unavailable";
                              }
                            }));
              }));
}

function getSimplestModeForStateMatrix(stateMatrix, absence) {
  var withUnavailableFiltered = Belt_Array.map(Belt_MapString.valuesToArray(stateMatrix), (function (sources) {
          return Belt_Array.keep(Belt_MapString.valuesToArray(sources), (function (state) {
                        return state !== "Unavailable";
                      }));
        }));
  var emptyOrEverythingAlways = Belt_Array.every(withUnavailableFiltered, (function ($$event) {
          return Belt_Array.every($$event, (function (state) {
                        return state === "Always";
                      }));
        }));
  var emptyOrEveryEventConsistent = Belt_Array.every(withUnavailableFiltered, (function ($$event) {
          return Belt_SetString.size(Belt_SetString.fromArray(Belt_Array.map($$event, getEventAndSourceStateString))) <= 1;
        }));
  if (emptyOrEverythingAlways) {
    return "Always";
  } else if (emptyOrEveryEventConsistent) {
    if (absence !== undefined && !(typeof absence !== "object" || absence.TAG === "SometimesSent")) {
      return "SometimesByEvent";
    } else {
      return "SometimesAll";
    }
  } else {
    return "BySource";
  }
}

function getDefaultModeForAbsence(propertyEvents, modelSources, absence) {
  if (absence !== undefined && typeof absence === "object") {
    if (absence.TAG === "SometimesSent") {
      return "SometimesAll";
    } else {
      return getSimplestModeForStateMatrix(getFullStateMatrix(propertyEvents, modelSources, absence), absence);
    }
  } else {
    return "Always";
  }
}

function sourcesEmptyOrEverythingSometimes(systemPropsAbsenceMap) {
  return Belt_Array.every(Belt_MapString.valuesToArray(systemPropsAbsenceMap), (function (state) {
                if (state === "Sometimes") {
                  return true;
                } else {
                  return state === "Unavailable";
                }
              }));
}

function getSimplestModeForAbsenceMap(absenceMap, absence) {
  var withUnavailableFiltered = Belt_Array.keep(Belt_MapString.valuesToArray(absenceMap), (function (state) {
          return state !== "Unavailable";
        }));
  var emptyOrEverythingAlways = Belt_Array.every(withUnavailableFiltered, (function (state) {
          return state === "Always";
        }));
  var emptyOrEveryEventConsistent = Belt_Array.every(withUnavailableFiltered, (function (state) {
          return state === "Sometimes";
        }));
  if (emptyOrEverythingAlways) {
    return "Always";
  }
  if (!emptyOrEveryEventConsistent) {
    return "BySource";
  }
  if (absence === undefined) {
    return "SometimesAll";
  }
  if (typeof absence !== "object") {
    return "SometimesAll";
  }
  if (absence.TAG === "SometimesSent") {
    return "SometimesAll";
  }
  var eventsMap = absence._0;
  if (Belt_Option.isSome(Belt_MapString.get(eventsMap, TrackingPlanModel.propertyAbsenceAllEventsKey)) && Belt_MapString.size(eventsMap) === 1) {
    return "SometimesAll";
  } else {
    return "SometimesByEvent";
  }
}

function getFullSysAbsenceMap(modelSources, absence) {
  if (absence !== undefined && typeof absence === "object") {
    if (absence.TAG === "SometimesSent") {
      return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(modelSources, (function (source) {
                            return [
                                    source.id,
                                    "Sometimes"
                                  ];
                          }))));
    }
    var alleventsAbsences = absence._0;
    return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(modelSources, (function (source) {
                          return [
                                  source.id,
                                  Belt_Option.getWithDefault(getAbsenceByEventAndSource(alleventsAbsences, TrackingPlanModel.propertyAbsenceAllEventsKey, source.id), "Always")
                                ];
                        }))));
  }
  return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.map(modelSources, (function (source) {
                        return [
                                source.id,
                                "Always"
                              ];
                      }))));
}

function getDefaultModeForAbsenceSystemProp(modelSources, absence) {
  if (absence !== undefined && typeof absence === "object") {
    if (absence.TAG === "SometimesSent") {
      return "SometimesAll";
    } else {
      return getSimplestModeForAbsenceMap(getFullSysAbsenceMap(modelSources, absence), absence);
    }
  } else {
    return "Always";
  }
}

function getPropertyAbsenceString(model, sometimesSentPhraseOpt, property, param) {
  var sometimesSentPhrase = sometimesSentPhraseOpt !== undefined ? sometimesSentPhraseOpt : "Sometimes sent";
  if (property.sendAs === "SystemProperty") {
    var match = property.absence;
    if (match !== undefined) {
      if (typeof match !== "object") {
        return ;
      } else if (match.TAG === "SometimesSent") {
        return sometimesSentPhrase;
      } else {
        return getModeString(getSimplestModeForAbsenceMap(getFullSysAbsenceMap(model.sources, property.absence), property.absence));
      }
    } else if (property.optionalDeprecated) {
      return sometimesSentPhrase;
    } else {
      return ;
    }
  }
  var propertyEvents = ModelUtils.eventsSendingProperty(model, undefined, property.id);
  var stateMatrix = getFullStateMatrix(propertyEvents, model.sources, property.absence);
  var match$1 = getSimplestModeForStateMatrix(stateMatrix, property.absence);
  switch (match$1) {
    case "Always" :
        return ;
    case "SometimesAll" :
        return sometimesSentPhrase;
    case "SometimesByEvent" :
        return sometimesSentPhrase + " by event";
    case "BySource" :
        return sometimesSentPhrase + " by event and source";
    
  }
}

function getPropertyAbsenceString_mapped(events, propertyBundles, sources, sometimesSentPhraseOpt, property) {
  var sometimesSentPhrase = sometimesSentPhraseOpt !== undefined ? sometimesSentPhraseOpt : "Sometimes sent";
  if (property.sendAs === "SystemProperty") {
    var match = property.absence;
    if (match !== undefined) {
      if (typeof match !== "object") {
        return ;
      } else if (match.TAG === "SometimesSent") {
        return sometimesSentPhrase;
      } else {
        return getModeString(getSimplestModeForAbsenceMap(getFullSysAbsenceMap(sources, property.absence), property.absence));
      }
    } else if (property.optionalDeprecated) {
      return sometimesSentPhrase;
    } else {
      return ;
    }
  }
  var propertyEvents = Curry._1(TrackingPlanMappedModel.Events.toList, ModelUtils_mapped.eventsSendingProperty(events, propertyBundles, undefined, property.id));
  var stateMatrix = getFullStateMatrix(propertyEvents, sources, property.absence);
  var match$1 = getSimplestModeForStateMatrix(stateMatrix, property.absence);
  switch (match$1) {
    case "Always" :
        return ;
    case "SometimesAll" :
        return sometimesSentPhrase;
    case "SometimesByEvent" :
        return sometimesSentPhrase + " by event";
    case "BySource" :
        return sometimesSentPhrase + " by event and source";
    
  }
}

function analyticsPresenceTypeBefore(mode) {
  switch (mode) {
    case "Always" :
        return "AlwaysSent";
    case "SometimesAll" :
        return "SometimesSent";
    case "SometimesByEvent" :
        return "SometimesSentByEvent";
    case "BySource" :
        return "DependingOnSource";
    
  }
}

function getDirectPropertyAbsenceForRelevantEventIds(property, model) {
  var eventsSendingProperty = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.mapU(ModelUtils.eventsSendingProperty(model, undefined, property.id), (function (param) {
                  return param.id;
                }))));
  var match = property.absence;
  if (match !== undefined && !(typeof match !== "object" || match.TAG === "SometimesSent")) {
    return {
            TAG: "Mixed",
            _0: Belt_MapString.keepU(match._0, (function (eventId, _a) {
                    return Belt_SetString.has(eventsSendingProperty, eventId);
                  }))
          };
  } else {
    return property.absence;
  }
}

function getAbsenceForRelevantEventIds_mapped(events, propertyBundles, property) {
  var eventsSendingProperty = ModelUtils_mapped.eventsSendingProperty(events, propertyBundles, undefined, property.id);
  var match = property.absence;
  if (match !== undefined && !(typeof match !== "object" || match.TAG === "SometimesSent")) {
    return {
            TAG: "Mixed",
            _0: Belt_MapString.keepU(match._0, (function (eventId, param) {
                    return Curry._2(TrackingPlanMappedModel.Events.has, eventsSendingProperty, eventId);
                  }))
          };
  } else {
    return property.absence;
  }
}

export {
  modes ,
  getModeString ,
  ParseMode ,
  parseModeString ,
  getMoreComplex ,
  getEventAndSourceStateString ,
  getEventAndSourceStateStringForBranchDiff ,
  parseEventAndSourceStateString ,
  getAbsenceByEventAndSource ,
  getAllEventSources ,
  getFullStateMatrix ,
  emptyOrEverythingSometimes ,
  getSimplestModeForStateMatrix ,
  getDefaultModeForAbsence ,
  sourcesEmptyOrEverythingSometimes ,
  getSimplestModeForAbsenceMap ,
  getFullSysAbsenceMap ,
  getDefaultModeForAbsenceSystemProp ,
  getPropertyAbsenceString ,
  getPropertyAbsenceString_mapped ,
  analyticsPresenceTypeBefore ,
  getDirectPropertyAbsenceForRelevantEventIds ,
  getAbsenceForRelevantEventIds_mapped ,
}
/* AvoConfig Not a pure module */
