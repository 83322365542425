// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchSelect from "./SearchSelect.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as GuardRailsConfigUtils from "./guardrails/GuardRailsConfigUtils.mjs";
import * as GuardRailsConfigComponents from "./guardrails/GuardRailsConfigComponents.mjs";

var targetContainerStyles = Curry._1(Css.style, {
      hd: Css.label("name-component"),
      tl: {
        hd: Css.background("transparent"),
        tl: {
          hd: Css.margin(Css.px(-4)),
          tl: {
            hd: Css.padding(Css.px(4)),
            tl: {
              hd: Css.outline(Css.px(0), "solid", "transparent"),
              tl: {
                hd: Css.transitions({
                      hd: Css_Legacy_Core.Transition.shorthand({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "outline"),
                      tl: {
                        hd: Css_Legacy_Core.Transition.shorthand({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "padding"),
                        tl: {
                          hd: Css_Legacy_Core.Transition.shorthand({
                                NAME: "ms",
                                VAL: Styles.Duration.$$short
                              }, undefined, undefined, "margin"),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: {
                  hd: Css.hover({
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.85)),
                            tl: {
                              hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: {
                    hd: Css.focusWithin({
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.outline(Css.px(1), "solid", Styles.Color.light07),
                            tl: {
                              hd: Css.marginLeft(Css.px(0)),
                              tl: {
                                hd: Css.marginRight(Css.px(0)),
                                tl: {
                                  hd: Css.padding4(Css.px(4), Css.px(18), Css.px(4), Css.px(4)),
                                  tl: {
                                    hd: Css.selector(" .allowed-value__chevron-down", {
                                          hd: Css.opacity(1.0),
                                          tl: {
                                            hd: Css.width(Css.px(16)),
                                            tl: {
                                              hd: Css.pointerEvents("none"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var targetContentStyles = Curry._1(Css.style, {
      hd: Css.zIndex(0),
      tl: {
        hd: Css.padding("zero"),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: /* [] */0
        }
      }
    });

var targetOpenedStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
            tl: {
              hd: Css.outline(Css.px(1), "solid", Styles.Color.red),
              tl: {
                hd: Css.marginLeft(Css.px(0)),
                tl: {
                  hd: Css.marginRight(Css.px(0)),
                  tl: {
                    hd: Css.padding4(Css.px(4), Css.px(18), Css.px(4), Css.px(4)),
                    tl: {
                      hd: Css.selector(" .allowed-value__chevron-down", {
                            hd: Css.opacity(1.0),
                            tl: {
                              hd: Css.width(Css.px(16)),
                              tl: {
                                hd: Css.pointerEvents("none"),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var dropdownContainerStyles_0 = Css.transforms({
      hd: Css.translateY(Css.px(-6)),
      tl: {
        hd: Css.translateX(Css.px(-4)),
        tl: /* [] */0
      }
    });

var dropdownContainerStyles = {
  hd: dropdownContainerStyles_0,
  tl: /* [] */0
};

function dropdownItemStyles(selected) {
  return Curry._1(Css.style, {
              hd: Css.color(Styles.Color.light10),
              tl: {
                hd: selected ? Css.fontWeight(Styles.FontWeight.semi) : Styles.emptyStyle,
                tl: /* [] */0
              }
            });
}

var buttonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.focus({
                              hd: Css.outlineWidth("zero"),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var chevronStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(-16)),
        tl: {
          hd: Css.top(Css.px(2)),
          tl: /* [] */0
        }
      }
    });

function AdvancedNameInput__Component$AllowedValuesSelect(Props) {
  var allowedValues = Props.allowedValues;
  var autoFocus = Props.autoFocus;
  var $$case = Props.case;
  var eventEdit = Props.eventEdit;
  var name = Props.name;
  var onChange = Props.onChange;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var role = Props.role;
  var validation = Props.validation;
  var value = Props.value;
  var actionString = eventEdit === "Update" ? "updated" : "created";
  var buttonRef = React.useRef(null);
  var currentValue = value !== undefined ? (
      Belt_Array.every(allowedValues, (function (allowedValue) {
              return allowedValue !== value;
            })) ? [{
            value: value,
            label: value,
            selected: true,
            message: role === "Admin" ? "This value will be added to the predefined values list of \"" + name + "\" when the event is " + actionString + "." : "This value is not a valid \"" + name + "\" value.",
            valid: role === "Admin"
          }] : []
    ) : [];
  var allowedValues$1 = Belt_Array.map(Belt_SetString.toArray(Belt_SetString.fromArray(allowedValues)), (function (allowedValue) {
          return {
                  value: allowedValue,
                  label: allowedValue,
                  selected: Belt_Option.mapWithDefault(value, false, (function (value) {
                          return value === allowedValue;
                        })),
                  valid: true
                };
        }));
  var tmp = {
    items: Belt_Array.concat(currentValue, allowedValues$1),
    maxItemsBeforeOfferingSearch: 0,
    arbitraryChoice: role === "Admin" ? ({
          NAME: "withMessage",
          VAL: (function (searchInput) {
              return "\"" + searchInput + "\" will be added to the predefined values list of \"" + name + "\" when the event is " + actionString;
            })
        }) : ({
          NAME: "disabled",
          VAL: (function (param) {
              return "Only workspace admins can add allowed values to event name fields";
            })
        }),
    onChange: (function (v) {
        Curry._1(onChange, v);
      }),
    targetContainerStyles: targetContainerStyles,
    targetContentStyles: targetContentStyles,
    targetOpenedStyles: targetOpenedStyles,
    dropdownContainerStyles: dropdownContainerStyles,
    dropdownItemStyles: dropdownItemStyles,
    children: React.createElement("button", {
          ref: buttonRef,
          className: buttonStyles,
          autoFocus: autoFocus
        }, React.createElement($$Text.make, {
              size: "Medium",
              color: value !== undefined ? (
                  validation === "valid" || role === "Admin" ? Styles.Color.light11 : Styles.Color.darkOrange
                ) : Styles.Color.light07,
              children: Belt_Option.getWithDefault(value, Case.to_($$case, name))
            }), React.createElement("span", {
              className: chevronStyles + " allowed-value__chevron-down"
            }, React.createElement(Spacer.make, {
                  width: 4,
                  grow: 1.0
                }), React.createElement(Icon.make, {
                  type_: "chevronDown",
                  size: "small",
                  color: Styles.Color.light08
                })))
  };
  if (onEnter !== undefined) {
    tmp.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEscape !== undefined) {
    tmp.onEscape = Caml_option.valFromOption(onEscape);
  }
  return React.createElement(SearchSelect.make, tmp);
}

var AllowedValuesSelect = {
  targetContainerStyles: targetContainerStyles,
  targetContentStyles: targetContentStyles,
  targetOpenedStyles: targetOpenedStyles,
  dropdownContainerStyles: dropdownContainerStyles,
  dropdownItemStyles: dropdownItemStyles,
  buttonStyles: buttonStyles,
  chevronStyles: chevronStyles,
  make: AdvancedNameInput__Component$AllowedValuesSelect
};

function AdvancedNameInput__Component(Props) {
  var allowedValues = Props.allowedValues;
  var autoFocus = Props.autoFocus;
  var $$case = Props.case;
  var eventEdit = Props.eventEdit;
  var inputRef = Props.inputRef;
  var onPaste = Props.onPaste;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var name = Props.name;
  var onChange = Props.onChange;
  var role = Props.role;
  var validation = Props.validation;
  var value = Props.value;
  var hasBlockedValue = Props.hasBlockedValue;
  if (allowedValues.length !== 0) {
    var tmp = {
      allowedValues: allowedValues,
      autoFocus: autoFocus,
      case: $$case,
      eventEdit: eventEdit,
      name: name,
      onChange: onChange,
      role: role,
      validation: validation,
      value: value
    };
    if (onEnter !== undefined) {
      tmp.onEnter = Caml_option.valFromOption(onEnter);
    }
    if (onEscape !== undefined) {
      tmp.onEscape = Caml_option.valFromOption(onEscape);
    }
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.display("inlineBlock"),
                        tl: {
                          hd: Css.focusWithin({
                                hd: Css.zIndex(1),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(-22)),
                            tl: {
                              hd: Css.left("zero"),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.textOverflow("ellipsis"),
                                    tl: {
                                      hd: Css.display("inlineGrid"),
                                      tl: {
                                        hd: Css.maxWidth({
                                              NAME: "subtract",
                                              VAL: [
                                                {
                                                  NAME: "subtract",
                                                  VAL: [
                                                    Css.vw(100.0),
                                                    Css.pct(100.0)
                                                  ]
                                                },
                                                Css.px(20)
                                              ]
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement($$Text.make, {
                        element: "Span",
                        size: "Tiny",
                        singleLine: true,
                        color: Styles.Color.light09,
                        title: name,
                        children: name
                      })), React.createElement(AdvancedNameInput__Component$AllowedValuesSelect, tmp));
  }
  var tmp$1 = {
    containerClassName: Curry._1(Css.style, {
          hd: Css.label("name-component"),
          tl: {
            hd: Css.display("inlineBlock"),
            tl: {
              hd: Css.margin(Css.px(-6)),
              tl: {
                hd: Css.marginTop(Css.px(-5)),
                tl: {
                  hd: Css.padding(Css.px(6)),
                  tl: {
                    hd: Css.paddingTop(Css.px(7)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.focusWithin({
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
                                tl: {
                                  hd: Css.margin2(Css.px(-6), Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.zIndex(1),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.85)),
                                  tl: {
                                    hd: Css.outline(Css.px(1), "solid", Styles.Color.light07),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.outline("zero", "solid", "transparent"),
                            tl: {
                              hd: Css.marginBottom(Css.px(-20)),
                              tl: {
                                hd: Css.paddingBottom(Css.pxFloat(20.5)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }),
    className: Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.margin("zero"),
                  tl: {
                    hd: Css.left(Css.px(6)),
                    tl: {
                      hd: Css.top(Css.px(5)),
                      tl: {
                        hd: Css.fontSize(Css.px(14)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          tl: {
            hd: hasBlockedValue ? Curry._1(Css.style, {
                    hd: Css.textDecorationLine("underline"),
                    tl: {
                      hd: Css.textDecorationStyle("wavy"),
                      tl: {
                        hd: Css.textDecorationColor(Styles.Color.avoPink),
                        tl: {
                          hd: Css.unsafe("textDecorationThickness", "0.8px"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }) : Curry._1(Css.style, /* [] */0),
            tl: /* [] */0
          }
        }),
    height: 24,
    onBlur: (function (param) {
        if (value !== undefined) {
          return Curry._1(onChange, Case.to_($$case, value));
        }
        
      }),
    onChange: (function (newValue) {
        if (newValue !== undefined) {
          return Curry._1(onChange, GuardRailsConfigUtils.getCasedValue($$case, newValue));
        } else {
          return Curry._1(onChange, newValue);
        }
      }),
    onPaste: (function (_e) {
        Belt_Option.forEach(onPaste, (function (f) {
                Curry._1(f, (_e.clipboardData.getData('text/plain')));
              }));
      }),
    placeholder: Case.to_($$case, name),
    value: Belt_Option.getWithDefault(value, ""),
    autoFocus: autoFocus
  };
  if (inputRef !== undefined) {
    tmp$1.inputRef = Caml_option.valFromOption(inputRef);
  }
  if (onEnter !== undefined) {
    tmp$1.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEscape !== undefined) {
    tmp$1.onEscape = Caml_option.valFromOption(onEscape);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("inlineBlock"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.marginBottom(Css.px(-2)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("inlineGrid"),
                        tl: {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.left("zero"),
                            tl: {
                              hd: Css.top(Css.px(-23)),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.textOverflow("ellipsis"),
                                    tl: {
                                      hd: Css.display("inlineGrid"),
                                      tl: {
                                        hd: Css.maxWidth({
                                              NAME: "subtract",
                                              VAL: [
                                                {
                                                  NAME: "subtract",
                                                  VAL: [
                                                    Css.vw(100.0),
                                                    Css.pct(100.0)
                                                  ]
                                                },
                                                Css.px(20)
                                              ]
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Tiny",
                      singleLine: true,
                      color: Styles.Color.light09,
                      title: name,
                      children: name
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingRight(Css.px(0)),
                        tl: /* [] */0
                      })
                }, React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, tmp$1)));
}

var make = AdvancedNameInput__Component;

export {
  AllowedValuesSelect ,
  make ,
}
/* targetContainerStyles Not a pure module */
