// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "../../../app/src/ModelUtils_mapped.mjs";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../TrackingPlanMappedModel.mjs";

var propertyValuesAllEventsKey = "system";

function get(modelProperties, property, getEventIdsSendingPropertyAsPropertyRef) {
  var match = property.sendAs;
  if (typeof match !== "object" && match === "SystemProperty") {
    return {
            TAG: "SystemProperty",
            _0: propertyValuesAllEventsKey
          };
  }
  var notNestedEventIds = Curry._1(getEventIdsSendingPropertyAsPropertyRef, property.id);
  var parentProperties = Belt_Array.keepU(modelProperties, (function (modelProperty) {
          if (modelProperty.type_ === "object") {
            return ModelUtils.doesPropertyContainPropertyIdAsFirstLevelNested(modelProperty, property.id);
          } else {
            return false;
          }
        }));
  var eventIdsSendingParentProperties = Belt_List.toArray(BeltListExtensions.flatMapU(Belt_List.fromArray(parentProperties), (function (parentProperty) {
              var propertyValuesAllEventsKey = get(modelProperties, parentProperty, getEventIdsSendingPropertyAsPropertyRef);
              switch (propertyValuesAllEventsKey.TAG) {
                case "SystemProperty" :
                    return {
                            hd: propertyValuesAllEventsKey._0,
                            tl: /* [] */0
                          };
                case "SystemAndNotSystemProperty" :
                case "NotSystemProperty" :
                    return Belt_List.fromArray(propertyValuesAllEventsKey._0);
                
              }
            })));
  var eventIdsSendingProperties = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(notNestedEventIds, eventIdsSendingParentProperties)));
  if (Belt_Array.someU(eventIdsSendingProperties, (function (eventId) {
            return eventId === propertyValuesAllEventsKey;
          }))) {
    return {
            TAG: "SystemAndNotSystemProperty",
            _0: eventIdsSendingProperties
          };
  } else {
    return {
            TAG: "NotSystemProperty",
            _0: eventIdsSendingProperties
          };
  }
}

function getFromSlowModel(model, property) {
  return get(Belt_Array.keepMapU(Belt_List.toArray(model.properties), (function (property) {
                    if (property.TAG === "PropertyRef") {
                      return ;
                    } else {
                      return property._0;
                    }
                  })), property, (function (propertyId) {
                return Belt_Array.mapU(Belt_List.toArray(ModelUtils.eventsSendingProperty(model, undefined, propertyId)), (function ($$event) {
                              return $$event.id;
                            }));
              }));
}

function getFromMappedModel(model, property) {
  return get(Curry._1(TrackingPlanMappedModel.Properties.toArray, model.properties), property, (function (propertyId) {
                return Curry._1(TrackingPlanMappedModel.Events.keys, ModelUtils_mapped.eventsSendingProperty(model.events, model.propertyBundles, undefined, propertyId));
              }));
}

export {
  propertyValuesAllEventsKey ,
  get ,
  getFromSlowModel ,
  getFromMappedModel ,
}
/* ModelUtils Not a pure module */
