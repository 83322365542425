// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Case from "../../../bs-case/src/case.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Issues from "../../../shared/models/Issues.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as DateFns from "../DateFns.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Mantine from "../Mantine.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as TextButton from "../TextButton.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconWarning from "../IconWarning.mjs";
import * as ImportModel from "./ImportModel.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as QueryString from "query-string";
import * as IconCheckmark from "../IconCheckmark.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as ImportReviewRow from "./ImportReviewRow.mjs";
import LodashDebounce from "lodash.debounce";
import * as AppVersionsStore from "../AppVersionsStore.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as IconCheckmarkCircle from "../IconCheckmarkCircle.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as InspectorIssuesStore from "../inspector/InspectorIssuesStore.mjs";
import * as ImportReview__Context from "./ImportReview__Context.mjs";
import * as InspectorIssuesFilter from "../../../shared/models/InspectorIssuesFilter.mjs";
import * as ImportReviewRow__Event from "./ImportReviewRow__Event.mjs";
import * as ImportReviewRow__Styles from "./ImportReviewRow__Styles.mjs";
import * as ImportReview__V2__Utils from "./ImportReview__V2__Utils.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as ImportReview__BranchPicker from "./ImportReview__BranchPicker.mjs";
import * as InspectorIssuesFilterUtils from "../../../shared/utils/InspectorIssuesFilterUtils.mjs";
import * as GetAllPropertyValuesUseCase from "../../../model/src/eventSpecificPropertyValues/GetAllPropertyValuesUseCase.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(40)),
      tl: {
        hd: Css.maxWidth(Css.pct(100.0)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.alignItems("center"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.right(Css.px(0)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.width(Css.pct(100.0)),
                  tl: {
                    hd: Css.padding(Css.px(16)),
                    tl: {
                      hd: Css.paddingTop(Css.px(48)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light02),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var topStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(580)),
      tl: {
        hd: Css.position("relative"),
        tl: /* [] */0
      }
    });

var backlinkStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.pct(100.0)),
        tl: {
          hd: Css.top(Css.px(10)),
          tl: {
            hd: Css.marginRight(Css.px(20)),
            tl: /* [] */0
          }
        }
      }
    });

var countsStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(12)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.boxShadow(Styles.Shadow.subtle),
          tl: {
            hd: Css.padding(Css.px(12)),
            tl: {
              hd: Css.marginBottom(Css.px(32)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var countStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

var tableIntroStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(99)),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.width(Css.pct(100.0)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.paddingTop(Css.px(8)),
                    tl: {
                      hd: Css.paddingRight(Css.px(8)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(8)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(80)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var tableIntroLeftStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.marginRight(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function ImportReview__V2$SearchInput(Props) {
  var value = Props.value;
  var hiddenEventsCount = Props.hiddenEventsCount;
  var onChange = Props.onChange;
  var onClear = Props.onClear;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingLeft(Css.px(8)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderRadius(Css.px(12)),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                          tl: {
                            hd: Css.borderColor(Styles.Color.light07),
                            tl: {
                              hd: Css.filter({
                                    hd: {
                                      NAME: "dropShadow",
                                      VAL: [
                                        Css.px(0),
                                        Css.px(0),
                                        Css.px(0),
                                        "transparent"
                                      ]
                                    },
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(4)),
                                    tl: {
                                      hd: Css.paddingLeft(Css.px(4)),
                                      tl: {
                                        hd: Css.paddingRight(Css.px(8)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.white),
                                          tl: {
                                            hd: Css.transition({
                                                  NAME: "ms",
                                                  VAL: Styles.Duration.$$short
                                                }, undefined, undefined, "all"),
                                            tl: {
                                              hd: Css.focusWithin({
                                                    hd: Css.borderColor(Styles.Color.light04),
                                                    tl: {
                                                      hd: Css.outline(Css.px(1), "solid", Styles.Color.darkBlue),
                                                      tl: {
                                                        hd: Css.filter({
                                                              hd: {
                                                                NAME: "dropShadow",
                                                                VAL: [
                                                                  Css.px(0),
                                                                  Css.px(0),
                                                                  Css.px(2),
                                                                  Styles.Color.lightBlue
                                                                ]
                                                              },
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("input", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding(Css.px(4)),
                              tl: {
                                hd: Css.margin("zero"),
                                tl: {
                                  hd: Css.outlineWidth("zero"),
                                  tl: {
                                    hd: Css.borderWidth("zero"),
                                    tl: {
                                      hd: Css.borderRadius(Css.px(11)),
                                      tl: {
                                        hd: Css.focusWithin({
                                              hd: Css.outlineWidth("zero"),
                                              tl: {
                                                hd: Css.borderWidth("zero"),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: {
                                          hd: Css.active({
                                                hd: Css.outlineWidth("zero"),
                                                tl: {
                                                  hd: Css.borderWidth("zero"),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      placeholder: "Search for event...",
                      type: "text",
                      value: value,
                      onChange: (function ($$event) {
                          Curry._1(onChange, $$event.target.value);
                        })
                    }), React.createElement("button", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, Styles.buttonReset),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.width(Css.px(14)),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: "input-search-clear-button",
                                tl: /* [] */0
                              }
                            }
                          }),
                      onClick: (function (param) {
                          if (value === "") {
                            return ;
                          } else {
                            return Curry._1(onClear, undefined);
                          }
                        })
                    }, value === "" ? React.createElement(Icon.make, {
                            type_: "search",
                            size: {
                              NAME: "int",
                              VAL: 14
                            },
                            color: Styles.Color.light10
                          }) : React.createElement(Icon.make, {
                            type_: "close",
                            size: {
                              NAME: "int",
                              VAL: 14
                            },
                            color: Styles.Color.light10
                          }))), hiddenEventsCount !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light11,
                        children: AvoUtils.plural(undefined, undefined, hiddenEventsCount, "event") + " hidden by search criteria."
                      }), React.createElement(Spacer.make, {
                        width: 8
                      }), React.createElement(TextButton.make, {
                        onClick: (function (param) {
                            Curry._1(onClear, undefined);
                          }),
                        style: "Blue",
                        size: "Small",
                        children: React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: "Clear search"
                            })
                      })) : null);
}

var SearchInput = {
  make: ImportReview__V2$SearchInput
};

var debounceFunction = LodashDebounce((function (fn) {
        Curry._1(fn, undefined);
      }), 500, {
      leading: false,
      trailing: true
    });

function isIndeterminate(filteredRowItems, excludedItemIds) {
  var allVisibleSelected = Belt_Array.every(filteredRowItems, (function (param) {
          return !Belt_SetString.has(excludedItemIds, param.id);
        }));
  var noVisibleSelected = Belt_Array.every(filteredRowItems, (function (param) {
          return Belt_SetString.has(excludedItemIds, param.id);
        }));
  if (noVisibleSelected || allVisibleSelected) {
    return false;
  } else {
    return true;
  }
}

function getVisibleExcludedEvents(filteredEvents, excludedEventIds) {
  return Belt_SetString.fromArray(Belt_Array.keepMap(filteredEvents, (function (param) {
                    var id = param.id;
                    if (Belt_SetString.has(excludedEventIds, id)) {
                      return id;
                    }
                    
                  })));
}

function getEventConflictInfo(issues, appVersions, workingModel, mappedEvents) {
  var eventNamesInImport = Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents), (function (param) {
              return param.name;
            })));
  var remainingEventsToImportFromIssues = Curry._2(Issues.keep, InspectorIssuesFilterUtils.filterIssueViewModels(Belt_Array.keepU(InspectorIssuesFilter.FilterType.availableFilterTypes, (function (t) {
                  return t !== "issueType";
                })), appVersions, issues, [
            {
              NAME: "issueType",
              VAL: {
                NAME: "old",
                VAL: "eventNotInTrackingPlan"
              }
            },
            {
              NAME: "issueType",
              VAL: {
                NAME: "new",
                VAL: "eventNotInTrackingPlanV2"
              }
            }
          ]), (function (issue) {
          return !Belt_SetString.has(eventNamesInImport, issue.eventName);
        }));
  return Belt_MapString.fromArray(Belt_Array.mapU(Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents), (function (param) {
                    var eventName = param.name;
                    return [
                            param.id,
                            {
                              similarEventsInTrackingPlan: Curry._2(TrackingPlanMappedModel.Events.keep, mappedEvents, (function (param) {
                                      var name = param.name;
                                      if (NameUtils.areConflictingNames(eventName, name)) {
                                        return true;
                                      } else {
                                        return NameUtils.areSimilarlyNamed(undefined, eventName, name);
                                      }
                                    })),
                              similarEventsInImport: Belt_Array.keepU(workingModel.newEvents, (function (param) {
                                      var name = param.name;
                                      if (NameUtils.areConflictingNames(eventName, name) || NameUtils.areSimilarlyNamed(undefined, eventName, name)) {
                                        return eventName !== name;
                                      } else {
                                        return false;
                                      }
                                    })),
                              similarEventNamesInOtherIssues: Belt_Array.keepU(Curry._2(Issues.mapToArray, remainingEventsToImportFromIssues, (function (param) {
                                          return param.eventName;
                                        })), (function (name) {
                                      if (NameUtils.areConflictingNames(eventName, name)) {
                                        return true;
                                      } else {
                                        return NameUtils.areSimilarlyNamed(undefined, eventName, name);
                                      }
                                    }))
                            }
                          ];
                  })));
}

function ImportReview__V2(Props) {
  var currentBranch = Props.currentBranch;
  var importStartedLocation = Props.importStartedLocation;
  var onContinue = Props.onContinue;
  var openBranches = Props.openBranches;
  var importModelShouldOnlyBeUserToBuildWorkingModel = Props.parsedTrackingPlan;
  var viewerRole = Props.viewerRole;
  var importMethod = Props.importMethod;
  var mappedEvents = ModelStore.Mapped.useEvents();
  var mappedProperties = ModelStore.Mapped.useProperties();
  var mappedPropertyBundles = ModelStore.Mapped.usePropertyBundles();
  var match = AppVersionsStore.use(undefined, undefined, undefined);
  var appVersions = match[0];
  var match$1 = InspectorIssuesStore.useIssues(undefined, "Unresolved");
  var issues = match$1.issues;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var schemaBundle = SchemaBundleContext.use();
  var openModal = GlobalSendContext.useOpenModal();
  var match$2 = React.useState(function (param) {
        return false;
      });
  var setLoading = match$2[1];
  var isLoading = match$2[0];
  var updateIssues = InspectorIssuesStore.useUpdateIssues();
  var model = ModelStore.useModel();
  var importSuccessQueryParam = ImportReview__V2__Utils.useImportSuccessQueryParam();
  var importStartedLocationQueryParam = ImportReview__V2__Utils.useImportStartedLocationQueryParam();
  var eventsImportedCountQueryParam = ImportReview__V2__Utils.useEventsImportedCountQueryParam();
  var propertiesImportedCountQueryParam = ImportReview__V2__Utils.usePropertiesImportedCountQueryParam();
  var sourcesImportedCountQueryParam = ImportReview__V2__Utils.useSourcesImportedCountQueryParam();
  var resolvedIssuesCountQueryParam = ImportReview__V2__Utils.useResolvedIssuesCountQueryParam();
  var newStakeholderDomainCountQueryParam = ImportReview__V2__Utils.useNewStakeholderDomainCountQueryParam();
  var filterEvents = function (workingModel, filterBy) {
    var allEvents = Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents);
    return Fuzzaldrin.filter(allEvents, filterBy, {
                key: "name",
                maxResults: allEvents.length
              });
  };
  var match$3 = React.useReducer((function (state, action) {
          if (typeof action !== "object") {
            if (action === "clearInput") {
              return {
                      workingModel: state.workingModel,
                      searchInput: "",
                      filteredEvents: Belt_Array.concat(state.workingModel.newEvents, state.workingModel.updatedEvents),
                      eventConflictInfo: state.eventConflictInfo
                    };
            } else if (action === "filterEvents") {
              return {
                      workingModel: state.workingModel,
                      searchInput: state.searchInput,
                      filteredEvents: filterEvents(state.workingModel, state.searchInput),
                      eventConflictInfo: state.eventConflictInfo
                    };
            } else {
              return {
                      workingModel: state.workingModel,
                      searchInput: state.searchInput,
                      filteredEvents: state.filteredEvents,
                      eventConflictInfo: isLoading ? state.eventConflictInfo : getEventConflictInfo(issues, appVersions, state.workingModel, mappedEvents)
                    };
            }
          }
          if (action.NAME === "updateSearchInput") {
            return {
                    workingModel: state.workingModel,
                    searchInput: action.VAL,
                    filteredEvents: state.filteredEvents,
                    eventConflictInfo: state.eventConflictInfo
                  };
          }
          var newWorkingModel = Curry._1(action.VAL, state.workingModel);
          return {
                  workingModel: newWorkingModel,
                  searchInput: state.searchInput,
                  filteredEvents: Belt_Array.concat(newWorkingModel.newEvents, newWorkingModel.updatedEvents),
                  eventConflictInfo: isLoading ? state.eventConflictInfo : getEventConflictInfo(issues, appVersions, newWorkingModel, mappedEvents)
                };
        }), {
        workingModel: importModelShouldOnlyBeUserToBuildWorkingModel,
        searchInput: "",
        filteredEvents: Belt_Array.concat(importModelShouldOnlyBeUserToBuildWorkingModel.newEvents, importModelShouldOnlyBeUserToBuildWorkingModel.updatedEvents),
        eventConflictInfo: getEventConflictInfo(issues, appVersions, importModelShouldOnlyBeUserToBuildWorkingModel, mappedEvents)
      });
  var reduceFilterState = match$3[1];
  var match$4 = match$3[0];
  var eventConflictInfo = match$4.eventConflictInfo;
  var filteredEvents = match$4.filteredEvents;
  var workingModel = match$4.workingModel;
  React.useEffect((function (param) {
          Curry._1(reduceFilterState, "updateEventConflictInfo");
        }), [
        issues,
        appVersions,
        mappedEvents,
        isLoading
      ]);
  var setWorkingModel = function (fn) {
    Curry._1(reduceFilterState, {
          NAME: "updateWorkingModel",
          VAL: fn
        });
  };
  var trackImportChanged = function (eventNameOpt, propertyNameOpt, importChange) {
    var eventName = eventNameOpt !== undefined ? Caml_option.valFromOption(eventNameOpt) : undefined;
    var propertyName = propertyNameOpt !== undefined ? Caml_option.valFromOption(propertyNameOpt) : undefined;
    AnalyticsRe.importChanged(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, importChange, eventName, propertyName, importStartedLocation, workspace.id);
  };
  var match$5 = React.useState(function (param) {
        return Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents), (function ($$event) {
                          return $$event.id;
                        })));
      });
  var setExcludedEventIds = match$5[1];
  var excludedEventIds = match$5[0];
  var match$6 = React.useState(function (param) {
        
      });
  var match$7 = React.useState(function (param) {
        return Belt_Set.make(ImportReview__V2__Utils.StringTupleComparator);
      });
  var setExcludedEventPropertyIds = match$7[1];
  var excludedEventPropertyIds = match$7[0];
  var match$8 = React.useState(function (param) {
        
      });
  var setExcludedUserPropertyIds = match$8[1];
  var excludedUserPropertyIds = match$8[0];
  var match$9 = React.useState(function (param) {
        return false;
      });
  var setExcludeAllUserProperties = match$9[1];
  var excludeAllUserProperties = match$9[0];
  var match$10 = React.useState(function (param) {
        return Belt_Set.make(ImportReview__V2__Utils.StringTupleComparator);
      });
  var setExcludedEventSources = match$10[1];
  var excludedEventSources = match$10[0];
  var newAndUpdatedProperties = React.useMemo((function (param) {
          return Belt_Array.concat(workingModel.newProperties, workingModel.updatedProperties);
        }), [workingModel]);
  var allParsedProperties = React.useMemo((function (param) {
          return Belt_MapString.fromArray(Belt_Array.map(workingModel.allParsedProperties, (function (property) {
                            return [
                                    property.id,
                                    property
                                  ];
                          })));
        }), [workingModel.allParsedProperties]);
  var allParsedPropertiesByName = React.useMemo((function (param) {
          return Curry._1(ImportModel.Mapped.PropertiesByName.fromArray, workingModel.allParsedProperties);
        }), [workingModel.allParsedProperties]);
  var resolvedProperties = React.useMemo((function (param) {
          var filteredExistingProperties = Belt_MapString.fromArray(Belt_Array.keepMapU(workingModel.warnings.filteredOutPropertyInfo, (function (propertyInfo) {
                      if (propertyInfo.TAG !== "PropertyAlreadyInTrackingPlan") {
                        return ;
                      }
                      var trackingPlanProperty = propertyInfo._1;
                      if (trackingPlanProperty.TAG === "PropertyRef") {
                        return ;
                      }
                      var property = trackingPlanProperty._0;
                      return [
                              property.id,
                              [
                                property,
                                propertyInfo._0
                              ]
                            ];
                    })));
          return Belt_MapString.fromArray(Belt_Array.mapU(Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents), (function (param) {
                            return [
                                    param.id,
                                    Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_Array.concat(param.propertyIds, param.updatedPropertyIds), (function (param) {
                                                var pinnedValue = param[1];
                                                var propertyId = param[0];
                                                var match = Belt_MapString.get(filteredExistingProperties, propertyId);
                                                var match$1 = Belt_MapString.get(allParsedProperties, propertyId);
                                                if (match !== undefined) {
                                                  return [
                                                          propertyId,
                                                          {
                                                            trackingPlanProperty: match[0],
                                                            importProperty: match[1],
                                                            pinnedValue: pinnedValue,
                                                            existingProperty: true
                                                          }
                                                        ];
                                                } else if (match$1 !== undefined) {
                                                  return [
                                                          propertyId,
                                                          {
                                                            trackingPlanProperty: undefined,
                                                            importProperty: match$1,
                                                            pinnedValue: pinnedValue,
                                                            existingProperty: false
                                                          }
                                                        ];
                                                } else {
                                                  return ;
                                                }
                                              })))
                                  ];
                          })));
        }), [
        workingModel,
        allParsedProperties
      ]);
  var autoResolvedProperties = React.useMemo((function (param) {
          return Belt_MapString.keepU(Belt_MapString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(Belt_MapString.valuesToArray(resolvedProperties), Belt_MapString.toArray))), (function (_propertyId, param) {
                        return Belt_Array.someU(param.importProperty.warnings, (function (warning) {
                                      if (typeof warning === "object") {
                                        return warning.NAME === "typeConflictAutoResolved";
                                      } else {
                                        return false;
                                      }
                                    }));
                      }));
        }), [resolvedProperties]);
  var totalEventAlerts = React.useMemo((function (param) {
          return Belt_Array.reduceU(Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents), 0, (function (sum, $$event) {
                        return sum + $$event.warnings.length | 0;
                      }));
        }), [workingModel]);
  var totalAlertsCount = React.useMemo((function (param) {
          return Belt_Array.reduceU(Belt_MapString.valuesToArray(eventConflictInfo), totalEventAlerts, (function (sum, param) {
                        return ((sum + Curry._1(TrackingPlanMappedModel.Events.size, param.similarEventsInTrackingPlan) | 0) + param.similarEventsInImport.length | 0) + param.similarEventNamesInOtherIssues.length | 0;
                      }));
        }), [eventConflictInfo]);
  React.useEffect((function (param) {
          if (isLoading || Belt_SetString.isEmpty(excludedEventIds) && Belt_Set.isEmpty(excludedEventPropertyIds) && Belt_Set.isEmpty(excludedEventSources) && Belt_SetString.isEmpty(excludedUserPropertyIds) && excludeAllUserProperties) {
            return ;
          }
          var warnBeforeLosingChanges = function ($$event) {
            $$event.preventDefault();
            $$event.returnValue = "";
          };
          window.addEventListener("beforeunload", warnBeforeLosingChanges);
          return (function (param) {
                    window.removeEventListener("beforeunload", warnBeforeLosingChanges);
                  });
        }), [
        isLoading,
        excludedEventIds,
        excludedEventPropertyIds,
        excludedEventSources,
        excludedUserPropertyIds,
        excludeAllUserProperties
      ]);
  var selectedEvents = React.useMemo((function (param) {
          return Belt_Array.keepU(Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents), (function ($$event) {
                        return !Belt_SetString.has(excludedEventIds, $$event.id);
                      }));
        }), [
        workingModel,
        excludedEventIds
      ]);
  var selectedEventCount = selectedEvents.length;
  var filterPropertiesBeforeImport = React.useMemo((function (param) {
          return function (param) {
            return ImportReview__V2__Utils.filterPropertiesBeforeImport(excludedEventPropertyIds, excludedUserPropertyIds, excludeAllUserProperties, selectedEvents, param);
          };
        }), [
        excludedEventIds,
        excludedEventPropertyIds,
        excludeAllUserProperties,
        excludedUserPropertyIds,
        selectedEvents
      ]);
  var selectedProperties = React.useMemo((function (param) {
          return Curry._1(filterPropertiesBeforeImport, workingModel.allParsedProperties);
        }), [
        newAndUpdatedProperties,
        filterPropertiesBeforeImport,
        workingModel,
        excludedEventPropertyIds,
        excludeAllUserProperties,
        excludedUserPropertyIds,
        excludedEventIds
      ]);
  var selectedPropertiesCount = selectedProperties.length;
  var handleIncludeUserPropertyToggle = function (propertyId, propertyName, reactEvent) {
    var checked = reactEvent.target.checked;
    Curry._1(setExcludedUserPropertyIds, (function (excluded) {
            if (checked) {
              return Belt_SetString.remove(excluded, propertyId);
            } else {
              return Belt_SetString.add(excluded, propertyId);
            }
          }));
    trackImportChanged(undefined, Caml_option.some(propertyName), checked ? "PropertySelected" : "PropertyDeselected");
  };
  var handleExcludeAllUserPropertiesToggle = function (reactEvent) {
    var checked = reactEvent.target.checked;
    Curry._1(setExcludeAllUserProperties, (function (param) {
            return !checked;
          }));
  };
  var handleImportSuccessful = function (numNewEvents, numNewProperties, numNewSources, numNewStakeholderDomains, resolvedIssues, branch) {
    var resolvedUntil = DateFns.addHours(2.0, new Date());
    Curry._1(updateIssues, Belt_Array.mapU(resolvedIssues, (function (issue) {
                var init = issue.issueStatus;
                return {
                        id: issue.id,
                        sharedId: issue.sharedId,
                        issueType: issue.issueType,
                        issueStatus: {
                          status: {
                            TAG: "Resolved",
                            _0: {
                              TAG: "Date",
                              _0: resolvedUntil
                            }
                          },
                          updatedAt: init.updatedAt,
                          updatedBy: init.updatedBy
                        },
                        eventName: issue.eventName,
                        propertyName: issue.propertyName,
                        source: issue.source,
                        categories: issue.categories,
                        tags: issue.tags,
                        firstSeen: issue.firstSeen,
                        lastSeen: issue.lastSeen,
                        eventCount: issue.eventCount,
                        issueCount: issue.issueCount,
                        newestAppVersion: issue.newestAppVersion,
                        oldestAppVersion: issue.oldestAppVersion,
                        regression: issue.regression
                      };
              })));
    if (importStartedLocation === "InspectorEventsTableRow" || importStartedLocation === "InspectorEventsSidebar" || importStartedLocation === "InspectorIssueDetails" || importStartedLocation === "InspectorEventsHeader" || importStartedLocation === "InspectorIssuesHeader" || importStartedLocation === "OnboardingChecklist") {
      if (typeof branch !== "object") {
        Router.Schema.clearDrawerItems(undefined, undefined);
        return Curry._1(openModal, {
                    NAME: "ImportSuccess",
                    VAL: {
                      eventCount: numNewEvents,
                      propertyCount: numNewProperties,
                      sourceCount: numNewSources,
                      resolvedIssuesCount: resolvedIssues.length,
                      importOrigin: importStartedLocation,
                      newStakeholderDomainCount: numNewStakeholderDomains
                    }
                  });
      }
      var queryString = QueryString.stringify(Js_dict.fromArray([
                Curry._1(importSuccessQueryParam.build, true),
                Curry._1(importStartedLocationQueryParam.build, importStartedLocation),
                Curry._1(eventsImportedCountQueryParam.build, numNewEvents),
                Curry._1(propertiesImportedCountQueryParam.build, numNewProperties),
                Curry._1(sourcesImportedCountQueryParam.build, numNewSources),
                Curry._1(newStakeholderDomainCountQueryParam.build, numNewStakeholderDomains),
                Curry._1(resolvedIssuesCountQueryParam.build, resolvedIssues.length)
              ]));
      var schemaRoute = importStartedLocation === "InspectorIssueDetails" || importStartedLocation === "InspectorIssuesHeader" ? ({
            NAME: "inspector",
            VAL: "issues"
          }) : (
          importStartedLocation === "InspectorEventsTableRow" || importStartedLocation === "InspectorEventsSidebar" || importStartedLocation === "InspectorEventsHeader" ? ({
                NAME: "inspector",
                VAL: "events"
              }) : "gettingStarted"
        );
      return Router.Schema.pushRoute(undefined, {
                  NAME: "branch",
                  VAL: branch._0
                }, schemaRoute, /* [] */0, queryString, undefined, undefined);
    }
    if (typeof branch !== "object") {
      var match = Curry._1(importStartedLocationQueryParam.build, importStartedLocation);
      var queryString$1 = match[0] + "=" + match[1];
      return Router.Schema.pushRoute(undefined, undefined, {
                  NAME: "import",
                  VAL: "success"
                }, undefined, queryString$1, undefined, undefined);
    }
    var match$1 = Curry._1(importStartedLocationQueryParam.build, importStartedLocation);
    var queryString$2 = match$1[0] + "=" + match$1[1];
    Router.Schema.pushRoute(undefined, {
          NAME: "branch",
          VAL: branch._0
        }, {
          NAME: "import",
          VAL: "success"
        }, undefined, queryString$2, undefined, undefined);
  };
  var handleFinishImport = function (selectedBranch) {
    var branchName = typeof selectedBranch === "object" ? (
        selectedBranch.NAME === "Create" ? selectedBranch.VAL : selectedBranch.VAL.name
      ) : "main";
    var importedStakeholderDomains = workingModel.allStakeholderDomains;
    var confirmationText = "You are about to import " + AvoUtils.plural(undefined, undefined, selectedEventCount, "event") + " and " + String(selectedPropertiesCount) + " propert" + (
      selectedPropertiesCount === 1 ? "y" : "ies"
    ) + " to your workspace" + (
      selectedBranch === "Master" ? "" : " on the \"" + branchName + "\" branch"
    ) + "." + (
      importedStakeholderDomains.length !== 0 ? " The stakeholder domains changes will be applied to the tracking plan globally, on all branches." : ""
    ) + " Do you want to run the import?";
    Curry._1(openModal, {
          NAME: "ConfirmModal",
          VAL: [
            "Finish import",
            confirmationText,
            "Import",
            (function (param) {
                Curry._1(setLoading, (function (param) {
                        return true;
                      }));
                Curry._4(onContinue, handleImportSuccessful, issues, selectedBranch, ImportReview__V2__Utils.applyExclusionsBeforeImport(excludedEventIds, excludeAllUserProperties, excludedEventPropertyIds, excludedUserPropertyIds, excludedEventSources, workingModel));
              }),
            (function (param) {
                
              })
          ]
        });
  };
  var currentBranchName;
  if (typeof currentBranch === "object") {
    var branchId = currentBranch.VAL;
    currentBranchName = Belt_Option.mapWithDefault(Belt_List.getByU(openBranches, (function (param) {
                return param[0] === branchId;
              })), "main", (function (param) {
            return param[1];
          }));
  } else {
    currentBranchName = "main";
  }
  var canSelectMain = !workspace.settings.protectedMainBranch || viewerRole === "Admin";
  var userProperties = Belt_Array.keepU(newAndUpdatedProperties, (function (property) {
          return property.sendAs === "UserProperty";
        }));
  var summary = React.useMemo((function (param) {
          var match = Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents).length;
          var match$1 = newAndUpdatedProperties.length;
          var match$2 = workingModel.sources.length;
          var exit = 0;
          var exit$1 = 0;
          if (match !== 0) {
            exit$1 = 3;
          } else {
            var exit$2 = 0;
            if (match$1 === 0) {
              if (match$2 !== 0) {
                return AvoUtils.plural(undefined, undefined, match$2, "source") + " found";
              } else {
                return "Found no data";
              }
            }
            if (match$1 !== 1) {
              exit$2 = 4;
            } else {
              if (match$2 === 0) {
                return "1 property found";
              }
              exit$2 = 4;
            }
            if (exit$2 === 4) {
              if (match$2 === 0) {
                return match$1.toString() + " properties found";
              }
              if (match$1 === 1) {
                return "1 property and " + AvoUtils.plural(undefined, undefined, match$2, "source") + " found";
              }
              exit$1 = 3;
            }
            
          }
          if (exit$1 === 3) {
            if (match$1 !== 0) {
              exit = 2;
            } else {
              if (match$2 === 0) {
                return AvoUtils.plural(undefined, undefined, match, "event") + " found";
              }
              exit = 2;
            }
          }
          if (exit === 2) {
            if (match === 0) {
              return match$1.toString() + " properties and " + AvoUtils.plural(undefined, undefined, match$2, "source") + " found";
            }
            if (match$1 === 0) {
              return AvoUtils.plural(undefined, undefined, match, "event") + " and " + AvoUtils.plural(undefined, undefined, match$2, "source") + " found";
            }
            if (match$1 === 1 && match$2 === 0) {
              return AvoUtils.plural(undefined, undefined, match, "event") + " and 1 property found";
            }
            
          }
          if (match$2 !== 0) {
            if (match$1 !== 1) {
              return AvoUtils.plural(undefined, undefined, match, "event") + ", " + match$1.toString() + " properties and " + AvoUtils.plural(undefined, undefined, match$2, "source") + " found";
            } else {
              return AvoUtils.plural(undefined, undefined, match, "event") + ", 1 property and " + AvoUtils.plural(undefined, undefined, match$2, "source") + " found";
            }
          } else {
            return AvoUtils.plural(undefined, undefined, match, "event") + " and " + match$1.toString() + " properties found";
          }
        }), [workingModel]);
  var headerRef = React.useRef(null);
  var match$11 = React.useState(function (param) {
        return 0.0;
      });
  var setTableTop = match$11[1];
  var tableTop = match$11[0];
  React.useEffect(function (param) {
        var el = headerRef.current;
        if (!(el == null)) {
          Curry._1(setTableTop, (function (param) {
                  return el.getBoundingClientRect().height + -20.0;
                }));
        }
        
      });
  var propertyIdsOnEvents = React.useMemo((function (param) {
          return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.map(Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents), (function ($$event) {
                                        return Belt_Array.concat($$event.propertyIds, $$event.updatedPropertyIds);
                                      }))), (function (param) {
                                return param[0];
                              }))));
        }), [
        workingModel.newEvents,
        workingModel.updatedEvents
      ]);
  var findPropertyIdsUsedInBundleById = function (propertyBundleId) {
    return Belt_Option.getWithDefault(Belt_Option.mapU(Belt_Array.getByU(workingModel.propertyBundles, (function (bundle) {
                          return bundle.id === propertyBundleId;
                        })), (function (bundle) {
                      return Belt_Array.mapU(bundle.propertiesUsedOnlyInThisPropertyBundle, (function (param) {
                                    return param.id;
                                  }));
                    })), []);
  };
  var propertyIdsOnEventBundles = Belt_Array.concatMany(Belt_Array.concat(Belt_Array.concatMany(Belt_Array.map(workingModel.newEvents, (function ($$event) {
                      return Belt_Array.map($$event.propertyBundleIds, findPropertyIdsUsedInBundleById);
                    }))), Belt_Array.concatMany(Belt_Array.map(workingModel.updatedEvents, (function ($$event) {
                      return Belt_Array.map($$event.propertyBundleIds, findPropertyIdsUsedInBundleById);
                    })))));
  var allPropertyIdsOnEvents = Belt_Array.concat(propertyIdsOnEvents, propertyIdsOnEventBundles);
  var eventPropertiesWithoutEvents = Belt_Array.keepU(newAndUpdatedProperties, (function (property) {
          if (property.sendAs === "EventProperty") {
            return Belt_Array.everyU(allPropertyIdsOnEvents, (function (propertyId) {
                          return propertyId !== property.id;
                        }));
          } else {
            return false;
          }
        }));
  return React.createElement(ImportReview__Context.make, {
              eventConflictInfo: eventConflictInfo,
              importModel: workingModel,
              resolvedProperties: resolvedProperties,
              children: null
            }, React.createElement("div", {
                  ref: headerRef,
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(16)),
                        tl: {
                          hd: Css.paddingTop(Css.px(48)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(32)),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: {
                                hd: Css.position("sticky"),
                                tl: {
                                  hd: Css.top(Css.pxFloat(-20.0)),
                                  tl: {
                                    hd: Css.left(Css.px(0)),
                                    tl: {
                                      hd: Css.zIndex(1),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.light02),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("header", undefined, React.createElement(Title.make, {
                          children: "Review import data",
                          size: "Medium"
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light10,
                          children: "Please review the information below before adding to your Tracking Plan"
                        }), React.createElement(Spacer.make, {
                          height: 24
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(16)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.gap(Css.px(6)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement(IconCheckmarkCircle.make, {
                                  size: 16,
                                  color: Styles.Color.green
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: summary
                                })), Belt_MapString.size(autoResolvedProperties) > 0 ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.gap(Css.px(4)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(Icon.make, {
                                    type_: "lilRobotFilled",
                                    size: "small",
                                    color: Styles.Color.light11
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    children: AvoUtils.plural(undefined, undefined, Belt_MapString.size(autoResolvedProperties), "conflict") + " auto resolved"
                                  })) : null, totalAlertsCount > 0 ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.gap(Css.px(4)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(IconWarning.make, {
                                    color: Styles.Color.orange,
                                    size: 16
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    children: AvoUtils.plural(undefined, undefined, totalAlertsCount, "alert")
                                  })) : null, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontFamily(Styles.FontFamily.polar),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.flexDirection("column"),
                                        tl: {
                                          hd: Css.gap(Css.px(4)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, Belt_Array.mapU(workingModel.warnings.parseWarnings, (function (errorLine) {
                                    return React.createElement($$Text.make, {
                                                size: "Small",
                                                color: Styles.Color.red,
                                                children: errorLine,
                                                key: errorLine
                                              });
                                  })))))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2("zero", Css.px(16)),
                        tl: {
                          hd: Css.marginBottom(Css.px(16)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100.0)),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexDirection("row"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("spaceBetween"),
                                    tl: {
                                      hd: Css.padding(Css.px(8)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.white),
                                        tl: {
                                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                          tl: {
                                            hd: Css.borderTopLeftRadius(Css.px(6)),
                                            tl: {
                                              hd: Css.borderTopRightRadius(Css.px(6)),
                                              tl: {
                                                hd: Css.zIndex(1),
                                                tl: {
                                                  hd: Css.position("sticky"),
                                                  tl: {
                                                    hd: Css.top(Css.pxFloat(tableTop)),
                                                    tl: {
                                                      hd: Css.height(Css.pxFloat(50.0)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement(ImportReview__V2$SearchInput, {
                          value: match$4.searchInput,
                          hiddenEventsCount: (workingModel.newEvents.length + workingModel.updatedEvents.length | 0) - filteredEvents.length | 0,
                          onChange: (function (value) {
                              Curry._1(reduceFilterState, {
                                    NAME: "updateSearchInput",
                                    VAL: value
                                  });
                              debounceFunction(function (param) {
                                    Curry._1(reduceFilterState, "filterEvents");
                                  });
                            }),
                          onClear: (function (param) {
                              Curry._1(reduceFilterState, "clearInput");
                            })
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(4)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, typeof currentBranch === "object" ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Medium",
                                    color: Styles.Color.light08,
                                    children: "Import " + selectedEventCount.toString() + " Events & " + selectedPropertiesCount.toString() + " Properties to…"
                                  }), React.createElement(Spacer.make, {
                                    width: 2
                                  }), React.createElement(IconCheckmark.make, {
                                    size: 12,
                                    color: Styles.Color.green
                                  }), React.createElement(Spacer.make, {
                                    width: 2
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    color: Styles.Color.green,
                                    children: currentBranchName
                                  }), React.createElement(Spacer.make, {
                                    width: 8
                                  }), React.createElement(Button.make, {
                                    disabled: isLoading || selectedEventCount === 0,
                                    label: isLoading ? "Importing…" : "Import to Tracking Plan",
                                    onClick: (function (param) {
                                        Curry._1(setLoading, (function (param) {
                                                return true;
                                              }));
                                        handleFinishImport({
                                              NAME: "Branch",
                                              VAL: {
                                                id: NamedBranch.unnamedBranchToId(currentBranch),
                                                name: currentBranchName
                                              }
                                            });
                                      })
                                  })) : (
                            Curry._1(TrackingPlanMappedModel.Events.size, mappedEvents) === 0 ? React.createElement(Button.make, {
                                    disabled: isLoading || selectedEventCount === 0,
                                    label: isLoading ? "Importing..." : "Import " + String(selectedEventCount) + " Events & " + String(selectedPropertiesCount) + " Properties to main",
                                    loading: isLoading,
                                    onClick: (function (param) {
                                        handleFinishImport(currentBranch);
                                      })
                                  }) : React.createElement(ImportReview__BranchPicker.make, {
                                    canSelectMain: canSelectMain,
                                    handleFinishImport: handleFinishImport,
                                    importDisabled: selectedEventCount === 0,
                                    openBranches: openBranches,
                                    selectedBranch: match$6[0],
                                    selectedEventCount: selectedEventCount,
                                    selectedPropertiesCount: selectedPropertiesCount,
                                    setSelectedBranch: match$6[1]
                                  })
                          ))), React.createElement("table", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("block"),
                            tl: {
                              hd: Css.borderCollapse("collapse"),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.minWidth(Css.pct(100.0)),
                                  tl: {
                                    hd: Css.maxWidth(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                      tl: {
                                        hd: Css.borderTopWidth("zero"),
                                        tl: {
                                          hd: Css.borderBottomLeftRadius(Css.px(6)),
                                          tl: {
                                            hd: Css.borderBottomRightRadius(Css.px(6)),
                                            tl: {
                                              hd: Css.selector(" tr[class$=\"row\"]:not(:nth-last-child(2))", {
                                                    hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents).length === 0 ? null : React.createElement("thead", {
                            className: Curry._1(Css.style, {
                                  hd: Css.maxWidth(Css.pct(100.0)),
                                  tl: {
                                    hd: Css.position("sticky"),
                                    tl: {
                                      hd: Css.top(Css.pxFloat(tableTop + 50.0)),
                                      tl: {
                                        hd: Css.zIndex(4),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.6)),
                                          tl: {
                                            hd: Css.backdropFilter({
                                                  hd: {
                                                    NAME: "blur",
                                                    VAL: Css.pxFloat(7.5)
                                                  },
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.selector(" tr", {
                                                    hd: Css.boxShadows({
                                                          hd: Css_Legacy_Core.Shadow.box(undefined, Css.px(1), undefined, undefined, undefined, Styles.Color.light04),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }),
                                              tl: {
                                                hd: Css.selector(" th", {
                                                      hd: Css.textAlign("left"),
                                                      tl: /* [] */0
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.minWidth(Css.px(35)),
                                          tl: /* [] */0
                                        })
                                  }), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding(Css.px(12)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(Mantine.Checkbox.make, {
                                        checked: Belt_Array.every(filteredEvents, (function (param) {
                                                return !Belt_SetString.has(excludedEventIds, param.id);
                                              })),
                                        color: "blue100",
                                        indeterminate: isIndeterminate(filteredEvents, excludedEventIds),
                                        onChange: (function (param) {
                                            Curry._1(setExcludedEventIds, (function (excluded) {
                                                    if (Belt_SetString.isEmpty(getVisibleExcludedEvents(filteredEvents, excludedEventIds))) {
                                                      return Belt_SetString.fromArray(Belt_Array.map(filteredEvents, (function (param) {
                                                                        return param.id;
                                                                      })));
                                                    } else {
                                                      return Belt_SetString.removeMany(excluded, Belt_Array.map(filteredEvents, (function (param) {
                                                                        return param.id;
                                                                      })));
                                                    }
                                                  }));
                                          }),
                                        size: {
                                          NAME: "num",
                                          VAL: 16
                                        }
                                      })), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.light10,
                                        children: "Event"
                                      })), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(12)),
                                            tl: {
                                              hd: Css.width(Css.pct(100.0)),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.light10,
                                        children: "Sources"
                                      })), React.createElement("th", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(12)),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Tiny",
                                        weight: "Semi",
                                        color: Styles.Color.light10,
                                        children: "Alerts"
                                      })))), React.createElement("tbody", undefined, Belt_Array.concatMany([
                              userProperties.length !== 0 ? [React.createElement(ImportReviewRow.UserOrSystemProperties.make, {
                                        properties: userProperties,
                                        excludedProperties: excludedUserPropertyIds,
                                        onPropertyToggle: handleIncludeUserPropertyToggle,
                                        onPropertiesIncludedToggle: handleExcludeAllUserPropertiesToggle,
                                        propertiesIncluded: !excludeAllUserProperties,
                                        importMethod: importMethod,
                                        propertySendAs: "UserProperty",
                                        key: "user-properties"
                                      })] : [],
                              eventPropertiesWithoutEvents.length !== 0 ? [React.createElement(ImportReviewRow.EventPropertiesWithoutEvents.make, {
                                        properties: eventPropertiesWithoutEvents,
                                        importMethod: importMethod,
                                        key: "event-properties"
                                      })] : [],
                              Belt_Array.map(Belt_SortArray.stableSortByU(filteredEvents, (function (a, b) {
                                          return Caml.string_compare(Case.camel(a.name), Case.camel(b.name));
                                        })), (function ($$event) {
                                      var propertyBundles = Belt_Array.keepMapU($$event.propertyBundleIds, (function (id) {
                                              var match = Belt_Array.getByU(workingModel.propertyBundles, (function (group) {
                                                      return group.id === id;
                                                    }));
                                              var match$1 = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, mappedPropertyBundles, id);
                                              if (match !== undefined) {
                                                return match;
                                              } else if (match$1 !== undefined) {
                                                return {
                                                        id: match$1.id,
                                                        name: match$1.name,
                                                        description: match$1.description,
                                                        propertiesUsedOnlyInThisPropertyBundle: Belt_Array.keepMapU(Belt_List.toArray(match$1.properties), (function (propertyId) {
                                                                return Belt_Option.mapU(Curry._2(TrackingPlanMappedModel.Properties.get, mappedProperties, propertyId), (function (param) {
                                                                              return {
                                                                                      id: propertyId,
                                                                                      name: param.name
                                                                                    };
                                                                            }));
                                                              })),
                                                        propertiesUsedInMultiplePropertyBundles: []
                                                      };
                                              } else {
                                                return ;
                                              }
                                            }));
                                      var propertyBundleProperties = Belt_Array.concatMany(Belt_Array.map(propertyBundles, (function (group) {
                                                  return Belt_Array.keepMapU(group.propertiesUsedOnlyInThisPropertyBundle, (function (param) {
                                                                var id = param.id;
                                                                var match = Belt_Array.getByU(newAndUpdatedProperties, (function (property) {
                                                                        return property.id === id;
                                                                      }));
                                                                var match$1 = Curry._2(TrackingPlanMappedModel.Properties.get, mappedProperties, id);
                                                                if (match !== undefined) {
                                                                  return [
                                                                          match,
                                                                          false
                                                                        ];
                                                                }
                                                                if (match$1 === undefined) {
                                                                  return ;
                                                                }
                                                                var allowedValues = GetAllPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), match$1);
                                                                var importProperty = Curry._2(ImportModel.Mapped.PropertiesByName.get, allParsedPropertiesByName, match$1.name);
                                                                var warnings = Belt_Option.mapWithDefaultU(importProperty, [], (function (param) {
                                                                        return param.warnings;
                                                                      }));
                                                                return [
                                                                        {
                                                                          id: match$1.id,
                                                                          name: match$1.name,
                                                                          uniqueNameIndex: undefined,
                                                                          description: match$1.description,
                                                                          type_: match$1.type_,
                                                                          list: match$1.list,
                                                                          matches: allowedValues,
                                                                          warnings: warnings,
                                                                          presence: undefined,
                                                                          sendAs: match$1.sendAs,
                                                                          nameMapping: undefined
                                                                        },
                                                                        true
                                                                      ];
                                                              }));
                                                })));
                                      var partial_arg = $$event.name;
                                      var partial_arg$1 = $$event.id;
                                      var partial_arg$2 = $$event.name;
                                      var partial_arg$3 = $$event.id;
                                      var partial_arg$4 = $$event.name;
                                      var partial_arg$5 = $$event.name;
                                      var partial_arg$6 = $$event.id;
                                      var partial_arg$7 = $$event.name;
                                      var partial_arg$8 = $$event.id;
                                      return React.createElement(ImportReviewRow__Event.make, {
                                                  event: $$event,
                                                  included: !Belt_SetString.has(excludedEventIds, $$event.id),
                                                  propertyGroups: propertyBundles,
                                                  propertyGroupProperties: propertyBundleProperties,
                                                  onToggle: (function (param) {
                                                      var checked = param.target.checked;
                                                      Curry._1(setExcludedEventIds, (function (excluded) {
                                                              if (checked) {
                                                                return Belt_SetString.remove(excluded, partial_arg$1);
                                                              } else {
                                                                return Belt_SetString.add(excluded, partial_arg$1);
                                                              }
                                                            }));
                                                      return trackImportChanged(Caml_option.some(partial_arg), undefined, checked ? "EventSelected" : "EventDeselected");
                                                    }),
                                                  excludedProperties: excludedEventPropertyIds,
                                                  excludedSources: excludedEventSources,
                                                  sources: workingModel.sources,
                                                  onPropertyToggle: (function (param, param$1, param$2) {
                                                      var checked = param$2.target.checked;
                                                      Curry._1(setExcludedEventPropertyIds, (function (excluded) {
                                                              if (checked) {
                                                                return Belt_Set.remove(excluded, [
                                                                            partial_arg$3,
                                                                            param
                                                                          ]);
                                                              } else {
                                                                return Belt_Set.add(excluded, [
                                                                            partial_arg$3,
                                                                            param
                                                                          ]);
                                                              }
                                                            }));
                                                      return trackImportChanged(Caml_option.some(partial_arg$2), Caml_option.some(param$1), checked ? "PropertySelected" : "PropertyDeselected");
                                                    }),
                                                  onPropertyTypeChange: (function (param, param$1, param$2) {
                                                      return Belt_Option.forEach(param$2, (function (type_) {
                                                                    var eventsUsingProperty = Belt_Array.keepU(Belt_Array.concat(workingModel.newEvents, workingModel.updatedEvents), (function (param$3) {
                                                                            return Belt_Array.someU(Belt_Array.concat(param$3.propertyIds, param$3.updatedPropertyIds), (function (param$4) {
                                                                                          return param$4[0] === param;
                                                                                        }));
                                                                          })).length;
                                                                    var onSuccess = function (param$3) {
                                                                      trackImportChanged(Caml_option.some(partial_arg$4), Caml_option.some(param$1), "PropertyTypeChanged");
                                                                    };
                                                                    if (eventsUsingProperty > 1) {
                                                                      return Curry._1(openModal, {
                                                                                  NAME: "ConfirmModal",
                                                                                  VAL: [
                                                                                    "Change property type",
                                                                                    "You are about to change the type of this property. This will change the type of this property for " + AvoUtils.plural(undefined, undefined, eventsUsingProperty, "event") + ". Do you want to continue?",
                                                                                    "Change type",
                                                                                    (function (param$3) {
                                                                                        ImportReview__V2__Utils.updatePropertyType(onSuccess, setWorkingModel, param, type_);
                                                                                      }),
                                                                                    (function (param) {
                                                                                        
                                                                                      })
                                                                                  ]
                                                                                });
                                                                    } else {
                                                                      return ImportReview__V2__Utils.updatePropertyType(onSuccess, setWorkingModel, param, type_);
                                                                    }
                                                                  }));
                                                    }),
                                                  onPropertyPresenceChange: (function (param, param$1, param$2) {
                                                      return Belt_Option.forEach(param$2, (function (presence) {
                                                                    ImportReview__V2__Utils.updatePropertyPresence((function (param$3) {
                                                                            trackImportChanged(Caml_option.some(partial_arg$5), Caml_option.some(param$1), "PropertyPresenceChanged");
                                                                          }), setWorkingModel, partial_arg$6, param, presence);
                                                                  }));
                                                    }),
                                                  onSourceToggle: (function (param, param$1) {
                                                      var checked = param$1.target.checked;
                                                      Curry._1(setExcludedEventSources, (function (excluded) {
                                                              if (checked) {
                                                                return Belt_Set.remove(excluded, [
                                                                            partial_arg$8,
                                                                            param
                                                                          ]);
                                                              } else {
                                                                return Belt_Set.add(excluded, [
                                                                            partial_arg$8,
                                                                            param
                                                                          ]);
                                                              }
                                                            }));
                                                      return trackImportChanged(Caml_option.some(partial_arg$7), undefined, checked ? "SourceSelected" : "SourceDeselected");
                                                    }),
                                                  key: $$event.id
                                                });
                                    }))
                            ]), filteredEvents.length === 0 ? React.createElement("tr", {
                                className: Curry._1(Css.merge, {
                                      hd: ImportReviewRow__Styles.rootStyles,
                                      tl: /* [] */0
                                    })
                              }, React.createElement("td", undefined), React.createElement("td", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.padding(Css.px(12)),
                                          tl: /* [] */0
                                        }),
                                    colSpan: 10
                                  }, React.createElement($$Text.make, {
                                        color: Styles.Color.light10,
                                        children: "No results!"
                                      }))) : null))));
}

var ImportBranchPicker;

var WorkingModelContext;

var $$Event;

var make = ImportReview__V2;

export {
  rootStyles ,
  headerStyles ,
  topStyles ,
  backlinkStyles ,
  countsStyles ,
  countStyles ,
  tableIntroStyles ,
  tableIntroLeftStyles ,
  ImportBranchPicker ,
  WorkingModelContext ,
  $$Event ,
  SearchInput ,
  debounceFunction ,
  isIndeterminate ,
  getVisibleExcludedEvents ,
  getEventConflictInfo ,
  make ,
}
/* rootStyles Not a pure module */
