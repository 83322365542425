// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";

function getId(namedBranch) {
  if (typeof namedBranch !== "object") {
    return "master";
  } else {
    return namedBranch._0;
  }
}

function getName(namedBranch) {
  if (typeof namedBranch !== "object") {
    return "main";
  } else {
    return namedBranch._1;
  }
}

function toUnnamed(namedBranch) {
  if (typeof namedBranch !== "object") {
    return "Master";
  } else {
    return {
            NAME: "Branch",
            VAL: namedBranch._0
          };
  }
}

function fromUnnamed(model, unnamedBranch) {
  if (typeof unnamedBranch !== "object") {
    return "Master";
  }
  var id = unnamedBranch.VAL;
  var name = Belt_Option.getWithDefault(ModelUtils.getBranchName(model.openBranches, id), "");
  return {
          TAG: "Branch",
          _0: id,
          _1: name
        };
}

function fromUnnamed__mapped(model, unnamedBranch) {
  if (typeof unnamedBranch !== "object") {
    return "Master";
  }
  var id = unnamedBranch.VAL;
  var name = Belt_Option.getWithDefault(ModelUtils_mapped.getBranchName(model.openBranches, id), "");
  return {
          TAG: "Branch",
          _0: id,
          _1: name
        };
}

function unnamedBranchToId(branch) {
  if (typeof branch === "object") {
    return branch.VAL;
  } else {
    return "master";
  }
}

export {
  getId ,
  getName ,
  toUnnamed ,
  fromUnnamed ,
  fromUnnamed__mapped ,
  unnamedBranchToId ,
}
/* ModelUtils Not a pure module */
