// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function getTextEncoder(param) {
  try {
    return new TextEncoder();
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    var match = Belt_Option.flatMap(Caml_js_exceptions.as_js_exn(e), (function (prim) {
            return prim.name;
          }));
    if (match === "ReferenceError") {
      return ((new (require('util')).TextEncoder));
    } else {
      return Js_exn.raiseReferenceError("TextEncoder is not available");
    }
  }
}

function getStringByteSize(encoder, string) {
  var encoder$1 = Belt_Option.getWithDefault(encoder, getTextEncoder());
  return encoder$1.encode(string).length;
}

function split(size, string) {
  var findSafeSplitPoint = function (_pos, string) {
    while(true) {
      var pos = _pos;
      var testChunk = string.slice(0, pos);
      var testChunkBytes = getStringByteSize(undefined, testChunk);
      if (testChunkBytes > size) {
        if (pos <= 1) {
          var $$char = string.charAt(pos);
          var charBytes = getStringByteSize(undefined, $$char);
          Js_exn.raiseError("Cannot split string: found character \"" + $$char + "\" at position " + String(pos) + " that requires " + String(charBytes) + " bytes, but chunk size is only " + String(size) + " bytes");
        }
        _pos = pos - 1 | 0;
        continue ;
      }
      var nextChar = string.charAt(pos);
      var prevChar = string.charAt(pos - 1 | 0);
      if (!(Belt_Option.getWithDefault(nextChar.codePointAt(0), 0) >= 56320 && Belt_Option.getWithDefault(nextChar.codePointAt(0), 0) <= 57343 || Belt_Option.getWithDefault(prevChar.codePointAt(0), 0) >= 55296 && Belt_Option.getWithDefault(prevChar.codePointAt(0), 0) <= 56319)) {
        return pos;
      }
      _pos = pos - 1 | 0;
      continue ;
    };
  };
  var _chunksOpt = [];
  var _string = string;
  while(true) {
    var chunksOpt = _chunksOpt;
    var string$1 = _string;
    var chunks = chunksOpt !== undefined ? chunksOpt : [];
    if (getStringByteSize(undefined, string$1) <= size) {
      return Belt_Array.concatMany([
                  chunks,
                  [string$1]
                ]);
    }
    var splitPoint = findSafeSplitPoint(size, string$1);
    var chunk = string$1.slice(0, splitPoint);
    var rest = string$1.slice(splitPoint);
    var nextChunks = Belt_Array.concatMany([
          chunks,
          [chunk]
        ]);
    if (rest === "") {
      return nextChunks;
    }
    _string = rest;
    _chunksOpt = nextChunks;
    continue ;
  };
}

export {
  getTextEncoder ,
  getStringByteSize ,
  split ,
}
/* No side effect */
