// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Semver from "../bindings/semver.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function print(appVersion) {
  return appVersion.VAL;
}

function classify(releaseString) {
  var match = Semver.valid(releaseString);
  var match$1 = Semver.coerceAndValidate(releaseString);
  if (match !== undefined) {
    return {
            NAME: "semver",
            VAL: match
          };
  } else if (match$1 !== undefined) {
    return {
            NAME: "version",
            VAL: releaseString
          };
  } else {
    return {
            NAME: "invalid",
            VAL: releaseString
          };
  }
}

function appVersion(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          appVersion: classify(Json_decode.field("appVersion", Json_decode.string, json)),
          firstSeen: new Date(Json_decode.field("firstSeen", Json_decode.string, json)),
          lastSeen: new Date(Json_decode.field("lastSeen", Json_decode.string, json))
        };
}

function appVersions(json) {
  return Json_decode.array(appVersion, json);
}

function response(json) {
  return Belt_MapString.fromArray(Js_dict.entries(Json_decode.field("appVersions", (function (param) {
                        return Json_decode.dict(appVersions, param);
                      }), json)));
}

var Decode = {
  appVersion: appVersion,
  appVersions: appVersions,
  response: response
};

function getLatestSourceVersions(offsetOpt, appVersions) {
  var offset = offsetOpt !== undefined ? offsetOpt : 0;
  return Belt_MapString.map(appVersions, (function (sourceAppVersions) {
                return Belt_Option.map(Belt_Array.get(sourceAppVersions, offset), (function (param) {
                              return param.appVersion;
                            }));
              }));
}

function getLatestVersion(offsetOpt, appVersions, sourceId) {
  var offset = offsetOpt !== undefined ? offsetOpt : 0;
  return Belt_Option.map(Belt_Option.flatMap(Belt_MapString.get(appVersions, sourceId), (function (versions) {
                    return Belt_Array.get(versions, offset);
                  })), (function (param) {
                return param.appVersion;
              }));
}

function getLatestVersions(lenOpt, appVersions, sourceId) {
  var len = lenOpt !== undefined ? lenOpt : 3;
  return Belt_Option.mapWithDefault(Belt_Option.map(Belt_MapString.get(appVersions, sourceId), (function (param) {
                    return Belt_Array.slice(param, 0, len);
                  })), [], (function (versions) {
                return Belt_Array.map(versions, (function (param) {
                              return param.appVersion;
                            }));
              }));
}

var $$Map;

export {
  print ,
  $$Map ,
  classify ,
  Decode ,
  getLatestSourceVersions ,
  getLatestVersion ,
  getLatestVersions ,
}
/* Semver Not a pure module */
