// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "../../../app/src/ModelUtils_mapped.mjs";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../TrackingPlanMappedModel.mjs";
import * as GetEventIdsSendingPropertyForEventSpecificValuesUseCase from "./GetEventIdsSendingPropertyForEventSpecificValuesUseCase.mjs";

function getPropertyValidationMatches(propertyValidations) {
  return Belt_Option.getWithDefault(BeltListExtensions.findAndMap(propertyValidations, (function (validation) {
                    if (typeof validation === "object" && validation.NAME === "Matches") {
                      return validation.VAL;
                    }
                    
                  })), /* [] */0);
}

function convert(modelProperties, property, getEventIdsSendingPropertyAsPropertyRef, propertyMatchValidations) {
  var propertyValuesAllEventsKey = GetEventIdsSendingPropertyForEventSpecificValuesUseCase.get(modelProperties, property, getEventIdsSendingPropertyAsPropertyRef);
  var eventIdsSendingPropertyForEventSpecificValues;
  switch (propertyValuesAllEventsKey.TAG) {
    case "SystemProperty" :
        eventIdsSendingPropertyForEventSpecificValues = [propertyValuesAllEventsKey._0];
        break;
    case "SystemAndNotSystemProperty" :
    case "NotSystemProperty" :
        eventIdsSendingPropertyForEventSpecificValues = propertyValuesAllEventsKey._0;
        break;
    
  }
  return Belt_List.toArray(Belt_List.mapU(propertyMatchValidations, (function (validationMatch) {
                    var excludedSources = validationMatch[1];
                    return [
                            validationMatch[0].VAL,
                            Belt_MapString.fromArray(Belt_Array.keepMapU(eventIdsSendingPropertyForEventSpecificValues, (function (eventIdSendingProperty) {
                                        if (excludedSources) {
                                          return [
                                                  eventIdSendingProperty,
                                                  {
                                                    TAG: "DisallowedSources",
                                                    _0: Belt_SetString.fromArray(Belt_List.toArray(excludedSources))
                                                  }
                                                ];
                                        }
                                        
                                      })))
                          ];
                  })));
}

function convertWithSlowModel(model, property, validations) {
  return convert(Belt_List.toArray(Belt_List.mapU(model.properties, (function (property) {
                        if (property.TAG === "PropertyRef") {
                          return Pervasives.failwith("Expected property");
                        } else {
                          return property._0;
                        }
                      }))), property, (function (propertyId) {
                return Belt_Array.mapU(Belt_List.toArray(ModelUtils.eventsSendingProperty(model, undefined, propertyId)), (function ($$event) {
                              return $$event.id;
                            }));
              }), getPropertyValidationMatches(validations));
}

function convertWithMappedModel(model, property, validations) {
  return convert(Curry._1(TrackingPlanMappedModel.Properties.toArray, model.properties), property, (function (propertyId) {
                return Curry._1(TrackingPlanMappedModel.Events.keys, ModelUtils_mapped.eventsSendingProperty(model.events, model.propertyBundles, undefined, propertyId));
              }), getPropertyValidationMatches(validations));
}

export {
  convertWithSlowModel ,
  convertWithMappedModel ,
}
/* ModelUtils Not a pure module */
