// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Version from "../version.mjs";
import * as React from "@sentry/react";

function options(env) {
  return {
          dsn: "https://1866be293d8e4d708c3551795e7aeea8@o998558.ingest.sentry.io/5957408",
          tracesSampleRate: 0.1,
          integrations: [
            React.browserTracingIntegration(),
            React.browserProfilingIntegration()
          ],
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
          tracePropagationTargets: [
            "localhost",
            "https://www.avo.app",
            "avo-dev.app"
          ],
          profilesSampleRate: 1.0,
          environment: env,
          release: Version.current
        };
}

function init(env) {
  React.init(options(env));
}

function captureException(exn, obj) {
  React.captureException(exn, {
        extra: obj
      });
}

function captureExceptionString(string, obj) {
  React.captureException(string, {
        extra: obj
      });
}

export {
  options ,
  init ,
  captureException ,
  captureExceptionString ,
}
/* Version Not a pure module */
