// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ActionDiff from "../../../shared/ActionDiff.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function isEventActionRelevantForSource(model, action, sourceId) {
  if (typeof action !== "object") {
    return false;
  }
  var variant = action.NAME;
  if (variant === "RemoveEventType" || variant === "AddEventType") {
    return true;
  }
  if (variant === "RemoveEventTrigger" || variant === "UpdateTriggerDescription" || variant === "UpdateTriggerContent") {
    var eventId = action.VAL[0];
    return Belt_List.someU(ModelUtils.getSourceEvents(undefined, model, sourceId), (function ($$event) {
                  return $$event.id === eventId;
                }));
  }
  if (variant === "AddSourceSegmentIntegration" || variant === "RemoveSourceConfig" || variant === "UpdateSourcePlatform" || variant === "AddSourceConfig" || variant === "UpdateSourceSegmentIntegration" || variant === "IncludeDestinationInSourceV2" || variant === "RemoveSourceSegmentIntegration" || variant === "UpdateLocalWorkspaceName" || variant === "UpdateSourcePlatformV3" || variant === "UpdateSourcePlatformV2" || variant === "LegacyIncludeDestinationInSource" || variant === "UpdateSourceName" || variant === "UpdateSourceDestinationMode") {
    return action.VAL[0] === sourceId;
  }
  if (variant === "AddPropertyGroupToEvent" || variant === "RemovePropertyPinnedValue" || variant === "RemovePropertyGroupFromEvent" || variant === "RemoveGroupTypeFromLogEvent" || variant === "UpdateEventName" || variant === "DuplicateEvent" || variant === "RemoveUserFromGroup" || variant === "UpdatePropertyPinnedValue" || variant === "AddGroupTypeToLogEvent" || variant === "PushGlobalRequirementsToTrackingPlan" || variant === "DuplicateMetric" || variant === "RemovePropertyRefV3" || variant === "AddUserToGroup" || variant === "RemovePropertyRefV2Deprecated" || variant === "RemoveEvent" || variant === "AddPropertyRef" || variant === "CreateDemoBranch" || variant === "AddEvent") {
    return true;
  }
  if (variant === "UpdatePropertyPinnedValueOnEventVariant" || variant === "ClearEventVariantSpecificPropertyValueOverride" || variant === "ClearPropertyRegexOverrideFromEventVariant" || variant === "RemovePropertyFromEventVariant" || variant === "CreateEventVariant" || variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" || variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed" || variant === "SetPropertyAbsenceOnVariant" || variant === "ClearEventVariantPropertyOverride" || variant === "UpdatePropertyRegexOverrideOnEventVariant" || variant === "ClearSourceOverrideOnVariant" || variant === "SetSourceOverrideOnVariant" || variant === "ClearPropertyPinnedValueFromEventVariant" || variant === "AddPropertyToEventVariant") {
    var baseEventId = action.VAL[0].baseEventId;
    return Belt_List.someU(ModelUtils.getSourceEvents(undefined, model, sourceId), (function ($$event) {
                  return $$event.id === baseEventId;
                }));
  }
  if (variant === "CreateEventTrigger" || variant === "UpdateTriggerSources" || variant === "UpdateEventVariantTriggerSources") {
    var sources = action.VAL[2];
    if (typeof sources !== "object") {
      return true;
    } else {
      return Belt_Array.some(sources._0, (function (actionSourceId) {
                    return actionSourceId === sourceId;
                  }));
    }
  }
  if (variant !== "AddRule") {
    if (variant === "CreateLocalWorkspace" || variant === "CreateSource" || variant === "DeleteSource" || variant === "DeleteLocalWorkspace") {
      return action.VAL === sourceId;
    } else if (variant === "ExcludeEventFromSourceV2" || variant === "IncludeDestinationInEventSource" || variant === "ExcludeDestinationFromEventSource" || variant === "IncludeEventInSource" || variant === "ToggleIncludeEventInCodegenForSource" || variant === "ExcludeEventFromSource" || variant === "IncludeEventInSourceV2") {
      return action.VAL[1] === sourceId;
    } else if (variant === "RemoveTriggerSource" || variant === "AddTriggerSource") {
      return action.VAL[2] === sourceId;
    } else if (variant === "ConfigureInspectorValidation") {
      return Belt_MapString.has(action.VAL[1], sourceId);
    } else {
      return false;
    }
  }
  var match = action.VAL[2];
  var variant$1 = match.NAME;
  if (variant$1 === "SegmentIntegrationOption") {
    return false;
  }
  if (variant$1 !== "NameInLocalWorkspace") {
    return true;
  }
  var workspace = match.VAL.workspace;
  if (workspace !== undefined) {
    if (typeof workspace === "object") {
      return workspace.VAL === sourceId;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function isEventIncludedInSource(model, sourceId, eventId) {
  return Belt_List.someU(ModelUtils.getSourceEvents(undefined, model, sourceId), (function (sourceEvent) {
                return sourceEvent.id === eventId;
              }));
}

function isPropertyIncludedInEventAndSource(model, sourceId, eventId, propertyId) {
  var property = ModelUtils.resolvePropertyById(propertyId, model);
  if (property !== undefined) {
    return ModelUtils.isPropertyIncludedOnEventAndSource(undefined, property, eventId, sourceId, model)();
  } else {
    return false;
  }
}

function isPropertyRelevantForSource(propertyId, sourceId, model) {
  if (sourceId === "") {
    return true;
  } else {
    return ModelUtils.isPropertySentFromSource(model, propertyId, sourceId);
  }
}

function getSourceIdsDirectlyChangedOnBranch(fromModel, toModel) {
  return Belt_List.toArray(Belt_List.keepMap(toModel.sources, (function (toSource) {
                    var fromSource = Belt_List.getBy(fromModel.sources, (function (fromSource) {
                            return fromSource.id === toSource.id;
                          }));
                    var actions = Belt_Option.getWithDefault(Belt_Option.map(fromSource, (function (fromSource) {
                                return ActionDiff.diffSource(fromSource, toSource);
                              })), /* [] */0);
                    if (Belt_List.size(actions) > 0) {
                      return toSource.id;
                    }
                    
                  })));
}

function getSourceWithEventsChanges(branchModel, sourcesToAttachedEventsActions, actions) {
  return Belt_Array.keepU(Belt_Array.mapU(sourcesToAttachedEventsActions, (function (param) {
                    var source = param[0];
                    return {
                            source: source,
                            eventsChanges: Belt_List.toArray(Belt_List.keepMapU(param[1], (function (eventActionsById) {
                                        if (!eventActionsById) {
                                          return ;
                                        }
                                        var match = eventActionsById.hd[0];
                                        if (typeof match === "object" && match.NAME === "Event") {
                                          return {
                                                  eventId: match.VAL,
                                                  eventActions: Belt_List.toArray(Belt_List.keepMapU(eventActionsById, (function (eventAndEventAction) {
                                                              var eventAction = eventAndEventAction[1];
                                                              if (isEventActionRelevantForSource(branchModel, eventAction, source.id)) {
                                                                return eventAction;
                                                              }
                                                              
                                                            })))
                                                };
                                        }
                                        
                                      }))),
                            propertyChanges: Belt_List.flatten(Belt_List.keepMap(actions, (function (action) {
                                        if (!action) {
                                          return ;
                                        }
                                        var match = action.hd[0];
                                        if (typeof match === "object" && match.NAME === "Property" && isPropertyRelevantForSource(match.VAL, source.id, branchModel)) {
                                          return action;
                                        }
                                        
                                      })))
                          };
                  })), (function (sourceWithChanges) {
                if (sourceWithChanges.eventsChanges.length !== 0 && Belt_Array.someU(sourceWithChanges.eventsChanges, (function (eventWithPropertiesChanges) {
                          return eventWithPropertiesChanges.eventActions.length !== 0;
                        }))) {
                  return true;
                } else {
                  return Belt_List.length(sourceWithChanges.propertyChanges) > 0;
                }
              }));
}

function getSourcesToAttachedEventsActions(branchModel, eventActions) {
  if (branchModel.sources === /* [] */0) {
    return [];
  } else {
    return Belt_List.toArray(Belt_List.mapU(branchModel.sources, (function (source) {
                      return [
                              source,
                              Belt_List.keepU(eventActions, (function (eventActionsById) {
                                      if (!eventActionsById) {
                                        return false;
                                      }
                                      var match = eventActionsById.hd[0];
                                      if (typeof match === "object" && match.NAME === "Event") {
                                        return isEventIncludedInSource(branchModel, source.id, match.VAL);
                                      } else {
                                        return false;
                                      }
                                    }))
                            ];
                    })));
  }
}

function getEventActions(actions) {
  return Belt_List.keep(actions, (function (action) {
                if (!action) {
                  return false;
                }
                var match = action.hd[0];
                if (typeof match === "object") {
                  return match.NAME === "Event";
                } else {
                  return false;
                }
              }));
}

export {
  isEventActionRelevantForSource ,
  isEventIncludedInSource ,
  isPropertyIncludedInEventAndSource ,
  isPropertyRelevantForSource ,
  getSourceIdsDirectlyChangedOnBranch ,
  getSourceWithEventsChanges ,
  getSourcesToAttachedEventsActions ,
  getEventActions ,
}
/* ActionDiff Not a pure module */
