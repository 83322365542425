// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as AnalyticsUtilsBundles from "./analyticsUtilsBundles.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function planToAnalyticsPlan(planName) {
  switch (planName) {
    case "Scholarship" :
        return "Scholarship";
    case "Growth" :
    case "GrowthV2" :
        return "Growth";
    case "Starter" :
        return "Starter";
    case "Empty" :
    case "Free" :
    case "FreeV2" :
    case "FreeV3" :
        return "Free";
    case "Enterprise" :
    case "EnterpriseV2" :
        return "Enterprise";
    default:
      return "Team";
  }
}

function planToAnalyticsPlanSlug(planName) {
  switch (planName) {
    case "Empty" :
    case "Free" :
        return "Free";
    case "FreeV2" :
        return "Freev2";
    case "Scholarship" :
        return "Scholarship";
    case "Growth" :
        return "Growth";
    case "GrowthV2" :
        return "Growthv2";
    case "Enterprise" :
        return "Enterprise";
    case "Starter" :
        return "Starter";
    case "TeamV2" :
        return "Teamv2";
    case "TeamV2Annual" :
        return "Teamv2annual";
    case "FreeV3" :
        return "Freev3";
    case "TeamV3" :
        return "Teamv3";
    case "TeamV3Annual" :
        return "Teamv3annual";
    case "EnterpriseV2" :
        return "Enterprisev2";
    
  }
}

function analyticsLanguage(language) {
  if (language !== undefined) {
    if (language === "java") {
      return "Java";
    } else if (language === "json") {
      return "JsonSchema";
    } else if (language === "objc") {
      return "ObjectiveMinusC";
    } else if (language === "js" || language === "js_v2") {
      return "JavaScript";
    } else if (language === "re" || language === "re_v2") {
      return "Reason";
    } else if (language === "cs") {
      return "CSharp";
    } else if (language === "go") {
      return "Go";
    } else if (language === "kt") {
      return "Kotlin";
    } else if (language === "py") {
      return "Python";
    } else if (language === "rb") {
      return "Ruby";
    } else if (language === "ts") {
      return "TypeScript";
    } else if (language === "php") {
      return "Php";
    } else if (language === "py3") {
      return "Python3";
    } else if (language === "swift") {
      return "Swift";
    } else {
      return "Dart";
    }
  }
  
}

function getLegacySchemaBillingStatus(maybeTrial, planName) {
  if (maybeTrial !== undefined) {
    return "Trial";
  }
  switch (planName) {
    case "Empty" :
    case "Free" :
    case "FreeV2" :
    case "FreeV3" :
        return "Free";
    default:
      return "Team";
  }
}

function makeSchemaBundle(workspace, currentBranchId, currentBranchName) {
  var match = workspace.authMethod;
  return AnalyticsUtilsBundles.schema(workspace.id, workspace.name, getLegacySchemaBillingStatus(workspace.trial, workspace.plan.name), currentBranchId, currentBranchName, planToAnalyticsPlan(workspace.plan.name), Belt_Option.map(workspace.trial, (function (trial) {
                    return planToAnalyticsPlan(trial.plan.name);
                  })), match === "Google" ? "GoogleSSO" : (
                match === "Saml" ? "SamlSso" : "Default"
              ), planToAnalyticsPlanSlug(workspace.plan.name), Belt_Option.map(workspace.trial, (function (trial) {
                    return planToAnalyticsPlanSlug(trial.plan.name);
                  })), workspace.isSandbox ? "Sandbox" : "Regular");
}

function countSourceEventsImplementWithAvoAfterCurrentChange(model, sourceId, includeInCodegen) {
  var match = Belt_List.partitionU(Belt_List.keepMapU(model.events, (function ($$event) {
              return Belt_List.getByU($$event.includeSources, (function (includedSource) {
                            return includedSource.id === sourceId;
                          }));
            })), (function (includedEvent) {
          return includedEvent.includeInCodegen;
        }));
  return [
          Belt_List.length(match[0]) + (
            includeInCodegen ? 1 : -1
          ) | 0,
          Belt_List.length(match[1]) + (
            includeInCodegen ? -1 : 1
          ) | 0
        ];
}

function columnTypeToEventColumnsName(columnType) {
  if (columnType === "EventProperties") {
    return "EventProperties";
  } else if (columnType === "Destinations") {
    return "Destinations";
  } else if (columnType === "PropertyGroups") {
    return "PropertyGroups";
  } else if (columnType === "Metrics") {
    return "Metrics";
  } else if (columnType === "Sources") {
    return "Sources";
  } else if (columnType === "GroupProperties") {
    return "GroupProperties";
  } else if (columnType === "Category") {
    return "Category";
  } else if (columnType === "EventName") {
    return "EventName";
  } else if (columnType === "Actions") {
    return "Actions";
  } else if (columnType === "Tags") {
    return "Tags";
  } else {
    return ;
  }
}

function columnTypeToPropertyColumnsName(columnType) {
  if (columnType === "PropertyIsList") {
    return "ValueIsList";
  } else if (columnType === "EventPropertyGroup") {
    return "EventPropertyGroup";
  } else if (columnType === "PropertyConstraints") {
    return "PropertyConstraints";
  } else if (columnType === "PropertyNameMapping") {
    return "NameMapping";
  } else if (columnType === "PropertyName") {
    return "PropertyName";
  } else if (columnType === "PropertyType") {
    return "PropertyType";
  } else if (columnType === "PropertyEvents") {
    return "PropertyEvents";
  } else if (columnType === "PropertyAbsence") {
    return "PropertyPresence";
  } else {
    return ;
  }
}

function currentFilters(model, filters, sorting) {
  return AnalyticsUtilsBundles.currentFilters(StateFilterUtils.getTagFilters(filters), Belt_Array.map(StateFilterUtils.getSourceFilters(filters), (function (param) {
                    return AvoConfig.getSourceNameById(model, param);
                  })), Belt_Array.map(StateFilterUtils.getDestinationFilters(filters), (function (param) {
                    return ModelUtils.getDestinationNameById(model, param);
                  })), sorting, Belt_Array.keepMapU(StateFilterUtils.getPropertyGroupFilters(filters), (function (propertyGroupId) {
                    return Belt_Option.mapU(ModelUtils.getPropertyBundleById(propertyGroupId, model), (function (param) {
                                  return param.name;
                                }));
                  })), Belt_Array.keepMapU(StateFilterUtils.getPropertyFilters(filters), (function (propertyId) {
                    return ModelUtils.getPropertyNameById(propertyId, model);
                  })), StateFilterUtils.getActionFilters(filters), Belt_Array.keepMapU(StateFilterUtils.getCategoryFilters(filters), (function (goalId) {
                    return Belt_Option.mapU(ModelUtils.getGoalById(goalId, model), (function (param) {
                                  return param.name;
                                }));
                  })));
}

function destinationModeToAnalyticsDestinationMode(destinationMode) {
  switch (destinationMode) {
    case "DestinationInterface" :
        return "DestinationInterface";
    case "AvoManaged" :
    case "Legacy" :
        return "AvoManaged";
    
  }
}

function roleToAnalyticsRole(role) {
  if (role === "Editor") {
    return "Editor";
  } else if (role === "CodegenAccess") {
    return "CodegenAccess";
  } else if (role === "NoAccess" || role === "Viewer") {
    return "Viewer";
  } else if (role === "CommentOnly") {
    return "CommentOnly";
  } else if (role === "Admin") {
    return "Admin";
  } else {
    return "BillingOnly";
  }
}

function configToConfigsOnAnalytics(param) {
  var properties = param.properties;
  var events = param.events;
  return Belt_Array.keepMapU([
              [
                "AllEventsHaveDescriptions",
                Curry._1(ValidationConfigV2.Rule.isChecked, events.description)
              ],
              [
                "AllPropertiesHaveDescriptions",
                Curry._1(ValidationConfigV2.Rule.isChecked, properties.description)
              ],
              [
                "AllPropertiesHaveTypes",
                Curry._1(ValidationConfigV2.Rule.isChecked, properties.anyType)
              ],
              [
                "ConsistentEventNameCasing",
                ValidationConfigV2.EventsV2.hasConsistentNameCasing(events)
              ],
              [
                "ConsistentPropertyNameCasing",
                Curry._1(ValidationConfigV2.Rule.isChecked, properties.case)
              ],
              [
                "ForceEventCasing",
                ValidationConfigV2.EventsV2.hasEnforcedNameCasing(events)
              ],
              [
                "ForcePropertyCasing",
                Curry._1(ValidationConfigV2.Rule.isEnforced, properties.case)
              ],
              [
                "NoConflictingProperties",
                Curry._1(ValidationConfigV2.Rule.isChecked, properties.conflictsOnEvent)
              ],
              [
                "PreferredEventNameCasingOtherThanInherit",
                Caml_obj.notequal(ValidationConfigV2.EventsV2.getSimpleNameCasing(events), "None")
              ],
              [
                "PreferredPropertyNameCasingOtherThanInherit",
                Caml_obj.notequal(properties.case.value, {
                      NAME: "case",
                      VAL: "None"
                    })
              ],
              [
                "UniqueEventNames",
                Curry._1(ValidationConfigV2.Rule.isChecked, events.uniqueName)
              ],
              [
                "UniquePropertyNames",
                Curry._1(ValidationConfigV2.Rule.isChecked, properties.uniqueName)
              ]
            ], (function (param) {
                if (param[1]) {
                  return param[0];
                }
                
              }));
}

function enrichSchemaBundleWithNamedBranch(schemaBundle, branch) {
  return {
          schemaId: schemaBundle.schemaId,
          schemaName: schemaBundle.schemaName,
          schemaBillingStatus: schemaBundle.schemaBillingStatus,
          branchId: NamedBranch.getId(branch),
          branchName: NamedBranch.getName(branch),
          schemaSubscriptionPlan: schemaBundle.schemaSubscriptionPlan,
          schemaTrialPlan: schemaBundle.schemaTrialPlan,
          authenticationConfig: schemaBundle.authenticationConfig,
          schemaSubscriptionPlanSlug: schemaBundle.schemaSubscriptionPlanSlug,
          schemaTrialPlanSlug: schemaBundle.schemaTrialPlanSlug,
          schemaType: schemaBundle.schemaType
        };
}

function sendAsToPropertyType(sendAs) {
  if (typeof sendAs === "object") {
    return "GroupProperty";
  }
  switch (sendAs) {
    case "SystemProperty" :
        return "SystemProperty";
    case "EventProperty" :
        return "EventProperty";
    case "UserProperty" :
        return "UserProperty";
    
  }
}

function propertyLocationToOrigin($$location) {
  return $$location;
}

function getComponentCasingsFromValidationComponents(nameComponents) {
  return Belt_Array.map(nameComponents, (function (component) {
                if (component.NAME === "separator") {
                  return "Separator";
                }
                var match = Curry._1(ValidationConfigV2.Value.getCase, component.VAL.case.value);
                if (match === "Capital") {
                  return "CapitalCase";
                } else if (match === "Kebab") {
                  return "KebabCase";
                } else if (match === "Sentence") {
                  return "SentenceCase";
                } else if (match === "Constant") {
                  return "ConstantCase";
                } else if (match === "Lower") {
                  return "LowerCase";
                } else if (match === "Snake") {
                  return "SnakeCase";
                } else if (match === "Pascal") {
                  return "PascalCase";
                } else if (match === "Title") {
                  return "TitleCase";
                } else if (match === "Header") {
                  return "HeaderCase";
                } else if (match === "None") {
                  return "Inherit";
                } else if (match === "Upper") {
                  return "UpperCase";
                } else {
                  return "CamelCase";
                }
              }));
}

function getComponentTypesAsAnalytics(nameComponents) {
  return Belt_Array.map(nameComponents, (function (component) {
                if (component.NAME === "separator") {
                  return "Separator";
                }
                var match = Curry._1(ValidationConfigV2.Value.getAllowedValues, component.VAL.allowedValues.value);
                if (match.length !== 0) {
                  return "AllowedValues";
                } else {
                  return "FreeInput";
                }
              }));
}

function getAdvancedNameConfigProperties(nameComponents) {
  if (ValidationConfigV2.NameComponent.hasBasicNameCasing(nameComponents)) {
    return {
            getComponentNamesAsString: (function (param) {
                
              }),
            getComponentTypesAsString: (function (param) {
                
              }),
            getComponentCount: (function (param) {
                
              }),
            getComponentNamesIncludingSeparators: (function (param) {
                
              }),
            getComponentTypesAsArray: (function (param) {
                
              }),
            getComponentCasings: (function (param) {
                
              })
          };
  }
  var componentNamesAsString = ValidationConfigV2.NameComponent.getNamesAsStructuredString(nameComponents);
  var componentTypesAsString = ValidationConfigV2.NameComponent.getComponentCasesAsStructuredString(nameComponents);
  var componentCount = nameComponents.length;
  var componentNamesIncludingSeparators = ValidationConfigV2.NameComponent.getComponentNamesIncludingSeparators(nameComponents);
  var componentTypesAsArray = getComponentTypesAsAnalytics(nameComponents);
  var componentCasings = getComponentCasingsFromValidationComponents(nameComponents);
  return {
          getComponentNamesAsString: (function (param) {
              return componentNamesAsString;
            }),
          getComponentTypesAsString: (function (param) {
              return componentTypesAsString;
            }),
          getComponentCount: (function (param) {
              return componentCount;
            }),
          getComponentNamesIncludingSeparators: (function (param) {
              return componentNamesIncludingSeparators;
            }),
          getComponentTypesAsArray: (function (param) {
              return componentTypesAsArray;
            }),
          getComponentCasings: (function (param) {
              return componentCasings;
            })
        };
}

function getAnalyticsPropertyGroupFromCommonCase(commonCase, property) {
  return AnalyticsUtilsBundles.property(property.id, property.name, Belt_Option.map(commonCase, (function (commonCase) {
                    return Case.toAnalytics(Case.getCasingWithWorkspaceCase(property.name, commonCase));
                  })), sendAsToPropertyType(property.sendAs), Belt_Option.map(commonCase, (function (commonCase) {
                    return NameUtils.isCorrectCase(commonCase, property.name);
                  })));
}

function getAnalyticsPropertyGroup(model, property) {
  var commonCase = NameUtils.getCommonCase(Belt_List.keepMapU(model.properties, (function (property) {
              if (property.TAG === "PropertyRef") {
                return ;
              } else {
                return property._0.name;
              }
            })));
  return getAnalyticsPropertyGroupFromCommonCase(commonCase, property);
}

function getAnalyticsPropertyGroup_mapped(properties, property) {
  var commonCase = NameUtils.getCommonCase(Belt_List.fromArray(Curry._2(TrackingPlanMappedModel.Properties.mapToArray, properties, (function (param) {
                  return param.name;
                }))));
  return getAnalyticsPropertyGroupFromCommonCase(commonCase, property);
}

function withSchemaBundle(f, schemaBundle) {
  return Curry.app(f, [
              schemaBundle.schemaId,
              schemaBundle.schemaName,
              schemaBundle.schemaBillingStatus,
              schemaBundle.branchId,
              schemaBundle.branchName,
              schemaBundle.schemaSubscriptionPlan,
              schemaBundle.schemaTrialPlan,
              schemaBundle.authenticationConfig,
              schemaBundle.schemaSubscriptionPlanSlug,
              schemaBundle.schemaTrialPlanSlug,
              schemaBundle.schemaType
            ]);
}

function withCurrentFilters(f, currentFilters) {
  return Curry._8(f, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters);
}

function sectionStringToAnalyticsItemType(section) {
  if (section === undefined) {
    return ;
  }
  switch (section) {
    case "destination" :
        return "Destination";
    case "event" :
        return "Event";
    case "category" :
    case "goal" :
        return "Goal";
    case "metric" :
        return "Metric";
    case "property" :
        return "Property";
    case "property_bundle" :
        return "PropertyGroup";
    case "source" :
        return "Source";
    default:
      return ;
  }
}

export {
  planToAnalyticsPlan ,
  planToAnalyticsPlanSlug ,
  analyticsLanguage ,
  getLegacySchemaBillingStatus ,
  makeSchemaBundle ,
  countSourceEventsImplementWithAvoAfterCurrentChange ,
  columnTypeToEventColumnsName ,
  columnTypeToPropertyColumnsName ,
  currentFilters ,
  destinationModeToAnalyticsDestinationMode ,
  roleToAnalyticsRole ,
  configToConfigsOnAnalytics ,
  enrichSchemaBundleWithNamedBranch ,
  sendAsToPropertyType ,
  propertyLocationToOrigin ,
  getComponentCasingsFromValidationComponents ,
  getComponentTypesAsAnalytics ,
  getAdvancedNameConfigProperties ,
  getAnalyticsPropertyGroupFromCommonCase ,
  getAnalyticsPropertyGroup ,
  getAnalyticsPropertyGroup_mapped ,
  withSchemaBundle ,
  withCurrentFilters ,
  sectionStringToAnalyticsItemType ,
}
/* Case Not a pure module */
