// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FeatureFlag from "./FeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

var featureFlagValues = {
  contents: undefined
};

function emptyFeatureFlagsCliParameters(param) {
  return {
          warnings: [],
          successMessages: [],
          flagsToForce: []
        };
}

function codegenFeatureFlags(param) {
  return Belt_Array.keepU(FeatureFlag.featureFlags, (function (flag) {
                if (flag === "ServerTrackingWithDeviceId" || flag === "SwiftTrackInterface" || flag === "SplitSwiftFiles" || flag === "EventSpecificPropertyValuesInCode" || flag === "ImprovedPropertyGroupsUnfolding" || flag === "ReduceSizeIfNotDev" || flag === "KotlinCoroutines" || flag === "IntegerTypeInJsonSchema" || flag === "FixNestedListMappingInKt" || flag === "DotNet6" || flag === "KeepNumbersInEventPropertyPrefixesInTypescript" || flag === "KotlinTrackInterface" || flag === "SplitKtFiles" || flag === "UseFetchInNode" || flag === "SkipEmptySystemPropsInJavascript") {
                  return true;
                } else {
                  return flag === "AddUserPropertiesParameterInLogEvent";
                }
              }));
}

function printAvailableCodegenFeatureFlags(param) {
  return Belt_Array.mapU(codegenFeatureFlags(), FeatureFlag.featureFlagToJs).join(",");
}

function featureEnabledWithOverrides(schema, feature, flagsToForce) {
  if (Belt_Array.someU(flagsToForce, (function (forceFlag) {
            return forceFlag === feature;
          }))) {
    return true;
  } else {
    return FeatureFlag.featureEnabled(schema, feature);
  }
}

function featureEnabledWithoutOverrides(feature) {
  var flags = featureFlagValues.contents;
  if (flags !== undefined) {
    return Belt_Option.getWithDefault(Belt_MapString.get(Caml_option.valFromOption(flags), FeatureFlag.featureFlagToString(feature)), false);
  } else {
    return false;
  }
}

function initFeatureFlags(overwriteIfExistsOpt, schema, flagsToForce) {
  var overwriteIfExists = overwriteIfExistsOpt !== undefined ? overwriteIfExistsOpt : true;
  if (overwriteIfExists || Belt_Option.isNone(featureFlagValues.contents)) {
    featureFlagValues.contents = Caml_option.some(Belt_MapString.fromArray(Belt_Array.mapU(FeatureFlag.featureFlags, (function (featureFlag) {
                    return [
                            FeatureFlag.featureFlagToString(featureFlag),
                            featureEnabledWithOverrides(schema, featureFlag, flagsToForce)
                          ];
                  }))));
    return ;
  }
  
}

function parseForceFeaturesCliParameter(schema, forceFeatures) {
  var features = forceFeatures.trim() !== "" ? forceFeatures.split(",") : [];
  var parseResult = Belt_Array.reduceU(features, {
        warnings: [],
        successMessages: [],
        flagsToForce: []
      }, (function (featureFlagsResult, feature) {
          var featureFlag = FeatureFlag.featureFlagFromJs(feature);
          var parsedFeatureFlag = featureFlag !== undefined && Belt_Option.isSome(Belt_Array.getBy(codegenFeatureFlags(), (function (ff) {
                      return ff === featureFlag;
                    }))) ? featureFlag : undefined;
          if (parsedFeatureFlag !== undefined) {
            if (FeatureFlag.featureEnabled(schema, parsedFeatureFlag)) {
              return {
                      warnings: featureFlagsResult.warnings,
                      successMessages: Belt_Array.concat(featureFlagsResult.successMessages, ["Feature '" + feature + "' already enabled in your workspace"]),
                      flagsToForce: featureFlagsResult.flagsToForce
                    };
            } else {
              return {
                      warnings: featureFlagsResult.warnings,
                      successMessages: Belt_Array.concat(featureFlagsResult.successMessages, ["Feature '" + feature + "' force enabled successfully"]),
                      flagsToForce: Belt_Array.concat(featureFlagsResult.flagsToForce, [parsedFeatureFlag])
                    };
            }
          } else {
            return {
                    warnings: Belt_Array.concat(featureFlagsResult.warnings, ["Feature '" + feature + "' not found"]),
                    successMessages: featureFlagsResult.successMessages,
                    flagsToForce: featureFlagsResult.flagsToForce
                  };
          }
        }));
  if (Caml_obj.notequal(parseResult.warnings, [])) {
    return {
            warnings: Belt_Array.concat(parseResult.warnings, ["Available features: " + printAvailableCodegenFeatureFlags()]),
            successMessages: parseResult.successMessages,
            flagsToForce: parseResult.flagsToForce
          };
  } else {
    return parseResult;
  }
}

export {
  featureFlagValues ,
  emptyFeatureFlagsCliParameters ,
  codegenFeatureFlags ,
  printAvailableCodegenFeatureFlags ,
  featureEnabledWithOverrides ,
  featureEnabledWithoutOverrides ,
  initFeatureFlags ,
  parseForceFeaturesCliParameter ,
}
/* No side effect */
