// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(shareType) {
  return "BranchImplementation";
}

function decode(json) {
  var match = Json_decode.string(json);
  if (match === "BranchImplementation") {
    return "BranchImplementation";
  } else {
    return Pervasives.failwith("Unexepcted share type");
  }
}

var ShareType = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "shareType",
                "BranchImplementation"
              ],
              tl: {
                hd: [
                  "shareId",
                  t.shareId
                ],
                tl: {
                  hd: [
                    "schemaId",
                    t.schemaId
                  ],
                  tl: {
                    hd: [
                      "branchId",
                      t.branchId
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function decode$1(json) {
  var jsonDict = Js_json.classify(json);
  if (typeof jsonDict === "object" && jsonDict.TAG === "JSONObject") {
    var shareType = Belt_Option.map(Js_dict.get(jsonDict._0, "shareType"), decode);
    if (shareType !== undefined) {
      return {
              TAG: "BranchImplementation",
              shareId: Json_decode.field("shareId", Json_decode.string, json),
              schemaId: Json_decode.field("schemaId", Json_decode.string, json),
              branchId: Json_decode.field("branchId", Json_decode.string, json)
            };
    } else {
      return Pervasives.failwith("Sharing.decode got unexpected shareType " + JSON.stringify(json));
    }
  }
  return Pervasives.failwith("Sharing.decode expected object but got " + JSON.stringify(json));
}

export {
  ShareType ,
  encode$1 as encode,
  decode$1 as decode,
}
/* No side effect */
