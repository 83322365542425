// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";

var context = React.createContext(undefined);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Provider = {
  makeProps: makeProps,
  make: make
};

function use(param) {
  return Belt_Option.getExn(React.useContext(context));
}

function SchemaBundleContext(Props) {
  var currentBranchId = Props.currentBranchId;
  var currentBranchName = Props.currentBranchName;
  var children = Props.children;
  var workspace = WorkspaceContext.use();
  var schemaBundle = AnalyticsUtils.makeSchemaBundle(workspace, currentBranchId, currentBranchName);
  return React.createElement(make, makeProps(schemaBundle, children, undefined));
}

var make$1 = SchemaBundleContext;

export {
  context ,
  Provider ,
  use ,
  make$1 as make,
}
/* context Not a pure module */
