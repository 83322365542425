// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Domains from "./Domains.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

function keyByEventIds(domains) {
  return Curry._3(Domains.reduce, domains, undefined, (function (events, domain) {
                return Belt_Array.reduceU(domain.items.events, events, (function (events, eventId) {
                              return Belt_MapString.update(events, eventId, (function (currentDomains) {
                                            return Curry._2(Domains.append, Belt_Option.getWithDefault(currentDomains, Domains.empty), domain);
                                          }));
                            }));
              }));
}

function keyByEventVariantIds(domains) {
  return Curry._3(Domains.reduce, domains, undefined, (function (eventVariants, domain) {
                return Belt_Array.reduceU(domain.items.eventVariants, eventVariants, (function (eventVariants, eventVariantId) {
                              return Belt_MapString.update(eventVariants, eventVariantId, (function (currentDomains) {
                                            return Curry._2(Domains.append, Belt_Option.getWithDefault(currentDomains, Domains.empty), domain);
                                          }));
                            }));
              }));
}

function keyByEventAndVariantIds(domains) {
  var eventIds = keyByEventIds(domains);
  var eventVariantIds = keyByEventVariantIds(domains);
  return Belt_MapString.merge(eventIds, eventVariantIds, (function (_key, eventIds, eventVariantIds) {
                if (eventIds !== undefined) {
                  if (eventVariantIds !== undefined) {
                    return Curry._2(Domains.mergeMany, eventIds, eventVariantIds);
                  } else {
                    return eventIds;
                  }
                } else if (eventVariantIds !== undefined) {
                  return eventVariantIds;
                } else {
                  return ;
                }
              }));
}

export {
  keyByEventIds ,
  keyByEventVariantIds ,
  keyByEventAndVariantIds ,
}
/* Domains Not a pure module */
