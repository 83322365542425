// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Domain__Json from "./domains/Domain__Json.mjs";
import * as Domain__Types from "./domains/Domain__Types.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "./TrackingPlanModel.mjs";
import * as InspectorIssueStatus from "../../shared/models/InspectorIssueStatus.mjs";
import * as WebhookPayloadFormat from "../../shared/models/WebhookPayloadFormat.mjs";

function printLiteral(lit) {
  var variant = lit.NAME;
  if (variant === "BooleanLit") {
    return Json_encode.object_({
                hd: [
                  "bool",
                  lit.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "StringLit") {
    return Json_encode.object_({
                hd: [
                  "string",
                  lit.VAL
                ],
                tl: /* [] */0
              });
  } else if (variant === "FloatLit") {
    return Json_encode.object_({
                hd: [
                  "float",
                  lit.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "int",
                  lit.VAL
                ],
                tl: /* [] */0
              });
  }
}

function printBinOp(binOp) {
  if (binOp === "GT") {
    return "gt";
  } else if (binOp === "LE") {
    return "le";
  } else if (binOp === "GTE") {
    return "gte";
  } else if (binOp === "LEQ") {
    return "leq";
  } else if (binOp === "NEQ") {
    return "neq";
  } else {
    return "eq";
  }
}

function printExp(match_) {
  var variant = match_.NAME;
  if (variant === "BinOp") {
    return Pervasives.failwith("binop not implemented");
  } else if (variant === "Literal") {
    return Json_encode.object_({
                hd: [
                  "literal",
                  printLiteral(match_.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "PrefOp") {
    return Pervasives.failwith("prefop not implemented");
  } else if (variant === "Predicate") {
    return Pervasives.failwith("predicate not implemented");
  } else {
    return Json_encode.object_({
                hd: [
                  "identifier",
                  match_.VAL
                ],
                tl: /* [] */0
              });
  }
}

function printPropertyRef(param) {
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "id",
                    param.id
                  ],
                  tl: {
                    hd: [
                      "description",
                      param.description
                    ],
                    tl: /* [] */0
                  }
                }, Belt_Option.mapWithDefault(param.pinnedValue, /* [] */0, (function (pinnedValue) {
                        return {
                                hd: [
                                  "pinnedValue",
                                  printLiteral(pinnedValue)
                                ],
                                tl: /* [] */0
                              };
                      }))));
}

function encodeVariantAbsenceOverride(absence) {
  if (typeof absence !== "object") {
    return /* [] */0;
  }
  var tmp;
  switch (absence._0) {
    case "AlwaysSent" :
        tmp = "AlwaysSent";
        break;
    case "SometimesSent" :
        tmp = "SometimesSent";
        break;
    case "NeverSent" :
        tmp = "NeverSent";
        break;
    
  }
  return {
          hd: [
            "absence",
            tmp
          ],
          tl: /* [] */0
        };
}

function encodeVariantSourceOverride(sourceOverride) {
  var includeInCodegen = sourceOverride.includeInCodegen;
  var tmp;
  tmp = typeof includeInCodegen !== "object" ? /* [] */0 : ({
        hd: [
          "includeInCodegen",
          includeInCodegen._0
        ],
        tl: /* [] */0
      });
  return Json_encode.object_(tmp);
}

function printInspectorIssueMetadata(param) {
  return Json_encode.object_({
              hd: [
                "issueId",
                param.issueId
              ],
              tl: {
                hd: [
                  "eventName",
                  param.eventName
                ],
                tl: {
                  hd: [
                    "eventId",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), param.eventId)
                  ],
                  tl: {
                    hd: [
                      "propertyName",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), param.propertyName)
                    ],
                    tl: {
                      hd: [
                        "propertyId",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), param.propertyId)
                      ],
                      tl: {
                        hd: [
                          "sourceId",
                          param.sourceId
                        ],
                        tl: {
                          hd: [
                            "issueStatus",
                            InspectorIssueStatus.encode(param.issueStatus)
                          ],
                          tl: {
                            hd: [
                              "regression",
                              param.regression
                            ],
                            tl: {
                              hd: [
                                "trigger",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), Belt_Option.map(param.trigger, TrackingPlanModel.issueTriggerToJs))
                              ],
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function printPropertyRegexValidationRule(param) {
  return Json_encode.object_({
              hd: [
                "regex",
                param.regex
              ],
              tl: {
                hd: [
                  "testValue",
                  param.testValue
                ],
                tl: /* [] */0
              }
            });
}

function printPropertyRegexValidation(param) {
  var eventOverrides = param.eventOverrides;
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "propertyRule",
                      Json_encode.nullable(printPropertyRegexValidationRule, param.propertyRule)
                    ],
                    tl: /* [] */0
                  },
                  Belt_MapString.size(eventOverrides) > 0 ? ({
                        hd: [
                          "eventOverrides",
                          Json_encode.object_(Belt_List.mapU(Belt_MapString.toList(eventOverrides), (function (param) {
                                      return [
                                              param[0],
                                              printPropertyRegexValidationRule(param[1])
                                            ];
                                    })))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0
                ]));
}

function printCoreType(type_) {
  if (type_ === "Object") {
    return "object";
  } else if (type_ === "Any") {
    return "any";
  } else if (type_ === "Int") {
    return "int";
  } else if (type_ === "Float") {
    return "float";
  } else if (type_ === "Bool") {
    return "bool";
  } else if (type_ === "Long") {
    return "long";
  } else {
    return "string";
  }
}

function printValueType(valueType) {
  return Json_encode.object_({
              hd: [
                "type_",
                printCoreType(valueType.type_)
              ],
              tl: {
                hd: [
                  "validations",
                  Json_encode.list(printValidation, valueType.validations)
                ],
                tl: /* [] */0
              }
            });
}

function printValue(value) {
  return Json_encode.object_({
              hd: [
                "value",
                printValueType(value.VAL)
              ],
              tl: /* [] */0
            });
}

function printObjectItem(item) {
  return Json_encode.object_({
              hd: [
                "key",
                item.key
              ],
              tl: {
                hd: [
                  "list",
                  item.list
                ],
                tl: {
                  hd: [
                    "optional",
                    item.optional
                  ],
                  tl: {
                    hd: [
                      "value",
                      printValue(item.value)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printValidation(validation) {
  var variant = validation.NAME;
  if (variant === "StartsWith") {
    return Json_encode.object_({
                hd: [
                  "startsWith",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Regex") {
    return Json_encode.object_({
                hd: [
                  "regex",
                  printPropertyRegexValidation(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Max") {
    return Json_encode.object_({
                hd: [
                  "max",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Min") {
    return Json_encode.object_({
                hd: [
                  "min",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Contains") {
    return Json_encode.object_({
                hd: [
                  "contains",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "NestedProperty") {
    return Json_encode.object_({
                hd: [
                  "nestedProperty",
                  Json_encode.list(printPropertyRef, validation.VAL)
                ],
                tl: /* [] */0
              });
  } else if (variant === "Shape") {
    return Json_encode.object_({
                hd: [
                  "shape",
                  Json.stringify(Json_encode.list(printObjectItem, validation.VAL))
                ],
                tl: /* [] */0
              });
  } else if (variant === "EndsWith") {
    return Json_encode.object_({
                hd: [
                  "endsWith",
                  printLiteral(validation.VAL)
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "matches",
                  Belt_List.toArray(Belt_List.map(validation.VAL, (function (param) {
                              return Json_encode.object_({
                                          hd: [
                                            "exp",
                                            printExp(param[0])
                                          ],
                                          tl: {
                                            hd: [
                                              "excludedSources",
                                              Json_encode.list((function (prim) {
                                                      return prim;
                                                    }), param[1])
                                            ],
                                            tl: /* [] */0
                                          }
                                        });
                            })))
                ],
                tl: /* [] */0
              });
  }
}

function printSendAs(sendAs) {
  if (typeof sendAs === "object") {
    return Json_encode.object_({
                hd: [
                  "group",
                  sendAs._0
                ],
                tl: /* [] */0
              });
  }
  switch (sendAs) {
    case "SystemProperty" :
        return "system";
    case "EventProperty" :
        return "event";
    case "UserProperty" :
        return "user";
    
  }
}

function printItem(item) {
  var variant = item.NAME;
  if (variant === "Destination") {
    return Json_encode.object_({
                hd: [
                  "destination",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  if (variant === "Integration") {
    return Pervasives.failwith("unexpected item type");
  }
  if (variant === "GroupType") {
    return Json_encode.object_({
                hd: [
                  "groupType",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  if (variant === "Property") {
    return Json_encode.object_({
                hd: [
                  "property",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  if (variant === "Event") {
    return Json_encode.object_({
                hd: [
                  "event",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  if (variant === "PropertyGroup") {
    return Json_encode.object_({
                hd: [
                  "propertyGroup",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  if (variant === "Goal") {
    return Json_encode.object_({
                hd: [
                  "goal",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  if (variant !== "EventVariant") {
    if (variant === "Metric") {
      return Json_encode.object_({
                  hd: [
                    "metric",
                    item.VAL
                  ],
                  tl: /* [] */0
                });
    } else {
      return Json_encode.object_({
                  hd: [
                    "source",
                    item.VAL
                  ],
                  tl: /* [] */0
                });
    }
  }
  var variantIdentifier = item.VAL;
  return Json_encode.object_({
              hd: [
                "eventVariant",
                Json_encode.object_({
                      hd: [
                        "eventId",
                        variantIdentifier.baseEventId
                      ],
                      tl: {
                        hd: [
                          "variantId",
                          variantIdentifier.variantId
                        ],
                        tl: /* [] */0
                      }
                    })
              ],
              tl: /* [] */0
            });
}

function printCollaborationItem(collaborationItem) {
  if (collaborationItem.NAME === "CollaborationItemEvent") {
    return Json_encode.object_({
                hd: [
                  "event",
                  collaborationItem.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "branch",
                  collaborationItem.VAL
                ],
                tl: /* [] */0
              });
  }
}

function printRuleItem(item) {
  var variant = item.NAME;
  if (variant === "PropertyRef") {
    var match = item.VAL;
    return Json_encode.object_({
                hd: [
                  "propertyRef",
                  Json_encode.object_({
                        hd: [
                          "eventId",
                          match[0]
                        ],
                        tl: {
                          hd: [
                            "propertyId",
                            match[1]
                          ],
                          tl: /* [] */0
                        }
                      })
                ],
                tl: /* [] */0
              });
  }
  if (variant === "Property") {
    return Json_encode.object_({
                hd: [
                  "property",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  if (variant === "Event") {
    return Json_encode.object_({
                hd: [
                  "event",
                  item.VAL
                ],
                tl: /* [] */0
              });
  }
  var match$1 = item.VAL;
  return Json_encode.object_({
              hd: [
                "eventObjectField",
                Json_encode.object_({
                      hd: [
                        "eventId",
                        match$1[0]
                      ],
                      tl: {
                        hd: [
                          "propertyId",
                          match$1[1]
                        ],
                        tl: {
                          hd: [
                            "nestedPropertyId",
                            match$1[2]
                          ],
                          tl: /* [] */0
                        }
                      }
                    })
              ],
              tl: /* [] */0
            });
}

function printMappingDestination(mappingDestination) {
  if (typeof mappingDestination === "object") {
    return Json_encode.object_({
                hd: [
                  "destinationId",
                  mappingDestination.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "allDestinations",
                  true
                ],
                tl: /* [] */0
              });
  }
}

function printNameMapping(param) {
  return Json_encode.object_({
              hd: [
                "nameMapping",
                Json_encode.object_({
                      hd: [
                        "destination",
                        Json_encode.nullable(printMappingDestination, param.destination)
                      ],
                      tl: {
                        hd: [
                          "name",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), param.name)
                        ],
                        tl: /* [] */0
                      }
                    })
              ],
              tl: /* [] */0
            });
}

function printMappingLocalWorkspace(mappingWorkspace) {
  if (typeof mappingWorkspace === "object") {
    return Json_encode.object_({
                hd: [
                  "workspaceId",
                  mappingWorkspace.VAL
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "allWorkspaces",
                  true
                ],
                tl: /* [] */0
              });
  }
}

function printWorkspaceNameMapping(param) {
  return Json_encode.object_({
              hd: [
                "nameInLocalWorkspace",
                Json_encode.object_({
                      hd: [
                        "workspace",
                        Json_encode.nullable(printMappingLocalWorkspace, param.workspace)
                      ],
                      tl: {
                        hd: [
                          "name",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), param.name)
                        ],
                        tl: /* [] */0
                      }
                    })
              ],
              tl: /* [] */0
            });
}

function printSegmentIntegrationOption(param) {
  return Json_encode.object_({
              hd: [
                "key",
                param[0]
              ],
              tl: {
                hd: [
                  "enabled",
                  param[1]
                ],
                tl: /* [] */0
              }
            });
}

function printSegmentIntegrationOptionRule(param) {
  return Json_encode.object_({
              hd: [
                "segmentIntegrationOption",
                printSegmentIntegrationOption([
                      param[0],
                      param[1]
                    ])
              ],
              tl: /* [] */0
            });
}

function printRuleDefinition(definition) {
  var variant = definition.NAME;
  if (variant === "SegmentIntegrationOption") {
    return printSegmentIntegrationOptionRule(definition.VAL);
  } else if (variant === "NameMapping") {
    return printNameMapping(definition.VAL);
  } else if (variant === "NameInLocalWorkspace") {
    return printWorkspaceNameMapping(definition.VAL);
  } else {
    return Json_encode.object_({
                hd: [
                  "pinnedValue",
                  printLiteral(definition.VAL)
                ],
                tl: /* [] */0
              });
  }
}

function printRule(param) {
  return Json_encode.object_({
              hd: [
                "id",
                param.id
              ],
              tl: {
                hd: [
                  "item",
                  printRuleItem(param.item)
                ],
                tl: {
                  hd: [
                    "definition",
                    printRuleDefinition(param.definition)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printRules(rules) {
  return Json_encode.list(printRule, rules);
}

function printRuleIdTuple(ruleIdTuple) {
  return Json_encode.object_({
              hd: [
                "fromRuleId",
                ruleIdTuple[0]
              ],
              tl: {
                hd: [
                  "toRuleId",
                  ruleIdTuple[1]
                ],
                tl: /* [] */0
              }
            });
}

function printPropertyAbsenceUpdateType(propertyAbsenceUpdateType) {
  if (typeof propertyAbsenceUpdateType !== "object") {
    return Json_encode.object_({
                hd: [
                  "all",
                  true
                ],
                tl: /* [] */0
              });
  }
  switch (propertyAbsenceUpdateType.TAG) {
    case "Event" :
        return Json_encode.object_({
                    hd: [
                      "eventId",
                      propertyAbsenceUpdateType._0
                    ],
                    tl: /* [] */0
                  });
    case "Source" :
        return Json_encode.object_({
                    hd: [
                      "sourceId",
                      propertyAbsenceUpdateType._0
                    ],
                    tl: /* [] */0
                  });
    case "EventSource" :
        return Json_encode.object_({
                    hd: [
                      "eventId",
                      propertyAbsenceUpdateType._0
                    ],
                    tl: {
                      hd: [
                        "sourceId",
                        propertyAbsenceUpdateType._1
                      ],
                      tl: /* [] */0
                    }
                  });
    
  }
}

function printEventSourceAbsence(eventSourceAbsence) {
  if (typeof eventSourceAbsence !== "object") {
    return Json_encode.object_({
                hd: [
                  "neverSent",
                  true
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "sometimesSent",
                  eventSourceAbsence._0
                ],
                tl: /* [] */0
              });
  }
}

function printPropertyAbsenceEventSourceMap(sourceMap) {
  if (sourceMap.TAG === "AllSources") {
    return Json_encode.object_({
                hd: [
                  "allSources",
                  printEventSourceAbsence(sourceMap._0)
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "mixedSources",
                  Json_encode.object_(Belt_List.mapU(Belt_MapString.toList(sourceMap._0), (function (param) {
                              return [
                                      param[0],
                                      printEventSourceAbsence(param[1])
                                    ];
                            })))
                ],
                tl: /* [] */0
              });
  }
}

function printPropertyAbsenceEventMap(eventMap) {
  return Json_encode.object_(Belt_List.mapU(Belt_MapString.toList(eventMap), (function (param) {
                    return [
                            param[0],
                            printPropertyAbsenceEventSourceMap(param[1])
                          ];
                  })));
}

function printPropertyAbsence(absence) {
  var tmp;
  tmp = typeof absence !== "object" ? [
      "alwaysSent",
      true
    ] : (
      absence.TAG === "SometimesSent" ? [
          "sometimesSent",
          absence._0
        ] : [
          "mixed",
          printPropertyAbsenceEventMap(absence._0)
        ]
    );
  return Json_encode.object_({
              hd: tmp,
              tl: /* [] */0
            });
}

function printGlobalRequirementsMetadataForItem(globalRequirementsMetadata) {
  return Json_encode.object_({
              hd: [
                "fromLocalItemId",
                globalRequirementsMetadata.fromLocalItemId
              ],
              tl: {
                hd: [
                  "globalWorkspaceId",
                  globalRequirementsMetadata.globalWorkspaceId
                ],
                tl: /* [] */0
              }
            });
}

function printDisallowedState(disallowedState) {
  var tmp;
  tmp = typeof disallowedState !== "object" ? ({
        hd: [
          "disallowed",
          true
        ],
        tl: /* [] */0
      }) : ({
        hd: [
          "disallowedSources",
          Json_encode.list((function (prim) {
                  return prim;
                }), Belt_SetString.toList(disallowedState._0))
        ],
        tl: /* [] */0
      });
  return Json_encode.object_(tmp);
}

function printDisallowedEvents(disallowedEvents) {
  return Json_encode.object_(Belt_List.fromArray(Belt_Array.mapU(Belt_MapString.toArray(disallowedEvents), (function (param) {
                        var disallowedStateJson = printDisallowedState(param[1]);
                        return [
                                param[0],
                                disallowedStateJson
                              ];
                      }))));
}

function encodeVariantAllowedPropertyValues(allowedValues) {
  if (typeof allowedValues !== "object") {
    return /* [] */0;
  } else {
    return Belt_List.concatMany([{
                  hd: [
                    "allowedValues",
                    Json_encode.object_(Belt_List.mapU(Belt_MapString.toList(allowedValues._0), (function (param) {
                                return [
                                        param[0],
                                        printDisallowedState(param[1])
                                      ];
                              })))
                  ],
                  tl: /* [] */0
                }]);
  }
}

function encodeVariantPropertyOverride(propertyOverride) {
  var pinnedValue = propertyOverride.pinnedValue;
  var tmp;
  if (typeof pinnedValue !== "object") {
    tmp = /* [] */0;
  } else {
    var pinnedValue$1 = pinnedValue._0;
    tmp = {
      hd: [
        "pinnedValue",
        pinnedValue$1 !== undefined ? printLiteral(pinnedValue$1) : null
      ],
      tl: /* [] */0
    };
  }
  var regexValidation = propertyOverride.regexValidation;
  var tmp$1;
  if (typeof regexValidation !== "object") {
    tmp$1 = /* [] */0;
  } else {
    var regexValidation$1 = regexValidation._0;
    tmp$1 = {
      hd: [
        "regexValidation",
        regexValidation$1 !== undefined ? printPropertyRegexValidationRule(regexValidation$1) : null
      ],
      tl: /* [] */0
    };
  }
  return Json_encode.object_(Belt_List.concatMany([
                  tmp,
                  encodeVariantAbsenceOverride(propertyOverride.absence),
                  encodeVariantAllowedPropertyValues(propertyOverride.allowedValues),
                  tmp$1
                ]));
}

function printEventSpecificPropertyValues(eventSpecificAllowedPropertyValues) {
  return Json_encode.array(Json_encode.object_, Belt_Array.map(eventSpecificAllowedPropertyValues, (function (param) {
                    var matchLiteral = printLiteral(param[0]);
                    var disallowedEventsJson = printDisallowedEvents(param[1]);
                    return {
                            hd: [
                              "matchLiteral",
                              matchLiteral
                            ],
                            tl: {
                              hd: [
                                "disallowedEvents",
                                disallowedEventsJson
                              ],
                              tl: /* [] */0
                            }
                          };
                  })));
}

function printProperty(prop) {
  if (prop.TAG === "PropertyRef") {
    return printPropertyRef(prop._0);
  }
  var match = prop._0;
  var globalRequirementsMetadata = match.globalRequirementsMetadata;
  var absence = match.absence;
  var uniqueName = match.uniqueName;
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "id",
                      match.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        match.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          match.description
                        ],
                        tl: {
                          hd: [
                            "type",
                            match.type_
                          ],
                          tl: {
                            hd: [
                              "sendAs",
                              printSendAs(match.sendAs)
                            ],
                            tl: {
                              hd: [
                                "validations",
                                Belt_List.toArray(Belt_List.map(match.validations, printValidation))
                              ],
                              tl: {
                                hd: [
                                  "analyticsTools",
                                  Belt_List.toArray(Belt_List.map(match.analyticsTools, AvoConfigTypes.analyticsToolToJs))
                                ],
                                tl: {
                                  hd: [
                                    "devPlatforms",
                                    Belt_List.toArray(Belt_List.map(match.devPlatforms, AvoConfigTypes.devPlatformToJs))
                                  ],
                                  tl: {
                                    hd: [
                                      "optional",
                                      match.optionalDeprecated
                                    ],
                                    tl: {
                                      hd: [
                                        "optionalWhenInObject",
                                        match.optionalWhenInObject
                                      ],
                                      tl: {
                                        hd: [
                                          "auto",
                                          match.auto
                                        ],
                                        tl: {
                                          hd: [
                                            "builtIn",
                                            TrackingPlanModel.builtInTypesToJs(match.builtIn)
                                          ],
                                          tl: {
                                            hd: [
                                              "list",
                                              match.list
                                            ],
                                            tl: {
                                              hd: [
                                                "operation",
                                                TrackingPlanModel.operationToJs(match.operation)
                                              ],
                                              tl: {
                                                hd: [
                                                  "excludedSources",
                                                  Belt_List.toArray(Belt_List.map(match.excludedSourcesDeprecated, (function (prim) {
                                                              return prim;
                                                            })))
                                                ],
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  },
                  absence !== undefined ? ({
                        hd: [
                          "absence",
                          printPropertyAbsence(absence)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  uniqueName !== undefined ? ({
                        hd: [
                          "uniqueName",
                          uniqueName
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  globalRequirementsMetadata !== undefined ? ({
                        hd: [
                          "globalRequirementsMetadata",
                          printGlobalRequirementsMetadataForItem(globalRequirementsMetadata)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  {
                    hd: [
                      "eventSpecificAllowedPropertyValues",
                      printEventSpecificPropertyValues(match.eventSpecificAllowedPropertyValues)
                    ],
                    tl: /* [] */0
                  }
                ]));
}

function printProperties(properties) {
  return Json_encode.object_(Belt_List.map(properties, (function (x) {
                    return [
                            x._0.id,
                            printProperty(x)
                          ];
                  })));
}

function printPropertyBundle(propertyBundle) {
  var globalRequirementsMetadata = propertyBundle.globalRequirementsMetadata;
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "id",
                      propertyBundle.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        propertyBundle.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          propertyBundle.description
                        ],
                        tl: {
                          hd: [
                            "properties",
                            Json_encode.list((function (prim) {
                                    return prim;
                                  }), propertyBundle.properties)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  },
                  globalRequirementsMetadata !== undefined ? ({
                        hd: [
                          "globalRequirementsMetadata",
                          printGlobalRequirementsMetadataForItem(globalRequirementsMetadata)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0
                ]));
}

function printPropertyBundles(propertyBundles) {
  return Json_encode.object_(Belt_List.map(propertyBundles, (function (propertyBundle) {
                    return [
                            propertyBundle.id,
                            printPropertyBundle(propertyBundle)
                          ];
                  })));
}

function printPropertyBundleRef(propertyBundleRef) {
  return Json_encode.object_({
              hd: [
                "id",
                propertyBundleRef.id
              ],
              tl: {
                hd: [
                  "description",
                  propertyBundleRef.description
                ],
                tl: /* [] */0
              }
            });
}

function printPropertyBundleRefs(propertyBundleRefs) {
  return Json_encode.object_(Belt_List.map(propertyBundleRefs, (function (propertyBundleRef) {
                    return [
                            propertyBundleRef.id,
                            printPropertyBundleRef(propertyBundleRef)
                          ];
                  })));
}

function printSourceDestinationMap(sourceDestinationMap) {
  var match = sourceDestinationMap.VAL;
  return Json_encode.object_({
              hd: [
                "sourceId",
                match[0]
              ],
              tl: {
                hd: [
                  "destinationIds",
                  Json_encode.list((function (prim) {
                          return prim;
                        }), match[1])
                ],
                tl: /* [] */0
              }
            });
}

function printPropertyWhitelist(param) {
  return Json_encode.object_({
              hd: [
                "analyticsPlatform",
                AvoConfigTypes.analyticsToolToJs(param[0])
              ],
              tl: {
                hd: [
                  "propertyId",
                  param[1]
                ],
                tl: /* [] */0
              }
            });
}

function printSourceValidation(validation) {
  var variant = validation.NAME;
  if (variant === "oneHourAfterMerge") {
    return "oneHourAfterMerge-" + validation.VAL.toString();
  } else if (variant === "currentVersion") {
    return "currentVersion-" + validation.VAL;
  } else if (variant === "twentyFourHoursAfterMerge") {
    return "twentyFourHoursAfterMerge-" + validation.VAL.toString();
  } else if (variant === "customVersion") {
    return "customVersion-" + validation.VAL;
  } else if (variant === "customTime") {
    return "customTime-" + validation.VAL.toString();
  } else {
    return "nextVersion-" + validation.VAL;
  }
}

function printSourceValidationMap(sourceValidationMap) {
  return Json_encode.array(Json_encode.object_, Belt_Array.map(Belt_MapString.toArray(sourceValidationMap), (function (param) {
                    return {
                            hd: [
                              "sourceId",
                              param[0]
                            ],
                            tl: {
                              hd: [
                                "validation",
                                Json_encode.nullable(printSourceValidation, param[1])
                              ],
                              tl: /* [] */0
                            }
                          };
                  })));
}

function printIncludedSource(param) {
  return Json_encode.object_({
              hd: [
                "sourceId",
                param.id
              ],
              tl: {
                hd: [
                  "includeInCodegen",
                  param.includeInCodegen
                ],
                tl: {
                  hd: [
                    "inspectorValidation",
                    Json_encode.nullable(printSourceValidation, param.inspectorValidation)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printImageMetadata(imageMetadata) {
  return Json_encode.object_({
              hd: [
                "url",
                imageMetadata.url
              ],
              tl: {
                hd: [
                  "storagePath",
                  imageMetadata.storagePath
                ],
                tl: {
                  hd: [
                    "width",
                    imageMetadata.width
                  ],
                  tl: {
                    hd: [
                      "height",
                      imageMetadata.height
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printFigmaMetadata(figmaMetadata) {
  return Json_encode.object_({
              hd: [
                "url",
                figmaMetadata.url
              ],
              tl: /* [] */0
            });
}

function printTriggerContent(triggerContent) {
  if (triggerContent.TAG === "Image") {
    return Json_encode.object_({
                hd: [
                  "Image",
                  printImageMetadata(triggerContent._0)
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "Figma",
                  printFigmaMetadata(triggerContent._0)
                ],
                tl: /* [] */0
              });
  }
}

function printTriggerSources(triggerSources) {
  if (typeof triggerSources !== "object") {
    return "SourceIndependent";
  } else {
    return Json_encode.object_({
                hd: [
                  "SourceIds",
                  Json_encode.array((function (prim) {
                          return prim;
                        }), triggerSources._0)
                ],
                tl: /* [] */0
              });
  }
}

function printEventTrigger(eventTrigger) {
  return Json_encode.object_({
              hd: [
                "id",
                eventTrigger.id
              ],
              tl: {
                hd: [
                  "sources",
                  printTriggerSources(eventTrigger.sources)
                ],
                tl: {
                  hd: [
                    "description",
                    eventTrigger.description
                  ],
                  tl: {
                    hd: [
                      "content",
                      Json_encode.nullable(printTriggerContent, eventTrigger.content)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printEventTriggers(eventTriggers) {
  return Json_encode.array(printEventTrigger, eventTriggers);
}

function printEventSourceAbsenceOverrideType(sourceOverrideType) {
  if (typeof sourceOverrideType !== "object") {
    return "Removed";
  } else {
    return "Overridden";
  }
}

function printEventVariant(variant) {
  return Json_encode.object_({
              hd: [
                "id",
                variant.id
              ],
              tl: {
                hd: [
                  "baseEventId",
                  variant.baseEventId
                ],
                tl: {
                  hd: [
                    "nameSuffix",
                    variant.nameSuffix
                  ],
                  tl: {
                    hd: [
                      "description",
                      variant.description
                    ],
                    tl: {
                      hd: [
                        "propertyOverrides",
                        Json_encode.object_(Belt_List.mapU(Belt_MapString.toList(variant.propertyOverrides), (function (param) {
                                    var propertyOverride = param[1];
                                    var tmp;
                                    tmp = typeof propertyOverride !== "object" ? null : encodeVariantPropertyOverride(propertyOverride._0);
                                    return [
                                            param[0],
                                            tmp
                                          ];
                                  })))
                      ],
                      tl: {
                        hd: [
                          "sourceOverrides",
                          Json_encode.object_(Belt_List.mapU(Belt_MapString.toList(variant.sourceOverrides), (function (param) {
                                      var sourceOverride = param[1];
                                      var tmp;
                                      tmp = typeof sourceOverride !== "object" ? null : encodeVariantSourceOverride(sourceOverride._0);
                                      return [
                                              param[0],
                                              tmp
                                            ];
                                    })))
                        ],
                        tl: {
                          hd: [
                            "triggers",
                            Json_encode.array(printEventTrigger, variant.triggers)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function printEventVariants(variants) {
  return Json_encode.array(printEventVariant, variants);
}

function printTriggerIdTuple(triggerIdTuple) {
  return Json_encode.object_({
              hd: [
                "fromTriggerId",
                triggerIdTuple[0]
              ],
              tl: {
                hd: [
                  "toTriggerId",
                  triggerIdTuple[1]
                ],
                tl: /* [] */0
              }
            });
}

function printEvent($$event) {
  var globalRequirementsMetadata = $$event.globalRequirementsMetadata;
  var triggers = $$event.triggers;
  var userGroupTypeIdsWithArchive = $$event.userGroupTypeIdsWithArchive;
  var eventGroupTypeIdsWithArchive = $$event.eventGroupTypeIdsWithArchive;
  var propertyWhitelist = $$event.propertyWhitelist;
  var includeDestinations = $$event.includeDestinations;
  var includeSources = $$event.includeSources;
  var excludeSourcesDeprecated = $$event.excludeSourcesDeprecated;
  var tags = $$event.tags;
  var types = $$event.types;
  var variants = $$event.variants;
  var propertyBundles = $$event.propertyBundles;
  var uniqueName = $$event.uniqueName;
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "id",
                      $$event.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        $$event.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          $$event.description
                        ],
                        tl: {
                          hd: [
                            "properties",
                            printProperties($$event.directPropertyRefs)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  },
                  variants.length !== 0 ? ({
                        hd: [
                          "variants",
                          Json_encode.array(printEventVariant, variants)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(types) > 0 ? ({
                        hd: [
                          "types",
                          Belt_List.toArray(Belt_List.map(Belt_List.map(types, TrackingPlanModel.eventTypeToJs), (function (prim) {
                                      return prim;
                                    })))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(excludeSourcesDeprecated) > 0 ? ({
                        hd: [
                          "excludeSources",
                          Belt_List.toArray(Belt_List.map(excludeSourcesDeprecated, (function (prim) {
                                      return prim;
                                    })))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(includeSources) > 0 ? ({
                        hd: [
                          "includeSources",
                          Belt_List.toArray(Belt_List.map(includeSources, printIncludedSource))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(includeDestinations) > 0 ? ({
                        hd: [
                          "includeDestinations",
                          Belt_List.toArray(Belt_List.map(includeDestinations, printSourceDestinationMap))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(tags) > 0 ? ({
                        hd: [
                          "tags",
                          Belt_List.toArray(Belt_List.map(tags, (function (prim) {
                                      return prim;
                                    })))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(propertyBundles) > 0 ? ({
                        hd: [
                          "propertyGroups",
                          printPropertyBundleRefs(propertyBundles)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  Belt_List.length(propertyWhitelist) > 0 ? ({
                        hd: [
                          "propertyWhitelist",
                          Belt_List.toArray(Belt_List.map(propertyWhitelist, printPropertyWhitelist))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  eventGroupTypeIdsWithArchive.length !== 0 ? ({
                        hd: [
                          "eventGroupTypeIds",
                          Belt_Array.map(eventGroupTypeIdsWithArchive, (function (prim) {
                                  return prim;
                                }))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  userGroupTypeIdsWithArchive.length !== 0 ? ({
                        hd: [
                          "userGroupTypeIds",
                          Belt_Array.map(userGroupTypeIdsWithArchive, (function (prim) {
                                  return prim;
                                }))
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  uniqueName !== undefined ? ({
                        hd: [
                          "uniqueName",
                          uniqueName
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  triggers.length !== 0 ? ({
                        hd: [
                          "triggers",
                          Json_encode.array(printEventTrigger, triggers)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0,
                  globalRequirementsMetadata !== undefined ? ({
                        hd: [
                          "globalRequirementsMetadata",
                          printGlobalRequirementsMetadataForItem(globalRequirementsMetadata)
                        ],
                        tl: /* [] */0
                      }) : /* [] */0
                ]));
}

function printType(type_) {
  switch (type_.TAG) {
    case "BuiltInType" :
        var match = type_._0;
        if (match === "Object") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "object"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Any") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "any"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Int") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "int"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Float") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "float"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Bool") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "bool"
                      ],
                      tl: /* [] */0
                    });
        } else if (match === "Long") {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "long"
                      ],
                      tl: /* [] */0
                    });
        } else {
          return Json_encode.object_({
                      hd: [
                        "type",
                        "string"
                      ],
                      tl: /* [] */0
                    });
        }
    case "ExtendedType" :
        return Pervasives.failwith("extendedtype not supported");
    case "TypeRef" :
        return Pervasives.failwith("typeref not supported");
    
  }
}

function printEvents(events) {
  return Json_encode.object_(Belt_List.map(events, (function (x) {
                    return [
                            x.id,
                            printEvent(x)
                          ];
                  })));
}

function printMigrations(migrations) {
  return Belt_List.toArray(Belt_List.map(migrations, TrackingPlanModel.migrationToJs));
}

function printSourceConfig(config) {
  if (config === "IosSwiftUseAmplitudeOver5") {
    return Json_encode.object_({
                hd: [
                  "IosSwiftUseAmplitudeOver5",
                  true
                ],
                tl: /* [] */0
              });
  } else if (config === "SwiftConcurrency") {
    return Json_encode.object_({
                hd: [
                  "SwiftConcurrency",
                  true
                ],
                tl: /* [] */0
              });
  } else if (config === "ForceFlattenPropertyBundlesInCodegen") {
    return Json_encode.object_({
                hd: [
                  "ForceFlattenPropertyBundlesInCodegen",
                  true
                ],
                tl: /* [] */0
              });
  } else {
    return Json_encode.object_({
                hd: [
                  "ValidateAvoEventFunction",
                  true
                ],
                tl: /* [] */0
              });
  }
}

function printSourceDestinationMode(mode) {
  switch (mode) {
    case "AvoManaged" :
        return "AvoManaged";
    case "DestinationInterface" :
        return "DestinationInterface";
    case "Legacy" :
        return "Legacy";
    
  }
}

function printSourceDestinationRef(sourceDestination) {
  var match = sourceDestination.destinationMode;
  var tmp;
  switch (match) {
    case "AvoManaged" :
    case "DestinationInterface" :
        tmp = {
          hd: [
            "destinationMode",
            printSourceDestinationMode(match)
          ],
          tl: /* [] */0
        };
        break;
    case "Legacy" :
        tmp = /* [] */0;
        break;
    
  }
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "destinationId",
                    sourceDestination.destinationId
                  ],
                  tl: /* [] */0
                }, tmp));
}

function printSource(source) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "id",
                      source.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), source.name)
                      ],
                      tl: {
                        hd: [
                          "language",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), Belt_Option.map(source.language, AvoConfigTypes.languageToJs))
                        ],
                        tl: {
                          hd: [
                            "platform",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), Belt_Option.map(source.platform, AvoConfigTypes.devPlatformToJs))
                          ],
                          tl: {
                            hd: [
                              "destinations",
                              Json_encode.list(printSourceDestinationRef, source.destinations)
                            ],
                            tl: {
                              hd: [
                                "filename",
                                source.filename
                              ],
                              tl: {
                                hd: [
                                  "path",
                                  source.path
                                ],
                                tl: {
                                  hd: [
                                    "segmentDestinationOptions",
                                    Json_encode.list(printSegmentIntegrationOption, source.segmentDestinationOptions)
                                  ],
                                  tl: {
                                    hd: [
                                      "config",
                                      Json_encode.list(printSourceConfig, source.config)
                                    ],
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }]));
}

function printSources(sources) {
  return Json_encode.list(printSource, sources);
}

function printOtherApiKey(param) {
  return Json_encode.object_({
              hd: [
                "envName",
                param[0]
              ],
              tl: {
                hd: [
                  "apiKey",
                  param[1]
                ],
                tl: /* [] */0
              }
            });
}

function printDestination(destination) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "id",
                      destination.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), destination.name)
                      ],
                      tl: {
                        hd: [
                          "type_",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), Belt_Option.map(destination.type_, AvoConfigTypes.analyticsToolToJs))
                        ],
                        tl: {
                          hd: [
                            "apiKeys",
                            Json_encode.object_({
                                  hd: [
                                    "dev",
                                    Json_encode.nullable((function (prim) {
                                            return prim;
                                          }), destination.apiKeys.dev)
                                  ],
                                  tl: {
                                    hd: [
                                      "prod",
                                      Json_encode.nullable((function (prim) {
                                              return prim;
                                            }), destination.apiKeys.prod)
                                    ],
                                    tl: {
                                      hd: [
                                        "other",
                                        Json_encode.list(printOtherApiKey, destination.apiKeys.other)
                                      ],
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          ],
                          tl: {
                            hd: [
                              "includeUserPropsWithEventProps",
                              destination.includeUserPropsWithEventProps
                            ],
                            tl: {
                              hd: [
                                "disabledByDefault",
                                destination.disabledByDefault
                              ],
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }]));
}

function printGroupType(groupType) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "groupTypeId",
                      groupType.id
                    ],
                    tl: {
                      hd: [
                        "groupTypeName",
                        groupType.name
                      ],
                      tl: {
                        hd: [
                          "groupTypeDescription",
                          groupType.description
                        ],
                        tl: /* [] */0
                      }
                    }
                  }]));
}

function printDestinations(destinations) {
  return Json_encode.list(printDestination, destinations);
}

function printGroupTypes(groupTypes) {
  return Json_encode.array(printGroupType, groupTypes);
}

function printGoal(goal) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "id",
                      goal.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        goal.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          goal.description
                        ],
                        tl: {
                          hd: [
                            "metrics",
                            Json_encode.list((function (prim) {
                                    return prim;
                                  }), goal.metrics)
                          ],
                          tl: {
                            hd: [
                              "events",
                              Json_encode.list((function (prim) {
                                      return prim;
                                    }), goal.events)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }]));
}

function printGoals(goals) {
  return Json_encode.list(printGoal, goals);
}

function printMetricItem(x) {
  var match = x.VAL;
  return Json_encode.object_({
              hd: [
                "event",
                Json_encode.object_(Belt_List.concatMany([{
                            hd: [
                              "id",
                              match.id
                            ],
                            tl: {
                              hd: [
                                "eventId",
                                match.eventId
                              ],
                              tl: {
                                hd: [
                                  "where",
                                  Json_encode.list((function (param) {
                                          return Json_encode.object_(Belt_List.concatMany([{
                                                            hd: [
                                                              "id",
                                                              param.id
                                                            ],
                                                            tl: {
                                                              hd: [
                                                                "propertyId",
                                                                param.propertyId
                                                              ],
                                                              tl: {
                                                                hd: [
                                                                  "binOp",
                                                                  printBinOp(param.binOp)
                                                                ],
                                                                tl: {
                                                                  hd: [
                                                                    "literals",
                                                                    Json_encode.list(printLiteral, param.literals)
                                                                  ],
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }]));
                                        }), match.where)
                                ],
                                tl: {
                                  hd: [
                                    "groupBy",
                                    Json_encode.list((function (param) {
                                            return Json_encode.object_(Belt_List.concatMany([{
                                                              hd: [
                                                                "id",
                                                                param.id
                                                              ],
                                                              tl: {
                                                                hd: [
                                                                  "propertyId",
                                                                  param.propertyId
                                                                ],
                                                                tl: /* [] */0
                                                              }
                                                            }]));
                                          }), match.groupBy)
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            }
                          }]))
              ],
              tl: /* [] */0
            });
}

function printMetric(metric) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "id",
                      metric.id
                    ],
                    tl: {
                      hd: [
                        "name",
                        metric.name
                      ],
                      tl: {
                        hd: [
                          "description",
                          metric.description
                        ],
                        tl: {
                          hd: [
                            "type",
                            TrackingPlanModel.metricTypeToJs(metric.type_)
                          ],
                          tl: {
                            hd: [
                              "items",
                              Json_encode.list(printMetricItem, metric.items)
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }]));
}

function printMetrics(metrics) {
  return Json_encode.list(printMetric, metrics);
}

function printIntegrationConfig(x) {
  switch (x.TAG) {
    case "Protocols" :
        var match = x._0;
        return Json_encode.object_({
                    hd: [
                      "protocols",
                      Json_encode.object_({
                            hd: [
                              "accessKey",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match.accessKey)
                            ],
                            tl: {
                              hd: [
                                "trackingPlanId",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match.trackingPlanId)
                              ],
                              tl: {
                                hd: [
                                  "workspace",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match.workspace)
                                ],
                                tl: {
                                  hd: [
                                    "usePublicApi",
                                    match.usePublicApi
                                  ],
                                  tl: {
                                    hd: [
                                      "euDataResidency",
                                      match.euDataResidency
                                    ],
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case "Taxonomy" :
        var match$1 = x._0;
        return Json_encode.object_({
                    hd: [
                      "taxonomy",
                      Json_encode.object_({
                            hd: [
                              "apiKey",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$1.apiKey)
                            ],
                            tl: {
                              hd: [
                                "secretKey",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$1.secretKey)
                              ],
                              tl: {
                                hd: [
                                  "euDataResidency",
                                  match$1.euDataResidency
                                ],
                                tl: /* [] */0
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case "Lexicon" :
        var match$2 = x._0;
        return Json_encode.object_({
                    hd: [
                      "lexicon",
                      Json_encode.object_({
                            hd: [
                              "userName",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$2.userName)
                            ],
                            tl: {
                              hd: [
                                "secretKey",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$2.secretKey)
                              ],
                              tl: {
                                hd: [
                                  "projectId",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$2.projectId)
                                ],
                                tl: {
                                  hd: [
                                    "euDataResidency",
                                    match$2.euDataResidency
                                  ],
                                  tl: {
                                    hd: [
                                      "categoriesAsTags",
                                      match$2.categoriesAsTags
                                    ],
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case "Webhook" :
        var match$3 = x._0;
        return Json_encode.object_({
                    hd: [
                      "webhook",
                      Json_encode.object_({
                            hd: [
                              "webhookUrl",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$3.webhookUrl)
                            ],
                            tl: {
                              hd: [
                                "payloadFormat",
                                WebhookPayloadFormat.encode(match$3.payloadFormat)
                              ],
                              tl: /* [] */0
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case "SnowplowDataStructures" :
        var match$4 = x._0;
        return Json_encode.object_({
                    hd: [
                      "snowplowDataStructures",
                      Json_encode.object_({
                            hd: [
                              "accessToken",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$4.accessToken)
                            ],
                            tl: {
                              hd: [
                                "organizationId",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$4.organizationId)
                              ],
                              tl: {
                                hd: [
                                  "vendor",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$4.vendor)
                                ],
                                tl: /* [] */0
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case "MParticleDataMaster" :
        var match$5 = x._0;
        return Json_encode.object_({
                    hd: [
                      "mParticleDataMaster",
                      Json_encode.object_({
                            hd: [
                              "clientId",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$5.clientId)
                            ],
                            tl: {
                              hd: [
                                "clientSecret",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$5.clientSecret)
                              ],
                              tl: {
                                hd: [
                                  "workspaceId",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$5.workspaceId)
                                ],
                                tl: {
                                  hd: [
                                    "planId",
                                    Json_encode.nullable((function (prim) {
                                            return prim;
                                          }), match$5.planId)
                                  ],
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case "RudderstackTrackingPlans" :
        var match$6 = x._0;
        return Json_encode.object_({
                    hd: [
                      "rudderstackTrackingPlans",
                      Json_encode.object_({
                            hd: [
                              "accessToken",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$6.accessToken)
                            ],
                            tl: {
                              hd: [
                                "trackingPlanDisplayName",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$6.trackingPlanDisplayName)
                              ],
                              tl: {
                                hd: [
                                  "emailAddress",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$6.emailAddress)
                                ],
                                tl: /* [] */0
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case "RudderstackDataCatalog" :
        var match$7 = x._0;
        return Json_encode.object_({
                    hd: [
                      "rudderstackDataCatalog",
                      Json_encode.object_({
                            hd: [
                              "accessToken",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$7.accessToken)
                            ],
                            tl: {
                              hd: [
                                "trackingPlanId",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$7.trackingPlanId)
                              ],
                              tl: /* [] */0
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    case "AdobeExperiencePlatform" :
        var match$8 = x._0;
        return Json_encode.object_({
                    hd: [
                      "adobeExperiencePlatform",
                      Json_encode.object_({
                            hd: [
                              "clientId",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), match$8.clientId)
                            ],
                            tl: {
                              hd: [
                                "orgId",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), match$8.orgId)
                              ],
                              tl: {
                                hd: [
                                  "clientSecret",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), match$8.clientSecret)
                                ],
                                tl: /* [] */0
                              }
                            }
                          })
                    ],
                    tl: /* [] */0
                  });
    
  }
}

function printIntegrationFilters(filters) {
  return Json_encode.list((function (filterBy) {
                var variant = filterBy.NAME;
                if (variant === "Destination") {
                  return Json_encode.object_({
                              hd: [
                                "destination",
                                filterBy.VAL
                              ],
                              tl: /* [] */0
                            });
                } else if (variant === "Tag") {
                  return Json_encode.object_({
                              hd: [
                                "tag",
                                filterBy.VAL
                              ],
                              tl: /* [] */0
                            });
                } else {
                  return Json_encode.object_({
                              hd: [
                                "source",
                                filterBy.VAL
                              ],
                              tl: /* [] */0
                            });
                }
              }), filters);
}

function printIntegration(param) {
  return Json_encode.object_({
              hd: [
                "id",
                param.id
              ],
              tl: {
                hd: [
                  "name",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), param.name)
                ],
                tl: {
                  hd: [
                    "filters",
                    printIntegrationFilters(param.filters)
                  ],
                  tl: {
                    hd: [
                      "config",
                      Json_encode.nullable(printIntegrationConfig, param.config)
                    ],
                    tl: {
                      hd: [
                        "autoPublish",
                        param.autoPublish
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function printIntegrations(integrations) {
  return Json_encode.list(printIntegration, integrations);
}

function printArchive(param) {
  return Json_encode.object_({
              hd: [
                "properties",
                printProperties(param.properties)
              ],
              tl: {
                hd: [
                  "events",
                  printEvents(param.events)
                ],
                tl: {
                  hd: [
                    "sources",
                    Json_encode.list(printSource, param.sources)
                  ],
                  tl: {
                    hd: [
                      "destinations",
                      Json_encode.list(printDestination, param.destinations)
                    ],
                    tl: {
                      hd: [
                        "goals",
                        Json_encode.list(printGoal, param.goals)
                      ],
                      tl: {
                        hd: [
                          "metrics",
                          Json_encode.list(printMetric, param.metrics)
                        ],
                        tl: {
                          hd: [
                            "groupTypes",
                            Json_encode.array(printGroupType, param.groupTypes)
                          ],
                          tl: {
                            hd: [
                              "propertyGroups",
                              printPropertyBundles(param.propertyBundles)
                            ],
                            tl: {
                              hd: [
                                "eventVariants",
                                Json_encode.array(printEventVariant, param.eventVariants)
                              ],
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function printBranch(param) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "branchId",
                      param[0]
                    ],
                    tl: {
                      hd: [
                        "branchName",
                        param[1]
                      ],
                      tl: /* [] */0
                    }
                  }]));
}

function printBranches(branches) {
  return Json_encode.list(printBranch, branches);
}

function printBranchPointer(param) {
  return Json_encode.object_(Belt_List.concatMany([{
                    hd: [
                      "actionId",
                      param[0]
                    ],
                    tl: {
                      hd: [
                        "actionTimestamp",
                        param[1]
                      ],
                      tl: /* [] */0
                    }
                  }]));
}

function printPublishingSuccessStats(successStats) {
  return Json_encode.object_({
              hd: [
                "version",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), successStats.version)
              ],
              tl: {
                hd: [
                  "numSuccessfulEvents",
                  successStats.numSuccessfulEvents
                ],
                tl: {
                  hd: [
                    "numSuccessfulProperties",
                    successStats.numSuccessfulProperties
                  ],
                  tl: {
                    hd: [
                      "numSuccessfulCategories",
                      successStats.numSuccessfulCategories
                    ],
                    tl: {
                      hd: [
                        "numSuccessfulPropertyBundles",
                        successStats.numSuccessfulPropertyBundles
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function printGlobalRequirementsMetadata(metadata) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                metadata.schemaId
              ],
              tl: {
                hd: [
                  "actionId",
                  metadata.actionId
                ],
                tl: {
                  hd: [
                    "branchId",
                    metadata.branchId
                  ],
                  tl: {
                    hd: [
                      "lastUpdatedAt",
                      metadata.lastUpdatedAt.toString()
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printGlobalRequirements(param) {
  return Json_encode.object_({
              hd: [
                "metadata",
                printGlobalRequirementsMetadata(param.metadata)
              ],
              tl: {
                hd: [
                  "events",
                  printEvents(param.events)
                ],
                tl: {
                  hd: [
                    "properties",
                    printProperties(param.properties)
                  ],
                  tl: {
                    hd: [
                      "propertyBundles",
                      printPropertyBundles(param.propertyBundles)
                    ],
                    tl: {
                      hd: [
                        "goals",
                        Json_encode.list(printGoal, param.goals)
                      ],
                      tl: {
                        hd: [
                          "groupTypes",
                          Json_encode.array(printGroupType, param.groupTypes)
                        ],
                        tl: {
                          hd: [
                            "rules",
                            Json_encode.list(printRule, param.rules)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function printTrackingPlanAuditConfigItem(configItem) {
  if (configItem === "PreferredPropertyNameCasing") {
    return "PreferredPropertyNameCasing";
  } else if (configItem === "NoConflictingProperties") {
    return "NoConflictingProperties";
  } else if (configItem === "ConsistentPropertyNameCasing") {
    return "ConsistentPropertyNameCasing";
  } else if (configItem === "AllEventsHaveDescriptions") {
    return "AllEventsHaveDescriptions";
  } else if (configItem === "BranchEnforcementStatus") {
    return "BranchEnforcementStatus";
  } else if (configItem === "ConsistentEventNameCasing") {
    return "ConsistentEventNameCasing";
  } else if (configItem === "ForcePropertyCasing") {
    return "ForcePropertyCasing";
  } else if (configItem === "ForceEventCasing") {
    return "ForceEventCasing";
  } else if (configItem === "AllPropertiesHaveTypes") {
    return "AllPropertiesHaveTypes";
  } else if (configItem === "UniqueEventNames") {
    return "UniqueEventNames";
  } else if (configItem === "AllPropertiesHaveDescriptions") {
    return "AllPropertiesHaveDescriptions";
  } else if (configItem === "PreferredEventNameCasing") {
    return "PreferredEventNameCasing";
  } else {
    return "UniquePropertyNames";
  }
}

function printTrackingPlanAuditRuleSeverity(severity) {
  if (severity === "enforce") {
    return "enforce";
  } else if (severity === "warning") {
    return "warning";
  } else {
    return "none";
  }
}

function printTrackingPlanAuditPointOfBlock(pointOfBlock) {
  if (pointOfBlock === "beforeRequestForReview") {
    return "beforeRequestForReview";
  } else {
    return "beforeMerge";
  }
}

function printModel(param) {
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "types",
                    Belt_List.toArray(Belt_List.map(param.types, printType))
                  ],
                  tl: {
                    hd: [
                      "properties",
                      printProperties(param.properties)
                    ],
                    tl: {
                      hd: [
                        "propertyGroups",
                        printPropertyBundles(param.propertyBundles)
                      ],
                      tl: {
                        hd: [
                          "events",
                          printEvents(param.events)
                        ],
                        tl: {
                          hd: [
                            "migrations",
                            printMigrations(param.migrations)
                          ],
                          tl: {
                            hd: [
                              "sources",
                              Json_encode.list(printSource, param.sources)
                            ],
                            tl: {
                              hd: [
                                "destinations",
                                Json_encode.list(printDestination, param.destinations)
                              ],
                              tl: {
                                hd: [
                                  "groupTypes",
                                  Json_encode.array(printGroupType, param.groupTypes)
                                ],
                                tl: {
                                  hd: [
                                    "goals",
                                    Json_encode.list(printGoal, param.goals)
                                  ],
                                  tl: {
                                    hd: [
                                      "metrics",
                                      Json_encode.list(printMetric, param.metrics)
                                    ],
                                    tl: {
                                      hd: [
                                        "archive",
                                        printArchive(param.archive)
                                      ],
                                      tl: {
                                        hd: [
                                          "openBranches",
                                          Json_encode.list(printBranch, param.openBranches)
                                        ],
                                        tl: {
                                          hd: [
                                            "rules",
                                            Json_encode.list(printRule, param.rules)
                                          ],
                                          tl: {
                                            hd: [
                                              "integrations",
                                              Json_encode.list(printIntegration, param.integrations)
                                            ],
                                            tl: {
                                              hd: [
                                                "globalRequirements",
                                                Json_encode.nullable(printGlobalRequirements, param.globalRequirements)
                                              ],
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }, Belt_Option.mapWithDefault(param.branchPointer, /* [] */0, (function (branchPointer) {
                        return {
                                hd: [
                                  "branchPointer",
                                  printBranchPointer(branchPointer)
                                ],
                                tl: /* [] */0
                              };
                      }))));
}

function printAvoJson(param) {
  return Json_encode.object_({
              hd: [
                "types",
                Belt_List.toArray(Belt_List.map(param.types, printType))
              ],
              tl: {
                hd: [
                  "properties",
                  printProperties(param.properties)
                ],
                tl: {
                  hd: [
                    "events",
                    printEvents(param.events)
                  ],
                  tl: {
                    hd: [
                      "migrations",
                      printMigrations(param.migrations)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printModelString(model) {
  return JSON.stringify(printModel(model));
}

function printAction(action) {
  var tmp;
  if (typeof action === "object") {
    var variant = action.NAME;
    if (variant === "UpdateDestinationDisabledByDefault") {
      var match = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationDisabledByDefault",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match[0]
                ],
                tl: {
                  hd: [
                    "disabledByDefault",
                    match[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddProperty") {
      var match$1 = action.VAL;
      tmp = {
        hd: [
          "AddProperty",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$1[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$1[1]
                  ],
                  tl: {
                    hd: [
                      "name",
                      match$1[2]
                    ],
                    tl: {
                      hd: [
                        "sendAs",
                        printSendAs(match$1[3])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "MergeBranch") {
      var match$2 = action.VAL;
      tmp = {
        hd: [
          "MergeBranch",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$2[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$2[1]
                  ],
                  tl: {
                    hd: [
                      "actions",
                      Json_encode.list(printAction, match$2[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventType") {
      var match$3 = action.VAL;
      tmp = {
        hd: [
          "AddEventType",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$3[0]
                ],
                tl: {
                  hd: [
                    "type",
                    TrackingPlanModel.eventTypeToJs(match$3[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateTriggerContent") {
      var match$4 = action.VAL;
      tmp = {
        hd: [
          "UpdateTriggerContent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$4[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$4[1]
                  ],
                  tl: {
                    hd: [
                      "content",
                      Json_encode.nullable(printTriggerContent, match$4[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ConvertPropertyBundleToGlobalPropertyBundle") {
      var match$5 = action.VAL;
      tmp = {
        hd: [
          "ConvertPropertyBundleToGlobalPropertyBundle",
          Json_encode.object_({
                hd: [
                  "fromPropertyBundleId",
                  match$5[0]
                ],
                tl: {
                  hd: [
                    "toPropertyBundleId",
                    match$5[1]
                  ],
                  tl: {
                    hd: [
                      "globalSchemaId",
                      match$5[2]
                    ],
                    tl: {
                      hd: [
                        "metadata",
                        printGlobalRequirementsMetadata(match$5[3])
                      ],
                      tl: {
                        hd: [
                          "globalPropertyBundle",
                          printPropertyBundle(match$5[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventVariantTrigger") {
      var match$6 = action.VAL;
      var variantIdentifier = match$6[0];
      tmp = {
        hd: [
          "RemoveEventVariantTrigger",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier.variantId
                  ],
                  tl: {
                    hd: [
                      "triggerId",
                      match$6[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeprecatedRemovePropertyValidationMatch") {
      var match$7 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyValidationMatch",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$7[0]
                ],
                tl: {
                  hd: [
                    "match",
                    match$7[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourceDestinationMode") {
      var match$8 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourceDestinationMode",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$8[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$8[1]
                  ],
                  tl: {
                    hd: [
                      "destinationMode",
                      printSourceDestinationMode(match$8[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "TogglePropertyValidationMatchSource") {
      var match$9 = action.VAL;
      tmp = {
        hd: [
          "TogglePropertyValidationMatchSource",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$9[0]
                ],
                tl: {
                  hd: [
                    "match",
                    match$9[1]
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      match$9[2]
                    ],
                    tl: {
                      hd: [
                        "isActive",
                        match$9[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventSpecificPropertyValueForAllEvents") {
      var match$10 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventSpecificPropertyValueForAllEvents",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$10[0]
                ],
                tl: {
                  hd: [
                    "literal",
                    printLiteral(match$10[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyUniqueName") {
      var match$11 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyUniqueName",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$11[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$11[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationIncludeUserPropsWithEventProps") {
      var match$12 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationIncludeUserPropsWithEventProps",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$12[0]
                ],
                tl: {
                  hd: [
                    "includeUserPropsWithEventProps",
                    match$12[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEvent") {
      var match$13 = action.VAL;
      tmp = {
        hd: [
          "AddEvent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$13[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$13[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyPermanently") {
      tmp = {
        hd: [
          "RemovePropertyPermanently",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateSavedView") {
      var match$14 = action.VAL;
      tmp = {
        hd: [
          "CreateSavedView",
          Json_encode.object_({
                hd: [
                  "savedViewId",
                  match$14[0]
                ],
                tl: {
                  hd: [
                    "savedViewName",
                    match$14[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyFromWhitelist") {
      var match$15 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyFromWhitelist",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$15[0]
                ],
                tl: {
                  hd: [
                    "groupId",
                    match$15[1]
                  ],
                  tl: {
                    hd: [
                      "analyticsTool",
                      AvoConfigTypes.analyticsToolToJs(match$15[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetBranchStatus") {
      var match$16 = action.VAL;
      tmp = {
        hd: [
          "SetBranchStatus",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$16[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$16[1]
                  ],
                  tl: {
                    hd: [
                      "branchStatus",
                      TrackingPlanModel.branchStatusToJs(match$16[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateLibraryDestination") {
      var match$17 = action.VAL;
      tmp = {
        hd: [
          "UpdateLibraryDestination",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$17[0]
                ],
                tl: {
                  hd: [
                    "path",
                    match$17[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyToEventVariant") {
      var match$18 = action.VAL;
      var variantIdentifier$1 = match$18[0];
      tmp = {
        hd: [
          "AddPropertyToEventVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$1.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$1.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$18[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PaymentCompleted") {
      var match$19 = action.VAL;
      tmp = {
        hd: [
          "PaymentCompleted",
          Json_encode.object_({
                hd: [
                  "paymentSource",
                  match$19[0]
                ],
                tl: {
                  hd: [
                    "subscription",
                    match$19[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventVariantTriggerSources") {
      var match$20 = action.VAL;
      var variantIdentifier$2 = match$20[0];
      tmp = {
        hd: [
          "UpdateEventVariantTriggerSources",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$2.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$2.variantId
                  ],
                  tl: {
                    hd: [
                      "triggerId",
                      match$20[1]
                    ],
                    tl: {
                      hd: [
                        "sources",
                        printTriggerSources(match$20[2])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ImportDeprecated") {
      tmp = {
        hd: [
          "Import",
          Json_encode.object_({
                hd: [
                  "actions",
                  Json_encode.list(printAction, action.VAL)
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ClearPropertyPinnedValueFromEventVariant") {
      var match$21 = action.VAL;
      var variantIdentifier$3 = match$21[0];
      tmp = {
        hd: [
          "ClearPropertyPinnedValueFromEventVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$3.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$3.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$21[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddGoal") {
      tmp = {
        hd: [
          "AddGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationAutoPublish") {
      var match$22 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationAutoPublish",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$22[0]
                ],
                tl: {
                  hd: [
                    "autoPublish",
                    match$22[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetSourceOverrideOnVariant") {
      var match$23 = action.VAL;
      var variantIdentifier$4 = match$23[0];
      tmp = {
        hd: [
          "SetSourceOverrideOnVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$4.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$4.variantId
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      match$23[1]
                    ],
                    tl: {
                      hd: [
                        "sourceOverrideType",
                        printEventSourceAbsenceOverrideType(match$23[2])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateDemoBranch") {
      tmp = {
        hd: [
          "CreateDemoBranch",
          Json_encode.object_({
                hd: [
                  "actions",
                  Json_encode.list(printAction, action.VAL)
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeprecatedAddPropertyValidationMatch") {
      var match$24 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyValidationMatch",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$24[0]
                ],
                tl: {
                  hd: [
                    "match",
                    match$24[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateGoalDescription") {
      var match$25 = action.VAL;
      tmp = {
        hd: [
          "UpdateGoalDescription",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$25[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$25[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "MigrateSourcesAndDestinationsToModel") {
      tmp = {
        hd: [
          "MigrateSourcesAndDestinationsToModel",
          Json_encode.object_({
                hd: [
                  "sources",
                  []
                ],
                tl: {
                  hd: [
                    "destinitions",
                    []
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeprecatedRemovePropertyValidationMatches") {
      tmp = {
        hd: [
          "RemovePropertyValidationMatches",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyList") {
      var match$26 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyList",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$26[0]
                ],
                tl: {
                  hd: [
                    "list",
                    match$26[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetMetricWhereV2") {
      var match$27 = action.VAL;
      tmp = {
        hd: [
          "SetMetricWhereV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$27[0]
                ],
                tl: {
                  hd: [
                    "metricItemId",
                    match$27[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$27[2]
                    ],
                    tl: {
                      hd: [
                        "id",
                        match$27[3]
                      ],
                      tl: {
                        hd: [
                          "propertyId",
                          match$27[4]
                        ],
                        tl: {
                          hd: [
                            "binOp",
                            printBinOp(match$27[5])
                          ],
                          tl: {
                            hd: [
                              "literals",
                              Json_encode.list(printLiteral, match$27[6])
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReviewerRemoved") {
      var match$28 = action.VAL;
      tmp = {
        hd: [
          "ReviewerRemoved",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$28[0]
                ],
                tl: {
                  hd: [
                    "removedByUserId",
                    match$28[1]
                  ],
                  tl: {
                    hd: [
                      "userId",
                      match$28[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ConvertEventToGlobalEvent") {
      var match$29 = action.VAL;
      tmp = {
        hd: [
          "ConvertEventToGlobalEvent",
          Json_encode.object_({
                hd: [
                  "fromEventId",
                  match$29[0]
                ],
                tl: {
                  hd: [
                    "toEventId",
                    match$29[1]
                  ],
                  tl: {
                    hd: [
                      "globalSchemaId",
                      match$29[2]
                    ],
                    tl: {
                      hd: [
                        "metadata",
                        printGlobalRequirementsMetadata(match$29[3])
                      ],
                      tl: {
                        hd: [
                          "globalEvent",
                          printEvent(match$29[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyName") {
      var match$30 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyName",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$30[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$30[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "InviteMember") {
      var match$31 = action.VAL;
      tmp = {
        hd: [
          "InviteMember",
          Json_encode.object_({
                hd: [
                  "email",
                  match$31[0]
                ],
                tl: {
                  hd: [
                    "role",
                    match$31[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyDescription") {
      var match$32 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyDescription",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$32[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$32[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddDomainItems") {
      var match$33 = action.VAL;
      tmp = {
        hd: [
          "AddDomainItems",
          Json_encode.object_({
                hd: [
                  "id",
                  match$33[0]
                ],
                tl: {
                  hd: [
                    "items",
                    Domain__Json.encodeDomainItems(match$33[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddTag") {
      var match$34 = action.VAL;
      tmp = {
        hd: [
          "AddTag",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$34[0]
                ],
                tl: {
                  hd: [
                    "tag",
                    match$34[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderEventsInGoalV2") {
      var match$35 = action.VAL;
      tmp = {
        hd: [
          "ReorderEventsInGoalV2",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$35[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$35[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$35[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ClearAllEventSpecificPropertyValuesFromProperty") {
      tmp = {
        hd: [
          "ClearAllEventSpecificPropertyValuesFromProperty",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderMetricsInGoal") {
      var match$36 = action.VAL;
      tmp = {
        hd: [
          "ReorderMetricsInGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$36[0]
                ],
                tl: {
                  hd: [
                    "metricId",
                    match$36[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$36[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertySendAs") {
      var match$37 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertySendAs",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$37[0]
                ],
                tl: {
                  hd: [
                    "sendAs",
                    printSendAs(match$37[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventVariantTriggerSource") {
      var match$38 = action.VAL;
      var variantIdentifier$5 = match$38[0];
      tmp = {
        hd: [
          "RemoveEventVariantTriggerSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$5.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$5.variantId
                  ],
                  tl: {
                    hd: [
                      "triggerId",
                      match$38[1]
                    ],
                    tl: {
                      hd: [
                        "sourceId",
                        match$38[2]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddRule") {
      var match$39 = action.VAL;
      tmp = {
        hd: [
          "AddRule",
          Json_encode.object_({
                hd: [
                  "ruleId",
                  match$39[0]
                ],
                tl: {
                  hd: [
                    "item",
                    printRuleItem(match$39[1])
                  ],
                  tl: {
                    hd: [
                      "definition",
                      printRuleDefinition(match$39[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourceName") {
      var match$40 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourceName",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$40[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$40[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "BranchComment") {
      var match$41 = action.VAL;
      tmp = {
        hd: [
          "BranchComment",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$41[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$41[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyType") {
      var match$42 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyType",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$42[0]
                ],
                tl: {
                  hd: [
                    "type",
                    match$42[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "LegacyIncludeDestinationInSource") {
      var match$43 = action.VAL;
      tmp = {
        hd: [
          "IncludeDestinationInSource",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$43[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$43[1]
                  ],
                  tl: {
                    hd: [
                      "eventIds",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), match$43[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyRef") {
      var match$44 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyRef",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$44[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$44[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveInvite") {
      var match$45 = action.VAL;
      tmp = {
        hd: [
          "RemoveInvite",
          Json_encode.object_({
                hd: [
                  "email",
                  match$45[0]
                ],
                tl: {
                  hd: [
                    "role",
                    match$45[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "OpenBranch") {
      var match$46 = action.VAL;
      tmp = {
        hd: [
          "OpenBranch",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$46[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$46[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ConvertPropertyToGlobalProperty") {
      var match$47 = action.VAL;
      tmp = {
        hd: [
          "ConvertPropertyToGlobalProperty",
          Json_encode.object_({
                hd: [
                  "fromPropertyId",
                  match$47[0]
                ],
                tl: {
                  hd: [
                    "toPropertyId",
                    match$47[1]
                  ],
                  tl: {
                    hd: [
                      "globalSchemaId",
                      match$47[2]
                    ],
                    tl: {
                      hd: [
                        "globalProperty",
                        printProperty({
                              TAG: "Property",
                              _0: match$47[3]
                            })
                      ],
                      tl: {
                        hd: [
                          "metadata",
                          printGlobalRequirementsMetadata(match$47[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEvent") {
      tmp = {
        hd: [
          "RemoveEvent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderEventsInGoal") {
      var match$48 = action.VAL;
      tmp = {
        hd: [
          "ReorderEventsInGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$48[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$48[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$48[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "EventComment") {
      var match$49 = action.VAL;
      tmp = {
        hd: [
          "EventComment",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$49[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$49[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateInspectorIssueStatus") {
      tmp = {
        hd: [
          "UpdateInspectorIssueStatus",
          printInspectorIssueMetadata(action.VAL)
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddMigrationDangerous") {
      tmp = {
        hd: [
          "AddMigrationDangerous",
          Json_encode.object_({
                hd: [
                  "migration",
                  TrackingPlanModel.migrationToJs(action.VAL)
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateEventVariantTrigger") {
      var match$50 = action.VAL;
      var variantIdentifier$6 = match$50[0];
      tmp = {
        hd: [
          "CreateEventVariantTrigger",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$6.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$6.variantId
                  ],
                  tl: {
                    hd: [
                      "triggerId",
                      match$50[1]
                    ],
                    tl: {
                      hd: [
                        "sources",
                        printTriggerSources(match$50[2])
                      ],
                      tl: {
                        hd: [
                          "description",
                          match$50[3]
                        ],
                        tl: {
                          hd: [
                            "content",
                            Json_encode.nullable(printTriggerContent, match$50[4])
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteLocalWorkspace") {
      tmp = {
        hd: [
          "DeleteLocalWorkspace",
          Json_encode.object_({
                hd: [
                  "workspaceId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PropertyGroupComment") {
      var match$51 = action.VAL;
      tmp = {
        hd: [
          "PropertyGroupComment",
          Json_encode.object_({
                hd: [
                  "propertyGroupId",
                  match$51[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$51[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ExcludeEventFromSourceV2") {
      var match$52 = action.VAL;
      tmp = {
        hd: [
          "ExcludeEventFromSourceV2",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$52[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$52[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateGroupType") {
      var match$53 = action.VAL;
      tmp = {
        hd: [
          "CreateGroupType",
          Json_encode.object_({
                hd: [
                  "groupTypeId",
                  match$53[0]
                ],
                tl: {
                  hd: [
                    "groupTypeName",
                    match$53[1]
                  ],
                  tl: {
                    hd: [
                      "groupTypeDescription",
                      match$53[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CollaboratorAdded") {
      var match$54 = action.VAL;
      tmp = {
        hd: [
          "CollaboratorAdded",
          Json_encode.object_({
                hd: [
                  "collaborationItem",
                  printCollaborationItem(match$54[0])
                ],
                tl: {
                  hd: [
                    "addedByUserId",
                    match$54[1]
                  ],
                  tl: {
                    hd: [
                      "userId",
                      match$54[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "LegacyToggleDestination") {
      var match$55 = action.VAL;
      tmp = {
        hd: [
          "ToggleDestination",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$55[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$55[1]
                  ],
                  tl: {
                    hd: [
                      "isActive",
                      match$55[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeletePublicBranchImplementation") {
      tmp = {
        hd: [
          "DeletePublicBranchImplementation",
          Json_encode.object_({
                hd: [
                  "shareId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ClearSourceOverrideOnVariant") {
      var match$56 = action.VAL;
      var variantIdentifier$7 = match$56[0];
      tmp = {
        hd: [
          "ClearSourceOverrideOnVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$7.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$7.variantId
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      match$56[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteSource") {
      tmp = {
        hd: [
          "DeleteSource",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderMetricsInGoalV2") {
      var match$57 = action.VAL;
      tmp = {
        hd: [
          "ReorderMetricsInGoalV2",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$57[0]
                ],
                tl: {
                  hd: [
                    "metricId",
                    match$57[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$57[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderEventsInMetricV2") {
      var match$58 = action.VAL;
      tmp = {
        hd: [
          "ReorderEventsInMetricV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$58[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$58[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$58[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyRegexOverrideOnEventVariant") {
      var match$59 = action.VAL;
      var variantIdentifier$8 = match$59[0];
      tmp = {
        hd: [
          "UpdatePropertyRegexOverrideOnEventVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$8.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$8.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$59[1]
                    ],
                    tl: {
                      hd: [
                        "regexValidationRule",
                        Json_encode.nullable(printPropertyRegexValidationRule, match$59[2])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricGroupBy") {
      var match$60 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricGroupBy",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$60[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$60[1]
                  ],
                  tl: {
                    hd: [
                      "id",
                      match$60[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateProgrammingLanguage") {
      var match$61 = action.VAL;
      tmp = {
        hd: [
          "UpdateProgrammingLanguage",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$61[0]
                ],
                tl: {
                  hd: [
                    "language",
                    AvoConfigTypes.languageToJs(match$61[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ConvertEventToGlobalEventV2") {
      var match$62 = action.VAL;
      tmp = {
        hd: [
          "ConvertEventToGlobalEventV2",
          Json_encode.object_({
                hd: [
                  "fromEventId",
                  match$62[0]
                ],
                tl: {
                  hd: [
                    "toEventId",
                    match$62[1]
                  ],
                  tl: {
                    hd: [
                      "globalSchemaId",
                      match$62[2]
                    ],
                    tl: {
                      hd: [
                        "metadata",
                        printGlobalRequirementsMetadata(match$62[3])
                      ],
                      tl: {
                        hd: [
                          "globalEvent",
                          printEvent(match$62[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddMetric") {
      tmp = {
        hd: [
          "AddMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateTriggerSources") {
      var match$63 = action.VAL;
      tmp = {
        hd: [
          "UpdateTriggerSources",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$63[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$63[1]
                  ],
                  tl: {
                    hd: [
                      "sources",
                      printTriggerSources(match$63[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "MetricComment") {
      var match$64 = action.VAL;
      tmp = {
        hd: [
          "MetricComment",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$64[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$64[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourcePlatformV2") {
      var match$65 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourcePlatformV2",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$65[0]
                ],
                tl: {
                  hd: [
                    "platform",
                    AvoConfigTypes.devPlatformToJs(match$65[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourcePlatformV3") {
      var match$66 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourcePlatformV3",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$66[0]
                ],
                tl: {
                  hd: [
                    "platform",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), Belt_Option.map(match$66[1], AvoConfigTypes.devPlatformToJs))
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventUniqueNameV2") {
      var match$67 = action.VAL;
      tmp = {
        hd: [
          "UpdateEventUniqueNameV2",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$67[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$67[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateEventTrigger") {
      var match$68 = action.VAL;
      tmp = {
        hd: [
          "CreateEventTrigger",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$68[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$68[1]
                  ],
                  tl: {
                    hd: [
                      "sources",
                      printTriggerSources(match$68[2])
                    ],
                    tl: {
                      hd: [
                        "description",
                        match$68[3]
                      ],
                      tl: {
                        hd: [
                          "content",
                          Json_encode.nullable(printTriggerContent, match$68[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyRegexValidation") {
      var match$69 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyRegexValidation",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$69[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), match$69[1])
                  ],
                  tl: {
                    hd: [
                      "rule",
                      Json_encode.nullable(printPropertyRegexValidationRule, match$69[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateGroupTypeName") {
      var match$70 = action.VAL;
      tmp = {
        hd: [
          "UpdateGroupTypeName",
          Json_encode.object_({
                hd: [
                  "groupTypeId",
                  match$70[0]
                ],
                tl: {
                  hd: [
                    "groupTypeName",
                    match$70[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveDestinationApiKey") {
      var match$71 = action.VAL;
      tmp = {
        hd: [
          "RemoveDestinationApiKey",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$71[0]
                ],
                tl: {
                  hd: [
                    "envName",
                    match$71[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteServiceAccount") {
      tmp = {
        hd: [
          "DeleteServiceAccount",
          Json_encode.object_({
                hd: [
                  "serviceAccountName",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventSpecificPropertyValue") {
      var match$72 = action.VAL;
      var __x = Belt_SetString.toArray(match$72[3]);
      tmp = {
        hd: [
          "RemoveEventSpecificPropertyValue",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$72[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$72[1]
                  ],
                  tl: {
                    hd: [
                      "literal",
                      printLiteral(match$72[2])
                    ],
                    tl: {
                      hd: [
                        "removedAllowedSourcesSet",
                        Json_encode.array((function (prim) {
                                return prim;
                              }), __x)
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "Archive") {
      tmp = {
        hd: [
          "Archive",
          printItem(action.VAL)
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyRefV2Deprecated") {
      var match$73 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyRefV2",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$73[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$73[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationConfig") {
      var match$74 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationConfig",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$74[0]
                ],
                tl: {
                  hd: [
                    "config",
                    printIntegrationConfig(match$74[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ClearEventVariantPropertyOverride") {
      var match$75 = action.VAL;
      var variantIdentifier$9 = match$75[0];
      tmp = {
        hd: [
          "ClearEventVariantPropertyOverride",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$9.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$9.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$75[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyOperation") {
      var match$76 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyOperation",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$76[0]
                ],
                tl: {
                  hd: [
                    "operation",
                    TrackingPlanModel.operationToJs(match$76[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "TogglePropertyExcludedSource") {
      var match$77 = action.VAL;
      tmp = {
        hd: [
          "TogglePropertyExcludedSource",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$77[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$77[1]
                  ],
                  tl: {
                    hd: [
                      "isActive",
                      match$77[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveTag") {
      var match$78 = action.VAL;
      tmp = {
        hd: [
          "RemoveTag",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$78[0]
                ],
                tl: {
                  hd: [
                    "tag",
                    match$78[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddUserToGroup") {
      var match$79 = action.VAL;
      tmp = {
        hd: [
          "AddUserToGroup",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$79[0]
                ],
                tl: {
                  hd: [
                    "groupTypeId",
                    match$79[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetEventSpecificPropertyValueSources") {
      var match$80 = action.VAL;
      var __x$1 = Belt_SetString.toArray(match$80[3]);
      var __x$2 = Belt_SetString.toArray(match$80[4]);
      tmp = {
        hd: [
          "SetEventSpecificPropertyValueSources",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$80[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$80[1]
                  ],
                  tl: {
                    hd: [
                      "literal",
                      printLiteral(match$80[2])
                    ],
                    tl: {
                      hd: [
                        "addedAllowedSourcesSet",
                        Json_encode.array((function (prim) {
                                return prim;
                              }), __x$1)
                      ],
                      tl: {
                        hd: [
                          "removedAllowedSourcesSet",
                          Json_encode.array((function (prim) {
                                  return prim;
                                }), __x$2)
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "MigrateExcludeEventsToIncludeEvents") {
      tmp = {
        hd: [
          "MigrateExcludeEventsToIncludeEvents",
          Json_encode.object_({
                hd: [
                  "sourceIds",
                  []
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveDomainItems") {
      var match$81 = action.VAL;
      tmp = {
        hd: [
          "RemoveDomainItems",
          Json_encode.object_({
                hd: [
                  "id",
                  match$81[0]
                ],
                tl: {
                  hd: [
                    "items",
                    Domain__Json.encodeDomainItems(match$81[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventVariantDescription") {
      var match$82 = action.VAL;
      var variantIdentifier$10 = match$82[0];
      tmp = {
        hd: [
          "UpdateEventVariantDescription",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$10.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$10.variantId
                  ],
                  tl: {
                    hd: [
                      "description",
                      match$82[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeprecatedUpdatePropertyValidationMinOrMax") {
      var match$83 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyValidationMinOrMax",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$83[0]
                ],
                tl: {
                  hd: [
                    "newValue",
                    match$83[1]
                  ],
                  tl: {
                    hd: [
                      "validationType",
                      TrackingPlanModel.constraint_ToJs(match$83[2])
                    ],
                    tl: {
                      hd: [
                        "validationIndex",
                        match$83[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyRefV3") {
      var match$84 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyRef",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$84[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$84[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "InspectorIssueComment") {
      var match$85 = action.VAL;
      tmp = {
        hd: [
          "InspectorIssueComment",
          Json_encode.object_({
                hd: [
                  "inspectorIssueId",
                  match$85[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$85[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveIntegration") {
      tmp = {
        hd: [
          "RemoveIntegration",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetPropertyAbsenceOnVariant") {
      var match$86 = action.VAL;
      var variantIdentifier$11 = match$86[0];
      tmp = {
        hd: [
          "SetPropertyAbsenceOnVariant",
          Json_encode.object_(Belt_List.concat({
                    hd: [
                      "eventId",
                      variantIdentifier$11.baseEventId
                    ],
                    tl: {
                      hd: [
                        "variantId",
                        variantIdentifier$11.variantId
                      ],
                      tl: {
                        hd: [
                          "propertyId",
                          match$86[1]
                        ],
                        tl: /* [] */0
                      }
                    }
                  }, encodeVariantAbsenceOverride(match$86[2])))
        ],
        tl: /* [] */0
      };
    } else if (variant === "IntegrationComment") {
      var match$87 = action.VAL;
      tmp = {
        hd: [
          "IntegrationComment",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$87[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$87[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationApiKey") {
      var match$88 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationApiKey",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$88[0]
                ],
                tl: {
                  hd: [
                    "envName",
                    match$88[1]
                  ],
                  tl: {
                    hd: [
                      "key",
                      match$88[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationName") {
      var match$89 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationName",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$89[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$89[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ToggleIncludeEventInCodegenForSource") {
      var match$90 = action.VAL;
      tmp = {
        hd: [
          "ToggleIncludeEventInCodegenForSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$90[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$90[1]
                  ],
                  tl: {
                    hd: [
                      "includeInCodegen",
                      match$90[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateLocalWorkspaceName") {
      var match$91 = action.VAL;
      tmp = {
        hd: [
          "UpdateLocalWorkspaceName",
          Json_encode.object_({
                hd: [
                  "workspaceId",
                  match$91[0]
                ],
                tl: {
                  hd: [
                    "workspaceName",
                    match$91[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyOptional") {
      var match$92 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyOptional",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$92[0]
                ],
                tl: {
                  hd: [
                    "optional",
                    match$92[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed") {
      var match$93 = action.VAL;
      var variantIdentifier$12 = match$93[0];
      tmp = {
        hd: [
          "OverrideEventVariantSpecificPropertyValueToBeDisallowed",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$12.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$12.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$93[1]
                    ],
                    tl: {
                      hd: [
                        "literal",
                        printLiteral(match$93[2])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IncludeEventInSourceV2") {
      var match$94 = action.VAL;
      tmp = {
        hd: [
          "IncludeEventInSourceV2",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$94[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$94[1]
                  ],
                  tl: {
                    hd: [
                      "destinationIds",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), match$94[2])
                    ],
                    tl: {
                      hd: [
                        "includeInCodegen",
                        match$94[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSavedView") {
      var match$95 = action.VAL;
      tmp = {
        hd: [
          "UpdateSavedView",
          Json_encode.object_({
                hd: [
                  "savedViewId",
                  match$95[0]
                ],
                tl: {
                  hd: [
                    "savedViewName",
                    match$95[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ConvertPropertyToGlobalPropertyV2") {
      var match$96 = action.VAL;
      tmp = {
        hd: [
          "ConvertPropertyToGlobalPropertyV2",
          Json_encode.object_({
                hd: [
                  "fromPropertyId",
                  match$96[0]
                ],
                tl: {
                  hd: [
                    "toPropertyId",
                    match$96[1]
                  ],
                  tl: {
                    hd: [
                      "globalSchemaId",
                      match$96[2]
                    ],
                    tl: {
                      hd: [
                        "globalProperty",
                        printProperty({
                              TAG: "Property",
                              _0: match$96[3]
                            })
                      ],
                      tl: {
                        hd: [
                          "metadata",
                          printGlobalRequirementsMetadata(match$96[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddSystemProperty") {
      var match$97 = action.VAL;
      tmp = {
        hd: [
          "AddSystemProperty",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$97[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$97[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateRule") {
      var match$98 = action.VAL;
      tmp = {
        hd: [
          "UpdateRule",
          Json_encode.object_({
                hd: [
                  "ruleId",
                  match$98[0]
                ],
                tl: {
                  hd: [
                    "item",
                    printRuleItem(match$98[1])
                  ],
                  tl: {
                    hd: [
                      "definition",
                      printRuleDefinition(match$98[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "GoalComment") {
      var match$99 = action.VAL;
      tmp = {
        hd: [
          "GoalComment",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$99[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$99[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetMetricGroupBy") {
      var match$100 = action.VAL;
      tmp = {
        hd: [
          "SetMetricGroupBy",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$100[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$100[1]
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$100[3]
                    ],
                    tl: {
                      hd: [
                        "id",
                        match$100[2]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources") {
      var match$101 = action.VAL;
      var variantIdentifier$13 = match$101[0];
      tmp = {
        hd: [
          "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$13.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$13.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$101[1]
                    ],
                    tl: {
                      hd: [
                        "literal",
                        printLiteral(match$101[2])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventSpecificPropertyValue") {
      var match$102 = action.VAL;
      var __x$3 = Belt_SetString.toArray(match$102[3]);
      tmp = {
        hd: [
          "AddEventSpecificPropertyValue",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$102[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$102[1]
                  ],
                  tl: {
                    hd: [
                      "literal",
                      printLiteral(match$102[2])
                    ],
                    tl: {
                      hd: [
                        "addedAllowedSourcesSet",
                        Json_encode.array((function (prim) {
                                return prim;
                              }), __x$3)
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateMemberRole") {
      var match$103 = action.VAL;
      tmp = {
        hd: [
          "UpdateMemberRole",
          Json_encode.object_({
                hd: [
                  "userId",
                  match$103[0]
                ],
                tl: {
                  hd: [
                    "email",
                    match$103[1]
                  ],
                  tl: {
                    hd: [
                      "fromRole",
                      match$103[2]
                    ],
                    tl: {
                      hd: [
                        "toRole",
                        match$103[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveSourceSegmentIntegration") {
      var match$104 = action.VAL;
      tmp = {
        hd: [
          "RemoveSourceSegmentIntegration",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$104[0]
                ],
                tl: {
                  hd: [
                    "segmentIntegration",
                    match$104[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IncludeEventInSource") {
      var match$105 = action.VAL;
      tmp = {
        hd: [
          "IncludeEventInSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$105[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$105[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventVariantTriggerDescription") {
      var match$106 = action.VAL;
      var variantIdentifier$14 = match$106[0];
      tmp = {
        hd: [
          "UpdateEventVariantTriggerDescription",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$14.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$14.variantId
                  ],
                  tl: {
                    hd: [
                      "triggerId",
                      match$106[1]
                    ],
                    tl: {
                      hd: [
                        "description",
                        match$106[2]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventDescription") {
      var match$107 = action.VAL;
      tmp = {
        hd: [
          "UpdateEventDescription",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$107[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$107[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DuplicateMetric") {
      var match$108 = action.VAL;
      tmp = {
        hd: [
          "DuplicateMetric",
          Json_encode.object_({
                hd: [
                  "fromMetricId",
                  match$108[0]
                ],
                tl: {
                  hd: [
                    "toMetricId",
                    match$108[1]
                  ],
                  tl: {
                    hd: [
                      "metricItems",
                      Json_encode.list(printMetricItem, match$108[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventToMetric") {
      var match$109 = action.VAL;
      tmp = {
        hd: [
          "AddEventToMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$109[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$109[2]
                  ],
                  tl: {
                    hd: [
                      "id",
                      match$109[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventVariantTriggerSource") {
      var match$110 = action.VAL;
      var variantIdentifier$15 = match$110[0];
      tmp = {
        hd: [
          "AddEventVariantTriggerSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$15.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$15.variantId
                  ],
                  tl: {
                    hd: [
                      "triggerId",
                      match$110[1]
                    ],
                    tl: {
                      hd: [
                        "sourceId",
                        match$110[2]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderEventsInMetric") {
      var match$111 = action.VAL;
      tmp = {
        hd: [
          "ReorderEventsInMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$111[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$111[1]
                  ],
                  tl: {
                    hd: [
                      "maybePredecessorId",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), match$111[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateDestination") {
      tmp = {
        hd: [
          "CreateDestination",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateNestedPropertyPinnedValue") {
      var match$112 = action.VAL;
      tmp = {
        hd: [
          "UpdateNestedPropertyPinnedValue",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$112[0]
                ],
                tl: {
                  hd: [
                    "nestedPropertyId",
                    match$112[1]
                  ],
                  tl: {
                    hd: [
                      "pinnedValue",
                      printLiteral(match$112[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddMetricToGoal") {
      var match$113 = action.VAL;
      tmp = {
        hd: [
          "AddMetricToGoal",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$113[1]
                ],
                tl: {
                  hd: [
                    "goalId",
                    match$113[0]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventSpecificPropertyValueForAllEvents") {
      var match$114 = action.VAL;
      tmp = {
        hd: [
          "AddEventSpecificPropertyValueForAllEvents",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$114[0]
                ],
                tl: {
                  hd: [
                    "literal",
                    printLiteral(match$114[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ExcludeDestinationFromEventSource") {
      var match$115 = action.VAL;
      tmp = {
        hd: [
          "ExcludeDestinationFromEventSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$115[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$115[1]
                  ],
                  tl: {
                    hd: [
                      "destinationId",
                      match$115[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReviewerAdded") {
      var match$116 = action.VAL;
      tmp = {
        hd: [
          "ReviewerAdded",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$116[0]
                ],
                tl: {
                  hd: [
                    "addedByUserId",
                    match$116[1]
                  ],
                  tl: {
                    hd: [
                      "userId",
                      match$116[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteDestination") {
      tmp = {
        hd: [
          "DeleteDestination",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "GenerateWebhookSecret") {
      tmp = {
        hd: [
          "GenerateWebhookSecret",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyGroupDescription") {
      var match$117 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyGroupDescription",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$117[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$117[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ExcludeEventFromSource") {
      var match$118 = action.VAL;
      tmp = {
        hd: [
          "ExcludeEventFromSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$118[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$118[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricGroupByV2") {
      var match$119 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricGroupByV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$119[0]
                ],
                tl: {
                  hd: [
                    "metricItemId",
                    match$119[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$119[2]
                    ],
                    tl: {
                      hd: [
                        "id",
                        match$119[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateTriggerDescription") {
      var match$120 = action.VAL;
      tmp = {
        hd: [
          "UpdateTriggerDescription",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$120[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$120[1]
                  ],
                  tl: {
                    hd: [
                      "description",
                      match$120[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyToWhitelist") {
      var match$121 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyToWhitelist",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$121[0]
                ],
                tl: {
                  hd: [
                    "groupId",
                    match$121[1]
                  ],
                  tl: {
                    hd: [
                      "analyticsTool",
                      AvoConfigTypes.analyticsToolToJs(match$121[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources") {
      tmp = {
        hd: [
          "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CopyChangesToBranch") {
      var match$122 = action.VAL;
      tmp = {
        hd: [
          "CopyChangesToBranch",
          Json_encode.object_({
                hd: [
                  "fromBranchId",
                  match$122[0]
                ],
                tl: {
                  hd: [
                    "fromBranchName",
                    match$122[1]
                  ],
                  tl: {
                    hd: [
                      "toBranchId",
                      match$122[2]
                    ],
                    tl: {
                      hd: [
                        "toBranchName",
                        match$122[3]
                      ],
                      tl: {
                        hd: [
                          "actions",
                          Json_encode.list(printAction, match$122[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IncludeDestinationInSourceV2") {
      var match$123 = action.VAL;
      tmp = {
        hd: [
          "IncludeDestinationInSourceV2",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$123[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$123[1]
                  ],
                  tl: {
                    hd: [
                      "eventIds",
                      Json_encode.list((function (prim) {
                              return prim;
                            }), match$123[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateIntegration") {
      tmp = {
        hd: [
          "CreateIntegration",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationAnalyticsTool") {
      var match$124 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationAnalyticsTool",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$124[0]
                ],
                tl: {
                  hd: [
                    "analyticsTool",
                    AvoConfigTypes.analyticsToolToJs(match$124[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetMetricGroupByV2") {
      var match$125 = action.VAL;
      tmp = {
        hd: [
          "SetMetricGroupByV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$125[0]
                ],
                tl: {
                  hd: [
                    "metricItemId",
                    match$125[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$125[2]
                    ],
                    tl: {
                      hd: [
                        "propertyId",
                        match$125[4]
                      ],
                      tl: {
                        hd: [
                          "id",
                          match$125[3]
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyFromGroup") {
      var match$126 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyFromGroup",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$126[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$126[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventToGoal") {
      var match$127 = action.VAL;
      tmp = {
        hd: [
          "AddEventToGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$127[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$127[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SubscriptionUpdated") {
      var match$128 = action.VAL;
      tmp = {
        hd: [
          "SubscriptionUpdated",
          Json_encode.object_({
                hd: [
                  "paymentSource",
                  match$128[0]
                ],
                tl: {
                  hd: [
                    "subscription",
                    match$128[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDomainMemberRole") {
      var match$129 = action.VAL;
      tmp = {
        hd: [
          "UpdateDomainMemberRole",
          Json_encode.object_({
                hd: [
                  "id",
                  match$129[0]
                ],
                tl: {
                  hd: [
                    "memberId",
                    match$129[1]
                  ],
                  tl: {
                    hd: [
                      "role",
                      Domain__Types.Member.roleToJs(match$129[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SubscriptionCancelled") {
      var match$130 = action.VAL;
      tmp = {
        hd: [
          "SubscriptionCancelled",
          Json_encode.object_({
                hd: [
                  "paymentSource",
                  match$130[0]
                ],
                tl: {
                  hd: [
                    "subscription",
                    match$130[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddTriggerSource") {
      var match$131 = action.VAL;
      tmp = {
        hd: [
          "AddTriggerSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$131[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$131[1]
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      match$131[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "IncludeDestinationInEventSource") {
      var match$132 = action.VAL;
      tmp = {
        hd: [
          "IncludeDestinationInEventSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$132[0]
                ],
                tl: {
                  hd: [
                    "sourceId",
                    match$132[1]
                  ],
                  tl: {
                    hd: [
                      "destinationId",
                      match$132[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PushGlobalRequirementsToTrackingPlan") {
      var match$133 = action.VAL;
      tmp = {
        hd: [
          "PushGlobalRequirementsToTrackingPlan",
          Json_encode.object_({
                hd: [
                  "globalSchemaId",
                  match$133[0]
                ],
                tl: {
                  hd: [
                    "globalBranchId",
                    match$133[1]
                  ],
                  tl: {
                    hd: [
                      "actions",
                      Json_encode.list(printAction, match$133[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddGroupTypeToLogEvent") {
      var match$134 = action.VAL;
      tmp = {
        hd: [
          "AddGroupTypeToLogEvent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$134[0]
                ],
                tl: {
                  hd: [
                    "groupTypeId",
                    match$134[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyPinnedValue") {
      var match$135 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyPinnedValue",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$135[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$135[1]
                  ],
                  tl: {
                    hd: [
                      "pinnedValue",
                      printLiteral(match$135[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PublishIntegration") {
      var match$136 = action.VAL;
      tmp = {
        hd: [
          "PublishIntegration",
          Json_encode.object_(Belt_List.concat({
                    hd: [
                      "integrationId",
                      match$136[0]
                    ],
                    tl: {
                      hd: [
                        "success",
                        match$136[1]
                      ],
                      tl: {
                        hd: [
                          "warnings",
                          Json_encode.array((function (prim) {
                                  return prim;
                                }), match$136[2])
                        ],
                        tl: {
                          hd: [
                            "messages",
                            Json_encode.array((function (prim) {
                                    return prim;
                                  }), match$136[3])
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }, Belt_Option.mapWithDefault(match$136[4], /* [] */0, (function (successStats) {
                          return {
                                  hd: [
                                    "successStats",
                                    printPublishingSuccessStats(successStats)
                                  ],
                                  tl: /* [] */0
                                };
                        }))))
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveUserFromGroup") {
      var match$137 = action.VAL;
      tmp = {
        hd: [
          "RemoveUserFromGroup",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$137[0]
                ],
                tl: {
                  hd: [
                    "groupTypeId",
                    match$137[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyValidation") {
      var match$138 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyValidation",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$138[0]
                ],
                tl: {
                  hd: [
                    "validation",
                    printValidation(match$138[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddDomainMembers") {
      var match$139 = action.VAL;
      tmp = {
        hd: [
          "AddDomainMembers",
          Json_encode.object_({
                hd: [
                  "id",
                  match$139[0]
                ],
                tl: {
                  hd: [
                    "domainMembers",
                    Json_encode.array((function (param) {
                            return Json_encode.object_({
                                        hd: [
                                          "memberId",
                                          param[0]
                                        ],
                                        tl: {
                                          hd: [
                                            "role",
                                            Domain__Types.Member.roleToJs(param[1])
                                          ],
                                          tl: /* [] */0
                                        }
                                      });
                          }), match$139[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateGoalName") {
      var match$140 = action.VAL;
      tmp = {
        hd: [
          "UpdateGoalName",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$140[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$140[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateDomain") {
      var match$141 = action.VAL;
      tmp = {
        hd: [
          "CreateDomain",
          Json_encode.object_({
                hd: [
                  "id",
                  match$141[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$141[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDomainName") {
      var match$142 = action.VAL;
      tmp = {
        hd: [
          "UpdateDomainName",
          Json_encode.object_({
                hd: [
                  "id",
                  match$142[0]
                ],
                tl: {
                  hd: [
                    "previousName",
                    match$142[1]
                  ],
                  tl: {
                    hd: [
                      "newName",
                      match$142[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DuplicateEvent") {
      var match$143 = action.VAL;
      var match$144 = match$143[0];
      tmp = {
        hd: [
          "DuplicateEvent",
          Json_encode.object_({
                hd: [
                  "eventIdArray",
                  Json_encode.object_({
                        hd: [
                          "fromEventId",
                          match$144[0]
                        ],
                        tl: {
                          hd: [
                            "toEventId",
                            match$144[1]
                          ],
                          tl: /* [] */0
                        }
                      })
                ],
                tl: {
                  hd: [
                    "triggerIdArrays",
                    Json_encode.array(printTriggerIdTuple, match$143[1])
                  ],
                  tl: {
                    hd: [
                      "ruleIdArrays",
                      Json_encode.array(printRuleIdTuple, match$143[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateEventVariant") {
      var match$145 = action.VAL;
      var variantIdentifier$16 = match$145[0];
      tmp = {
        hd: [
          "CreateEventVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$16.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$16.variantId
                  ],
                  tl: {
                    hd: [
                      "variantName",
                      match$145[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyValidation") {
      var match$146 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyValidation",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$146[0]
                ],
                tl: {
                  hd: [
                    "validation",
                    printValidation(match$146[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PullMaster") {
      var match$147 = action.VAL;
      tmp = {
        hd: [
          "PullMaster",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$147[0]
                ],
                tl: {
                  hd: [
                    "masterActionId",
                    match$147[1]
                  ],
                  tl: {
                    hd: [
                      "masterActionTimestamp",
                      match$147[2]
                    ],
                    tl: {
                      hd: [
                        "actions",
                        Json_encode.list(printAction, match$147[3])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddEventSpecificPropertyValueForSomeEvents") {
      var match$148 = action.VAL;
      tmp = {
        hd: [
          "AddEventSpecificPropertyValueForSomeEvents",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$148[0]
                ],
                tl: {
                  hd: [
                    "literal",
                    printLiteral(match$148[1])
                  ],
                  tl: {
                    hd: [
                      "disallowedEvents",
                      printDisallowedEvents(match$148[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateMetricName") {
      var match$149 = action.VAL;
      tmp = {
        hd: [
          "UpdateMetricName",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$149[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$149[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveNestedPropertyPinnedValue") {
      var match$150 = action.VAL;
      tmp = {
        hd: [
          "RemoveNestedPropertyPinnedValue",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$150[0]
                ],
                tl: {
                  hd: [
                    "nestedPropertyId",
                    match$150[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SetMetricWhere") {
      var match$151 = action.VAL;
      tmp = {
        hd: [
          "SetMetricWhere",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$151[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$151[1]
                  ],
                  tl: {
                    hd: [
                      "id",
                      match$151[2]
                    ],
                    tl: {
                      hd: [
                        "propertyId",
                        match$151[3]
                      ],
                      tl: {
                        hd: [
                          "binOp",
                          printBinOp(match$151[4])
                        ],
                        tl: {
                          hd: [
                            "literals",
                            Json_encode.list(printLiteral, match$151[5])
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateSource") {
      tmp = {
        hd: [
          "CreateSource",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourceSegmentIntegration") {
      var match$152 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourceSegmentIntegration",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$152[0]
                ],
                tl: {
                  hd: [
                    "segmentIntegration",
                    match$152[1]
                  ],
                  tl: {
                    hd: [
                      "isActive",
                      match$152[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ArchiveDomain") {
      tmp = {
        hd: [
          "ArchiveDomain",
          Json_encode.object_({
                hd: [
                  "id",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventType") {
      var match$153 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventType",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$153[0]
                ],
                tl: {
                  hd: [
                    "type",
                    TrackingPlanModel.eventTypeToJs(match$153[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventFromGoal") {
      var match$154 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventFromGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$154[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$154[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ExcludeDestinationFromSource") {
      var match$155 = action.VAL;
      tmp = {
        hd: [
          "ExcludeDestinationFromSource",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$155[0]
                ],
                tl: {
                  hd: [
                    "destinationId",
                    match$155[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderGoalsV2") {
      var match$156 = action.VAL;
      tmp = {
        hd: [
          "ReorderGoalsV2",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$156[0]
                ],
                tl: {
                  hd: [
                    "maybePredecessorId",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), match$156[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateMetricType") {
      var match$157 = action.VAL;
      tmp = {
        hd: [
          "UpdateMetricType",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$157[0]
                ],
                tl: {
                  hd: [
                    "type",
                    TrackingPlanModel.metricTypeToJs(match$157[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ClearPropertyExcludedSources") {
      tmp = {
        hd: [
          "ClearPropertyExcludedSources",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationName") {
      var match$158 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationName",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$158[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$158[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreatePropertyGroup") {
      var match$159 = action.VAL;
      tmp = {
        hd: [
          "CreatePropertyGroup",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$159[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$159[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyGroupName") {
      var match$160 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyGroupName",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$160[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$160[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddSourceConfig") {
      var match$161 = action.VAL;
      tmp = {
        hd: [
          "AddSourceConfig",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$161[0]
                ],
                tl: {
                  hd: [
                    "sourceConfig",
                    printSourceConfig(match$161[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "SubscriptionStarted") {
      var match$162 = action.VAL;
      tmp = {
        hd: [
          "SubscriptionStarted",
          Json_encode.object_({
                hd: [
                  "paymentSource",
                  match$162[0]
                ],
                tl: {
                  hd: [
                    "subscription",
                    match$162[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventUniqueName") {
      var match$163 = action.VAL;
      tmp = {
        hd: [
          "UpdateEventUniqueName",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$163[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$163[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreatePublicBranchImplementation") {
      tmp = {
        hd: [
          "CreatePublicBranchImplementation",
          Json_encode.object_({
                hd: [
                  "shareId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeprecatedRemovePropertyValidationMinOrMax") {
      var match$164 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyValidationMinOrMax",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$164[0]
                ],
                tl: {
                  hd: [
                    "validationIndex",
                    match$164[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyFromEventVariant") {
      var match$165 = action.VAL;
      var variantIdentifier$17 = match$165[0];
      tmp = {
        hd: [
          "RemovePropertyFromEventVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$17.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$17.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$165[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationType") {
      var match$166 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationType",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$166[0]
                ],
                tl: {
                  hd: [
                    "type",
                    TrackingPlanModel.integrationTypeToJs(match$166[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricWhere") {
      var match$167 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricWhere",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$167[0]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$167[1]
                  ],
                  tl: {
                    hd: [
                      "id",
                      match$167[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeleteComment") {
      var match$168 = action.VAL;
      tmp = {
        hd: [
          "DeleteComment",
          Json_encode.object_({
                hd: [
                  "commentId",
                  match$168[1]
                ],
                tl: {
                  hd: [
                    "threadId",
                    match$168[0]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "PropertyComment") {
      var match$169 = action.VAL;
      tmp = {
        hd: [
          "PropertyComment",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$169[0]
                ],
                tl: {
                  hd: [
                    "commentId",
                    match$169[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventName") {
      var match$170 = action.VAL;
      tmp = {
        hd: [
          "UpdateEventName",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$170[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$170[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateInspectorIssue") {
      tmp = {
        hd: [
          "CreateInspectorIssue",
          printInspectorIssueMetadata(action.VAL)
        ],
        tl: /* [] */0
      };
    } else if (variant === "ResendInvite") {
      var match$171 = action.VAL;
      tmp = {
        hd: [
          "ResendInvite",
          Json_encode.object_({
                hd: [
                  "email",
                  match$171[0]
                ],
                tl: {
                  hd: [
                    "role",
                    match$171[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationFilters") {
      var match$172 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationFilters",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$172[0]
                ],
                tl: {
                  hd: [
                    "filters",
                    printIntegrationFilters(match$172[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateProperty") {
      var match$173 = action.VAL;
      tmp = {
        hd: [
          "CreateProperty",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$173[0]
                ],
                tl: {
                  hd: [
                    "name",
                    match$173[1]
                  ],
                  tl: {
                    hd: [
                      "sendAs",
                      printSendAs(match$173[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyOptionalWhenInObject") {
      var match$174 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyOptionalWhenInObject",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$174[0]
                ],
                tl: {
                  hd: [
                    "optional",
                    match$174[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveGroupTypeFromLogEvent") {
      var match$175 = action.VAL;
      tmp = {
        hd: [
          "RemoveGroupTypeFromLogEvent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$175[0]
                ],
                tl: {
                  hd: [
                    "groupTypeId",
                    match$175[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ClearPropertyRegexOverrideFromEventVariant") {
      var match$176 = action.VAL;
      var variantIdentifier$18 = match$176[0];
      tmp = {
        hd: [
          "ClearPropertyRegexOverrideFromEventVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$18.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$18.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$176[1]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyGroupFromEvent") {
      var match$177 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyGroupFromEvent",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$177[1]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$177[0]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateMetricDescription") {
      var match$178 = action.VAL;
      tmp = {
        hd: [
          "UpdateMetricDescription",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$178[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$178[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDomainDescription") {
      var match$179 = action.VAL;
      tmp = {
        hd: [
          "UpdateDomainDescription",
          Json_encode.object_({
                hd: [
                  "id",
                  match$179[0]
                ],
                tl: {
                  hd: [
                    "description",
                    match$179[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateLocalWorkspace") {
      tmp = {
        hd: [
          "CreateLocalWorkspace",
          Json_encode.object_({
                hd: [
                  "workspaceId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateGlobalRequirements") {
      tmp = {
        hd: [
          "UpdateGlobalRequirements",
          printGlobalRequirements(action.VAL)
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricFromGoal") {
      var match$180 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricFromGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$180[0]
                ],
                tl: {
                  hd: [
                    "metricId",
                    match$180[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateEventVariantTriggerContent") {
      var match$181 = action.VAL;
      var variantIdentifier$19 = match$181[0];
      tmp = {
        hd: [
          "UpdateEventVariantTriggerContent",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$19.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$19.variantId
                  ],
                  tl: {
                    hd: [
                      "triggerId",
                      match$181[1]
                    ],
                    tl: {
                      hd: [
                        "content",
                        Json_encode.nullable(printTriggerContent, match$181[2])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ConfigureInspectorValidation") {
      var match$182 = action.VAL;
      tmp = {
        hd: [
          "ConfigureInspectorValidation",
          Json_encode.object_({
                hd: [
                  "eventIds",
                  Json_encode.list((function (prim) {
                          return prim;
                        }), match$182[0])
                ],
                tl: {
                  hd: [
                    "sourceValidationMap",
                    printSourceValidationMap(match$182[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CodeGenerated") {
      var match$183 = action.VAL;
      tmp = {
        hd: [
          "CodeGenerated",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$183[0]
                ],
                tl: {
                  hd: [
                    "sourceIds",
                    Json_encode.list((function (prim) {
                            return prim;
                          }), match$183[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyToGroup") {
      var match$184 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyToGroup",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$184[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$184[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateSourcePlatform") {
      var match$185 = action.VAL;
      tmp = {
        hd: [
          "UpdateSourcePlatform",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$185[0]
                ],
                tl: {
                  hd: [
                    "platform",
                    AvoConfigTypes.devPlatformToJs(match$185[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationDevApiKey") {
      var match$186 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationDevApiKey",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$186[0]
                ],
                tl: {
                  hd: [
                    "key",
                    match$186[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ArchiveSavedView") {
      var match$187 = action.VAL;
      tmp = {
        hd: [
          "ArchiveSavedView",
          Json_encode.object_({
                hd: [
                  "savedViewId",
                  match$187[0]
                ],
                tl: {
                  hd: [
                    "savedViewName",
                    match$187[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateLibraryName") {
      var match$188 = action.VAL;
      tmp = {
        hd: [
          "UpdateLibraryName",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$188[0]
                ],
                tl: {
                  hd: [
                    "filename",
                    match$188[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CloseBranch") {
      var match$189 = action.VAL;
      tmp = {
        hd: [
          "CloseBranch",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$189[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$189[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventFromMetric") {
      var match$190 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventFromMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$190[0]
                ],
                tl: {
                  hd: [
                    "id",
                    match$190[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$190[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemovePropertyPinnedValue") {
      var match$191 = action.VAL;
      tmp = {
        hd: [
          "RemovePropertyPinnedValue",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$191[0]
                ],
                tl: {
                  hd: [
                    "propertyId",
                    match$191[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateDestinationProdApiKey") {
      var match$192 = action.VAL;
      tmp = {
        hd: [
          "UpdateDestinationProdApiKey",
          Json_encode.object_({
                hd: [
                  "destinationId",
                  match$192[0]
                ],
                tl: {
                  hd: [
                    "key",
                    match$192[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "BranchInit") {
      var match$193 = action.VAL;
      tmp = {
        hd: [
          "BranchInit",
          Json_encode.object_({
                hd: [
                  "branchId",
                  match$193[0]
                ],
                tl: {
                  hd: [
                    "branchName",
                    match$193[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AutoPublishIntegration") {
      var match$194 = action.VAL;
      tmp = {
        hd: [
          "AutoPublishIntegration",
          Json_encode.object_(Belt_List.concat({
                    hd: [
                      "integrationId",
                      match$194[0]
                    ],
                    tl: {
                      hd: [
                        "success",
                        match$194[1]
                      ],
                      tl: {
                        hd: [
                          "warnings",
                          Json_encode.array((function (prim) {
                                  return prim;
                                }), match$194[2])
                        ],
                        tl: {
                          hd: [
                            "messages",
                            Json_encode.array((function (prim) {
                                    return prim;
                                  }), match$194[3])
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }, Belt_Option.mapWithDefault(match$194[4], /* [] */0, (function (successStats) {
                          return {
                                  hd: [
                                    "successStats",
                                    printPublishingSuccessStats(successStats)
                                  ],
                                  tl: /* [] */0
                                };
                        }))))
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveEventTrigger") {
      var match$195 = action.VAL;
      tmp = {
        hd: [
          "RemoveEventTrigger",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$195[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$195[1]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ReorderGoals") {
      var match$196 = action.VAL;
      tmp = {
        hd: [
          "ReorderGoals",
          Json_encode.object_({
                hd: [
                  "goalId",
                  match$196[0]
                ],
                tl: {
                  hd: [
                    "maybePredecessorId",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), match$196[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "DeprecatedAddPropertyValidationMinOrMax") {
      var match$197 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyValidationMinOrMax",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$197[0]
                ],
                tl: {
                  hd: [
                    "validationType",
                    TrackingPlanModel.constraint_ToJs(match$197[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CreateServiceAccount") {
      tmp = {
        hd: [
          "CreateServiceAccount",
          Json_encode.object_({
                hd: [
                  "serviceAccountName",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "CollaboratorRemoved") {
      var match$198 = action.VAL;
      tmp = {
        hd: [
          "CollaboratorRemoved",
          Json_encode.object_({
                hd: [
                  "collaborationItem",
                  printCollaborationItem(match$198[0])
                ],
                tl: {
                  hd: [
                    "removedByUserId",
                    match$198[1]
                  ],
                  tl: {
                    hd: [
                      "userId",
                      match$198[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveGoal") {
      tmp = {
        hd: [
          "RemoveGoal",
          Json_encode.object_({
                hd: [
                  "goalId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMetricWhereV2") {
      var match$199 = action.VAL;
      tmp = {
        hd: [
          "RemoveMetricWhereV2",
          Json_encode.object_({
                hd: [
                  "metricId",
                  match$199[0]
                ],
                tl: {
                  hd: [
                    "metricItemId",
                    match$199[1]
                  ],
                  tl: {
                    hd: [
                      "eventId",
                      match$199[2]
                    ],
                    tl: {
                      hd: [
                        "id",
                        match$199[3]
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveTriggerSource") {
      var match$200 = action.VAL;
      tmp = {
        hd: [
          "RemoveTriggerSource",
          Json_encode.object_({
                hd: [
                  "eventId",
                  match$200[0]
                ],
                tl: {
                  hd: [
                    "triggerId",
                    match$200[1]
                  ],
                  tl: {
                    hd: [
                      "sourceId",
                      match$200[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyAbsence") {
      var match$201 = action.VAL;
      tmp = {
        hd: [
          "UpdatePropertyAbsence",
          Json_encode.object_({
                hd: [
                  "propertyId",
                  match$201[0]
                ],
                tl: {
                  hd: [
                    "propertyAbsenceUpdateType",
                    printPropertyAbsenceUpdateType(match$201[1])
                  ],
                  tl: {
                    hd: [
                      "maybeEventSourceAbsence",
                      Json_encode.nullable(printEventSourceAbsence, match$201[2])
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "Unarchive") {
      tmp = {
        hd: [
          "Unarchive",
          printItem(action.VAL)
        ],
        tl: /* [] */0
      };
    } else if (variant === "CompletedImport") {
      var match$202 = action.VAL;
      tmp = {
        hd: [
          "CompletedImport",
          Json_encode.object_({
                hd: [
                  "eventCount",
                  match$202[0]
                ],
                tl: {
                  hd: [
                    "propertyCount",
                    match$202[1]
                  ],
                  tl: {
                    hd: [
                      "sourceCount",
                      match$202[2]
                    ],
                    tl: {
                      hd: [
                        "newStakeholderDomainCount",
                        match$202[3]
                      ],
                      tl: {
                        hd: [
                          "importLocation",
                          TrackingPlanModel.importLocationToJs(match$202[4])
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "ClearEventVariantSpecificPropertyValueOverride") {
      var match$203 = action.VAL;
      var variantIdentifier$20 = match$203[0];
      tmp = {
        hd: [
          "ClearEventVariantSpecificPropertyValueOverride",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$20.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$20.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$203[1]
                    ],
                    tl: {
                      hd: [
                        "literal",
                        printLiteral(match$203[2])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateIntegrationDestinationIds") {
      var match$204 = action.VAL;
      tmp = {
        hd: [
          "UpdateIntegrationDestinationIds",
          Json_encode.object_({
                hd: [
                  "integrationId",
                  match$204[0]
                ],
                tl: {
                  hd: [
                    "destinationIds",
                    Json_encode.list((function (prim) {
                            return prim;
                          }), match$204[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveMember") {
      var match$205 = action.VAL;
      tmp = {
        hd: [
          "RemoveMember",
          Json_encode.object_({
                hd: [
                  "userId",
                  match$205[0]
                ],
                tl: {
                  hd: [
                    "email",
                    match$205[1]
                  ],
                  tl: {
                    hd: [
                      "role",
                      match$205[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdatePropertyPinnedValueOnEventVariant") {
      var match$206 = action.VAL;
      var variantIdentifier$21 = match$206[0];
      tmp = {
        hd: [
          "UpdatePropertyPinnedValueOnEventVariant",
          Json_encode.object_({
                hd: [
                  "eventId",
                  variantIdentifier$21.baseEventId
                ],
                tl: {
                  hd: [
                    "variantId",
                    variantIdentifier$21.variantId
                  ],
                  tl: {
                    hd: [
                      "propertyId",
                      match$206[1]
                    ],
                    tl: {
                      hd: [
                        "literal",
                        printLiteral(match$206[2])
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveSourceConfig") {
      var match$207 = action.VAL;
      tmp = {
        hd: [
          "RemoveSourceConfig",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$207[0]
                ],
                tl: {
                  hd: [
                    "sourceConfig",
                    printSourceConfig(match$207[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveDomainMembers") {
      var match$208 = action.VAL;
      tmp = {
        hd: [
          "RemoveDomainMembers",
          Json_encode.object_({
                hd: [
                  "id",
                  match$208[0]
                ],
                tl: {
                  hd: [
                    "domainMemberIds",
                    Json_encode.array((function (prim) {
                            return prim;
                          }), match$208[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddSourceSegmentIntegration") {
      var match$209 = action.VAL;
      tmp = {
        hd: [
          "AddSourceSegmentIntegration",
          Json_encode.object_({
                hd: [
                  "sourceId",
                  match$209[0]
                ],
                tl: {
                  hd: [
                    "segmentIntegration",
                    match$209[1]
                  ],
                  tl: {
                    hd: [
                      "isActive",
                      match$209[2]
                    ],
                    tl: /* [] */0
                  }
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "RemoveRule") {
      var match$210 = action.VAL;
      tmp = {
        hd: [
          "RemoveRule",
          Json_encode.object_({
                hd: [
                  "ruleId",
                  match$210[0]
                ],
                tl: {
                  hd: [
                    "item",
                    printRuleItem(match$210[1])
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else if (variant === "UpdateTrackingPlanAuditConfig") {
      var match$211 = action.VAL;
      tmp = {
        hd: [
          "UpdateTrackingPlanAuditConfig",
          Json_encode.object_(Belt_List.concatMany([
                    {
                      hd: [
                        "configItem",
                        printTrackingPlanAuditConfigItem(match$211[0])
                      ],
                      tl: /* [] */0
                    },
                    Belt_Option.mapWithDefault(match$211[1], /* [] */0, (function (ruleSeverity) {
                            return {
                                    hd: [
                                      "ruleSeverity",
                                      printTrackingPlanAuditRuleSeverity(ruleSeverity)
                                    ],
                                    tl: /* [] */0
                                  };
                          })),
                    Belt_Option.mapWithDefault(match$211[2], /* [] */0, (function (pointOfBlock) {
                            return {
                                    hd: [
                                      "pointOfBlock",
                                      printTrackingPlanAuditPointOfBlock(pointOfBlock)
                                    ],
                                    tl: /* [] */0
                                  };
                          }))
                  ]))
        ],
        tl: /* [] */0
      };
    } else if (variant === "AddPropertyGroupToEvent") {
      var match$212 = action.VAL;
      tmp = {
        hd: [
          "AddPropertyGroupToEvent",
          Json_encode.object_({
                hd: [
                  "groupId",
                  match$212[1]
                ],
                tl: {
                  hd: [
                    "eventId",
                    match$212[0]
                  ],
                  tl: /* [] */0
                }
              })
        ],
        tl: /* [] */0
      };
    } else {
      tmp = {
        hd: [
          "RemoveMetric",
          Json_encode.object_({
                hd: [
                  "metricId",
                  action.VAL
                ],
                tl: /* [] */0
              })
        ],
        tl: /* [] */0
      };
    }
  } else {
    tmp = action === "StartedImport" ? ({
          hd: [
            "StartedImport",
            Json_encode.object_(/* [] */0)
          ],
          tl: /* [] */0
        }) : (
        action === "MigratePropertyOptionalAndExcludedSourcesToAbsence" ? ({
              hd: [
                "MigratePropertyOptionalAndExcludedSourcesToAbsence",
                Json_encode.object_(/* [] */0)
              ],
              tl: /* [] */0
            }) : (
            action === "SchemaAccessed" ? ({
                  hd: [
                    "SchemaAccessed",
                    Json_encode.object_(/* [] */0)
                  ],
                  tl: /* [] */0
                }) : (
                action === "MigrateToEventSpecificAllowedPropertyValues" ? ({
                      hd: [
                        "MigrateToEventSpecificAllowedPropertyValues",
                        Json_encode.object_(/* [] */0)
                      ],
                      tl: /* [] */0
                    }) : (
                    action === "MigrateSkipWebFnTagToIncludeInCodegen" ? ({
                          hd: [
                            "MigrateSkipWebFnTagToIncludeInCodegen",
                            Json_encode.object_(/* [] */0)
                          ],
                          tl: /* [] */0
                        }) : (
                        action === "MigrateSourceDestinationMapping" ? ({
                              hd: [
                                "MigrateSourceDestinationMapping",
                                Json_encode.object_(/* [] */0)
                              ],
                              tl: /* [] */0
                            }) : (
                            action === "ResetTrackingPlan" ? ({
                                  hd: [
                                    "ResetTrackingPlan",
                                    Json_encode.object_(/* [] */0)
                                  ],
                                  tl: /* [] */0
                                }) : ({
                                  hd: [
                                    "MigrateRemoveUnarchivedItemsFromArchive",
                                    Json_encode.object_(/* [] */0)
                                  ],
                                  tl: /* [] */0
                                })
                          )
                      )
                  )
              )
          )
      );
  }
  return Json_encode.object_(tmp);
}

var printMetricType = TrackingPlanModel.metricTypeToJs;

export {
  printLiteral ,
  printBinOp ,
  printExp ,
  printPropertyRef ,
  encodeVariantAbsenceOverride ,
  encodeVariantSourceOverride ,
  printInspectorIssueMetadata ,
  printPropertyRegexValidationRule ,
  printPropertyRegexValidation ,
  printCoreType ,
  printValueType ,
  printValue ,
  printObjectItem ,
  printValidation ,
  printSendAs ,
  printItem ,
  printCollaborationItem ,
  printRuleItem ,
  printMappingDestination ,
  printNameMapping ,
  printMappingLocalWorkspace ,
  printWorkspaceNameMapping ,
  printSegmentIntegrationOption ,
  printSegmentIntegrationOptionRule ,
  printRuleDefinition ,
  printRule ,
  printRules ,
  printRuleIdTuple ,
  printPropertyAbsenceUpdateType ,
  printEventSourceAbsence ,
  printPropertyAbsenceEventSourceMap ,
  printPropertyAbsenceEventMap ,
  printPropertyAbsence ,
  printGlobalRequirementsMetadataForItem ,
  printDisallowedState ,
  printDisallowedEvents ,
  encodeVariantAllowedPropertyValues ,
  encodeVariantPropertyOverride ,
  printEventSpecificPropertyValues ,
  printProperty ,
  printProperties ,
  printPropertyBundle ,
  printPropertyBundles ,
  printPropertyBundleRef ,
  printPropertyBundleRefs ,
  printSourceDestinationMap ,
  printPropertyWhitelist ,
  printSourceValidation ,
  printSourceValidationMap ,
  printIncludedSource ,
  printImageMetadata ,
  printFigmaMetadata ,
  printTriggerContent ,
  printTriggerSources ,
  printEventTrigger ,
  printEventTriggers ,
  printEventSourceAbsenceOverrideType ,
  printEventVariant ,
  printEventVariants ,
  printTriggerIdTuple ,
  printEvent ,
  printType ,
  printEvents ,
  printMigrations ,
  printSourceConfig ,
  printSourceDestinationMode ,
  printSourceDestinationRef ,
  printSource ,
  printSources ,
  printOtherApiKey ,
  printDestination ,
  printGroupType ,
  printDestinations ,
  printGroupTypes ,
  printGoal ,
  printGoals ,
  printMetricType ,
  printMetricItem ,
  printMetric ,
  printMetrics ,
  printIntegrationConfig ,
  printIntegrationFilters ,
  printIntegration ,
  printIntegrations ,
  printArchive ,
  printBranch ,
  printBranches ,
  printBranchPointer ,
  printPublishingSuccessStats ,
  printGlobalRequirementsMetadata ,
  printGlobalRequirements ,
  printTrackingPlanAuditConfigItem ,
  printTrackingPlanAuditRuleSeverity ,
  printTrackingPlanAuditPointOfBlock ,
  printModel ,
  printAvoJson ,
  printModelString ,
  printAction ,
}
/* TrackingPlanModel Not a pure module */
