// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

function toDate(serverTimestamp) {
  return Js_null_undefined.bind(serverTimestamp, (function (ts) {
                return ts.toDate();
              }));
}

function toDateOption(serverTimestamp) {
  return Caml_option.nullable_to_opt(Js_null_undefined.bind(serverTimestamp, (function (ts) {
                    return ts.toDate();
                  })));
}

var _map = {"Admin":"Admin","Editor":"Editor","Viewer":"Viewer","NoAccess":"NoAccess","CommentOnly":"Comment Only","BillingOnly":"Billing Only","CodegenAccess":"CodegenAccess"};

var _revMap = {"Admin":"Admin","Editor":"Editor","Viewer":"Viewer","NoAccess":"NoAccess","Comment Only":"CommentOnly","Billing Only":"BillingOnly","CodegenAccess":"CodegenAccess"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

function cmp(a, b) {
  return $$String.compare(_map[a], _map[b]);
}

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

var Role = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  cmp: cmp,
  Comparator: Comparator
};

var Context = {};

var InspectorKey = {};

function getId(prim) {
  return prim.id;
}

function getCreatedAt(prim) {
  return prim.createdAt;
}

var Action = {
  name: "action",
  getId: getId,
  getCreatedAt: getCreatedAt
};

var OnboardingTemplate = {};

var OnboardingV2 = {};

function getId$1(schema) {
  return schema.id;
}

var Schema = {
  name: "schemas",
  getId: getId$1
};

function getId$2(trial) {
  return trial.id;
}

var Trial = {
  name: "trials",
  getId: getId$2
};

function getId$3(prim) {
  return prim.id;
}

var Version = {
  name: "version",
  getId: getId$3
};

var Chunk = {};

var Picture = {};

var ColumnSettings = {};

var $$default = {
  showIssuesOnly: false
};

var BranchAuditSettings = {
  $$default: $$default
};

var _map$1 = {"liveData":"liveData","manual":"manual"};

function onboardingChoiceToJs(param) {
  return param;
}

function onboardingChoiceFromJs(param) {
  return _map$1[param];
}

function decode(maybeState) {
  if (maybeState == null) {
    return Js_null_undefined.fromOption(undefined);
  } else {
    return {
            onboardingChoice: maybeState.onboardingChoice,
            completed: maybeState.completed,
            dismissed: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(maybeState.dismissed), false),
            complete: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(maybeState.complete), false)
          };
  }
}

var GettingStarted = {
  onboardingChoiceToJs: onboardingChoiceToJs,
  onboardingChoiceFromJs: onboardingChoiceFromJs,
  decode: decode
};

var Domain = {};

var SsoProviderBySchema = {};

var Filter = {};

function getId$4(prim) {
  return prim.id;
}

function enrichMemberWithEmail(email, member) {
  var tmp = {
    id: member.id,
    role: member.role,
    type_: member.type_,
    createdAt: member.createdAt
  };
  var tmp$1 = Caml_option.undefined_to_opt(member.jobFunction);
  if (tmp$1 !== undefined) {
    tmp.jobFunction = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Caml_option.undefined_to_opt(member.filter);
  if (tmp$2 !== undefined) {
    tmp.filter = Caml_option.valFromOption(tmp$2);
  }
  if (email !== undefined) {
    tmp.email = Caml_option.valFromOption(email);
  }
  return tmp;
}

function enrichMembersWithEmail(members, users) {
  return Belt_List.map(members, (function (member) {
                var email = Belt_Option.flatMap(Belt_Array.getBy(users, (function (user) {
                            return user.id === member.id;
                          })), (function (user) {
                        return Caml_option.nullable_to_opt(user.email);
                      }));
                return enrichMemberWithEmail(email, member);
              }));
}

var Member = {
  name: "member",
  getId: getId$4,
  enrichMemberWithEmail: enrichMemberWithEmail,
  enrichMembersWithEmail: enrichMembersWithEmail
};

function getId$5(prim) {
  return prim.id;
}

var Invite = {
  name: "invite",
  getId: getId$5
};

function getId$6(prim) {
  return prim.id;
}

var SchemaInvite = {
  name: "schemaInvite",
  getId: getId$6
};

function getId$7(prim) {
  return prim.id;
}

function decode$1(firebaseUserSchema) {
  return {
          id: firebaseUserSchema.id,
          lastUsedAt: firebaseUserSchema.lastUsedAt,
          onboardingChecklist: decode(firebaseUserSchema.onboardingChecklist)
        };
}

var UserSchema = {
  name: "schemas",
  getId: getId$7,
  decode: decode$1
};

var Payment = {
  name: "payment"
};

var Config = {
  name: "config"
};

function getId$8(prim) {
  return prim.id;
}

function getCreatedAt$1(prim) {
  return prim.createdAt;
}

var $$Comment = {
  name: "comment",
  getId: getId$8,
  getCreatedAt: getCreatedAt$1
};

function getId$9(prim) {
  return prim.id;
}

function getCreatedAt$2(prim) {
  return prim.createdAt;
}

var Activity = {
  name: "activity",
  getId: getId$9,
  getCreatedAt: getCreatedAt$2
};

var _map$2 = {"prod":"prod","dev":"dev"};

var _revMap$1 = {"prod":"prod","dev":"dev"};

function envToJs(param) {
  return _map$2[param];
}

function envFromJs(param) {
  return _revMap$1[param];
}

function getId$10(invocation) {
  return invocation.eventId + invocation.sourceId + invocation.hash;
}

var Invocation = {
  getId: getId$10,
  name: "invocation"
};

function getId$11(invocation) {
  return invocation.type_ + invocation.sourceId;
}

var InvocationMeta = {
  getId: getId$11,
  name: "meta"
};

var EmailVerificationTrigger = {};

var CreateContact = {};

var PullResponse = {};

var ExportResponse = {};

var CollaborationItem = {};

var _map$3 = {"Comment":"Comment","AddCollaborator":"AddCollaborator","AtMention":"AtMention","NewBranch":"NewBranch","CloseBranch":"CloseBranch","MergeBranch":"MergeBranch","SetBranchStatusToDraft":"SetBranchStatusToDraft","SetBranchStatusToReadyForReview":"SetBranchStatusToReadyForReview","SetBranchStatusToApproved":"SetBranchStatusToApproved","SetBranchStatusToChangesRequested":"SetBranchStatusToChangesRequested"};

function tToJs$1(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$3[param];
}

var CollaborationTrigger = {
  tToJs: tToJs$1,
  tFromJs: tFromJs$1
};

function getId$12(prim) {
  return prim.id;
}

var Collaborator = {
  getId: getId$12
};

function getId$13(prim) {
  return prim.id;
}

var OAuthRequest = {
  getId: getId$13
};

function printEventType(_type) {
  if (typeof _type === "object") {
    return "saved-view:" + _type._0;
  }
  switch (_type) {
    case "InspectorIssues" :
        return "inspector-issues";
    case "WorkspaceNotifications" :
        return "workspace-notifications";
    case "WorkspaceReminders" :
        return "workspace-reminders";
    
  }
}

function parseEventType(_type) {
  switch (_type) {
    case "inspector-issues" :
        return "InspectorIssues";
    case "workspace-notifications" :
        return "WorkspaceNotifications";
    case "workspace-reminders" :
        return "WorkspaceReminders";
    default:
      return {
              TAG: "SavedView",
              _0: $$String.sub(_type, 11, _type.length - 11 | 0)
            };
  }
}

var NotificationChannel = {
  printEventType: printEventType,
  parseEventType: parseEventType
};

function getId$14(prim) {
  return prim.id;
}

var NotificationChannelAuth = {
  getId: getId$14
};

function encode(t) {
  return Json_encode.object_({
              hd: [
                "type",
                typeof t === "object" ? (
                    t.NAME === "Annual" ? "Annual" : "Monthly"
                  ) : "Unlimited"
              ],
              tl: {
                hd: [
                  "value",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), typeof t === "object" ? t.VAL : undefined)
                ],
                tl: /* [] */0
              }
            });
}

function decode$2(json) {
  var other = Json_decode.field("type", Json_decode.string, json);
  switch (other) {
    case "Annual" :
        return {
                NAME: "Annual",
                VAL: Json_decode.field("value", Json_decode.$$float, json)
              };
    case "Monthly" :
        return {
                NAME: "Monthly",
                VAL: Json_decode.field("value", Json_decode.$$float, json)
              };
    case "Unlimited" :
        return "Unlimited";
    default:
      return Pervasives.failwith("Unexpected UsageLimit.Usage type: " + other);
  }
}

var Limit = {
  encode: encode,
  decode: decode$2
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "type",
                t.NAME === "Annual" ? "Annual" : "Monthly"
              ],
              tl: {
                hd: [
                  "value",
                  t.VAL
                ],
                tl: /* [] */0
              }
            });
}

function decode$3(json) {
  var other = Json_decode.field("type", Json_decode.string, json);
  switch (other) {
    case "Annual" :
        return {
                NAME: "Annual",
                VAL: Json_decode.field("value", Json_decode.$$float, json)
              };
    case "Monthly" :
        return {
                NAME: "Monthly",
                VAL: Json_decode.field("value", Json_decode.$$float, json)
              };
    default:
      return Pervasives.failwith("Unexpected UsageLimit.Commitment type: " + other);
  }
}

var Commitment = {
  encode: encode$1,
  decode: decode$3
};

function encode$2(t) {
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "usageLimit",
                      encode(t.usageLimit)
                    ],
                    tl: {
                      hd: [
                        "usageIncluded",
                        encode(t.usageIncluded)
                      ],
                      tl: /* [] */0
                    }
                  },
                  Belt_Option.mapWithDefault(t.usageCommitment, /* [] */0, (function (commitment) {
                          return {
                                  hd: [
                                    "usageCommitment",
                                    encode$1(commitment)
                                  ],
                                  tl: /* [] */0
                                };
                        }))
                ]));
}

function decode$4(json) {
  return {
          usageLimit: Json_decode.field("usageLimit", decode$2, json),
          usageIncluded: Json_decode.field("usageIncluded", decode$2, json),
          usageCommitment: Json_decode.optional((function (param) {
                  return Json_decode.field("usageCommitment", decode$3, param);
                }), json)
        };
}

var UsageLimit = {
  Limit: Limit,
  Commitment: Commitment,
  encode: encode$2,
  decode: decode$4
};

export {
  toDate ,
  toDateOption ,
  Role ,
  Context ,
  InspectorKey ,
  Action ,
  OnboardingTemplate ,
  OnboardingV2 ,
  Schema ,
  Trial ,
  Version ,
  Chunk ,
  Picture ,
  ColumnSettings ,
  BranchAuditSettings ,
  GettingStarted ,
  Domain ,
  SsoProviderBySchema ,
  Filter ,
  Member ,
  Invite ,
  SchemaInvite ,
  UserSchema ,
  Payment ,
  Config ,
  $$Comment ,
  Activity ,
  envToJs ,
  envFromJs ,
  Invocation ,
  InvocationMeta ,
  EmailVerificationTrigger ,
  CreateContact ,
  PullResponse ,
  ExportResponse ,
  CollaborationItem ,
  CollaborationTrigger ,
  Collaborator ,
  OAuthRequest ,
  NotificationChannel ,
  NotificationChannelAuth ,
  UsageLimit ,
}
/* Comparator Not a pure module */
