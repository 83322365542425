// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as $$Window from "./externals/window.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intercom from "./externals/intercom.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as RouterTypes from "./RouterTypes.mjs";
import * as QueryString from "query-string";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as SharedRouterUtils from "../../shared/utils/SharedRouterUtils.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";

function schemaRouteToAnalyticsLocation(schemaRoute, drawerItems) {
  var exit = 0;
  if (schemaRoute !== undefined) {
    if (typeof schemaRoute === "object") {
      var variant = schemaRoute.NAME;
      if (variant === "inspector") {
        var match = schemaRoute.VAL;
        if (typeof match === "object") {
          if (!drawerItems) {
            return "InspectorSavedView";
          }
          exit = 1;
        } else if (match === "issues") {
          if (!drawerItems) {
            return "InspectorIssues";
          }
          exit = 1;
        } else if (match === "events") {
          if (!drawerItems) {
            return "InspectorEvents";
          }
          exit = 1;
        } else {
          if (!drawerItems) {
            return "InspectorSavedViews";
          }
          exit = 1;
        }
      } else if (variant === "import") {
        if (drawerItems) {
          exit = 1;
        } else {
          var screen = schemaRoute.VAL;
          if (screen === "success") {
            return "ImportSuccess";
          } else if (screen === "index") {
            return "ImportUpload";
          } else {
            return "ImportReview";
          }
        }
      } else if (variant === "domain") {
        if (!drawerItems) {
          return "Domain";
        }
        exit = 1;
      } else if (drawerItems) {
        exit = 1;
      } else {
        var part = schemaRoute.VAL;
        if (typeof part === "object") {
          if (part.NAME === "comment") {
            return "BranchActivity";
          } else if (typeof part.VAL === "object") {
            return "BranchImplementationSource";
          } else {
            return "BranchImplementation";
          }
        } else {
          return "Diff";
        }
      }
    } else {
      if (schemaRoute === "billing") {
        return "Billing";
      }
      if (schemaRoute === "properties") {
        if (!drawerItems) {
          return "Properties";
        }
        exit = 1;
      } else {
        if (schemaRoute === "auditLog") {
          return "AuditLog";
        }
        if (schemaRoute === "settings") {
          if (!drawerItems) {
            return "Settings";
          }
          exit = 1;
        } else {
          if (schemaRoute === "dashboard") {
            return "SchemaDashboard";
          }
          if (schemaRoute === "gettingStarted") {
            return "Onboarding";
          }
          if (schemaRoute === "integrations") {
            if (!drawerItems) {
              return "Integrations";
            }
            exit = 1;
          } else if (schemaRoute === "events") {
            if (!drawerItems) {
              return "Events";
            }
            exit = 1;
          } else if (schemaRoute === "metrics") {
            if (!drawerItems) {
              return "Metric";
            }
            exit = 1;
          } else if (schemaRoute === "implement") {
            if (!drawerItems) {
              return "Implement";
            }
            exit = 1;
          } else {
            if (!drawerItems) {
              return "EventPropertyGroups";
            }
            exit = 1;
          }
        }
      }
    }
  } else {
    if (!drawerItems) {
      return "Onboarding";
    }
    exit = 1;
  }
  if (exit === 1) {
    var drawerItem = drawerItems.hd;
    var variant$1 = drawerItem.NAME;
    if (variant$1 === "propertyGroup") {
      return "PropertyGroup";
    }
    if (variant$1 === "codegenSource") {
      return "Implement";
    }
    if (variant$1 === "eventVariant") {
      if (drawerItem.VAL[1] !== undefined) {
        return "EventVariantTrigger";
      } else {
        return "EventVariant";
      }
    }
    if (variant$1 === "event") {
      var match$1 = drawerItem.VAL;
      var exit$1 = 0;
      if (match$1[1] !== undefined && match$1[2] === undefined) {
        if (!match$1[3]) {
          return "EventComment";
        }
        exit$1 = 2;
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        if (match$1[2] !== undefined) {
          if (match$1[3]) {
            if (match$1[3]) {
              return "EventFullscreen";
            } else {
              return "Event";
            }
          } else {
            return "EventTrigger";
          }
        } else if (match$1[3]) {
          return "EventFullscreen";
        } else {
          return "Event";
        }
      }
      
    } else {
      if (variant$1 === "inspectorIssue") {
        if (drawerItem.VAL[1] !== undefined) {
          return "InspectorIssueComment";
        } else {
          return "InspectorIssue";
        }
      }
      if (variant$1 === "metric") {
        if (drawerItem.VAL[2]) {
          return "MetricFullscreen";
        } else {
          return "Metric";
        }
      }
      if (variant$1 === "destination") {
        return "DestinationSettings";
      }
      if (variant$1 === "integration") {
        if (drawerItem.VAL[1] !== undefined) {
          return "IntegrationComment";
        } else {
          return "Integration";
        }
      }
      if (variant$1 === "property") {
        return "Property";
      }
      if (variant$1 === "category") {
        return "Category";
      }
      if (variant$1 === "source") {
        var sourceTab = drawerItem.VAL[1];
        if (sourceTab === "overview") {
          return "SourceSettings";
        } else if (sourceTab === "codegenSetup") {
          return "SourceFunctionsSetup";
        } else {
          return "SourceInspectorSetup";
        }
      }
      var match$2 = drawerItem.VAL[1];
      if (typeof match$2 !== "object") {
        return Pervasives.failwith("Preview route not implemented");
      }
      var variant$2 = match$2.NAME;
      if (variant$2 === "event") {
        return "EventPreview";
      } else if (variant$2 === "property") {
        return "PropertyPreview";
      } else {
        return Pervasives.failwith("Preview route not implemented");
      }
    }
  }
  
}

function baseRouteLocationToAnalyticsLocation(baseRoute, schemaRoute, drawerItems) {
  if (typeof baseRoute !== "object") {
    if (baseRoute === "passwordReset") {
      return "PasswordReset";
    } else if (baseRoute === "sandbox") {
      return "Sandbox";
    } else if (baseRoute === "notFound") {
      return "NotFound";
    } else if (baseRoute === "invite") {
      return "Invite";
    } else if (baseRoute === "welcome") {
      return "Welcome";
    } else if (baseRoute === "index" || baseRoute === "allSchemas") {
      return "Schemas";
    } else if (baseRoute === "signUp") {
      return "SignUp";
    } else {
      return "SignIn";
    }
  }
  var variant = baseRoute.NAME;
  if (variant === "schema") {
    return schemaRouteToAnalyticsLocation(schemaRoute, drawerItems);
  }
  if (variant === "share") {
    return "PublicBranchImplementation";
  }
  if (variant === "onboarding") {
    return "Onboarding";
  }
  var match = baseRoute.VAL;
  if (match === "success") {
    return "AuthenticateCLISuccess";
  } else if (match === "index") {
    return "AuthenticateCLI";
  } else {
    return "AuthenticateCLIError";
  }
}

function getOrigin(param) {
  return window.location.origin;
}

function getPathName(param) {
  return window.location.pathname;
}

function getSearch(param) {
  return window.location.search;
}

function getHash(param) {
  return window.location.hash;
}

function buildRedirectPath(param) {
  var pathname = window.location.pathname;
  var search = window.location.search;
  return encodeURIComponent(pathname + search);
}

function getRescriptReactRouterUrl(urlConstructorUrl) {
  var raw = urlConstructorUrl.pathname;
  var tmp;
  switch (raw) {
    case "" :
    case "/" :
        tmp = /* [] */0;
        break;
    default:
      var raw$1 = Js_string.sliceToEnd(1, raw);
      var match = raw$1[raw$1.length - 1 | 0];
      var raw$2 = match === "/" ? Js_string.slice(0, -1, raw$1) : raw$1;
      tmp = Belt_List.fromArray(raw$2.split("/"));
  }
  var raw$3 = urlConstructorUrl.hash;
  var tmp$1;
  switch (raw$3) {
    case "" :
    case "#" :
        tmp$1 = "";
        break;
    default:
      tmp$1 = raw$3.slice(1);
  }
  var raw$4 = urlConstructorUrl.search;
  var tmp$2;
  switch (raw$4) {
    case "" :
    case "?" :
        tmp$2 = "";
        break;
    default:
      tmp$2 = raw$4.slice(1);
  }
  return {
          path: tmp,
          hash: tmp$1,
          search: tmp$2
        };
}

function getUrlParts(url) {
  var currentOrigin = window.location.origin;
  var url$1 = url.startsWith(currentOrigin) ? url : (
      url.startsWith("/") ? currentOrigin + url : currentOrigin + "/" + url
    );
  return getRescriptReactRouterUrl(new URL(url$1));
}

function getRouteFromPath(path) {
  var baseRoute = RouterTypes.BaseRoute.get(path);
  var branch = typeof baseRoute === "object" && baseRoute.NAME === "schema" ? RouterTypes.Branch.get(path) : undefined;
  var schemaRoute = typeof baseRoute === "object" && baseRoute.NAME === "schema" ? RouterTypes.SchemaRoute.get(path) : undefined;
  var drawerItems = typeof baseRoute === "object" && baseRoute.NAME === "schema" ? RouterTypes.DrawerItems.get(path) : /* [] */0;
  return {
          baseRoute: baseRoute,
          branch: branch,
          schemaRoute: schemaRoute,
          drawerItems: drawerItems
        };
}

function getStaticRoute(param) {
  var origin = window.location.origin;
  var pathName = window.location.pathname;
  var pathName$1 = pathName.startsWith("/") ? pathName : "/" + pathName;
  var search = window.location.search;
  var hash = window.location.hash;
  var url = getUrlParts(origin + pathName$1 + search + hash);
  var match = getRouteFromPath(url.path);
  return {
          baseRoute: match.baseRoute,
          branch: match.branch,
          schemaRoute: match.schemaRoute,
          drawerItems: match.drawerItems,
          search: SharedRouterUtils.getSearchString(search),
          hash: SharedRouterUtils.getHashString(hash)
        };
}

function getCurrentUrl(param) {
  return window.location.origin + window.location.pathname;
}

var get = SharedRouterUtils.Link.get;

var getFullPath = SharedRouterUtils.Link.getFullPath;

function searchToArray(search) {
  return Js_dict.entries(QueryString.parse(search));
}

function prefixIfPresent(prefix, value) {
  if (value.trim() === "") {
    return "";
  } else {
    return prefix + value;
  }
}

function withUrlItems(queryParams, hash, uri) {
  var queryParams$1 = Belt_Option.mapWithDefaultU(queryParams, "", (function (queryParams) {
          return QueryString.stringify(Js_dict.fromArray(queryParams));
        }));
  var hash$1 = Belt_Option.mapWithDefaultU(hash, "", (function (hash) {
          return hash;
        }));
  return uri + prefixIfPresent("?", queryParams$1) + prefixIfPresent("#", hash$1);
}

function getSchemaLink(baseRoute, branch, schemaRoute, drawerItems) {
  return Curry._4(get, baseRoute, branch, schemaRoute, drawerItems);
}

function getSchemaRouteLink(branch, schemaRoute) {
  var uri = getCurrentUrl();
  var url = getUrlParts(uri);
  var route = getRouteFromPath(url.path);
  return Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                    return branch;
                  })), schemaRoute, /* [] */0);
}

function getNewFormatPathString(url, route) {
  var oldFormatPathString = "/" + Belt_List.toArray(url.path).join("/") + ((
      url.search === "" ? "" : "?" + url.search
    ) + (
      url.hash === "" ? "" : "#" + url.hash
    ));
  var newFormatPathString = withUrlItems(Js_dict.entries(QueryString.parse(url.search)), url.hash, Curry._4(get, route.baseRoute, route.branch, route.schemaRoute, route.drawerItems));
  var isOfOldFormat = newFormatPathString !== oldFormatPathString;
  if (isOfOldFormat) {
    return newFormatPathString;
  }
  
}

function addDrawerItem(branch, drawerItem) {
  var route = getStaticRoute();
  return Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                    return branch;
                  })), route.schemaRoute, {
              hd: drawerItem,
              tl: route.drawerItems
            });
}

function swapTopDrawerItem(branch, drawerItem) {
  var route = getStaticRoute();
  var match = route.drawerItems;
  return Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                    return branch;
                  })), route.schemaRoute, match ? ({
                  hd: drawerItem,
                  tl: match.tl
                }) : ({
                  hd: drawerItem,
                  tl: /* [] */0
                }));
}

function getSimple(param) {
  var uri = getCurrentUrl();
  var url = getUrlParts(uri);
  var route = getRouteFromPath(url.path);
  var drawerItem = Belt_List.head(route.drawerItems);
  return window.location.origin + Curry._4(get, route.baseRoute, route.branch, Belt_Option.mapWithDefault(drawerItem, route.schemaRoute, (function (drawerItem) {
                    return RouterTypes.DrawerItem.getParentSchemaRoute(drawerItem);
                  })), Belt_Option.mapWithDefault(drawerItem, /* [] */0, (function (drawerItem) {
                    return {
                            hd: drawerItem,
                            tl: /* [] */0
                          };
                  })));
}

function getBaseRoute(branch, baseRoute) {
  return Curry._4(get, baseRoute, Belt_Option.mapWithDefault(branch, undefined, (function (branch) {
                    return branch;
                  })), undefined, /* [] */0);
}

var Link = {
  getCurrentUrl: getCurrentUrl,
  get: get,
  getFullPath: getFullPath,
  searchToArray: searchToArray,
  prefixIfPresent: prefixIfPresent,
  withUrlItems: withUrlItems,
  getSchemaLink: getSchemaLink,
  getSchemaRouteLink: getSchemaRouteLink,
  getNewFormatPathString: getNewFormatPathString,
  addDrawerItem: addDrawerItem,
  swapTopDrawerItem: swapTopDrawerItem,
  getSimple: getSimple,
  getBaseRoute: getBaseRoute
};

function reParsePath(path) {
  var url = getUrlParts(path);
  var match = getRouteFromPath(url.path);
  return withUrlItems(Js_dict.entries(QueryString.parse(url.search)), url.hash, Curry._4(get, match.baseRoute, match.branch, match.schemaRoute, match.drawerItems));
}

function withQueryParams(uri) {
  var currentPath = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path;
  var match = getUrlParts(uri);
  var path = match.path;
  var pathString = Belt_List.toArray(path).join("/");
  var newPathString = pathString.startsWith("/") ? pathString : "/" + pathString;
  var currentBaseRoute = RouterTypes.BaseRoute.get(currentPath);
  var newBaseRoute = RouterTypes.BaseRoute.get(path);
  var currentSchemaRoute = RouterTypes.SchemaRoute.get(currentPath);
  var newSchemaRoute = RouterTypes.SchemaRoute.get(path);
  var shouldResetQueryParams = Caml_obj.notequal(currentSchemaRoute, newSchemaRoute) || Caml_obj.notequal(currentBaseRoute, newBaseRoute);
  var oldQueryParams = Js_dict.entries(QueryString.parse(window.location.search));
  var newQueryParams = Js_dict.entries(QueryString.parse(match.search));
  var combinedQueryParams = QueryString.stringify(Js_dict.fromArray(Belt_Array.concat(oldQueryParams, newQueryParams)));
  var newQueryParamsString = combinedQueryParams === "" ? "" : "?" + combinedQueryParams;
  var newUriWithQueryParams = newPathString + newQueryParamsString;
  if (shouldResetQueryParams) {
    return uri;
  } else {
    return newUriWithQueryParams;
  }
}

function getLowestRouteChange(a, b) {
  var aParts = getUrlParts(a);
  var bParts = getUrlParts(b);
  var match = getRouteFromPath(aParts.path);
  var match$1 = getRouteFromPath(bParts.path);
  if (Caml_obj.notequal(match.baseRoute, match$1.baseRoute)) {
    return "baseRoute";
  } else if (Caml_obj.notequal(match.branch, match$1.branch)) {
    return "branch";
  } else if (Caml_obj.notequal(match.schemaRoute, match$1.schemaRoute)) {
    return "schemaRoute";
  } else if (Caml_obj.notequal(match.drawerItems, match$1.drawerItems)) {
    return "drawerItem";
  } else if (aParts.search !== bParts.search) {
    return "search";
  } else if (aParts.hash !== bParts.hash) {
    return "hash";
  } else {
    return ;
  }
}

function push(withoutQueryParamsOpt, path) {
  var withoutQueryParams = withoutQueryParamsOpt !== undefined ? withoutQueryParamsOpt : false;
  var match = RouterStore.Schema.getState();
  var routeChangePreventionSubscribers = match.routeChangePreventionSubscribers;
  var mayPush;
  if (routeChangePreventionSubscribers.length !== 0) {
    var routeChange = getLowestRouteChange(getCurrentUrl(), path);
    mayPush = routeChange !== undefined ? Belt_Option.mapWithDefault(Belt_Array.getByU(Belt_Array.reverse(routeChangePreventionSubscribers), (function (param) {
                  return RouterTypes.Route.routingMeaningfully(param[1], routeChange);
                })), true, (function (param) {
              Curry._1(param[2], (function (param) {
                      push(withoutQueryParams, path);
                    }));
              return false;
            })) : true;
  } else {
    mayPush = true;
  }
  if (!mayPush) {
    return ;
  }
  var path$1 = reParsePath(path);
  if (withoutQueryParams) {
    return RescriptReactRouter.push(path$1);
  } else {
    return RescriptReactRouter.push(withQueryParams(path$1));
  }
}

function replace(path) {
  $$Window.$$History.replaceState(withQueryParams(reParsePath(path)));
}

function use(param) {
  var search = RescriptReactRouter.useUrl(undefined, undefined).search;
  return React.useMemo((function (param) {
                return QueryString.parse(search);
              }), [search]);
}

function getQueryParam(queryParams, key) {
  return Belt_Option.map(Js_dict.get(queryParams, key), (function (value) {
                if (value !== undefined) {
                  return value;
                } else {
                  return "";
                }
              }));
}

function getUrlForSettingQueryParam(key, value) {
  var search = QueryString.parse(window.location.search);
  search[key] = value;
  var pathName = window.location.pathname;
  var pathName$1 = pathName.startsWith("/") ? pathName : "/" + pathName;
  return pathName$1 + "?" + QueryString.stringify(search);
}

function getUrlForRemovingQueryParams(keys) {
  var search = QueryString.parse(window.location.search);
  Belt_Array.forEachU(keys, (function (key) {
          Belt_Option.forEach(Js_dict.get(search, key), (function (param) {
                  Js_dict.unsafeDeleteKey(search, key);
                }));
        }));
  var pathName = window.location.pathname;
  var pathName$1 = pathName.startsWith("/") ? pathName : "/" + pathName;
  return pathName$1 + (
          Caml_obj.equal(Object.keys(search), []) ? "" : "?" + QueryString.stringify(search)
        );
}

function getUrlForRemovingQueryParam(key) {
  return getUrlForRemovingQueryParams([key]);
}

function removeQueryParam(key) {
  RescriptReactRouter.push(getUrlForRemovingQueryParams([key]));
}

function removeQueryParamByReplace(key) {
  RescriptReactRouter.replace(getUrlForRemovingQueryParams([key]));
}

function setQueryParam(key, value) {
  RescriptReactRouter.push(getUrlForSettingQueryParam(key, value));
}

function replaceQueryParam(key, value) {
  RescriptReactRouter.replace(getUrlForSettingQueryParam(key, value));
}

function build(key, value) {
  return [
          key,
          value
        ];
}

function removeQueryParamsByReplace(keys) {
  RescriptReactRouter.replace(getUrlForRemovingQueryParams(keys));
}

function useKeyValue(key) {
  var queryParams = use();
  return React.useMemo((function (param) {
                return getQueryParam(queryParams, key);
              }), [
              key,
              JSON.stringify(queryParams)
            ]);
}

function toString(value) {
  return value;
}

function fromString(value) {
  return value;
}

var StringKey = {
  toString: toString,
  fromString: fromString
};

function toString$1(value) {
  return String(value);
}

function fromString$1(value) {
  return Belt_Option.flatMapU(value, Belt_Int.fromString);
}

var IntKey = {
  toString: toString$1,
  fromString: fromString$1
};

function toString$2(value) {
  return String(value);
}

function fromString$2(value) {
  return Belt_Option.flatMapU(value, Belt_Float.fromString);
}

var FloatKey = {
  toString: toString$2,
  fromString: fromString$2
};

function fromString$3(value) {
  if (value !== undefined && value !== "") {
    return true;
  } else {
    return false;
  }
}

var BoolKey = {
  toString: Pervasives.string_of_bool,
  fromString: fromString$3
};

function MakeKey(C) {
  var useKey = function (key) {
    var value = useKeyValue(key);
    return React.useMemo((function (param) {
                  return {
                          build: (function (value) {
                              return [
                                      key,
                                      Curry._1(C.toString, value)
                                    ];
                            }),
                          value: Curry._1(C.fromString, value),
                          set: (function (value) {
                              var value$1 = Curry._1(C.toString, value);
                              RescriptReactRouter.push(getUrlForSettingQueryParam(key, value$1));
                            }),
                          replace: (function (value) {
                              var value$1 = Curry._1(C.toString, value);
                              RescriptReactRouter.replace(getUrlForSettingQueryParam(key, value$1));
                            }),
                          remove: (function (param) {
                              RescriptReactRouter.push(getUrlForRemovingQueryParams([key]));
                            }),
                          removeByReplace: (function (param) {
                              RescriptReactRouter.replace(getUrlForRemovingQueryParams([key]));
                            })
                        };
                }), [value]);
  };
  return {
          useKey: useKey
        };
}

function MakeFixedKey(C) {
  var use = function (param) {
    var value = useKeyValue(C.key);
    return React.useMemo((function (param) {
                  return {
                          build: (function (value) {
                              return [
                                      C.key,
                                      Curry._1(C.toString, value)
                                    ];
                            }),
                          value: Curry._1(C.fromString, value),
                          set: (function (value) {
                              var value$1 = Curry._1(C.toString, value);
                              RescriptReactRouter.push(getUrlForSettingQueryParam(C.key, value$1));
                            }),
                          replace: (function (value) {
                              var value$1 = Curry._1(C.toString, value);
                              RescriptReactRouter.replace(getUrlForSettingQueryParam(C.key, value$1));
                            }),
                          remove: (function (param) {
                              RescriptReactRouter.push(getUrlForRemovingQueryParams([C.key]));
                            }),
                          removeByReplace: (function (param) {
                              RescriptReactRouter.replace(getUrlForRemovingQueryParams([C.key]));
                            })
                        };
                }), [value]);
  };
  return {
          use: use
        };
}

function useKey(key) {
  var value = useKeyValue(key);
  return React.useMemo((function (param) {
                return {
                        build: (function (value) {
                            return [
                                    key,
                                    value
                                  ];
                          }),
                        value: value,
                        set: (function (value) {
                            RescriptReactRouter.push(getUrlForSettingQueryParam(key, value));
                          }),
                        replace: (function (value) {
                            RescriptReactRouter.replace(getUrlForSettingQueryParam(key, value));
                          }),
                        remove: (function (param) {
                            RescriptReactRouter.push(getUrlForRemovingQueryParams([key]));
                          }),
                        removeByReplace: (function (param) {
                            RescriptReactRouter.replace(getUrlForRemovingQueryParams([key]));
                          })
                      };
              }), [value]);
}

var String_ = {
  useKey: useKey
};

function useKey$1(key) {
  var value = useKeyValue(key);
  return React.useMemo((function (param) {
                return {
                        build: (function (value) {
                            return [
                                    key,
                                    Pervasives.string_of_bool(value)
                                  ];
                          }),
                        value: fromString$3(value),
                        set: (function (value) {
                            var value$1 = Pervasives.string_of_bool(value);
                            RescriptReactRouter.push(getUrlForSettingQueryParam(key, value$1));
                          }),
                        replace: (function (value) {
                            var value$1 = Pervasives.string_of_bool(value);
                            RescriptReactRouter.replace(getUrlForSettingQueryParam(key, value$1));
                          }),
                        remove: (function (param) {
                            RescriptReactRouter.push(getUrlForRemovingQueryParams([key]));
                          }),
                        removeByReplace: (function (param) {
                            RescriptReactRouter.replace(getUrlForRemovingQueryParams([key]));
                          })
                      };
              }), [value]);
}

var Bool_ = {
  useKey: useKey$1
};

function useKey$2(key) {
  var value = useKeyValue(key);
  return React.useMemo((function (param) {
                return {
                        build: (function (value) {
                            return [
                                    key,
                                    String(value)
                                  ];
                          }),
                        value: Belt_Option.flatMapU(value, Belt_Int.fromString),
                        set: (function (value) {
                            var value$1 = String(value);
                            RescriptReactRouter.push(getUrlForSettingQueryParam(key, value$1));
                          }),
                        replace: (function (value) {
                            var value$1 = String(value);
                            RescriptReactRouter.replace(getUrlForSettingQueryParam(key, value$1));
                          }),
                        remove: (function (param) {
                            RescriptReactRouter.push(getUrlForRemovingQueryParams([key]));
                          }),
                        removeByReplace: (function (param) {
                            RescriptReactRouter.replace(getUrlForRemovingQueryParams([key]));
                          })
                      };
              }), [value]);
}

var Int_ = {
  useKey: useKey$2
};

function useKey$3(key) {
  var value = useKeyValue(key);
  return React.useMemo((function (param) {
                return {
                        build: (function (value) {
                            return [
                                    key,
                                    String(value)
                                  ];
                          }),
                        value: Belt_Option.flatMapU(value, Belt_Float.fromString),
                        set: (function (value) {
                            var value$1 = String(value);
                            RescriptReactRouter.push(getUrlForSettingQueryParam(key, value$1));
                          }),
                        replace: (function (value) {
                            var value$1 = String(value);
                            RescriptReactRouter.replace(getUrlForSettingQueryParam(key, value$1));
                          }),
                        remove: (function (param) {
                            RescriptReactRouter.push(getUrlForRemovingQueryParams([key]));
                          }),
                        removeByReplace: (function (param) {
                            RescriptReactRouter.replace(getUrlForRemovingQueryParams([key]));
                          })
                      };
              }), [value]);
}

var Float_ = {
  useKey: useKey$3
};

var useStringKey = useKey;

var useBoolKey = useKey$1;

var useIntKey = useKey$2;

var useFloatKey = useKey$3;

var QueryParams = {
  use: use,
  getQueryParam: getQueryParam,
  getUrlForSettingQueryParam: getUrlForSettingQueryParam,
  getUrlForRemovingQueryParams: getUrlForRemovingQueryParams,
  getUrlForRemovingQueryParam: getUrlForRemovingQueryParam,
  removeQueryParam: removeQueryParam,
  removeQueryParamByReplace: removeQueryParamByReplace,
  setQueryParam: setQueryParam,
  replaceQueryParam: replaceQueryParam,
  build: build,
  removeQueryParamsByReplace: removeQueryParamsByReplace,
  useKeyValue: useKeyValue,
  StringKey: StringKey,
  IntKey: IntKey,
  FloatKey: FloatKey,
  BoolKey: BoolKey,
  MakeKey: MakeKey,
  MakeFixedKey: MakeFixedKey,
  String_: String_,
  Bool_: Bool_,
  Int_: Int_,
  Float_: Float_,
  useStringKey: useStringKey,
  useBoolKey: useBoolKey,
  useIntKey: useIntKey,
  useFloatKey: useFloatKey
};

function use$1(param) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var pathJoined = Belt_List.toArray(url.path).join("/");
  var baseRoute = React.useMemo((function (param) {
          return RouterTypes.BaseRoute.get(url.path);
        }), [pathJoined]);
  var route = React.useMemo((function (param) {
          if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
            return {
                    baseRoute: baseRoute,
                    branch: RouterTypes.Branch.get(url.path),
                    schemaRoute: RouterTypes.SchemaRoute.get(url.path),
                    drawerItems: RouterTypes.DrawerItems.get(url.path)
                  };
          } else {
            return {
                    baseRoute: baseRoute,
                    branch: undefined,
                    schemaRoute: undefined,
                    drawerItems: /* [] */0
                  };
          }
        }), [pathJoined]);
  var drawerItems = route.drawerItems;
  var schemaRoute = route.schemaRoute;
  var branch = route.branch;
  var baseRoute$1 = route.baseRoute;
  var prevRoute = Hooks.usePrevious1(route);
  React.useEffect((function (param) {
          AnalyticsRe.navigated(AnalyticsRe.toScreenToJs(baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems)), baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems), undefined, undefined, typeof baseRoute$1 === "object" && baseRoute$1.NAME === "schema" ? baseRoute$1.VAL : undefined, undefined, branch !== undefined && typeof branch === "object" && branch.NAME === "branch" ? branch.VAL : undefined);
        }), []);
  Hooks.useDidUpdate1((function (param) {
          Intercom.update({});
          var match = prevRoute.baseRoute;
          var match$1 = prevRoute.branch;
          AnalyticsRe.navigated(AnalyticsRe.toScreenToJs(baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems)), baseRouteLocationToAnalyticsLocation(baseRoute$1, schemaRoute, drawerItems), baseRouteLocationToAnalyticsLocation(prevRoute.baseRoute, prevRoute.schemaRoute, prevRoute.drawerItems), typeof match === "object" && match.NAME === "schema" ? match.VAL : undefined, typeof baseRoute$1 === "object" && baseRoute$1.NAME === "schema" ? baseRoute$1.VAL : undefined, match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "branch" ? match$1.VAL : undefined, branch !== undefined && typeof branch === "object" && branch.NAME === "branch" ? branch.VAL : undefined);
        }), [pathJoined]);
}

var AnalyticsHook = {
  use: use$1
};

function useUrlManager(url) {
  var match = React.useState(function (param) {
        return RouterTypes.BaseRoute.get(url.path);
      });
  var setBaseRoute = match[1];
  var baseRoute = match[0];
  var match$1 = React.useState(function (param) {
        if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
          return RouterTypes.Branch.get(url.path);
        }
        
      });
  var setBranch = match$1[1];
  var branch = match$1[0];
  var match$2 = React.useState(function (param) {
        if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
          return RouterTypes.SchemaRoute.get(url.path);
        }
        
      });
  var setSchemaRoute = match$2[1];
  var schemaRoute = match$2[0];
  var match$3 = React.useState(function (param) {
        if (typeof baseRoute === "object" && baseRoute.NAME === "schema") {
          return RouterTypes.DrawerItems.get(url.path);
        } else {
          return /* [] */0;
        }
      });
  var setDrawerItems = match$3[1];
  var drawerItems = match$3[0];
  var match$4 = React.useState(function (param) {
        
      });
  var setReplacePath = match$4[1];
  var replacePath = match$4[0];
  React.useEffect((function (param) {
          if (replacePath !== undefined) {
            replace(replacePath);
          }
          
        }), [replacePath]);
  React.useEffect((function (param) {
          Curry._1(setReplacePath, (function (param) {
                  return getNewFormatPathString(url, {
                              baseRoute: baseRoute,
                              branch: branch,
                              schemaRoute: schemaRoute,
                              drawerItems: drawerItems
                            });
                }));
        }), []);
  Hooks.useDidUpdate1((function (param) {
          var newBaseRoute = RouterTypes.BaseRoute.get(url.path);
          if (Caml_obj.equal(baseRoute, newBaseRoute)) {
            
          } else {
            Curry._1(setBaseRoute, (function (param) {
                    return newBaseRoute;
                  }));
          }
          var exit = 0;
          if (typeof newBaseRoute === "object" && newBaseRoute.NAME === "schema") {
            var newBranch = RouterTypes.Branch.get(url.path);
            if (Caml_obj.equal(branch, newBranch)) {
              
            } else {
              Curry._1(setBranch, (function (param) {
                      return newBranch;
                    }));
            }
            var newSchemaRoute = RouterTypes.SchemaRoute.get(url.path);
            if (Caml_obj.equal(schemaRoute, newSchemaRoute)) {
              
            } else {
              Curry._1(setSchemaRoute, (function (param) {
                      return newSchemaRoute;
                    }));
            }
            var newDrawerItems = RouterTypes.DrawerItems.get(url.path);
            if (Caml_obj.equal(drawerItems, newDrawerItems)) {
              
            } else {
              Curry._1(setDrawerItems, (function (param) {
                      return RouterTypes.DrawerItems.get(url.path);
                    }));
            }
            var newFormatString = getNewFormatPathString(url, {
                  baseRoute: newBaseRoute,
                  branch: newBranch,
                  schemaRoute: newSchemaRoute,
                  drawerItems: newDrawerItems
                });
            Curry._1(setReplacePath, (function (param) {
                    return newFormatString;
                  }));
          } else {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(setReplacePath, (function (param) {
                    return getNewFormatPathString(url, {
                                baseRoute: newBaseRoute,
                                branch: branch,
                                schemaRoute: schemaRoute,
                                drawerItems: drawerItems
                              });
                  }));
          }
          
        }), [url]);
  return {
          baseRoute: baseRoute,
          branch: branch,
          schemaRoute: schemaRoute,
          drawerItems: drawerItems
        };
}

var ProviderHooks = {
  useUrlManager: useUrlManager
};

var initialValue = {
  baseRoute: "welcome",
  branch: undefined,
  schemaRoute: undefined,
  drawerItems: /* [] */0
};

var context = React.createContext(initialValue);

var make = context.Provider;

var Provider = {
  make: make
};

function Router$Provider(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = useUrlManager(url);
  use$1();
  return JsxRuntime.jsx(make, {
              value: {
                baseRoute: match.baseRoute,
                branch: match.branch,
                schemaRoute: match.schemaRoute,
                drawerItems: match.drawerItems
              },
              children: props.children
            });
}

var Provider$1 = {
  initialValue: initialValue,
  context: context,
  Provider: Provider,
  make: Router$Provider
};

var Context = {
  AnalyticsHook: AnalyticsHook,
  ProviderHooks: ProviderHooks,
  Provider: Provider$1
};

function useBaseRoute(param) {
  return RouterTypes.BaseRoute.get(RescriptReactRouter.useUrl(undefined, undefined).path);
}

var NotInSchemaException = /* @__PURE__ */Caml_exceptions.create("Router.Schema.NotInSchemaException");

function toParams(queryString) {
  return Belt_Option.mapU(queryString, (function (queryString) {
                return Js_dict.entries(QueryString.parse(queryString));
              }));
}

function pushRoute(baseRoute, branch, schemaRoute, drawerItems, queryString, hash, param) {
  var staticRoute = getStaticRoute();
  push(undefined, withUrlItems(Belt_Option.mapWithDefault(queryString, toParams(staticRoute.search), (function (qs) {
                  return toParams(qs);
                })), Belt_Option.mapWithDefault(hash, staticRoute.hash, (function (hash) {
                  return hash;
                })), Curry._4(get, Belt_Option.getWithDefault(baseRoute, staticRoute.baseRoute), Belt_Option.mapWithDefault(branch, staticRoute.branch, (function (branch) {
                      return branch;
                    })), Belt_Option.mapWithDefault(schemaRoute, staticRoute.schemaRoute, (function (schemaRoute) {
                      return schemaRoute;
                    })), Belt_Option.getWithDefault(drawerItems, staticRoute.drawerItems))));
}

function getSwappedBranches(queryString, branch) {
  var match = getStaticRoute();
  return withUrlItems(toParams(queryString), undefined, Curry._4(get, match.baseRoute, branch, match.schemaRoute, match.drawerItems));
}

function swapBranches(queryString, branch) {
  push(undefined, getSwappedBranches(queryString, branch));
}

function pushSchemaRoute(branch, queryString, schemaRoute) {
  var route = getStaticRoute();
  push(undefined, withUrlItems(toParams(queryString), undefined, Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                      return branch;
                    })), schemaRoute, /* [] */0)));
}

function getImportRoute(branch, importStartedLocation, schemaRoute) {
  return getSchemaRouteLink(branch, schemaRoute) + ("?importStartedLocation=" + AnalyticsRe.importStartedLocationToJs(importStartedLocation));
}

function pushDrawerItem(branch, queryString, drawerItem) {
  var route = getStaticRoute();
  var verifiedSchemaRoute = route.schemaRoute === undefined ? RouterTypes.DrawerItem.getParentSchemaRoute(drawerItem) : route.schemaRoute;
  push(undefined, withUrlItems(toParams(queryString), undefined, Curry._4(get, route.baseRoute, Belt_Option.mapWithDefault(branch, route.branch, (function (branch) {
                      return branch;
                    })), verifiedSchemaRoute, {
                hd: drawerItem,
                tl: route.drawerItems
              })));
}

function popDrawerItem(queryString, param) {
  var route = getStaticRoute();
  var match = route.drawerItems;
  push(undefined, withUrlItems(toParams(queryString), undefined, Curry._4(get, route.baseRoute, route.branch, route.schemaRoute, match ? match.tl : /* [] */0)));
}

function swapTopDrawerItem$1(drawerItem) {
  var route = getStaticRoute();
  var match = route.drawerItems;
  push(undefined, Curry._4(get, route.baseRoute, route.branch, route.schemaRoute, match ? ({
                hd: drawerItem,
                tl: match.tl
              }) : ({
                hd: drawerItem,
                tl: /* [] */0
              })));
}

function replaceDrawerItems(queryString, drawerItems) {
  var route = getStaticRoute();
  push(undefined, withUrlItems(toParams(queryString), undefined, Curry._4(get, route.baseRoute, route.branch, route.schemaRoute, drawerItems)));
}

function clearDrawerItems(queryString, param) {
  replaceDrawerItems(queryString, /* [] */0);
}

function getBranch(param) {
  var match = getStaticRoute();
  var branch = match.branch;
  var baseRoute = match.baseRoute;
  if (typeof baseRoute === "object") {
    if (baseRoute.NAME === "schema") {
      if (branch !== undefined && typeof branch === "object") {
        return {
                NAME: "branch",
                VAL: branch.VAL
              };
      } else {
        return "master";
      }
    }
    throw {
          RE_EXN_ID: NotInSchemaException,
          Error: new Error()
        };
  }
  throw {
        RE_EXN_ID: NotInSchemaException,
        Error: new Error()
      };
}

function getBranchId(param) {
  var match = getBranch();
  if (typeof match === "object") {
    return match.VAL;
  } else {
    return "master";
  }
}

function getSchemaId(param) {
  var match = getStaticRoute();
  var baseRoute = match.baseRoute;
  if (typeof baseRoute === "object") {
    if (baseRoute.NAME === "schema") {
      return baseRoute.VAL;
    }
    throw {
          RE_EXN_ID: NotInSchemaException,
          Error: new Error()
        };
  }
  throw {
        RE_EXN_ID: NotInSchemaException,
        Error: new Error()
      };
}

var Schema = {
  NotInSchemaException: NotInSchemaException,
  pushRoute: pushRoute,
  getSwappedBranches: getSwappedBranches,
  swapBranches: swapBranches,
  pushSchemaRoute: pushSchemaRoute,
  getImportRoute: getImportRoute,
  pushDrawerItem: pushDrawerItem,
  popDrawerItem: popDrawerItem,
  swapTopDrawerItem: swapTopDrawerItem$1,
  replaceDrawerItems: replaceDrawerItems,
  clearDrawerItems: clearDrawerItems,
  getBranch: getBranch,
  getBranchId: getBranchId,
  getSchemaId: getSchemaId
};

var logError = RouterTypes.logError;

var parseCombinedEventAndVariantId = RouterTypes.parseCombinedEventAndVariantId;

var BaseRoute = RouterTypes.BaseRoute;

var Branch = RouterTypes.Branch;

var SchemaRoute = RouterTypes.SchemaRoute;

var DrawerItem = RouterTypes.DrawerItem;

var DrawerItems = RouterTypes.DrawerItems;

var Route = RouterTypes.Route;

export {
  logError ,
  parseCombinedEventAndVariantId ,
  BaseRoute ,
  Branch ,
  SchemaRoute ,
  DrawerItem ,
  DrawerItems ,
  Route ,
  schemaRouteToAnalyticsLocation ,
  baseRouteLocationToAnalyticsLocation ,
  getOrigin ,
  getPathName ,
  getSearch ,
  getHash ,
  buildRedirectPath ,
  getRescriptReactRouterUrl ,
  getUrlParts ,
  getRouteFromPath ,
  getStaticRoute ,
  Link ,
  reParsePath ,
  withQueryParams ,
  getLowestRouteChange ,
  push ,
  replace ,
  QueryParams ,
  Context ,
  useBaseRoute ,
  Schema ,
}
/* context Not a pure module */
