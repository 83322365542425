// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function Redirect(Props) {
  var path = Props.path;
  var onRedirect = Props.onRedirect;
  React.useEffect((function (param) {
          Belt_Option.forEach(onRedirect, (function (onRedirect) {
                  Curry._1(onRedirect, undefined);
                }));
          Router.replace(path);
        }), [path]);
  return null;
}

var make = Redirect;

export {
  make ,
}
/* react Not a pure module */
