// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function parse(rawParserOutput, _csv) {
  var processProperties = function (properties) {
    var properties$1 = Belt_Array.keepMap(properties, (function (property) {
            if (property.name.endsWith(".$")) {
              return ;
            }
            if (!property.list) {
              return property;
            }
            var maybeListItemProperty = Belt_Array.getBy(properties, (function (eventProperty) {
                    return eventProperty.name === property.name + ".$";
                  }));
            return Belt_Option.mapWithDefault(maybeListItemProperty, property, (function (listItemProperty) {
                          return {
                                  name: property.name,
                                  description: property.description,
                                  type_: listItemProperty.type_,
                                  typeWarning: property.typeWarning,
                                  required: property.required,
                                  list: property.list,
                                  matches: listItemProperty.matches,
                                  sendAs: property.sendAs,
                                  propertyBundle: property.propertyBundle,
                                  propertyEvents: property.propertyEvents,
                                  nameMapping: property.nameMapping,
                                  pinnedValue: property.pinnedValue
                                };
                        }));
          }));
    return Belt_Array.keepMap(properties$1, (function (property) {
                  if (!property.name.includes(".")) {
                    return property;
                  }
                  var maybeParentPropertyName = Belt_Array.get(property.name.split("."), 0);
                  var isObjectChild = Belt_Option.isSome(Belt_Array.getBy(properties$1, (function (eventProperty) {
                              if (Caml_obj.equal(eventProperty.name, maybeParentPropertyName)) {
                                return eventProperty.type_ === "object";
                              } else {
                                return false;
                              }
                            })));
                  if (isObjectChild) {
                    return ;
                  } else {
                    return property;
                  }
                }));
  };
  return {
          formatName: rawParserOutput.formatName,
          events: Belt_Array.map(rawParserOutput.events, (function ($$event) {
                  var properties = processProperties($$event.properties);
                  return {
                          id: $$event.id,
                          name: $$event.name,
                          description: $$event.description,
                          categories: $$event.categories,
                          tags: $$event.tags,
                          properties: properties,
                          propertyBundleNames: $$event.propertyBundleNames,
                          sources: $$event.sources,
                          stakeholderDomains: $$event.stakeholderDomains,
                          nameMapping: $$event.nameMapping,
                          version: $$event.version
                        };
                })),
          propertiesWithoutEvent: processProperties(rawParserOutput.propertiesWithoutEvent),
          warnings: rawParserOutput.warnings,
          propertyBundles: rawParserOutput.propertyBundles
        };
}

export {
  parse ,
}
/* No side effect */
