// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoConfig from "./AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";

function getSimplestAbsenceModeByEvent(absence, sources, $$event, propertySendAs) {
  var match = AvoConfig.determineSendingToSources(sources, $$event);
  var eventSources = typeof match === "object" ? match.VAL : (
      match === "None" ? /* [] */0 : sources
    );
  var eventId = propertySendAs === "SystemProperty" ? TrackingPlanModel.propertyAbsenceAllEventsKey : $$event.id;
  if (typeof absence !== "object") {
    return "Always";
  }
  if (absence.TAG === "SometimesSent") {
    return "Sometimes";
  }
  var match$1 = Belt_MapString.get(absence._0, eventId);
  if (match$1 === undefined) {
    return "Always";
  }
  if (match$1.TAG === "AllSources") {
    var tmp = match$1._0;
    if (typeof tmp !== "object") {
      return "Never";
    } else {
      return "Sometimes";
    }
  }
  var sourceAbsences = match$1._0;
  var states = Belt_List.map(eventSources, (function (source) {
          var match = Belt_MapString.get(sourceAbsences, source.id);
          if (match !== undefined) {
            if (typeof match !== "object") {
              return "Never";
            } else {
              return "Sometimes";
            }
          } else {
            return "Always";
          }
        }));
  if (Belt_List.every(states, (function (state) {
            return state === "Always";
          }))) {
    return "Always";
  } else if (Belt_List.every(states, (function (state) {
            return state === "Sometimes";
          }))) {
    return "Sometimes";
  } else if (Belt_List.every(states, (function (state) {
            return state === "Never";
          }))) {
    return "Never";
  } else {
    return "BySource";
  }
}

export {
  getSimplestAbsenceModeByEvent ,
}
/* AvoConfig Not a pure module */
