// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Models from "./Models.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getRoleFromMember(member) {
  return Belt_Option.getWithDefault(Models.Role.tFromJs(member.role), "NoAccess");
}

function availableRoles(hasCodegenAccessRole, workspace) {
  return Belt_Array.concatMany([
              [
                "Admin",
                "Editor"
              ],
              hasCodegenAccessRole ? ["CodegenAccess"] : [],
              workspace.plan.commentOnlyMembers === "NotAvailable" ? [] : ["CommentOnly"],
              [
                "BillingOnly",
                "Viewer"
              ]
            ]);
}

function getLabel(role, viewersCanComment) {
  if (role === "Editor") {
    return "Editor";
  } else if (role === "CodegenAccess") {
    return "Codegen Access";
  } else if (role === "Viewer") {
    if (viewersCanComment) {
      return "Viewer";
    } else {
      return "View Only";
    }
  } else if (role === "CommentOnly") {
    return "Comment Only";
  } else if (role === "Admin") {
    return "Admin";
  } else if (role === "NoAccess") {
    return "No Access";
  } else {
    return "Billing Only";
  }
}

function fromString(roleString) {
  switch (roleString) {
    case "Admin" :
        return "Admin";
    case "Billing Only" :
        return "BillingOnly";
    case "Codegen Access" :
    case "CodegenAccess" :
        return "CodegenAccess";
    case "Comment Only" :
        return "CommentOnly";
    case "Editor" :
        return "Editor";
    case "View Only" :
    case "Viewer" :
        return "Viewer";
    default:
      return ;
  }
}

function canEdit(role) {
  if (role === "Admin") {
    return true;
  } else {
    return role === "Editor";
  }
}

function canComment(role, viewersCanComment) {
  if (role === "Admin" || role === "CommentOnly" || role === "CodegenAccess" || role === "Editor" || role === "Viewer" && viewersCanComment) {
    return true;
  } else {
    return false;
  }
}

function getRoleRank(role) {
  if (role !== undefined) {
    if (role === "Editor") {
      return 2;
    } else if (role === "CodegenAccess") {
      return 3;
    } else if (role === "Viewer") {
      return 5;
    } else if (role === "CommentOnly") {
      return 4;
    } else if (role === "Admin") {
      return 1;
    } else if (role === "NoAccess") {
      return 7;
    } else {
      return 6;
    }
  } else {
    return 8;
  }
}

export {
  getRoleFromMember ,
  availableRoles ,
  getLabel ,
  fromString ,
  canEdit ,
  canComment ,
  getRoleRank ,
}
/* Models Not a pure module */
