// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Models from "./Models.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateFns from "date-fns";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FeatureFlag from "../../shared/FeatureFlag.mjs";
import * as InspectorViewModel from "./inspector/InspectorViewModel.mjs";

function BooleanLimits(C) {
  var computeAvailability = function (workspace) {
    var match = Curry._1(C.getLimit, workspace.plan);
    var match$1 = workspace.trial;
    if (match) {
      return "Available";
    } else if (match$1 !== undefined && Curry._1(C.getLimit, match$1.plan) === true) {
      return "AvailableDuringTrial";
    } else {
      return "Unavailable";
    }
  };
  var availableOnPlan = function (workspace) {
    var _plan = workspace.plan;
    while(true) {
      var plan = _plan;
      if (Curry._1(C.getLimit, plan) === true || Workspace.isEnterprise(plan.name)) {
        return plan;
      }
      if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
        return Workspace.Instances.enterprise;
      }
      _plan = Workspace.Instances.nextHint(plan);
      continue ;
    };
  };
  var isAvailable = function (workspace) {
    return computeAvailability(workspace) !== "Unavailable";
  };
  return {
          computeAvailability: computeAvailability,
          availableOnPlan: availableOnPlan,
          isAvailable: isAvailable
        };
}

function LadderLimits(C) {
  var computeAvailability = function (workspace, step) {
    var match = Curry._1(C.getLimit, workspace.plan);
    var match$1 = workspace.trial;
    if (Caml_obj.greaterequal(Belt_Array.getIndexBy(C.ladder, (function (ladderStep) {
                  return Caml_obj.equal(ladderStep, match);
                })), Belt_Array.getIndexBy(C.ladder, (function (ladderStep) {
                  return Caml_obj.equal(ladderStep, step);
                })))) {
      return "Available";
    } else if (match$1 !== undefined && Caml_obj.greaterequal(Belt_Array.getIndexBy(C.ladder, (function (ladderStep) {
                  return Caml_obj.equal(ladderStep, Curry._1(C.getLimit, match$1.plan));
                })), Belt_Array.getIndexBy(C.ladder, (function (ladderStep) {
                  return Caml_obj.equal(ladderStep, step);
                })))) {
      return "AvailableDuringTrial";
    } else {
      return "Unavailable";
    }
  };
  var availableOnPlan = function (workspace, step) {
    var _plan = workspace.plan;
    while(true) {
      var plan = _plan;
      if (Caml_obj.equal(Curry._1(C.getLimit, plan), step) || plan.name === "Enterprise") {
        return plan.name;
      }
      if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
        return Workspace.Instances.enterprise.name;
      }
      _plan = Workspace.Instances.nextHint(plan);
      continue ;
    };
  };
  return {
          computeAvailability: computeAvailability,
          availableOnPlan: availableOnPlan
        };
}

function NumericLimits(C) {
  var constructLimitExceeded = function (trial, limit, planHint) {
    if (trial === undefined) {
      return {
              NAME: "LimitExceeded",
              VAL: [
                limit,
                planHint
              ]
            };
    } else {
      return {
              NAME: "AvailableDuringTrial",
              VAL: [
                limit,
                planHint
              ]
            };
    }
  };
  var computeEntityNumericLimits = function (workspace, model) {
    var match = Curry._1(C.getLimit, workspace.plan);
    var limits;
    if (typeof match === "object" && match.NAME === "Limited") {
      var planEntityCountLimit = match.VAL;
      if (Belt_List.length(Curry._2(C.getItems, workspace, model)) > planEntityCountLimit) {
        var entityCount = Belt_List.length(Curry._2(C.getItems, workspace, model));
        var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
        var trial = workspace.trial;
        var trialEntityCount;
        if (trial !== undefined) {
          var match$1 = Curry._1(C.getLimit, trial.plan);
          trialEntityCount = typeof match$1 === "object" ? Caml.int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
        } else {
          trialEntityCount = 0;
        }
        var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
        var limitToInt = function (limit) {
          if (typeof limit === "object") {
            return limit.VAL;
          } else {
            return Pervasives.max_int;
          }
        };
        var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(Curry._2(C.getItems, workspace, model), (function (entityIndex, entity) {
                    var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                            return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                          }));
                    var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                            var match = Curry._1(C.getLimit, publicPlan);
                            if (typeof match === "object") {
                              return match.VAL > entityIndex;
                            } else {
                              return true;
                            }
                          }));
                    var currentPlanLimit = limitToInt(Curry._1(C.getLimit, workspace.plan));
                    var trial = Belt_Option.map(workspace.trial, (function (trial) {
                            return trial.plan;
                          }));
                    var currentTrialLimit = trial !== undefined ? limitToInt(Curry._1(C.getLimit, trial)) : 0;
                    var exit = 0;
                    if (minimumRequiredPlan !== undefined) {
                      if (entityIndex === (currentPlanLimit - 1 | 0)) {
                        return {
                                hd: {
                                  NAME: "LimitReached",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        C.entity,
                                        Curry._1(C.getId, entity)
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit = 1;
                    } else {
                      exit = 1;
                    }
                    if (exit === 1) {
                      if (entityIndex < currentPlanLimit) {
                        return /* [] */0;
                      }
                      if (minimumRequiredPlan === undefined) {
                        return {
                                hd: {
                                  NAME: "LimitExceeded",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        C.entity,
                                        Curry._1(C.getId, entity)
                                      ]
                                    },
                                    Workspace.Instances.enterprise
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      var exit$1 = 0;
                      if (trial !== undefined) {
                        if (entityIndex < currentTrialLimit) {
                          return {
                                  hd: {
                                    NAME: "AvailableDuringTrial",
                                    VAL: [
                                      {
                                        NAME: "NumericLimit",
                                        VAL: [
                                          C.entity,
                                          Curry._1(C.getId, entity)
                                        ]
                                      },
                                      minimumRequiredPlan
                                    ]
                                  },
                                  tl: /* [] */0
                                };
                        }
                        exit$1 = 2;
                      } else {
                        exit$1 = 2;
                      }
                      if (exit$1 === 2) {
                        return {
                                hd: {
                                  NAME: "LimitExceeded",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        C.entity,
                                        Curry._1(C.getId, entity)
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      
                    }
                    
                  })));
        var match$2 = trialEntityCount$1 > 0;
        var match$3 = workspace.trial;
        var exit = 0;
        if (match$2 && match$3 !== undefined) {
          var match$4 = Curry._1(C.getLimit, match$3.plan);
          limits = typeof match$4 === "object" ? ({
                hd: constructLimitExceeded(workspace.trial, {
                      NAME: "NumericListLimit",
                      VAL: [
                        C.entity,
                        Belt_List.length(Curry._2(C.getItems, workspace, model)),
                        match$4.VAL
                      ]
                    }, Workspace.Instances.nextHint(workspace.plan)),
                tl: limits$1
              }) : limits$1;
        } else {
          exit = 1;
        }
        if (exit === 1) {
          limits = {
            hd: constructLimitExceeded(workspace.trial, {
                  NAME: "NumericListLimit",
                  VAL: [
                    C.entity,
                    Belt_List.length(Curry._2(C.getItems, workspace, model)),
                    planEntityCountLimit
                  ]
                }, Workspace.Instances.nextHint(workspace.plan)),
            tl: limits$1
          };
        }
        
      } else {
        limits = /* [] */0;
      }
    } else {
      limits = /* [] */0;
    }
    var match$5 = Curry._1(C.getLimit, workspace.plan);
    var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
            return Curry._1(C.getLimit, trial.plan);
          }));
    var tmp;
    if (typeof match$5 === "object") {
      var maxPlanEntities = match$5.VAL;
      if (Belt_List.length(Curry._2(C.getItems, workspace, model)) < maxPlanEntities) {
        tmp = /* [] */0;
      } else if (match$6 !== undefined) {
        if (typeof match$6 === "object") {
          var maxTrialEntities = match$6.VAL;
          tmp = Belt_List.length(Curry._2(C.getItems, workspace, model)) < maxTrialEntities ? ({
                hd: {
                  NAME: "AvailableDuringTrial",
                  VAL: [
                    {
                      NAME: "CreateEntityStatus",
                      VAL: C.entity
                    },
                    Workspace.Instances.nextHint(workspace.plan)
                  ]
                },
                tl: /* [] */0
              }) : (
              Belt_List.length(Curry._2(C.getItems, workspace, model)) === maxTrialEntities ? ({
                    hd: {
                      NAME: "LimitReached",
                      VAL: [
                        {
                          NAME: "CreateEntityStatus",
                          VAL: C.entity
                        },
                        Workspace.Instances.nextHint(workspace.plan)
                      ]
                    },
                    tl: /* [] */0
                  }) : ({
                    hd: {
                      NAME: "LimitExceeded",
                      VAL: [
                        {
                          NAME: "CreateEntityStatus",
                          VAL: C.entity
                        },
                        Workspace.Instances.nextHint(workspace.plan)
                      ]
                    },
                    tl: /* [] */0
                  })
            );
        } else {
          tmp = {
            hd: {
              NAME: "AvailableDuringTrial",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: C.entity
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          };
        }
      } else {
        tmp = Belt_List.length(Curry._2(C.getItems, workspace, model)) === maxPlanEntities ? ({
              hd: {
                NAME: "LimitReached",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: C.entity
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : ({
              hd: {
                NAME: "LimitExceeded",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: C.entity
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            });
      }
    } else {
      tmp = /* [] */0;
    }
    return Belt_List.concat(limits, tmp);
  };
  var createActionStatus = function (workspaceLimits) {
    return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                      var match = limit.VAL[0];
                      if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                        return match.VAL === C.entity;
                      } else {
                        return false;
                      }
                    })), "Available", (function (limit) {
                  if (limit.NAME === "AvailableDuringTrial") {
                    return "AvailableDuringTrial";
                  } else {
                    return "Unavailable";
                  }
                }));
  };
  var entityStatus = function (workspaceLimits, entityId) {
    return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                      var entity;
                      var id;
                      var variant = limit.NAME;
                      if (variant === "LimitReached") {
                        var match = limit.VAL[0];
                        if (typeof match !== "object") {
                          return false;
                        }
                        if (match.NAME !== "NumericLimit") {
                          return false;
                        }
                        var match$1 = match.VAL;
                        entity = match$1[0];
                        id = match$1[1];
                      } else if (variant === "AvailableDuringTrial") {
                        var match$2 = limit.VAL[0];
                        if (typeof match$2 !== "object") {
                          return false;
                        }
                        if (match$2.NAME !== "NumericLimit") {
                          return false;
                        }
                        var match$3 = match$2.VAL;
                        entity = match$3[0];
                        id = match$3[1];
                      } else {
                        var match$4 = limit.VAL[0];
                        if (typeof match$4 !== "object") {
                          return false;
                        }
                        if (match$4.NAME !== "NumericLimit") {
                          return false;
                        }
                        var match$5 = match$4.VAL;
                        entity = match$5[0];
                        id = match$5[1];
                      }
                      if (entityId === id) {
                        return entity === C.entity;
                      } else {
                        return false;
                      }
                    })), [
                "Available",
                undefined
              ], (function (limit) {
                  var variant = limit.NAME;
                  if (variant === "LimitReached") {
                    return [
                            "Available",
                            limit.VAL[1]
                          ];
                  } else if (variant === "AvailableDuringTrial") {
                    return [
                            "AvailableDuringTrial",
                            limit.VAL[1]
                          ];
                  } else {
                    return [
                            "Unavailable",
                            limit.VAL[1]
                          ];
                  }
                }));
  };
  var status = function (workspaceLimits) {
    return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                      if (typeof limit !== "object") {
                        return false;
                      }
                      var variant = limit.NAME;
                      if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                        return false;
                      }
                      var match = limit.VAL[0];
                      if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                        return match.VAL === C.entity;
                      } else {
                        return false;
                      }
                    })), "Available", (function (limit) {
                  if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                    return "AvailableDuringTrial";
                  } else {
                    return "Unavailable";
                  }
                }));
  };
  return {
          constructLimitExceeded: constructLimitExceeded,
          computeEntityNumericLimits: computeEntityNumericLimits,
          createActionStatus: createActionStatus,
          entityStatus: entityStatus,
          status: status
        };
}

function computeAvailability(workspace) {
  var match = workspace.plan.collaboration;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.collaboration === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.collaboration === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable(workspace) {
  return computeAvailability(workspace) !== "Unavailable";
}

var Collaboration = {
  computeAvailability: computeAvailability,
  availableOnPlan: availableOnPlan,
  isAvailable: isAvailable
};

var getId = Models.Member.getId;

function getItems(workspace, _model) {
  var membersExcludingAvo = Belt_List.keep(workspace.members, (function (member) {
          return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(member.email), true, (function (email) {
                        if (email.endsWith("@avo.sh")) {
                          return false;
                        } else {
                          return !email.endsWith("@avo.app");
                        }
                      }));
        }));
  return Belt_List.keep(membersExcludingAvo, (function (member) {
                var match = AnalyticsRe.roleFromJs(member.role);
                if (match !== undefined) {
                  return match === "CommentOnly";
                } else {
                  return false;
                }
              }));
}

function getLimit(plan) {
  var count = plan.commentOnlyMembers;
  if (typeof count !== "object") {
    if (count === "NotAvailable") {
      return {
              NAME: "Limited",
              VAL: 0
            };
    } else {
      return "Unlimited";
    }
  } else if (count.TAG === "Limited") {
    return {
            NAME: "Limited",
            VAL: count._0
          };
  } else {
    return "Unlimited";
  }
}

function constructLimitExceeded(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits(workspace, model) {
  var match = getLimit(workspace.plan);
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(getItems(workspace, model)) > planEntityCountLimit) {
      var entityCount = Belt_List.length(getItems(workspace, model));
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = getLimit(trial.plan);
        trialEntityCount = typeof match$1 === "object" ? Caml.int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(getItems(workspace, model), (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = getLimit(publicPlan);
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(getLimit(workspace.plan));
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(getLimit(trial)) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId, entity)
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId, entity)
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "User",
                                        Curry._1(getId, entity)
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId, entity)
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = getLimit(match$3.plan);
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "User",
                      Belt_List.length(getItems(workspace, model)),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "User",
                  Belt_List.length(getItems(workspace, model)),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = getLimit(workspace.plan);
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return getLimit(trial.plan);
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(getItems(workspace, model)) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(getItems(workspace, model)) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "User"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(getItems(workspace, model)) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "User"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "User"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "User"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(getItems(workspace, model)) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "User"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "User"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "User";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "User";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "User";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var Commenters = {
  constructLimitExceeded: constructLimitExceeded,
  computeEntityNumericLimits: computeEntityNumericLimits,
  createActionStatus: createActionStatus,
  entityStatus: entityStatus,
  status: status
};

function computeAvailability$1(workspace) {
  var match = workspace.plan.configEventSourcesAndDestinations;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.configEventSourcesAndDestinations === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$1(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.configEventSourcesAndDestinations === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$1(workspace) {
  return computeAvailability$1(workspace) !== "Unavailable";
}

var ConfigEventSourcesAndDestinations = {
  computeAvailability: computeAvailability$1,
  availableOnPlan: availableOnPlan$1,
  isAvailable: isAvailable$1
};

function computeAvailability$2(workspace) {
  var match = workspace.plan.sso;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.sso === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$2(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.sso === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$2(workspace) {
  return computeAvailability$2(workspace) !== "Unavailable";
}

var Sso = {
  computeAvailability: computeAvailability$2,
  availableOnPlan: availableOnPlan$2,
  isAvailable: isAvailable$2
};

function computeAvailability$3(workspace) {
  var match = workspace.plan.canProtectMainBranch;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.canProtectMainBranch === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$3(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.canProtectMainBranch === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$3(workspace) {
  return computeAvailability$3(workspace) !== "Unavailable";
}

var CanProtectMainBranch = {
  computeAvailability: computeAvailability$3,
  availableOnPlan: availableOnPlan$3,
  isAvailable: isAvailable$3
};

function computeAvailability$4(workspace) {
  var match = workspace.plan.advancedProtectedMainBranch;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.advancedProtectedMainBranch === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$4(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.advancedProtectedMainBranch === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$4(workspace) {
  return computeAvailability$4(workspace) !== "Unavailable";
}

var AdvancedProtectedMainBranch = {
  computeAvailability: computeAvailability$4,
  availableOnPlan: availableOnPlan$4,
  isAvailable: isAvailable$4
};

function computeAvailability$5(workspace) {
  var match = workspace.plan.forceBranchAudit;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.forceBranchAudit === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$5(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.forceBranchAudit === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$5(workspace) {
  return computeAvailability$5(workspace) !== "Unavailable";
}

var ForceBranchAudit = {
  computeAvailability: computeAvailability$5,
  availableOnPlan: availableOnPlan$5,
  isAvailable: isAvailable$5
};

function computeAvailability$6(workspace) {
  var match = workspace.plan.hasAdvancedNameConventionsPredefinedValues;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.hasAdvancedNameConventionsPredefinedValues === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$6(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.hasAdvancedNameConventionsPredefinedValues === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$6(workspace) {
  return computeAvailability$6(workspace) !== "Unavailable";
}

var PredefinedValues = {
  computeAvailability: computeAvailability$6,
  availableOnPlan: availableOnPlan$6,
  isAvailable: isAvailable$6
};

function computeAvailability$7(workspace) {
  var match = workspace.plan.hasAdvancedNameConventionsCustomStructs;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.hasAdvancedNameConventionsCustomStructs === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$7(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.hasAdvancedNameConventionsCustomStructs === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$7(workspace) {
  return computeAvailability$7(workspace) !== "Unavailable";
}

var CustomStructs = {
  computeAvailability: computeAvailability$7,
  availableOnPlan: availableOnPlan$7,
  isAvailable: isAvailable$7
};

var AdvancedNamingConvention = {
  PredefinedValues: PredefinedValues,
  CustomStructs: CustomStructs
};

var Guardrails = {
  AdvancedNamingConvention: AdvancedNamingConvention
};

function computeEntityNumericLimits$1(workspace, currentDomainCount) {
  var match = workspace.plan.domainsIncluded;
  var planLimit = typeof match === "object" ? match.VAL : Pervasives.max_int;
  var trial = workspace.trial;
  var trialLimit;
  if (trial !== undefined) {
    var match$1 = trial.plan.domainsIncluded;
    trialLimit = typeof match$1 === "object" ? match$1.VAL : Pervasives.max_int;
  } else {
    trialLimit = 0;
  }
  var effectiveLimit = planLimit > trialLimit ? planLimit : trialLimit;
  if (currentDomainCount >= effectiveLimit) {
    return {
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "NumericLimit",
                  VAL: [
                    "Domain",
                    String(effectiveLimit)
                  ]
                },
                workspace.plan.name
              ]
            },
            tl: /* [] */0
          };
  } else if (currentDomainCount >= planLimit && currentDomainCount < trialLimit) {
    return {
            hd: {
              NAME: "AvailableDuringTrial",
              VAL: [
                {
                  NAME: "NumericLimit",
                  VAL: [
                    "Domain",
                    String(trialLimit)
                  ]
                },
                workspace.plan.name
              ]
            },
            tl: /* [] */0
          };
  } else if (currentDomainCount === (effectiveLimit - 1 | 0)) {
    return {
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "NumericLimit",
                  VAL: [
                    "Domain",
                    String(effectiveLimit)
                  ]
                },
                workspace.plan.name
              ]
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function createActionStatus$1(limits) {
  var match = Belt_List.head(limits);
  if (match === undefined) {
    return "Available";
  }
  if (typeof match !== "object") {
    return "Available";
  }
  var variant = match.NAME;
  if (variant === "LimitExceeded") {
    return "Unavailable";
  } else if (variant === "LimitReached" || variant !== "AvailableDuringTrial") {
    return "Available";
  } else {
    return "AvailableDuringTrial";
  }
}

var Domain = {
  computeEntityNumericLimits: computeEntityNumericLimits$1,
  createActionStatus: createActionStatus$1
};

function computeAvailability$8(workspace) {
  var match = workspace.plan.hasImpactedStakeholders;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.hasImpactedStakeholders === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$8(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.hasImpactedStakeholders === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$8(workspace) {
  return computeAvailability$8(workspace) !== "Unavailable";
}

var ImpactedStakeholders = {
  computeAvailability: computeAvailability$8,
  availableOnPlan: availableOnPlan$8,
  isAvailable: isAvailable$8
};

function computeAvailability$9(workspace) {
  var match = workspace.plan.approvalWorkflow;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.approvalWorkflow === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$9(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.approvalWorkflow === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$9(workspace) {
  return computeAvailability$9(workspace) !== "Unavailable";
}

var ApprovalWorkflow = {
  computeAvailability: computeAvailability$9,
  availableOnPlan: availableOnPlan$9,
  isAvailable: isAvailable$9
};

function computeAvailability$10(workspace) {
  var match = workspace.plan.propertyAbsenceByEventAndSource;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.propertyAbsenceByEventAndSource === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$10(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.propertyAbsenceByEventAndSource === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$10(workspace) {
  return computeAvailability$10(workspace) !== "Unavailable";
}

var PropertyAbsenceByEventAndSource = {
  computeAvailability: computeAvailability$10,
  availableOnPlan: availableOnPlan$10,
  isAvailable: isAvailable$10
};

function computeAvailability$11(workspace) {
  var match = workspace.plan.propertyMatchesBySource;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.propertyMatchesBySource === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$11(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.propertyMatchesBySource === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$11(workspace) {
  return computeAvailability$11(workspace) !== "Unavailable";
}

var PropertyMatchesBySource = {
  computeAvailability: computeAvailability$11,
  availableOnPlan: availableOnPlan$11,
  isAvailable: isAvailable$11
};

function computeAvailability$12(workspace) {
  var match = workspace.plan.activityLog;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.activityLog === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$12(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.activityLog === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$12(workspace) {
  return computeAvailability$12(workspace) !== "Unavailable";
}

var ActivityLogs = {
  computeAvailability: computeAvailability$12,
  availableOnPlan: availableOnPlan$12,
  isAvailable: isAvailable$12
};

function computeAvailability$13(workspace) {
  var match = workspace.plan.auditLog;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.auditLog === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$13(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.auditLog === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$13(workspace) {
  return computeAvailability$13(workspace) !== "Unavailable";
}

var AuditLog = {
  computeAvailability: computeAvailability$13,
  availableOnPlan: availableOnPlan$13,
  isAvailable: isAvailable$13
};

function constructLimitExceeded$1(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$2(workspace, model) {
  var match = workspace.plan.maxServiceAccounts;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) > planEntityCountLimit) {
      var entityCount = Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts));
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxServiceAccounts;
        trialEntityCount = typeof match$1 === "object" ? Caml.int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(Belt_List.fromArray(workspace.serviceAccounts), (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxServiceAccounts;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxServiceAccounts);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxServiceAccounts) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "ServiceAccount",
                                      entity.name
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "ServiceAccount",
                                      entity.name
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "ServiceAccount",
                                        entity.name
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "ServiceAccount",
                                      entity.name
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxServiceAccounts;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$1(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "ServiceAccount",
                      Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$1(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "ServiceAccount",
                  Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxServiceAccounts;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxServiceAccounts;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "ServiceAccount"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "ServiceAccount"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "ServiceAccount"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "ServiceAccount"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(Belt_List.fromArray(workspace.serviceAccounts)) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "ServiceAccount"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "ServiceAccount"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$2(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "ServiceAccount";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$1(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "ServiceAccount";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$1(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "ServiceAccount";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var ServiceAccount = {
  constructLimitExceeded: constructLimitExceeded$1,
  computeEntityNumericLimits: computeEntityNumericLimits$2,
  createActionStatus: createActionStatus$2,
  entityStatus: entityStatus$1,
  status: status$1
};

function constructLimitExceeded$2(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$3(workspace, model) {
  var match = workspace.plan.maxSources;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(model.sources) > planEntityCountLimit) {
      var entityCount = Belt_List.length(model.sources);
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxSources;
        trialEntityCount = typeof match$1 === "object" ? Caml.int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(model.sources, (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxSources;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxSources);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxSources) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Source",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Source",
                                      entity.id
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "Source",
                                        entity.id
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Source",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxSources;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$2(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "Source",
                      Belt_List.length(model.sources),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$2(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "Source",
                  Belt_List.length(model.sources),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxSources;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxSources;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(model.sources) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(model.sources) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "Source"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(model.sources) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Source"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Source"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "Source"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(model.sources) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Source"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Source"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$3(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Source";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$2(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "Source";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$2(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Source";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var Source = {
  constructLimitExceeded: constructLimitExceeded$2,
  computeEntityNumericLimits: computeEntityNumericLimits$3,
  createActionStatus: createActionStatus$3,
  entityStatus: entityStatus$2,
  status: status$2
};

function constructLimitExceeded$3(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$4(workspace, model) {
  var match = workspace.plan.maxDestinations;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(model.destinations) > planEntityCountLimit) {
      var entityCount = Belt_List.length(model.destinations);
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxDestinations;
        trialEntityCount = typeof match$1 === "object" ? Caml.int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(model.destinations, (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxDestinations;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxDestinations);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxDestinations) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Destination",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Destination",
                                      entity.id
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "Destination",
                                        entity.id
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Destination",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxDestinations;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$3(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "Destination",
                      Belt_List.length(model.destinations),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$3(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "Destination",
                  Belt_List.length(model.destinations),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxDestinations;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxDestinations;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(model.destinations) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(model.destinations) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "Destination"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(model.destinations) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Destination"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Destination"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "Destination"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(model.destinations) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Destination"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Destination"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$4(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Destination";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$3(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "Destination";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$3(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Destination";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var Destination = {
  constructLimitExceeded: constructLimitExceeded$3,
  computeEntityNumericLimits: computeEntityNumericLimits$4,
  createActionStatus: createActionStatus$4,
  entityStatus: entityStatus$3,
  status: status$3
};

function constructLimitExceeded$4(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$5(workspace, model) {
  var match = workspace.plan.maxIntegrations;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(model.integrations) > planEntityCountLimit) {
      var entityCount = Belt_List.length(model.integrations);
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxIntegrations;
        trialEntityCount = typeof match$1 === "object" ? Caml.int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(model.integrations, (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxIntegrations;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxIntegrations);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxIntegrations) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Integration",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Integration",
                                      entity.id
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "Integration",
                                        entity.id
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "Integration",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxIntegrations;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$4(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "Integration",
                      Belt_List.length(model.integrations),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$4(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "Integration",
                  Belt_List.length(model.integrations),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxIntegrations;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxIntegrations;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(model.integrations) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(model.integrations) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "Integration"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(model.integrations) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Integration"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "Integration"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "Integration"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(model.integrations) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Integration"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "Integration"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$5(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Integration";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$4(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "Integration";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$4(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "Integration";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var Integration = {
  constructLimitExceeded: constructLimitExceeded$4,
  computeEntityNumericLimits: computeEntityNumericLimits$5,
  createActionStatus: createActionStatus$5,
  entityStatus: entityStatus$4,
  status: status$4
};

var getId$1 = Models.Member.getId;

function getItems$1(workspace, _model) {
  var membersExcludingAvo = Belt_List.keep(workspace.members, (function (member) {
          return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(member.email), true, (function (email) {
                        if (email.endsWith("@avo.sh")) {
                          return false;
                        } else {
                          return !email.endsWith("@avo.app");
                        }
                      }));
        }));
  return Belt_List.keep(membersExcludingAvo, (function (member) {
                var match = AnalyticsRe.roleFromJs(member.role);
                if (match !== undefined && !(match === "Admin" || match === "CodegenAccess" || match === "Editor")) {
                  if (match === "Viewer") {
                    return workspace.plan.countsViewersAsUsers;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }));
}

function constructLimitExceeded$5(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$6(workspace, model) {
  var match = workspace.plan.maxUsers;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(getItems$1(workspace, model)) > planEntityCountLimit) {
      var entityCount = Belt_List.length(getItems$1(workspace, model));
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxUsers;
        trialEntityCount = typeof match$1 === "object" ? Caml.int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(getItems$1(workspace, model), (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxUsers;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxUsers);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxUsers) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId$1, entity)
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId$1, entity)
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "User",
                                        Curry._1(getId$1, entity)
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "User",
                                      Curry._1(getId$1, entity)
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxUsers;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$5(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "User",
                      Belt_List.length(getItems$1(workspace, model)),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$5(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "User",
                  Belt_List.length(getItems$1(workspace, model)),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxUsers;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxUsers;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(getItems$1(workspace, model)) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(getItems$1(workspace, model)) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "User"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(getItems$1(workspace, model)) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "User"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "User"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "User"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(getItems$1(workspace, model)) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "User"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "User"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$6(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "User";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$5(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "User";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$5(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "User";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var User = {
  constructLimitExceeded: constructLimitExceeded$5,
  computeEntityNumericLimits: computeEntityNumericLimits$6,
  createActionStatus: createActionStatus$6,
  entityStatus: entityStatus$5,
  status: status$5
};

function computeAvailability$14(workspace, step) {
  var match = workspace.plan.inspectorLookback;
  var match$1 = workspace.trial;
  if (Caml_obj.greaterequal(Belt_Array.getIndexBy(InspectorViewModel.timeWindowLadder, (function (ladderStep) {
                return Caml_obj.equal(ladderStep, match);
              })), Belt_Array.getIndexBy(InspectorViewModel.timeWindowLadder, (function (ladderStep) {
                return Caml_obj.equal(ladderStep, step);
              })))) {
    return "Available";
  } else if (match$1 !== undefined && Caml_obj.greaterequal(Belt_Array.getIndexBy(InspectorViewModel.timeWindowLadder, (function (ladderStep) {
                return Caml_obj.equal(ladderStep, match$1.plan.inspectorLookback);
              })), Belt_Array.getIndexBy(InspectorViewModel.timeWindowLadder, (function (ladderStep) {
                return Caml_obj.equal(ladderStep, step);
              })))) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$14(workspace, step) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (Caml_obj.equal(plan.inspectorLookback, step) || plan.name === "Enterprise") {
      return plan.name;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise.name;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

var InspectorLookback = {
  computeAvailability: computeAvailability$14,
  availableOnPlan: availableOnPlan$14
};

function constructLimitExceeded$6(trial, limit, planHint) {
  if (trial === undefined) {
    return {
            NAME: "LimitExceeded",
            VAL: [
              limit,
              planHint
            ]
          };
  } else {
    return {
            NAME: "AvailableDuringTrial",
            VAL: [
              limit,
              planHint
            ]
          };
  }
}

function computeEntityNumericLimits$7(workspace, model) {
  var match = workspace.plan.maxCodegenEvents;
  var limits;
  if (typeof match === "object" && match.NAME === "Limited") {
    var planEntityCountLimit = match.VAL;
    if (Belt_List.length(model.events) > planEntityCountLimit) {
      var entityCount = Belt_List.length(model.events);
      var entityCountBeyondPlan = entityCount - planEntityCountLimit | 0;
      var trial = workspace.trial;
      var trialEntityCount;
      if (trial !== undefined) {
        var match$1 = trial.plan.maxCodegenEvents;
        trialEntityCount = typeof match$1 === "object" ? Caml.int_min(match$1.VAL - planEntityCountLimit | 0, entityCountBeyondPlan) : entityCountBeyondPlan;
      } else {
        trialEntityCount = 0;
      }
      var trialEntityCount$1 = trialEntityCount < 0 ? (console.log("Clipping trialEntityCount to 0 from", trialEntityCount), 0) : trialEntityCount;
      var limitToInt = function (limit) {
        if (typeof limit === "object") {
          return limit.VAL;
        } else {
          return Pervasives.max_int;
        }
      };
      var limits$1 = Belt_List.flatten(Belt_List.mapWithIndexU(model.events, (function (entityIndex, entity) {
                  var publicPlans = Belt_List.map(Workspace.Instances.publicPlans, (function (publicPlanName) {
                          return Workspace.Instances.planWithOverrides(publicPlanName, undefined);
                        }));
                  var minimumRequiredPlan = Belt_List.getBy(publicPlans, (function (publicPlan) {
                          var match = publicPlan.maxCodegenEvents;
                          if (typeof match === "object") {
                            return match.VAL > entityIndex;
                          } else {
                            return true;
                          }
                        }));
                  var currentPlanLimit = limitToInt(workspace.plan.maxCodegenEvents);
                  var trial = Belt_Option.map(workspace.trial, (function (trial) {
                          return trial.plan;
                        }));
                  var currentTrialLimit = trial !== undefined ? limitToInt(trial.maxCodegenEvents) : 0;
                  var exit = 0;
                  if (minimumRequiredPlan !== undefined) {
                    if (entityIndex === (currentPlanLimit - 1 | 0)) {
                      return {
                              hd: {
                                NAME: "LimitReached",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "CodegenEvent",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    exit = 1;
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    if (entityIndex < currentPlanLimit) {
                      return /* [] */0;
                    }
                    if (minimumRequiredPlan === undefined) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "CodegenEvent",
                                      entity.id
                                    ]
                                  },
                                  Workspace.Instances.enterprise
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    var exit$1 = 0;
                    if (trial !== undefined) {
                      if (entityIndex < currentTrialLimit) {
                        return {
                                hd: {
                                  NAME: "AvailableDuringTrial",
                                  VAL: [
                                    {
                                      NAME: "NumericLimit",
                                      VAL: [
                                        "CodegenEvent",
                                        entity.id
                                      ]
                                    },
                                    minimumRequiredPlan
                                  ]
                                },
                                tl: /* [] */0
                              };
                      }
                      exit$1 = 2;
                    } else {
                      exit$1 = 2;
                    }
                    if (exit$1 === 2) {
                      return {
                              hd: {
                                NAME: "LimitExceeded",
                                VAL: [
                                  {
                                    NAME: "NumericLimit",
                                    VAL: [
                                      "CodegenEvent",
                                      entity.id
                                    ]
                                  },
                                  minimumRequiredPlan
                                ]
                              },
                              tl: /* [] */0
                            };
                    }
                    
                  }
                  
                })));
      var match$2 = trialEntityCount$1 > 0;
      var match$3 = workspace.trial;
      var exit = 0;
      if (match$2 && match$3 !== undefined) {
        var match$4 = match$3.plan.maxCodegenEvents;
        limits = typeof match$4 === "object" ? ({
              hd: constructLimitExceeded$6(workspace.trial, {
                    NAME: "NumericListLimit",
                    VAL: [
                      "CodegenEvent",
                      Belt_List.length(model.events),
                      match$4.VAL
                    ]
                  }, Workspace.Instances.nextHint(workspace.plan)),
              tl: limits$1
            }) : limits$1;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        limits = {
          hd: constructLimitExceeded$6(workspace.trial, {
                NAME: "NumericListLimit",
                VAL: [
                  "CodegenEvent",
                  Belt_List.length(model.events),
                  planEntityCountLimit
                ]
              }, Workspace.Instances.nextHint(workspace.plan)),
          tl: limits$1
        };
      }
      
    } else {
      limits = /* [] */0;
    }
  } else {
    limits = /* [] */0;
  }
  var match$5 = workspace.plan.maxCodegenEvents;
  var match$6 = Belt_Option.map(workspace.trial, (function (trial) {
          return trial.plan.maxCodegenEvents;
        }));
  var tmp;
  if (typeof match$5 === "object") {
    var maxPlanEntities = match$5.VAL;
    if (Belt_List.length(model.events) < maxPlanEntities) {
      tmp = /* [] */0;
    } else if (match$6 !== undefined) {
      if (typeof match$6 === "object") {
        var maxTrialEntities = match$6.VAL;
        tmp = Belt_List.length(model.events) < maxTrialEntities ? ({
              hd: {
                NAME: "AvailableDuringTrial",
                VAL: [
                  {
                    NAME: "CreateEntityStatus",
                    VAL: "CodegenEvent"
                  },
                  Workspace.Instances.nextHint(workspace.plan)
                ]
              },
              tl: /* [] */0
            }) : (
            Belt_List.length(model.events) === maxTrialEntities ? ({
                  hd: {
                    NAME: "LimitReached",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "CodegenEvent"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                }) : ({
                  hd: {
                    NAME: "LimitExceeded",
                    VAL: [
                      {
                        NAME: "CreateEntityStatus",
                        VAL: "CodegenEvent"
                      },
                      Workspace.Instances.nextHint(workspace.plan)
                    ]
                  },
                  tl: /* [] */0
                })
          );
      } else {
        tmp = {
          hd: {
            NAME: "AvailableDuringTrial",
            VAL: [
              {
                NAME: "CreateEntityStatus",
                VAL: "CodegenEvent"
              },
              Workspace.Instances.nextHint(workspace.plan)
            ]
          },
          tl: /* [] */0
        };
      }
    } else {
      tmp = Belt_List.length(model.events) === maxPlanEntities ? ({
            hd: {
              NAME: "LimitReached",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "CodegenEvent"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          }) : ({
            hd: {
              NAME: "LimitExceeded",
              VAL: [
                {
                  NAME: "CreateEntityStatus",
                  VAL: "CodegenEvent"
                },
                Workspace.Instances.nextHint(workspace.plan)
              ]
            },
            tl: /* [] */0
          });
    }
  } else {
    tmp = /* [] */0;
  }
  return Belt_List.concat(limits, tmp);
}

function createActionStatus$7(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "CodegenEvent";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

function entityStatus$6(workspaceLimits, entityId) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    var entity;
                    var id;
                    var variant = limit.NAME;
                    if (variant === "LimitReached") {
                      var match = limit.VAL[0];
                      if (typeof match !== "object") {
                        return false;
                      }
                      if (match.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$1 = match.VAL;
                      entity = match$1[0];
                      id = match$1[1];
                    } else if (variant === "AvailableDuringTrial") {
                      var match$2 = limit.VAL[0];
                      if (typeof match$2 !== "object") {
                        return false;
                      }
                      if (match$2.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$3 = match$2.VAL;
                      entity = match$3[0];
                      id = match$3[1];
                    } else {
                      var match$4 = limit.VAL[0];
                      if (typeof match$4 !== "object") {
                        return false;
                      }
                      if (match$4.NAME !== "NumericLimit") {
                        return false;
                      }
                      var match$5 = match$4.VAL;
                      entity = match$5[0];
                      id = match$5[1];
                    }
                    if (entityId === id) {
                      return entity === "CodegenEvent";
                    } else {
                      return false;
                    }
                  })), [
              "Available",
              undefined
            ], (function (limit) {
                var variant = limit.NAME;
                if (variant === "LimitReached") {
                  return [
                          "Available",
                          limit.VAL[1]
                        ];
                } else if (variant === "AvailableDuringTrial") {
                  return [
                          "AvailableDuringTrial",
                          limit.VAL[1]
                        ];
                } else {
                  return [
                          "Unavailable",
                          limit.VAL[1]
                        ];
                }
              }));
}

function status$6(workspaceLimits) {
  return Belt_Option.mapWithDefault(Belt_List.getBy(workspaceLimits, (function (limit) {
                    if (typeof limit !== "object") {
                      return false;
                    }
                    var variant = limit.NAME;
                    if (!(variant === "AvailableDuringTrial" || variant === "LimitExceeded")) {
                      return false;
                    }
                    var match = limit.VAL[0];
                    if (typeof match === "object" && match.NAME === "CreateEntityStatus") {
                      return match.VAL === "CodegenEvent";
                    } else {
                      return false;
                    }
                  })), "Available", (function (limit) {
                if (typeof limit === "object" && limit.NAME === "AvailableDuringTrial") {
                  return "AvailableDuringTrial";
                } else {
                  return "Unavailable";
                }
              }));
}

var CodegenEvents = {
  constructLimitExceeded: constructLimitExceeded$6,
  computeEntityNumericLimits: computeEntityNumericLimits$7,
  createActionStatus: createActionStatus$7,
  entityStatus: entityStatus$6,
  status: status$6
};

function computeAvailability$15(workspace) {
  var match = workspace.plan.triggers;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.triggers === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$15(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.triggers === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$14(workspace) {
  return computeAvailability$15(workspace) !== "Unavailable";
}

var Triggers = {
  computeAvailability: computeAvailability$15,
  availableOnPlan: availableOnPlan$15,
  isAvailable: isAvailable$14
};

function computeAvailability$16(workspace) {
  var match = workspace.plan.eventVariants;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.eventVariants === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$16(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.eventVariants === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$15(workspace) {
  return computeAvailability$16(workspace) !== "Unavailable";
}

var EventVariants = {
  computeAvailability: computeAvailability$16,
  availableOnPlan: availableOnPlan$16,
  isAvailable: isAvailable$15
};

function computeAvailability$17(workspace) {
  var match = workspace.plan.discrepancyConfig;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.discrepancyConfig === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$17(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.discrepancyConfig === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$16(workspace) {
  return computeAvailability$17(workspace) !== "Unavailable";
}

var DiscrepancyConfig = {
  computeAvailability: computeAvailability$17,
  availableOnPlan: availableOnPlan$17,
  isAvailable: isAvailable$16
};

function computeAvailability$18(workspace) {
  var match = workspace.plan.forcePreferredCasing;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.forcePreferredCasing === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$18(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.forcePreferredCasing === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$17(workspace) {
  return computeAvailability$18(workspace) !== "Unavailable";
}

var ForcePreferredCasing = {
  computeAvailability: computeAvailability$18,
  availableOnPlan: availableOnPlan$18,
  isAvailable: isAvailable$17
};

function computeLimits(workspace, model) {
  return Belt_List.concatMany([
              computeEntityNumericLimits$3(workspace, model),
              computeEntityNumericLimits$4(workspace, model),
              computeEntityNumericLimits$5(workspace, model),
              computeEntityNumericLimits$6(workspace, model),
              computeEntityNumericLimits$7(workspace, model)
            ]);
}

function entityStatus$7(limits, entityId, entityType) {
  if (entityType === "Source") {
    return entityStatus$2(limits, entityId);
  } else if (entityType === "Destination") {
    return entityStatus$3(limits, entityId);
  } else if (entityType === "Integration") {
    return entityStatus$4(limits, entityId);
  } else {
    return entityStatus$5(limits, entityId);
  }
}

function hoursLeftOfTrial(workspace) {
  return Belt_Option.mapWithDefault(workspace.trial, 0, (function (trial) {
                return DateFns.differenceInHours(trial.endAt, new Date()) | 0;
              }));
}

function computeAvailability$19(workspace) {
  var match = workspace.plan.pinnedProperties;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.pinnedProperties === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$19(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.pinnedProperties === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$18(workspace) {
  return computeAvailability$19(workspace) !== "Unavailable";
}

var PinnedProperties = {
  computeAvailability: computeAvailability$19,
  availableOnPlan: availableOnPlan$19,
  isAvailable: isAvailable$18
};

function computeAvailability$20(workspace) {
  var match = workspace.plan.nameMapping;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.nameMapping === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$20(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.nameMapping === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$19(workspace) {
  return computeAvailability$20(workspace) !== "Unavailable";
}

var NameMapping = {
  computeAvailability: computeAvailability$20,
  availableOnPlan: availableOnPlan$20,
  isAvailable: isAvailable$19
};

function computeAvailability$21(workspace) {
  var match = workspace.plan.editors;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.editors === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$21(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.editors === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$20(workspace) {
  return computeAvailability$21(workspace) !== "Unavailable";
}

var Editors = {
  computeAvailability: computeAvailability$21,
  availableOnPlan: availableOnPlan$21,
  isAvailable: isAvailable$20
};

function computeAvailability$22(workspace) {
  var match = workspace.plan.canDuplicateItems;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.canDuplicateItems === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$22(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.canDuplicateItems === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$21(workspace) {
  return computeAvailability$22(workspace) !== "Unavailable";
}

var DuplicateItems = {
  computeAvailability: computeAvailability$22,
  availableOnPlan: availableOnPlan$22,
  isAvailable: isAvailable$21
};

function computeAvailability$23(workspace) {
  var match = workspace.plan.expandedBranchAudit;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.expandedBranchAudit === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$23(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.expandedBranchAudit === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$22(workspace) {
  return computeAvailability$23(workspace) !== "Unavailable";
}

var ExpandedBranchAudit = {
  computeAvailability: computeAvailability$23,
  availableOnPlan: availableOnPlan$23,
  isAvailable: isAvailable$22
};

function computeAvailability$24(workspace) {
  var match = workspace.plan.closedBranches;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.closedBranches === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$24(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.closedBranches === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$23(workspace) {
  return computeAvailability$24(workspace) !== "Unavailable";
}

var ClosedBranches = {
  computeAvailability: computeAvailability$24,
  availableOnPlan: availableOnPlan$24,
  isAvailable: isAvailable$23
};

function getInspectorVolumeStatus(workspace) {
  return workspace.planUsageStatus.inspectorEvents;
}

function isInspectorBlockedWhenLimitExceeded(workspace) {
  var plan = Belt_Option.mapWithDefault(workspace.trial, workspace.plan, (function (trial) {
          return trial.plan;
        }));
  return plan.inspectorEvents.usageLimit !== "Unlimited";
}

function isInspectorVolumeBlocked(workspace) {
  var match = workspace.planUsageStatus.inspectorEvents;
  if (typeof match === "object" && match.NAME === "ExceededThreshold" && isInspectorBlockedWhenLimitExceeded(workspace)) {
    return true;
  } else {
    return false;
  }
}

function computeAvailability$25(workspace) {
  var match = workspace.plan.viewersCanComment;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.viewersCanComment === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$25(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.viewersCanComment === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$24(workspace) {
  return computeAvailability$25(workspace) !== "Unavailable";
}

var ViewersCanComment = {
  computeAvailability: computeAvailability$25,
  availableOnPlan: availableOnPlan$25,
  isAvailable: isAvailable$24
};

function memberRoleStringToVariant(roleStr) {
  var match = roleStr.replace(" ", "").toLowerCase();
  switch (match) {
    case "admin" :
        return "Admin";
    case "codegenaccess" :
        return "CodegenAccess";
    case "commentonly" :
        return "CommentOnly";
    case "editor" :
        return "Editor";
    case "viewer" :
        return "Viewer";
    default:
      return ;
  }
}

function canCodegen(workspace, member) {
  var plan = Belt_Option.mapWithDefault(workspace.trial, workspace.plan, (function (trial) {
          return trial.plan;
        }));
  var role = memberRoleStringToVariant(member.role);
  var hasCodegenAccessRole = FeatureFlag.featureEnabledForWorkspace(workspace, "CodegenAccessRole");
  var match = plan.name;
  if (role === undefined) {
    return "Unavailable";
  }
  if (role === "Admin" || role === "Editor") {
    return "Available";
  }
  if (role === "CodegenAccess") {
    if (hasCodegenAccessRole) {
      return "Available";
    } else {
      return "Unavailable";
    }
  }
  if (!(role === "CommentOnly" || role === "Viewer")) {
    return "Unavailable";
  }
  switch (match) {
    case "FreeV3" :
        return {
                NAME: "AvailableOnUpgrade",
                VAL: Workspace.Instances.teamV3
              };
    case "TeamV3" :
    case "TeamV3Annual" :
    case "EnterpriseV2" :
        return "Available";
    default:
      return "Unavailable";
  }
}

function getCanCodegenErrorMessage(workspace, canCodegen) {
  var hasCodegenAccessRole = FeatureFlag.featureEnabledForWorkspace(workspace, "CodegenAccessRole");
  if (typeof canCodegen === "object") {
    return "Your role lacks the necessary permissions to run Codegen under the current plan. To resolve this, please ask an admin to either elevate your role to an Editor or upgrade the plan to include Codegen access for your role.";
  } else if (canCodegen === "Available") {
    return ;
  } else if (hasCodegenAccessRole) {
    return "Running Codegen requires Editor, Admin or Codegen Access role. Please contact an Admin to request a role upgrade.";
  } else {
    return "Running Codegen requires Editor or Admin role. Please contact an Admin to request a role upgrade.";
  }
}

var CodegenAvailability = {
  memberRoleStringToVariant: memberRoleStringToVariant,
  canCodegen: canCodegen,
  getCanCodegenErrorMessage: getCanCodegenErrorMessage
};

function computeAvailability$26(workspace) {
  var match = workspace.plan.pushPullBetweenBranches;
  var match$1 = workspace.trial;
  if (match) {
    return "Available";
  } else if (match$1 !== undefined && match$1.plan.pushPullBetweenBranches === true) {
    return "AvailableDuringTrial";
  } else {
    return "Unavailable";
  }
}

function availableOnPlan$26(workspace) {
  var _plan = workspace.plan;
  while(true) {
    var plan = _plan;
    if (plan.pushPullBetweenBranches === true || Workspace.isEnterprise(plan.name)) {
      return plan;
    }
    if (Caml_obj.equal(plan, Workspace.Instances.nextHint(plan))) {
      return Workspace.Instances.enterprise;
    }
    _plan = Workspace.Instances.nextHint(plan);
    continue ;
  };
}

function isAvailable$25(workspace) {
  return computeAvailability$26(workspace) !== "Unavailable";
}

var PushPullBetweenBranches = {
  computeAvailability: computeAvailability$26,
  availableOnPlan: availableOnPlan$26,
  isAvailable: isAvailable$25
};

export {
  BooleanLimits ,
  LadderLimits ,
  NumericLimits ,
  Collaboration ,
  Commenters ,
  ConfigEventSourcesAndDestinations ,
  Sso ,
  CanProtectMainBranch ,
  AdvancedProtectedMainBranch ,
  ForceBranchAudit ,
  Guardrails ,
  Domain ,
  ImpactedStakeholders ,
  ApprovalWorkflow ,
  PropertyAbsenceByEventAndSource ,
  PropertyMatchesBySource ,
  ActivityLogs ,
  AuditLog ,
  ServiceAccount ,
  Source ,
  Destination ,
  Integration ,
  User ,
  InspectorLookback ,
  CodegenEvents ,
  Triggers ,
  EventVariants ,
  DiscrepancyConfig ,
  ForcePreferredCasing ,
  computeLimits ,
  entityStatus$7 as entityStatus,
  hoursLeftOfTrial ,
  PinnedProperties ,
  NameMapping ,
  Editors ,
  DuplicateItems ,
  ExpandedBranchAudit ,
  ClosedBranches ,
  getInspectorVolumeStatus ,
  isInspectorBlockedWhenLimitExceeded ,
  isInspectorVolumeBlocked ,
  ViewersCanComment ,
  CodegenAvailability ,
  PushPullBetweenBranches ,
}
/* Models Not a pure module */
