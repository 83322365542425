// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sentry from "../externals/Sentry.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as ImportParserTypes from "./ImportParserTypes.mjs";
import * as ImportFormatsSegmentCustomParser from "./ImportFormatsSegmentCustomParser.mjs";
import * as ImportFormatsCustomSpreadsheetFormatOneParser from "./ImportFormatsCustomSpreadsheetFormatOneParser.mjs";

function parsePropertyType(type_) {
  var propertyType = type_.toLocaleLowerCase();
  switch (propertyType) {
    case "bool" :
    case "boolean" :
        return [
                "bool",
                undefined
              ];
    case "date" :
        return [
                "string",
                "'date' is imported as 'string'"
              ];
    case "datetime" :
        return [
                "string",
                "'datetime' is imported as 'string'"
              ];
    case "double" :
        return [
                "float",
                "'double' is imported as 'float'"
              ];
    case "incremental" :
        return [
                "int",
                "'incremental' is imported as 'int'"
              ];
    case "int" :
    case "integer" :
        return [
                "int",
                undefined
              ];
    case "" :
    case "#N/A" :
    case "any" :
    case "array" :
    case "list" :
        return [
                "any",
                undefined
              ];
    case "long" :
        return [
                "int",
                "'long' is imported as 'int'"
              ];
    case "float" :
    case "number" :
    case "numeric" :
        return [
                "float",
                undefined
              ];
    case "object" :
        return [
                "object",
                undefined
              ];
    case "enum" :
    case "string" :
        return [
                "string",
                undefined
              ];
    default:
      Sentry.captureException({
            RE_EXN_ID: ImportParserTypes.ParseWarning,
            _1: "type parsing warning"
          }, {
            unrecognizedType: propertyType
          });
      return [
              "any",
              "Unsupported property type: '" + propertyType + "'. Defaulting to 'any'."
            ];
  }
}

function parsePropertyRequired(required) {
  var isRequired = required.toLocaleLowerCase().trim();
  switch (isRequired) {
    case "false" :
    case "n" :
    case "no" :
    case "optional" :
        return false;
    case "" :
    case "required" :
    case "true" :
    case "y" :
    case "yes" :
        return true;
    default:
      console.error("Unsupported property required: " + isRequired);
      return Pervasives.failwith("Unsupported property required: " + isRequired);
  }
}

function parsePropertyList(isPropertyList) {
  var isArray = isPropertyList.toLocaleLowerCase().trim();
  switch (isArray) {
    case "" :
    case "false" :
    case "n" :
    case "no" :
        return false;
    case "array" :
    case "true" :
    case "y" :
    case "yes" :
        return true;
    default:
      console.error("Unsupported property is array value: " + isArray);
      return Pervasives.failwith("Unsupported property is array value: " + isArray);
  }
}

function parseStringArray(stringArray) {
  return Belt_Array.keepU(Belt_Array.map(stringArray.includes(",") ? stringArray.split(",") : (
                    stringArray.includes("\n") ? stringArray.split("\n") : stringArray.split(";")
                  ), (function (prim) {
                    return prim.trim();
                  })), (function (item) {
                if (item !== "" && item !== "\"\"") {
                  return item !== "#N/A";
                } else {
                  return false;
                }
              }));
}

function parseSendAs(value) {
  var match = value.toLocaleLowerCase();
  switch (match) {
    case "system" :
    case "system property" :
        return "SystemProperty";
    case "user" :
    case "user property" :
        return "UserProperty";
    default:
      return "EventProperty";
  }
}

var amplitudeExport_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Event"
};

var amplitudeExport_eventDescriptionParser = {
  TAG: "Read",
  _0: "Event Description"
};

var amplitudeExport_eventCategoriesParser = {
  TAG: "MapStringArray",
  _0: "Category",
  _1: parseStringArray
};

var amplitudeExport_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeExport_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeExport_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeExport_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeExport_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeExport_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var amplitudeExport_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Event Property"
};

var amplitudeExport_propertyDescriptionParser = {
  TAG: "Read",
  _0: "Property Description"
};

var amplitudeExport_propertyTypeParser = {
  TAG: "Map",
  _0: "Property Type",
  _1: parsePropertyType
};

var amplitudeExport_propertyRequiredParser = {
  TAG: "MapBoolean",
  _0: "Property Is Required",
  _1: parsePropertyRequired
};

var amplitudeExport_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Property Is Array",
  _1: parsePropertyList
};

var amplitudeExport_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Property Enum Options",
  _1: parseStringArray
};

var amplitudeExport_propertySendAsParser = {
  TAG: "SkipSendAs",
  _0: "EventProperty"
};

var amplitudeExport_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeExport_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeExport_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeExport_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeExport_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeExport_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeExport = {
  formatName: "Amplitude CSV Export",
  eventNameParser: amplitudeExport_eventNameParser,
  eventDescriptionParser: amplitudeExport_eventDescriptionParser,
  eventCategoriesParser: amplitudeExport_eventCategoriesParser,
  eventTagsParser: amplitudeExport_eventTagsParser,
  eventSourcesParser: amplitudeExport_eventSourcesParser,
  eventStakeholderDomainsParser: amplitudeExport_eventStakeholderDomainsParser,
  eventNameMappingParser: amplitudeExport_eventNameMappingParser,
  eventPropertyBundleNameParser: amplitudeExport_eventPropertyBundleNameParser,
  eventVersionParser: amplitudeExport_eventVersionParser,
  propertyNameParser: amplitudeExport_propertyNameParser,
  propertyDescriptionParser: amplitudeExport_propertyDescriptionParser,
  propertyTypeParser: amplitudeExport_propertyTypeParser,
  propertyRequiredParser: amplitudeExport_propertyRequiredParser,
  propertyListParser: amplitudeExport_propertyListParser,
  propertyMatchesParser: amplitudeExport_propertyMatchesParser,
  propertySendAsParser: amplitudeExport_propertySendAsParser,
  propertyPropertyBundleNameParser: amplitudeExport_propertyPropertyBundleNameParser,
  propertyEventsParser: amplitudeExport_propertyEventsParser,
  propertyNameMappingParser: amplitudeExport_propertyNameMappingParser,
  propertyPinnedValueParser: amplitudeExport_propertyPinnedValueParser,
  propertyBundleRowNameParser: amplitudeExport_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: amplitudeExport_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var mixpanelExport_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Entity Name"
};

var mixpanelExport_eventDescriptionParser = {
  TAG: "Read",
  _0: "Entity Description"
};

var mixpanelExport_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelExport_eventTagsParser = {
  TAG: "MapStringArray",
  _0: "Event Tags",
  _1: parseStringArray
};

var mixpanelExport_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelExport_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelExport_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelExport_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelExport_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var mixpanelExport_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Property Name"
};

var mixpanelExport_propertyDescriptionParser = {
  TAG: "Read",
  _0: "Property Description"
};

var mixpanelExport_propertyTypeParser = {
  TAG: "Map",
  _0: "Property Type",
  _1: parsePropertyType
};

var mixpanelExport_propertyRequiredParser = {
  TAG: "MapBoolean",
  _0: "Property Required",
  _1: parsePropertyRequired
};

var mixpanelExport_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Property Type",
  _1: (function (type_) {
      if (type_ === "list") {
        return true;
      } else {
        return type_ === "array";
      }
    })
};

var mixpanelExport_propertyMatchesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelExport_propertySendAsParser = {
  TAG: "SkipSendAs",
  _0: "EventProperty"
};

var mixpanelExport_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelExport_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelExport_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelExport_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelExport_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelExport_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelExport = {
  formatName: "Mixpanel CSV Export",
  eventNameParser: mixpanelExport_eventNameParser,
  eventDescriptionParser: mixpanelExport_eventDescriptionParser,
  eventCategoriesParser: mixpanelExport_eventCategoriesParser,
  eventTagsParser: mixpanelExport_eventTagsParser,
  eventSourcesParser: mixpanelExport_eventSourcesParser,
  eventStakeholderDomainsParser: mixpanelExport_eventStakeholderDomainsParser,
  eventNameMappingParser: mixpanelExport_eventNameMappingParser,
  eventPropertyBundleNameParser: mixpanelExport_eventPropertyBundleNameParser,
  eventVersionParser: mixpanelExport_eventVersionParser,
  propertyNameParser: mixpanelExport_propertyNameParser,
  propertyDescriptionParser: mixpanelExport_propertyDescriptionParser,
  propertyTypeParser: mixpanelExport_propertyTypeParser,
  propertyRequiredParser: mixpanelExport_propertyRequiredParser,
  propertyListParser: mixpanelExport_propertyListParser,
  propertyMatchesParser: mixpanelExport_propertyMatchesParser,
  propertySendAsParser: mixpanelExport_propertySendAsParser,
  propertyPropertyBundleNameParser: mixpanelExport_propertyPropertyBundleNameParser,
  propertyEventsParser: mixpanelExport_propertyEventsParser,
  propertyNameMappingParser: mixpanelExport_propertyNameMappingParser,
  propertyPinnedValueParser: mixpanelExport_propertyPinnedValueParser,
  propertyBundleRowNameParser: mixpanelExport_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: mixpanelExport_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var mixpanelLegacyExport_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Event"
};

var mixpanelLegacyExport_eventDescriptionParser = {
  TAG: "Read",
  _0: "Event Description"
};

var mixpanelLegacyExport_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelLegacyExport_eventTagsParser = {
  TAG: "MapStringArray",
  _0: "Event Tags",
  _1: parseStringArray
};

var mixpanelLegacyExport_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelLegacyExport_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelLegacyExport_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelLegacyExport_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelLegacyExport_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var mixpanelLegacyExport_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Event Property"
};

var mixpanelLegacyExport_propertyDescriptionParser = {
  TAG: "Read",
  _0: "Property Description"
};

var mixpanelLegacyExport_propertyTypeParser = {
  TAG: "Map",
  _0: "Property Type",
  _1: parsePropertyType
};

var mixpanelLegacyExport_propertyRequiredParser = {
  TAG: "SkipBoolean",
  _0: false
};

var mixpanelLegacyExport_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Property Type",
  _1: (function (type_) {
      if (type_ === "list") {
        return true;
      } else {
        return type_ === "array";
      }
    })
};

var mixpanelLegacyExport_propertyMatchesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelLegacyExport_propertySendAsParser = {
  TAG: "SkipSendAs",
  _0: "EventProperty"
};

var mixpanelLegacyExport_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelLegacyExport_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelLegacyExport_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelLegacyExport_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelLegacyExport_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelLegacyExport_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelLegacyExport = {
  formatName: "Mixpanel Legacy CSV Export",
  eventNameParser: mixpanelLegacyExport_eventNameParser,
  eventDescriptionParser: mixpanelLegacyExport_eventDescriptionParser,
  eventCategoriesParser: mixpanelLegacyExport_eventCategoriesParser,
  eventTagsParser: mixpanelLegacyExport_eventTagsParser,
  eventSourcesParser: mixpanelLegacyExport_eventSourcesParser,
  eventStakeholderDomainsParser: mixpanelLegacyExport_eventStakeholderDomainsParser,
  eventNameMappingParser: mixpanelLegacyExport_eventNameMappingParser,
  eventPropertyBundleNameParser: mixpanelLegacyExport_eventPropertyBundleNameParser,
  eventVersionParser: mixpanelLegacyExport_eventVersionParser,
  propertyNameParser: mixpanelLegacyExport_propertyNameParser,
  propertyDescriptionParser: mixpanelLegacyExport_propertyDescriptionParser,
  propertyTypeParser: mixpanelLegacyExport_propertyTypeParser,
  propertyRequiredParser: mixpanelLegacyExport_propertyRequiredParser,
  propertyListParser: mixpanelLegacyExport_propertyListParser,
  propertyMatchesParser: mixpanelLegacyExport_propertyMatchesParser,
  propertySendAsParser: mixpanelLegacyExport_propertySendAsParser,
  propertyPropertyBundleNameParser: mixpanelLegacyExport_propertyPropertyBundleNameParser,
  propertyEventsParser: mixpanelLegacyExport_propertyEventsParser,
  propertyNameMappingParser: mixpanelLegacyExport_propertyNameMappingParser,
  propertyPinnedValueParser: mixpanelLegacyExport_propertyPinnedValueParser,
  propertyBundleRowNameParser: mixpanelLegacyExport_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: mixpanelLegacyExport_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var avoFormat_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Event Name"
};

var avoFormat_eventDescriptionParser = {
  TAG: "Read",
  _0: "Event Description"
};

var avoFormat_eventCategoriesParser = {
  TAG: "MapStringArray",
  _0: "Event Category",
  _1: parseStringArray
};

var avoFormat_eventTagsParser = {
  TAG: "MapStringArrayFromOptionalColumn",
  _0: "Event Tags",
  _1: parseStringArray
};

var avoFormat_eventSourcesParser = {
  TAG: "MapStringArrayOneOf",
  _0: [
    "Sources",
    "Platforms"
  ],
  _1: parseStringArray
};

var avoFormat_eventStakeholderDomainsParser = {
  TAG: "MapStringArrayFromOptionalColumn",
  _0: "Stakeholder Domains",
  _1: parseStringArray
};

var avoFormat_eventNameMappingParser = {
  TAG: "RegexMapWithDefault",
  _0: "Event Name Mapping:.*",
  _1: "",
  _2: (function (columnName, nameMapping) {
      var match = columnName.split(":");
      var destinationName = match.length !== 2 ? "" : match[1].trim();
      return [
              nameMapping,
              nameMapping !== "" ? destinationName.toLowerCase() : undefined
            ];
    })
};

var avoFormat_eventPropertyBundleNameParser = {
  TAG: "ReadFromOptionalColumn",
  _0: "Event Property Bundle Name"
};

var avoFormat_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var avoFormat_propertyNameParser = {
  TAG: "ReadOneOf",
  _0: [
    "Event Property Name",
    "Property Name"
  ]
};

var avoFormat_propertyDescriptionParser = {
  TAG: "Read",
  _0: "Property Description"
};

var avoFormat_propertyTypeParser = {
  TAG: "Map",
  _0: "Property Value Type",
  _1: parsePropertyType
};

var avoFormat_propertyRequiredParser = {
  TAG: "MapBooleanOneOf",
  _0: [
    "Is Property Requierd?",
    "Is Property Required?"
  ],
  _1: parsePropertyRequired
};

var avoFormat_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Is Property Array?",
  _1: parsePropertyList
};

var avoFormat_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Property Enumeration Options",
  _1: parseStringArray
};

var avoFormat_propertySendAsParser = {
  TAG: "MapSendAsFromOptionalColumn",
  _0: "Property Type",
  _1: parseSendAs
};

var avoFormat_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var avoFormat_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var avoFormat_propertyNameMappingParser = {
  TAG: "RegexMapWithDefault",
  _0: "Property Name Mapping:.*",
  _1: "",
  _2: (function (columnName, nameMapping) {
      var match = columnName.split(":");
      var destinationName = match.length !== 2 ? "" : match[1].trim();
      return [
              nameMapping,
              nameMapping !== "" ? destinationName.toLowerCase() : undefined
            ];
    })
};

var avoFormat_propertyPinnedValueParser = {
  TAG: "ReadFromOptionalColumnOneOf",
  _0: [
    "Pinned Value",
    "Pinned Property Value"
  ]
};

var avoFormat_propertyBundleRowNameParser = {
  TAG: "ReadFromOptionalColumn",
  _0: "Property Bundle Name"
};

var avoFormat_propertyBundleRowDescriptionParser = {
  TAG: "ReadFromOptionalColumn",
  _0: "Property Bundle Description"
};

var avoFormat = {
  formatName: "Avo Format",
  eventNameParser: avoFormat_eventNameParser,
  eventDescriptionParser: avoFormat_eventDescriptionParser,
  eventCategoriesParser: avoFormat_eventCategoriesParser,
  eventTagsParser: avoFormat_eventTagsParser,
  eventSourcesParser: avoFormat_eventSourcesParser,
  eventStakeholderDomainsParser: avoFormat_eventStakeholderDomainsParser,
  eventNameMappingParser: avoFormat_eventNameMappingParser,
  eventPropertyBundleNameParser: avoFormat_eventPropertyBundleNameParser,
  eventVersionParser: avoFormat_eventVersionParser,
  propertyNameParser: avoFormat_propertyNameParser,
  propertyDescriptionParser: avoFormat_propertyDescriptionParser,
  propertyTypeParser: avoFormat_propertyTypeParser,
  propertyRequiredParser: avoFormat_propertyRequiredParser,
  propertyListParser: avoFormat_propertyListParser,
  propertyMatchesParser: avoFormat_propertyMatchesParser,
  propertySendAsParser: avoFormat_propertySendAsParser,
  propertyPropertyBundleNameParser: avoFormat_propertyPropertyBundleNameParser,
  propertyEventsParser: avoFormat_propertyEventsParser,
  propertyNameMappingParser: avoFormat_propertyNameMappingParser,
  propertyPinnedValueParser: avoFormat_propertyPinnedValueParser,
  propertyBundleRowNameParser: avoFormat_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: avoFormat_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var amplitudeTrackingPlanTemplate_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Event Name"
};

var amplitudeTrackingPlanTemplate_eventDescriptionParser = {
  TAG: "Read",
  _0: "Trigger + Event Description"
};

var amplitudeTrackingPlanTemplate_eventCategoriesParser = {
  TAG: "MapStringArray",
  _0: "Event Category",
  _1: parseStringArray
};

var amplitudeTrackingPlanTemplate_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeTrackingPlanTemplate_eventSourcesParser = {
  TAG: "MapStringArray",
  _0: "Platform",
  _1: parseStringArray
};

var amplitudeTrackingPlanTemplate_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeTrackingPlanTemplate_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeTrackingPlanTemplate_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeTrackingPlanTemplate_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var amplitudeTrackingPlanTemplate_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Property Name"
};

var amplitudeTrackingPlanTemplate_propertyDescriptionParser = {
  TAG: "Read",
  _0: "Property Description"
};

var amplitudeTrackingPlanTemplate_propertyTypeParser = {
  TAG: "Map",
  _0: "Sample Values",
  _1: (function (value) {
      if (Caml_obj.equal(parseStringArray(value), [])) {
        return [
                "any",
                undefined
              ];
      } else {
        return [
                "string",
                undefined
              ];
      }
    })
};

var amplitudeTrackingPlanTemplate_propertyRequiredParser = {
  TAG: "SkipBoolean",
  _0: false
};

var amplitudeTrackingPlanTemplate_propertyListParser = {
  TAG: "SkipBoolean",
  _0: false
};

var amplitudeTrackingPlanTemplate_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Sample Values",
  _1: parseStringArray
};

var amplitudeTrackingPlanTemplate_propertySendAsParser = {
  TAG: "MapSendAs",
  _0: "Property Type",
  _1: (function (value) {
      var match = value.toLocaleLowerCase();
      switch (match) {
        case "system" :
            return "SystemProperty";
        case "user" :
            return "UserProperty";
        default:
          return "EventProperty";
      }
    })
};

var amplitudeTrackingPlanTemplate_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeTrackingPlanTemplate_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeTrackingPlanTemplate_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeTrackingPlanTemplate_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeTrackingPlanTemplate_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeTrackingPlanTemplate_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeTrackingPlanTemplate = {
  formatName: "Amplitude Taxonomy Template",
  eventNameParser: amplitudeTrackingPlanTemplate_eventNameParser,
  eventDescriptionParser: amplitudeTrackingPlanTemplate_eventDescriptionParser,
  eventCategoriesParser: amplitudeTrackingPlanTemplate_eventCategoriesParser,
  eventTagsParser: amplitudeTrackingPlanTemplate_eventTagsParser,
  eventSourcesParser: amplitudeTrackingPlanTemplate_eventSourcesParser,
  eventStakeholderDomainsParser: amplitudeTrackingPlanTemplate_eventStakeholderDomainsParser,
  eventNameMappingParser: amplitudeTrackingPlanTemplate_eventNameMappingParser,
  eventPropertyBundleNameParser: amplitudeTrackingPlanTemplate_eventPropertyBundleNameParser,
  eventVersionParser: amplitudeTrackingPlanTemplate_eventVersionParser,
  propertyNameParser: amplitudeTrackingPlanTemplate_propertyNameParser,
  propertyDescriptionParser: amplitudeTrackingPlanTemplate_propertyDescriptionParser,
  propertyTypeParser: amplitudeTrackingPlanTemplate_propertyTypeParser,
  propertyRequiredParser: amplitudeTrackingPlanTemplate_propertyRequiredParser,
  propertyListParser: amplitudeTrackingPlanTemplate_propertyListParser,
  propertyMatchesParser: amplitudeTrackingPlanTemplate_propertyMatchesParser,
  propertySendAsParser: amplitudeTrackingPlanTemplate_propertySendAsParser,
  propertyPropertyBundleNameParser: amplitudeTrackingPlanTemplate_propertyPropertyBundleNameParser,
  propertyEventsParser: amplitudeTrackingPlanTemplate_propertyEventsParser,
  propertyNameMappingParser: amplitudeTrackingPlanTemplate_propertyNameMappingParser,
  propertyPinnedValueParser: amplitudeTrackingPlanTemplate_propertyPinnedValueParser,
  propertyBundleRowNameParser: amplitudeTrackingPlanTemplate_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: amplitudeTrackingPlanTemplate_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var amplitudeDataExport_eventNameParser = {
  TAG: "ReadRequiredWithAnotherColumnConditional",
  _0: "Object Name",
  _1: "Object Type",
  _2: (function (value) {
      return value === "Event";
    })
};

var amplitudeDataExport_eventDescriptionParser = {
  TAG: "Read",
  _0: "Object Description"
};

var amplitudeDataExport_eventCategoriesParser = {
  TAG: "MapStringArray",
  _0: "Event Category",
  _1: parseStringArray
};

var amplitudeDataExport_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeDataExport_eventSourcesParser = {
  TAG: "MapStringArray",
  _0: "Event Source",
  _1: parseStringArray
};

var amplitudeDataExport_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeDataExport_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeDataExport_eventPropertyBundleNameParser = {
  TAG: "ReadRequired",
  _0: "Property Group Names"
};

var amplitudeDataExport_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var amplitudeDataExport_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Event Property Name"
};

var amplitudeDataExport_propertyDescriptionParser = {
  TAG: "Read",
  _0: "Property Description"
};

var amplitudeDataExport_propertyTypeParser = {
  TAG: "Map",
  _0: "Property Value Type",
  _1: parsePropertyType
};

var amplitudeDataExport_propertyRequiredParser = {
  TAG: "SkipBoolean",
  _0: false
};

var amplitudeDataExport_propertyListParser = {
  TAG: "SkipBoolean",
  _0: false
};

var amplitudeDataExport_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Const Value",
  _1: parseStringArray
};

var amplitudeDataExport_propertySendAsParser = {
  TAG: "MapSendAs",
  _0: "Property Type",
  _1: parseSendAs
};

var amplitudeDataExport_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeDataExport_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeDataExport_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeDataExport_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeDataExport_propertyBundleRowNameParser = {
  TAG: "ReadRequiredWithAnotherColumnConditional",
  _0: "Object Name",
  _1: "Object Type",
  _2: (function (value) {
      return value === "Property Group";
    })
};

var amplitudeDataExport_propertyBundleRowDescriptionParser = {
  TAG: "Read",
  _0: "Object Description"
};

var amplitudeDataExport = {
  formatName: "Amplitude Data Template",
  eventNameParser: amplitudeDataExport_eventNameParser,
  eventDescriptionParser: amplitudeDataExport_eventDescriptionParser,
  eventCategoriesParser: amplitudeDataExport_eventCategoriesParser,
  eventTagsParser: amplitudeDataExport_eventTagsParser,
  eventSourcesParser: amplitudeDataExport_eventSourcesParser,
  eventStakeholderDomainsParser: amplitudeDataExport_eventStakeholderDomainsParser,
  eventNameMappingParser: amplitudeDataExport_eventNameMappingParser,
  eventPropertyBundleNameParser: amplitudeDataExport_eventPropertyBundleNameParser,
  eventVersionParser: amplitudeDataExport_eventVersionParser,
  propertyNameParser: amplitudeDataExport_propertyNameParser,
  propertyDescriptionParser: amplitudeDataExport_propertyDescriptionParser,
  propertyTypeParser: amplitudeDataExport_propertyTypeParser,
  propertyRequiredParser: amplitudeDataExport_propertyRequiredParser,
  propertyListParser: amplitudeDataExport_propertyListParser,
  propertyMatchesParser: amplitudeDataExport_propertyMatchesParser,
  propertySendAsParser: amplitudeDataExport_propertySendAsParser,
  propertyPropertyBundleNameParser: amplitudeDataExport_propertyPropertyBundleNameParser,
  propertyEventsParser: amplitudeDataExport_propertyEventsParser,
  propertyNameMappingParser: amplitudeDataExport_propertyNameMappingParser,
  propertyPinnedValueParser: amplitudeDataExport_propertyPinnedValueParser,
  propertyBundleRowNameParser: amplitudeDataExport_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: amplitudeDataExport_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var mixpanelTrackingPlanTemplate_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Event Name"
};

var mixpanelTrackingPlanTemplate_eventDescriptionParser = {
  TAG: "Read",
  _0: "Event Definition"
};

var mixpanelTrackingPlanTemplate_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelTrackingPlanTemplate_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelTrackingPlanTemplate_eventSourcesParser = {
  TAG: "MapStringArray",
  _0: "Platform",
  _1: parseStringArray
};

var mixpanelTrackingPlanTemplate_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelTrackingPlanTemplate_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelTrackingPlanTemplate_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelTrackingPlanTemplate_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var mixpanelTrackingPlanTemplate_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Property Name"
};

var mixpanelTrackingPlanTemplate_propertyDescriptionParser = {
  TAG: "Read",
  _0: "Property Definition"
};

var mixpanelTrackingPlanTemplate_propertyTypeParser = {
  TAG: "Map",
  _0: "Data Type",
  _1: parsePropertyType
};

var mixpanelTrackingPlanTemplate_propertyRequiredParser = {
  TAG: "SkipBoolean",
  _0: false
};

var mixpanelTrackingPlanTemplate_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Data Type",
  _1: (function (value) {
      var value$1 = value.toLocaleLowerCase();
      if (value$1 === "list") {
        return true;
      } else {
        return value$1 === "array";
      }
    })
};

var mixpanelTrackingPlanTemplate_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Sample Values",
  _1: parseStringArray
};

var mixpanelTrackingPlanTemplate_propertySendAsParser = {
  TAG: "SkipSendAs",
  _0: "EventProperty"
};

var mixpanelTrackingPlanTemplate_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelTrackingPlanTemplate_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var mixpanelTrackingPlanTemplate_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelTrackingPlanTemplate_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelTrackingPlanTemplate_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelTrackingPlanTemplate_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var mixpanelTrackingPlanTemplate = {
  formatName: "Mixpanel Tracking Plan Template",
  eventNameParser: mixpanelTrackingPlanTemplate_eventNameParser,
  eventDescriptionParser: mixpanelTrackingPlanTemplate_eventDescriptionParser,
  eventCategoriesParser: mixpanelTrackingPlanTemplate_eventCategoriesParser,
  eventTagsParser: mixpanelTrackingPlanTemplate_eventTagsParser,
  eventSourcesParser: mixpanelTrackingPlanTemplate_eventSourcesParser,
  eventStakeholderDomainsParser: mixpanelTrackingPlanTemplate_eventStakeholderDomainsParser,
  eventNameMappingParser: mixpanelTrackingPlanTemplate_eventNameMappingParser,
  eventPropertyBundleNameParser: mixpanelTrackingPlanTemplate_eventPropertyBundleNameParser,
  eventVersionParser: mixpanelTrackingPlanTemplate_eventVersionParser,
  propertyNameParser: mixpanelTrackingPlanTemplate_propertyNameParser,
  propertyDescriptionParser: mixpanelTrackingPlanTemplate_propertyDescriptionParser,
  propertyTypeParser: mixpanelTrackingPlanTemplate_propertyTypeParser,
  propertyRequiredParser: mixpanelTrackingPlanTemplate_propertyRequiredParser,
  propertyListParser: mixpanelTrackingPlanTemplate_propertyListParser,
  propertyMatchesParser: mixpanelTrackingPlanTemplate_propertyMatchesParser,
  propertySendAsParser: mixpanelTrackingPlanTemplate_propertySendAsParser,
  propertyPropertyBundleNameParser: mixpanelTrackingPlanTemplate_propertyPropertyBundleNameParser,
  propertyEventsParser: mixpanelTrackingPlanTemplate_propertyEventsParser,
  propertyNameMappingParser: mixpanelTrackingPlanTemplate_propertyNameMappingParser,
  propertyPinnedValueParser: mixpanelTrackingPlanTemplate_propertyPinnedValueParser,
  propertyBundleRowNameParser: mixpanelTrackingPlanTemplate_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: mixpanelTrackingPlanTemplate_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var amplitudeUserPropertiesExport_eventNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_eventDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeUserPropertiesExport_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeUserPropertiesExport_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeUserPropertiesExport_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeUserPropertiesExport_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var amplitudeUserPropertiesExport_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "User Property"
};

var amplitudeUserPropertiesExport_propertyDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_propertyTypeParser = {
  TAG: "SkipWithDefault",
  _0: "any"
};

var amplitudeUserPropertiesExport_propertyRequiredParser = {
  TAG: "SkipBoolean",
  _0: true
};

var amplitudeUserPropertiesExport_propertyListParser = {
  TAG: "SkipBoolean",
  _0: false
};

var amplitudeUserPropertiesExport_propertyMatchesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeUserPropertiesExport_propertySendAsParser = {
  TAG: "SkipSendAs",
  _0: "UserProperty"
};

var amplitudeUserPropertiesExport_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var amplitudeUserPropertiesExport_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var amplitudeUserPropertiesExport = {
  formatName: "Amplitude User Properties CSV Export",
  eventNameParser: amplitudeUserPropertiesExport_eventNameParser,
  eventDescriptionParser: amplitudeUserPropertiesExport_eventDescriptionParser,
  eventCategoriesParser: amplitudeUserPropertiesExport_eventCategoriesParser,
  eventTagsParser: amplitudeUserPropertiesExport_eventTagsParser,
  eventSourcesParser: amplitudeUserPropertiesExport_eventSourcesParser,
  eventStakeholderDomainsParser: amplitudeUserPropertiesExport_eventStakeholderDomainsParser,
  eventNameMappingParser: amplitudeUserPropertiesExport_eventNameMappingParser,
  eventPropertyBundleNameParser: amplitudeUserPropertiesExport_eventPropertyBundleNameParser,
  eventVersionParser: amplitudeUserPropertiesExport_eventVersionParser,
  propertyNameParser: amplitudeUserPropertiesExport_propertyNameParser,
  propertyDescriptionParser: amplitudeUserPropertiesExport_propertyDescriptionParser,
  propertyTypeParser: amplitudeUserPropertiesExport_propertyTypeParser,
  propertyRequiredParser: amplitudeUserPropertiesExport_propertyRequiredParser,
  propertyListParser: amplitudeUserPropertiesExport_propertyListParser,
  propertyMatchesParser: amplitudeUserPropertiesExport_propertyMatchesParser,
  propertySendAsParser: amplitudeUserPropertiesExport_propertySendAsParser,
  propertyPropertyBundleNameParser: amplitudeUserPropertiesExport_propertyPropertyBundleNameParser,
  propertyEventsParser: amplitudeUserPropertiesExport_propertyEventsParser,
  propertyNameMappingParser: amplitudeUserPropertiesExport_propertyNameMappingParser,
  propertyPinnedValueParser: amplitudeUserPropertiesExport_propertyPinnedValueParser,
  propertyBundleRowNameParser: amplitudeUserPropertiesExport_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: amplitudeUserPropertiesExport_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var segmentCSVExport_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Event Name"
};

var segmentCSVExport_eventDescriptionParser = {
  TAG: "Read",
  _0: "Description"
};

var segmentCSVExport_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var segmentCSVExport_eventTagsParser = {
  TAG: "MapStringArray",
  _0: "Labels",
  _1: parseStringArray
};

var segmentCSVExport_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var segmentCSVExport_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var segmentCSVExport_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var segmentCSVExport_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var segmentCSVExport_eventVersionParser = {
  TAG: "ReadWithDefault",
  _0: "Version",
  _1: 0
};

var segmentCSVExport_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Property Name"
};

var segmentCSVExport_propertyDescriptionParser = {
  TAG: "Read",
  _0: "Description"
};

var segmentCSVExport_propertyTypeParser = {
  TAG: "Map",
  _0: "Property Data Type",
  _1: (function (propertyType) {
      return parsePropertyType(propertyType.replace(",null", ""));
    })
};

var segmentCSVExport_propertyRequiredParser = {
  TAG: "MapBoolean",
  _0: "Property Data Type",
  _1: (function (propertyDataType) {
      return !propertyDataType.includes("null");
    })
};

var segmentCSVExport_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Property Data Type",
  _1: (function (propertyType) {
      return propertyType.includes("array");
    })
};

var segmentCSVExport_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Enum Values",
  _1: parseStringArray
};

var segmentCSVExport_propertySendAsParser = {
  TAG: "MapSendAs",
  _0: "Event Type",
  _1: (function (eventType) {
      switch (eventType) {
        case "Identify" :
            return "UserProperty";
        default:
          return "EventProperty";
      }
    })
};

var segmentCSVExport_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var segmentCSVExport_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var segmentCSVExport_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var segmentCSVExport_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var segmentCSVExport_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var segmentCSVExport_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var segmentCSVExport_customParser = ImportFormatsSegmentCustomParser.parse;

var segmentCSVExport = {
  formatName: "Segment Protocols Export",
  eventNameParser: segmentCSVExport_eventNameParser,
  eventDescriptionParser: segmentCSVExport_eventDescriptionParser,
  eventCategoriesParser: segmentCSVExport_eventCategoriesParser,
  eventTagsParser: segmentCSVExport_eventTagsParser,
  eventSourcesParser: segmentCSVExport_eventSourcesParser,
  eventStakeholderDomainsParser: segmentCSVExport_eventStakeholderDomainsParser,
  eventNameMappingParser: segmentCSVExport_eventNameMappingParser,
  eventPropertyBundleNameParser: segmentCSVExport_eventPropertyBundleNameParser,
  eventVersionParser: segmentCSVExport_eventVersionParser,
  propertyNameParser: segmentCSVExport_propertyNameParser,
  propertyDescriptionParser: segmentCSVExport_propertyDescriptionParser,
  propertyTypeParser: segmentCSVExport_propertyTypeParser,
  propertyRequiredParser: segmentCSVExport_propertyRequiredParser,
  propertyListParser: segmentCSVExport_propertyListParser,
  propertyMatchesParser: segmentCSVExport_propertyMatchesParser,
  propertySendAsParser: segmentCSVExport_propertySendAsParser,
  propertyPropertyBundleNameParser: segmentCSVExport_propertyPropertyBundleNameParser,
  propertyEventsParser: segmentCSVExport_propertyEventsParser,
  propertyNameMappingParser: segmentCSVExport_propertyNameMappingParser,
  propertyPinnedValueParser: segmentCSVExport_propertyPinnedValueParser,
  propertyBundleRowNameParser: segmentCSVExport_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: segmentCSVExport_propertyBundleRowDescriptionParser,
  customParser: segmentCSVExport_customParser
};

var groupPropertiesTemplate_eventNameParser = {
  TAG: "SkipWithDefault",
  _0: "skip-event"
};

var groupPropertiesTemplate_eventDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var groupPropertiesTemplate_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var groupPropertiesTemplate_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var groupPropertiesTemplate_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var groupPropertiesTemplate_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var groupPropertiesTemplate_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var groupPropertiesTemplate_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var groupPropertiesTemplate_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var groupPropertiesTemplate_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Property Name"
};

var groupPropertiesTemplate_propertyDescriptionParser = {
  TAG: "ReadRequired",
  _0: "Property Description"
};

var groupPropertiesTemplate_propertyTypeParser = {
  TAG: "Map",
  _0: "Property Value Type",
  _1: parsePropertyType
};

var groupPropertiesTemplate_propertyRequiredParser = {
  TAG: "SkipBoolean",
  _0: true
};

var groupPropertiesTemplate_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Is Property Array?",
  _1: parsePropertyList
};

var groupPropertiesTemplate_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Property Enumeration Options",
  _1: parseStringArray
};

var groupPropertiesTemplate_propertySendAsParser = {
  TAG: "MapSendAs",
  _0: "Group Type",
  _1: (function (groupTypeName) {
      return {
              TAG: "GroupProperty",
              _0: groupTypeName
            };
    })
};

var groupPropertiesTemplate_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var groupPropertiesTemplate_propertyEventsParser = {
  TAG: "MapStringArray",
  _0: "Sent with Events",
  _1: (function (string) {
      return Belt_Array.map(string.split("/"), (function (prim) {
                    return prim.trim();
                  }));
    })
};

var groupPropertiesTemplate_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var groupPropertiesTemplate_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var groupPropertiesTemplate_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var groupPropertiesTemplate_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var groupPropertiesTemplate = {
  formatName: "Group Properties Template",
  eventNameParser: groupPropertiesTemplate_eventNameParser,
  eventDescriptionParser: groupPropertiesTemplate_eventDescriptionParser,
  eventCategoriesParser: groupPropertiesTemplate_eventCategoriesParser,
  eventTagsParser: groupPropertiesTemplate_eventTagsParser,
  eventSourcesParser: groupPropertiesTemplate_eventSourcesParser,
  eventStakeholderDomainsParser: groupPropertiesTemplate_eventStakeholderDomainsParser,
  eventNameMappingParser: groupPropertiesTemplate_eventNameMappingParser,
  eventPropertyBundleNameParser: groupPropertiesTemplate_eventPropertyBundleNameParser,
  eventVersionParser: groupPropertiesTemplate_eventVersionParser,
  propertyNameParser: groupPropertiesTemplate_propertyNameParser,
  propertyDescriptionParser: groupPropertiesTemplate_propertyDescriptionParser,
  propertyTypeParser: groupPropertiesTemplate_propertyTypeParser,
  propertyRequiredParser: groupPropertiesTemplate_propertyRequiredParser,
  propertyListParser: groupPropertiesTemplate_propertyListParser,
  propertyMatchesParser: groupPropertiesTemplate_propertyMatchesParser,
  propertySendAsParser: groupPropertiesTemplate_propertySendAsParser,
  propertyPropertyBundleNameParser: groupPropertiesTemplate_propertyPropertyBundleNameParser,
  propertyEventsParser: groupPropertiesTemplate_propertyEventsParser,
  propertyNameMappingParser: groupPropertiesTemplate_propertyNameMappingParser,
  propertyPinnedValueParser: groupPropertiesTemplate_propertyPinnedValueParser,
  propertyBundleRowNameParser: groupPropertiesTemplate_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: groupPropertiesTemplate_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var propertiesImportFormat_eventNameParser = {
  TAG: "SkipWithDefault",
  _0: "skip-event"
};

var propertiesImportFormat_eventDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var propertiesImportFormat_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var propertiesImportFormat_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var propertiesImportFormat_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var propertiesImportFormat_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var propertiesImportFormat_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var propertiesImportFormat_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var propertiesImportFormat_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var propertiesImportFormat_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Property Name"
};

var propertiesImportFormat_propertyDescriptionParser = {
  TAG: "ReadRequired",
  _0: "Property Description"
};

var propertiesImportFormat_propertyTypeParser = {
  TAG: "Map",
  _0: "Property Value Type",
  _1: parsePropertyType
};

var propertiesImportFormat_propertyRequiredParser = {
  TAG: "MapBoolean",
  _0: "Property Required",
  _1: parsePropertyRequired
};

var propertiesImportFormat_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Property Value Is List",
  _1: parsePropertyList
};

var propertiesImportFormat_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Property Allowed Values",
  _1: parseStringArray
};

var propertiesImportFormat_propertySendAsParser = {
  TAG: "MapSendAs",
  _0: "Property Type",
  _1: parseSendAs
};

var propertiesImportFormat_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var propertiesImportFormat_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var propertiesImportFormat_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var propertiesImportFormat_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var propertiesImportFormat_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var propertiesImportFormat_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var propertiesImportFormat = {
  formatName: "Properties Import Format",
  eventNameParser: propertiesImportFormat_eventNameParser,
  eventDescriptionParser: propertiesImportFormat_eventDescriptionParser,
  eventCategoriesParser: propertiesImportFormat_eventCategoriesParser,
  eventTagsParser: propertiesImportFormat_eventTagsParser,
  eventSourcesParser: propertiesImportFormat_eventSourcesParser,
  eventStakeholderDomainsParser: propertiesImportFormat_eventStakeholderDomainsParser,
  eventNameMappingParser: propertiesImportFormat_eventNameMappingParser,
  eventPropertyBundleNameParser: propertiesImportFormat_eventPropertyBundleNameParser,
  eventVersionParser: propertiesImportFormat_eventVersionParser,
  propertyNameParser: propertiesImportFormat_propertyNameParser,
  propertyDescriptionParser: propertiesImportFormat_propertyDescriptionParser,
  propertyTypeParser: propertiesImportFormat_propertyTypeParser,
  propertyRequiredParser: propertiesImportFormat_propertyRequiredParser,
  propertyListParser: propertiesImportFormat_propertyListParser,
  propertyMatchesParser: propertiesImportFormat_propertyMatchesParser,
  propertySendAsParser: propertiesImportFormat_propertySendAsParser,
  propertyPropertyBundleNameParser: propertiesImportFormat_propertyPropertyBundleNameParser,
  propertyEventsParser: propertiesImportFormat_propertyEventsParser,
  propertyNameMappingParser: propertiesImportFormat_propertyNameMappingParser,
  propertyPinnedValueParser: propertiesImportFormat_propertyPinnedValueParser,
  propertyBundleRowNameParser: propertiesImportFormat_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: propertiesImportFormat_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var customSpreadsheetFormatOne_eventNameParser = {
  TAG: "SkipWithDefault",
  _0: "skip-event"
};

var customSpreadsheetFormatOne_eventDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatOne_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatOne_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatOne_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatOne_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatOne_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatOne_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatOne_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var customSpreadsheetFormatOne_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Parameter Name"
};

var customSpreadsheetFormatOne_propertyDescriptionParser = {
  TAG: "ReadAndConcat",
  _0: [
    "Definition",
    "Example Value (Goal)",
    "Default Value (GTM)",
    "DQD Requirement (regex)",
    "GTM Name"
  ]
};

var customSpreadsheetFormatOne_propertyTypeParser = {
  TAG: "Map",
  _0: "Format",
  _1: parsePropertyType
};

var customSpreadsheetFormatOne_propertyRequiredParser = {
  TAG: "SkipBoolean",
  _0: false
};

var customSpreadsheetFormatOne_propertyListParser = {
  TAG: "SkipBoolean",
  _0: false
};

var customSpreadsheetFormatOne_propertyMatchesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatOne_propertySendAsParser = {
  TAG: "SkipSendAs",
  _0: "EventProperty"
};

var customSpreadsheetFormatOne_propertyPropertyBundleNameParser = {
  TAG: "Read",
  _0: "Property Group"
};

var customSpreadsheetFormatOne_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatOne_propertyNameMappingParser = {
  TAG: "Map",
  _0: "nameMapping:GoogleAnalytics",
  _1: (function (nameMapping) {
      return [
              nameMapping,
              nameMapping !== "" ? "Google Analytics" : undefined
            ];
    })
};

var customSpreadsheetFormatOne_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatOne_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatOne_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatOne_customParser = ImportFormatsCustomSpreadsheetFormatOneParser.eventsParser;

var customSpreadsheetFormatOne = {
  formatName: "Custom spreadsheet format #1",
  eventNameParser: customSpreadsheetFormatOne_eventNameParser,
  eventDescriptionParser: customSpreadsheetFormatOne_eventDescriptionParser,
  eventCategoriesParser: customSpreadsheetFormatOne_eventCategoriesParser,
  eventTagsParser: customSpreadsheetFormatOne_eventTagsParser,
  eventSourcesParser: customSpreadsheetFormatOne_eventSourcesParser,
  eventStakeholderDomainsParser: customSpreadsheetFormatOne_eventStakeholderDomainsParser,
  eventNameMappingParser: customSpreadsheetFormatOne_eventNameMappingParser,
  eventPropertyBundleNameParser: customSpreadsheetFormatOne_eventPropertyBundleNameParser,
  eventVersionParser: customSpreadsheetFormatOne_eventVersionParser,
  propertyNameParser: customSpreadsheetFormatOne_propertyNameParser,
  propertyDescriptionParser: customSpreadsheetFormatOne_propertyDescriptionParser,
  propertyTypeParser: customSpreadsheetFormatOne_propertyTypeParser,
  propertyRequiredParser: customSpreadsheetFormatOne_propertyRequiredParser,
  propertyListParser: customSpreadsheetFormatOne_propertyListParser,
  propertyMatchesParser: customSpreadsheetFormatOne_propertyMatchesParser,
  propertySendAsParser: customSpreadsheetFormatOne_propertySendAsParser,
  propertyPropertyBundleNameParser: customSpreadsheetFormatOne_propertyPropertyBundleNameParser,
  propertyEventsParser: customSpreadsheetFormatOne_propertyEventsParser,
  propertyNameMappingParser: customSpreadsheetFormatOne_propertyNameMappingParser,
  propertyPinnedValueParser: customSpreadsheetFormatOne_propertyPinnedValueParser,
  propertyBundleRowNameParser: customSpreadsheetFormatOne_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: customSpreadsheetFormatOne_propertyBundleRowDescriptionParser,
  customParser: customSpreadsheetFormatOne_customParser
};

var customSpreadsheetFormatTwo_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Event_Name"
};

var customSpreadsheetFormatTwo_eventDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatTwo_eventCategoriesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatTwo_eventTagsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatTwo_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatTwo_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatTwo_eventNameMappingParser = {
  TAG: "Map",
  _0: "Name_Mapping",
  _1: (function (nameMapping) {
      return [
              nameMapping,
              nameMapping !== "" ? "Firebase Analytics" : undefined
            ];
    })
};

var customSpreadsheetFormatTwo_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatTwo_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var customSpreadsheetFormatTwo_propertyNameParser = {
  TAG: "ReadRequired",
  _0: "Event_property_Name"
};

var customSpreadsheetFormatTwo_propertyDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatTwo_propertyTypeParser = {
  TAG: "Map",
  _0: "Event_Property_Type",
  _1: parsePropertyType
};

var customSpreadsheetFormatTwo_propertyRequiredParser = {
  TAG: "MapBoolean",
  _0: "Is_Property_Required",
  _1: parsePropertyRequired
};

var customSpreadsheetFormatTwo_propertyListParser = {
  TAG: "MapBoolean",
  _0: "Is_Property_Array",
  _1: parsePropertyList
};

var customSpreadsheetFormatTwo_propertyMatchesParser = {
  TAG: "MapStringArray",
  _0: "Event_Property_Allowed_Values",
  _1: parseStringArray
};

var customSpreadsheetFormatTwo_propertySendAsParser = {
  TAG: "SkipSendAs",
  _0: "EventProperty"
};

var customSpreadsheetFormatTwo_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatTwo_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatTwo_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatTwo_propertyPinnedValueParser = {
  TAG: "Map",
  _0: "Event_Property_Pinned_Value",
  _1: (function (pinnedValue) {
      if (pinnedValue !== "") {
        return pinnedValue;
      } else {
        return "";
      }
    })
};

var customSpreadsheetFormatTwo_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatTwo_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatTwo = {
  formatName: "Custom spreadsheet format #2",
  eventNameParser: customSpreadsheetFormatTwo_eventNameParser,
  eventDescriptionParser: customSpreadsheetFormatTwo_eventDescriptionParser,
  eventCategoriesParser: customSpreadsheetFormatTwo_eventCategoriesParser,
  eventTagsParser: customSpreadsheetFormatTwo_eventTagsParser,
  eventSourcesParser: customSpreadsheetFormatTwo_eventSourcesParser,
  eventStakeholderDomainsParser: customSpreadsheetFormatTwo_eventStakeholderDomainsParser,
  eventNameMappingParser: customSpreadsheetFormatTwo_eventNameMappingParser,
  eventPropertyBundleNameParser: customSpreadsheetFormatTwo_eventPropertyBundleNameParser,
  eventVersionParser: customSpreadsheetFormatTwo_eventVersionParser,
  propertyNameParser: customSpreadsheetFormatTwo_propertyNameParser,
  propertyDescriptionParser: customSpreadsheetFormatTwo_propertyDescriptionParser,
  propertyTypeParser: customSpreadsheetFormatTwo_propertyTypeParser,
  propertyRequiredParser: customSpreadsheetFormatTwo_propertyRequiredParser,
  propertyListParser: customSpreadsheetFormatTwo_propertyListParser,
  propertyMatchesParser: customSpreadsheetFormatTwo_propertyMatchesParser,
  propertySendAsParser: customSpreadsheetFormatTwo_propertySendAsParser,
  propertyPropertyBundleNameParser: customSpreadsheetFormatTwo_propertyPropertyBundleNameParser,
  propertyEventsParser: customSpreadsheetFormatTwo_propertyEventsParser,
  propertyNameMappingParser: customSpreadsheetFormatTwo_propertyNameMappingParser,
  propertyPinnedValueParser: customSpreadsheetFormatTwo_propertyPinnedValueParser,
  propertyBundleRowNameParser: customSpreadsheetFormatTwo_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: customSpreadsheetFormatTwo_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var customSpreadsheetFormatThree_eventNameParser = {
  TAG: "ReadRequired",
  _0: "Event name"
};

var customSpreadsheetFormatThree_eventDescriptionParser = {
  TAG: "ReadRequired",
  _0: "Event"
};

var customSpreadsheetFormatThree_eventCategoriesParser = {
  TAG: "MapStringArray",
  _0: "User Flow",
  _1: parseStringArray
};

var customSpreadsheetFormatThree_eventTagsParser = {
  TAG: "MapStringArrayFromOptionalColumn",
  _0: "Tags",
  _1: parseStringArray
};

var customSpreadsheetFormatThree_eventSourcesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatThree_eventStakeholderDomainsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatThree_eventNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree_eventPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree_eventVersionParser = {
  TAG: "SkipWithDefault",
  _0: 0
};

var customSpreadsheetFormatThree_propertyNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree_propertyDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree_propertyTypeParser = {
  TAG: "SkipWithDefault",
  _0: "string"
};

var customSpreadsheetFormatThree_propertyRequiredParser = {
  TAG: "SkipBoolean",
  _0: true
};

var customSpreadsheetFormatThree_propertyListParser = {
  TAG: "SkipBoolean",
  _0: false
};

var customSpreadsheetFormatThree_propertyMatchesParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatThree_propertySendAsParser = {
  TAG: "SkipSendAs",
  _0: "EventProperty"
};

var customSpreadsheetFormatThree_propertyPropertyBundleNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree_propertyEventsParser = {
  TAG: "SkipStringArray",
  _0: []
};

var customSpreadsheetFormatThree_propertyNameMappingParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree_propertyPinnedValueParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree_propertyBundleRowNameParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree_propertyBundleRowDescriptionParser = {
  TAG: "SkipWithDefault",
  _0: ""
};

var customSpreadsheetFormatThree = {
  formatName: "Custom spreadsheet format #3",
  eventNameParser: customSpreadsheetFormatThree_eventNameParser,
  eventDescriptionParser: customSpreadsheetFormatThree_eventDescriptionParser,
  eventCategoriesParser: customSpreadsheetFormatThree_eventCategoriesParser,
  eventTagsParser: customSpreadsheetFormatThree_eventTagsParser,
  eventSourcesParser: customSpreadsheetFormatThree_eventSourcesParser,
  eventStakeholderDomainsParser: customSpreadsheetFormatThree_eventStakeholderDomainsParser,
  eventNameMappingParser: customSpreadsheetFormatThree_eventNameMappingParser,
  eventPropertyBundleNameParser: customSpreadsheetFormatThree_eventPropertyBundleNameParser,
  eventVersionParser: customSpreadsheetFormatThree_eventVersionParser,
  propertyNameParser: customSpreadsheetFormatThree_propertyNameParser,
  propertyDescriptionParser: customSpreadsheetFormatThree_propertyDescriptionParser,
  propertyTypeParser: customSpreadsheetFormatThree_propertyTypeParser,
  propertyRequiredParser: customSpreadsheetFormatThree_propertyRequiredParser,
  propertyListParser: customSpreadsheetFormatThree_propertyListParser,
  propertyMatchesParser: customSpreadsheetFormatThree_propertyMatchesParser,
  propertySendAsParser: customSpreadsheetFormatThree_propertySendAsParser,
  propertyPropertyBundleNameParser: customSpreadsheetFormatThree_propertyPropertyBundleNameParser,
  propertyEventsParser: customSpreadsheetFormatThree_propertyEventsParser,
  propertyNameMappingParser: customSpreadsheetFormatThree_propertyNameMappingParser,
  propertyPinnedValueParser: customSpreadsheetFormatThree_propertyPinnedValueParser,
  propertyBundleRowNameParser: customSpreadsheetFormatThree_propertyBundleRowNameParser,
  propertyBundleRowDescriptionParser: customSpreadsheetFormatThree_propertyBundleRowDescriptionParser,
  customParser: undefined
};

var formats_1 = {
  hd: amplitudeExport,
  tl: {
    hd: mixpanelExport,
    tl: {
      hd: mixpanelLegacyExport,
      tl: {
        hd: amplitudeTrackingPlanTemplate,
        tl: {
          hd: amplitudeDataExport,
          tl: {
            hd: mixpanelTrackingPlanTemplate,
            tl: {
              hd: segmentCSVExport,
              tl: {
                hd: amplitudeUserPropertiesExport,
                tl: {
                  hd: groupPropertiesTemplate,
                  tl: {
                    hd: propertiesImportFormat,
                    tl: {
                      hd: customSpreadsheetFormatOne,
                      tl: {
                        hd: customSpreadsheetFormatTwo,
                        tl: {
                          hd: customSpreadsheetFormatThree,
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var formats = {
  hd: avoFormat,
  tl: formats_1
};

export {
  parsePropertyType ,
  parsePropertyRequired ,
  parsePropertyList ,
  parseStringArray ,
  parseSendAs ,
  amplitudeExport ,
  mixpanelExport ,
  mixpanelLegacyExport ,
  avoFormat ,
  amplitudeTrackingPlanTemplate ,
  amplitudeDataExport ,
  mixpanelTrackingPlanTemplate ,
  amplitudeUserPropertiesExport ,
  segmentCSVExport ,
  groupPropertiesTemplate ,
  propertiesImportFormat ,
  customSpreadsheetFormatOne ,
  customSpreadsheetFormatTwo ,
  customSpreadsheetFormatThree ,
  formats ,
}
/* Sentry Not a pure module */
